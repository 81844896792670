import { IDesignBlockMatchData } from 'models/ICanvas';

export const updateMatches = (
  matches: IDesignBlockMatchData,
  alternativeId: string,
  subSystemBlockId?: string,
): IDesignBlockMatchData => ({
  ...matches,
  // NOTE: If a subSystemBlock isn't selected then alternativeId is a Subsystem/Cubo (1st level) id
  ...(subSystemBlockId
    ? {
        alternatives: matches?.alternatives.map((a) =>
          a.type === 'sub-system'
            ? {
                ...a,
                blocks: a.blocks.map((b) =>
                  b.id === subSystemBlockId ? { ...b, matches: { ...b.matches, selectedId: alternativeId } } : b,
                ),
              }
            : a,
        ),
      }
    : { selectedId: alternativeId }),
});
