import { useEffect, useMemo } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'components/ToastComponent';
import { ShortcutCheatSheetProvider, ShortcutsCheatSheet } from 'components/Shortcuts';
import CommandPalette from 'views/CommandPalette';

import { GlobalStyle, getAppMuiTheme } from 'theme';

import 'i18n/config';

import Navigation from 'routes';

import {
  getAuthIssuer,
  getActiveThemeAction,
  getI18nTranslations,
  getPermissions,
  getUserInfo,
} from 'state_management/actions/auth/authActions';
import { getWelcomeAtAction } from 'state_management/actions/unboxing/unboxingActions';
import { BROWSER_SUPPORT_CHECK, getBrowserSupportAction } from 'state_management/actions/support/supportActions';
import { getPlugins } from 'state_management/actions/plugins/pluginsActions';
import ApiSuspense from 'components/ApiSuspense';
import { useLocaleEffect } from 'i18n/hooks/useLocaleEffect';
import { loadLocals } from 'i18n/setLanguage';
import WelcomeDialog from 'components/WelcomeDialog';
import Consents from 'components/Consents';
import { noAuthRoutes } from 'routes/getRoutes';
import DataLayer from '../../services/dataLayer';
import { IProps } from './IProps';

import { Container } from './styles';

const App = ({
  loggedOut,
  userId,
  userInfo,
  currentAppType,
  theme,
  getActiveThemeRequestState,
  dispatch,
}: IProps): JSX.Element => {
  const muiTheme = useMemo(
    () => getAppMuiTheme(theme, getActiveThemeRequestState !== 'success'),
    [theme, currentAppType, getActiveThemeRequestState],
  );

  const isNoAuthRoute = noAuthRoutes.includes((window.location.pathname || '').split('/').pop());

  const checkBrowserSupport = (): void => {
    const browserSupportChecked = localStorage.getItem(BROWSER_SUPPORT_CHECK);

    if (!browserSupportChecked) {
      dispatch(getBrowserSupportAction());
    }
  };

  useEffect(() => {
    if (userId) {
      checkBrowserSupport();
      dispatch(getI18nTranslations());
      dispatch(getPermissions());
      dispatch(getAuthIssuer());
      dispatch(getActiveThemeAction());
      dispatch(getWelcomeAtAction());

      userInfo && DataLayer.initializeDLAttributes(userInfo);
    } else if (!loggedOut && !isNoAuthRoute) {
      dispatch(getUserInfo());
    }
  }, [userId, loggedOut]);

  useLocaleEffect((lang: string) => {
    loadLocals(lang);
  }, []);

  useEffect(() => {
    if (!isNoAuthRoute) {
      dispatch(getPlugins());
    }
  }, []);

  return (
    <ApiSuspense success={isNoAuthRoute || getActiveThemeRequestState === 'success'}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Container>
          {/* NOTE: Material ThemeProvider needs to be child of Styled one as to get the theme from StyledComponents */}
          <ApiSuspense success={isNoAuthRoute || !!userId}>
            <MuiThemeProvider theme={muiTheme}>
              <Consents isNoAuthRoute={isNoAuthRoute}>
                <ShortcutCheatSheetProvider>
                  <>
                    <WelcomeDialog />
                    <ShortcutsCheatSheet />
                    <Navigation />

                    {/* NOTE: ToastsContainer needs to be here so get theme from Both Material and StyledComp */}
                    <ToastContainer />
                    <CommandPalette />
                  </>
                </ShortcutCheatSheetProvider>
              </Consents>
            </MuiThemeProvider>
          </ApiSuspense>
        </Container>
      </ThemeProvider>
    </ApiSuspense>
  );
};

export default App;
