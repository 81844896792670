import { NotificationActionTypes, NotificationActions } from '../../actions/notification/ActionTypes';
import { NotificationState } from './Modals';

export const initialStateNotification: NotificationState = {
  read: [],
  unread: [],
};

export const notificationReducer = (
  state: NotificationState = initialStateNotification,
  action: NotificationActions,
): NotificationState => {
  switch (action.type) {
    case NotificationActionTypes.NEW_NOTIFICATION:
      return {
        ...state,
        read: action.payload.read ? [...state.read] : state.read,
        unread: !action.payload.read ? [...state.unread] : state.unread,
      };
    case NotificationActionTypes.RESET_NOTIFICATIONS:
      return { ...state, ...initialStateNotification };
    default:
      return state;
  }
};
