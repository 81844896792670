import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';

import {
  SystemDesignCanvasActionTypes,
  SaveDesignCanvasAction,
  SaveDesignCanvasErrorAction,
  SaveDesignCanvasSuccessAction,
} from 'state_management/actions/systemDesignCanvas/ActionTypes';

import {
  saveDesignCanvasSuccess,
  saveDesignCanvasError,
} from 'state_management/actions/systemDesignCanvas/systemDesignCanvasActions';
import { axiosInstance } from 'services/dataService';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  projectCanvasCompileAction,
  projectSaveSuccessAction,
} from 'state_management/actions/projects/projectsActions';
import { ProjectCanvasCompileAction, ProjectSaveSuccessAction } from 'state_management/actions/projects/ActionTypes';
import { newNotification } from 'state_management/actions/notification/notificationActions';
import { NewNotification } from 'state_management/actions/notification/ActionTypes';
import {
  deserializeDesignCanvasBlock,
  deserializeRappidDesignCanvasBlock,
} from 'utils/serializers/systemDesignCanvasSerializer';
import { IDesignCanvas, RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';
import { ICanvasData } from 'models/ICanvas';
import { AxiosResponse } from 'axios';
import i18n from 'i18n/config';
import { createLocalCanvas } from 'services/localProjectsService';
import { IEntity } from 'models/IEntity';
import { apiUri } from 'services/main_app';
import dataLayer from 'services/dataLayer';

export function* saveSaga(
  action: SaveDesignCanvasAction,
): Generator<
  | PutEffect<SaveDesignCanvasAction>
  | TakeEffect
  | CallEffect
  | SelectEffect
  | PutEffect<
      | SaveDesignCanvasSuccessAction
      | SaveDesignCanvasErrorAction
      | ProjectSaveSuccessAction
      | NewNotification
      | ProjectCanvasCompileAction
    >
> {
  try {
    const t = i18n.t.bind(i18n);

    const { data, canvasVersion, compileProject } = action.payload;
    const isVersion2 = canvasVersion === '2';

    const {
      authState: {
        currentAppType,
        userInfo: { isAnonymousUser },
      },
      projectsState: { currentProject: project },
      systemDesignCanvasState: { id: rappidCanvasId },
    } = (yield select((state: AppState) => state)) as AppState;

    if (isAnonymousUser) {
      createLocalCanvas(project.id, data as Array<RappidGraph<IEntity>>);

      yield put(saveDesignCanvasSuccess(project.id));
    } else {
      const payload = isVersion2
        ? {
            graph: (data as Array<RappidGraph>).map(deserializeRappidDesignCanvasBlock),
            canvasId: rappidCanvasId || undefined,
            project_id: project.id,
          }
        : deserializeDesignCanvasBlock(data as ICanvasData<IDesignCanvas>);

      // NOTE: Only Canvas v2 has create on frontend, old canvas with new endpoint is created auto on express
      const res = yield call(() =>
        !rappidCanvasId && isVersion2
          ? axiosInstance.post(apiUri(`/canvas/rappid/${project.id}`, 2), payload)
          : axiosInstance.patch(apiUri(`/canvas/design/${project.id}`, 2), {
              ...payload,
              version: canvasVersion,
            }),
      );

      const response = res as AxiosResponse<RappidGraph>;
      dataLayer.collectSaveDesignCanvas(project.id, response.data?.graph || []);
      yield put(saveDesignCanvasSuccess(response.data.id));
    }

    yield put(projectSaveSuccessAction({ ...project, currentAppType }));
    yield put(
      newNotification({
        type: 'success',
        message: t(
          'supernova:toasts.projectSaveSuccessfully',
          'Project has been successfully saved. You can now resolve the project.',
        ),
      }),
    );

    compileProject && (yield put(projectCanvasCompileAction('design', canvasVersion)));
  } catch (error) {
    yield put(saveDesignCanvasError(getErrorMessage(error) || 'Saving Design Canvas failed.'));
  }
}

export function* saveWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS, saveSaga);
}
