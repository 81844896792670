import { IPagination } from 'models/Pagination';
import {
  PluginsGetAllAction,
  PluginsGetAllSuccessAction,
  PluginsGetAllErrorAction,
  PluginsActionTypes,
  PluginById,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';
import { Plugin } from '../../reducers/plugins/Modals';

export const ENDPOINT_PLUGINS = 'plugin-metas';
export const DS_PLUGINS_ENDPOINT = `dataservice/${ENDPOINT_PLUGINS}`;

export const getPlugins = (): PluginsGetAllAction => ({
  type: PluginsActionTypes.GET_ALL_PLUGINS,
});

export const getPluginsSuccessAction = (pagination: IPagination, data: Array<Plugin>): PluginsGetAllSuccessAction => ({
  type: PluginsActionTypes.GET_ALL_PLUGINS_SUCCESS,
  payload: {
    data,
    pagination,
  },
});

export const getPluginsErrorAction = (payload: string): PluginsGetAllErrorAction => ({
  payload,
  type: PluginsActionTypes.GET_ALL_PLUGINS_FAIL,
});

export const getPluginById = (id: string): PluginById => ({
  type: PluginsActionTypes.GET_PLUGIN_BY_ID,
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_PLUGINS}/${id}`, 2),
    },
  },
});
