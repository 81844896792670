import {
  BomModificationRequest,
  BomModificationResponse,
  ReplacementTarget,
} from 'state_management/reducers/bomModification/Modals';

export interface RawBomModificationRequest {
  project_id: string;
  rules: Array<{
    design_block_id: string;
    selected_cubo_id: string;
    original_component_id: string;
    original_component_device: string;
    replaced_component_id: string;
    replaced_component_device: string;
    replacement_target: string;
    applied: boolean;
    processed_time?: string;
  }>;
}

export interface RawBomModificationResponse {
  id?: string;
  project_id: string;
  rules: Array<{
    design_block_id: string;
    selected_cubo_id: string;
    original_component_id: string;
    original_component_device: string;
    replaced_component_id: string;
    replaced_component_device: string;
    replacement_target: string;
    processed_time?: string;
    applied: boolean;
  }>;
}

export default class BomModificationSerializer {
  public static serializeRequestPayload(reqPayload: BomModificationRequest): RawBomModificationRequest {
    return {
      project_id: reqPayload.projectId,
      rules:
        reqPayload?.items?.map((i) => ({
          design_block_id: i.designBlockId,
          selected_cubo_id: i.selectedCuboId,
          original_component_id: i.originalComponentId,
          replaced_component_id: i.replacedComponentId,
          replacement_target: i.replacementTarget,
          applied: i.applied,
          replaced_component_device: i.replacedComponentDevice,
          original_component_device: i.originalComponentDevice,
          processed_time: i.processedTime,
        })) || [],
    };
  }

  public static deSerializeResponsePayload(resPayload: RawBomModificationResponse): BomModificationResponse {
    return {
      id: resPayload.id,
      projectId: resPayload.project_id,
      items: resPayload?.rules?.map((i) => ({
        applied: i.applied,
        designBlockId: i.design_block_id,
        selectedCuboId: i.selected_cubo_id,
        originalComponentId: i.original_component_id,
        replacedComponentId: i.replaced_component_id,
        replacedComponentDevice: i.replaced_component_device,
        originalComponentDevice: i.original_component_device,
        replacementTarget: i.replacement_target as ReplacementTarget,
        processedTime: i.processed_time,
      })),
    } as BomModificationResponse;
  }
}
