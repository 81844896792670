import { ForkEffect, put, PutEffect, takeLatest, SelectEffect } from 'redux-saga/effects';
import { ProjectsActionTypes, ProjectsGetAllAction } from 'state_management/actions/projects/ActionTypes';

import { getAllProjects } from 'state_management/actions/projects/projectsActions';

export function* refreshProjects(): Generator<SelectEffect | PutEffect<ProjectsGetAllAction>> {
  yield put(getAllProjects());
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ProjectsActionTypes.PROJECTS_ADD_SUCCESS,
      ProjectsActionTypes.PROJECTS_UPDATE_SUCCESS,
      ProjectsActionTypes.PROJECTS_CLONE_SUCCESS,
      ProjectsActionTypes.PROJECTS_DELETE_SUCCESS,
    ],
    refreshProjects,
  );
}
