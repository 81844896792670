import { BillOfMaterialsState } from './Modals';
import {
  BillOfMaterialActions,
  BillOfMaterialActionTypes,
  GetBomTransitionStateSuccess,
} from '../../actions/billOfMaterial/ActionTypes';

export const initialStatBillOfMaterialsView: BillOfMaterialsState = {
  pagination: {
    page: 0,
    perPage: 50,
    search: '',
    total: 100,
  },
  transitionState: {
    state: 'idle',
  },
  billOfMaterialsList: [],
  billOfMaterialsListState: 'idle',
  billOfMaterialsListError: '',
  billOfMaterialCreateState: 'idle',
  billOfMaterialCreateError: '',
  billOfMaterialUpdateState: 'idle',
  billOfMaterialUpdateError: '',
  billOfMaterialDeleteState: 'idle',
  billOfMaterialDeleteError: '',
  hierarchicalBom: [],
  initHierarchicalBomState: 'idle',
  initHierarchicalBomError: '',
};

export const billOfMaterialReducer = (
  state: BillOfMaterialsState = initialStatBillOfMaterialsView,
  action: BillOfMaterialActions,
): BillOfMaterialsState => {
  switch (action.type) {
    case BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS:
      return {
        ...state,
        billOfMaterialsListState: 'loading',
      };
    case BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS_SUCCESS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        billOfMaterialsListState: 'success',
        billOfMaterialsList: action.payload.data,
      };
    case BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS_FAIL:
      return {
        ...state,
        billOfMaterialsListState: 'error',
        billOfMaterialsListError: action.payload,
      };
    case BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL:
      return {
        ...state,
        billOfMaterialCreateState: 'loading',
      };
    case BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL_SUCCESS:
      return {
        ...state,
        billOfMaterialCreateState: 'success',
      };
    case BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL_FAIL:
      return {
        ...state,
        billOfMaterialCreateState: 'error',
        billOfMaterialCreateError: action.error.message,
      };
    case BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL:
      return {
        ...state,
        billOfMaterialUpdateState: 'loading',
      };
    case BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL_SUCCESS:
      return {
        ...state,
        billOfMaterialUpdateState: 'success',
      };
    case BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL_FAIL:
      return {
        ...state,
        billOfMaterialUpdateState: 'error',
        billOfMaterialUpdateError: action.error.message,
      };
    case BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL:
      return {
        ...state,
        billOfMaterialDeleteState: 'loading',
      };
    case BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL_SUCCESS:
      return {
        ...state,
        billOfMaterialDeleteState: 'success',
      };
    case BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL_FAIL:
      return {
        ...state,
        billOfMaterialDeleteState: 'error',
        billOfMaterialDeleteError: action.error.message,
      };
    case BillOfMaterialActionTypes.UPDATE_HIERARCHICAL_BOM:
      return {
        ...state,
        hierarchicalBom: action.payload,
      };
    case BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM:
      return {
        ...state,
        initHierarchicalBomState: 'loading',
      };
    case BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_SUCCESS:
      return {
        ...state,
        initHierarchicalBomState: 'success',
      };
    case BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_FAIL:
      return {
        ...state,
        initHierarchicalBomState: 'error',
        initHierarchicalBomError: action.payload,
      };

    case BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET:
      return {
        ...state,
        transitionState: {
          state: 'idle',
        },
      };

    case BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET_SUCCESS:
      return {
        ...state,
        transitionState: { state: (action as GetBomTransitionStateSuccess).payload },
      };

    case BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET_FAIL:
      return {
        ...state,
        transitionState: {
          state: 'idle',
        },
      };

    case BillOfMaterialActionTypes.TRANSITION_STATE_BOM_RESET:
      return {
        ...state,
        transitionState: {
          state: 'idle',
        },
      };

    case BillOfMaterialActionTypes.RESET_BILL_OF_MATERIAL:
      return {
        ...state,
        hierarchicalBom: [],
      };
    default:
      return state;
  }
};
