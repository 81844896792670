import {
  DashboardDisplayModes,
  DashboardSortingTypes,
  DashboardProjectTypes,
  Project,
  ProjectsState,
  ICanvasStates,
  ICadFilesState,
  ICompilationStates,
  ICanvasType,
  ProjectImportType,
  IPaymentFileType,
} from 'state_management/reducers/projects/Modals';
import { IPagination } from 'models/Pagination';
import { AppType } from 'state_management/reducers/auth/Modals';
import { CanvasVersionType } from 'state_management/reducers/systemDesignCanvas/Modals';

export enum ProjectsActionTypes {
  PROJECTS_UPDATE_DISPLAY_MODE = 'PROJECTS_UPDATE_DISPLAY_MODE',

  PROJECTS_UPDATE_SORTING_TYPE = 'PROJECTS_UPDATE_SORTING_TYPE',

  PROJECTS_UPDATE_SHARING_FILTER = 'PROJECTS_UPDATE_SHARING_FILTER',

  PROJECTS_SET_CURRENT = 'PROJECTS_SET_CURRENT',

  PROJECTS_GET_BY_ID = 'PROJECTS_GET_BY_ID',
  PROJECTS_GET_BY_ID_SUCCESS = 'PROJECTS_GET_BY_ID_SUCCESS',
  PROJECTS_GET_BY_ID_FAIL = 'PROJECTS_GET_BY_ID_FAIL',

  PROJECT_STATE_GET = 'PROJECT_STATE_GET',
  PROJECT_STATE_GET_SUCCESS = 'PROJECT_STATE_GET_SUCCESS',
  PROJECT_STATE_GET_FAIL = 'PROJECT_STATE_GET_FAIL',
  PROJECT_STATE_GET_CANCEL = 'PROJECT_STATE_GET_CANCEL',

  PROJECTS_GET_ALL = 'PROJECTS_GET_ALL',
  PROJECTS_GET_ALL_SUCCESS = 'PROJECTS_GET_ALL_SUCCESS',
  PROJECTS_GET_ALL_FAIL = 'PROJECTS_GET_ALL_FAIL',

  PROJECT_SAVE = 'PROJECT_SAVE',
  PROJECT_SAVE_SUCCESS = 'PROJECT_SAVE_SUCCESS',
  PROJECT_SAVE_FAIL = 'PROJECT_SAVE_FAIL',

  PROJECT_CANVAS_COMPILE = 'PROJECT_CANVAS_COMPILE',
  PROJECT_CANVAS_COMPILE_SUCCESS = 'PROJECT_CANVAS_COMPILE_SUCCESS',
  PROJECT_CANVAS_COMPILE_FAIL = 'PROJECT_CANVAS_COMPILE_FAIL',

  PROJECT_COMPILE_ISSUES = 'PROJECT_COMPILE_ISSUES',
  PROJECT_COMPILE_ISSUES_SUCCESS = 'PROJECT_COMPILE_ISSUES_SUCCESS',
  PROJECT_COMPILE_ISSUES_FAIL = 'PROJECT_COMPILE_ISSUES_FAIL',

  PROJECTS_ADD = 'PROJECTS_ADD',
  PROJECTS_ADD_SUCCESS = 'PROJECTS_ADD_SUCCESS',
  PROJECTS_ADD_FAIL = 'PROJECTS_ADD_FAIL',

  PROJECTS_IMAGE_UPDATE = 'PROJECTS_IMAGE_UPDATE',
  PROJECTS_IMAGE_UPDATE_SUCCESS = 'PROJECTS_IMAGE_UPDATE_SUCCESS',
  PROJECTS_IMAGE_UPDATE_FAIL = 'PROJECTS_IMAGE_UPDATE_FAIL',

  PROJECTS_UPDATE = 'PROJECTS_UPDATE',
  PROJECTS_UPDATE_SUCCESS = 'PROJECTS_UPDATE_SUCCESS',
  PROJECTS_UPDATE_FAIL = 'PROJECTS_UPDATE_FAIL',

  PROJECTS_DELETE = 'PROJECTS_DELETE',
  PROJECTS_DELETE_SUCCESS = 'PROJECTS_DELETE_SUCCESS',
  PROJECTS_DELETE_FAIL = 'PROJECTS_DELETE_FAIL',

  PROJECTS_CLONE = 'PROJECTS_CLONE',
  PROJECTS_CLONE_SUCCESS = 'PROJECTS_CLONE_SUCCESS',
  PROJECTS_CLONE_FAIL = 'PROJECTS_CLONE_FAIL',

  PROJECT_DOWNLOAD_FILE_SCHEMATICS = 'PROJECT_DOWNLOAD_FILE_SCHEMATICS',
  PROJECT_DOWNLOAD_FILE_SCHEMATICS_SUCCESS = 'PROJECT_DOWNLOAD_FILE_SCHEMATICS_SUCCESS',
  PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL = 'PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL',
  PROJECT_DOWNLOAD_FILE_SCHEMATICS_PAYMENT = 'PROJECT_DOWNLOAD_FILE_SCHEMATICS_PAYMENT',
  PROJECT_CLEAR_PAYMENT = 'PROJECT_CLEAR_PAYMENT',

  PROJECT_DOWNLOAD_FILE_LAYOUT = 'PROJECT_DOWNLOAD_FILE_LAYOUT',
  PROJECT_DOWNLOAD_FILE_LAYOUT_SUCCESS = 'PROJECT_DOWNLOAD_FILE_LAYOUT_SUCCESS',
  PROJECT_DOWNLOAD_FILE_LAYOUT_FAIL = 'PROJECT_DOWNLOAD_FILE_LAYOUT_FAIL',
  PROJECT_DOWNLOAD_FILE_LAYOUT_PAYMENT = 'PROJECT_DOWNLOAD_FILE_LAYOUT_PAYMENT',

  PROJECT_DOWNLOAD_FILE_BOM = 'PROJECT_DOWNLOAD_FILE_BOM',
  PROJECT_DOWNLOAD_FILE_BOM_SUCCESS = 'PROJECT_DOWNLOAD_FILE_BOM_SUCCESS',
  PROJECT_DOWNLOAD_FILE_BOM_FAIL = 'PROJECT_DOWNLOAD_FILE_BOM_FAIL',

  PROJECT_DOWNLOAD_FILE_PDF = 'PROJECT_DOWNLOAD_FILE_PDF',
  PROJECT_DOWNLOAD_FILE_PDF_SUCCESS = 'PROJECT_DOWNLOAD_FILE_PDF_SUCCESS',
  PROJECT_DOWNLOAD_FILE_PDF_FAIL = 'PROJECT_DOWNLOAD_FILE_PDF_FAIL',

  PROJECT_IMPORT = 'PROJECT_IMPORT',
  PROJECT_IMPORT_SUCCESS = 'PROJECT_IMPORT_SUCCESS',
  PROJECT_IMPORT_FAIL = 'PROJECT_IMPORT_FAIL',

  PROJECT_DOWNLOAD_FILE_CANCEL = 'PROJECT_DOWNLOAD_FILE_CANCEL',

  PROJECTS_CLEAR = 'PROJECTS_CLEAR',

  PROJECTS_RESET = 'PROJECTS_RESET',

  PROJECT_STATE_CHANGE = 'PROJECT_STATE_CHANGE',

  PDF_DOWNLOAD_ICON_LOADING_FAIL = 'PDF_DOWNLOAD_ICON_LOADING_FAIL',
  SCHEMATICS_DOWNLOAD_ICON_LOADING_FAIL = 'SCHEMATICS_DOWNLOAD_ICON_LOADING_FAIL',
  BOM_DOWNLOAD_ICON_LOADING_FAIL = 'BOM_DOWNLOAD_ICON_LOADING_FAIL',
  LAYOUT_DOWNLOAD_ICON_LOADING_FAIL = 'LAYOUT_DOWNLOAD_ICON_LOADING_FAIL',
}

//
export interface PdfDownloadIConLoadingFailAction {
  type: ProjectsActionTypes.PDF_DOWNLOAD_ICON_LOADING_FAIL;
}

export interface SchematicsDownloadIconLoadingFailAction {
  type: ProjectsActionTypes.SCHEMATICS_DOWNLOAD_ICON_LOADING_FAIL;
}

export interface BomDownloadIconLoadingFailAction {
  type: ProjectsActionTypes.BOM_DOWNLOAD_ICON_LOADING_FAIL;
}

export interface LayoutDownloadIconLoadingFailACtion {
  type: ProjectsActionTypes.LAYOUT_DOWNLOAD_ICON_LOADING_FAIL;
}

// ============================================
export interface ProjectsResetStateAction {
  type: ProjectsActionTypes.PROJECTS_RESET;
}
// ============================================ PROJECTS_UPDATE_DISPLAY_MODE

export interface ProjectsUpdateDisplayModeAction {
  type: ProjectsActionTypes.PROJECTS_UPDATE_DISPLAY_MODE;
  payload: DashboardDisplayModes;
}

// ============================================ PROJECTS_UPDATE_SORTING_TYPE

export interface ProjectsUpdateSortTypeAction {
  type: ProjectsActionTypes.PROJECTS_UPDATE_SORTING_TYPE;
  payload: DashboardSortingTypes;
}

// ============================================ PROJECTS_UPDATE_SHARING_FILTER

export interface ProjectsUpdateSharingFilterAction {
  type: ProjectsActionTypes.PROJECTS_UPDATE_SHARING_FILTER;
  payload: DashboardProjectTypes;
}

// ============================================ PROJECTS_SET_CURRENT

export interface ProjectsSetCurrentAction {
  type: ProjectsActionTypes.PROJECTS_SET_CURRENT;
  payload: Partial<Project>;
}

// ============================================ PROJECTS_GET_PROJECT

export interface ProjectsGetByIdAction {
  type: ProjectsActionTypes.PROJECTS_GET_BY_ID;
  payload: string;
}
export interface ProjectsGetByIdSuccessAction {
  type: ProjectsActionTypes.PROJECTS_GET_BY_ID_SUCCESS;
  payload: Project;
}
export interface ProjectsGetByIdErrorAction {
  type: ProjectsActionTypes.PROJECTS_GET_BY_ID_FAIL;
  payload: string;
}

// ============================================ PROJECT_STATE_GET

export interface ProjectStateGetAction {
  payload: { initialState?: ICanvasType; canvasVersion?: '1' | '2' };
  type: ProjectsActionTypes.PROJECT_STATE_GET;
}

export interface ProjectStateGetSuccessAction {
  payload: {
    canvasStates: ICanvasStates;
    cadFilesState: ICadFilesState;
    compilerState: Raw.CompilerState;
    compilationStates: ICompilationStates;
    projectCompiledState: Pick<
      ProjectsState,
      'boardCompileRequest' | 'designCompileRequest' | 'boardCompileRequestError' | 'designCompileRequestError'
    >;
  };
  type: ProjectsActionTypes.PROJECT_STATE_GET_SUCCESS;
}

export interface ProjectStateGetErrorAction {
  payload: string;
  type: ProjectsActionTypes.PROJECT_STATE_GET_FAIL;
}

export interface ProjectStateGetCancelAction {
  type: ProjectsActionTypes.PROJECT_STATE_GET_CANCEL;
}

// ============================================ PROJECTS_GET_ALL_PROJECTS

export interface ProjectsGetAllAction {
  type: ProjectsActionTypes.PROJECTS_GET_ALL;
  payload: Partial<IPagination>;
}

export interface ProjectsGetAllSuccessAction {
  type: ProjectsActionTypes.PROJECTS_GET_ALL_SUCCESS;
  payload: { data: Array<Project>; pagination: IPagination };
}

export interface ProjectsGetAllErrorAction {
  type: ProjectsActionTypes.PROJECTS_GET_ALL_FAIL;
  payload: string;
}

// ============================================ PROJECT_SAVE

export interface ProjectSaveAction {
  payload: Project;
  type: ProjectsActionTypes.PROJECT_SAVE;
}

export interface ProjectSaveSuccessAction {
  payload: Project & { currentAppType: AppType };
  type: ProjectsActionTypes.PROJECT_SAVE_SUCCESS;
}

export interface ProjectSaveErrorAction {
  payload: string;
  type: ProjectsActionTypes.PROJECT_SAVE_FAIL;
}

// ============================================ PROJECT_CANVAS_COMPILE

export interface ProjectCanvasCompileAction {
  payload: { canvas: ICanvasType; version?: CanvasVersionType };
  type: ProjectsActionTypes.PROJECT_CANVAS_COMPILE;
}

export interface ProjectCanvasCompileSuccessAction {
  type: ProjectsActionTypes.PROJECT_CANVAS_COMPILE_SUCCESS;
}

export interface ProjectCanvasCompileErrorAction {
  payload: Pick<
    ProjectsState,
    'boardCompileRequest' | 'designCompileRequest' | 'boardCompileRequestError' | 'designCompileRequestError'
  >;
  type: ProjectsActionTypes.PROJECT_CANVAS_COMPILE_FAIL;
}

// ============================================ PROJECT_CANVAS_COMPILE_ISSUES

export interface ProjectCompileIssuesAction {
  payload: { projectId: string; version?: CanvasVersionType };
  type: ProjectsActionTypes.PROJECT_COMPILE_ISSUES;
}

export interface ProjectCompileIssuesSuccessAction {
  payload: Raw.ProjectIssues;
  type: ProjectsActionTypes.PROJECT_COMPILE_ISSUES_SUCCESS;
}

export interface ProjectCompileIssuesErrorAction {
  payload: string;
  type: ProjectsActionTypes.PROJECT_COMPILE_ISSUES_FAIL;
}

// ============================================ PROJECTS_ADD_PROJECT

export interface ProjectsAddAction {
  payload: Partial<Project>;
  type: ProjectsActionTypes.PROJECTS_ADD;
}

export interface ProjectsAddSuccessAction {
  type: ProjectsActionTypes.PROJECTS_ADD_SUCCESS;
  payload: Partial<Raw.Project>;
}

export interface ProjectsAddErrorAction {
  type: ProjectsActionTypes.PROJECTS_ADD_FAIL;
  payload: string;
}

// ============================================ PROJECTS_IMAGE_UPDATE

export interface ProjectsImageUpdateAction {
  payload: {
    data: string;
  };
  type: ProjectsActionTypes.PROJECTS_IMAGE_UPDATE;
}

export interface ProjectsImageUpdateSuccessAction {
  type: ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_SUCCESS;
}

export interface ProjectsImageUpdateErrorAction {
  payload: string;
  type: ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_FAIL;
}

// ============================================ PROJECTS_UPDATE_PROJECT

export interface ProjectsUpdateAction {
  type: ProjectsActionTypes.PROJECTS_UPDATE;
  payload: Partial<Project>;
}
export interface ProjectsUpdateSuccessAction {
  type: ProjectsActionTypes.PROJECTS_UPDATE_SUCCESS;
  payload: Project;
}
export interface ProjectsUpdateErrorAction {
  type: ProjectsActionTypes.PROJECTS_UPDATE_FAIL;
  payload: string;
}

// ============================================ PROJECTS_DELETE

export interface ProjectsDeleteAction {
  type: ProjectsActionTypes.PROJECTS_DELETE;
  payload: Partial<Project>;
}
export interface ProjectsDeleteSuccessAction {
  type: ProjectsActionTypes.PROJECTS_DELETE_SUCCESS;
  payload: Partial<Project>;
}
export interface ProjectsDeleteErrorAction {
  type: ProjectsActionTypes.PROJECTS_DELETE_FAIL;
  payload: string;
}

// ============================================ PROJECT_DOWNLOAD_FILE SCHEMATICS,LAYOUT,BOM
export interface ProjectDownloadFileSchematicsAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS;
  unlock: boolean;
}
export interface ProjectDownloadFileSchematicsSuccessAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_SUCCESS;
}
export interface ProjectDownloadFileSchematicsErrorAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL;
  payload: string;
}
export interface ProjectDownloadFileSchematicsPaymentAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_PAYMENT;
  numComponents: number;
}
export interface ProjectsClearPaymentAction {
  type: ProjectsActionTypes.PROJECT_CLEAR_PAYMENT;
}
export interface ProjectDownloadFileLayoutAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT;
  unlock: boolean;
}
export interface ProjectDownloadFileLayoutSuccessAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_SUCCESS;
}
export interface ProjectDownloadFileLayoutErrorAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_FAIL;
  payload: string;
}
export interface ProjectDownloadFileLayoutPaymentAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_PAYMENT;
  numComponents: number;
  fileType: IPaymentFileType;
}
export interface ProjectDownloadFileBomAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM;
}
export interface ProjectDownloadFileBomSuccessAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_SUCCESS;
}
export interface ProjectDownloadFileBomErrorAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_FAIL;
  payload: string;
}
export interface ProjectDownloadFilePdfAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF;
}
export interface ProjectDownloadFilePdfSuccessAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_SUCCESS;
}
export interface ProjectDownloadFilePdfErrorAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_FAIL;
  payload: string;
}
export interface ProjectDownloadFileCancelAction {
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_CANCEL;
}

// ============================================ PROJECT_IMPORT
export interface ProjectImportAction {
  payload: {
    type: ProjectImportType;
    data: string | Array<Partial<Project>>;
  };
  type: ProjectsActionTypes.PROJECT_IMPORT;
}
export interface ProjectImportSuccessAction {
  payload: Project;
  type: ProjectsActionTypes.PROJECT_IMPORT_SUCCESS;
}
export interface ProjectImportErrorAction {
  payload: string;
  type: ProjectsActionTypes.PROJECT_IMPORT_FAIL;
}

// ============================================ PROJECTS_CLEAR

export interface ProjectClearAction {
  payload: Array<keyof ProjectsState>;
  type: ProjectsActionTypes.PROJECTS_CLEAR;
}

// ============================================ PROJECTS_CLONE
export interface ProjectsCloneAction {
  type: ProjectsActionTypes.PROJECTS_CLONE;
  payload: Partial<Project>;
}

export interface ProjectsCloneSuccessAction {
  type: ProjectsActionTypes.PROJECTS_CLONE_SUCCESS;
  payload: Project;
}

export interface ProjectsCloneErrorAction {
  type: ProjectsActionTypes.PROJECTS_CLONE_FAIL;
  payload: string;
}

// ============================================= PROJECT_STATE
export interface ProjectStateChangeAction {
  type: ProjectsActionTypes.PROJECT_STATE_CHANGE;
  payload: { projectId: string; state: string; error?: { error_msg: string } };
}
// ============================================ ALL

export type ProjectsActions =
  | ProjectsUpdateDisplayModeAction
  | ProjectsUpdateSortTypeAction
  | ProjectsUpdateSharingFilterAction
  | ProjectsSetCurrentAction
  | ProjectsGetByIdAction
  | ProjectsGetByIdSuccessAction
  | ProjectsGetByIdErrorAction
  | ProjectStateGetAction
  | ProjectStateGetSuccessAction
  | ProjectStateGetErrorAction
  | ProjectStateGetCancelAction
  | ProjectsGetAllAction
  | ProjectsGetAllSuccessAction
  | ProjectsGetAllErrorAction
  | ProjectSaveAction
  | ProjectSaveSuccessAction
  | ProjectSaveErrorAction
  | ProjectCanvasCompileAction
  | ProjectCanvasCompileSuccessAction
  | ProjectCanvasCompileErrorAction
  | ProjectCompileIssuesAction
  | ProjectCompileIssuesSuccessAction
  | ProjectCompileIssuesErrorAction
  | ProjectsAddAction
  | ProjectsAddSuccessAction
  | ProjectsAddErrorAction
  | ProjectsImageUpdateAction
  | ProjectsImageUpdateSuccessAction
  | ProjectsImageUpdateErrorAction
  | ProjectsUpdateAction
  | ProjectsUpdateSuccessAction
  | ProjectsUpdateErrorAction
  | ProjectsDeleteAction
  | ProjectsDeleteSuccessAction
  | ProjectsDeleteErrorAction
  | ProjectDownloadFileSchematicsAction
  | ProjectDownloadFileSchematicsSuccessAction
  | ProjectDownloadFileSchematicsErrorAction
  | ProjectDownloadFileSchematicsPaymentAction
  | ProjectDownloadFileLayoutAction
  | ProjectDownloadFileLayoutSuccessAction
  | ProjectDownloadFileLayoutErrorAction
  | ProjectDownloadFileLayoutPaymentAction
  | ProjectsClearPaymentAction
  | ProjectDownloadFileBomAction
  | ProjectDownloadFileBomSuccessAction
  | ProjectDownloadFileBomErrorAction
  | ProjectDownloadFilePdfAction
  | ProjectDownloadFilePdfSuccessAction
  | ProjectDownloadFilePdfErrorAction
  | ProjectDownloadFileCancelAction
  | ProjectImportAction
  | ProjectImportSuccessAction
  | ProjectImportErrorAction
  | ProjectClearAction
  | ProjectsCloneAction
  | ProjectsCloneSuccessAction
  | ProjectsCloneErrorAction
  | ProjectsResetStateAction
  | ProjectStateChangeAction
  | PdfDownloadIConLoadingFailAction
  | BomDownloadIconLoadingFailAction
  | SchematicsDownloadIconLoadingFailAction
  | LayoutDownloadIconLoadingFailACtion;
