import {
  SpecificationActionTypes,
  SpecificationActions,
  UpdateSpecificationErrorAction,
  DeleteSpecificationErrorAction,
  CreateSpecificationErrorAction,
  CloneSpecificationErrorAction,
} from 'state_management/actions/specifications/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const specificationsCreateToasts = (action: SpecificationActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Specification Type is being created, please wait...',
      success: 'Specification Type has been successfully created.',
      error:
        getErrorMessage((action as CreateSpecificationErrorAction).error) ||
        'Creating Specification Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const specificationsUpdateToasts = (action: SpecificationActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Specification Type is being updated, please wait...',
      success: 'Specification Type has been successfully updated.',
      error:
        getErrorMessage((action as UpdateSpecificationErrorAction).error) ||
        'Updating Specification Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const specificationsCloneToasts = (action: SpecificationActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Specification Type is being cloned, please wait...',
      success: 'Specification Type has been successfully cloned.',
      error:
        getErrorMessage((action as CloneSpecificationErrorAction).error) ||
        'Cloning Specification Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const specificationsDeleteToasts = (action: SpecificationActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Specification Type is being deleted, please wait...',
      success: 'Specification Type has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteSpecificationErrorAction).error) ||
        'Deleting Specification Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsSaga(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        SpecificationActionTypes.CREATE_SPECIFICATION,
        SpecificationActionTypes.CREATE_SPECIFICATION_SUCCESS,
        SpecificationActionTypes.CREATE_SPECIFICATION_FAIL,
      ],
      specificationsCreateToasts,
    ),
    takeEvery(
      [
        SpecificationActionTypes.UPDATE_SPECIFICATION,
        SpecificationActionTypes.UPDATE_SPECIFICATION_SUCCESS,
        SpecificationActionTypes.UPDATE_SPECIFICATION_FAIL,
      ],
      specificationsUpdateToasts,
    ),
    takeEvery(
      [
        SpecificationActionTypes.CLONE_SPECIFICATION,
        SpecificationActionTypes.CLONE_SPECIFICATION_SUCCESS,
        SpecificationActionTypes.CLONE_SPECIFICATION_FAIL,
      ],
      specificationsCloneToasts,
    ),
    takeEvery(
      [
        SpecificationActionTypes.DELETE_SPECIFICATION,
        SpecificationActionTypes.DELETE_SPECIFICATION_SUCCESS,
        SpecificationActionTypes.DELETE_SPECIFICATION_FAIL,
      ],
      specificationsDeleteToasts,
    ),
  ]);
}
