import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  && {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    width: min(40vw, 700px);
  }
`;

export const SearchContainer = styled.div`
  && {
    & .MuiTextField-root {
      border-radius: 4px;
    }
  }
`;

export const CommandsContainer = styled.div`
  && {
    max-height: 50vh;
    overflow-y: auto;
    box-sizing: border-box;
  }
`;

export const CommandsItem = styled.div`
  && {
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    padding: ${({ theme }): number => theme.spacing.screenContent}px;
    margin: ${({ theme }): number => theme.spacing.extraSmall}px 0;
    background-color: ${({ theme }): string => theme.palette.white};

    &:focus {
      background-color: ${({ theme }): string => theme.palette.border};
    }

    & > p {
      margin: ${({ theme }): number => theme.spacing.extraSmall}px 0;
    }
  }
`;

export const useStyles = makeStyles(() => ({
  container: {
    marginTop: 50,
    alignItems: 'flex-start',
  },
  paperScrollPaper: {
    width: '100%',
    background: 'transparent',
    boxShadow: 'none',
  },
}));
