import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18n/config';

import { apiUri } from 'services/main_app';
import {
  GetResourceSharingErrorAction,
  GetResourceSharingSuccessAction,
  SharingActionTypes,
  UpdateResourceSharingAction,
} from 'state_management/actions/sharing/ActionTypes';
import {
  getResourceSharingErrorAction,
  getResourceSharingSuccessAction,
} from 'state_management/actions/sharing/sharingActions';
import { getErrorMessage } from 'utils/getErrorMessage';
import { axiosInstance } from 'services/dataService';
import { AppState } from 'state_management/AppState';
import { translateIdsToEmail } from './utils/emailsTranslation';

export function* getResourceSharingSaga(
  action: UpdateResourceSharingAction,
): Generator<
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetResourceSharingSuccessAction | GetResourceSharingErrorAction>
  | CallEffect
> {
  const { resourceEndpoint, resourceId } = action.payload;

  const t = i18n.t.bind(i18n);
  try {
    const {
      authState: { featurePermissions },
    } = (yield select((state) => state)) as AppState;

    let { data } = (yield call(() =>
      axiosInstance.get(apiUri(`${resourceEndpoint}/${resourceId}/share`, 2)),
    )) as AxiosResponse<Raw.IResourceSharing>;

    if (featurePermissions.SHARE_WITH_USER.read && data.users) {
      data = {
        ...data,
        users: (yield call(() => translateIdsToEmail(data.users))) as Raw.IResourceShareItems,
      };
    }

    yield put(getResourceSharingSuccessAction(resourceId, data));
  } catch (error) {
    yield put(
      getResourceSharingErrorAction(
        getErrorMessage(error as AxiosError) || t('orbit:getSharingError', 'Fetching sharing details failed.'),
      ),
    );
  }
}

export function* getResourceSharingWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(SharingActionTypes.GET_RESOURCE_SHARING, getResourceSharingSaga);
}
