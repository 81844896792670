import styled from 'styled-components';

export const Container = styled.div`
  && {
    display: flex;
    align-items: center;
    color: ${({ theme }): string => theme.palette.text};
    font-size: ${({ theme }): string => theme.fontSize.small};
    width: 100%;
  }
`;

export const Content = styled.p`
  && {
    margin: 0;
  }
`;
