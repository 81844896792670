import { PutEffect, ForkEffect, CallEffect, put, takeEvery, select, SelectEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  SubSystemCanvasActionTypes,
  SaveSelectedAlternativeAction,
  SaveSelectedAlternativeErrorAction,
  SaveSelectedAlternativeSuccessAction,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import {
  saveSelectedAlternativeErrorAction,
  saveSelectedAlternativeSuccessAction,
} from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';

import { getErrorMessage } from 'utils/getErrorMessage';
import { AppState } from 'state_management/AppState';
import { RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';
import { IBlockV2 } from 'models/ICanvas';
import { saveDesignCanvas } from 'state_management/actions/systemDesignCanvas/systemDesignCanvasActions';
import { SaveDesignCanvasAction } from 'state_management/actions/systemDesignCanvas/ActionTypes';
import { updateMatches } from 'utils/subSystems/subSystemUtils';

export function* updateSelectedAlternativeSaga(
  action: SaveSelectedAlternativeAction,
): Generator<
  | SelectEffect
  | CallEffect<AxiosResponse | undefined>
  | PutEffect<SaveDesignCanvasAction | SaveSelectedAlternativeSuccessAction | SaveSelectedAlternativeErrorAction>
> {
  try {
    const { subSystemBlockId, alternativeId } = action.payload;

    const {
      systemDesignCanvasState: { graph },
      subSystemCanvasState: { selectedSystemDesignBlock },
    } = (yield select((state: AppState) => state)) as AppState;

    // NOTE: Update selected SubSystem in SystemDesign Canvas graph
    const data = (graph as Array<RappidGraph<IBlockV2>>).map((s) =>
      s.id === selectedSystemDesignBlock?.id
        ? {
            ...s,
            data: {
              ...s.data,
              matches: s.data.matches ? updateMatches(s.data.matches, alternativeId, subSystemBlockId) : {},
            },
          }
        : s,
    );

    yield put(saveDesignCanvas(data, '2'));

    yield put(saveSelectedAlternativeSuccessAction());
  } catch (error) {
    yield put(
      saveSelectedAlternativeErrorAction(getErrorMessage(error as AxiosError) || 'Saving Sub System Canvas failed.'),
    );
  }
}

export function* updateSelectedAlternativeWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE, updateSelectedAlternativeSaga);
}
