// NOTE Due to significant styles changes required, using components directly rather our Dialog comp
import MuiDialog from '@material-ui/core/Dialog';
import { useShortcuts } from 'components/Shortcuts';
import TextInput from 'components/TextInput';
import Fuse from 'fuse.js';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { BLOCKS_LIBRARY_ROUTES, MAIN_ROUTES } from 'routes/getRoutes';
import { FEATURES } from 'state_management/actions/features/features';
import { addProject } from 'state_management/actions/projects/projectsActions';
import { ActionCreatorType } from 'utils/dispatchHandler';
import { IProps } from './IProps';
import { ICommand } from './Modals';
import { CommandsContainer, CommandsItem, Container, SearchContainer, useStyles } from './styles';

const COMMAND_CODES = {
  all: '',
  gotoPage: 'Go to page: ',
  createProject: 'Create Project: ',
  toggleFeature: 'Toggle feature: ',
} as const;

const fuseInstance = new Fuse([] as Array<ICommand>, { keys: ['title'] });

const CommandPalette = ({ dispatch }: IProps): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);

  const [open, setOpen] = useState(false);
  const [selectedCommand, setSelectedCommand] = useState('');
  const [commandSearch, setCommandSearch] = useState('');

  const handleToggleOpen = (): void => {
    setOpen(!open);
  };

  const handleCommandSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newSearch = event.target.value;
    setCommandSearch(newSearch);

    !newSearch && setSelectedCommand('');
  };

  const handleCommandSelect = (command: string) => (): void => {
    setCommandSearch(command);
    setSelectedCommand(command);
  };

  const handleGotoPage = (commandValue: string) => (): void => {
    setOpen(false);

    navigate(commandValue);
  };

  const handleCreateProject = (commandValue: string) => (): void => {
    if (commandValue) {
      setOpen(false);

      dispatch(addProject({ title: commandValue }));
    }
  };

  const handleFeatureToggle = (action: ActionCreatorType) => (): void => {
    setOpen(false);

    dispatch(action());
  };

  const commands: Array<ICommand> = [
    {
      title: COMMAND_CODES.gotoPage,
      handler: handleCommandSelect(COMMAND_CODES.gotoPage),
    },
    {
      title: COMMAND_CODES.createProject,
      handler: handleCommandSelect(COMMAND_CODES.createProject),
    },
    {
      title: COMMAND_CODES.toggleFeature,
      handler: handleCommandSelect(COMMAND_CODES.toggleFeature),
    },
  ];

  const subCommands: Record<string, Array<ICommand>> = {
    [COMMAND_CODES.all]: commands,
    [COMMAND_CODES.gotoPage]: [
      ...Object.values(BLOCKS_LIBRARY_ROUTES).map((r) => ({
        title: `${COMMAND_CODES.gotoPage} Orbit > ${r.label}`,
        handler: handleGotoPage(r.route),
      })),
      ...Object.values(MAIN_ROUTES).map((r) => ({
        title: `${COMMAND_CODES.gotoPage} ${r.label}`,
        handler: handleGotoPage(r.route),
      })),
    ],
    [COMMAND_CODES.createProject]: [
      {
        title: commandSearch || COMMAND_CODES.createProject,
        handler: handleCreateProject(commandSearch.replace(COMMAND_CODES.createProject, '')),
      },
    ],
    [COMMAND_CODES.toggleFeature]: [
      ...Object.values(FEATURES).map((r) => ({
        title: `${COMMAND_CODES.toggleFeature} ${r.label}`,
        handler: handleFeatureToggle(r.action as ActionCreatorType),
      })),
    ],
  };

  // NOTE: Improve usage as to not create new instance on search change
  const filteredCommands = useMemo(() => {
    fuseInstance.setCollection(subCommands[selectedCommand]);

    return !commandSearch ? subCommands[selectedCommand] : fuseInstance.search(commandSearch).map((r) => r.item);
  }, [subCommands, selectedCommand, commandSearch]);

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const currentCommand = filteredCommands;
    const currentSubCommand =
      currentCommand.find((c) => c.title.toLowerCase().includes(commandSearch.toLowerCase())) || currentCommand[0];

    if (event.key === 'Enter') {
      currentSubCommand?.handler();

      !selectedCommand && inputRef.current?.focus();
    }

    if (event.key === 'ArrowDown') {
      const commandTitle = currentSubCommand?.title;
      document.getElementById(commandTitle)?.focus();
    }
  };

  const handleCommandKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    const currentFocusIndex = (document.activeElement as HTMLDivElement | null)?.tabIndex || 0;
    const currentCommand = filteredCommands;

    if (event.key === 'Enter') {
      currentCommand[currentFocusIndex]?.handler();

      !selectedCommand && inputRef.current?.focus();
    }

    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      const increment = event.key === 'ArrowUp' ? -1 : 1;

      // NOTE: % .length loops index based get around on the array
      const nextFocusIndex = (currentFocusIndex + increment) % currentCommand.length;
      const commandTitle = currentCommand[nextFocusIndex]?.title;

      document.getElementById(commandTitle)?.focus();
    }
  };

  useShortcuts('app-commands-palette-id', 'Command Palette', [
    {
      keys: [
        {
          // Firefox
          key: '!',
          ctrl: true,
          shift: true,
        },
        {
          // Mac
          key: 'P',
          shift: true,
          alt: true,
        },
        {
          // Chrome
          key: 'P',
          ctrl: true,
          shift: true,
        },
      ],
      description: 'Toggle Command Palette',
      handler: handleToggleOpen,
    },
  ]);

  return (
    <Container>
      <MuiDialog
        open={open}
        scroll="paper"
        transitionDuration={0}
        onClose={handleToggleOpen}
        classes={{ container: classes.container, paperScrollPaper: classes.paperScrollPaper }}
      >
        <SearchContainer>
          <TextInput
            autoFocus
            ref={inputRef}
            id="command-search"
            value={commandSearch}
            onChange={handleCommandSearch}
            onKeyDown={handleInputKeyDown}
            placeholder="Start typing..."
          />
        </SearchContainer>
        <CommandsContainer>
          {filteredCommands.map((c, index) => (
            <CommandsItem
              id={c.title}
              key={c.title}
              tabIndex={index}
              onClick={c.handler}
              onKeyDown={handleCommandKeyDown}
            >
              <p>{c.title}</p>
            </CommandsItem>
          ))}
        </CommandsContainer>
      </MuiDialog>
    </Container>
  );
};

export default CommandPalette;
