import { apiUri } from 'services/main_app';
import { GetAllPhysicalQuantitiesAction, UnitConverterActionTypes } from './ActionTypes';

export const getAllPhysicalQuantities = (): GetAllPhysicalQuantitiesAction => ({
  type: UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES,
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri('/unit-converter/quantities/units', 2),
    },
  },
});
