import { PutEffect, ForkEffect, CallEffect, put, takeEvery, call, select, SelectEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  SubSystemCanvasActionTypes,
  GetAllSubSystemsAction,
  GetAllSubSystemsErrorAction,
  GetAllSubSystemsSuccessAction,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import {
  ENDPOINT_SUB_SYSTEM_CANVAS,
  getAllSubSystemsErrorAction,
  getAllSubSystemsSuccessAction,
} from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';

import { axiosInstance } from 'services/dataService';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';
import { apiUri } from 'services/main_app';
import { AppState } from 'state_management/AppState';
import { combineSearchQueries, getSearchQuery, TypeSearch } from 'utils/searchHelper';
import { escapeSearch } from 'utils/urlUtils';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';

export function* getAllSaga(
  action: GetAllSubSystemsAction,
): Generator<
  | SelectEffect
  | CallEffect<AxiosResponse<Array<RawSubSystemCanvas.Payload>>>
  | PutEffect<GetAllSubSystemsAction | GetAllSubSystemsSuccessAction | GetAllSubSystemsErrorAction>
> {
  try {
    const {
      subSystemCanvasState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const subSystemSearchSchema: TypeSearch = {
      name: 'value',
      description: 'value',
      info: 'value',
      functions: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), subSystemSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      action?.payload?.selectedWorkspaces || userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_SUB_SYSTEM_CANVAS}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2),
      ),
    )) as AxiosResponse<Array<RawSubSystemCanvas.Payload>>;

    yield put(
      getAllSubSystemsSuccessAction(
        { ...getPaginationFromResponse(res), search: search || '' },
        res.data.map(serializeSubSystemCanvas) || [],
      ),
    );
  } catch (error) {
    yield put(getAllSubSystemsErrorAction(getErrorMessage(error as AxiosError) || 'Fetching Sub Systems failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS, getAllSaga);
}
