import { ResourceDomains } from 'state_management/reducers/sharing/Modals';

export enum SharingActionTypes {
  GET_RESOURCE_SHARING = 'GET_RESOURCE_SHARING',
  GET_RESOURCE_SHARING_SUCCESS = 'GET_RESOURCE_SHARING_SUCCESS',
  GET_RESOURCE_SHARING_FAIL = 'GET_RESOURCE_SHARING_FAIL',

  UPDATE_RESOURCE_SHARING = 'UPDATE_RESOURCE_SHARING',
  UPDATE_RESOURCE_SHARING_SUCCESS = 'UPDATE_RESOURCE_SHARING_SUCCESS',
  UPDATE_RESOURCE_SHARING_FAIL = 'UPDATE_RESOURCE_SHARING_FAIL',
}

export interface UpdateResourceSharingActionPayload {
  resourceId: string;
  resourceDomains: ResourceDomains;
  resourceEndpoint: string;
}

// ============================================ GET_RESOURCE_SHARING

export interface GetResourceSharingAction {
  type: SharingActionTypes.GET_RESOURCE_SHARING;
  payload: Omit<UpdateResourceSharingActionPayload, 'resourceDomains'>;
}

export interface GetResourceSharingSuccessAction {
  type: SharingActionTypes.GET_RESOURCE_SHARING_SUCCESS;
  payload: {
    resourceId: string;
    data: Raw.IResourceSharing;
  };
}

export interface GetResourceSharingErrorAction {
  type: SharingActionTypes.GET_RESOURCE_SHARING_FAIL;
  payload: string;
}

// ============================================ UPDATE_RESOURCE_SHARING

export interface UpdateResourceSharingAction {
  type: SharingActionTypes.UPDATE_RESOURCE_SHARING;
  payload: UpdateResourceSharingActionPayload;
}

export interface UpdateResourceSharingSuccessAction {
  type: SharingActionTypes.UPDATE_RESOURCE_SHARING_SUCCESS;
  payload: Partial<Raw.IResourceShareItems>;
}

export interface UpdateResourceSharingErrorAction {
  type: SharingActionTypes.UPDATE_RESOURCE_SHARING_FAIL;
  payload: string;
}

// ============================================ ALL

export type SharingActions =
  | GetResourceSharingAction
  | GetResourceSharingSuccessAction
  | GetResourceSharingErrorAction
  | UpdateResourceSharingAction
  | UpdateResourceSharingSuccessAction
  | UpdateResourceSharingErrorAction;
