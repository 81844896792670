/**
 * Return the maximum length of keys
 * @returns { number } maximum unit length
 */
export const units: { [key: string]: { name: string; acceptPrefix: boolean } } = {
  '#': { name: 'Number of', acceptPrefix: false },
  '%': { name: '', acceptPrefix: false },
  '°': { name: 'Degrees', acceptPrefix: false },
  '°C': { name: 'Deg. Celsius', acceptPrefix: false },
  '°C/W': { name: 'Deg. Celsius per Watt', acceptPrefix: false },
  A: { name: 'Ampere', acceptPrefix: true },
  'A^2s': { name: 'Ampere squared-second', acceptPrefix: true },
  AWG: { name: 'AWG', acceptPrefix: true },
  'b/s': { name: 'bits per second', acceptPrefix: true },
  'B/s': { name: 'Bytes per second', acceptPrefix: true },
  Bar: { name: 'Bar', acceptPrefix: true },
  Bd: { name: 'Baud', acceptPrefix: true },
  'Bd/s': { name: 'Baud per second', acceptPrefix: true },
  bit: { name: 'bit', acceptPrefix: true },
  Byte: { name: 'Byte', acceptPrefix: true },
  C: { name: 'Coulomb', acceptPrefix: true },
  cd: { name: 'Candela', acceptPrefix: true },
  CFM: { name: 'Cubic feet per minute', acceptPrefix: true },
  Cycles: { name: 'Cycles', acceptPrefix: true },
  dB: { name: 'Decibel', acceptPrefix: true },
  dBA: { name: 'Decibel', acceptPrefix: true },
  dBi: { name: 'Decibel', acceptPrefix: true },
  dBuV: { name: 'Decibel microvolt', acceptPrefix: true },
  dBm: { name: 'Decibel miliwatt', acceptPrefix: true },
  deg: { name: 'Degrees', acceptPrefix: true },
  degC: { name: 'Deg. Celsius', acceptPrefix: true },
  'degC/W': { name: 'Deg. Celsius per Watt', acceptPrefix: true },
  F: { name: 'Farad', acceptPrefix: true },
  fps: { name: 'Frames per second', acceptPrefix: true },
  G: { name: 'G (Acceleration)', acceptPrefix: true },
  g: { name: 'Gram', acceptPrefix: true },
  'g/m3': { name: 'Gram per Cubic meter', acceptPrefix: true },
  gauss: { name: 'Gauss', acceptPrefix: true },
  H: { name: 'Henry', acceptPrefix: true },
  Hz: { name: 'Hertz', acceptPrefix: true },
  in: { name: 'Inches', acceptPrefix: true },
  Inputs: { name: 'Inputs', acceptPrefix: true },
  J: { name: 'Joule', acceptPrefix: true },
  K: { name: 'Kelvin', acceptPrefix: true },
  'K/W': { name: 'Kelvin per Watt', acceptPrefix: true },
  lb: { name: 'Pounds', acceptPrefix: true },
  lm: { name: 'Lumen', acceptPrefix: true },
  LPI: { name: 'Lines per inch', acceptPrefix: true },
  lsb: { name: 'Least-significant bit', acceptPrefix: true },
  lx: { name: 'Lux', acceptPrefix: true },
  m: { name: 'Meter', acceptPrefix: true },
  m2: { name: 'Square meter', acceptPrefix: true },
  'm3/h': { name: 'Cubic meter per hour', acceptPrefix: true },
  'm3/s': { name: 'Cubic meter per second', acceptPrefix: true },
  'm/s': { name: 'Meter per second', acceptPrefix: true },
  mils: { name: 'Thousands of inch', acceptPrefix: true },
  N: { name: 'Newton', acceptPrefix: true },
  Ohm: { name: 'Ohm', acceptPrefix: true },
  Ops: { name: 'Operations', acceptPrefix: true },
  Outputs: { name: 'Outputs', acceptPrefix: true },
  Pa: { name: 'Pascal', acceptPrefix: true },
  Poles: { name: 'Poles', acceptPrefix: true },
  ppm: { name: 'PPM', acceptPrefix: true },
  'ppm/°C': { name: 'PPM per deg. Celsius', acceptPrefix: true },
  'ppm/degC': { name: 'PPM per deg. Celsius', acceptPrefix: true },
  'ppm/K': { name: 'PPM per Kelvin', acceptPrefix: true },
  'ppm/mA': { name: 'PPM per miliampere', acceptPrefix: true },
  PSI: { name: 'Psi', acceptPrefix: true },
  psig: { name: 'Pound-force per square inch', acceptPrefix: true },
  px: { name: 'Pixel', acceptPrefix: true },
  Qty: { name: 'Quantity', acceptPrefix: true },
  RPM: { name: 'Rotations per minute', acceptPrefix: true },
  s: { name: 'Second', acceptPrefix: true },
  sps: { name: 'Samples per second', acceptPrefix: true },
  Stages: { name: 'Stages', acceptPrefix: true },
  T: { name: 'Tesla', acceptPrefix: true },
  Terminals: { name: 'Terminals', acceptPrefix: true },
  V: { name: 'Volts', acceptPrefix: true },
  'V/A': { name: 'Volts per Ampere', acceptPrefix: true },
  'V/lux-s': { name: 'Volts per lux-second', acceptPrefix: true },
  'V/us': { name: 'Volts per microsecond', acceptPrefix: true },
  'V/V': { name: 'Volts per Volt', acceptPrefix: true },
  VA: { name: 'Volt-Ampere', acceptPrefix: true },
  VAC: { name: 'Volts AC', acceptPrefix: true },
  VDC: { name: 'Volts DC', acceptPrefix: true },
  'VDC/VAC': { name: 'Volts AC/DC', acceptPrefix: true },
  Vrms: { name: 'Volts RMS', acceptPrefix: true },
  Vus: { name: 'Volts-microssecond', acceptPrefix: true },
  W: { name: 'Watt', acceptPrefix: true },
  'W/m3': { name: 'Watt per m3', acceptPrefix: true },
  'W/K': { name: 'Watt per Kelvin', acceptPrefix: true },
  'W/sr': { name: 'Watt per steradiant', acceptPrefix: true },
};

export function maxUnitLength(): number {
  return Math.max(...Object.keys(units).map((k) => k.length));
}
