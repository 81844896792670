import i18n from 'i18n/config';

export const DEFAULT_PAGINATION = { current: 1, pageSize: 50 };
export const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];
export const NUMBER_OF_ALTERNATIVES = 4;
export const KEY_SEPARATOR = '_';

export const BASIC_BLOCK_NAMESPACE = 'BASIC_BLOCK_NAMESPACE';
export const DESIGN_BLOCK_TYPE = 'design-block';
export const SUBSYSTEM_TYPE = 'sub-system';
export const CUBO_SLOT_TYPE = 'cubo-slot';
export const CUBO_TYPE = 'implementation';
export const COMPONENT_TYPE = 'component';

export const MAIN = 'Main';
export const ALTERNATIVE = 'Alternative';

export type IType = 'design-block' | 'sub-system' | 'cubo-slot' | 'implementation' | 'component';

export const MAPPED_TYPE_ICONS: Record<IType, { title: string; icon: string }> = {
  [DESIGN_BLOCK_TYPE]: {
    title: 'Design Block',
    icon: 'design-block',
  },
  [SUBSYSTEM_TYPE]: {
    title: 'Subsystem',
    icon: 'concept-blocks',
  },
  [CUBO_SLOT_TYPE]: {
    title: 'Cubo Slot',
    icon: 'cubos',
  },
  [CUBO_TYPE]: {
    title: 'Cubo',
    icon: 'cubos',
  },
  [COMPONENT_TYPE]: {
    title: 'Component',
    icon: 'components',
  },
};

export const ALTERNATIVE_FILTERS = [
  {
    text: MAIN,
    value: MAIN,
  },
  {
    text: ALTERNATIVE,
    value: ALTERNATIVE,
  },
];

export const TYPE_FILTERS = Object.entries(MAPPED_TYPE_ICONS).map((el) => ({ value: el[0], text: el[1].title }));

export const FILTER_TYPE: Record<IType, { title: string; icon: string }> = {
  [DESIGN_BLOCK_TYPE]: {
    title: 'Design Block',
    icon: 'design-block',
  },
  [SUBSYSTEM_TYPE]: {
    title: 'Subsystem',
    icon: 'concept-blocks',
  },
  [CUBO_SLOT_TYPE]: {
    title: 'Cubo Slot',
    icon: 'cubos',
  },
  [CUBO_TYPE]: {
    title: 'Cubo',
    icon: 'cubos',
  },
  [COMPONENT_TYPE]: {
    title: 'Component',
    icon: 'components',
  },
};

const t = i18n.t.bind(i18n);
export const TABLE_DENSITY_OPTIONS = [
  {
    key: 'small',
    label: t('supernova:bom.table.config.density.small', 'Small'),
  },
  {
    key: 'middle',
    label: t('supernova:bom.table.config.density.compact', 'Compact'),
  },
  {
    key: 'large',
    label: t('supernova:bom.table.config.density.large', 'Large'),
  },
];
