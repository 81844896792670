/* eslint-disable dot-notation */

import { AppState } from 'state_management/AppState';
import { Implementation } from 'state_management/reducers/implementations/Modals';
import { ISubSystem } from 'state_management/reducers/subSystemCanvas/Modals';
import {
  highlightCorruptedBlock,
  highlightCorruptedExternalPort,
  highlightCorruptedLink,
  resetBlocksStyle,
  resetExternalPortsStyle,
  resetLinksStyle,
} from 'utils/dom/canvasStyleUtil';

let storage: AppState | undefined;
export const errorTypeToMessage: {
  [key: string]: { key: string; errorMessage: string };
} = {
  'no-data': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.noData',
    errorMessage: 'Subsystem Canvas cannot be saved. Please set proper values, and then click the Save button again.',
  },
  'no-name': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.noName',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please set the subsystem a name, and then click the Save button again.',
  },
  'no-cubo-slot': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.noCuboSlot',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please add at least one cubo slot to the canvas, and then click the save button again.',
  },
  'no-category': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.noCategory',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please assign a Category to the Subsystem Canvas, and click the Save button again.',
  },
  'no-graph': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.noGraph',
    errorMessage: 'Subsystem Canvas cannot be saved. Please add at least one cubo slot and one external port.',
  },
  'no-block-category': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.noCategory',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please assign a Category to the CORRUPTED_BLOCK, and click the Save button again.',
  },
  'invalid-block-type': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.invalidType',
    errorMessage: 'Subsystem Canvas cannot be saved. CORRUPTED_BLOCK block type is not valid.',
  },
  'no-external-port': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.subSystem.noExternalPort',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please add at least one External Port to the canvas, and then click the Save button again.',
  },
  'no-port-type': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.links.noPortType',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please add at least one port type to the CORRUPTED_LINK, and then click the Save button again.',
  },
  'no-name-block': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.noBlockName',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please set the name for the highlighted block, and then click the Save button again.',
  },
  'isolated-external-port': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.isolatedExternalPort',
    errorMessage:
      'Subsystem Canvas cannot be saved. An isolated external port was found. Please connect the external port, and then click the Save button again.',
  },
  'no-name-external-port': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.noExternalPortName',
    errorMessage:
      'Subsystem Canvas cannot be saved. Please set the name for the highlighted external port, and then click the Save button again.',
  },
  'non-effective-external-port': {
    key: 'supernova:subSystemCanvas.save.validation.subSystem.blocks.nonEffectiveExternalPort',
    errorMessage: 'The external port is not connected to any Cubo Slot.',
  },
};

export type subsystemSaveErrors =
  | 'no-data'
  | 'no-name'
  | 'no-category'
  | 'no-graph'
  | 'no-block-category'
  | 'invalid-block-type'
  | 'no-external-port'
  | 'no-port-type'
  | 'no-cubo-slot'
  | 'no-name-block'
  | 'isolated-external-port'
  | 'non-effective-external-port'
  | 'no-name-external-port';

export const sanitizeErrorMessage = (
  errorMessage: string,
  errorType: subsystemSaveErrors,
  corruptedObject?: string,
): string => {
  if (['no-data', 'no-name', 'no-category', 'no-graph', 'no-external-port'].includes(errorType)) return errorMessage;
  if (['no-block-category', 'invalid-block-type'].includes(errorType))
    return errorMessage.replace('CORRUPTED_BLOCK', corruptedObject || '');
  return errorMessage.replace('CORRUPTED_LINK', corruptedObject || '');
};

const TEXT_ONLY_BLOCK_NAMESPACE = 'TEXT_ONLY_BLOCK_NAMESPACE';
const App_Default_Link = 'app.DefaultLink';
const TRANSPARENT_BLOCK_NAMESPACE = 'TRANSPARENT_BLOCK_NAMESPACE';

export interface SubsystemSaveValidationResult {
  isValid: boolean;
  errorType: subsystemSaveErrors | undefined;
  corruptedObject?: 'subsystem' | { objectName: string };
}

/* This function is very important. We are categorizing the
   blocks using the type which can be misleading later */
/* We always need to accept three different types which are TEXT_ONLY_BLOCK_NAMESPACE,
   app.DefaultLink, and TRANSPARENT_BLOCK_NAMESPACE */
/* By adding this function, we avoid bugs which may come later
   and always keep the code updated through development phase */
export const isBlockTypesValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | undefined;

  if (
    (subsystem?.canvas?.graph || []).some(
      (item) => ![TEXT_ONLY_BLOCK_NAMESPACE, App_Default_Link, TRANSPARENT_BLOCK_NAMESPACE].includes(item.type),
    )
  ) {
    isValid = false;
    errorType = 'invalid-block-type';
    corruptedObject = 'subsystem';
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

/* There must be at leasts one external port on the canvas */
export const isExternalPortsValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | undefined;

  if (!(subsystem?.canvas?.graph || []).some((item) => item?.type === TRANSPARENT_BLOCK_NAMESPACE)) {
    isValid = false;
    errorType = 'no-external-port';
    corruptedObject = 'subsystem';
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

export const isSubsystemGeneralInfoValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;

  if (!subsystem) {
    isValid = false;
    errorType = 'no-data';
  } else if (!subsystem.name) {
    isValid = false;
    errorType = 'no-name';
  } else if (!subsystem.taxonomy) {
    isValid = false;
    errorType = 'no-category';
  } else if (!subsystem.canvas?.graph?.length) {
    isValid = false;
    errorType = 'no-graph';
  }
  return {
    isValid,
    errorType,
    corruptedObject: isValid ? undefined : 'subsystem',
  };
};

export const isBlocksCategoryValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;
  const blocks = subsystem.canvas?.graph?.filter((b) => b.type === TEXT_ONLY_BLOCK_NAMESPACE) || [];

  const blocksIds = blocks.map((b) => b.id);
  resetBlocksStyle(blocksIds);
  for (let i = 0; i < blocks?.length; i += 1) {
    const attrs = (blocks[i]?.attrs as Record<string, any>) || {};
    const objectName = (attrs['.block-name'] || {})['text'];

    if (!((blocks[i]?.data as any) || {})['existingBlockId']) {
      if (!blocks[i]?.data?.taxonomy) {
        isValid = false;
        errorType = 'no-block-category';
        corruptedObject = { objectName: objectName || 'BLOCK' };
        highlightCorruptedBlock(blocks[i]?.id);
        break;
      }
    } else {
      const existingId = ((blocks[i]?.data as any) || {})['existingBlockId'];
      const implementation = storage?.implementationsState?.implementationsList?.find((impl) => impl.id === existingId);
      const _subsystem = storage?.subSystemCanvasState?.subSystems?.find((s) => s.id === existingId);
      if (implementation) {
        if (!implementation.taxonomy) {
          isValid = false;
          errorType = 'no-block-category';
          corruptedObject = { objectName: objectName || 'BLOCK' };
          highlightCorruptedBlock(blocks[i]?.id);
          break;
        }
      }
      if (_subsystem) {
        if (!_subsystem.taxonomy) {
          isValid = false;
          errorType = 'no-block-category';
          corruptedObject = { objectName: objectName || 'BLOCK' };
          highlightCorruptedBlock(blocks[i]?.id);
          break;
        }
      }
    }
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

export const isPortTypesValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;

  const links = subsystem?.canvas?.graph?.filter((i) => i.type === App_Default_Link) || [];
  const allLinksIds = links.map((l) => l.id);
  resetLinksStyle(allLinksIds);
  for (let i = 0; i < links.length; i += 1) {
    const linkData = links[i]?.data as Implementation;
    if (!linkData?.interfaces?.length) {
      isValid = false;
      errorType = 'no-port-type';
      corruptedObject = { objectName: 'links' };
      highlightCorruptedLink(links[i]?.id || '');
      break;
    }
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

export const isCuboSlotValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;
  if (!(subsystem?.canvas?.graph || []).some((item) => item?.type === TEXT_ONLY_BLOCK_NAMESPACE)) {
    isValid = false;
    errorType = 'no-cubo-slot';
    corruptedObject = 'subsystem';
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

export const isCuboSlotNamesValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;
  const blocks = subsystem?.canvas?.graph?.filter((i) => i.type === TEXT_ONLY_BLOCK_NAMESPACE) || [];
  const blocksIds = blocks.map((b) => b.id);
  resetBlocksStyle(blocksIds);
  for (let i = 0; i < blocks.length; i += 1) {
    const attrs = (blocks[i]?.attrs as Record<string, any>) || {};
    const blockName = ((attrs['.block-name'] || {})['text'] as string)?.trim();
    if (!blockName) {
      isValid = false;
      errorType = 'no-name-block';
      highlightCorruptedBlock(blocks[i]?.id);
    }
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

export const isExternalPortsNamesValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;
  const externalPorts = subsystem?.canvas?.graph?.filter((i) => i.type === TRANSPARENT_BLOCK_NAMESPACE) || [];
  const blocksIds = externalPorts.map((b) => b.id);
  resetExternalPortsStyle(blocksIds);
  for (let i = 0; i < externalPorts.length; i += 1) {
    const attrs = (externalPorts[i]?.attrs as Record<string, any>) || {};
    const blockName = ((attrs['.block-name'] || {})['text'] as string)?.trim();
    if (!blockName) {
      isValid = false;
      errorType = 'no-name-external-port';
      highlightCorruptedExternalPort(externalPorts[i]?.id);
    }
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

/* isolated external ports are not valid.
   an external port with no connection is called isolated */
export const isExternalPortsIsolationStateValid = (subsystem: Partial<ISubSystem>): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;
  const externalPorts = (subsystem?.canvas?.graph?.filter((p) => p.type === TRANSPARENT_BLOCK_NAMESPACE) || []).map(
    (p) => p.id,
  );
  const linksData = (subsystem?.canvas?.graph?.filter((l) => l.type === App_Default_Link) || []).map((l) => ({
    source: l.source?.id,
    target: l.target?.id,
  }));
  resetExternalPortsStyle(externalPorts);
  for (let i = 0; i < externalPorts.length; i += 1) {
    if (!linksData?.some((ld) => [ld.target, ld.source].includes(externalPorts[i]))) {
      highlightCorruptedExternalPort(externalPorts[i]);
      isValid = false;
      errorType = 'isolated-external-port';
      break;
    }
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

/* 
  In a subsystem all external ports must somehow be connected to a cub slot.
  Otherwise they are not valid.
  This connection can either be direct or indirect.
  IF A => B &&  B => C THEN A ==> C  
  In order to find out if they are connected to any cubo slot.
  The BFS algorithm is used which is a graph traversal technique.
  It traverses the graph and checks if external ports are connected to cubo slots.   
*/
export const isExternalPortsBlockConnectionStateValid = (
  subsystem: Partial<ISubSystem>,
): SubsystemSaveValidationResult => {
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;

  const externalPorts = (subsystem?.canvas?.graph?.filter((p) => p.type === TRANSPARENT_BLOCK_NAMESPACE) || []).map(
    (p) => p.id,
  );

  resetExternalPortsStyle(externalPorts);

  const cuboSlots = (subsystem?.canvas?.graph?.filter((c) => c.type === TEXT_ONLY_BLOCK_NAMESPACE) || []).map(
    (c) => c.id,
  );

  const links = (subsystem?.canvas?.graph?.filter((l) => l.type === App_Default_Link) || []).map((l) => ({
    source: l.source?.id,
    target: l.target?.id,
  }));

  for (let i = 0; i < externalPorts.length; i += 1) {
    /* initialize the queue */
    const ep = externalPorts[i];
    const queue: Array<string> = [ep];
    const visitedNodes: Array<string> = [];
    let scaped = false;

    while (queue.length) {
      const item = queue.shift() as string;
      if (cuboSlots.findIndex((cs) => cs === item) !== -1) {
        scaped = true;
        break;
      }
      links.forEach((l) => {
        if (l.target === item || l.source === item) {
          /* avoid self-connected items */
          if (l.source !== l.target)
            if (visitedNodes.findIndex((vn) => vn === item) === -1) queue.push(item === l.target ? l.source : l.target);
        }
      });
      visitedNodes.push(item);
    }

    if (!scaped) {
      isValid = false;
      errorType = 'non-effective-external-port';
      corruptedObject = undefined;
      highlightCorruptedExternalPort(ep);
      break;
    }
  }

  return {
    isValid,
    errorType,
    corruptedObject,
  };
};

export const isSubSystemCanvasSaveDataValid = (
  subsystem: Partial<ISubSystem>,
  store: AppState,
): SubsystemSaveValidationResult => {
  storage = store;
  let isValid = true;
  let errorType: subsystemSaveErrors | undefined;
  let corruptedObject: 'subsystem' | { objectName: string } | undefined;
  const registeredValidators: Array<
    (subsystem: Partial<ISubSystem>) => {
      isValid: boolean;
      errorType: subsystemSaveErrors | undefined;
      corruptedObject?: 'subsystem' | { objectName: string };
    }
  > = [
    isSubsystemGeneralInfoValid,
    isBlockTypesValid,
    isCuboSlotValid,
    isCuboSlotNamesValid,
    isBlocksCategoryValid,
    isExternalPortsValid,
    isExternalPortsNamesValid,
    isExternalPortsIsolationStateValid,
    isExternalPortsBlockConnectionStateValid,
    isPortTypesValid,
  ];

  for (let i = 0; i < registeredValidators.length; i += 1) {
    const validationResult = registeredValidators[i](subsystem);
    isValid = validationResult.isValid;
    errorType = validationResult.errorType;
    corruptedObject = validationResult.corruptedObject;
    if (!isValid) break;
  }
  return {
    isValid,
    errorType,
    corruptedObject,
  };
};
