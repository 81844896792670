import i18n from 'i18n/config';
import { AxiosError } from 'axios';

import {
  call,
  select,
  SelectEffect,
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { GROUPS_FILTERING_LUT } from 'config';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  ProjectsActionTypes,
  ProjectCanvasCompileAction,
  ProjectCanvasCompileErrorAction,
  ProjectStateGetAction,
} from 'state_management/actions/projects/ActionTypes';
import { projectCanvasCompileErrorAction, getProjectState } from 'state_management/actions/projects/projectsActions';
import { ICompilationStates } from 'state_management/reducers/projects/Modals';
import { getProjectCompiledState } from 'state_management/reducers/projects/utils/projectCompileState';
import { AppState } from 'state_management/AppState';
import { combineSearchQueries } from 'utils/searchHelper';
import { getDagInfoByCanvas, getDagStatus, triggerDAG } from './utils/airflow';

const t = i18n.t.bind(i18n);

export function* updateProjectState(
  projectId: string,
  state: string,
  error?: { error_msg: string },
): Generator<CallEffect> {
  try {
    yield call(() =>
      axiosInstance.put(apiUri(`/dataservice/project-state/${projectId}`, 2), {
        project_id: projectId,
        state,
        error,
      }),
    );
  } catch {
    throw new Error(t('supernova:canvas.toasts.updateProjStateError', 'Error during updating Project state'));
  }
}

const handleDAGRunning = (): void => {
  throw new Error(
    t('supernova:canvas.toasts.compilationAlreadyRunningError', 'Another compilation is already running'),
  );
};

const handleTriggerDAGError = (): void => {
  throw new Error(t('supernova:canvas.toasts.startingCompilationError', 'Error when starting the compilation'));
};

const handleGetDAGStatusError =
  (message: string) =>
  (error: AxiosError): Raw.IDAGStatus | undefined => {
    if (error.response?.status === 500) {
      return { state: 'failed', errorMessage: 'Internal error when running the DAG' };
    }

    if (error.response?.status === 404) {
      return undefined;
    }
    throw new Error(message);
  };

export function* projectCanvasCompileSaga(
  action: ProjectCanvasCompileAction,
): Generator<
  SelectEffect | PutEffect<ProjectStateGetAction | ProjectCanvasCompileErrorAction> | TakeEffect | CallEffect
> {
  const { canvas, version } = action.payload;

  try {
    const {
      authState: {
        userInfo: { isAnonymousUser, _id: userId },
        userSettings,
        featurePermissions,
      },
      projectsState: {
        currentProject: { id: projectId },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    if (isAnonymousUser) {
      // NOTE: can't compile local projects
      return;
    }

    let filterQuery: string | undefined;
    if (!featurePermissions?.PROJECT_WORKSPACES?.read && window.location.host in GROUPS_FILTERING_LUT) {
      filterQuery = combineSearchQueries('', '', true, userId, userSettings?.preferredWorkspaces).replace('query=', '');
    }

    const { dagName, initialState, runningState } = getDagInfoByCanvas(canvas);
    const processId = dagName;
    const runId = projectId;

    // Check if it is already running
    yield call(() =>
      getDagStatus(
        processId,
        runId,
        handleDAGRunning,
        handleGetDAGStatusError(
          t('supernova:canvas.toasts.getDAGStatusError', 'Error while checking the current status from the DAG'),
        ),
      ),
    );

    // NOTE gotta go to initial state, then to the runningState as it is
    // only a valid transition like that
    yield call(() => updateProjectState(projectId, initialState));
    yield call(() => updateProjectState(projectId, runningState));

    const dagConfig = {
      // NOTE not using userId from the project because if it is a shared
      // project, it would be the owner, but it needs to be the current user
      user: userId,
      item: projectId,
      filters: filterQuery,
      legacyMode: version !== '2',
    };

    yield call(() => triggerDAG<typeof dagConfig>(processId, runId, dagConfig, handleTriggerDAGError));

    // NOTE: projectCanvasCompileSuccessAction should be triggered after we get successful state in getProjectStateSaga
    yield put(getProjectState(canvas, version));
  } catch (error) {
    const compilationStates = {
      design: canvas === 'design' ? 'error' : 'success',
      board: canvas === 'board' ? 'error' : 'disabled',
    } as ICompilationStates;

    yield put(
      projectCanvasCompileErrorAction(
        getProjectCompiledState(compilationStates, getErrorMessage(error) || 'Generating Project failed'),
      ),
    );
  }
}

export function* projectCanvasCompileSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECT_CANVAS_COMPILE, projectCanvasCompileSaga);
}
