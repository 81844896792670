import { ProjectGeneralSettingsState } from 'state_management/reducers/projectGeneralSettings/Modals';

export enum ProjectGeneralSettingsActionTypes {
  GET_PROJECT_GENERAL_SETTINGS = 'GET_PROJECT_GENERAL_SETTINGS',
  GET_PROJECT_GENERAL_SETTINGS_SUCCESS = 'GET_PROJECT_GENERAL_SETTINGS_SUCCESS',
  GET_PROJECT_GENERAL_SETTINGS_FAIL = 'GET_PROJECT_GENERAL_SETTINGS_FAIL',
}

export type ProjectGeneralSettingsSuccessPayload = Pick<
  ProjectGeneralSettingsState,
  'cadTools' | 'defaultCadToolID' | 'bomTypes' | 'defaultBomTypeID'
>;

export interface GetProjectGeneralSettingsAction {
  type: ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS;
}

export interface GetProjectGeneralSettingsSuccessAction {
  type: ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS_SUCCESS;
  payload: ProjectGeneralSettingsSuccessPayload;
}

export interface GetProjectGeneralSettingsFailAction {
  type: ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS_FAIL;
  payload: string;
}

export type ProjectGeneralSettingsAction =
  | GetProjectGeneralSettingsAction
  | GetProjectGeneralSettingsSuccessAction
  | GetProjectGeneralSettingsFailAction;
