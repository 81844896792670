import styled from 'styled-components';
import FormControl from '@material-ui/core/FormControl';

export const FormContainer = styled(FormControl)`
  && {
    width: 100%;

    // NOTE: This is required as on 'disable' clearIcon gets removed and Input shrinks
    & .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'],
    .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding-right: 70px !important;
    }
  }
`;

export const FormControlContainer = styled.div<{ noMargin?: boolean; hasInfo: boolean; disabled?: boolean }>`
  && {
    margin: ${({ noMargin, theme }): string => (noMargin ? '0px' : `${theme.spacing.small}px 0px`)} !important;
    display: ${({ hasInfo }): string => (hasInfo ? 'grid' : 'block')};
    grid-gap: 10px;
    grid-template-columns: auto min-content;
    align-items: center;
    width: 100%;
    background-color: ${({ theme, disabled }): string => (disabled ? theme.palette.canvasBg : theme.palette.white)};

    & input {
      min-height: 19px;
    }
  }
`;
