import { conversionTable } from './convertionTable';
import { InvalidPrefix } from './errors/invalidPrefixError';

export class DetailedValue {
  /**
   * Save values for units wiht params and prefix.
   * @param value the raw value
   * @param unit the unit
   * @param prefix the unit prefix if any
   * @param emptySpaces empty spaces representation
   * @param decimalPlaces quantity of decimal places
   * @param postfixNotation bool represeting if is using postfix notation
   */
  constructor(
    public value: number,
    public unit: string,
    public prefix: string = '',
    public emptySpaces: number = 1,
    public decimalPlaces: number = 0,
    public postfixNotation: boolean = false,
  ) {
    if (prefix) {
      this.value = DetailedValue.calculateValueWithoutPrefix(value, prefix);
    } else {
      this.value = value;
    }
    this.unit = unit;
    this.prefix = prefix;
    this.emptySpaces = emptySpaces;
    this.decimalPlaces = decimalPlaces;
    this.postfixNotation = postfixNotation;
  }

  /**
   * Return true if the point is contained
   * @param value target value
   * @param prefix target prefix
   * @returns { number | undefined } if prefix exist if not undefined
   */
  static calculateValueWithoutPrefix = (value: number, prefix: string): number => {
    if (prefix in conversionTable) {
      return value * conversionTable[prefix];
    }
    throw new InvalidPrefix();
  };

  /**
   * Return the value in the prefix unit
   * @returns { number }
   */
  calculateValueWithPrefix = (): number => {
    if (this.prefix in conversionTable) {
      return this.value / conversionTable[this.prefix];
    }
    return this.value;
  };

  /**
   * Return the value using normal notation
   * @returns { string }
   */
  private asNormalNotation = (): string => {
    const value: number = this.calculateValueWithPrefix();

    const valueAsString = Number(value).toFixed(this.decimalPlaces);

    return valueAsString + ' '.repeat(this.emptySpaces) + this.prefix + this.unit;
  };

  /**
   * Return the value using postifx notation
   * @returns { string }
   */
  private asPostfixNotation = (): string => {
    const prefix: string = this.prefix || '.';
    const value: number = this.calculateValueWithPrefix();

    const integerPart: number = Math.floor(value);
    const decimalPart: number = Math.floor((value - integerPart) * 10 ** this.decimalPlaces);

    return integerPart + prefix + decimalPart + ' '.repeat(this.emptySpaces) + this.unit;
  };

  /**
   * Return the value using the set notation
   * @returns { string }
   */
  getValue = (): string => {
    if (this.postfixNotation) {
      return this.asPostfixNotation();
    }
    return this.asNormalNotation();
  };

  /**
   * Return the detailed object as a JavaScript Object
   * @returns {
   *      value: number,
   *      unit: string,
   *      prefix: string,
   *      emptySpaces: number,
   *      decimalPlaces: number,
   *      multiplicationFactor: number,
   *      usePostfixNotation: boolean
   * }
   */
  detailedObject = (): { [key: string]: number | string | boolean } => ({
    value: this.value,
    unit: this.unit,
    prefix: this.prefix,
    emptySpaces: this.emptySpaces,
    decimalPlaces: this.decimalPlaces,
    multiplicationFactor: conversionTable[this.prefix] || 1,
    usePostfixNotation: this.postfixNotation,
  });
}
