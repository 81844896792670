import { IManufacturer } from 'state_management/reducers/manufacturers/Modals';

export const deserializeManufacturer = (manufacturer: Partial<IManufacturer>): Partial<Raw.IManufacturer> => ({
  id: manufacturer.id,
  created_at: manufacturer.createdAt,
  modified_at: manufacturer.modifiedAt,
  name: manufacturer.name,
  short_description: manufacturer.shortDescription,
  full_info: manufacturer.fullInfo,
  website: manufacturer.website,
});

export const serializeManufacturer = (manufacturer: Raw.IManufacturer): IManufacturer => ({
  id: manufacturer.id,
  createdAt: manufacturer.created_at,
  modifiedAt: manufacturer.modified_at,
  name: manufacturer.name,
  shortDescription: manufacturer.short_description,
  fullInfo: manufacturer.full_info,
  website: manufacturer.website,
});
