import { AxiosResponse } from 'axios';
import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  ProjectsActionTypes,
  ProjectsDeleteAction,
  ProjectsDeleteErrorAction,
  ProjectsDeleteSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  deleteProjectErrorAction,
  deleteProjectSuccessAction,
  MACRO_RES_PROJECTS_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeProject } from 'utils/projectSerializer';
import { deleteLocalProject } from 'services/localProjectsService';

export function* projectDeleteSaga(
  action: ProjectsDeleteAction,
): Generator<SelectEffect | CallEffect | PutEffect<ProjectsDeleteSuccessAction | ProjectsDeleteErrorAction>> {
  try {
    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    const project = action.payload;

    if (isAnonymousUser) {
      deleteLocalProject(project);

      yield put(deleteProjectSuccessAction(project));
    } else {
      const res = yield call(() => axiosInstance.delete(apiUri(`${MACRO_RES_PROJECTS_ENDPOINT}/${project.id}`, 2)));

      yield put(deleteProjectSuccessAction(serializeProject((res as AxiosResponse<Raw.Project>).data)));
    }
  } catch (error) {
    yield put(deleteProjectErrorAction(getErrorMessage(error) || 'Deleting Project failed'));
  }
}

export function* projectDeleteWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECTS_DELETE, projectDeleteSaga);
}
