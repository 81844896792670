import i18n from 'i18n/config';
import { IEntity } from 'models/IEntity';
import { Project } from 'state_management/reducers/projects/Modals';
import { initialProjectsState } from 'state_management/reducers/projects/projectsReducer';
import { RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';

const LOCAL_PROJECTS_KEY = 'LOCAL_PROJECTS_KEY';
const LOCAL_PROJECTS_CANVAS_KEY = 'LOCAL_PROJECTS_CANVAS_KEY';
const LOCAL_PROJECTS_SCREENSHOT_KEY = 'LOCAL_PROJECTS_SCREENSHOT_KEY';

export const getLocalProjects = (): Array<Project> => {
  const rawStoredProject = global.localStorage.getItem(LOCAL_PROJECTS_KEY);

  return rawStoredProject ? JSON.parse(rawStoredProject) : [];
};

export const deleteLocalProjects = (): void => {
  const projectIds = getLocalProjects().map((p) => p.id);

  global.localStorage.removeItem(LOCAL_PROJECTS_KEY);

  projectIds.forEach((i) => {
    global.localStorage.removeItem(LOCAL_PROJECTS_CANVAS_KEY + i);
    global.localStorage.removeItem(LOCAL_PROJECTS_SCREENSHOT_KEY + i);
  });
};

export const getLocalProjectById = (id: string): Project => {
  const projects = getLocalProjects();

  const currentProject = projects.find((p) => p.id === id);

  if (!currentProject) {
    const t = i18n.t.bind(i18n);

    throw new Error(t('supernova:toasts.projectUpdateError', 'Project not found'));
  }

  return currentProject;
};

export const createLocalProject = (
  project: Partial<Project>,
  originalProjId?: string,
  projectStateData?: Pick<Project, 'canvasStates' | 'cadFilesState' | 'compilationStates' | 'compilerState'>,
): Partial<Project> => {
  // NOTE: Replaces any existing projects as the anonymous user can only have 1 project
  deleteLocalProjects();
  const _projects = getLocalProjects();

  const { title, description, cadTool, bomType, numberOfLayers } = project;

  const projectState = {
    compilationStates: { board: 'disabled', design: 'enabled' },
    canvasState: initialProjectsState.currentProject.canvasStates,
    cadFilesState: initialProjectsState.currentProject.cadFilesState,
    compilerState: initialProjectsState.currentProject.compilerState,
    ...(projectStateData || {}),
  };

  // NOTE: Only BOM is enabled
  projectState.cadFilesState = {
    ...projectState.cadFilesState,
    pdf: 'disabled',
    brd: 'disabled',
    sch: 'disabled',
  };

  const newProject = {
    title,
    description,
    cadTool,
    bomType,
    numberOfLayers,
    id: Date.now().toString(),
    originalProj: originalProjId,
    isRefDesign: false,
    modifiedAt: undefined,
    userId: undefined,
    ...projectState,
  } as Partial<Project>;
  const projects = [..._projects, newProject];

  global.localStorage.setItem(LOCAL_PROJECTS_KEY, JSON.stringify(projects));

  return newProject;
};

export const updateLocalProject = (project: Partial<Project>): Project | undefined => {
  const _projects = getLocalProjects();

  let currentProject = _projects.find((p) => p.id === project.id);

  if (currentProject) {
    currentProject = { ...currentProject, ...project };

    const projects = _projects.map((p) => (p.id === project.id ? currentProject : p));

    global.localStorage.setItem(LOCAL_PROJECTS_KEY, JSON.stringify(projects));
  }

  return currentProject;
};

export const deleteLocalProject = (project: Partial<Project>): void => {
  const projects = getLocalProjects().filter((p) => p.id !== project.id);

  global.localStorage.setItem(LOCAL_PROJECTS_KEY, JSON.stringify(projects));
};

export const getLocalScreenshotByProjectId = (projectId: string): string | null =>
  global.localStorage.getItem(LOCAL_PROJECTS_SCREENSHOT_KEY + projectId);

export const createLocalScreenshot = (projectId: string, data: string): void => {
  global.localStorage.setItem(LOCAL_PROJECTS_SCREENSHOT_KEY + projectId, data);
};

export const deleteLocalScreenshot = (projectId: string): void => {
  global.localStorage.removeItem(LOCAL_PROJECTS_SCREENSHOT_KEY + projectId);
};

export const getLocalCanvasByProjectId = (projectId: string): Array<RappidGraph<IEntity>> => {
  const canvas = global.localStorage.getItem(LOCAL_PROJECTS_CANVAS_KEY + projectId);

  if (!canvas) {
    return [];
  }

  return JSON.parse(canvas);
};

export const createLocalCanvas = (projectId: string, canvas: Array<RappidGraph<IEntity>>): void => {
  global.localStorage.setItem(LOCAL_PROJECTS_CANVAS_KEY + projectId, JSON.stringify(canvas));
};

export const deleteLocalCanvas = (projectId: string): void => {
  global.localStorage.removeItem(LOCAL_PROJECTS_CANVAS_KEY + projectId);
};

export const cloneLocalProject = (
  project: Partial<Project>,
  projectState: Pick<Project, 'canvasStates' | 'cadFilesState' | 'compilationStates' | 'compilerState'>,
  canvas?: Array<RappidGraph<IEntity>>,
  screenshotData?: string,
): Partial<Project> => {
  const newProject = createLocalProject(project, project.id, projectState);
  const projId = newProject.id as string;

  if (canvas) {
    createLocalCanvas(projId, canvas);
  }

  if (screenshotData) {
    createLocalScreenshot(projId, screenshotData);
  }

  return newProject;
};
