import { IPagination } from 'models/Pagination';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';
import { ISpecification, SpecificationContext, SpecificationList } from '../../reducers/specifications/Modals';

export enum SpecificationActionTypes {
  RESET_SPECIFICATIONS = 'RESET_SPECIFICATIONS',

  GET_ALL_SPECIFICATIONS = 'GET_ALL_SPECIFICATIONS',
  GET_ALL_SPECIFICATIONS_SUCCESS = 'GET_ALL_SPECIFICATIONS_SUCCESS',
  GET_ALL_SPECIFICATIONS_FAIL = 'GET_ALL_SPECIFICATIONS_FAIL',

  GET_SPECIFICATION_DEPENDENCIES = 'GET_SPECIFICATION_DEPENDENCIES',

  CREATE_SPECIFICATION = 'CREATE_SPECIFICATION',
  CREATE_SPECIFICATION_SUCCESS = 'CREATE_SPECIFICATION_SUCCESS',
  CREATE_SPECIFICATION_FAIL = 'CREATE_SPECIFICATION_FAIL',

  GET_SPECIFICATION_BY_ID = 'GET_SPECIFICATION_BY_ID',
  GET_SPECIFICATION_BY_ID_SUCCESS = 'GET_SPECIFICATION_BY_ID_SUCCESS',
  GET_SPECIFICATION_BY_ID_FAIL = 'GET_SPECIFICATION_BY_ID_FAIL',

  UPDATE_SPECIFICATION = 'UPDATE_SPECIFICATION',
  UPDATE_SPECIFICATION_SUCCESS = 'UPDATE_SPECIFICATION_SUCCESS',
  UPDATE_SPECIFICATION_FAIL = 'UPDATE_SPECIFICATION_FAIL',

  CLONE_SPECIFICATION = 'CLONE_SPECIFICATION',
  CLONE_SPECIFICATION_SUCCESS = 'CLONE_SPECIFICATION_SUCCESS',
  CLONE_SPECIFICATION_FAIL = 'CLONE_SPECIFICATION_FAIL',

  DELETE_SPECIFICATION = 'DELETE_SPECIFICATION',
  DELETE_SPECIFICATION_SUCCESS = 'DELETE_SPECIFICATION_SUCCESS',
  DELETE_SPECIFICATION_FAIL = 'DELETE_SPECIFICATION_FAIL',
}

// ============================================ RESET_SPECIFICATIONS
export interface ResetSpecificationsAction {
  type: SpecificationActionTypes.RESET_SPECIFICATIONS;
}

// ============================================ GET_ALL_SPECIFICATIONS

export interface GetAllSpecificationsAction {
  payload: { pagination: Partial<IPagination>; specTypes: Array<SpecificationContext> };
  type: SpecificationActionTypes.GET_ALL_SPECIFICATIONS;
}

export interface GetAllSpecificationsSuccessAction {
  payload: { pagination: IPagination; data: SpecificationList; specTypes: Array<SpecificationContext> };
  type: SpecificationActionTypes.GET_ALL_SPECIFICATIONS_SUCCESS;
}

export interface GetAllSpecificationsErrorAction {
  payload: string;
  type: SpecificationActionTypes.GET_ALL_SPECIFICATIONS_FAIL;
}

// ============================================ GET_DESIGN_BLOCK_DEPENDENCIES

export interface GetSpecificationkDependenciesAction {
  type: SpecificationActionTypes.GET_SPECIFICATION_DEPENDENCIES;
}

// ============================================ CREATE_SPECIFICATION

export interface CreateSpecificationAction extends AxiosRequestPayload<Partial<Raw.ISpecification>> {
  type: SpecificationActionTypes.CREATE_SPECIFICATION;
}

export interface CreateSpecificationSuccessAction
  extends AxiosSuccessPayload<
    Raw.ISpecification,
    SpecificationActionTypes.CREATE_SPECIFICATION_SUCCESS,
    CreateSpecificationAction
  > {
  type: SpecificationActionTypes.CREATE_SPECIFICATION_SUCCESS;
}

export interface CreateSpecificationErrorAction
  extends AxiosErrorPayload<SpecificationActionTypes.CREATE_SPECIFICATION_FAIL, CreateSpecificationAction> {
  type: SpecificationActionTypes.CREATE_SPECIFICATION_FAIL;
}

// ============================================ GET_SPECIFICATION_BY_ID

export interface GetSpecificationByIdAction {
  payload: { id: string };
  type: SpecificationActionTypes.GET_SPECIFICATION_BY_ID;
}

export interface GetSpecificationByIdSuccessAction {
  payload: ISpecification;
  type: SpecificationActionTypes.GET_SPECIFICATION_BY_ID_SUCCESS;
}

export interface GetSpecificationByIdErrorAction {
  payload: string;
  type: SpecificationActionTypes.GET_SPECIFICATION_BY_ID_FAIL;
}

// ============================================ UPDATE_SPECIFICATION

export interface UpdateSpecificationAction extends AxiosRequestPayload<Raw.ISpecification> {
  type: SpecificationActionTypes.UPDATE_SPECIFICATION;
}
export interface UpdateSpecificationSuccessAction
  extends AxiosSuccessPayload<
    Raw.ISpecification,
    SpecificationActionTypes.UPDATE_SPECIFICATION_SUCCESS,
    UpdateSpecificationAction
  > {
  type: SpecificationActionTypes.UPDATE_SPECIFICATION_SUCCESS;
}
export interface UpdateSpecificationErrorAction
  extends AxiosErrorPayload<SpecificationActionTypes.UPDATE_SPECIFICATION_FAIL, UpdateSpecificationAction> {
  type: SpecificationActionTypes.UPDATE_SPECIFICATION_FAIL;
}

// ============================================ CLONE_SPECIFICATION

export interface CloneSpecificationAction extends AxiosRequestPayload<Partial<Raw.ISpecification>> {
  type: SpecificationActionTypes.CLONE_SPECIFICATION;
}

export interface CloneSpecificationSuccessAction
  extends AxiosSuccessPayload<
    Raw.ISpecification,
    SpecificationActionTypes.CLONE_SPECIFICATION_SUCCESS,
    CloneSpecificationAction
  > {
  type: SpecificationActionTypes.CLONE_SPECIFICATION_SUCCESS;
}

export interface CloneSpecificationErrorAction
  extends AxiosErrorPayload<SpecificationActionTypes.CLONE_SPECIFICATION_FAIL, CloneSpecificationAction> {
  type: SpecificationActionTypes.CLONE_SPECIFICATION_FAIL;
}

// ============================================ DELETE_SPECIFICATION

export interface DeleteSpecificationAction extends AxiosRequestPayload {
  type: SpecificationActionTypes.DELETE_SPECIFICATION;
}
export interface DeleteSpecificationSuccessAction
  extends AxiosSuccessPayload<
    string,
    SpecificationActionTypes.DELETE_SPECIFICATION_SUCCESS,
    DeleteSpecificationAction
  > {
  type: SpecificationActionTypes.DELETE_SPECIFICATION_SUCCESS;
}
export interface DeleteSpecificationErrorAction
  extends AxiosErrorPayload<SpecificationActionTypes.DELETE_SPECIFICATION_FAIL, DeleteSpecificationAction> {
  type: SpecificationActionTypes.DELETE_SPECIFICATION_FAIL;
}

// ============================================ ALL

export type SpecificationActions =
  | GetAllSpecificationsAction
  | GetAllSpecificationsSuccessAction
  | GetAllSpecificationsErrorAction
  | CreateSpecificationAction
  | CreateSpecificationSuccessAction
  | CreateSpecificationErrorAction
  | GetSpecificationByIdAction
  | GetSpecificationByIdSuccessAction
  | GetSpecificationByIdErrorAction
  | UpdateSpecificationAction
  | UpdateSpecificationSuccessAction
  | UpdateSpecificationErrorAction
  | CloneSpecificationAction
  | CloneSpecificationSuccessAction
  | CloneSpecificationErrorAction
  | DeleteSpecificationAction
  | DeleteSpecificationSuccessAction
  | DeleteSpecificationErrorAction
  | ResetSpecificationsAction;
