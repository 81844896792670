import { FunctionComponent, useEffect, useRef } from 'react';
import { Wrapper } from './styles';

/**
 * BrowserZoomGuard HOC (Higher Order Component) can be wrapped around components,
 * so when the mouse pointer points at them, the browser resize functionality will be prevented.
 */
const BrowserZoomGuard: FunctionComponent = (props): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  function disableZoomByWheel(e: WheelEvent): void {
    e.ctrlKey && e.preventDefault();
  }

  function disableZoomByKeyBoard(e: KeyboardEvent): void {
    const keyMap = {
      plus: '61',
      add: '107',
      minus: '173',
      subtract: '109',
      equal: '187',
      dash: '189',
    };
    if (
      e.ctrlKey &&
      [keyMap.plus, keyMap.add, keyMap.minus, keyMap.subtract, keyMap.equal, keyMap.dash].includes(e.key)
    ) {
      e.preventDefault();
    }
  }

  function disableZoomAction(): void {
    window.addEventListener('wheel', disableZoomByWheel, { passive: false });
    window.addEventListener('keydown', disableZoomByKeyBoard);
  }

  function enableZoomAction(): void {
    window.removeEventListener('wheel', disableZoomByWheel);
    window.removeEventListener('keydown', disableZoomByKeyBoard);
  }

  useEffect(() => {
    wrapperRef?.current?.addEventListener('mouseleave', enableZoomAction);
    wrapperRef?.current?.addEventListener('mouseover', disableZoomAction);
    return (): void => {
      enableZoomAction();
    };
  }, []);

  return (
    <Wrapper ref={wrapperRef} data-testid="zoom-guard-wrapper">
      {/* eslint-disable-next-line */}
      {props?.children}
    </Wrapper>
  );
};
export default BrowserZoomGuard;
