import { IPagination } from 'models/Pagination';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';
import { InterfaceType } from '../../reducers/interfaces/Modals';

export enum InterfaceActionTypes {
  GET_ALL_INTERFACES = 'GET_ALL_INTERFACES',
  GET_ALL_INTERFACES_SUCCESS = 'GET_ALL_INTERFACES_SUCCESS',
  GET_ALL_INTERFACES_FAIL = 'GET_ALL_INTERFACES_FAIL',

  CREATE_INTERFACE = 'CREATE_INTERFACE',
  CREATE_INTERFACE_SUCCESS = 'CREATE_INTERFACE_SUCCESS',
  CREATE_INTERFACE_FAIL = 'CREATE_INTERFACE_FAIL',

  GET_INTERFACE_DEPENDENCIES = 'GET_INTERFACE_DEPENDENCIES',

  GET_INTERFACE_BY_ID = 'GET_INTERFACE_BY_ID',
  GET_INTERFACE_BY_ID_SUCCESS = 'GET_INTERFACE_BY_ID_SUCCESS',
  GET_INTERFACE_BY_ID_FAIL = 'GET_INTERFACE_BY_ID_FAIL',

  UPDATE_INTERFACE = 'UPDATE_INTERFACE',
  UPDATE_INTERFACE_SUCCESS = 'UPDATE_INTERFACE_SUCCESS',
  UPDATE_INTERFACE_FAIL = 'UPDATE_INTERFACE_FAIL',

  CLONE_INTERFACE = 'CLONE_INTERFACE',
  CLONE_INTERFACE_SUCCESS = 'CLONE_INTERFACE_SUCCESS',
  CLONE_INTERFACE_FAIL = 'CLONE_INTERFACE_FAIL',

  DELETE_INTERFACE = 'DELETE_INTERFACE',
  DELETE_INTERFACE_SUCCESS = 'DELETE_INTERFACE_SUCCESS',
  DELETE_INTERFACE_FAIL = 'DELETE_INTERFACE_FAIL',
}

// ============================================ GET_ALL_INTERFACES

export interface GetAllInterfacesAction {
  payload: Partial<IPagination>;
  type: InterfaceActionTypes.GET_ALL_INTERFACES;
}

export interface GetAllInterfacesSuccessAction {
  payload: { pagination: IPagination; data: Array<InterfaceType> };
  type: InterfaceActionTypes.GET_ALL_INTERFACES_SUCCESS;
}

export interface GetAllInterfacesErrorAction {
  payload: string;
  type: InterfaceActionTypes.GET_ALL_INTERFACES_FAIL;
}

// ============================================ CREATE_INTERFACE

export interface CreateInterfaceAction extends AxiosRequestPayload<Partial<Raw.InterfaceType>> {
  type: InterfaceActionTypes.CREATE_INTERFACE;
}

export interface CreateInterfaceSuccessAction
  extends AxiosSuccessPayload<Raw.InterfaceType, InterfaceActionTypes.CREATE_INTERFACE_SUCCESS, CreateInterfaceAction> {
  type: InterfaceActionTypes.CREATE_INTERFACE_SUCCESS;
}

export interface CreateInterfaceErrorAction
  extends AxiosErrorPayload<InterfaceActionTypes.CREATE_INTERFACE_FAIL, CreateInterfaceAction> {
  type: InterfaceActionTypes.CREATE_INTERFACE_FAIL;
}

// ============================================ GET_INTERFACE_DEPENDENCIES

export interface GetInterfaceDependenciesAction {
  type: InterfaceActionTypes.GET_INTERFACE_DEPENDENCIES;
}

// ============================================ GET_INTERFACE_BY_ID

export interface GetInterfaceByIdAction {
  payload: { id: string };
  type: InterfaceActionTypes.GET_INTERFACE_BY_ID;
}

export interface GetInterfaceByIdSuccessAction {
  payload: InterfaceType;
  type: InterfaceActionTypes.GET_INTERFACE_BY_ID_SUCCESS;
}

export interface GetInterfaceByIdErrorAction {
  payload: string;
  type: InterfaceActionTypes.GET_INTERFACE_BY_ID_FAIL;
}

// ============================================ UPDATE_INTERFACE

export interface UpdateInterfaceAction extends AxiosRequestPayload<Raw.InterfaceType> {
  type: InterfaceActionTypes.UPDATE_INTERFACE;
}
export interface UpdateInterfaceSuccessAction
  extends AxiosSuccessPayload<Raw.InterfaceType, InterfaceActionTypes.UPDATE_INTERFACE_SUCCESS, UpdateInterfaceAction> {
  type: InterfaceActionTypes.UPDATE_INTERFACE_SUCCESS;
}
export interface UpdateInterfaceErrorAction
  extends AxiosErrorPayload<InterfaceActionTypes.UPDATE_INTERFACE_FAIL, UpdateInterfaceAction> {
  type: InterfaceActionTypes.UPDATE_INTERFACE_FAIL;
}

// ============================================ CLONE_INTERFACE

export interface CloneInterfaceAction extends AxiosRequestPayload<Partial<Raw.InterfaceType>> {
  type: InterfaceActionTypes.CLONE_INTERFACE;
}

export interface CloneInterfaceSuccessAction
  extends AxiosSuccessPayload<Raw.InterfaceType, InterfaceActionTypes.CLONE_INTERFACE_SUCCESS, CloneInterfaceAction> {
  type: InterfaceActionTypes.CLONE_INTERFACE_SUCCESS;
}

export interface CloneInterfaceErrorAction
  extends AxiosErrorPayload<InterfaceActionTypes.CLONE_INTERFACE_FAIL, CloneInterfaceAction> {
  type: InterfaceActionTypes.CLONE_INTERFACE_FAIL;
}

// ============================================ DELETE_INTERFACE

export interface DeleteInterfaceAction extends AxiosRequestPayload {
  type: InterfaceActionTypes.DELETE_INTERFACE;
}
export interface DeleteInterfaceSuccessAction
  extends AxiosSuccessPayload<string, InterfaceActionTypes.DELETE_INTERFACE_SUCCESS, DeleteInterfaceAction> {
  type: InterfaceActionTypes.DELETE_INTERFACE_SUCCESS;
}
export interface DeleteInterfaceErrorAction
  extends AxiosErrorPayload<InterfaceActionTypes.DELETE_INTERFACE_FAIL, DeleteInterfaceAction> {
  type: InterfaceActionTypes.DELETE_INTERFACE_FAIL;
}

// ============================================ ALL

export type InterfaceActions =
  | GetAllInterfacesAction
  | GetAllInterfacesSuccessAction
  | GetAllInterfacesErrorAction
  | CreateInterfaceAction
  | CreateInterfaceSuccessAction
  | CreateInterfaceErrorAction
  | GetInterfaceDependenciesAction
  | GetInterfaceByIdAction
  | GetInterfaceByIdSuccessAction
  | GetInterfaceByIdErrorAction
  | UpdateInterfaceAction
  | UpdateInterfaceSuccessAction
  | UpdateInterfaceErrorAction
  | DeleteInterfaceAction
  | DeleteInterfaceSuccessAction
  | DeleteInterfaceErrorAction;
