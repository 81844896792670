import styled from 'styled-components';
import { ToastContainer as ReactToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ToastContainer = styled(ReactToastContainer).attrs({
  closeButton: false,
  transition: undefined,
})`
  width: 382px;
  height: 87px;
  padding: 0;

  .Toastify__toast-container--top-right {
  }
  .Toastify__toast {
    width: 382px;
    height: 87px;
    padding: 8px 16px;
    box-shadow: ${({ theme }): string => theme.shadow.prominent};
    border-radius: 4px;
  }
  .Toastify__toast--default {
  }
  .Toastify__toast--success {
    background: ${({ theme }): string => theme.palette.white};
  }
  .Toastify__toast--info {
  }
  .Toastify__toast--warning {
    background: ${({ theme }): string => theme.palette.white};
  }
  .Toastify__toast--error {
    background: ${({ theme }): string => theme.palette.white};
  }
  .Toastify__toast-body {
    margin: 0;
  }
  .Toastify__progress-bar {
  }
  .Toastify__toast-body > div:nth-child(2) {
    /* NOTE: Message content */
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Container = styled.div<{ simpleMessage?: boolean }>`
  && {
    display: ${({ simpleMessage }): string => (simpleMessage ? 'grid' : 'flex')};
    grid-template: auto / 1fr 12px;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }
`;

export const Message = styled.p`
  && {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: ${({ theme }): string => theme.fontSize.medium};
    color: ${({ theme }): string => theme.palette.text};
  }
`;

export const CloseIcon = styled.div`
  && {
    align-self: flex-start;
    color: ${({ theme }): string => theme.palette.dark};
  }
`;
