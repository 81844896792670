import { IDesignConsideration } from 'state_management/reducers/designConsiderations/Modals';

export const deserializeDesignConsideration = (
  designConsideration: Partial<IDesignConsideration>,
): Partial<Raw.IDesignConsideration> => ({
  id: designConsideration.id,
  created_at: designConsideration.createdAt,
  modified_at: designConsideration.modifiedAt,
  name: designConsideration.name,
  short_description: designConsideration.description,
  hyperlink: designConsideration.hyperlink,
});

export const serializeDesignConsideration = (designConsideration: Raw.IDesignConsideration): IDesignConsideration => ({
  id: designConsideration.id,
  createdAt: designConsideration.created_at,
  modifiedAt: designConsideration.modified_at,
  name: designConsideration.name,
  description: designConsideration.short_description,
  hyperlink: designConsideration.hyperlink,
});
