import { IBillOfMaterial, IRappidGraphWithChildren } from 'state_management/reducers/billOfMaterial/Modals';
import { deserializeBillOfMaterials } from 'utils/billOfMaterialsSerializer';
import { IPagination } from 'models/Pagination';
import {
  GetAllBillOfMaterialsAction,
  CreateBillOfMaterialAction,
  UpdateBillOfMaterialAction,
  DeleteBillOfMaterialAction,
  BillOfMaterialActionTypes,
  GetAllBillOfMaterialsSuccessAction,
  GetAllBillOfMaterialsErrorAction,
  ResetBillOfMaterialAction,
  UpdateHierarchicalBomAction,
  InitHierarchicalBomAction,
  InitHierarchicalBomSuccessAction,
  InitHierarchicalBomErrorAction,
  GetBomTransitionState,
  GetBomTransitionStateSuccess,
  GetBomTransitionStateFail,
  ResetBomTransitionState,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

// ============================================ GET_ALL_BILL_OF_MATERIAL
export const getAllBillOfMaterials = (
  projectId: string,
  pagination: Partial<IPagination>,
): GetAllBillOfMaterialsAction => ({
  payload: {
    projectId,
    pagination,
  },
  type: BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS,
});

export const getAllBillOfMaterialsSuccessAction = (
  pagination: IPagination,
  data: Array<IBillOfMaterial>,
): GetAllBillOfMaterialsSuccessAction => ({
  payload: { pagination, data },
  type: BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS_SUCCESS,
});

export const getAllBillOfMaterialsErrorAction = (payload: string): GetAllBillOfMaterialsErrorAction => ({
  payload,
  type: BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS_FAIL,
});

// ============================================ CREATE_BILL_OF_MATERIAL
export const createBillOfMaterial = (designBlock: Partial<IBillOfMaterial>): CreateBillOfMaterialAction => ({
  type: BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/canvas/bom`, 2),
      data: deserializeBillOfMaterials(designBlock),
    },
  },
});

// ============================================ UPDATE_BILL_OF_MATERIAL
export const updateBillOfMaterial = (designBlock: IBillOfMaterial): UpdateBillOfMaterialAction => ({
  type: BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/canvas/bom/${designBlock.partNumber}`, 2),
      data: deserializeBillOfMaterials(designBlock),
    },
  },
});

// ============================================ DELETE_BILL_OF_MATERIAL
export const deleteBillOfMaterial = (designBlock: Partial<IBillOfMaterial>): DeleteBillOfMaterialAction => ({
  type: BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/canvas/bom/${designBlock.partNumber}`, 2),
    },
  },
});

// ============================================ RESET_BILL_OF_MATERIAL
export const resetBillOfMaterialAction = (): ResetBillOfMaterialAction => ({
  type: BillOfMaterialActionTypes.RESET_BILL_OF_MATERIAL,
});

// ============================================ HIERARCHICAL BOM
export const updateHierarchicalBomAction = (payload: Array<IRappidGraphWithChildren>): UpdateHierarchicalBomAction => ({
  payload,
  type: BillOfMaterialActionTypes.UPDATE_HIERARCHICAL_BOM,
});

export const initHierarchicalBomAction = (): InitHierarchicalBomAction => ({
  type: BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM,
});

export const initHierarchicalBomSuccessAction = (): InitHierarchicalBomSuccessAction => ({
  type: BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_SUCCESS,
});

export const initHierarchicalBomErrorAction = (payload: string): InitHierarchicalBomErrorAction => ({
  payload,
  type: BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_FAIL,
});

// =========================================== TRANSITION_STATE_GET
export const getBomTransitionState = (payload: { projectId: string }): GetBomTransitionState => ({
  payload,
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET,
});

export const getBomTransitionStateSuccess = (
  payload: 'idle' | 'outdated' | 'updated',
): GetBomTransitionStateSuccess => ({
  payload,
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET_SUCCESS,
});

export const getBomTransitionStateFail = (): GetBomTransitionStateFail => ({
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET_FAIL,
});

// =========================================== TRANSITION_STATE_RESET
export const resetBomTransitionState = (): ResetBomTransitionState => ({
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_RESET,
});
