import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  InterfaceActionTypes,
  GetAllInterfacesAction,
  GetAllInterfacesSuccessAction,
  GetAllInterfacesErrorAction,
} from 'state_management/actions/interfaces/ActionTypes';

import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  ENDPOINT_INTERFACES,
  getAllInterfacesSuccessAction,
  getAllInterfacesErrorAction,
} from 'state_management/actions/interfaces/interfacesActions';
import { serializeInterface } from 'utils/interfaceSerializer';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';

export function* getAllSaga(
  action: GetAllInterfacesAction,
): Generator<
  | PutEffect<GetAllInterfacesAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllInterfacesSuccessAction | GetAllInterfacesErrorAction>
> {
  try {
    const {
      interfacesState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const { page, perPage, search, advancedSearchQuery } = action.payload;

    // NOTE: for Spec and Interfaces, it could be `full` list rf `paginated`
    const isPaginatedAction = action.payload.queryType === 'paginated';

    const interfacesSearchSchema: TypeSearch = {
      port_type: 'value',
      signal_keys: 'value',
      direction: 'value',
      id_prefix: 'value',
      layer_signal_type: 'value',
    };
    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search || pagination.search), interfacesSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const query = isPaginatedAction
      ? `?pg=${page || pagination.page}&pg_len=${perPage || pagination.perPage}&${searchQuery}`
      : '';

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_INTERFACES}${query}`, 2)),
    )) as AxiosResponse;

    const _pagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllInterfacesSuccessAction(_pagination, res.data.map(serializeInterface)));
  } catch (error) {
    yield put(getAllInterfacesErrorAction(getErrorMessage(error) || 'Fetching interfaces failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(InterfaceActionTypes.GET_ALL_INTERFACES, getAllSaga);
}
