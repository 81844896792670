import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getManufacturerByIdSuccess,
  getManufacturerByIdError,
  ENDPOINT_MANUFACTURERS,
} from 'state_management/actions/manufacturers/manufacturersActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetManufacturerByIdErrorAction,
  GetManufacturerByIdSuccessAction,
  GetManufacturerByIdAction,
  ManufacturersActionTypes,
} from 'state_management/actions/manufacturers/ActionTypes';
import { IManufacturer } from 'state_management/reducers/manufacturers/Modals';
import { serializeManufacturer } from 'utils/manufacturersSerializer';

export function* getByIdSaga(
  action: GetManufacturerByIdAction,
): Generator<
  | PutEffect<GetManufacturerByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetManufacturerByIdSuccessAction | GetManufacturerByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_MANUFACTURERS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedManufacturer: IManufacturer = serializeManufacturer(res.data);

    yield put(getManufacturerByIdSuccess(parsedManufacturer));
  } catch (error) {
    yield put(getManufacturerByIdError(getErrorMessage(error) || 'Fetching manufacturers failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ManufacturersActionTypes.GET_MANUFACTURER_BY_ID, getByIdSaga);
}
