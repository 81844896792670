import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { DesignBlockActionTypes, GetAllDesignBlocksAction } from 'state_management/actions/designBlocks/ActionTypes';
import { getAllDesignBlocksAction } from 'state_management/actions/designBlocks/designBlocksActions';

export function* refreshDesignBlocks(): Generator<PutEffect<GetAllDesignBlocksAction>> {
  yield put(getAllDesignBlocksAction());
}

export function* refreshSaga(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      DesignBlockActionTypes.CREATE_DESIGN_BLOCK_SUCCESS,
      DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_SUCCESS,
      DesignBlockActionTypes.CLONE_DESIGN_BLOCK_SUCCESS,
      DesignBlockActionTypes.DELETE_DESIGN_BLOCK_SUCCESS,
    ],
    refreshDesignBlocks,
  );
}
