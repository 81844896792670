/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  all,
  AllEffect,
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  TakeEffect,
  takeEvery,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { IFileUpload } from 'models/IFileUpload';
import {
  DeleteImplementationFileAction,
  ImplementationActionTypes,
  UploadImplementationFileFailAction,
  UploadImplementationFilesAction,
  UploadImplementationFileSuccessAction,
} from 'state_management/actions/implementations/ActionTypes';
import {
  deleteImplementationFileAction,
  uploadImplementationFileFailAction,
  uploadImplementationFileSuccessAction,
} from 'state_management/actions/implementations/implementationsActions';

const actionsByReducer = {
  [ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE]: {
    success: uploadImplementationFileSuccessAction,
    fail: uploadImplementationFileFailAction,
    delete: deleteImplementationFileAction,
    model: 'ImplementationsFiles',
  },
};

type SuccessAction = (fileUpload: IFileUpload) => UploadImplementationFileSuccessAction;
type FailAction = (fileUpload: IFileUpload) => UploadImplementationFileFailAction;

const processFileInfo = (fileInfo: any, name: string, successAction: SuccessAction, failAction: FailAction): any => {
  if (fileInfo.model === 'CADFileInfo') {
    return put(
      successAction({
        error: '',
        cad: fileInfo.file_type,
        name,
        state: 'idle',
        usedAs: fileInfo.content_types,
        version: fileInfo.file_info.version,
        columns: fileInfo.file_info.columns,
        id: fileInfo.saved_file,
      }),
    );
  }
  if (fileInfo.model === 'FileError' || fileInfo.error) {
    return put(
      failAction({
        error: [`orbit:cadFiles.errors.${fileInfo.error}`, fileInfo.message],
        id: name,
        name,
        state: 'error',
        usedAs: [],
      }),
    );
  }
  if (fileInfo.model === 'UnknownFile') {
    return put(
      failAction({
        error: ['orbit:cadFiles.errors.unknownFile', 'Unknown File'],
        id: name,
        name,
        state: 'error',
        usedAs: [],
      }),
    );
  }
  return put(
    failAction({
      error: ['orbit:cadFiles.errors.unknownError', 'Unknown Error'],
      id: name,
      name,
      state: 'error',
      usedAs: [],
    }),
  );
};

export function* uploadFileSaga(
  fileUpload: IFileUpload,
  reducerType: keyof typeof actionsByReducer,
): Generator<
  | TakeEffect
  | CallEffect<any>
  | PutEffect<
      UploadImplementationFileSuccessAction | UploadImplementationFileFailAction | DeleteImplementationFileAction
    >
> {
  const actions = actionsByReducer[reducerType];

  const formData = new FormData();
  formData.append('file', fileUpload.file?.slice(0) || '', fileUpload.name);

  const fileInfoRes = (yield call(() =>
    axiosInstance.post(apiUri(`/file-checker/file?model=${actions.model}`, 2), formData),
  )) as AxiosResponse;

  const fileInfo = (fileInfoRes?.data as unknown as Raw.IFileChecker)?.response || {};

  if (fileInfo.model === 'CADFileArchive') {
    const files = Object.keys(fileInfo.contents);
    for (let i = 0; i < files.length; i += 1) {
      yield processFileInfo(
        fileInfo.contents[files[i]],
        fileInfo.contents[files[i]].file_name || files[i],
        actions.success,
        actions.fail,
      );
    }
    yield put(
      actions.delete({
        error: '',
        id: fileUpload.name,
        name: fileUpload.name,
        state: 'idle',
        usedAs: [],
      }),
    );
  } else {
    yield processFileInfo(fileInfo, fileUpload.name, actions.success, actions.fail);
  }
}

export function* uploadCADFilesFilesSaga(action: UploadImplementationFilesAction): Generator<AllEffect<any>> {
  const newFiles = action.payload.filter((f) => f.state === 'idle');

  yield all(newFiles.map((f) => uploadFileSaga(f, action.type)));
}

export function* uploadCADFilesFilesSagaWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([takeEvery(ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE, uploadCADFilesFilesSaga)]);
}
