/* eslint-disable dot-notation */

import { AxiosResponse } from 'axios';
import { call, CallEffect, ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { ErmisActionTypes, GetErmisExceptionAction } from 'state_management/actions/ermis/ActionTypes';
import { NewNotification } from 'state_management/actions/notification/ActionTypes';
import { newNotification } from 'state_management/actions/notification/notificationActions';
import {
  BomDownloadIconLoadingFailAction,
  LayoutDownloadIconLoadingFailACtion,
  PdfDownloadIConLoadingFailAction,
  ProjectDownloadFileBomErrorAction,
  ProjectDownloadFileLayoutErrorAction,
  ProjectDownloadFilePdfErrorAction,
  ProjectDownloadFileSchematicsErrorAction,
  ProjectsActionTypes,
  SchematicsDownloadIconLoadingFailAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  bomDownloadIconLoadingFailAction,
  layoutDownloadIconLoadingFailACtion,
  pdfDownloadIConLoadingFailAction,
  schematicsDownloadIconLoadingFailAction,
} from 'state_management/actions/projects/projectsActions';

function* ermistGetExceptionSaga(
  action: GetErmisExceptionAction,
): Generator<
  | CallEffect<AxiosResponse>
  | PutEffect<
      | NewNotification
      | ProjectDownloadFileSchematicsErrorAction
      | ProjectDownloadFileLayoutErrorAction
      | ProjectDownloadFileBomErrorAction
      | ProjectDownloadFilePdfErrorAction
      | PdfDownloadIConLoadingFailAction
      | BomDownloadIconLoadingFailAction
      | SchematicsDownloadIconLoadingFailAction
      | LayoutDownloadIconLoadingFailACtion
    >
> {
  const { projectId: id, fileType: type, defaultError } = action.payload;
  try {
    const ermisResponse = (yield call(() =>
      axiosInstance.get(apiUri(`/ermis/messages/supernova?project_id=${id}&file_type=${type}`, 2)),
    )) as AxiosResponse;

    if (ermisResponse?.data) {
      /* The response can not be translated,
         because it is directly received from the back-end 
      */
      yield put(
        newNotification({
          autoClose: false,
          type: 'error',
          message: ermisResponse.data['message'],
        }),
      );

      if (defaultError.type === ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_FAIL) {
        yield put(bomDownloadIconLoadingFailAction());
      } else if (defaultError.type === ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_FAIL) {
        yield put(pdfDownloadIConLoadingFailAction());
      } else if (defaultError.type === ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL) {
        yield put(schematicsDownloadIconLoadingFailAction());
      } else {
        yield put(layoutDownloadIconLoadingFailACtion());
      }
    } else {
      /* If Ermis response data is empty or does not exist,
         simply show the default error that has been already prepared */
      yield put(defaultError);
    }
  } catch {
    /* The default error has been already prepared.
       If there is any problem with getting the exact error by Ermis service
       Just show the default error message there */
    yield put(defaultError);
  }
}

const ermisSagaWatcherFunc = function* ermisSagaWatcher(): Generator<ForkEffect> {
  yield takeLatest(ErmisActionTypes.GET_ERMIS_EXCEPTIONS, ermistGetExceptionSaga);
};

export default ermisSagaWatcherFunc;
