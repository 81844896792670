import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetSpecificationByIdAction,
  GetSpecificationByIdErrorAction,
  GetSpecificationByIdSuccessAction,
  SpecificationActionTypes,
} from 'state_management/actions/specifications/ActionTypes';
import {
  ENDPOINT_SPECIFICATIONS,
  getSpecificationByIdError,
  getSpecificationByIdSuccess,
} from 'state_management/actions/specifications/specificationsActions';
import { ISpecification } from 'state_management/reducers/specifications/Modals';
import { serializeSpecification } from 'utils/specificationsSerializer';

export function* getByIdSaga(
  action: GetSpecificationByIdAction,
): Generator<
  | PutEffect<GetSpecificationByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetSpecificationByIdSuccessAction | GetSpecificationByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedSpecification: ISpecification = serializeSpecification(res.data);

    yield put(getSpecificationByIdSuccess(parsedSpecification));
  } catch (error) {
    yield put(
      getSpecificationByIdError(
        getErrorMessage(error as AxiosError) || 'Fetching specification failed. Please try again...',
      ),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(SpecificationActionTypes.GET_SPECIFICATION_BY_ID, getByIdSaga);
}
