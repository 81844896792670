import { AxiosResponse } from 'axios';
import i18n from 'i18n/config';
import { PutEffect, CallEffect, TakeEffect, takeLeading, ForkEffect, put, call } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getBomTransitionStateSuccess } from 'state_management/actions/billOfMaterial/billOfMaterialActions';
import {
  CreateBomModificationRulesAction,
  BomModificationActionTypes,
} from 'state_management/actions/bomModification/ActionTypes';
import {
  createBomModificationRulesSuccess,
  createBomModificationRulesFail,
} from 'state_management/actions/bomModification/bomModificationActions';
import { newNotification } from 'state_management/actions/notification/notificationActions';
import { BomModificationResponse } from 'state_management/reducers/bomModification/Modals';
import BomModificationSerializer, { RawBomModificationResponse } from 'utils/serializers/bomModificationSerializer';

function* saveSaga(
  action: CreateBomModificationRulesAction,
): Generator<CallEffect<AxiosResponse<BomModificationResponse>> | PutEffect> {
  try {
    const t = i18n.t.bind(i18n);
    const serializedPayload = BomModificationSerializer.serializeRequestPayload(action.payload);
    const result = (yield call(() =>
      axiosInstance.post(apiUri('dataservice/compiler/bom-modification-rules', 2), serializedPayload),
    )) as AxiosResponse<RawBomModificationResponse>;

    if (result?.data) {
      yield put(createBomModificationRulesSuccess(BomModificationSerializer.deSerializeResponsePayload(result.data)));
      yield put(getBomTransitionStateSuccess('outdated'));
      yield put(
        newNotification({
          type: 'success',
          message: t(
            'supernova:bom.modification.def.save.saga.success',
            "You've successfully added modification rules",
          ),
        }),
      );
      yield put(
        newNotification({
          type: 'warning',
          message: t(
            'supernova:bom.modification.def.post.save.saga.warning',
            'The bom is outdated due to added rules. Please resolve the bom review again.',
          ),
        }),
      );
    } else {
      yield put(createBomModificationRulesFail('FAILED > BOM Modification POST request returns undefined data'));
    }
  } catch (error) {
    yield put(createBomModificationRulesFail('FAILED > BOM Modification POST request encounters an unknown error'));
  }
}

const saveSagaWatcherFunc = function* saveSagaWatcher(): Generator<ForkEffect<TakeEffect>> {
  yield takeLeading(BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE, saveSaga);
};

export default saveSagaWatcherFunc;
