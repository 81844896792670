import CelusIcon from 'components/CelusIcon';
import Loader from 'components/Loader';
import { DEFAULT_THEME } from 'theme';
import { IProps } from './IProps';

import { IconContainer } from './styles';

const ToastIcon = ({ type }: IProps): JSX.Element => {
  let icon: JSX.Element;

  // TODO: [WAPP-1326] Fix Accessing theme in Toast
  const theme = DEFAULT_THEME.supernova;

  switch (type) {
    case 'loading':
      icon = <Loader size={20} />;
      break;

    case 'success':
      icon = <CelusIcon color={theme.palette.success}>success</CelusIcon>;
      break;

    case 'error':
      icon = <CelusIcon color={theme.palette.error}>error</CelusIcon>;
      break;

    case 'warning':
      icon = <CelusIcon color={theme.palette.warning}>warning</CelusIcon>;
      break;

    default:
      return <></>;
  }

  return <IconContainer>{icon}</IconContainer>;
};

export default ToastIcon;
