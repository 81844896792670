import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  CreateChangeRequestSuccessAction,
  RequestsActionTypes,
} from 'state_management/actions/changesRequest/ActionTypes';
import { getAllDesignBlocksAction } from 'state_management/actions/designBlocks/designBlocksActions';
import { getAllImplementations } from 'state_management/actions/implementations/implementationsActions';
import { getAllInterfacesAction } from 'state_management/actions/interfaces/interfacesActions';
import { getAllSpecificationsAction } from 'state_management/actions/specifications/specificationsActions';
import { getAllComponentsAction } from 'state_management/actions/components/componentsActions';
import { getAllRefDesigns } from 'state_management/actions/referenceDesigns/referenceDesignsActions';
import { getAllRequestsAction } from 'state_management/actions/changesRequest/changesRequestActions';
import { getAllCategoriesAction } from 'state_management/actions/categories/categoriesActions';
import { getAllVirtualComponentsAction } from 'state_management/actions/virtualComponents/virtualComponentsActions';
import { ActionCreatorType } from 'utils/dispatchHandler';
import { getAllSuppliersAction } from 'state_management/actions/suppliers/suppliersActions';
import { getAllManufacturersAction } from 'state_management/actions/manufacturers/manufacturersActions';
import { getAllDesignConsiderationsAction } from 'state_management/actions/designConsiderations/designConsiderationsActions';
import { getAllExportTemplatesAction } from 'state_management/actions/exportTemplates/exportTemplatesActions';
import { getAllExportJobsAction } from 'state_management/actions/exportJobs/exportJobsActions';

export function* refreshLibrary(action: CreateChangeRequestSuccessAction): Generator<PutEffect<any>> {
  const getAllAction: Record<Raw.BackendResourceNames, ActionCreatorType<any>> = {
    design_blocks: getAllDesignBlocksAction,
    implementations: getAllImplementations,
    interfaces: getAllInterfacesAction,
    specifications: getAllSpecificationsAction(['implementation', 'pcb']),
    components: getAllComponentsAction,
    projects: getAllRefDesigns,
    taxonomy: getAllCategoriesAction,
    virtual_components: getAllVirtualComponentsAction,
    custom_field_specifications: getAllSpecificationsAction(['custom_field']),
    suppliers: getAllSuppliersAction,
    manufacturers: getAllManufacturersAction,
    design_considerations: getAllDesignConsiderationsAction,
    export_templates: getAllExportTemplatesAction,
    export_jobs: getAllExportJobsAction,
  };
  const meta = action.meta || { previousAction: { forceRefresh: true } };
  if (meta.previousAction?.forceRefresh) yield put(getAllAction[action.payload.data.resource_name]());
}
export function* refreshChangesRequest(): Generator<PutEffect<any>> {
  yield put(getAllRequestsAction());
}

export function* refreshSaga(): Generator<ForkEffect<never>> {
  yield takeLatest([RequestsActionTypes.CREATE_CHANGES_REQUEST_SUCCESS], refreshLibrary);
}
export function* refreshChangeListSaga(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_SUCCESS, RequestsActionTypes.DELETE_CHANGES_REQUEST_SUCCESS],
    refreshChangesRequest,
  );
}
