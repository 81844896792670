import { AxiosResponse } from 'axios';
import { ForkEffect, CallEffect, delay, call, takeLatest, PutEffect } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';

import { AuthActionTypes } from 'state_management/actions/auth/ActionTypes';

export function* authPoolingSaga(): Generator<PutEffect | CallEffect<AxiosResponse | true>> {
  try {
    while (true) {
      yield delay(60 * 1000);

      yield call(() => axiosInstance.get(apiUri('status/auth', 2)));
    }
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('==================================');
      console.log(error);
      console.log('==================================');
    }
  }
}

export function* authPoolingSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(AuthActionTypes.GET_USER_INFO_SUCCESS, authPoolingSaga);
}
