import { AxiosResponse } from 'axios';
import { call, PutEffect, ForkEffect, CallEffect, put, takeLatest } from 'redux-saga/effects';

import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  ProjectsActionTypes,
  ProjectCompileIssuesAction,
  ProjectCompileIssuesErrorAction,
  ProjectCompileIssuesSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  projectCompileIssuesErrorAction,
  projectCompileIssuesSuccessAction,
} from 'state_management/actions/projects/projectsActions';
import { ProjectIssues } from 'state_management/reducers/projects/Modals';

export function* projectCompileIssuesSaga(
  action: ProjectCompileIssuesAction,
): Generator<CallEffect | PutEffect<ProjectCompileIssuesSuccessAction | ProjectCompileIssuesErrorAction>> {
  try {
    const { projectId } = action.payload;
    const response = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/project-issues/${projectId}`, 2)),
    )) as AxiosResponse;
    const issues = <ProjectIssues>{ errors: response?.data?.errors || [], warnings: response?.data?.warnings || [] };
    yield put(projectCompileIssuesSuccessAction(issues));
  } catch (error) {
    yield put(projectCompileIssuesErrorAction(getErrorMessage(error) || "Couldn't fetch issues"));
  }
}

export function* projectCompileIssuesSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECT_COMPILE_ISSUES, projectCompileIssuesSaga);
}
