import { InterfaceType } from 'state_management/reducers/interfaces/Modals';
import { deserializeInterface } from 'utils/interfaceSerializer';
import { IPagination } from 'models/Pagination';
import {
  GetAllInterfacesAction,
  CreateInterfaceAction,
  GetInterfaceByIdAction,
  UpdateInterfaceAction,
  DeleteInterfaceAction,
  InterfaceActionTypes,
  GetInterfaceByIdSuccessAction,
  GetInterfaceByIdErrorAction,
  GetAllInterfacesErrorAction,
  GetAllInterfacesSuccessAction,
  GetInterfaceDependenciesAction,
  CloneInterfaceAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const ENDPOINT_INTERFACES = 'interfaces';

export const getAllInterfacesAction = (payload: Partial<IPagination> = {}): GetAllInterfacesAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: InterfaceActionTypes.GET_ALL_INTERFACES,
});

export const getAllInterfacesSuccessAction = (
  pagination: IPagination,
  data: Array<InterfaceType>,
): GetAllInterfacesSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: InterfaceActionTypes.GET_ALL_INTERFACES_SUCCESS,
});

export const getAllInterfacesErrorAction = (payload: string): GetAllInterfacesErrorAction => ({
  payload,
  type: InterfaceActionTypes.GET_ALL_INTERFACES_FAIL,
});

export const createInterface = (interfaceModel: Partial<InterfaceType>): CreateInterfaceAction => ({
  type: InterfaceActionTypes.CREATE_INTERFACE,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_INTERFACES}`, 2),
      data: deserializeInterface(interfaceModel),
    },
  },
});

export const updateInterface = (interfaceModel: InterfaceType): UpdateInterfaceAction => ({
  type: InterfaceActionTypes.UPDATE_INTERFACE,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_INTERFACES}/${interfaceModel.id}`, 2),
      data: deserializeInterface(interfaceModel),
    },
  },
});

export const cloneInterface = (interfaceModel: Partial<InterfaceType>): CloneInterfaceAction => ({
  type: InterfaceActionTypes.CLONE_INTERFACE,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_INTERFACES}`, 2),
      data: deserializeInterface(interfaceModel),
    },
  },
});

export const deleteInterface = (interfaceModel: Partial<InterfaceType>): DeleteInterfaceAction => ({
  type: InterfaceActionTypes.DELETE_INTERFACE,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_INTERFACES}/${interfaceModel.id}`, 2),
    },
  },
});

export const getInterfaceById = (payload: Partial<InterfaceType>): GetInterfaceByIdAction => ({
  type: InterfaceActionTypes.GET_INTERFACE_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getInterfaceDependenciesAction = (): GetInterfaceDependenciesAction => ({
  type: InterfaceActionTypes.GET_INTERFACE_DEPENDENCIES,
});

export const getInterfaceByIdSuccess = (payload: InterfaceType): GetInterfaceByIdSuccessAction => ({
  type: InterfaceActionTypes.GET_INTERFACE_BY_ID_SUCCESS,
  payload,
});

export const getInterfaceByIdError = (payload: string): GetInterfaceByIdErrorAction => ({
  type: InterfaceActionTypes.GET_INTERFACE_BY_ID_FAIL,
  payload,
});
