import { HybridFormsActions, HybridFormsActionsTypes } from 'state_management/actions/hybridForms/ActionTypes';
import { HybridFormsState } from './Modals';

export const initialStateHybridForms: HybridFormsState = {
  isHybridForm: false,
};

export const hybridFormsReducer = (
  state: HybridFormsState = initialStateHybridForms,
  action: HybridFormsActions,
): HybridFormsState => {
  switch (action.type) {
    case HybridFormsActionsTypes.ACTIVATE_HYBRID_FORMS:
      return {
        ...state,
        isHybridForm: action.payload.isHybridForm,
      };
    default:
      return state;
  }
};
