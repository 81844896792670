import { connect } from 'react-redux';

import { AppState } from 'state_management/AppState';

import Navigation from './Navigation';

import { IProps } from './IProps';

export default connect(
  ({ authState }: AppState): IProps => ({
    userInfo: authState.userInfo,
    featurePermissions: authState.featurePermissions,
  }),
)(Navigation);
