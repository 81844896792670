import { call, CallEffect, ForkEffect, put, PutEffect, takeEvery, select, SelectEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18n/config';

import { apiUri } from 'services/main_app';
import {
  SharingActionTypes,
  UpdateResourceSharingAction,
  UpdateResourceSharingErrorAction,
  UpdateResourceSharingSuccessAction,
} from 'state_management/actions/sharing/ActionTypes';
import {
  updateResourceSharingErrorAction,
  updateResourceSharingSuccessAction,
} from 'state_management/actions/sharing/sharingActions';
import { getErrorMessage } from 'utils/getErrorMessage';
import { deserializeSharingResourceGroups } from 'utils/sharingSerializer';
import { axiosInstance } from 'services/dataService';
import { AppState } from 'state_management/AppState';
import { translateEmailsToIds } from './utils/emailsTranslation';

export function* updateResourceSharingSaga(
  action: UpdateResourceSharingAction,
): Generator<
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<UpdateResourceSharingSuccessAction | UpdateResourceSharingErrorAction>
  | CallEffect
> {
  const { resourceEndpoint, resourceId, resourceDomains } = action.payload;

  const t = i18n.t.bind(i18n);
  try {
    const {
      authState: { featurePermissions },
    } = (yield select((state) => state)) as AppState;

    let data = deserializeSharingResourceGroups(resourceDomains);
    if (featurePermissions.SHARE_WITH_USER.read && data.users) {
      data = {
        ...data,
        users: (yield call(() =>
          translateEmailsToIds(data.users as Raw.IResourceShareItems),
        )) as Raw.IResourceShareItems,
      };
    }

    const res = (yield call(() =>
      axiosInstance.patch(apiUri(`${resourceEndpoint}/${resourceId}/share`, 2), data),
    )) as AxiosResponse<Partial<Raw.IResourceShareItems>>;

    yield put(updateResourceSharingSuccessAction(res.data));
  } catch (error) {
    yield put(
      updateResourceSharingErrorAction(
        getErrorMessage(error as AxiosError) || t('orbit:updateSharingError', 'Updating sharing failed.'),
      ),
    );
  }
}

export function* updateResourceSharingWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(SharingActionTypes.UPDATE_RESOURCE_SHARING, updateResourceSharingSaga);
}
