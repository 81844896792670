import { IPagination } from 'models/Pagination';
import { RequestState } from 'models/RequestState';

export type ProjectImportType = 'localProject' | 'referenceDesign' | 'externalProject' | 'unknown';

export type ICanvasType = 'design' | 'board' | 'bom';
export type IDownloadFileType = 'schematics' | 'layout' | 'bom' | 'pdf';
export type IPaymentFileType = 'schematics' | 'layout' | 'both';

export interface IDownloadFile {
  link: string;
  fileType: IDownloadFileType;
}

export interface IOptimization {
  factor: string;
  label: string;
  unit: string;
  value: number;
  target: 'maximize' | 'minimize';
}

export type EntityState = 'enabled' | 'disabled' | 'error' | 'warning';
export type CompilationState = 'enabled' | 'disabled' | 'error' | 'running' | 'success';

export interface ICanvasStates {
  designCanvas: EntityState;
  bomCanvas: EntityState;
  boardCanvas: EntityState;
}

export type FileType = 'sch' | 'brd' | 'bom' | 'pdf';

export type ICadFilesState = {
  [Property in FileType]: EntityState;
};

export interface ICompilationStates {
  design: CompilationState;
  board: CompilationState;
}

export type Canvas = 'design' | 'board';

export interface IssuesData {
  canvas: Canvas;
  element_uids: string[];
  message: string;
  title: string;
}

export interface ProjectIssues {
  errors: Array<IssuesData>;
  warnings: Array<IssuesData>;
}

export enum DashboardProjectTypes {
  PRIVATE = 'private',
  SHARED_BY_ME = 'shared-by-me',
  SHARED_WITH_ME = 'shared-with-me',
  ALL = 'all',
}

export interface Project {
  id: string;
  userId?: string;
  title: string;
  description: string;
  image: string;
  projectType?: DashboardProjectTypes;
  shareType: Array<Raw.IShareType>;
  canvasStates: ICanvasStates;
  compilerState: Raw.CompilerState;
  cadTool?: Raw.CadToolID;
  cadFilesState: ICadFilesState;
  pcbTechnology: string;
  createdAt: string;
  updatedAt: string;
  optimizations: Array<IOptimization>;
  downloadFiles: Array<IDownloadFile>;
  compilationStates: ICompilationStates;
  isRefDesign: boolean;
  originalProj?: string;
  bomType?: Raw.BomType;
  routing: boolean;
  workspaces: string[] | null;
  issues?: ProjectIssues;
  numberOfLayers?: number;
}

export enum DashboardDisplayModes {
  GRID = 'grid',
  LIST = 'list',
}

export enum DashboardSortingTypes {
  LAST_MODIFIED = 'sorting.lastModified',
  ALPHABETICALLY = 'sorting.alphabetically',
  LAST_CREATED = 'sorting.lastCreated',
}

export interface ProjectsState {
  pagination: IPagination;
  currentProject: Project;
  projects: Array<Project>;
  projectsGetRequest: RequestState;
  projectsGetRequestError: string;
  projectsGetAllRequest: RequestState;
  projectsGetAllRequestError: string;
  projectsCloneRequest: RequestState;
  projectsCloneRequestError: string;
  projectStateRequestState: RequestState;
  projectStateRequestError: string;
  projectCompileIssuesRequest: RequestState;
  projectCompileIssuesRequestError: string;
  designCompileRequest: RequestState;
  designCompileRequestError: string;
  boardCompileRequest: RequestState;
  boardCompileRequestError: string;
  projectFileDownloadSchematics: RequestState;
  projectFileDownloadSchematicsError: string;
  projectFileDownloadLayout: RequestState;
  projectFileDownloadLayoutError: string;
  projectFileDownloadBom: RequestState;
  projectFileDownloadBomError: string;
  projectFileDownloadPdf: RequestState;
  projectFileDownloadPdfError: string;
  projectImageUpdateRequest: RequestState;
  projectImageUpdateRequestError: string;
  projectUpdateRequest: RequestState;
  projectUpdateRequestError: string;
  projectImportRequest: RequestState;
  projectImportError: string;
  optimizationOptions: Array<IOptimization>;
  viewOptions: {
    displayMode: DashboardDisplayModes;
    sortType: DashboardSortingTypes;
    sharingFilter: DashboardProjectTypes;
  };
  waitingPaymentForFiles: Array<IPaymentFileType>;
}
