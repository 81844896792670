import { FeaturePermissions } from 'state_management/reducers/auth/Modals';
import { OptionModel } from 'components/Select/Modals';

export function compareAlphabeticallyEnabledOptions(a: OptionModel, b: OptionModel): number {
  if (a.disabled && !b.disabled) {
    return 1;
  }

  if (!a.disabled && b.disabled) {
    return -1;
  }

  if (a.label > b.label) {
    return 1;
  }

  return -1;
}

export const listCadTools = (permissions: FeaturePermissions): OptionModel<Raw.CadToolID>[] =>
  [
    // NOTE: do NOT modify `value` otherwise will break existing projects and reference designs
    {
      label: 'Altium Designer',
      value: 'altium' as Raw.CadToolID,
      disabled: !permissions.SUPERNOVA_SHOW_CAD_TOOL_ALTIUM?.read,
    },
    {
      label: 'Autodesk Eagle',
      value: 'eagle' as Raw.CadToolID,
      disabled: !permissions.SUPERNOVA_SHOW_CAD_TOOL_EAGLE?.read,
    },
    {
      label: 'Zuken Cadstar',
      value: 'cadstar' as Raw.CadToolID,
      disabled: !permissions.SUPERNOVA_SHOW_CAD_TOOL_CADSTAR?.read,
    },
  ].sort(compareAlphabeticallyEnabledOptions);

export const getDefaultCadTool = (cadTools: OptionModel<Raw.CadToolID>[]): Raw.CadToolID | undefined => {
  const defaultCadTool = cadTools.find((item) => item.value === 'eagle' && !item.disabled);

  if (!defaultCadTool) {
    const enabledCadTool = cadTools.find((item) => !item.disabled);
    return enabledCadTool?.value;
  }
  return defaultCadTool.value;
};

export const listBomTypes = (permissions: FeaturePermissions): OptionModel<Raw.BomType>[] =>
  [
    { label: 'Consolidated', value: 'consolidated' as Raw.BomType, disabled: false },
    { label: 'Flat', value: 'flat' as Raw.BomType, disabled: !permissions.SUPERNOVA_SHOW_BOM_TYPE_FLAT?.read },
  ]
    .filter((item) => !item.disabled)
    .sort(compareAlphabeticallyEnabledOptions);

export const getDefaultBomType = (bomTypes: OptionModel<Raw.BomType>[]): Raw.BomType | undefined =>
  bomTypes.find((item) => !item.disabled)?.value;
