import { connect } from 'react-redux';

import { AppState } from 'state_management/AppState';

import Component from './PoweredByCelus';

import { IProps } from './IProps';

const mapStateToProps = (state: AppState): Omit<IProps, 'hasStatusBar'> => ({
  poweredByCelus: state.authState.activeTheme?.show_powered_by_celus,
});

export default connect(mapStateToProps)(Component);
