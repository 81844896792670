export const doubleUriEncodeSpecailChars = (search: string): string =>
  search
    .replace(/&/g, encodeURIComponent(encodeURIComponent('&')))
    .replace(/\+/g, encodeURIComponent(encodeURIComponent('+')))
    .replace(/#/g, encodeURIComponent(encodeURIComponent('#')));

/**
 * The purpose of this utility is to escape quotations without using `JSON.stringify`
 * which is much more costly operation also cause everything else (which needs escaping) in our search query
 * is already escaped by `axios` with [exception](https://github.com/axios/axios/blob/b7e954eba3911874575ed241ec2ec38ff8af21bb/lib/helpers/buildURL.js#L5)
 */
export const escapeSearch = (search: string): string => {
  if (!search) {
    return '';
  }

  //  This might be refactored if the express-app is discarded
  //  The double uri encoding with some special characters was implemented so
  //  the backend can get these characters without converting them to something else.
  return doubleUriEncodeSpecailChars(search).replace(/[\\"']/g, '\\$&');
};

export const DEV_ORIGINS = [
  'localhost',
  '127.0.0.1',
  'celus-vps',
  '80.241.208.253',
  'nightly.celus.co',
  'staging.celus.co',
];

export const isWhitelistedOrigin = (whitelist?: Array<string>): boolean => {
  const url = new URL(window.location as unknown as string);
  return (whitelist || DEV_ORIGINS).some((o) => url.origin.includes(o));
};

export const appendURLQueryStr = (currentURL: string, key: string, value: string): string => {
  const url = new URL(currentURL);

  const params = new URLSearchParams(url.search);
  params.set(key, value);

  url.search = params.toString();

  return url.toString();
};
