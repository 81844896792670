import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  GetImplementationByIdAction,
  GetImplementationDependenciesAction,
  ImplementationActionTypes,
} from 'state_management/actions/implementations/ActionTypes';
import {
  GetInterfaceByIdAction,
  GetInterfaceDependenciesAction,
  InterfaceActionTypes,
} from 'state_management/actions/interfaces/ActionTypes';
import { ComponentActionTypes, GetComponentDependenciesAction } from 'state_management/actions/components/ActionTypes';
import {
  DesignBlockActionTypes,
  GetDesignBlockDependenciesAction,
} from 'state_management/actions/designBlocks/ActionTypes';

import {
  SpecificationActionTypes,
  GetAllSpecificationsAction,
  CreateSpecificationSuccessAction,
  DeleteSpecificationSuccessAction,
  UpdateSpecificationSuccessAction,
} from 'state_management/actions/specifications/ActionTypes';
import { getAllSpecificationsAction } from 'state_management/actions/specifications/specificationsActions';
import { GetAllChangesRequestsAction, RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { CategoriesActionTypes } from 'state_management/actions/categories/ActionTypes';
import { ChangeLogActionTypes, GetAllChangeLogAction } from 'state_management/actions/changeLog/ActionTypes';
import { SpecificationContext } from 'state_management/reducers/specifications/Modals';
import {
  GetVirtualComponentDependenciesAction,
  VirtualComponentsActionTypes,
} from 'state_management/actions/virtualComponents/ActionTypes';

export function* refreshSpecifications(
  action:
    | GetImplementationByIdAction
    | GetInterfaceByIdAction
    | CreateSpecificationSuccessAction
    | UpdateSpecificationSuccessAction
    | DeleteSpecificationSuccessAction
    | GetImplementationDependenciesAction
    | GetDesignBlockDependenciesAction
    | GetInterfaceDependenciesAction
    | GetComponentDependenciesAction
    | GetAllChangesRequestsAction
    | GetAllChangeLogAction
    | GetVirtualComponentDependenciesAction,
): Generator<PutEffect<GetAllSpecificationsAction>> {
  const isFetchFullListAction = [
    DesignBlockActionTypes.GET_DESIGN_BLOCK_DEPENDENCIES,
    ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
    InterfaceActionTypes.GET_INTERFACE_DEPENDENCIES,
    ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
    RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
    CategoriesActionTypes.GET_CATEGORY_DEPENDENCIES,
    ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
    VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
  ].includes(
    action.type as
      | DesignBlockActionTypes
      | ImplementationActionTypes
      | InterfaceActionTypes
      | ComponentActionTypes
      | RequestsActionTypes
      | ChangeLogActionTypes
      | VirtualComponentsActionTypes,
  );

  // By default all the resources requires the implementation spec types
  const specTypes: Array<SpecificationContext> = ['implementation'];
  if (
    [
      ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
      RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
      ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
    ].includes(action.type as ImplementationActionTypes | RequestsActionTypes | ChangeLogActionTypes)
  ) {
    // implementation (aka cubo) requires additionally PCB specs
    specTypes.push('pcb');
  }

  yield put(getAllSpecificationsAction(specTypes)({ queryType: isFetchFullListAction ? 'full' : 'paginated' }));
}

export function* refreshSaga(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      SpecificationActionTypes.CREATE_SPECIFICATION_SUCCESS,
      SpecificationActionTypes.UPDATE_SPECIFICATION_SUCCESS,
      SpecificationActionTypes.CLONE_SPECIFICATION_SUCCESS,
      SpecificationActionTypes.DELETE_SPECIFICATION_SUCCESS,

      // NOTE: Need all specs for Implementation, Concept and Interface Edit and Create Forms
      DesignBlockActionTypes.GET_DESIGN_BLOCK_DEPENDENCIES,
      ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
      InterfaceActionTypes.GET_INTERFACE_DEPENDENCIES,
      ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
      RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
      ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
      CategoriesActionTypes.GET_CATEGORY_DEPENDENCIES,
      VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
    ],
    refreshSpecifications,
  );
}
