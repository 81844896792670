export enum SupportActionTypes {
  GET_BROWSER_SUPPORT = 'GET_BROWSER_SUPPORT',
}

// ============================================ GET_BROWSER_SUPPORT

export interface GetBrowserSupportAction {
  type: SupportActionTypes.GET_BROWSER_SUPPORT;
}

// ============================================ ALL

export type SupportActions = GetBrowserSupportAction;
