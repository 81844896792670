import { AppType, Languages, RawPermissionResponse, User, UserSettings } from 'state_management/reducers/auth/Modals';

import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum AuthActionTypes {
  LOGIN = 'LOGIN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAIL = 'LOGIN_FAIL',

  UNAUTHORIZED_USER_ACTION = 'UNAUTHORIZED_USER_ACTION',

  GET_USER_INFO = 'GET_USER_INFO',
  GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS',
  GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL',

  GET_PERMISSIONS = 'GET_PERMISSIONS',
  GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS',
  GET_PERMISSIONS_FAIL = 'GET_PERMISSIONS_FAIL',

  LOGOUT = 'LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAIL = 'LOGOUT_FAIL',

  UPDATE_APP_TYPE = 'UPDATE_APP_TYPE',

  GET_I18N_TRANSLATIONS = 'GET_I18N_TRANSLATIONS',
  GET_I18N_TRANSLATIONS_SUCCESS = 'GET_I18N_TRANSLATIONS_SUCCESS',

  GET_AUTH_ISSUER = 'GET_AUTH_ISSUER',
  GET_AUTH_ISSUER_SUCCESS = 'GET_AUTH_ISSUER_SUCCESS',
  GET_AUTH_ISSUER_FAIL = 'GET_AUTH_ISSUER_FAIL',

  GET_ACTIVE_THEME = 'GET_ACTIVE_THEME',
  GET_ACTIVE_THEME_SUCCESS = 'GET_ACTIVE_THEME_SUCCESS',
  GET_ACTIVE_THEME_FAIL = 'GET_ACTIVE_THEME_FAIL',

  UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS',
}

// ============================================ LOGIN

export interface LoginAction {
  type: AuthActionTypes.LOGIN;
}

export interface LoginSuccessAction extends AxiosSuccessPayload<User, AuthActionTypes.LOGIN_SUCCESS, LoginAction> {
  type: AuthActionTypes.LOGIN_SUCCESS;
}

export interface LoginErrorAction extends AxiosErrorPayload<AuthActionTypes.LOGIN_FAIL, LoginAction> {
  type: AuthActionTypes.LOGIN_FAIL;
}

export interface UnauthorizedUserAction {
  type: AuthActionTypes.UNAUTHORIZED_USER_ACTION;
}

// ============================================ GET_USER_INFO

export interface GetUserInfoAction extends AxiosRequestPayload {
  type: AuthActionTypes.GET_USER_INFO;
}
export interface GetUserInfoSuccessAction
  extends AxiosSuccessPayload<Partial<User>, AuthActionTypes.GET_USER_INFO_SUCCESS, GetUserInfoAction> {
  type: AuthActionTypes.GET_USER_INFO_SUCCESS;
}
export interface GetUserInfoErrorAction
  extends AxiosErrorPayload<AuthActionTypes.GET_USER_INFO_FAIL, GetUserInfoAction> {
  type: AuthActionTypes.GET_USER_INFO_FAIL;
}

// ============================================ PERMISSIONS

export interface GetPermissionsAction extends AxiosRequestPayload {
  type: AuthActionTypes.GET_PERMISSIONS;
}

export interface GetPermissionsSuccessAction
  extends AxiosSuccessPayload<
    Partial<RawPermissionResponse>,
    AuthActionTypes.GET_PERMISSIONS_SUCCESS,
    GetPermissionsAction
  > {
  type: AuthActionTypes.GET_PERMISSIONS_SUCCESS;
}

export interface GetPermissionsErrorAction
  extends AxiosErrorPayload<AuthActionTypes.GET_PERMISSIONS_FAIL, GetPermissionsAction> {
  type: AuthActionTypes.GET_PERMISSIONS_FAIL;
}

// ============================================ LOGOUT

export interface LogoutAction {
  type: AuthActionTypes.LOGOUT;
  payload?: string;
}

export interface LogoutSuccessAction {
  type: AuthActionTypes.LOGOUT_SUCCESS;
}

export interface LogoutErrorAction {
  type: AuthActionTypes.LOGOUT_FAIL;
  payload: string;
}

// ============================================ UPDATE_APP_TYPE

export interface UpdateAppTypeAction {
  payload: AppType;
  type: AuthActionTypes.UPDATE_APP_TYPE;
}

// ============================================ I18N

export interface GetI18nTranslations extends AxiosRequestPayload {
  type: AuthActionTypes.GET_I18N_TRANSLATIONS;
}

export interface GetI18nTranslationsSuccess
  extends AxiosSuccessPayload<Languages, AuthActionTypes.GET_I18N_TRANSLATIONS_SUCCESS, GetI18nTranslations> {
  type: AuthActionTypes.GET_I18N_TRANSLATIONS_SUCCESS;
}

// ============================================ I18N

export interface GetAuthIssuer extends AxiosRequestPayload {
  type: AuthActionTypes.GET_AUTH_ISSUER;
}

export interface GetAuthIssuerSuccess
  extends AxiosSuccessPayload<any, AuthActionTypes.GET_AUTH_ISSUER_SUCCESS, GetAuthIssuer> {
  type: AuthActionTypes.GET_AUTH_ISSUER_SUCCESS;
  payload: any;
}

export interface GetAuthIssuerErrorAction
  extends AxiosErrorPayload<AuthActionTypes.GET_AUTH_ISSUER_FAIL, GetAuthIssuer> {
  type: AuthActionTypes.GET_AUTH_ISSUER_FAIL;
}

// ============================================ ACTIVE_THEME

export interface GetActiveThemeAction {
  type: AuthActionTypes.GET_ACTIVE_THEME;
  // NOTE: Actions with `endpoints` are handled by CRUD Utilities Middleware
  // Check README under `src/state_management/sagas/utils/crudUtilities/`
  endpoint: string;
}

export interface GetActiveThemeSuccessAction {
  payload: Raw.ITheme;
  type: AuthActionTypes.GET_ACTIVE_THEME_SUCCESS;
}

export interface GetActiveThemeErrorAction {
  payload: string;
  type: AuthActionTypes.GET_ACTIVE_THEME_FAIL;
}

// ============================================ UPDATE_USER_SETTINGS

export interface UpdateUserSettingsAction {
  payload: UserSettings;
  type: AuthActionTypes.UPDATE_USER_SETTINGS;
}

// ============================================ ALL

export type AuthActions =
  | LoginAction
  | UnauthorizedUserAction
  | GetUserInfoAction
  | GetUserInfoSuccessAction
  | GetUserInfoErrorAction
  | LogoutAction
  | LogoutSuccessAction
  | LogoutErrorAction
  | UpdateAppTypeAction
  | GetI18nTranslations
  | GetI18nTranslationsSuccess
  | GetPermissionsAction
  | GetPermissionsSuccessAction
  | GetPermissionsErrorAction
  | GetAuthIssuer
  | GetAuthIssuerSuccess
  | GetAuthIssuerErrorAction
  | GetActiveThemeAction
  | GetActiveThemeSuccessAction
  | GetActiveThemeErrorAction
  | UpdateUserSettingsAction;
