import { OPERATORS } from 'components/AdvancedSearch/AdvancedSearchForm/AdvancedSearchForm';
import { getOperatorDisplayLabel } from 'components/AdvancedSearch/AdvancedSearchForm/utils/getChipLabel';
import { SearchValue, UnitValue, _SearchValue } from 'components/AdvancedSearch/Modals';
import { blockNameFormatting } from 'components/CanvasV2/utils/blockNameFormatting';
import { unescapeXmlText } from 'components/CanvasV2/utils/escapeXmlChars';
import { BLOCK_NAMESPACES, IBlockV2, ICanvasData } from 'models/ICanvas';
import { ISpecificationFilter } from 'state_management/reducers/specifications/Modals';
import { IDesignCanvas, IDesignCanvasBlock, RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';
import { deserializeDynamicOption, serializeDynamicOption } from './dynamicOptionsSerializer';

export function serializeDesignCanvasBlock(payload: RawDesignCanvas.Block): IDesignCanvasBlock {
  return {
    id: payload.id,
    name: payload.id,
    type: payload.type,
    position: payload.position,
    options: payload.options.map(serializeDynamicOption),
    functions: payload.functions,
    ports: payload.ports.map((p) => ({
      id: p.id,
      name: p.name,
      portId: p.id,
      direction: p.direction,
      createdAt: '',
      type: p.types && p.types[0],
      originalType: p.types && p.types[0],
      position: p.position,
    })),
  };
}

export function deserializeDesignCanvasBlock(
  payload: Partial<ICanvasData<IDesignCanvas>>,
): Partial<Omit<RawDesignCanvas.Canvas, 'library'>> {
  return {
    ...payload.design,
    blocks: payload.design?.blocks?.map((b) => ({ ...b, options: b.options.map(deserializeDynamicOption) })),
    settings: payload.design?.settings?.map(deserializeDynamicOption),
  };
}

export function deserializeRappidDesignCanvasBlock(payload: RappidGraph): RawRappidDesignCanvas.CanvasItem {
  return {
    ...payload,
    attrs: Object.entries(payload.attrs).reduce((a, [key, value]) => {
      // unescaped escape xml text
      const unescapedXmlValue: any = Object.fromEntries(
        Object.entries(value).map(([k, v]) => [k, typeof v === 'string' ? unescapeXmlText(v) : v]),
      );
      return { ...a, [key.replace('.', 'class-')]: unescapedXmlValue };
    }, {}),
  } as RawRappidDesignCanvas.CanvasItem;
}

const getValuesFromOldData = (value: _SearchValue, _unit?: Array<string>): SearchValue => {
  let values: SearchValue = {};

  const _value =
    (value as { min: UnitValue; max: UnitValue })?.min?.value ||
    (value as UnitValue).value ||
    (value as string | number | Array<string>);
  if (_value) {
    values = { ...values, value: _value };
  }

  const unit =
    (value as { min: UnitValue; max: UnitValue })?.min?.unit ||
    (value as UnitValue).unit ||
    (_unit?.length && _unit[0]) ||
    '';
  if (unit) {
    values = { ...values, unit };
  }

  const max = (value as { min: UnitValue; max: UnitValue }).max?.value;
  if (max) {
    values = { ...values, max };
  }

  const maxUnit = (value as { min: UnitValue; max: UnitValue })?.max?.unit;
  if (maxUnit) {
    values = { ...values, maxUnit };
  }

  return values;
};

// NOTE: This is some kind of an internal migration that will fix old blocks that contains old data structure
/* Single and List uses the root operator whereas minMax and typTol have their own opertor fields */
export const fixSpecificationFilters = (
  specificationFilter: ISpecificationFilter & { value: any },
): ISpecificationFilter & { value: undefined } => {
  if (['single', 'list'].includes(specificationFilter?.field?.inputType)) {
    return {
      ...specificationFilter,
      operator: {
        ...specificationFilter.operator,
        chipDisplayLabel: specificationFilter.operator.value
          ? getOperatorDisplayLabel(
              // NOTE: old filters have field named value that was deleted, adding this code to use that
              (specificationFilter?.userValues as SearchValue) ||
                (specificationFilter?.values as SearchValue) ||
                getValuesFromOldData(specificationFilter?.value, specificationFilter?.field?.units),
              specificationFilter.field,
              OPERATORS[specificationFilter?.operator?.value]?.chipDisplayLabel,
            )
          : specificationFilter?.operator?.chipDisplayLabel,
      },
      value: undefined,
      values:
        specificationFilter?.values ||
        getValuesFromOldData(specificationFilter?.value, specificationFilter?.field?.units),
    };
  }
  return specificationFilter;
};

// NOTE: This is some kind of an internal migration that will fix old blocks that contains old data structure
export const fixInfoAndDescription = (payloadData: Record<string, any>): Record<string, any> => ({
  ...payloadData,
  description: payloadData.shortInfo || payloadData.description,
  info: payloadData.fullInfo || payloadData.info,
});

export function serializeRappidDesignCanvasBlock(payload: RawRappidDesignCanvas.CanvasItem): RappidGraph<IBlockV2> {
  const iconLessBlock = ([BLOCK_NAMESPACES.TEXT_ONLY, BLOCK_NAMESPACES.TRANSPARENT] as Array<string>).includes(
    payload.data.namespace,
  );

  const blockStateIcon =
    payload.type === 'app.DefaultLink'
      ? {
          line: {
            ...payload.attrs.line,
            // NOTE: Indicate Error on Link with Dashed Link style
            strokeDasharray: payload.data.state === 'error' ? '8,8' : '0',
          },
        }
      : {
          ...(payload.data.namespace === BLOCK_NAMESPACES.BASIC
            ? {
                // NOTE: Only Basic Blocks have PortNumber and Color for now
                '.block-portNumber': {
                  text: payload.data.socketNum || '',
                },
                '.block-click-area': { stroke: payload.data.color || 'transparent' },
              }
            : {}),
          '.block-icon': { class: `block-icon celusico-${payload.data.icon || 'chip_mcu'}` },
          '.block-name': {
            text: blockNameFormatting(payload.data.name || '', payload.size?.width || 100, iconLessBlock ? 2 : 1).join(
              '\n',
            ),
          },
          '.canvas-block': { title: payload.data.name },
          '.block-status-icon': {
            class: `block-status-icon celusico-search-${payload.data.state}`,
          },
        };

  const specificationFilters = payload.data.specificationFilters?.map(fixSpecificationFilters);

  const payloadData = Object.keys(payload.data).length ? fixInfoAndDescription(payload.data) : payload.data;

  return {
    ...payload,
    attrs: {
      // NOTE: replace `class-mockClassName` prop with `.-mockClassName`
      // as MongoDB doesn't allow `.` props, we did that to save and revert it here
      ...Object.entries(payload.attrs).reduce((a, [key, value]) => ({ ...a, [key.replace('class-', '.')]: value }), {}),
      ...blockStateIcon,
    },
    data: {
      ...payloadData,
      specificationFilters,
    },
  } as RappidGraph<IBlockV2>;
}
