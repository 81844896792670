import { IPagination } from 'models/Pagination';
import { ISupplier } from 'state_management/reducers/suppliers/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum SuppliersActionTypes {
  GET_ALL_SUPPLIERS = 'GET_ALL_SUPPLIERS',
  GET_ALL_SUPPLIERS_SUCCESS = 'GET_ALL_SUPPLIERS_SUCCESS',
  GET_ALL_SUPPLIERS_FAIL = 'GET_ALL_SUPPLIERS_FAIL',

  GET_SUPPLIER_DEPENDENCIES = 'GET_SUPPLIER_DEPENDENCIES',

  GET_SUPPLIER_BY_ID = 'GET_SUPPLIER_BY_ID',
  GET_SUPPLIER_BY_ID_SUCCESS = 'GET_SUPPLIER_BY_ID_SUCCESS',
  GET_SUPPLIER_BY_ID_FAIL = 'GET_SUPPLIER_BY_ID_FAIL',

  CREATE_SUPPLIER = 'CREATE_SUPPLIER',
  CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS',
  CREATE_SUPPLIER_FAIL = 'CREATE_SUPPLIER_FAIL',

  UPDATE_SUPPLIER = 'UPDATE_SUPPLIER',
  UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS',
  UPDATE_SUPPLIER_FAIL = 'UPDATE_SUPPLIER_FAIL',

  CLONE_SUPPLIER = 'CLONE_SUPPLIER',
  CLONE_SUPPLIER_SUCCESS = 'CLONE_SUPPLIER_SUCCESS',
  CLONE_SUPPLIER_FAIL = 'CLONE_SUPPLIER_FAIL',

  DELETE_SUPPLIER = 'DELETE_SUPPLIER',
  DELETE_SUPPLIER_SUCCESS = 'DELETE_SUPPLIER_SUCCESS',
  DELETE_SUPPLIER_FAIL = 'DELETE_SUPPLIER_FAIL',
}

// ============================================ GET_ALL_SUPPLIERS

export interface GetAllSuppliersAction {
  payload: Partial<IPagination>;
  type: SuppliersActionTypes.GET_ALL_SUPPLIERS;
}

export interface GetAllSuppliersSuccessAction {
  payload: { pagination: IPagination; data: Array<ISupplier> };
  type: SuppliersActionTypes.GET_ALL_SUPPLIERS_SUCCESS;
}

export interface GetAllSuppliersErrorAction {
  payload: string;
  type: SuppliersActionTypes.GET_ALL_SUPPLIERS_FAIL;
}

// ============================================ GET_SUPPLIER_DEPENDENCIES

export interface GetSupplierDependenciesAction {
  type: SuppliersActionTypes.GET_SUPPLIER_DEPENDENCIES;
}

// ============================================ GET_SUPPLIER_BY_ID

export interface GetSupplierByIdAction {
  payload: { id: string };
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID;
}

export interface GetSupplierByIdSuccessAction {
  payload: ISupplier;
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID_SUCCESS;
}

export interface GetSupplierByIdErrorAction {
  payload: string;
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID_FAIL;
}

// ============================================ CREATE_SUPPLIER

export interface CreateSupplierAction extends AxiosRequestPayload<Raw.ISupplier> {
  type: SuppliersActionTypes.CREATE_SUPPLIER;
}

export interface CreateSupplierSuccessAction
  extends AxiosSuccessPayload<ISupplier, SuppliersActionTypes.CREATE_SUPPLIER_SUCCESS, CreateSupplierAction> {
  type: SuppliersActionTypes.CREATE_SUPPLIER_SUCCESS;
}

export interface CreateSupplierErrorAction
  extends AxiosErrorPayload<SuppliersActionTypes.CREATE_SUPPLIER_FAIL, CreateSupplierAction> {
  type: SuppliersActionTypes.CREATE_SUPPLIER_FAIL;
}

// ============================================ UPDATE_SUPPLIER

export interface UpdateSupplierAction extends AxiosRequestPayload<Raw.ISupplier> {
  type: SuppliersActionTypes.UPDATE_SUPPLIER;
}
export interface UpdateSupplierSuccessAction
  extends AxiosSuccessPayload<ISupplier, SuppliersActionTypes.UPDATE_SUPPLIER_SUCCESS, UpdateSupplierAction> {
  type: SuppliersActionTypes.UPDATE_SUPPLIER_SUCCESS;
}
export interface UpdateSupplierErrorAction
  extends AxiosErrorPayload<SuppliersActionTypes.UPDATE_SUPPLIER_FAIL, UpdateSupplierAction> {
  type: SuppliersActionTypes.UPDATE_SUPPLIER_FAIL;
}

// ============================================ CLONE_SUPPLIER

export interface CloneSupplierAction extends AxiosRequestPayload<Raw.ISupplier> {
  type: SuppliersActionTypes.CLONE_SUPPLIER;
}

export interface CloneSupplierSuccessAction
  extends AxiosSuccessPayload<ISupplier, SuppliersActionTypes.CLONE_SUPPLIER_SUCCESS, CloneSupplierAction> {
  type: SuppliersActionTypes.CLONE_SUPPLIER_SUCCESS;
}

export interface CloneSupplierErrorAction
  extends AxiosErrorPayload<SuppliersActionTypes.CLONE_SUPPLIER_FAIL, CloneSupplierAction> {
  type: SuppliersActionTypes.CLONE_SUPPLIER_FAIL;
}

// ============================================ DELETE_SUPPLIER

export interface DeleteSupplierAction extends AxiosRequestPayload {
  type: SuppliersActionTypes.DELETE_SUPPLIER;
}
export interface DeleteSupplierSuccessAction
  extends AxiosSuccessPayload<string, SuppliersActionTypes.DELETE_SUPPLIER_SUCCESS, DeleteSupplierAction> {
  type: SuppliersActionTypes.DELETE_SUPPLIER_SUCCESS;
}
export interface DeleteSupplierErrorAction
  extends AxiosErrorPayload<SuppliersActionTypes.DELETE_SUPPLIER_FAIL, DeleteSupplierAction> {
  type: SuppliersActionTypes.DELETE_SUPPLIER_FAIL;
}

// ============================================ ALL

export type SupplierActions =
  | GetAllSuppliersAction
  | GetAllSuppliersSuccessAction
  | GetAllSuppliersErrorAction
  | GetSupplierDependenciesAction
  | CreateSupplierAction
  | CreateSupplierSuccessAction
  | CreateSupplierErrorAction
  | UpdateSupplierAction
  | UpdateSupplierSuccessAction
  | UpdateSupplierErrorAction
  | CloneSupplierAction
  | CloneSupplierSuccessAction
  | CloneSupplierErrorAction
  | DeleteSupplierAction
  | DeleteSupplierSuccessAction
  | DeleteSupplierErrorAction
  | GetSupplierByIdAction
  | GetSupplierByIdSuccessAction
  | GetSupplierByIdErrorAction;
