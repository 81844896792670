import { AxiosError, AxiosResponse } from 'axios';
import { call, CallEffect } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { ICanvasType } from 'state_management/reducers/projects/Modals';

const DAGS = {
  QUERY: 'compiler_query_state',
  BOARD: 'compiler_placement',
} as const;

export const errorStatesOfRunningStates: Record<string, string> = {
  DESIGN_COMPILATION_REQ: 'DESIGN_COMPILATION_REQ_ERR',
  BOARD_COMPILATION_REQ: 'BOARD_COMPILATION_REQ_ERR',
  HARDWARE_COMPILATION_REQ: 'HARDWARE_COMPILATION_REQ_ERR',

  // NOTE: The compiler should be able to handle those cases,
  // but this is necessary when the DAG is fails in-between stages
  QUERY: 'QUERY_ERR',
  QUERY_RUNNING: 'QUERY_ERR',
  BOARD: 'BOARD_ERR',
  BOARD_RUNNING: 'BOARD_ERR',
  SCHEMATICS: 'SCHEMATICS_ERR',
  SCHEMATICS_RUNNING: 'SCHEMATICS_ERR',
  BOM_RUNNING: 'BOM_ERR',
  ROUTER: 'ROUTER_ERR',
  ROUTER_RUNNING: 'ROUTER_ERR',
  BOM_LAYOUT_RUNNING: 'BOM_LAYOUT_ERR',
};

export const EXIT_CODE = 'EXIT_CODE' as const;

export const getDagInfoByCanvas = (canvasType: ICanvasType): Raw.DAGInfo => {
  switch (canvasType) {
    case 'design':
      return { dagName: DAGS.QUERY, initialState: 'DESIGN', runningState: 'DESIGN_COMPILATION_REQ' };
    case 'board':
      return { dagName: DAGS.BOARD, initialState: 'BOM', runningState: 'BOARD_COMPILATION_REQ' };
    default:
      throw new Error(`Unknown canvas type: ${canvasType}`);
  }
};

export const handleDAGRunning = (message: string) => (): void => {
  throw new Error(message);
};

export const handleGetDAGStatusError =
  (message: string) =>
  (error: AxiosError): Raw.IDAGStatus | void => {
    if (error.response?.status === 500) {
      return { state: 'failed', errorMessage: 'Internal error when running the DAG' };
    }
    if (error.response?.status === 404) {
      // NOTE: cloned or Ref. Designs cause it to not find the DAG and keep pooling
      return { state: 'success', errorMessage: '' };
    }

    throw new Error(message);
  };

export function* getDagStatus(
  processId: string,
  runId: string,
  onRunningState: () => void,
  onError?: (error: AxiosError) => void,
): Generator<CallEffect<AxiosResponse<Raw.IDAGStatus>>, Raw.IDAGStatus | string | void> {
  let response: AxiosResponse<Raw.IDAGStatus>;
  try {
    response = (yield call(() =>
      axiosInstance.get(apiUri(`/process/${processId}/${runId}`, 2)),
    )) as AxiosResponse<Raw.IDAGStatus>;
  } catch (error) {
    return onError?.(error as AxiosError);
  }

  const state = response.data?.state;
  if (['running', 'queued'].includes(state)) {
    onRunningState();
  }

  return response.data;
}

export function* triggerDAG<T>(
  processId: string,
  runId: string,
  dagConfig: T,
  onError: () => void,
): Generator<CallEffect> {
  try {
    yield call(() => axiosInstance.post(apiUri(`/process/${processId}/${runId}`, 2), dagConfig));
  } catch (error) {
    onError();
  }
}
