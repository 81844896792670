import {
  LocalSnapshotsActionsTypes,
  LocalSnapshotsActionTypes,
} from 'state_management/actions/localSnapshots/ActionTypes';
import { LocalSnapshotsState } from './Modals';

export const localSnapShotInitState: LocalSnapshotsState = {
  blockSettingsSnapshot: undefined,
  linkSettingsSnapshot: undefined,
};

export const localSnapshotsReducer = (
  state: LocalSnapshotsState = localSnapShotInitState,
  action: LocalSnapshotsActionsTypes,
): LocalSnapshotsState => {
  switch (action.type) {
    case LocalSnapshotsActionTypes.LOAD_BLOCK_SETTINGS_SNAPSHOT: {
      return {
        ...state,
        blockSettingsSnapshot: action.payload,
      };
    }
    case LocalSnapshotsActionTypes.LOAD_LINK_SETTINGS_SNAPSHOT: {
      return {
        ...state,
        linkSettingsSnapshot: action.payload,
      };
    }
    case LocalSnapshotsActionTypes.CLEAR_SETTINGS_SNAPSHOT: {
      return localSnapShotInitState;
    }
    default:
      return state;
  }
};
