import { AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';

import { apiUri } from 'services/main_app';

const emailToID =
  (usersInfo: { [email: string]: string }) =>
  (list: Array<string>): Array<string> =>
    list.map((email) => usersInfo[email]);

export function* translateEmailsToIds(
  usersShare: Raw.IResourceShareItems,
): Generator<AllEffect<CallEffect> | CallEffect, Raw.IResourceShareItems> {
  const uniqueEmails = Array.from(new Set([...usersShare.read, ...usersShare.write, ...usersShare.share]));

  const idsRes = (yield all(
    uniqueEmails.map((email) =>
      call(() => axiosInstance.get(apiUri(`keycloak-admin-service/user-info/user-id/${email}`, 2))),
    ),
  )) as Array<AxiosResponse<{ user_id: string }>>;

  const usersInfo = uniqueEmails.reduce(
    (previous, current, currentIndex) => ({
      ...previous,
      [current]: idsRes[currentIndex].data.user_id as string,
    }),
    {} as Record<string, string>,
  );

  const emailToIDFn = emailToID(usersInfo);
  return {
    read: emailToIDFn(usersShare.read),
    write: emailToIDFn(usersShare.write),
    share: emailToIDFn(usersShare.share),
  } as Raw.IResourceShareItems;
}

const idToEmail =
  (usersInfo: { [userId: string]: string }) =>
  (list: Array<string>): Array<string> =>
    list.map((userId) => usersInfo[userId]);

export function* translateIdsToEmail(
  usersShare: Partial<Raw.IResourceShareItems>,
): Generator<AllEffect<CallEffect> | CallEffect, Raw.IResourceShareItems> {
  const uniqueIds = Array.from(
    new Set([...(usersShare.read || []), ...(usersShare.write || []), ...(usersShare.share || [])]),
  );

  const emailsRes = (yield all(
    uniqueIds.map((userId) =>
      call(() => axiosInstance.get(apiUri(`keycloak-admin-service/user-info/email/${userId}`, 2))),
    ),
  )) as Array<AxiosResponse<{ email: string }>>;

  const usersInfo = uniqueIds.reduce(
    (previous, current, currentIndex) => ({
      ...previous,
      [current]: emailsRes[currentIndex].data.email as string,
    }),
    {} as Record<string, string>,
  );

  const idToEmailFn = idToEmail(usersInfo);
  return {
    read: usersShare.read ? idToEmailFn(usersShare.read) : [],
    write: usersShare.write ? idToEmailFn(usersShare.write) : [],
    share: usersShare.share ? idToEmailFn(usersShare.share) : [],
  } as Raw.IResourceShareItems;
}
