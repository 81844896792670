import { IFileUpload } from 'models/IFileUpload';
import { Implementation } from 'state_management/reducers/implementations/Modals';
import { deserializeImplementation } from 'utils/implementationSerializer';
import { IPagination } from 'models/Pagination';
import { IImplementationSpecification, IPcbSpecification } from 'state_management/reducers/specifications/Modals';
import {
  GetAllImplementationsAction,
  ImplementationActionTypes,
  GetImplementationByIdAction,
  UpdateImplementationAction,
  DeleteImplementationAction,
  CleanUpImplementationAction,
  CreateImplementationAction,
  GetAllFunctions,
  UploadImplementationFilesAction,
  ConvertImplementationCadFilesAction,
  ConvertImplementationCadFilesActionSuccess,
  ConvertImplementationCadFilesActionFail,
  DeleteImplementationFileAction,
  GetAllImplementationsErrorAction,
  GetAllImplementationsSuccessAction,
  GetImplementationByIdErrorAction,
  GetImplementationByIdSuccessAction,
  UploadImplementationFileFailAction,
  UploadImplementationFileSuccessAction,
  GetImplementationDependenciesAction,
  UpdateImplementationFileAction,
  CloneImplementationAction,
  RestImplementationsAction,
  GetImplementationsByIdsAction,
  GetImplementationsByIdsSuccessAction,
  GetImplementationsByIdsErrorAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const ENDPOINT_IMPLEMENTATIONS = 'pure-implementations';
export const ENDPOINT_IMPLEMENTATIONS_FILES = 'object-storage/implementations-files';

export const getAllImplementations = (
  payload: Partial<IPagination & { selectedWorkspaces?: string[] }> = {},
): GetAllImplementationsAction => ({
  payload,
  type: ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS,
});

export const getAllImplementationsSuccess = (
  pagination: IPagination,
  data: Array<Implementation>,
): GetAllImplementationsSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS_SUCCESS,
});

export const getAllImplementationsError = (payload: string): GetAllImplementationsErrorAction => ({
  payload,
  type: ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS_FAIL,
});

export const getImplementationById = (implementation: Partial<Implementation>): GetImplementationByIdAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID,
  payload: {
    id: implementation.id || '',
  },
});

export const getImplementationByIdSuccess = (payload: Implementation): GetImplementationByIdSuccessAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID_SUCCESS,
  payload,
});

export const getImplementationByIdError = (payload: string): GetImplementationByIdErrorAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID_FAIL,
  payload,
});

export const getImplementationDependenciesAction = (): GetImplementationDependenciesAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
});

export const createImplementationAction = (implementation: Partial<Implementation>): CreateImplementationAction => ({
  type: ImplementationActionTypes.CREATE_IMPLEMENTATION,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS}`, 2),
      data: deserializeImplementation(implementation),
    },
  },
});

export const updateImplementation = (implementation: Implementation): UpdateImplementationAction => ({
  type: ImplementationActionTypes.UPDATE_IMPLEMENTATION,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS}/${implementation.id}`, 2),
      data: deserializeImplementation(implementation) as Raw.Implementation,
    },
  },
});

export const cloneImplementationAction =
  (
    specificationsImplList?: Array<IImplementationSpecification>,
    specificationsPcbList?: Array<IPcbSpecification>,
    withPortsV2?: boolean,
  ) =>
  (implementation: Partial<Implementation>): CloneImplementationAction => ({
    type: ImplementationActionTypes.CLONE_IMPLEMENTATION,
    payload: {
      request: {
        method: 'post',
        withCredentials: true,
        url: apiUri(`/macro-resources/${ENDPOINT_IMPLEMENTATIONS}/${implementation.id}/clone`, 2),
        data: deserializeImplementation(
          implementation,
          false,
          specificationsImplList,
          specificationsPcbList,
          withPortsV2,
        ),
      },
    },
  });

export const deleteImplementation = (implementation: Partial<Implementation>): DeleteImplementationAction => ({
  type: ImplementationActionTypes.DELETE_IMPLEMENTATION,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS}/${implementation.id}`, 2),
    },
  },
});

export const getAllFunctions = (): GetAllFunctions => ({
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri(`/dataservice/functions/implementation`, 2),
    },
  },
  type: ImplementationActionTypes.GET_ALL_FUNCTIONS,
});

export const uploadImplementationFilesAction = (files: Array<IFileUpload>): UploadImplementationFilesAction => ({
  payload: files,
  type: ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE,
});

export const convertImplementationCadFilesAction = (): ConvertImplementationCadFilesAction => ({
  type: ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE,
});

export const convertImplementationCadFilesSuccessAction = (
  payload: Array<IFileUpload>,
): ConvertImplementationCadFilesActionSuccess => ({
  type: ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE_SUCCESS,
  payload,
});

export const convertImplementationCadFilesFailAction = (
  payload: Array<IFileUpload>,
): ConvertImplementationCadFilesActionFail => ({
  type: ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE_FAIL,
  payload,
});

export const uploadImplementationFileSuccessAction = (
  fileUpload: IFileUpload,
): UploadImplementationFileSuccessAction => ({
  payload: fileUpload,
  type: ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE_SUCCESS,
});

export const uploadImplementationFileFailAction = (fileUpload: IFileUpload): UploadImplementationFileFailAction => ({
  payload: fileUpload,
  type: ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE_FAIL,
});

export const deleteImplementationFileAction = (file: IFileUpload): DeleteImplementationFileAction => ({
  payload: file,
  type: ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE,
});

export const cleanUpImplementation = (): CleanUpImplementationAction => ({
  type: ImplementationActionTypes.IMPLEMENTATIONS_CLEAN_UP,
});

export const patchImplementationFile = ({
  fileId,
  patchedData,
}: {
  fileId: string;
  patchedData: Partial<Raw.IMinioResource>;
}): UpdateImplementationFileAction => ({
  type: ImplementationActionTypes.UPDATE_IMPLEMENTATION_FILE,
  payload: {
    request: {
      method: 'patch',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS_FILES}/${fileId}`, 2),
      data: patchedData,
    },
  },
});

export const resetImplementations = (): RestImplementationsAction => ({
  type: ImplementationActionTypes.REST_IMPLEMENTATIONS,
});

export const getImplementationsByIds = (payload: {
  ids: Array<string>;
  fields: Array<string>;
}): GetImplementationsByIdsAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS,
  payload,
});

export const getImplementationsByIdsSuccess = (
  payload: Array<Implementation>,
): GetImplementationsByIdsSuccessAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS_SUCCESS,
  payload,
});

export const getImplementationsByIdsFail = (payload: string): GetImplementationsByIdsErrorAction => ({
  type: ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS_ERROR,
  payload,
});
