import styled from 'styled-components';
import { flex } from 'theme';

export const Container = styled.div`
  ${flex({ direction: 'column', align: 'center', justify: 'space-between' })}
  padding:  ${({ theme }): string => `${theme.spacing.medium}px`};
  width: 100%;
`;

export const Content = styled.div`
  height: calc(100% - 60px);
  width: 100%;
`;

export const ControllerContainer = styled.div`
  height: 60px;
  padding-top: ${({ theme }): string => `${theme.spacing.medium}px`};
  width: 100%;
  ${flex({ direction: 'row', align: 'center', justify: 'center' })}
`;
