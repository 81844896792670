import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { ChangeLogActionTypes, GetAllChangeLogAction } from 'state_management/actions/changeLog/ActionTypes';
import { GetAllChangesRequestsAction, RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { ComponentActionTypes, GetAllComponentsAction } from 'state_management/actions/components/ActionTypes';

import {
  SuppliersActionTypes,
  GetAllSuppliersAction,
  SupplierActions,
} from 'state_management/actions/suppliers/ActionTypes';
import { getAllSuppliersAction } from 'state_management/actions/suppliers/suppliersActions';
import {
  GetAllVirtualComponentsAction,
  VirtualComponentsActionTypes,
} from 'state_management/actions/virtualComponents/ActionTypes';

export function* refreshSuppliers(
  action:
    | SupplierActions
    | GetAllChangesRequestsAction
    | GetAllChangeLogAction
    | GetAllComponentsAction
    | GetAllVirtualComponentsAction,
): Generator<PutEffect<GetAllSuppliersAction>> {
  const isFetchFullListAction = [
    ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
    RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
    ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
    VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
  ].includes((action as GetAllChangesRequestsAction | GetAllChangeLogAction).type);

  yield put(getAllSuppliersAction({ queryType: isFetchFullListAction ? 'full' : 'paginated' }));
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      SuppliersActionTypes.CREATE_SUPPLIER_SUCCESS,
      SuppliersActionTypes.UPDATE_SUPPLIER_SUCCESS,
      SuppliersActionTypes.CLONE_SUPPLIER_SUCCESS,
      SuppliersActionTypes.DELETE_SUPPLIER_SUCCESS,
      RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
      ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
      ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
    ],
    refreshSuppliers,
  );
}
