import { IChangesRequest } from 'state_management/reducers/changesRequest/Modals';
import { IPagination } from 'models/Pagination';
import { IChangeLog } from 'models/ChangeLog';

import {
  ChangeLogActionTypes,
  GetAllChangeLogAction,
  GetAllChangeLogSuccessAction,
  GetAllChangeLogErrorAction,
  GetChangeLogByIdAction,
  GetChangeLogByIdSuccessAction,
  GetChangeLogByIdErrorAction,
} from './ActionTypes';

export const ENDPOINT_CHANGE_LOG = 'changes-request';

export const getAllChangeLogAction = (payload: Partial<IPagination> & IChangeLog): GetAllChangeLogAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
});

export const getAllChangeLogSuccessAction = (
  pagination: IPagination,
  data: Array<IChangesRequest>,
): GetAllChangeLogSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: ChangeLogActionTypes.GET_ALL_CHANGE_LOG_SUCCESS,
});

export const getAllChangeLogErrorAction = (payload: string): GetAllChangeLogErrorAction => ({
  payload,
  type: ChangeLogActionTypes.GET_ALL_CHANGE_LOG_FAIL,
});

export const getChangeLogById = (payload: Partial<IChangesRequest>): GetChangeLogByIdAction => ({
  type: ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID,
  payload,
});

export const getChangeLogByIdSuccess = (payload: IChangesRequest): GetChangeLogByIdSuccessAction => ({
  type: ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID_SUCCESS,
  payload,
});

export const getChangeLogByIdError = (payload: string): GetChangeLogByIdErrorAction => ({
  type: ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID_FAIL,
  payload,
});
