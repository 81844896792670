import { IPagination } from 'models/Pagination';
import { IExportJob, IExportJobWithChildren } from 'state_management/reducers/exportJobs/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum ExportJobsActionTypes {
  GET_ALL_EXPORT_JOBS = 'GET_ALL_EXPORT_JOBS',
  GET_ALL_EXPORT_JOBS_SUCCESS = 'GET_ALL_EXPORT_JOBS_SUCCESS',
  GET_ALL_EXPORT_JOBS_FAIL = 'GET_ALL_EXPORT_JOBS_FAIL',

  GET_EXPORT_JOB_DEPENDENCIES = 'GET_EXPORT_JOB_DEPENDENCIES',

  GET_EXPORT_JOB_BY_ID = 'GET_EXPORT_JOB_BY_ID',
  GET_EXPORT_JOB_BY_ID_SUCCESS = 'GET_EXPORT_JOB_BY_ID_SUCCESS',
  GET_EXPORT_JOB_BY_ID_FAIL = 'GET_EXPORT_JOB_BY_ID_FAIL',

  CREATE_EXPORT_JOB = 'CREATE_EXPORT_JOB',
  CREATE_EXPORT_JOB_SUCCESS = 'CREATE_EXPORT_JOB_SUCCESS',
  CREATE_EXPORT_JOB_FAIL = 'CREATE_EXPORT_JOB_FAIL',

  UPDATE_EXPORT_JOB = 'UPDATE_EXPORT_JOB',
  UPDATE_EXPORT_JOB_SUCCESS = 'UPDATE_EXPORT_JOB_SUCCESS',
  UPDATE_EXPORT_JOB_FAIL = 'UPDATE_EXPORT_JOB_FAIL',

  CLONE_EXPORT_JOB = 'CLONE_EXPORT_JOB',
  CLONE_EXPORT_JOB_SUCCESS = 'CLONE_EXPORT_JOB_SUCCESS',
  CLONE_EXPORT_JOB_FAIL = 'CLONE_EXPORT_JOB_FAIL',

  DELETE_EXPORT_JOB = 'DELETE_EXPORT_JOB',
  DELETE_EXPORT_JOB_SUCCESS = 'DELETE_EXPORT_JOB_SUCCESS',
  DELETE_EXPORT_JOB_FAIL = 'DELETE_EXPORT_JOB_FAIL',

  RUN_EXPORT_JOB = 'RUN_EXPORT_JOB',
  RUN_EXPORT_JOB_SUCCESS = 'RUN_EXPORT_JOB_SUCCESS',
  RUN_EXPORT_JOB_FAIL = 'RUN_EXPORT_JOB_FAIL',
}

// ============================================ GET_ALL_EXPORT_JOBS

export interface GetAllExportJobsAction {
  payload: Partial<IPagination>;
  type: ExportJobsActionTypes.GET_ALL_EXPORT_JOBS;
}

export interface GetAllExportJobsSuccessAction {
  payload: { pagination: IPagination; data: Array<IExportJob> };
  type: ExportJobsActionTypes.GET_ALL_EXPORT_JOBS_SUCCESS;
}

export interface GetAllExportJobsErrorAction {
  payload: string;
  type: ExportJobsActionTypes.GET_ALL_EXPORT_JOBS_FAIL;
}

// ============================================ GET_EXPORT_JOB_DEPENDENCIES

export interface GetExportJobDependenciesAction {
  type: ExportJobsActionTypes.GET_EXPORT_JOB_DEPENDENCIES;
}

// ============================================ GET_EXPORT_JOB_BY_ID

export interface GetExportJobByIdAction {
  payload: { id: string; fetchChildren: boolean };
  type: ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID;
}

export interface GetExportJobByIdSuccessAction {
  payload: IExportJob | IExportJobWithChildren;
  type: ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID_SUCCESS;
}

export interface GetExportJobByIdErrorAction {
  payload: string;
  type: ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID_FAIL;
}

// ============================================ RUN_EXPORT_JOB

export interface RunExportJobAction extends AxiosRequestPayload<{ export_job_id: string }> {
  type: ExportJobsActionTypes.RUN_EXPORT_JOB;
}
export interface RunExportJobSuccessAction
  extends AxiosSuccessPayload<any, ExportJobsActionTypes.RUN_EXPORT_JOB_SUCCESS, RunExportJobAction> {
  type: ExportJobsActionTypes.RUN_EXPORT_JOB_SUCCESS;
}
export interface RunExportJobErrorAction
  extends AxiosErrorPayload<ExportJobsActionTypes.RUN_EXPORT_JOB_FAIL, RunExportJobAction> {
  type: ExportJobsActionTypes.RUN_EXPORT_JOB_FAIL;
}

// ============================================ CREATE_EXPORT_JOB

export interface CreateExportJobAction {
  payload: Partial<IExportJob>;
  type: ExportJobsActionTypes.CREATE_EXPORT_JOB;
}

export interface CreateExportJobSuccessAction {
  type: ExportJobsActionTypes.CREATE_EXPORT_JOB_SUCCESS;
}

export interface CreateExportJobErrorAction {
  payload: string;
  type: ExportJobsActionTypes.CREATE_EXPORT_JOB_FAIL;
}

// ============================================ UPDATE_EXPORT_JOB

export interface UpdateExportJobAction extends AxiosRequestPayload<Raw.IExportJob> {
  type: ExportJobsActionTypes.UPDATE_EXPORT_JOB;
}
export interface UpdateExportJobSuccessAction
  extends AxiosSuccessPayload<IExportJob, ExportJobsActionTypes.UPDATE_EXPORT_JOB_SUCCESS, UpdateExportJobAction> {
  type: ExportJobsActionTypes.UPDATE_EXPORT_JOB_SUCCESS;
}
export interface UpdateExportJobErrorAction
  extends AxiosErrorPayload<ExportJobsActionTypes.UPDATE_EXPORT_JOB_FAIL, UpdateExportJobAction> {
  type: ExportJobsActionTypes.UPDATE_EXPORT_JOB_FAIL;
}

// ============================================ CLONE_EXPORT_JOB

export interface CloneExportJobAction extends AxiosRequestPayload<Raw.IExportJob> {
  type: ExportJobsActionTypes.CLONE_EXPORT_JOB;
}

export interface CloneExportJobSuccessAction
  extends AxiosSuccessPayload<IExportJob, ExportJobsActionTypes.CLONE_EXPORT_JOB_SUCCESS, CloneExportJobAction> {
  type: ExportJobsActionTypes.CLONE_EXPORT_JOB_SUCCESS;
}

export interface CloneExportJobErrorAction
  extends AxiosErrorPayload<ExportJobsActionTypes.CLONE_EXPORT_JOB_FAIL, CloneExportJobAction> {
  type: ExportJobsActionTypes.CLONE_EXPORT_JOB_FAIL;
}

// ============================================ DELETE_EXPORT_JOB

export interface DeleteExportJobAction extends AxiosRequestPayload {
  type: ExportJobsActionTypes.DELETE_EXPORT_JOB;
}
export interface DeleteExportJobSuccessAction
  extends AxiosSuccessPayload<string, ExportJobsActionTypes.DELETE_EXPORT_JOB_SUCCESS, DeleteExportJobAction> {
  type: ExportJobsActionTypes.DELETE_EXPORT_JOB_SUCCESS;
}
export interface DeleteExportJobErrorAction
  extends AxiosErrorPayload<ExportJobsActionTypes.DELETE_EXPORT_JOB_FAIL, DeleteExportJobAction> {
  type: ExportJobsActionTypes.DELETE_EXPORT_JOB_FAIL;
}

// ============================================ ALL

export type ExportJobActions =
  | GetAllExportJobsAction
  | GetAllExportJobsSuccessAction
  | GetAllExportJobsErrorAction
  | GetExportJobDependenciesAction
  | CreateExportJobAction
  | CreateExportJobSuccessAction
  | CreateExportJobErrorAction
  | UpdateExportJobAction
  | UpdateExportJobSuccessAction
  | UpdateExportJobErrorAction
  | CloneExportJobAction
  | CloneExportJobSuccessAction
  | CloneExportJobErrorAction
  | DeleteExportJobAction
  | DeleteExportJobSuccessAction
  | DeleteExportJobErrorAction
  | GetExportJobByIdAction
  | GetExportJobByIdSuccessAction
  | GetExportJobByIdErrorAction
  | RunExportJobAction
  | RunExportJobSuccessAction
  | RunExportJobErrorAction;
