import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getDesignBlockByIdSuccess,
  getDesignBlockByIdError,
  ENDPOINT_DESIGN_BLOCKS,
} from 'state_management/actions/designBlocks/designBlocksActions';
import { serializeDesignBlock } from 'utils/designBlocksSerializer';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetDesignBlockByIdErrorAction,
  GetDesignBlockByIdSuccessAction,
  GetDesignBlockByIdAction,
  DesignBlockActionTypes,
} from 'state_management/actions/designBlocks/ActionTypes';
import { IDesignBlock } from 'state_management/reducers/designBlocks/Modals';

export function* getByIdSaga(
  action: GetDesignBlockByIdAction,
): Generator<
  | PutEffect<GetDesignBlockByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetDesignBlockByIdSuccessAction | GetDesignBlockByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedDesignBlock: IDesignBlock = serializeDesignBlock(res.data);

    yield put(getDesignBlockByIdSuccess(parsedDesignBlock));
  } catch (error) {
    yield put(getDesignBlockByIdError(getErrorMessage(error) || 'Fetching design block failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID, getByIdSaga);
}
