import { PutEffect, ForkEffect, CallEffect, put, call, SelectEffect, select, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { User } from 'state_management/reducers/auth/Modals';
import {
  ChangeLogActionTypes,
  GetChangeLogByIdAction,
  GetChangeLogByIdErrorAction,
  GetChangeLogByIdSuccessAction,
} from 'state_management/actions/changeLog/ActionTypes';
import { getChangeLogByIdError } from 'state_management/actions/changeLog/changeLogActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';

import { getErrorMessage } from 'utils/getErrorMessage';
import { getRequestData, SERIALIZERS } from '../changesRequest/getByIdSaga';

export function* getByIdSaga(
  action: GetChangeLogByIdAction,
): Generator<
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetChangeLogByIdSuccessAction | GetChangeLogByIdErrorAction>
  | CallEffect
> {
  try {
    const { resourceName, resourceId, id } = action.payload;
    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(
          `/dataservice/${
            SERIALIZERS[resourceName as keyof typeof SERIALIZERS].endpoint
          }/${resourceId}/changelog/${id}`,
          2,
        ),
      ),
    )) as AxiosResponse;
    const changeRequest: Raw.IChangesRequest = res.data;

    const userInfo = (yield select((state) => state.authState.userInfo)) as User;

    // Sharing requests
    if (changeRequest.target_method === 'share') {
      // eslint-disable-next-line no-console
      console.warn('Sharing methods are not supported');
    }

    // Other requests
    if (['create', 'update', 'delete'].includes(changeRequest.target_method)) {
      yield call(getRequestData, changeRequest, userInfo, action);
    }
  } catch (error) {
    yield put(
      getChangeLogByIdError(getErrorMessage(error as AxiosError) || 'Fetching request failed. Please try again...'),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest([ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID], getByIdSaga);
}
