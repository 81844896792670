import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';

import {
  BoardShapeCanvasActionTypes,
  SaveBoardShapeCanvasAction,
  SaveBoardShapeCanvasSuccessAction,
  SaveBoardShapeCanvasErrorAction,
} from 'state_management/actions/boardShapeCanvas/ActionTypes';

import {
  saveBoardShapeCanvasSuccessAction,
  saveBoardShapeCanvasErrorAction,
} from 'state_management/actions/boardShapeCanvas/boardShapeCanvasActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';

import { flipAllAngles, getClosedPoly } from 'services/geo';
import { AxiosResponse } from 'axios';
import { ICanvas } from 'state_management/reducers/boardCanvas/Modals';
import { projectCanvasCompileAction } from 'state_management/actions/projects/projectsActions';
import { ProjectCanvasCompileAction } from 'state_management/actions/projects/ActionTypes';
import { newNotification } from 'state_management/actions/notification/notificationActions';
import i18n from 'i18n/config';
import { NewNotification } from 'state_management/actions/notification/ActionTypes';

export function* saveSaga(
  action: SaveBoardShapeCanvasAction,
): Generator<
  | PutEffect<
      SaveBoardShapeCanvasSuccessAction | SaveBoardShapeCanvasErrorAction | ProjectCanvasCompileAction | NewNotification
    >
  | TakeEffect
  | CallEffect
  | SelectEffect
> {
  const t = i18n.t.bind(i18n);
  try {
    const { canvas, compileProject } = action.payload;

    const {
      projectsState: {
        currentProject: { id: projectId },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    const payload = {
      ...canvas,
      modules: flipAllAngles(canvas?.modules || []),
      boardPolygon: getClosedPoly(canvas?.boardPolygon || []),
    };

    const res = yield call(() => axiosInstance.patch(apiUri(`canvas/board/${projectId}`, 2), payload));
    const newCanvas = (res as AxiosResponse<ICanvas>).data;

    yield put(saveBoardShapeCanvasSuccessAction(newCanvas));

    if (compileProject) {
      yield put(projectCanvasCompileAction('board'));
    }
    yield put(
      newNotification({
        type: 'success',
        message: t('supernova:toasts.projectSaveSuccess', 'Project successfully saved'),
      }),
    );
  } catch (error) {
    yield put(saveBoardShapeCanvasErrorAction(getErrorMessage(error) || 'Saving Board Shape Canvas failed.'));
  }
}

export function* saveWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE, saveSaga);
}
