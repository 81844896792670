import { connect } from 'react-redux';

import { AppState } from 'state_management/AppState';

import Component from './Consents';

import { IProps } from './IProps';

const mapStateToProps = (
  state: AppState,
): Omit<IProps, 'dispatch' | 'consentComponent' | 'children' | 'isNoAuthRoute'> => ({
  acceptedConsentIds: state.unboxingState.acceptedConsentIds,
  plugins: state.pluginsState.plugins,
  pluginsState: state.pluginsState.pluginsState,
});

export default connect(mapStateToProps)(Component);
