import { forwardRef, FunctionComponent } from 'react';
import Popover from 'components/Popover';
import CelusIcon from '../CelusIcon';
import { CelusButton, CelusIconWrapper, TooltipInfoContainer } from './styles';
import { IProps } from './IProps';

const withPopover =
  (Component: FunctionComponent<IProps>) =>
  (props: IProps): JSX.Element => {
    const button = <Component {...props} />;
    const { tooltip } = props;

    return tooltip ? (
      <Popover noTip delay={0.5} hoverTrigger content={<TooltipInfoContainer>{tooltip}</TooltipInfoContainer>}>
        {button}
      </Popover>
    ) : (
      button
    );
  };

const Button = (
  {
    autoFocus,
    icon,
    iconSize,
    empty,
    children,
    color,
    disableElevation,
    endIcon,
    fullWidth,
    href,
    size,
    startIcon,
    variant,
    disabled,
    type,
    className,
    style,
    colors,
    dataTestId,
    onClick,
    onMouseOver,
    onMouseOut,
    onMouseLeave,
  }: IProps,
  ref: RefType<HTMLButtonElement>,
): JSX.Element => (
  <CelusButton
    autoFocus={autoFocus}
    isOnlyIcon={!children}
    data-testid={dataTestId || 'button'}
    disabled={disabled}
    type={type}
    empty={empty || false}
    ref={ref}
    color={color}
    disableElevation={disableElevation}
    endIcon={endIcon}
    fullWidth={fullWidth}
    href={href}
    size={size}
    startIcon={startIcon}
    variant={variant}
    disableFocusRipple
    disableRipple
    colors={colors}
    style={style}
    onClick={onClick}
    className={className}
    onMouseOver={onMouseOver}
    onMouseOut={onMouseOut}
    onMouseLeave={onMouseLeave}
  >
    {icon && (
      <CelusIconWrapper data-testid="button-icon" isOnlyIcon={!children}>
        <CelusIcon size={iconSize || size}>{icon}</CelusIcon>
      </CelusIconWrapper>
    )}
    {children}
  </CelusButton>
);

export default withPopover(forwardRef(Button));
