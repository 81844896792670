import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  select,
  SelectEffect,
  call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  ComponentActionTypes,
  GetAllForLinkComponentsAction,
  GetAllForLinkComponentsSuccessAction,
  GetAllForLinkComponentsErrorAction,
} from 'state_management/actions/components/ActionTypes';

import { getErrorMessage } from 'utils/getErrorMessage';
import {
  ENDPOINT_COMPONENTS,
  getAllForLinkComponentsSuccessAction,
  getAllForLinkComponentsErrorAction,
} from 'state_management/actions/components/componentsActions';
import { AppState } from 'state_management/AppState';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { combineSearchQueries } from 'utils/searchHelper';
import { serializeComponent } from 'utils/componentsSerializer';

export function* getAllForLinkSaga(
  action: GetAllForLinkComponentsAction,
): Generator<
  | PutEffect<GetAllForLinkComponentsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllForLinkComponentsSuccessAction | GetAllForLinkComponentsErrorAction>
> {
  try {
    const {
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const { advancedSearchQuery, fetchChildren, withResetList } = action.payload;

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      '',
      false,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const fetchChildrenQuery = fetchChildren ? '&fetch_children=true' : '';

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_COMPONENTS}?${searchQuery}${fetchChildrenQuery}`, 2)),
    )) as AxiosResponse;

    yield put(getAllForLinkComponentsSuccessAction(res.data.map(serializeComponent), withResetList));
  } catch (error) {
    yield put(getAllForLinkComponentsErrorAction(getErrorMessage(error) || 'Fetching components failed.'));
  }
}

export function* getAllForLinkWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS, getAllForLinkSaga);
}
