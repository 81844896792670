import styled from 'styled-components';
import { CloseOutlined, SplitCellsOutlined, SettingOutlined } from '@ant-design/icons';
import { Alert } from 'antd';

export const PanelWrapper = styled.div<{
  doesSplit?: boolean;
}>`
  && {
    width: ${({ doesSplit }): string => (doesSplit ? '49.9%' : '100%')};
    height: 100%;
    overflow: hidden;

    .segmented-tabs {
      background-color: #e4e4e4;
    }
  }
`;

export const Body = styled.div`
  && {
    padding-top: 2px;
    width: calc(100% - 3px);
    height: calc(100% - 29px);
    overflow-y: scroll;
    overflow-x: hidden;

    .alertItem-container {
      width: 100%;
    }
  }
`;

export const Header = styled.div`
  && {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }): string => theme.palette.gray};
  }
`;

export const CloseButton = styled(CloseOutlined)`
  && {
    cursor: pointer;
    margin-right: 4px;
    padding: 1px 6px;
    color: ${({ theme }): string => theme.palette.darkText};

    :hover {
      background-color: #0000001c;
    }
  }
`;

export const SplitButton = styled(SplitCellsOutlined)`
  && {
    cursor: pointer;
    margin-right: 4px;
    padding: 1px 6px;
    color: ${({ theme }): string => theme.palette.darkText};

    :hover {
      background-color: #0000001c;
    }
  }
`;

export const ToolsButton = styled(SettingOutlined)`
  && {
    cursor: pointer;
    margin-right: 4px;
    padding: 1px 6px;
    color: ${({ theme }): string => theme.palette.darkText};

    :hover {
      background-color: #0000001c;
    }
  }
`;

export const AlertItem = styled(Alert)`
  && {
    background-color: ${({ theme }): string => theme.palette.white};
    color: #626262;

    :hover {
      color: initial;
      cursor: pointer;
      background-color: ${({ theme }): string => theme.palette.hoverGray};
    }
  }
`;
