import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { ChangeLogActionTypes } from 'state_management/actions/changeLog/ActionTypes';
import { RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { ComponentActions, ComponentActionTypes } from 'state_management/actions/components/ActionTypes';

import {
  ManufacturersActionTypes,
  GetAllManufacturersAction,
  ManufacturerActions,
} from 'state_management/actions/manufacturers/ActionTypes';
import { getAllManufacturersAction } from 'state_management/actions/manufacturers/manufacturersActions';

export function* refreshManufacturers(
  action: ManufacturerActions | ComponentActions,
): Generator<PutEffect<GetAllManufacturersAction>> {
  const isFetchFullListAction = [
    ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
    RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
    ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
  ].includes(action.type as ComponentActionTypes);
  yield put(getAllManufacturersAction({ queryType: isFetchFullListAction ? 'full' : 'paginated' }));
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ManufacturersActionTypes.CREATE_MANUFACTURER_SUCCESS,
      ManufacturersActionTypes.UPDATE_MANUFACTURER_SUCCESS,
      ManufacturersActionTypes.CLONE_MANUFACTURER_SUCCESS,
      ManufacturersActionTypes.DELETE_MANUFACTURER_SUCCESS,
      ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
      RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
      ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
    ],
    refreshManufacturers,
  );
}
