import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  VirtualComponentsActionTypes,
  GetAllVirtualComponentsAction,
} from 'state_management/actions/virtualComponents/ActionTypes';
import { getAllVirtualComponentsAction } from 'state_management/actions/virtualComponents/virtualComponentsActions';

export function* refreshVirtualComponents(): Generator<PutEffect<GetAllVirtualComponentsAction>> {
  yield put(getAllVirtualComponentsAction({ queryType: 'paginated' }));
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_SUCCESS,
      VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_SUCCESS,
      VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_SUCCESS,
      VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_SUCCESS,
    ],
    refreshVirtualComponents,
  );
}
