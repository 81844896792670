import { all, AllEffect } from 'redux-saga/effects';
import { requestsToastsWatcher } from './requestsToastsSaga';
import { refreshWatcher } from './refreshSaga';
import { predictedInterfacesSagaWatcher } from './predictedInterfacesSaga';
import { getAllWatcher } from './getAllSaga';
import { getByIdWatcher } from './getByIdSaga';
import { importCadFilesSagaWatcher } from './importCadFilesSaga';
import { getImplementationsByIdsWatcher } from './getImplementationsByIdsSaga';

export function* implementationsRootSaga(): Generator<AllEffect<any>> {
  yield all([
    requestsToastsWatcher(),
    refreshWatcher(),
    predictedInterfacesSagaWatcher(),
    getAllWatcher(),
    getByIdWatcher(),
    importCadFilesSagaWatcher(),
    getImplementationsByIdsWatcher(),
  ]);
}
