import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  DesignBlockActionTypes,
  GetAllDesignBlocksAction,
  GetAllDesignBlocksErrorAction,
  GetAllDesignBlocksSuccessAction,
} from 'state_management/actions/designBlocks/ActionTypes';

import {
  getAllDesignBlocksSuccessAction,
  getAllDesignBlocksErrorAction,
  ENDPOINT_DESIGN_BLOCKS,
} from 'state_management/actions/designBlocks/designBlocksActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeDesignBlock } from 'utils/designBlocksSerializer';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';
import { getAllFunctions } from 'state_management/actions/implementations/implementationsActions';
import { GetAllFunctions } from 'state_management/actions/implementations/ActionTypes';

export function* getAllSaga(
  action: GetAllDesignBlocksAction,
): Generator<
  | PutEffect<GetAllDesignBlocksAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllDesignBlocksSuccessAction | GetAllDesignBlocksErrorAction | GetAllFunctions>
> {
  try {
    const {
      designBlocksState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const designBlocksSearchSchema: TypeSearch = {
      name: 'value',
      description: 'value',
      full_info: 'value',
      functions: 'value',
      category: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), designBlocksSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2),
      ),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllDesignBlocksSuccessAction(_pagination, res.data.map(serializeDesignBlock)));
    // NOTE: Design Edit and Create Form needs all Concept Function
    yield put(getAllFunctions());
  } catch (error) {
    yield put(getAllDesignBlocksErrorAction(getErrorMessage(error) || 'Fetching designBlocks failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS, getAllSaga);
}
