import { DEFAULT_THEME, mergeThemes } from 'theme';
import { GROUPS_FILTERING_LUT } from 'config';
import { AuthActions, AuthActionTypes } from '../../actions/auth/ActionTypes';
import { AuthState, FeaturePermissions, FEATURES_KEYS, Roles } from './Modals';

export const initialAuthState: AuthState = {
  authIssuer: '',
  pendingResponse: false,
  isLoggedIn: false,
  loggedOut: false,
  featurePermissions: {
    ...Object.values(FEATURES_KEYS).reduce((a, v) => ({ ...a, [v]: { read: false } }), {} as FeaturePermissions),
  },
  userInfo: {
    _id: '',
    name: '',
    admin: false,
    groups: [],
    target_hosts: [],
    basic: {
      email: '',
      firstName: '',
      lastName: '',
      organization: '',
    },
    stats: {
      createdAt: '',
      lastModified: '',
      lastLogin: '',
      showlogin: '',
    },
    license: {
      licensekey: '',
      licensetype: '',
    },
    invoice: {
      adress1: '',
      adress2: '',
      city: '',
      country: '',
      firstName: '',
      lastName: '',
      organization: '',
      state: '',
      zipcode: '',
    },
    payment: {
      bankaccount: {
        BIC: '',
        IBAN: '',
      },
      creditcardnumber: [],
    },
    roles: [],
    tfa: {
      verified: false,
    },
    app_type: ['CELUS'],
    isAnonymousUser: false,
  },
  userSettings: {
    preferredWorkspaces: window.location.host in GROUPS_FILTERING_LUT ? GROUPS_FILTERING_LUT[window.location.host] : [],
  },
  currentAppType: 'CELUS',
  theme: DEFAULT_THEME.mainDashboard,
  translations: [],
  activeTheme: DEFAULT_THEME,
  getActiveThemeRequestError: '',
  getActiveThemeRequestState: 'idle',
  createThemeRequestError: '',
  createThemeRequestState: 'idle',
};

export const authReducer = (state: AuthState = initialAuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case AuthActionTypes.LOGIN: {
      return {
        ...state,
        userInfo: initialAuthState.userInfo,
      };
    }
    case AuthActionTypes.LOGOUT_SUCCESS: {
      return {
        ...state,
        ...initialAuthState,
        loggedOut: true,
      };
    }
    case AuthActionTypes.GET_USER_INFO: {
      return {
        ...state,
        pendingResponse: true,
        userInfo: initialAuthState.userInfo,
      };
    }
    case AuthActionTypes.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        pendingResponse: false,
        loggedOut: false,
        userInfo: {
          ...state.userInfo,
          ...action.payload.data,
          app_type: action.payload.data.app_type || ['CELUS'],
        },
        currentAppType:
          (action.payload.data.app_type?.includes('AVAIL') && 'AVAIL') ||
          (action.payload.data.app_type?.includes('ORBIT') && 'ORBIT') ||
          (action.payload.data.app_type?.includes('SUPERNOVA') && 'SUPERNOVA') ||
          'CELUS',
        userSettings: {
          preferredWorkspaces: (action.payload.data.roles || []).includes(Roles.SYSTEM_ROOT)
            ? undefined
            : (initialAuthState.userSettings.preferredWorkspaces?.length &&
                initialAuthState.userSettings.preferredWorkspaces) ||
              action.payload.data.groups ||
              undefined,
        },
      };
    }
    case AuthActionTypes.GET_USER_INFO_FAIL: {
      return {
        ...state,
        pendingResponse: false,
        userInfo: initialAuthState.userInfo,
      };
    }
    case AuthActionTypes.GET_PERMISSIONS: {
      return {
        ...state,
        pendingResponse: true,
        featurePermissions: initialAuthState.featurePermissions,
      };
    }
    case AuthActionTypes.GET_PERMISSIONS_SUCCESS: {
      const featurePermissions = (action.payload.data.scope_permissions || []).reduce(
        (a, b) => ({
          ...a,
          ...(FEATURES_KEYS[b.rsname as keyof typeof FEATURES_KEYS] && {
            [FEATURES_KEYS[b.rsname as keyof typeof FEATURES_KEYS]]: { read: (b.scopes || []).includes('read') },
          }),
        }),
        initialAuthState.featurePermissions,
      );
      const userSettings =
        featurePermissions.SHOW_WORKSPACE_SELECTION.read === true || window.location.host in GROUPS_FILTERING_LUT
          ? state.userSettings
          : { ...state.userSettings, preferredWorkspaces: undefined };
      return {
        ...state,
        pendingResponse: false,
        featurePermissions,
        userSettings,
        userInfo: {
          ...state.userInfo,
          isAnonymousUser: featurePermissions.ANONYMOUS_USER.read,
        },
      };
    }
    case AuthActionTypes.GET_PERMISSIONS_FAIL: {
      return {
        ...state,
        pendingResponse: false,
        featurePermissions: initialAuthState.featurePermissions,
      };
    }
    case AuthActionTypes.GET_I18N_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        translations: Object.keys(action.payload.data.languages).map((code) => ({
          label: action.payload.data.languages[code].name,
          flag: action.payload.data.languages[code].flag,
          namespace: code,
        })),
      };
    }
    case AuthActionTypes.UPDATE_APP_TYPE: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          app_type: Array.from(new Set([...state.userInfo.app_type, action.payload])),
        },
        currentAppType:
          (action.payload === 'SUPERNOVA' && state.userInfo.app_type?.includes('AVAIL') && 'AVAIL') || action.payload,
        theme:
          (action.payload === 'ORBIT' && state.activeTheme.orbit) ||
          (action.payload === 'SUPERNOVA' && state.activeTheme.supernova) ||
          state.activeTheme.mainDashboard,
      };
    }
    case AuthActionTypes.GET_ACTIVE_THEME: {
      return {
        ...state,
        getActiveThemeRequestState: 'loading',
        getActiveThemeRequestError: '',
      };
    }
    case AuthActionTypes.GET_ACTIVE_THEME_SUCCESS: {
      const itHasATheme = action.payload.orbit && action.payload.supernova && action.payload.main_dashboard;
      const activeTheme = itHasATheme ? mergeThemes(DEFAULT_THEME, action.payload) : DEFAULT_THEME;
      let theme;
      if (itHasATheme)
        theme =
          (state.currentAppType === 'ORBIT' && mergeThemes(DEFAULT_THEME, action.payload).orbit) ||
          (state.currentAppType === 'SUPERNOVA' && mergeThemes(DEFAULT_THEME, action.payload).supernova) ||
          mergeThemes(DEFAULT_THEME, action.payload).mainDashboard;
      else
        theme =
          (state.currentAppType === 'ORBIT' && DEFAULT_THEME.orbit) ||
          (state.currentAppType === 'SUPERNOVA' && DEFAULT_THEME.supernova) ||
          DEFAULT_THEME.mainDashboard;
      return {
        ...state,
        activeTheme,
        theme,
        // NOTE: Don't error out, just use DEFAULT_THEME theme
        getActiveThemeRequestState: 'success',
        getActiveThemeRequestError: '',
      };
    }
    case AuthActionTypes.GET_ACTIVE_THEME_FAIL: {
      return {
        ...state,
        activeTheme: DEFAULT_THEME,
        theme:
          (state.currentAppType === 'ORBIT' && DEFAULT_THEME.orbit) ||
          (state.currentAppType === 'SUPERNOVA' && DEFAULT_THEME.supernova) ||
          DEFAULT_THEME.mainDashboard,
        // NOTE: Don't error out, just use DEFAULT_THEME theme
        getActiveThemeRequestState: 'success',
        getActiveThemeRequestError: action.payload,
      };
    }
    case AuthActionTypes.GET_AUTH_ISSUER_SUCCESS: {
      return {
        ...state,
        authIssuer: new URL(action.payload.data.issuer).host,
      };
    }
    case AuthActionTypes.GET_AUTH_ISSUER_FAIL:
    case AuthActionTypes.GET_AUTH_ISSUER:
      return {
        ...state,
        authIssuer: '',
      };
    case AuthActionTypes.UPDATE_USER_SETTINGS:
      return {
        ...state,
        userSettings: action.payload,
      };
    default:
      return state;
  }
};
