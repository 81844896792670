export const doNothing = () => undefined;

export const updateObjectInList = (list, idx, obj) =>
  list.map((original, i) => (i === idx ? { ...original, ...obj } : original));

export const addObjectToList = (list, idx, obj) => [...list.slice(0, idx), obj, ...list.slice(idx)];

export const stripSlashes = (str) => str.replace(/^\/|\/$/g, '');

export const assetUri = (p) => `/app-assets/${stripSlashes(p)}`;
