import { IPagination } from 'models/Pagination';
import { AxiosErrorPayload, AxiosRequestPayload, AxiosSuccessPayload } from 'state_management/middlewares/axios/Modals';
import { IChangesRequest } from 'state_management/reducers/changesRequest/Modals';
import { ResourceSharing } from 'state_management/reducers/sharing/Modals';

export enum RequestsActionTypes {
  GET_ALL_CHANGES_REQUESTS = 'GET_ALL_CHANGES_REQUESTS',
  GET_ALL_CHANGES_REQUESTS_SUCCESS = 'GET_ALL_CHANGES_REQUESTS_SUCCESS',
  GET_ALL_CHANGES_REQUESTS_FAIL = 'GET_ALL_CHANGES_REQUESTS_FAIL',

  CREATE_CHANGES_REQUEST = 'CREATE_CHANGES_REQUEST',
  CREATE_CHANGES_REQUEST_SUCCESS = 'CREATE_CHANGES_REQUEST_SUCCESS',
  CREATE_CHANGES_REQUEST_FAIL = 'CREATE_CHANGES_REQUEST_FAIL',

  CALL_CHANGES_REQUEST_APPROVAL = 'CALL_CHANGES_REQUEST_APPROVAL',
  CALL_CHANGES_REQUEST_APPROVAL_SUCCESS = 'CALL_CHANGES_REQUEST_APPROVAL_SUCCESS',
  CALL_CHANGES_REQUEST_APPROVAL_FAIL = 'CALL_CHANGES_REQUEST_APPROVAL_FAIL',

  GET_CHANGES_REQUEST_BY_ID = 'GET_CHANGES_REQUEST_BY_ID',
  GET_CHANGES_REQUEST_BY_ID_SUCCESS = 'GET_CHANGES_REQUEST_BY_ID_SUCCESS',
  GET_CHANGES_REQUEST_BY_ID_FAIL = 'GET_CHANGES_REQUEST_BY_ID_FAIL',

  DELETE_CHANGES_REQUEST = 'DELETE_CHANGES_REQUEST',
  DELETE_CHANGES_REQUEST_SUCCESS = 'DELETE_CHANGES_REQUEST_SUCCESS',
  DELETE_CHANGES_REQUEST_FAIL = 'DELETE_CHANGES_REQUEST_FAIL',

  RETRY_CHANGES_REQUEST = 'RETRY_CHANGES_REQUEST',
  RETRY_CHANGES_REQUEST_SUCCESS = 'RETRY_CHANGES_REQUEST_SUCCESS',
  RETRY_CHANGES_REQUEST_FAIL = 'RETRY_CHANGES_REQUEST_FAIL',

  CREATE_CHANGES_REQUEST_FOR_SHARING = 'CREATE_CHANGES_REQUEST_FOR_SHARING',
}

// ============================================ GET_ALL_CHANGES_REQUESTS

export interface GetAllChangesRequestsAction {
  payload: Partial<IPagination>;
  type: RequestsActionTypes.GET_ALL_CHANGES_REQUESTS;
}

export interface GetAllChangesRequestsSuccessAction {
  payload: { pagination: IPagination; data: Array<IChangesRequest> };
  type: RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_SUCCESS;
}

export interface GetAllChangesRequestsErrorAction {
  payload: string;
  type: RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_FAIL;
}

// ============================================ CREATE_CHANGES_REQUEST
export interface CreateChangeRequestAction extends AxiosRequestPayload<Partial<IChangesRequest>> {
  forceRefresh?: boolean;
  type: RequestsActionTypes.CREATE_CHANGES_REQUEST;
}

export interface CreateChangeRequestSuccessAction
  extends AxiosSuccessPayload<
    Raw.IChangesRequest,
    RequestsActionTypes.CREATE_CHANGES_REQUEST,
    CreateChangeRequestAction
  > {
  type: RequestsActionTypes.CREATE_CHANGES_REQUEST_SUCCESS;
}

export interface CreateChangeRequestErrorAction {
  payload: string;
  type: RequestsActionTypes.CREATE_CHANGES_REQUEST_FAIL;
}

// ============================================ CALL_CHANGES_REQUEST_APPROVAL

export interface CallChangesRequestApprovalAction extends AxiosRequestPayload<Record<string, unknown>> {
  type: RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL;
}
export interface CallChangesRequestApprovalSuccessAction
  extends AxiosSuccessPayload<
    Raw.IChangesRequest,
    RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL,
    CallChangesRequestApprovalAction
  > {
  type: RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_SUCCESS;
}
export interface CallChangesRequestApprovalErrorAction
  extends AxiosErrorPayload<RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_FAIL, CallChangesRequestApprovalAction> {
  payload: string;
  type: RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_FAIL;
}

// ============================================ GET_CHANGES_REQUEST_BY_ID

export interface GetChangesRequestByIdAction {
  payload: { id: string };
  type: RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID;
}

export interface GetChangesRequestByIdSuccessAction {
  payload: IChangesRequest;
  originalSharingData?: ResourceSharing;
  type: RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID_SUCCESS;
}

export interface GetChangesRequestByIdErrorAction {
  payload: string;
  type: RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID_FAIL;
}

// ============================================ DELETE_CHANGES_REQUEST

export interface DeleteChangesRequestAction extends AxiosRequestPayload {
  type: RequestsActionTypes.DELETE_CHANGES_REQUEST;
}
export interface DeleteChangesRequestSuccessAction
  extends AxiosSuccessPayload<string, RequestsActionTypes.DELETE_CHANGES_REQUEST_SUCCESS, DeleteChangesRequestAction> {
  type: RequestsActionTypes.DELETE_CHANGES_REQUEST_SUCCESS;
}
export interface DeleteChangesRequestErrorAction
  extends AxiosErrorPayload<RequestsActionTypes.DELETE_CHANGES_REQUEST_FAIL, DeleteChangesRequestAction> {
  type: RequestsActionTypes.DELETE_CHANGES_REQUEST_FAIL;
}

// ============================================ RETRY_CHANGES_REQUEST

export interface RetryChangesRequestAction extends AxiosRequestPayload {
  type: RequestsActionTypes.RETRY_CHANGES_REQUEST;
}
export interface RetryChangesRequestSuccessAction
  extends AxiosSuccessPayload<string, RequestsActionTypes.RETRY_CHANGES_REQUEST_SUCCESS, RetryChangesRequestAction> {
  type: RequestsActionTypes.RETRY_CHANGES_REQUEST_SUCCESS;
}
export interface RetryChangesRequestErrorAction
  extends AxiosErrorPayload<RequestsActionTypes.RETRY_CHANGES_REQUEST_FAIL, RetryChangesRequestAction> {
  type: RequestsActionTypes.RETRY_CHANGES_REQUEST_FAIL;
}

// ============================================ CREATE_CHANGES_REQUEST_FOR_SHARING
export interface CreateChangeRequestForSharingAction {
  payload: Partial<IChangesRequest>;
  type: RequestsActionTypes.CREATE_CHANGES_REQUEST_FOR_SHARING;
}

export type RequestActions =
  | GetAllChangesRequestsAction
  | GetAllChangesRequestsSuccessAction
  | GetAllChangesRequestsErrorAction
  | CreateChangeRequestAction
  | CreateChangeRequestSuccessAction
  | CreateChangeRequestErrorAction
  | CallChangesRequestApprovalAction
  | CallChangesRequestApprovalSuccessAction
  | CallChangesRequestApprovalErrorAction
  | GetChangesRequestByIdAction
  | GetChangesRequestByIdSuccessAction
  | GetChangesRequestByIdErrorAction
  | DeleteChangesRequestAction
  | DeleteChangesRequestSuccessAction
  | DeleteChangesRequestErrorAction
  | RetryChangesRequestAction
  | RetryChangesRequestSuccessAction
  | RetryChangesRequestErrorAction
  | CreateChangeRequestForSharingAction;
