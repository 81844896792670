import { ForkEffect, takeLatest } from 'redux-saga/effects';
import { appRoutes } from 'routes/getRoutes';

import {
  ProjectImportSuccessAction,
  ProjectsActionTypes,
  ProjectsCloneSuccessAction,
} from 'state_management/actions/projects/ActionTypes';

export function projectToCanvasSaga(action: ProjectsCloneSuccessAction | ProjectImportSuccessAction): void {
  const { id, isRefDesign } = action.payload;
  const projectId = id;
  // NOTE: Don't redirect when the project is a reference design or no project was imported (empty id)
  if (isRefDesign || !projectId) {
    return;
  }

  const route = appRoutes.getCanvasRoute(projectId || '', 'Design Canvas');
  window.location.href = route;
}

export function* projectToCanvasSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [ProjectsActionTypes.PROJECTS_CLONE_SUCCESS, ProjectsActionTypes.PROJECT_IMPORT_SUCCESS],
    projectToCanvasSaga,
  );
}
