import { upsertResource } from 'utils/resourceUtils';
import {
  ExportTemplateActions,
  ExportTemplatesActionTypes,
} from 'state_management/actions/exportTemplates/ActionTypes';
import { ExportTemplatesState } from './Modals';

export const initialStateExportTemplates: ExportTemplatesState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  exportTemplatesList: [],
  exportTemplatesListState: 'idle',
  exportTemplatesListError: '',
  exportTemplateCreateState: 'idle',
  exportTemplateCreateError: '',
  exportTemplateUpdateState: 'idle',
  exportTemplateUpdateError: '',
  exportTemplateDeleteState: 'idle',
  exportTemplateDeleteError: '',
  exportTemplateByIdState: 'idle',
  exportTemplateByIdStateError: '',
};

export const exportTemplatesReducer = (
  state: ExportTemplatesState = initialStateExportTemplates,
  action: ExportTemplateActions,
): ExportTemplatesState => {
  switch (action.type) {
    case ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        exportTemplatesListState: 'loading',
      };
    case ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES_SUCCESS:
      return {
        ...state,
        exportTemplatesListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        exportTemplatesList: action.payload.data,
      };
    case ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES_FAIL:
      return {
        ...state,
        exportTemplatesListState: 'error',
        exportTemplatesListError: action.payload,
      };
    case ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE:
      return {
        ...state,
        exportTemplateCreateState: 'loading',
      };
    case ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        exportTemplateCreateState: 'success',
      };
    case ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_FAIL:
      return {
        ...state,
        exportTemplateCreateState: 'error',
        exportTemplateCreateError: action.error.message,
      };
    case ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE:
      return {
        ...state,
        exportTemplateUpdateState: 'loading',
      };
    case ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        exportTemplateUpdateState: 'success',
      };
    case ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_FAIL:
      return {
        ...state,
        exportTemplateUpdateState: 'error',
        exportTemplateUpdateError: action.error.message,
      };
    case ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE:
      return {
        ...state,
        exportTemplateDeleteState: 'loading',
      };
    case ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS:
      return {
        ...state,
        exportTemplateDeleteState: 'success',
      };
    case ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_FAIL:
      return {
        ...state,
        exportTemplateDeleteState: 'error',
        exportTemplateDeleteError: action.error.message,
      };
    case ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID:
      return {
        ...state,
        exportTemplateByIdState: 'loading',
      };
    case ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        exportTemplateByIdState: 'success',
        exportTemplatesList: upsertResource(state.exportTemplatesList, action.payload),
      };
    case ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID_FAIL:
      return {
        ...state,
        exportTemplateByIdState: 'error',
        exportTemplateByIdStateError: action.payload,
      };
    default:
      return state;
  }
};
