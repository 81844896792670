import { DOMLocation, PopoverContainerStyles } from '../Modals';

export const getToolTipLocation = (
  containerStyles: PopoverContainerStyles,
  toolTipLocation?: DOMLocation,
): DOMLocation =>
  toolTipLocation ||
  (containerStyles.top && 'top') ||
  (containerStyles.bottom && 'bottom') ||
  (containerStyles.left && 'left') ||
  (containerStyles.right && 'right') ||
  'top';
