import { upsertResource } from 'utils/resourceUtils';
import { CategoryActions, CategoriesActionTypes } from '../../actions/categories/ActionTypes';
import { CategoriesState } from './Modals';

export const initialStateCategories: CategoriesState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  categoriesList: [],
  listParentCategories: [],
  categoriesItems: {},
  categoriesAncestorsList: {},
  categoriesDescendantsList: {},
  categoriesListState: 'idle',
  categoriesListError: '',
  categoryCreateState: 'idle',
  categoryCreateError: '',
  categoryUpdateState: 'idle',
  categoryUpdateError: '',
  categoryDeleteState: 'idle',
  categoryDeleteError: '',
  categoryByIdState: 'idle',
  categoryByIdStateError: '',
};

export const categoriesReducer = (
  state: CategoriesState = initialStateCategories,
  action: CategoryActions,
): CategoriesState => {
  switch (action.type) {
    case CategoriesActionTypes.GET_ALL_CATEGORIES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        categoriesListState: action.payload.queryType === 'parent' ? state.categoriesListState : 'loading',
      };
    case CategoriesActionTypes.GET_ALL_CATEGORIES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const updatedState =
        action.payload.pagination.queryType === 'parent'
          ? { listParentCategories: action.payload.data }
          : { categoriesList: action.payload.data };
      return {
        ...state,
        ...updatedState,
        categoriesListState: action.payload.pagination.queryType === 'parent' ? state.categoriesListState : 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK_FAIL:
    case CategoriesActionTypes.GET_ALL_CATEGORIES_FAIL:
      return {
        ...state,
        categoriesListState: 'error',
        categoriesListError: action.payload,
      };
    case CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK:
      return {
        ...state,
      };
    case CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK_SUCCESS:
      return {
        ...state,
        categoriesItems: { ...state.categoriesItems, ...action.payload.categoriesItems },
        categoriesAncestorsList: { ...state.categoriesAncestorsList, ...action.payload.categoriesAncestorsList },
        categoriesDescendantsList: { ...state.categoriesDescendantsList, ...action.payload.categoriesDescendantsList },
      };
    case CategoriesActionTypes.CREATE_CATEGORY:
      return {
        ...state,
        categoryCreateState: 'loading',
      };
    case CategoriesActionTypes.CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryCreateState: 'success',
      };
    case CategoriesActionTypes.CREATE_CATEGORY_FAIL:
      return {
        ...state,
        categoryCreateState: 'error',
        categoryCreateError: action.error.message,
      };
    case CategoriesActionTypes.UPDATE_CATEGORY:
      return {
        ...state,
        categoryUpdateState: 'loading',
      };
    case CategoriesActionTypes.UPDATE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryUpdateState: 'success',
      };
    case CategoriesActionTypes.UPDATE_CATEGORY_FAIL:
      return {
        ...state,
        categoryUpdateState: 'error',
        categoryUpdateError: action.error.message,
      };
    case CategoriesActionTypes.DELETE_CATEGORY:
      return {
        ...state,
        categoryDeleteState: 'loading',
      };
    case CategoriesActionTypes.DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryDeleteState: 'success',
      };
    case CategoriesActionTypes.DELETE_CATEGORY_FAIL:
      return {
        ...state,
        categoryDeleteState: 'error',
        categoryDeleteError: action.error.message,
      };
    case CategoriesActionTypes.GET_CATEGORY_BY_ID:
      return {
        ...state,
        categoryByIdState: 'loading',
      };
    case CategoriesActionTypes.GET_CATEGORY_BY_ID_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newListParentCategories = [...state.listParentCategories];
      if (action.payload.parent) {
        newListParentCategories.push(action.payload.parent);
      }
      return {
        ...state,
        categoriesList: upsertResource(state.categoriesList, action.payload.data),
        listParentCategories: newListParentCategories,
        categoryByIdState: 'success',
      };
    case CategoriesActionTypes.GET_CATEGORY_BY_ID_FAIL:
      return {
        ...state,
        categoryByIdState: 'error',
        categoryByIdStateError: action.payload,
      };
    default:
      return state;
  }
};
