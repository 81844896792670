import { AxiosResponse } from 'axios';
import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  ProjectsActionTypes,
  ProjectsUpdateAction,
  ProjectsUpdateErrorAction,
  ProjectsUpdateSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  updateProjectErrorAction,
  updateProjectSuccessAction,
  DS_PROJECTS_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { deserializeProject, serializeProject } from 'utils/projectSerializer';
import { updateLocalProject } from 'services/localProjectsService';
import dataLayer from 'services/dataLayer';

export function* projectUpdateSaga(
  action: ProjectsUpdateAction,
): Generator<SelectEffect | CallEffect | PutEffect<ProjectsUpdateSuccessAction | ProjectsUpdateErrorAction>> {
  try {
    const {
      authState: {
        userInfo: { isAnonymousUser, _id: userId },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    const project = action.payload;

    if (isAnonymousUser) {
      const updatedProject = updateLocalProject(project);

      updatedProject && (yield put(updateProjectSuccessAction(updatedProject)));
    } else {
      const res = yield call(() =>
        axiosInstance.patch(apiUri(`${DS_PROJECTS_ENDPOINT}/${project.id}`, 2), {
          ...deserializeProject(project),
          userID: userId,
        }),
      );
      const savedProject = serializeProject((res as AxiosResponse<Raw.Project>).data);
      dataLayer.collectSaveProjectSettings(savedProject);
      yield put(updateProjectSuccessAction(savedProject));
    }
  } catch (error) {
    yield put(updateProjectErrorAction(getErrorMessage(error) || 'Updating Project failed'));
  }
}

export function* projectUpdateWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECTS_UPDATE, projectUpdateSaga);
}
