import { upsertResource } from 'utils/resourceUtils';
import { DesignBlocksState } from './Modals';
import { DesignBlockActions, DesignBlockActionTypes } from '../../actions/designBlocks/ActionTypes';

export const initialStateDesignBlocks: DesignBlocksState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  designBlocksList: [],
  designBlocksCategories: [
    'Connectivity',
    'Constants',
    'Display',
    'Input',
    'Math',
    'Output',
    'State Machine',
    'Table',
    'Other',
  ],
  designBlocksListState: 'idle',
  designBlocksListError: '',
  designBlockCreateState: 'idle',
  designBlockCreateError: '',
  designBlockUpdateState: 'idle',
  designBlockUpdateError: '',
  designBlockDeleteState: 'idle',
  designBlockDeleteError: '',
  designBlockByIdState: 'idle',
  designBlockByIdStateError: '',
};

export const designBlocksReducer = (
  state: DesignBlocksState = initialStateDesignBlocks,
  action: DesignBlockActions,
): DesignBlocksState => {
  switch (action.type) {
    case DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        designBlocksListState: 'loading',
      };
    case DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS_SUCCESS:
      return {
        ...state,
        designBlocksListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        designBlocksList: action.payload.data,
        designBlocksCategories: Array.from(
          new Set([...state.designBlocksCategories, ...action.payload.data.map((dB) => dB.category)]),
        )
          .filter(Boolean)
          .sort(),
      };
    case DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS_FAIL:
      return {
        ...state,
        designBlocksListState: 'error',
        designBlocksListError: action.payload,
      };
    case DesignBlockActionTypes.CREATE_DESIGN_BLOCK:
      return {
        ...state,
        designBlockCreateState: 'loading',
      };
    case DesignBlockActionTypes.CREATE_DESIGN_BLOCK_SUCCESS:
      return {
        ...state,
        designBlockCreateState: 'success',
      };
    case DesignBlockActionTypes.CREATE_DESIGN_BLOCK_FAIL:
      return {
        ...state,
        designBlockCreateState: 'error',
        designBlockCreateError: action.error.message,
      };
    case DesignBlockActionTypes.UPDATE_DESIGN_BLOCK:
      return {
        ...state,
        designBlockUpdateState: 'loading',
      };
    case DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_SUCCESS:
      return {
        ...state,
        designBlockUpdateState: 'success',
      };
    case DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_FAIL:
      return {
        ...state,
        designBlockUpdateState: 'error',
        designBlockUpdateError: action.error.message,
      };
    case DesignBlockActionTypes.DELETE_DESIGN_BLOCK:
      return {
        ...state,
        designBlockDeleteState: 'loading',
      };
    case DesignBlockActionTypes.DELETE_DESIGN_BLOCK_SUCCESS:
      return {
        ...state,
        designBlockDeleteState: 'success',
      };
    case DesignBlockActionTypes.DELETE_DESIGN_BLOCK_FAIL:
      return {
        ...state,
        designBlockDeleteState: 'error',
        designBlockDeleteError: action.error.message,
      };
    case DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID:
      return {
        ...state,
        designBlockByIdState: 'loading',
      };
    case DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID_SUCCESS:
      return {
        ...state,
        designBlockByIdState: 'success',
        designBlocksList: upsertResource(state.designBlocksList, action.payload),
      };
    case DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID_FAIL:
      return {
        ...state,
        designBlockByIdState: 'error',
        designBlockByIdStateError: action.payload,
      };
    default:
      return state;
  }
};
