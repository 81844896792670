import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  SpecificationActionTypes,
  GetAllSpecificationsAction,
  GetAllSpecificationsErrorAction,
  GetAllSpecificationsSuccessAction,
} from 'state_management/actions/specifications/ActionTypes';

import {
  getAllSpecificationsSuccessAction,
  getAllSpecificationsErrorAction,
  ENDPOINT_SPECIFICATIONS,
} from 'state_management/actions/specifications/specificationsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeSpecification } from 'utils/specificationsSerializer';
import { SpecificationList } from 'state_management/reducers/specifications/Modals';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { combineSearchQueries, getSearchQuery, TypeSearch } from 'utils/searchHelper';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';

export function* getAllSaga(
  action: GetAllSpecificationsAction,
): Generator<
  | PutEffect<GetAllSpecificationsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllSpecificationsSuccessAction | GetAllSpecificationsErrorAction>
> {
  try {
    const {
      specificationsState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload.pagination;

    // NOTE: for Spec and Interfaces, it could be `full` list rf `paginated`
    const isPaginatedAction = action.payload.pagination.queryType === 'paginated';

    const specificationsSearchSchema: TypeSearch = {
      key: 'value',
      name: 'value',
      spec_type: 'value',
      short_description: 'value',
      full_info: 'value',
      data_type: 'value',
      unit: 'value',
    };

    const startingString = advancedSearchQuery?.startsWith('query=') ? '' : 'query=';
    const specTypesQuery = action.payload.specTypes.reduce(
      (prev, curr) => `${prev}${prev === startingString ? '' : '%25or%25'}spec_type="${curr}"`,
      startingString,
    );
    const newAdvancedSearchQuery = combineSearchQueries(advancedSearchQuery || '', specTypesQuery, true);

    const searchQuery = combineSearchQueries(
      newAdvancedSearchQuery,
      getSearchQuery(escapeSearch(search), specificationsSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const query = isPaginatedAction ? `?pg=${page}&pg_len=${perPage}&${searchQuery}` : `?${searchQuery}`;

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}${query}`, 2)),
    )) as AxiosResponse;
    const _specificationsList = res.data.reduce(
      (a: SpecificationList, val: Raw.ISpecification) => ({
        ...a,
        [val.spec_type]: [...(a[val.spec_type] || []), serializeSpecification(val)],
      }),
      { implementation: [], pcb: [], custom_field: [] } as SpecificationList,
    );

    const _pagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllSpecificationsSuccessAction(_pagination, _specificationsList, action.payload.specTypes));
  } catch (error) {
    yield put(
      getAllSpecificationsErrorAction(getErrorMessage(error as AxiosError) || 'Fetching specifications failed.'),
    );
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(SpecificationActionTypes.GET_ALL_SPECIFICATIONS, getAllSaga);
}
