import styled from 'styled-components';

export const Dragger = styled.div`
  && {
    width: 100%;
    height: 2px;
    cursor: n-resize;
    border-top: 1px solid #ddd;
    background-color: ${({ theme }): string => theme.palette.lightgrey};
  }
`;

export const Drawer = styled.div`
  && {
    width: calc(100% - 24px);
    margin: 0 12px;
    overflow: hidden;
    background-color: ${({ theme }): string => theme.palette.white};
    transition: all 300ms ease-in-out;
  }

  &&.open {
    margin-top: 12px;
  }

  &&.close {
    margin-top: 0;
  }
`;

export const PanelSplitWrapper = styled.div`
  && {
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }
`;
