import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  SelectEffect,
  select,
} from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getCategoryByIdSuccess,
  getCategoryByIdError,
  ENDPOINT_CATEGORIES,
} from 'state_management/actions/categories/categoriesActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetCategoryByIdErrorAction,
  GetCategoryByIdSuccessAction,
  GetCategoryByIdAction,
  CategoriesActionTypes,
} from 'state_management/actions/categories/ActionTypes';
import { CategoriesState, ICategories } from 'state_management/reducers/categories/Modals';
import { serializeCategories } from 'utils/categoriesSerializer';
import { isMongoId } from 'utils/mongoId';
import { AppState } from 'state_management/AppState';

export function* getByIdSaga(
  action: GetCategoryByIdAction,
): Generator<
  | PutEffect<GetCategoryByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetCategoryByIdSuccessAction | GetCategoryByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedCategory: ICategories = serializeCategories(res.data);

    let parsedParentCategory: ICategories | undefined;
    if (isMongoId(parsedCategory.parent)) {
      const { listParentCategories } = (yield select((state: AppState) => state.categoriesState)) as CategoriesState;

      if (!listParentCategories.find((cat) => cat.id === parsedCategory.parent)) {
        const resParent = (yield call(() =>
          axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/${parsedCategory.parent}`, 2)),
        )) as AxiosResponse;
        parsedParentCategory = serializeCategories(resParent.data);
      }
    }

    yield put(getCategoryByIdSuccess({ data: parsedCategory, parent: parsedParentCategory }));
  } catch (error) {
    yield put(getCategoryByIdError(getErrorMessage(error) || 'Fetching categories failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(CategoriesActionTypes.GET_CATEGORY_BY_ID, getByIdSaga);
}
