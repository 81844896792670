import styled from 'styled-components';

export const ClickBlocker = styled.span`
  && {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
`;
