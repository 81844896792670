import { IDesignBlock } from 'state_management/reducers/designBlocks/Modals';
import { deserializeDesignBlock } from 'utils/designBlocksSerializer';
import { IPagination } from 'models/Pagination';
import {
  GetAllDesignBlocksAction,
  CreateDesignBlockAction,
  UpdateDesignBlockAction,
  DeleteDesignBlockAction,
  DesignBlockActionTypes,
  GetDesignBlockByIdAction,
  GetDesignBlockByIdSuccessAction,
  GetDesignBlockByIdErrorAction,
  GetAllDesignBlocksErrorAction,
  GetAllDesignBlocksSuccessAction,
  CloneDesignBlockAction,
  GetDesignBlockDependenciesAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const ENDPOINT_DESIGN_BLOCKS = 'design-blocks';

export const getAllDesignBlocksAction = (payload: Partial<IPagination> = {}): GetAllDesignBlocksAction => ({
  payload,
  type: DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS,
});

export const getAllDesignBlocksSuccessAction = (
  pagination: IPagination,
  data: Array<IDesignBlock>,
): GetAllDesignBlocksSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS_SUCCESS,
});

export const getAllDesignBlocksErrorAction = (payload: string): GetAllDesignBlocksErrorAction => ({
  payload,
  type: DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS_FAIL,
});

export const getDesignBlockDependenciesAction = (): GetDesignBlockDependenciesAction => ({
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_DEPENDENCIES,
});

export const createDesignBlock = (designBlock: Partial<IDesignBlock>): CreateDesignBlockAction => ({
  type: DesignBlockActionTypes.CREATE_DESIGN_BLOCK,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}`, 2),
      data: deserializeDesignBlock(designBlock) as unknown as Raw.IDesignBlock,
    },
  },
});

export const updateDesignBlock = (designBlock: IDesignBlock): UpdateDesignBlockAction => ({
  type: DesignBlockActionTypes.UPDATE_DESIGN_BLOCK,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}/${designBlock.id}`, 2),
      data: deserializeDesignBlock(designBlock) as unknown as Raw.IDesignBlock,
    },
  },
});

export const cloneDesignBlock = (designBlock: Partial<IDesignBlock>): CloneDesignBlockAction => ({
  type: DesignBlockActionTypes.CLONE_DESIGN_BLOCK,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}`, 2),
      data: deserializeDesignBlock(designBlock) as unknown as Raw.IDesignBlock,
    },
  },
});

export const deleteDesignBlock = (designBlock: Partial<IDesignBlock>): DeleteDesignBlockAction => ({
  type: DesignBlockActionTypes.DELETE_DESIGN_BLOCK,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}/${designBlock.id}`, 2),
    },
  },
});

export const getDesignBlockById = (payload: Partial<IDesignBlock>): GetDesignBlockByIdAction => ({
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getDesignBlockByIdSuccess = (payload: IDesignBlock): GetDesignBlockByIdSuccessAction => ({
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID_SUCCESS,
  payload,
});

export const getDesignBlockByIdError = (payload: string): GetDesignBlockByIdErrorAction => ({
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID_FAIL,
  payload,
});
