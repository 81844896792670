import { all, AllEffect } from 'redux-saga/effects';
import { projectToCanvasSagaWatcher } from './navigateToCanvas';
import { projectStateSagaWatcher } from './getProjectStateSaga';
import { projectCanvasCompileSagaWatcher } from './projectCanvasCompileSaga';
import { requestsToastsSagaWatcher } from './requestsToastsSaga';
import { projectFilesDownloadSagaWatcher } from './projectFilesDownloadSaga';
import { projectImportSagaWatcher } from './projectImportSaga';
import { projectImageUpdateWatcher } from './projectImageUpdateSaga';
import { getAllWatcher } from './getAllSaga';
import { refreshWatcher } from './refreshSaga';
import { projectAddWatcher } from './projectAddSaga';
import { projectUpdateWatcher } from './projectUpdateSaga';
import { projectDeleteWatcher } from './projectDeleteSaga';
import { projectCloneWatcher } from './projectCloneSaga';
import { projectGetByIdWatcher } from './projectGetByIdSaga';
import { projectStateChangeSagaWatcher } from './projectStateSaga';
import { projectCompileIssuesSagaWatcher } from './projectCompileIssuesSaga';

export function* projectsRootSaga(): Generator<AllEffect<any>> {
  yield all([
    projectCompileIssuesSagaWatcher(),
    projectToCanvasSagaWatcher(),
    projectStateSagaWatcher(),
    projectCanvasCompileSagaWatcher(),
    projectStateChangeSagaWatcher(),
    requestsToastsSagaWatcher(),
    projectFilesDownloadSagaWatcher(),
    projectImportSagaWatcher(),
    projectImageUpdateWatcher(),
    refreshWatcher(),
    getAllWatcher(),
    projectAddWatcher(),
    projectUpdateWatcher(),
    projectDeleteWatcher(),
    projectCloneWatcher(),
    projectGetByIdWatcher(),
  ]);
}
