import { createLogic } from 'redux-logic';
import { AuthActionTypes } from 'state_management/actions/auth/ActionTypes';
import { AppState } from 'state_management/AppState';
import { goToLogin } from 'services/main_app';

const baseConfig = {
  latest: true,
};

// TODO: Eventually AuthActionTypes.LOGIN should mean Login not Nav to Login
const performLogin = createLogic<AppState>({
  ...baseConfig,
  type: [AuthActionTypes.LOGIN],
  process({ getState }, _dispatch, done) {
    const { isLoggedIn } = getState().authState;

    const onLogin = window.location.pathname.includes('/login');

    if (!isLoggedIn && !onLogin) {
      goToLogin();
    }

    done();
  },
});

export default [performLogin];
