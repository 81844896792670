import { PutEffect, ForkEffect, CallEffect, put, takeEvery, call, SelectEffect, select } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import {
  SubSystemCanvasActionTypes,
  SaveSubSystemCanvasAction,
  SaveSubSystemCanvasErrorAction,
  SaveSubSystemCanvasSuccessAction,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import {
  ENDPOINT_SUB_SYSTEM_CANVAS,
  saveSubSystemCanvasErrorAction,
  saveSubSystemCanvasSuccessAction,
} from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';

import { axiosInstance } from 'services/dataService';
import { getErrorMessage } from 'utils/getErrorMessage';
import { deSerializeSubSystemCanvas, serializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';
import { apiUri } from 'services/main_app';
import {
  errorTypeToMessage,
  isSubSystemCanvasSaveDataValid,
  sanitizeErrorMessage,
  subsystemSaveErrors,
} from 'utils/validators/subSystemValidator';
import { ENDPOINT_SPECIFICATIONS } from 'state_management/actions/specifications/specificationsActions';
import { AppState } from 'state_management/AppState';
import i18n from '../../../i18n/config';

export function* saveSaga(
  action: SaveSubSystemCanvasAction,
): Generator<
  | CallEffect<AxiosResponse<Partial<RawSubSystemCanvas.Payload>>>
  | PutEffect<SaveSubSystemCanvasSuccessAction | SaveSubSystemCanvasErrorAction>
  | SelectEffect
> {
  const store = (yield select((state) => state)) as AppState;
  const { isValid, errorType, corruptedObject } = isSubSystemCanvasSaveDataValid(action.payload, store) || {};
  const t = i18n.t.bind(i18n);
  try {
    if (!isValid) {
      const errorMessageObject = errorTypeToMessage[errorType || ''];
      if (errorMessageObject?.key) {
        const corruptedObjectName =
          typeof corruptedObject === 'string' ? corruptedObject : corruptedObject?.objectName || '';
        const errorMessage = t(errorMessageObject.key, errorMessageObject.errorMessage);
        const sanitizedErrorMessage = sanitizeErrorMessage(
          errorMessage,
          errorType as subsystemSaveErrors,
          corruptedObjectName,
        );
        yield put(saveSubSystemCanvasErrorAction(sanitizedErrorMessage));
        return;
      }
    }

    /* 
      IMPORTANT NOTE: subsystem end point (ENDPOINT_SUB_SYSTEM_CANVA) returns an object in which specs only has ids 
      Here, we need the spec_type as well, so we need to call the spec end point 
      itself (ENDPOINT_SPECIFICATION) to get the data 
      We could have stuffed the spec completely by the ENDPOINT_SUB_SYSTEM_CANVAS,
      Since, we really need this spec-type data, we have to go for a second call, which is not a good idea!
      So, TODO: LATER, we can see if the ENDPOINT_SUB_SYSTEM_CANVAS should return a complete spec object,
      Then, the second call can be removed
  */

    const resSubsystemCanvas = (yield call(() =>
      axiosInstance.post<Partial<RawSubSystemCanvas.Payload>>(
        apiUri(`/dataservice/${ENDPOINT_SUB_SYSTEM_CANVAS}`, 2),
        deSerializeSubSystemCanvas(action.payload),
      ),
    )) as AxiosResponse<Partial<RawSubSystemCanvas.Payload>>;

    const subsysSpecIds =
      (resSubsystemCanvas?.data as Partial<RawSubSystemCanvas.Payload>)?.specifications?.map((s) => s.id) || [];

    const detailedSpecResult = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}?query=spec_type="implementation"`, 2)),
    )) as AxiosResponse;

    if (detailedSpecResult?.data && subsysSpecIds?.length && resSubsystemCanvas) {
      (detailedSpecResult.data as Array<Raw.ISpecification>).forEach((s) => {
        subsysSpecIds.forEach((id) => {
          s.id === id && resSubsystemCanvas.data.specifications?.push(s);
        });
      });
    }

    yield put(saveSubSystemCanvasSuccessAction(serializeSubSystemCanvas(resSubsystemCanvas?.data)));
  } catch (error) {
    const subsystemUnknownError = t(
      'supernova:subSystemCanvas.save.validation.subSystem.unknownError',
      'Saving Sub System Canvas failed.',
    );
    yield put(saveSubSystemCanvasErrorAction(getErrorMessage(error as AxiosError) || subsystemUnknownError));
  }
}

export function* saveWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS, saveSaga);
}
