import { IFileUpload } from 'models/IFileUpload';
import { IPagination } from 'models/Pagination';
import { IComponents, IComponentWithChildren } from 'state_management/reducers/components/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum ComponentActionTypes {
  GET_ALL_COMPONENTS = 'GET_ALL_COMPONENTS',
  GET_ALL_COMPONENTS_SUCCESS = 'GET_ALL_COMPONENTS_SUCCESS',
  GET_ALL_COMPONENTS_FAIL = 'GET_ALL_COMPONENTS_FAIL',

  GET_ALL_FOR_LINK_COMPONENTS = 'GET_ALL_FOR_LINK_COMPONENTS',
  GET_ALL_FOR_LINK_COMPONENTS_SUCCESS = 'GET_ALL_FOR_LINK_COMPONENTS_SUCCESS',
  GET_ALL_FOR_LINK_COMPONENTS_FAIL = 'GET_ALL_FOR_LINK_COMPONENTS_FAIL',

  GET_COMPONENT_BY_ID = 'GET_COMPONENT_BY_ID',
  GET_COMPONENT_BY_ID_SUCCESS = 'GET_COMPONENT_BY_ID_SUCCESS',
  GET_COMPONENT_BY_ID_FAIL = 'GET_COMPONENT_BY_ID_FAIL',

  GET_COMPONENT_DEPENDENCIES = 'GET_COMPONENT_DEPENDENCIES',

  CREATE_COMPONENT = 'CREATE_COMPONENT',
  CREATE_COMPONENT_SUCCESS = 'CREATE_COMPONENT_SUCCESS',
  CREATE_COMPONENT_FAIL = 'CREATE_COMPONENT_FAIL',

  UPDATE_COMPONENT = 'UPDATE_COMPONENT',
  UPDATE_COMPONENT_SUCCESS = 'UPDATE_COMPONENT_SUCCESS',
  UPDATE_COMPONENT_FAIL = 'UPDATE_COMPONENT_FAIL',

  CLONE_COMPONENT = 'CLONE_COMPONENT',
  CLONE_COMPONENT_SUCCESS = 'CLONE_COMPONENT_SUCCESS',
  CLONE_COMPONENT_FAIL = 'CLONE_COMPONENT_FAIL',

  DELETE_COMPONENT = 'DELETE_COMPONENT',
  DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS',
  DELETE_COMPONENT_FAIL = 'DELETE_COMPONENT_FAIL',

  UPLOAD_COMPONENT_CAD_FILE = 'UPLOAD_COMPONENT_CAD_FILE',
  UPLOAD_COMPONENT_CAD_FILE_SUCCESS = 'UPLOAD_COMPONENT_CAD_FILE_SUCCESS',
  UPLOAD_COMPONENT_CAD_FILE_FAIL = 'UPLOAD_COMPONENT_CAD_FILE_FAIL',

  VERIFY_COMPONENT_CAD_FILE = 'VERIFY_COMPONENT_CAD_FILE',
  VERIFY_COMPONENT_CAD_FILE_SUCCESS = 'VERIFY_COMPONENT_CAD_FILE_SUCCESS',
  VERIFY_COMPONENT_CAD_FILE_FAIL = 'VERIFY_COMPONENT_CAD_FILE_FAIL',

  DELETE_COMPONENT_CAD_FILE = 'DELETE_COMPONENT_CAD_FILE',

  UPLOAD_COMPONENT_DOC_FILE = 'UPLOAD_COMPONENT_DOC_FILE',
  UPLOAD_COMPONENT_DOC_FILE_SUCCESS = 'UPLOAD_COMPONENT_DOC_FILE_SUCCESS',
  UPLOAD_COMPONENT_DOC_FILE_FAIL = 'UPLOAD_COMPONENT_DOC_FILE_FAIL',

  VERIFY_COMPONENT_DOC_FILE = 'VERIFY_COMPONENT_DOC_FILE',
  VERIFY_COMPONENT_DOC_FILE_SUCCESS = 'VERIFY_COMPONENT_DOC_FILE_SUCCESS',
  VERIFY_COMPONENT_DOC_FILE_FAIL = 'VERIFY_COMPONENT_DOC_FILE_FAIL',

  DELETE_COMPONENT_DOC_FILE = 'DELETE_COMPONENT_DOC_FILE',

  UPDATE_COMPONENT_FILE = 'UPDATE_COMPONENT_FILE',
  DOWNLOAD_COMPONENT_FILE = 'DOWNLOAD_COMPONENT_FILE',
  CLEAN_UP_COMPONENTS = 'CLEAN_UP_COMPONENTS',
  CLEAN_UP_COMPONENT_LINKS = 'CLEAN_UP_COMPONENT_LINKS',
}

// ============================================ GET_ALL_COMPONENTS

export interface GetAllComponentsAction {
  payload: Partial<IPagination>;
  type: ComponentActionTypes.GET_ALL_COMPONENTS;
}

export interface GetAllComponentsSuccessAction {
  payload: { pagination: IPagination; data: Array<IComponents> };
  type: ComponentActionTypes.GET_ALL_COMPONENTS_SUCCESS;
}

export interface GetAllComponentsErrorAction {
  payload: string;
  type: ComponentActionTypes.GET_ALL_COMPONENTS_FAIL;
}

// ============================================ GET_ALL_FOR_LINK_COMPONENTS

export interface GetAllForLinkComponentsAction {
  payload: Partial<IPagination> & { withResetList: boolean };
  type: ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS;
}

export interface GetAllForLinkComponentsSuccessAction {
  payload: { data: Array<IComponentWithChildren>; withResetList: boolean };
  type: ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS_SUCCESS;
}

export interface GetAllForLinkComponentsErrorAction {
  payload: string;
  type: ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS_FAIL;
}

// ============================================ GET_COMPONENT_BY_ID

export interface GetComponentByIdAction {
  payload: { id: string };
  type: ComponentActionTypes.GET_COMPONENT_BY_ID;
}

export interface GetComponentByIdSuccessAction {
  payload: {
    data: IComponents;
    docFiles: Array<IFileUpload>;
    cadFiles: Array<IFileUpload>;
  };
  type: ComponentActionTypes.GET_COMPONENT_BY_ID_SUCCESS;
}

export interface GetComponentByIdErrorAction {
  payload: string;
  type: ComponentActionTypes.GET_COMPONENT_BY_ID_FAIL;
}

// ============================================ GET_COMPONENT_DEPENDENCIES

export interface GetComponentDependenciesAction {
  type: ComponentActionTypes.GET_COMPONENT_DEPENDENCIES;
}

// ============================================ CREATE_COMPONENT

export interface CreateComponentAction extends AxiosRequestPayload<Partial<Raw.Components>> {
  type: ComponentActionTypes.CREATE_COMPONENT;
}

export interface CreateComponentSuccessAction
  extends AxiosSuccessPayload<
    Partial<Raw.Components>,
    ComponentActionTypes.CREATE_COMPONENT_SUCCESS,
    CreateComponentAction
  > {
  type: ComponentActionTypes.CREATE_COMPONENT_SUCCESS;
}

export interface CreateComponentErrorAction
  extends AxiosErrorPayload<ComponentActionTypes.CREATE_COMPONENT_FAIL, CreateComponentAction> {
  type: ComponentActionTypes.CREATE_COMPONENT_FAIL;
}

// ============================================ UPDATE_COMPONENT

export interface UpdateComponentAction extends AxiosRequestPayload<Partial<Raw.Components>> {
  type: ComponentActionTypes.UPDATE_COMPONENT;
}
export interface UpdateComponentSuccessAction
  extends AxiosSuccessPayload<
    Partial<Raw.Components>,
    ComponentActionTypes.UPDATE_COMPONENT_SUCCESS,
    UpdateComponentAction
  > {
  type: ComponentActionTypes.UPDATE_COMPONENT_SUCCESS;
}
export interface UpdateComponentErrorAction
  extends AxiosErrorPayload<ComponentActionTypes.UPDATE_COMPONENT_FAIL, UpdateComponentAction> {
  type: ComponentActionTypes.UPDATE_COMPONENT_FAIL;
}

// ============================================ CLONE_COMPONENT

export interface CloneComponentAction extends AxiosRequestPayload<Partial<Raw.Components>> {
  type: ComponentActionTypes.CLONE_COMPONENT;
}

export interface CloneComponentSuccessAction
  extends AxiosSuccessPayload<
    Partial<Raw.Components>,
    ComponentActionTypes.CLONE_COMPONENT_SUCCESS,
    CloneComponentAction
  > {
  type: ComponentActionTypes.CLONE_COMPONENT_SUCCESS;
}

export interface CloneComponentErrorAction
  extends AxiosErrorPayload<ComponentActionTypes.CLONE_COMPONENT_FAIL, CloneComponentAction> {
  type: ComponentActionTypes.CLONE_COMPONENT_FAIL;
}

// ============================================ DELETE_COMPONENT

export interface DeleteComponentAction extends AxiosRequestPayload {
  type: ComponentActionTypes.DELETE_COMPONENT;
}
export interface DeleteComponentSuccessAction
  extends AxiosSuccessPayload<string, ComponentActionTypes.DELETE_COMPONENT_SUCCESS, DeleteComponentAction> {
  type: ComponentActionTypes.DELETE_COMPONENT_SUCCESS;
}
export interface DeleteComponentErrorAction
  extends AxiosErrorPayload<ComponentActionTypes.DELETE_COMPONENT_FAIL, DeleteComponentAction> {
  type: ComponentActionTypes.DELETE_COMPONENT_FAIL;
}

// ============================================ UPLOAD_COMPONENT_CAD_FILE

export interface UploadComponentCadFilesAction {
  type: ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE;
  payload: Array<IFileUpload>;
}

export interface UploadComponentCadFileSuccessAction {
  type: ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_SUCCESS;
  payload: IFileUpload;
}

export interface UploadComponentCadFileFailAction {
  type: ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_FAIL;
  payload: IFileUpload;
}

// ============================================ DELETE_COMPONENT_CAD_FILE

export interface DeleteComponentCadFileAction {
  payload: IFileUpload;
  type: ComponentActionTypes.DELETE_COMPONENT_CAD_FILE;
}

// ============================================ UPLOAD_COMPONENT_DOC_FILE

export interface UploadComponentDocFilesAction {
  type: ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE;
  payload: Array<IFileUpload>;
}

export interface UploadComponentDocFileSuccessAction {
  type: ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE_SUCCESS;
  payload: IFileUpload;
}

export interface UploadComponentDocFileFailAction {
  type: ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE_FAIL;
  payload: IFileUpload;
}

// ============================================ DELETE_COMPONENT_DOC_FILE

export interface DeleteComponentDocFileAction {
  payload: IFileUpload;
  type: ComponentActionTypes.DELETE_COMPONENT_DOC_FILE;
}

// ============================================ UPDATE_COMPONENT_FILE

export interface UpdateComponentFileAction extends AxiosRequestPayload<Partial<Raw.IMinioResource>> {
  type: ComponentActionTypes.UPDATE_COMPONENT_FILE;
}

// ============================================ DOWNLOAD_COMPONENT_FILE

export interface DownloadComponentFileAction extends AxiosRequestPayload {
  type: ComponentActionTypes.DOWNLOAD_COMPONENT_FILE;
}

// ============================================ CLEAN_UP_COMPONENTS

export interface CleanUpComponentsAction {
  type: ComponentActionTypes.CLEAN_UP_COMPONENTS;
}

export interface CleanUpComponentLinksAction {
  type: ComponentActionTypes.CLEAN_UP_COMPONENT_LINKS;
}

// ============================================ ALL

export type ComponentActions =
  | GetAllComponentsAction
  | GetAllComponentsSuccessAction
  | GetAllComponentsErrorAction
  | GetAllForLinkComponentsAction
  | GetAllForLinkComponentsSuccessAction
  | GetAllForLinkComponentsErrorAction
  | GetComponentDependenciesAction
  | CreateComponentAction
  | CreateComponentSuccessAction
  | CreateComponentErrorAction
  | UpdateComponentAction
  | UpdateComponentSuccessAction
  | UpdateComponentErrorAction
  | CloneComponentAction
  | CloneComponentSuccessAction
  | CloneComponentErrorAction
  | DeleteComponentAction
  | DeleteComponentSuccessAction
  | DeleteComponentErrorAction
  | GetComponentByIdAction
  | GetComponentByIdSuccessAction
  | GetComponentByIdErrorAction
  | UploadComponentCadFilesAction
  | UploadComponentCadFileSuccessAction
  | UploadComponentCadFileFailAction
  | DeleteComponentCadFileAction
  | UploadComponentDocFilesAction
  | UploadComponentDocFileSuccessAction
  | UploadComponentDocFileFailAction
  | UpdateComponentFileAction
  | DeleteComponentDocFileAction
  | CleanUpComponentsAction
  | CleanUpComponentLinksAction;
