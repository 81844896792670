import { MachineLearningActions, MachineLearningActionTypes } from '../../actions/machineLearning/ActionTypes';
import { MachineLearningState } from './Modals';

export const initialStateMachineLearning: MachineLearningState = {
  predictedInterfacesList: [],
  predictedLayerSignalType: {},
  listNets: [],
  errorMsg: '',
  pendingResponse: false,
};

export const machineLearningReducer = (
  state: MachineLearningState = initialStateMachineLearning,
  action: MachineLearningActions,
): MachineLearningState => {
  switch (action.type) {
    case MachineLearningActionTypes.GET_LIST_NETS: {
      return {
        ...state,
        pendingResponse: true,
      };
    }
    case MachineLearningActionTypes.GET_LIST_NETS_SUCCESS: {
      return {
        ...state,
        predictedInterfacesList: action.payload.interfaces || [],
        predictedLayerSignalType: action.payload.layerSignalType || {},
        pendingResponse: false,
      };
    }
    case MachineLearningActionTypes.GET_LIST_NETS_FAIL: {
      return {
        ...state,
        errorMsg: action.payload,
        pendingResponse: false,
      };
    }
    case MachineLearningActionTypes.GET_LIST_SIGNALS: {
      return {
        ...state,
      };
    }
    case MachineLearningActionTypes.GET_LIST_SIGNALS_SUCCESS: {
      return {
        ...state,
        listNets: action.payload.data.list_nets,
      };
    }
    case MachineLearningActionTypes.GET_LIST_SIGNALS_FAIL: {
      return {
        ...state,
        errorMsg: action.error.message,
      };
    }
    case MachineLearningActionTypes.MACHINE_LEARNING_CLEAN_UP: {
      return { ...state, predictedInterfacesList: [], predictedLayerSignalType: {}, listNets: [] };
    }
    default:
      return state;
  }
};
