import {
  ImplementationActionTypes,
  ImplementationActions,
  UpdateImplementationErrorAction,
  CreateImplementationErrorAction,
  DeleteImplementationErrorAction,
  CloneImplementationErrorAction,
} from 'state_management/actions/implementations/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const implementationsUpdateToasts = (action: ImplementationActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Cubo is being updated, please wait...',
      success: 'Cubo has been successfully updated.',
      error:
        getErrorMessage((action as UpdateImplementationErrorAction).error) ||
        'Updating Cubo failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const implementationsCloneToasts = (action: ImplementationActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Cubo is being cloned, please wait...',
      success: 'Cubo has been successfully cloned.',
      error:
        getErrorMessage((action as CloneImplementationErrorAction).error) || 'Cloning Cubo failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const implementationsCreateToasts = (action: ImplementationActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Cubo is being created, please wait...',
      success: 'Cubo has been successfully created.',
      error:
        getErrorMessage((action as CreateImplementationErrorAction).error) ||
        'Creating Cubo failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const implementationsDeleteToasts = (action: ImplementationActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Cubo is being deleted, please wait...',
      success: 'Cubo has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteImplementationErrorAction).error) ||
        'Deleting Cubo failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        ImplementationActionTypes.CREATE_IMPLEMENTATION,
        ImplementationActionTypes.CREATE_IMPLEMENTATION_SUCCESS,
        ImplementationActionTypes.CREATE_IMPLEMENTATION_FAIL,
      ],
      implementationsCreateToasts,
    ),
    takeEvery(
      [
        ImplementationActionTypes.UPDATE_IMPLEMENTATION,
        ImplementationActionTypes.UPDATE_IMPLEMENTATION_SUCCESS,
        ImplementationActionTypes.UPDATE_IMPLEMENTATION_FAIL,
      ],
      implementationsUpdateToasts,
    ),
    takeEvery(
      [
        ImplementationActionTypes.CLONE_IMPLEMENTATION,
        ImplementationActionTypes.CLONE_IMPLEMENTATION_SUCCESS,
        ImplementationActionTypes.CLONE_IMPLEMENTATION_FAIL,
      ],
      implementationsCloneToasts,
    ),
    takeEvery(
      [
        ImplementationActionTypes.DELETE_IMPLEMENTATION,
        ImplementationActionTypes.DELETE_IMPLEMENTATION_SUCCESS,
        ImplementationActionTypes.DELETE_IMPLEMENTATION_FAIL,
      ],
      implementationsDeleteToasts,
    ),
  ]);
}
