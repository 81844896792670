import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  ExportTemplatesActionTypes,
  GetAllExportTemplatesAction,
  GetAllExportTemplatesErrorAction,
  GetAllExportTemplatesSuccessAction,
} from 'state_management/actions/exportTemplates/ActionTypes';

import {
  getAllExportTemplatesSuccessAction,
  getAllExportTemplatesErrorAction,
  ENDPOINT_EXPORT_TEMPLATES,
} from 'state_management/actions/exportTemplates/exportTemplatesActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';
import { serializeExportTemplate } from 'utils/exportTemplatesSerializer';

export function* getAllSaga(
  action: GetAllExportTemplatesAction,
): Generator<
  | PutEffect<GetAllExportTemplatesAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllExportTemplatesSuccessAction | GetAllExportTemplatesErrorAction>
> {
  try {
    const pagination = (yield select((state: AppState) => state.exportTemplatesState.pagination)) as IPagination;
    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const exportTemplatesSearchSchema: TypeSearch = {
      name: 'value',
      resource: 'value',
      nameFrom: 'value',
      description: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), exportTemplatesSearchSchema),
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_EXPORT_TEMPLATES}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2),
      ),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllExportTemplatesSuccessAction(_pagination, res.data.map(serializeExportTemplate)));
  } catch (error) {
    yield put(getAllExportTemplatesErrorAction(getErrorMessage(error) || 'Fetching export templates failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES, getAllSaga);
}
