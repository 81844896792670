import {
  DesignConsiderationsActionTypes,
  DesignConsiderationActions,
  UpdateDesignConsiderationErrorAction,
  CreateDesignConsiderationErrorAction,
  DeleteDesignConsiderationErrorAction,
} from 'state_management/actions/designConsiderations/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const designConsiderationsUpdateToasts = (action: DesignConsiderationActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Design Consideration is being updated, please wait...',
      success: 'Design Consideration has been successfully updated.',
      error:
        getErrorMessage((action as UpdateDesignConsiderationErrorAction).error) ||
        'Updating Design Consideration failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const designConsiderationsCloneToasts = (action: DesignConsiderationActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Design Consideration is being cloned, please wait...',
      success: 'Design Consideration has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateDesignConsiderationErrorAction).error) ||
        'Cloning Design Consideration failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const designConsiderationsCreateToasts = (action: DesignConsiderationActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Design Consideration is being created, please wait...',
      success: 'Design Consideration has been successfully created.',
      error:
        getErrorMessage((action as CreateDesignConsiderationErrorAction).error) ||
        'Creating Design Consideration failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const designConsiderationsDeleteToasts = (action: DesignConsiderationActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Design Consideration is being deleted, please wait...',
      success: 'Design Consideration has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteDesignConsiderationErrorAction).error) ||
        'Deleting Design Consideration failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION,
        DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_SUCCESS,
        DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_FAIL,
      ],
      designConsiderationsCreateToasts,
    ),
    takeEvery(
      [
        DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION,
        DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_SUCCESS,
        DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_FAIL,
      ],
      designConsiderationsUpdateToasts,
    ),
    takeEvery(
      [
        DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION,
        DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_SUCCESS,
        DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_FAIL,
      ],
      designConsiderationsCloneToasts,
    ),
    takeEvery(
      [
        DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION,
        DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_SUCCESS,
        DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_FAIL,
      ],
      designConsiderationsDeleteToasts,
    ),
  ]);
}
