import { connect } from 'react-redux';

import { AppState } from 'state_management/AppState';

import App from './App';

import { IProps } from './IProps';

export default connect(
  (state: AppState): Omit<IProps, 'dispatch'> => ({
    loggedOut: state.authState.loggedOut,
    userId: state.authState.userInfo?._id,
    currentAppType: state.authState.currentAppType,
    theme: state.authState.theme,
    userInfo: state.authState.userInfo,
    getActiveThemeRequestState: state.authState.getActiveThemeRequestState,
  }),
)(App);
