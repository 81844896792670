import { upsertResource } from 'utils/resourceUtils';
import { IFileState } from 'models/IFileUpload';
import { ImplementationsState } from './Modals';
import { ImplementationActions, ImplementationActionTypes } from '../../actions/implementations/ActionTypes';

export const initialStateImplementations: ImplementationsState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  implementationsList: [],
  implementationsListState: 'idle',
  implementationsListError: '',
  implementationById: '',
  implementationByIdState: 'idle',
  implementationByIdError: '',
  implementationCreateState: 'idle',
  implementationCreateError: '',
  implementationUpdateState: 'idle',
  implementationUpdateError: '',
  implementationDeleteState: 'idle',
  implementationDeleteError: '',
  implementationFiles: [],
  implementationFilesError: '',
  implementationFunctionsList: [],
  implementationFunctionsListState: 'idle',
  implementationFunctionsListError: '',
  implementationFileDeleteState: 'idle',
  implementationFileDeleteError: '',
};

export const implementationsReducer = (
  state: ImplementationsState = initialStateImplementations,
  action: ImplementationActions,
): ImplementationsState => {
  switch (action.type) {
    case ImplementationActionTypes.REST_IMPLEMENTATIONS:
      return initialStateImplementations;
    case ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        implementationsListState: 'loading',
        implementationsListError: '',
      };
    case ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS_SUCCESS:
      return {
        ...state,
        implementationsListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        implementationsList: action.payload.data,
      };
    case ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS_FAIL:
      return {
        ...state,
        implementationsListState: 'error',
        implementationsListError: action.payload,
      };
    case ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID:
      return {
        ...state,
        implementationByIdState: 'loading',
        implementationByIdError: '',
      };
    case ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID_SUCCESS:
      return {
        ...state,
        implementationByIdState: 'success',
        implementationsList: upsertResource(state.implementationsList, action.payload),
      };
    case ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID_FAIL:
      return {
        ...state,
        implementationByIdState: 'error',
        implementationByIdError: action.payload,
      };

    case ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS:
      return {
        ...state,
        implementationByIdState: 'loading',
        implementationByIdError: '',
      };

    case ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS_SUCCESS:
      return {
        ...state,
        implementationByIdState: 'success',
        implementationByIdError: '',
        implementationsList: [...action.payload],
      };

    case ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS_ERROR:
      return {
        ...state,
        implementationByIdState: 'error',
        implementationByIdError: action.payload,
        implementationsList: [],
      };
    case ImplementationActionTypes.CREATE_IMPLEMENTATION:
      return {
        ...state,
        implementationCreateState: 'loading',
        implementationCreateError: '',
      };
    case ImplementationActionTypes.CREATE_IMPLEMENTATION_SUCCESS:
      return {
        ...state,
        implementationFiles: [],
        implementationCreateState: 'success',
      };
    case ImplementationActionTypes.CREATE_IMPLEMENTATION_FAIL:
      return {
        ...state,
        implementationCreateState: 'error',
        implementationCreateError: action.error.message,
      };
    case ImplementationActionTypes.UPDATE_IMPLEMENTATION:
      return {
        ...state,
        implementationUpdateState: 'loading',
        implementationUpdateError: '',
      };
    case ImplementationActionTypes.UPDATE_IMPLEMENTATION_SUCCESS:
      return {
        ...state,
        implementationUpdateState: 'success',
      };
    case ImplementationActionTypes.UPDATE_IMPLEMENTATION_FAIL:
      return {
        ...state,
        implementationUpdateState: 'error',
        implementationUpdateError: action.error.message,
      };
    case ImplementationActionTypes.DELETE_IMPLEMENTATION:
      return {
        ...state,
        implementationDeleteState: 'loading',
        implementationDeleteError: '',
      };
    case ImplementationActionTypes.DELETE_IMPLEMENTATION_SUCCESS:
      return {
        ...state,
        implementationDeleteState: 'success',
      };
    case ImplementationActionTypes.DELETE_IMPLEMENTATION_FAIL:
      return {
        ...state,
        implementationDeleteState: 'error',
        implementationDeleteError: action.error.message,
      };
    case ImplementationActionTypes.GET_ALL_FUNCTIONS: {
      return {
        ...state,
        implementationFunctionsListState: 'loading',
        implementationFunctionsListError: '',
      };
    }
    case ImplementationActionTypes.GET_ALL_FUNCTIONS_SUCCESS: {
      return {
        ...state,
        implementationFunctionsList: action.payload?.data,
        implementationFunctionsListState: 'success',
      };
    }
    case ImplementationActionTypes.GET_ALL_FUNCTIONS_FAIL: {
      return {
        ...state,
        implementationFunctionsListState: 'error',
        implementationFunctionsListError: action.error.message,
      };
    }
    case ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE: {
      const newFiles = action.payload.map((f) => f.name);
      return {
        ...state,
        implementationFiles: [
          ...state.implementationFiles.filter((f) => !newFiles.includes(f.name)),
          ...action.payload.map((f) => ({
            ...f,
            state: (f.state === 'converted' ? 'converted' : 'loading') as IFileState,
          })),
        ],
      };
    }
    case ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE_SUCCESS:
    case ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE_FAIL: {
      const file = action.payload;

      return {
        ...state,
        implementationFiles: [...state.implementationFiles.filter((f) => f.name !== file.name), file],
      };
    }
    case ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE: {
      return {
        ...state,
        implementationFileDeleteState: 'success',
        implementationFiles: state.implementationFiles.filter((f) => f.name !== action.payload.name),
      };
    }
    case ImplementationActionTypes.IMPLEMENTATIONS_CLEAN_UP: {
      return { ...state, implementationFiles: [] };
    }
    case ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE: {
      return {
        ...state,
        implementationFiles: state.implementationFiles.map((f) =>
          f.state !== 'error' ? { ...f, state: 'converting' } : f,
        ),
      };
    }
    case ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE_SUCCESS:
    case ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE_FAIL: {
      return {
        ...state,
        implementationFiles: action.payload,
      };
    }
    default:
      return state;
  }
};
