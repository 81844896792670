import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { ComponentActionTypes, GetAllComponentsAction } from 'state_management/actions/components/ActionTypes';
import { getAllComponentsAction } from 'state_management/actions/components/componentsActions';

export function* refreshComponents(): Generator<PutEffect<GetAllComponentsAction>> {
  yield put(getAllComponentsAction());
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ComponentActionTypes.CREATE_COMPONENT_SUCCESS,
      ComponentActionTypes.UPDATE_COMPONENT_SUCCESS,
      ComponentActionTypes.CLONE_COMPONENT_SUCCESS,
      ComponentActionTypes.DELETE_COMPONENT_SUCCESS,
    ],
    refreshComponents,
  );
}
