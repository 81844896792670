import { AxiosResponse } from 'axios';
import { IPagination } from 'models/Pagination';

const getLengthWithNext = <T>(res: AxiosResponse<T>): number | undefined => {
  const pageLength = (res.data as unknown as Array<T>)?.length || 0;
  let total =
    (Number(res.headers['x-pagination-current']) || 0) * (Number(res.headers['x-pagination-size']) || 0) + pageLength;
  if (res.headers['x-pagination-nextexists'] === 'True') {
    total += 1;
  }
  return total;
};

export const getPaginationFromResponse = <T extends { length: number }>(
  res: AxiosResponse<T>,
): Omit<IPagination, 'search'> => ({
  page: Number(res.headers['x-pagination-current']) || 0,
  perPage: Number(res.headers['x-pagination-size']) || 10,
  // NOTE: if we don't pass pagination parameters then the response header doesn't have the itemcount
  // For that we need to read the length of the data
  total: Number(res.headers['x-pagination-itemcount']) || getLengthWithNext(res) || 0,
  noCount: !Number(res.headers['x-pagination-itemcount']),
});
