import { CompilationState, ICompilationStates, ProjectsState } from 'state_management/reducers/projects/Modals';
import { RequestState } from 'models/RequestState';

const mapCompilationStatus = (status: CompilationState): RequestState => {
  switch (status) {
    case 'enabled':
      return 'success';
    case 'disabled':
      return 'idle';
    case 'running':
      return 'loading';
    case 'error':
      return 'error';
    default:
      return 'success';
  }
};

export const getProjectCompiledState = (
  compilerStates: ICompilationStates,
  error = '',
): Pick<
  ProjectsState,
  'boardCompileRequest' | 'designCompileRequest' | 'boardCompileRequestError' | 'designCompileRequestError'
> => ({
  boardCompileRequest: mapCompilationStatus(compilerStates.board),
  designCompileRequest: mapCompilationStatus(compilerStates.design),
  boardCompileRequestError: compilerStates.board === 'error' ? error : '',
  designCompileRequestError: compilerStates.design === 'error' ? error : '',
});
