import { apiUri } from 'services/main_app';
import { IListNetsPayload, IListNetsSuccessPayload } from 'state_management/reducers/machineLearning/Modals';
import {
  MachineLearningActionTypes,
  GetListNetsAction,
  GetListNetsSuccessAction,
  GetListNetsErrorAction,
  CleanUpMachineLearningAction,
  GetListSignalsAction,
} from './ActionTypes';

export const getListNets = (payload: IListNetsPayload): GetListNetsAction => ({
  payload,
  type: MachineLearningActionTypes.GET_LIST_NETS,
});

export const getListNetsSuccess = (payload: IListNetsSuccessPayload): GetListNetsSuccessAction => ({
  payload,
  type: MachineLearningActionTypes.GET_LIST_NETS_SUCCESS,
});

export const getListNetsFail = (error: string): GetListNetsErrorAction => ({
  payload: error,
  type: MachineLearningActionTypes.GET_LIST_NETS_FAIL,
});

export const getListSignals = (fileId: string): GetListSignalsAction => ({
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri(`/machine-learning/nets/${fileId}`, 2),
    },
  },
  type: MachineLearningActionTypes.GET_LIST_SIGNALS,
});

export const cleanUpMachineLearning = (): CleanUpMachineLearningAction => ({
  type: MachineLearningActionTypes.MACHINE_LEARNING_CLEAN_UP,
});
