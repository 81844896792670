import { RawUnboxingResponse } from 'state_management/reducers/unboxing/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum UnboxingActionTypes {
  GET_WELCOME_AT = 'GET_WELCOME_AT',
  GET_WELCOME_AT_SUCCESS = 'GET_WELCOME_AT_SUCCESS',
  GET_WELCOME_AT_FAIL = 'GET_WELCOME_AT_FAIL',

  UPDATE_WELCOME_AT = 'UPDATE_WELCOME_AT',
  UPDATE_WELCOME_AT_SUCCESS = 'UPDATE_WELCOME_AT_SUCCESS',
  UPDATE_WELCOME_AT_FAIL = 'UPDATE_WELCOME_AT_FAIL',

  SET_ACCEPTED_CONSENT_IDS = 'SET_ACCEPTED_CONSENT_IDS',
}

// =========================================== WELCOME
export interface GetWelcomeAtAction extends AxiosRequestPayload {
  type: UnboxingActionTypes.GET_WELCOME_AT;
}

export interface GetWelcomeAtSuccessAction
  extends AxiosSuccessPayload<
    Partial<RawUnboxingResponse>,
    UnboxingActionTypes.GET_WELCOME_AT_SUCCESS,
    GetWelcomeAtAction
  > {
  type: UnboxingActionTypes.GET_WELCOME_AT_SUCCESS;
}

export interface GetWelcomeAtErrorAction
  extends AxiosErrorPayload<UnboxingActionTypes.GET_WELCOME_AT_FAIL, GetWelcomeAtAction> {
  type: UnboxingActionTypes.GET_WELCOME_AT_FAIL;
}

export interface UpdateWelcomeAtAction extends AxiosRequestPayload {
  type: UnboxingActionTypes.UPDATE_WELCOME_AT;
}

export interface UpdateWelcomeAtSuccessAction
  extends AxiosSuccessPayload<
    Partial<RawUnboxingResponse>,
    UnboxingActionTypes.UPDATE_WELCOME_AT_SUCCESS,
    UpdateWelcomeAtAction
  > {
  type: UnboxingActionTypes.UPDATE_WELCOME_AT_SUCCESS;
}

export interface UpdateWelcomeAtErrorAction
  extends AxiosErrorPayload<UnboxingActionTypes.UPDATE_WELCOME_AT_FAIL, UpdateWelcomeAtAction> {
  type: UnboxingActionTypes.UPDATE_WELCOME_AT_FAIL;
}

// =========================================== CONSENT
export interface SetAcceptedConsentIdsActionType {
  type: UnboxingActionTypes.SET_ACCEPTED_CONSENT_IDS;
  payload: Array<string>;
}

// ============================================ ALL

export type UnboxingActions =
  | GetWelcomeAtAction
  | GetWelcomeAtSuccessAction
  | GetWelcomeAtErrorAction
  | UpdateWelcomeAtAction
  | UpdateWelcomeAtSuccessAction
  | UpdateWelcomeAtErrorAction
  | SetAcceptedConsentIdsActionType;
