/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

import lodash from 'lodash';
import {
  IAlternative,
  IBlock,
  ICuboBomList,
  IRappidGraph,
  IRappidGraphWithChildren,
  ISubsystemList,
} from 'state_management/reducers/billOfMaterial/Modals';
import { ComponentsReplacement } from 'state_management/reducers/bomModification/Modals';
import { IComponents } from 'state_management/reducers/components/Modals';
import {
  ALTERNATIVE,
  BASIC_BLOCK_NAMESPACE,
  COMPONENT_TYPE,
  CUBO_SLOT_TYPE,
  CUBO_TYPE,
  DESIGN_BLOCK_TYPE,
  IType,
  KEY_SEPARATOR,
  MAIN,
  NUMBER_OF_ALTERNATIVES,
  SUBSYSTEM_TYPE,
} from 'components/HierarchicalTable/constant';

const isAlternative = (condition: boolean): 'Main' | 'Alternative' => (condition ? MAIN : ALTERNATIVE);

const generateKey = (ids: Array<string>): string => ids.join(KEY_SEPARATOR);

// This function formats the rappid canvas graph into a format
// that is presentable in a hierarchical view and adds necessary fields
//
// DO NOT FORGET TO HANDLE FOR WHEN COMPONENT; CUBO & SUBSYSTEM ARE ON THE SAME LEVEL
export const formatHierarchicalBom = (graph: Array<IRappidGraph>): Array<IRappidGraphWithChildren> => {
  const clonedGraph = lodash.clone(graph);
  const designBlocks = clonedGraph
    .filter((designBlock: IRappidGraph) => designBlock?.type === BASIC_BLOCK_NAMESPACE)
    .map((designBlock: IRappidGraph) => ({
      id: designBlock.id,
      key: designBlock.id,
      type: DESIGN_BLOCK_TYPE,
      description: designBlock?.data?.name,
      children: (designBlock?.data?.matches?.alternatives || [])
        .sort((a, _) => (a.id === designBlock?.data?.matches?.selectedId ? -1 : 1))
        .slice(0, NUMBER_OF_ALTERNATIVES)
        .map((alternative: IAlternative) => {
          let _alternative;
          if (alternative?.type === SUBSYSTEM_TYPE) {
            _alternative = {
              id: alternative.id,
              description: alternative.name,
              type: alternative?.type,
              key: generateKey([designBlock.id, alternative.id]),
              alternative: isAlternative(alternative.id === designBlock?.data?.matches?.selectedId),
              children: (alternative?.blocks || []).map((block: IBlock) => ({
                id: block.id,
                description: block?.name || 'Cubo Slot',
                type: CUBO_SLOT_TYPE,
                key: generateKey([designBlock.id, alternative.id, block.id]),
                children: (block?.matches?.alternatives || [])
                  .sort((a, _) => (a.id === block?.matches?.selectedId ? -1 : 1))
                  .slice(0, NUMBER_OF_ALTERNATIVES)
                  .map((cuboSlotAlternative: IAlternative) => ({
                    id: cuboSlotAlternative.id,
                    description: cuboSlotAlternative?.name,
                    type: CUBO_TYPE,
                    key: generateKey([designBlock.id, alternative.id, block.id, cuboSlotAlternative.id]),
                    alternative: isAlternative(
                      alternative.id === designBlock?.data?.matches?.selectedId &&
                        cuboSlotAlternative.id === block?.matches?.selectedId,
                    ),
                    children: [],
                  })),
              })),
            };
          } else if (alternative?.type === CUBO_TYPE) {
            _alternative = {
              id: alternative.id,
              type: CUBO_TYPE,
              description: alternative.name,
              key: generateKey([designBlock.id, alternative.id]),
              alternative: isAlternative(alternative.id === designBlock?.data?.matches?.selectedId),
              children: [],
            };
          } else {
            _alternative = {
              ...alternative,
              type: COMPONENT_TYPE,
              key: generateKey([designBlock.id, alternative.id]),
              alternative: isAlternative(alternative.id === designBlock?.data?.matches?.selectedId),
            };
          }
          return _alternative;
        }),
    }));
  return designBlocks as Array<IRappidGraphWithChildren>;
};

export const getCuboIdsFromHierarchicalBom = (designBlocks: Array<IRappidGraphWithChildren>): Array<string> => {
  const ids = designBlocks.map((designBlock) =>
    designBlock?.children
      ?.filter((alternative: IRappidGraphWithChildren) => alternative?.type !== COMPONENT_TYPE)
      .map((alternative: IRappidGraphWithChildren) => {
        let _alternative;
        if (alternative?.type === SUBSYSTEM_TYPE) {
          _alternative = alternative?.children?.map((block: IRappidGraphWithChildren) =>
            block?.children?.map((cuboSlotAlternative: IRappidGraphWithChildren) => cuboSlotAlternative?.id),
          );
        } else {
          _alternative = alternative?.id;
        }
        return _alternative;
      }),
  );
  return Array.from(new Set(ids.flat(Infinity))) as string[];
};

export const getSubsystemIdsFromHierarchicalBom = (designBlocks: Array<IRappidGraphWithChildren>): Array<string> => {
  const ids = designBlocks.map((designBlock) =>
    designBlock?.children
      .filter((alternative: IRappidGraphWithChildren) => alternative?.type === SUBSYSTEM_TYPE)
      .map((alternative: IRappidGraphWithChildren) => alternative?.id),
  );
  return Array.from(new Set(ids.flat(Infinity))) as string[];
};

export const addCuboSlotsNameToHierarchicalBom = (
  designBlocks: Array<IRappidGraphWithChildren>,
  subsystemList: ISubsystemList,
): Array<IRappidGraphWithChildren> => {
  const clonedDesignBlocks = lodash.cloneDeep(designBlocks);
  return clonedDesignBlocks.map((designBlock) => ({
    ...designBlock,
    children: (designBlock?.children || []).map((subsystemOrCubo) => {
      let _subsystemOrCubo;
      if (subsystemOrCubo?.type === SUBSYSTEM_TYPE) {
        _subsystemOrCubo = {
          ...subsystemOrCubo,
          children: (subsystemOrCubo?.children || []).map((cuboSlot) => ({
            ...cuboSlot,
            ...(subsystemList[subsystemOrCubo.id]
              ? {
                  description: subsystemList[subsystemOrCubo.id]?.canvas?.graph?.find((item) => cuboSlot.id === item.id)
                    ?.data?.name,
                }
              : {}),
          })),
        };
      } else {
        _subsystemOrCubo = subsystemOrCubo;
      }
      return _subsystemOrCubo;
    }),
  })) as Array<IRappidGraphWithChildren>;
};

export const addCuboBomToHierarchicalBom = (
  designBlocks: Array<IRappidGraphWithChildren>,
  cuboBomList: ICuboBomList,
): Array<IRappidGraphWithChildren> => {
  const clonedDesignBlocks = lodash.cloneDeep(designBlocks);
  return clonedDesignBlocks.map((designBlock) => ({
    ...designBlock,
    children: (designBlock?.children || []).map((subsystemOrCubo) => {
      let _subsystemOrCubo;
      if (subsystemOrCubo?.type === SUBSYSTEM_TYPE) {
        _subsystemOrCubo = {
          ...subsystemOrCubo,
          children: (subsystemOrCubo?.children || []).map((cuboSlot) => ({
            ...cuboSlot,
            children: (cuboSlot?.children || []).map((cubo) => ({
              ...cubo,
              ...(cuboBomList[cubo?.id]
                ? {
                    children: (cuboBomList[cubo.id] || [])?.map((component, index) => ({
                      ...component,
                      key: generateKey([cubo.key, String(index)]),
                      type: COMPONENT_TYPE,
                      alternative: cubo?.alternative,
                    })),
                  }
                : {}),
            })),
          })),
        };
      } else if (subsystemOrCubo?.type === CUBO_TYPE) {
        _subsystemOrCubo = {
          ...subsystemOrCubo,
          children: (cuboBomList[subsystemOrCubo?.id] || [])?.map((component, index) => ({
            ...component,
            key: generateKey([subsystemOrCubo?.key, String(index)]),
            type: COMPONENT_TYPE,
            alternative: subsystemOrCubo?.alternative,
          })),
        };
      } else {
        _subsystemOrCubo = subsystemOrCubo;
      }
      return _subsystemOrCubo;
    }),
  })) as Array<IRappidGraphWithChildren>;
};

export const getDesignBlocksByType = (
  designBlocks: Array<IRappidGraphWithChildren>,
  type: IType | undefined = undefined,
): Array<IRappidGraphWithChildren> => {
  const clonedDesignBlocks = lodash.cloneDeep(designBlocks);
  return clonedDesignBlocks
    .map((designBlock) => {
      let _designBlock;
      if (designBlock?.children?.length) {
        _designBlock = [
          ...(!type || designBlock?.type === type ? [designBlock] : []),
          ...getDesignBlocksByType(designBlock?.children, type),
        ];
      } else {
        _designBlock = !type || designBlock?.type === type ? [designBlock] : [];
      }
      return _designBlock;
    })
    .flat(Infinity) as Array<IRappidGraphWithChildren>;
};

export const filterDesignBlocksByFields = (
  item: IRappidGraphWithChildren,
  filters: [keyof IRappidGraphWithChildren, string[]][],
): boolean =>
  filters.every(
    (filter) =>
      filter[1].includes(item?.[filter?.[0]]) || (filter[1]?.includes('') && (item?.[filter?.[0]] || '') === ''),
  );

export const getKeysFromHierarchicalBomByFilters = (
  designBlocks: Array<IRappidGraphWithChildren>,
  filters: [keyof IRappidGraphWithChildren, string[]][],
): Array<string> =>
  designBlocks
    .map((designBlock) => {
      let result;
      if (designBlock?.children?.length) {
        result = [
          ...(filterDesignBlocksByFields(designBlock, filters) ? [designBlock.key] : []),
          ...getKeysFromHierarchicalBomByFilters(designBlock?.children, filters),
        ];
      } else {
        result = [...(filterDesignBlocksByFields(designBlock, filters) ? [designBlock.key] : [])];
      }
      return result.flat(Infinity);
    })
    .flat(Infinity) as Array<string>;

export const getParentKeys = (key: string): Array<string> => {
  const ids = key.split(KEY_SEPARATOR);
  if (ids.length <= 1) {
    return [];
  }
  const keys = ids.reduce(
    (prev, curr) => [...prev, [...(prev[prev.length - 1] ? [prev[prev.length - 1]] : []), curr].join(KEY_SEPARATOR)],
    [] as Array<string>,
  );
  return keys.slice(0, keys.length - 1);
};

export const getHierarchicalBlocksByKeys = (
  designBlocks: Array<IRappidGraphWithChildren>,
  keys: Array<string>,
): Array<IRappidGraphWithChildren> => {
  const clonedDesignBlocks = lodash.cloneDeep(designBlocks);
  return clonedDesignBlocks
    .filter((designBlock) => keys.includes(designBlock.key))
    .map((designBlock) => ({
      ...designBlock,
      children: (designBlock?.children || [])
        .filter((subsystemOrCubo) => keys.includes(subsystemOrCubo.key))
        .map((subsystemOrCubo) => {
          let _subsystemOrCubo;
          if (subsystemOrCubo?.type === SUBSYSTEM_TYPE) {
            _subsystemOrCubo = {
              ...subsystemOrCubo,
              children: (subsystemOrCubo?.children || [])
                .filter((cuboSlot) => keys.includes(cuboSlot.key))
                .map((cuboSlot) => ({
                  ...cuboSlot,
                  children: (cuboSlot?.children || [])
                    .filter((cubo) => keys.includes(cubo.key))
                    .map((cubo) => ({
                      ...cubo,
                      children: (cubo?.children || []).filter((component) => keys.includes(component.key)),
                    })),
                })),
            };
          } else if (subsystemOrCubo?.type === CUBO_TYPE) {
            _subsystemOrCubo = {
              ...subsystemOrCubo,
              children: (subsystemOrCubo?.children || []).filter((component) => keys.includes(component.key)),
            };
          } else {
            _subsystemOrCubo = subsystemOrCubo;
          }
          return _subsystemOrCubo;
        }),
    }));
};

export const applyBomModificationRules = (
  dataSource: IRappidGraphWithChildren[],
  rules: ComponentsReplacement,
  componentsInfo: Array<Partial<IComponents>>,
  manufacturers?: Array<{ id: string; name: string }>,
): IRappidGraphWithChildren[] => {
  // this is a deep clone. for more information you may want to read
  const ds: IRappidGraphWithChildren[] = lodash.cloneDeep(dataSource);
  ds.forEach((i) => {
    rules.forEach((r) => {
      // is the rule active?
      if (r.applied) {
        // is there any rule for the current design block?
        if (i.id === r.designBlockId) {
          const mainCubo = i.children?.filter((f) => f.alternative === MAIN)[0];
          // inside that block, is there any rule for the main cubo?
          if (mainCubo?.id === r.selectedCuboId) {
            const components = mainCubo.children;
            // inside that cubo, is there any component which should be replaced by the rule?
            if (components?.some((c) => c.device === r.originalComponentDevice)) {
              // if there is any replace them
              components.forEach((c) => {
                if (c.device === r.originalComponentDevice) {
                  const componentInfo = componentsInfo.find((ci) => ci.id === r.replacedComponentId);
                  /* clean the object first values first */
                  const componentKey = c.key;
                  Object.keys(c).forEach((k) => ((c as any)[k] = undefined));
                  c.id = componentInfo?.id || '';
                  /* TODO: This is an expected bug. Back-end doesn't return manufacturer data. */
                  c.manufacturer = manufacturers?.find((m) => m.id === componentInfo?.manufacturer)?.name || '';
                  c.description = componentInfo?.description || '';
                  c.device = componentInfo?.partNumber || '';
                  c.type = COMPONENT_TYPE;
                  c.alternative = MAIN;
                  /* Key should reflect the replacement concept */
                  c.key = componentKey;
                }
              });
            }
          }
        }
      }
    });
  });
  return ds;
};
