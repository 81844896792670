import { PutEffect, ForkEffect, CallEffect, put, takeEvery, call } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  SubSystemCanvasActionTypes,
  GetSubSystemCanvasErrorAction,
  GetSubSystemCanvasSuccessAction,
  GetSubSystemCanvasAction,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import {
  ENDPOINT_SUB_SYSTEM_CANVAS,
  getSubSystemCanvasErrorAction,
  getSubSystemCanvasSuccessAction,
} from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';

import { axiosInstance } from 'services/dataService';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';
import { apiUri } from 'services/main_app';
import { ENDPOINT_SPECIFICATIONS } from 'state_management/actions/specifications/specificationsActions';

export function* getSaga(
  action: GetSubSystemCanvasAction,
): Generator<
  CallEffect<AxiosResponse | undefined> | PutEffect<GetSubSystemCanvasSuccessAction | GetSubSystemCanvasErrorAction>
> {
  try {
    const selectedId = action.payload;

    const subsystemResult = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_SUB_SYSTEM_CANVAS}/${selectedId}`, 2)),
    )) as AxiosResponse | undefined;

    const subsysSpecIds =
      (subsystemResult?.data as Partial<RawSubSystemCanvas.Payload>)?.specifications?.map((s) => s.id) || [];

    /* IMPORTANT NOTE: subsystem end point (ENDPOINT_SUB_SYSTEM_CANVAS) returns an object in which specs only has ids 
     Here, we need the spec_type as well, so we need to call the spec end point 
     itself (ENDPOINT_SPECIFICATION) to get the data 
     We could have stuffed the spec completely by the ENDPOINT_SUB_SYSTEM_CANVAS,
     Since, we really need this spec-type data, we have to go for a second call, which is not a good idea!
     So, TODO: LATER, we can see if the ENDPOINT_SUB_SYSTEM_CANVAS should return a complete spec object,
     Then, the second call can be removed
     */
    const detailedSpecResult = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}?query=spec_type="implementation"`, 2)),
    )) as AxiosResponse;

    const rawData = subsystemResult?.data
      ? ({
          ...subsystemResult?.data,
          specifications: [],
        } as Partial<RawSubSystemCanvas.Payload>)
      : undefined;
    if (detailedSpecResult?.data && subsysSpecIds?.length && rawData) {
      (detailedSpecResult.data as Array<Raw.ISpecification>).forEach((s) => {
        subsysSpecIds.forEach((id) => {
          s.id === id && rawData.specifications?.push(s);
        });
      });
    }
    yield put(
      getSubSystemCanvasSuccessAction(serializeSubSystemCanvas(rawData as Partial<RawSubSystemCanvas.Payload>)),
    );
  } catch (error) {
    yield put(
      getSubSystemCanvasErrorAction(getErrorMessage(error as AxiosError) || 'Fetching Sub System Canvas failed.'),
    );
  }
}

export function* getWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS, getSaga);
}
