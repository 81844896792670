import CelusIcon from 'components/CelusIcon';
import { Trans } from 'react-i18next';

import { IProps } from './IProps';

import { Container, Content } from './styles';

const ProjectSaveInfo = ({ canvas }: IProps): JSX.Element => (
  <Container>
    {canvas === 'Design Canvas' ? (
      <Content>
        <Trans i18nKey="supernova:toasts.compileDesignCanvasReady">
          If your design is done, you can click on <CelusIcon size="tiny">generate-button</CelusIcon> button on the top
          bar to resolve your canvas.
        </Trans>
      </Content>
    ) : (
      <Content>
        <Trans i18nKey="supernova:toasts.readyToCompileOtherCanvas">
          If your design is done, you can click on <CelusIcon size="tiny">generate-button</CelusIcon> button on the top
          bar to apply the last changes into your file.
        </Trans>
      </Content>
    )}
  </Container>
);

export const DesignSaveInfo = (): JSX.Element => <ProjectSaveInfo canvas="Design Canvas" />;
export const BoardShapeSaveInfo = (): JSX.Element => <ProjectSaveInfo canvas="Board-Shape Canvas" />;

export default ProjectSaveInfo;
