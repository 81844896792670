import { RequestState } from 'models/RequestState';
import { AppTheme, UserTheme } from 'models/ThemeProps';

export interface Basic {
  email: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
}

export interface Stats {
  createdAt: string;
  lastModified: string;
  lastLogin: string;
  showlogin: string;
}

export interface License {
  licensekey?: string;
  licensetype?: string;
}

export interface Invoice {
  adress1?: string;
  adress2?: string;
  city?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  organization?: string;
  state?: string;
  zipcode?: string;
}

export interface Bankaccount {
  BIC?: string;
  IBAN?: string;
}

export interface Payment {
  bankaccount: Bankaccount;
  creditcardnumber: Array<string>;
}
export interface TFA {
  verified: boolean;
}

export enum Roles {
  // General Roles
  SYSTEM_ROOT = 'System Root',
  ADMIN = 'Admin',
  PUBLIC_USER = 'Public User',
  VIEWER = 'Viewer',
  // Orbit Roles
  LIBRARY_Engineer = 'Library Engineer',
  LIBRARY_MANAGER = 'Library Manager',
  // Supernova Roles
  PROJECT_Engineer = 'Project Engineer',
  PROJECT_MANAGER = 'Project Manager',
  // DEPRECATED ROLES
  ADMIN_OLD = 'admin',
  ENGINEER_OLD = 'engineer',
  VIEWER_OLD = 'viewer',
}

export type AppType = 'CELUS' | 'AVAIL' | 'SUPERNOVA' | 'ORBIT';
export type PlatformType = 'SUPERNOVA' | 'ORBIT';

export interface User {
  _id: string;
  email?: string;
  name: string;
  admin: boolean;
  isAnonymousUser: boolean;
  basic: Basic;
  stats: Stats;
  license: License;
  invoice: Invoice;
  payment: Payment;
  roles: Array<Roles>;
  groups: Array<string>;
  tfa: TFA;
  app_type: Array<AppType>;
  target_hosts: Array<string>;
}

export interface RawPermission {
  rsname: string;
  rsid: string;
  scopes?: Array<string>;
}

export interface RawPermissionResponse {
  scope_permissions: Array<RawPermission>;
}

export interface Permission {
  read: boolean;
}

export interface Languages {
  languages: {
    [key: string]: {
      name: string;
      tName: string;
      flag: string;
      code: string;
    };
  };
}

export interface UserSettings {
  preferredWorkspaces: Array<string> | undefined;
}

export const FEATURES_KEYS = {
  'feature/avnet_bom': 'BOM_API',
  'feature/spec_filter_by_taxonomy': 'TAXONOMY_SPEC_FILTER',
  'feature/block_port_number': 'BLOCK_PORT_NUMBER',
  'feature/default_visual_link': 'DEFAULT_VISUAL_LINK',
  'feature/link_ports_direction': 'LINK_PORTS_DIRECTION',
  'feature/hide_main_dashboard': 'HIDE_MAIN_DASHBOARD',
  'feature/show_logout_button': 'SHOW_LOGOUT',
  'feature/show_design_considerations': 'SHOW_DESIGN_CONSIDERATIONS',
  'feature/buy_cad_files': 'BUY_CAD_FILES',
  'feature/share_with_user': 'SHARE_WITH_USER',
  'feature/project_workspaces': 'PROJECT_WORKSPACES',
  'feature/show_link_between_vcomp_and_comp': 'SHOW_LINK_BETWEEN_VCOMP_AND_COMP',
  'feature/show_workspace_selection': 'SHOW_WORKSPACE_SELECTION',
  'feature/show_spec_value_types': 'SHOW_SPEC_VALUE_TYPES',
  'feature/show_exports': 'SHOW_EXPORTS',
  'feature/show_custom_help_button': 'SHOW_CUSTOM_HELP_BUTTON',
  'feature/show_hierarchical_bom': 'SHOW_HIERARCHICAL_BOM',
  'feature/supernova_show_sharing_and_saving_as_ref_design_options':
    'SUPERNOVA_SHOW_SHARING_AND_SAVING_AS_REF_DESIGN_OPTIONS',
  'feature/supernova_show_optimization_factors_options': 'SUPERNOVA_SHOW_OPTIMIZATION_FACTORS_OPTIONS',
  'feature/supernova_show_pcb_autoroute_option': 'SUPERNOVA_SHOW_PCB_AUTOROUTE_OPTION',
  'feature/supernova_show_drag_and_drop_new_block': 'SUPERNOVA_SHOW_DRAG_AND_DROP_NEW_BLOCK',
  'feature/supernova_show_projects_filtering_options': 'SUPERNOVA_SHOW_PROJECTS_FILTERING_OPTIONS',
  'feature/supernova_show_reference_design_id': 'SUPERNOVA_SHOW_REFERENCE_DESIGN_ID',
  'feature/supernova_show_board_shape_canvas_and_download_layout':
    'SUPERNOVA_SHOW_BOARD_SHAPE_CANVAS_AND_DOWNLOAD_LAYOUT',
  'feature/supernova_show_editable_board_shape_canvas': 'SUPERNOVA_SHOW_EDITABLE_BOARD_SHAPE_CANVAS',
  'feature/use_new_orbit_ui': 'USE_NEW_ORBIT_UI',
  'feature/orbit_use_the_new_proxy_service': 'USE_NEW_PROXY_SERVICE',
  'feature/supernova_show_professional_upgrade_cta': 'SUPERNOVA_SHOW_PROFESSIONAL_UPGRADE_CTA',
  'feature/orbit_show_pcb_specifications': 'ORBIT_SHOW_PCB_SPECIFICATIONS',
  'feature/show_welcome_dialog': 'SHOW_WELCOME_DIALOG',
  'feature/supernova_show_bom_modification': 'SUPERNOVA_SHOW_BOM_MODIFICATION',
  'feature/admin_plugin_show_admin': 'ADMIN_PLUGIN_SHOW_ADMIN',
  'feature/orbit_show_orbit': 'ORBIT_SHOW_ORBIT',
  'feature/supernova_show_supernova': 'SUPERNOVA_SHOW_SUPERNOVA',
  'feature/supernova_show_system_design_tool_bar': 'SUPERNOVA_SHOW_SYSTEM_DESIGN_TOOL_BAR',
  'feature/supernova_show_cad_tool_eagle': 'SUPERNOVA_SHOW_CAD_TOOL_EAGLE',
  'feature/supernova_show_cad_tool_altium': 'SUPERNOVA_SHOW_CAD_TOOL_ALTIUM',
  'feature/supernova_show_cad_tool_cadstar': 'SUPERNOVA_SHOW_CAD_TOOL_CADSTAR',
  'feature/supernova_show_bom_type_flat': 'SUPERNOVA_SHOW_BOM_TYPE_FLAT',
  'feature/anonymous_user': 'ANONYMOUS_USER',
  'feature/orbit_use_ports_v_2': 'ORBIT_USE_PORTS_V2',
  'feature/hide_profile_settings': 'HIDE_PROFILE_SETTINGS',
} as const;

export type FeaturePermissions = { [key in typeof FEATURES_KEYS[keyof typeof FEATURES_KEYS]]: Permission };

export interface AuthState {
  authIssuer: string;
  pendingResponse: boolean;
  isLoggedIn: boolean;
  loggedOut: boolean;
  userInfo: User;
  currentAppType: AppType;
  theme: AppTheme;
  featurePermissions: FeaturePermissions;
  translations: Array<{
    flag: string;
    label: string;
    namespace: string;
  }>;
  userSettings: UserSettings;
  getActiveThemeRequestState: RequestState;
  getActiveThemeRequestError: string;
  createThemeRequestState: RequestState;
  createThemeRequestError: string;
  activeTheme: UserTheme;
}
