import { ShortcutMap } from '../Modals';

const getModifiers = (shortcut: ShortcutMap): string => {
  const MASTER_KEY = navigator.platform.toLowerCase().includes('mac') ? '⌘' : 'ctrl';

  return `${shortcut.ctrl ? `${MASTER_KEY}+` : ''}${shortcut.shift ? 'shift+' : ''}${shortcut.alt ? 'alt+' : ''}`;
};

export const createShortcutKeyString = (shortcut: ShortcutMap): string =>
  `${getModifiers(shortcut)}${
    shortcut.key ||
    shortcut.keys?.reduce(
      (a, k, index) => `${a + getModifiers(k) + k.key}${index !== (shortcut.keys?.length || 0) - 1 ? ' or ' : ''}`,
      '' as string,
    )
  }`;
