import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';

import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeProject } from 'utils/projectSerializer';
import { combineSearchQueries, getSearchQuery, TypeSearch } from 'utils/searchHelper';
import { escapeSearch } from 'utils/urlUtils';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import {
  DS_PROJECTS_ENDPOINT,
  getAllProjectsErrorAction,
  getAllProjectsSuccessAction,
} from 'state_management/actions/projects/projectsActions';
import { Project } from 'state_management/reducers/projects/Modals';
import {
  ProjectsActionTypes,
  ProjectsGetAllAction,
  ProjectsGetAllErrorAction,
  ProjectsGetAllSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import { getProjectType } from 'utils/project';
import { getLocalProjects } from 'services/localProjectsService';
import { createQueryStr } from './utils/projectQueryString';

export function* getAllSaga(
  action: ProjectsGetAllAction,
): Generator<
  SelectEffect | CallEffect<AxiosResponse> | PutEffect<ProjectsGetAllSuccessAction | ProjectsGetAllErrorAction>
> {
  try {
    const {
      projectsState: { pagination, viewOptions },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;
    const { _id: userId, isAnonymousUser } = userInfo;

    if (isAnonymousUser) {
      const projects = getLocalProjects();
      yield put(getAllProjectsSuccessAction({ page: 0, perPage: 10, total: projects.length, search: '' }, projects));
    } else {
      let { page, perPage, search, advancedSearchQuery } = pagination;
      if (action.type === ProjectsActionTypes.PROJECTS_GET_ALL) {
        ({
          page = page,
          perPage = perPage,
          search = search || '',
          advancedSearchQuery = advancedSearchQuery,
        } = action.payload);
      } else {
        page = 0;
      }

      const projectsSearchSchema: TypeSearch = {
        title: 'value',
        description: 'value',
      };

      const sQuery = combineSearchQueries(
        advancedSearchQuery || '',
        getSearchQuery(escapeSearch(search), projectsSearchSchema),
        true,
      );

      const { query, orderBy } = createQueryStr(userId, viewOptions.sharingFilter, viewOptions.sortType);
      const searchQuery = combineSearchQueries(
        query,
        sQuery,
        !advancedSearchQuery && !search,
        userId,
        userSettings?.preferredWorkspaces,
      );

      const res = (yield call(() =>
        axiosInstance.get(apiUri(`${DS_PROJECTS_ENDPOINT}?pg=${page}&pg_len=${perPage}&${searchQuery}&${orderBy}`, 2)),
      )) as AxiosResponse;
      const data = res.data.map((p: Raw.Project): Project => {
        const project = serializeProject(p);

        return {
          ...project,
          projectType: getProjectType(userId, project.userId, project.shareType),
        };
      });

      yield put(getAllProjectsSuccessAction({ ...getPaginationFromResponse(res), search: search || '' }, data));
    }
  } catch (error) {
    yield put(getAllProjectsErrorAction(getErrorMessage(error as AxiosError) || 'Fetching projects failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ProjectsActionTypes.PROJECTS_GET_ALL,
      ProjectsActionTypes.PROJECTS_UPDATE_SHARING_FILTER,
      ProjectsActionTypes.PROJECTS_UPDATE_SORTING_TYPE,
    ],
    getAllSaga,
  );
}
