export class InvalidPrefix extends Error {
  /**
   * Customm error when a invalid prefix is used
   * @param message message to be used in the error
   */
  constructor(message?: string) {
    super(message);
    this.name = 'InvalidPrefix';
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
