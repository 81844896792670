import { AxiosErrorPayload, AxiosRequestPayload, AxiosSuccessPayload } from 'state_management/middlewares/axios/Modals';
import { IListNetsSuccessPayload, IListNetsPayload } from 'state_management/reducers/machineLearning/Modals';

export enum MachineLearningActionTypes {
  GET_LIST_NETS = 'GET_LIST_NETS',
  GET_LIST_NETS_SUCCESS = 'GET_LIST_NETS_SUCCESS',
  GET_LIST_NETS_FAIL = 'GET_LIST_NETS_FAIL',
  GET_LIST_SIGNALS = 'GET_LIST_SIGNALS',
  GET_LIST_SIGNALS_SUCCESS = 'GET_LIST_SIGNALS_SUCCESS',
  GET_LIST_SIGNALS_FAIL = 'GET_LIST_SIGNALS_FAIL',

  MACHINE_LEARNING_CLEAN_UP = 'MACHINE_LEARNING_CLEAN_UP',
}

// ============================================ GET_LIST_NETS

export interface GetListNetsAction {
  payload: IListNetsPayload;
  type: MachineLearningActionTypes.GET_LIST_NETS;
}

// ============================================ GET_LIST_NETS_SUCCESS

export interface GetListNetsSuccessAction {
  payload: IListNetsSuccessPayload;
  type: MachineLearningActionTypes.GET_LIST_NETS_SUCCESS;
}

// ============================================ GET_LIST_NETS_FAIL

export interface GetListNetsErrorAction {
  payload: string;
  type: MachineLearningActionTypes.GET_LIST_NETS_FAIL;
}

// ============================================ GET_LIST_SIGNALS

export interface GetListSignalsAction extends AxiosRequestPayload<string> {
  type: MachineLearningActionTypes.GET_LIST_SIGNALS;
}

// ============================================ GET_LIST_SIGNALS_SUCCESS

export interface GetListSignalsSuccessAction
  extends AxiosSuccessPayload<
    Pick<Raw.PredictedInterfacesResponse, 'list_nets'>,
    MachineLearningActionTypes.GET_LIST_SIGNALS,
    GetListSignalsAction
  > {
  type: MachineLearningActionTypes.GET_LIST_SIGNALS_SUCCESS;
}

// ============================================ GET_LIST_SIGNALS_FAIL

export interface GetListSignalsErrorAction
  extends AxiosErrorPayload<MachineLearningActionTypes.GET_LIST_SIGNALS_FAIL, GetListSignalsAction> {
  type: MachineLearningActionTypes.GET_LIST_SIGNALS_FAIL;
}

// ============================================ IMPLEMENTATIONS_CLEAR_ALL

export interface CleanUpMachineLearningAction {
  type: MachineLearningActionTypes.MACHINE_LEARNING_CLEAN_UP;
}

export type MachineLearningActions =
  | GetListNetsAction
  | GetListNetsSuccessAction
  | GetListNetsErrorAction
  | GetListSignalsAction
  | GetListSignalsSuccessAction
  | GetListSignalsErrorAction
  | CleanUpMachineLearningAction;
