import { mergeResourcesExclusive, upsertResource } from 'utils/resourceUtils';
import { DesignConsiderationsState } from './Modals';
import {
  DesignConsiderationActions,
  DesignConsiderationsActionTypes,
} from '../../actions/designConsiderations/ActionTypes';

export const initialStateDesignConsiderations: DesignConsiderationsState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  designConsiderationsList: [],
  designConsiderationsListState: 'idle',
  designConsiderationsListError: '',
  designConsiderationCreateState: 'idle',
  designConsiderationCreateError: '',
  designConsiderationUpdateState: 'idle',
  designConsiderationUpdateError: '',
  designConsiderationDeleteState: 'idle',
  designConsiderationDeleteError: '',
  designConsiderationByIdState: 'idle',
  designConsiderationByIdStateError: '',
};

export const designConsiderationsReducer = (
  state: DesignConsiderationsState = initialStateDesignConsiderations,
  action: DesignConsiderationActions,
): DesignConsiderationsState => {
  switch (action.type) {
    case DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        designConsiderationsListState: 'loading',
      };
    case DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS_SUCCESS:
      return {
        ...state,
        designConsiderationsListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        designConsiderationsList: action.payload.data,
      };
    case DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS_FAIL:
      return {
        ...state,
        designConsiderationsListState: 'error',
        designConsiderationsListError: action.payload,
      };
    case DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION:
      return {
        ...state,
        designConsiderationCreateState: 'loading',
      };
    case DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_SUCCESS:
      return {
        ...state,
        designConsiderationCreateState: 'success',
      };
    case DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_FAIL:
      return {
        ...state,
        designConsiderationCreateState: 'error',
        designConsiderationCreateError: action.error.message,
      };
    case DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION:
      return {
        ...state,
        designConsiderationUpdateState: 'loading',
      };
    case DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_SUCCESS:
      return {
        ...state,
        designConsiderationUpdateState: 'success',
      };
    case DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_FAIL:
      return {
        ...state,
        designConsiderationUpdateState: 'error',
        designConsiderationUpdateError: action.error.message,
      };
    case DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION:
      return {
        ...state,
        designConsiderationDeleteState: 'loading',
      };
    case DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_SUCCESS:
      return {
        ...state,
        designConsiderationDeleteState: 'success',
      };
    case DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_FAIL:
      return {
        ...state,
        designConsiderationDeleteState: 'error',
        designConsiderationDeleteError: action.error.message,
      };
    case DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID:
      return {
        ...state,
        designConsiderationByIdState: 'loading',
      };
    case DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS:
      return {
        ...state,
        designConsiderationByIdState: 'success',
        designConsiderationsList: upsertResource(state.designConsiderationsList, action.payload),
      };
    case DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_FAIL:
      return {
        ...state,
        designConsiderationByIdState: 'error',
        designConsiderationByIdStateError: action.payload,
      };

    case DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_EVERY:
      return {
        ...state,
        designConsiderationsListState: 'loading',
      };
    case DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS_EVERY:
      return {
        ...state,
        designConsiderationsListState: 'success',
        designConsiderationsList: mergeResourcesExclusive(state.designConsiderationsList, action.payload),
      };
    case DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_FAIL_EVERY:
      return {
        ...state,
        designConsiderationsListState: 'error',
        designConsiderationByIdStateError: action.payload,
      };
    case DesignConsiderationsActionTypes.CLEAN_DESIGN_CONSIDERATION_LIST:
      return {
        ...state,
        designConsiderationsListState: 'idle',
        designConsiderationsList: [],
      };

    default:
      return state;
  }
};
