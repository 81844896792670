import { IPagination } from 'models/Pagination';
import { IChangesRequest } from 'state_management/reducers/changesRequest/Modals';
import { IChangeLog } from 'models/ChangeLog';

export enum ChangeLogActionTypes {
  GET_ALL_CHANGE_LOG = 'GET_ALL_CHANGE_LOG',
  GET_ALL_CHANGE_LOG_SUCCESS = 'GET_ALL_CHANGE_LOG_SUCCESS',
  GET_ALL_CHANGE_LOG_FAIL = 'GET_ALL_CHANGE_LOG_FAIL',

  GET_CHANGE_LOG_BY_ID = 'GET_CHANGE_LOG_BY_ID',
  GET_CHANGE_LOG_BY_ID_SUCCESS = 'GET_CHANGE_LOG_BY_ID_SUCCESS',
  GET_CHANGE_LOG_BY_ID_FAIL = 'GET_CHANGE_LOG_BY_ID_FAIL',
}

// ============================================ GET_ALL_CHANGE_LOG

export interface GetAllChangeLogAction {
  payload: Partial<IPagination> & IChangeLog;
  type: ChangeLogActionTypes.GET_ALL_CHANGE_LOG;
}

export interface GetAllChangeLogSuccessAction {
  payload: { pagination: IPagination; data: Array<IChangesRequest> };
  type: ChangeLogActionTypes.GET_ALL_CHANGE_LOG_SUCCESS;
}

export interface GetAllChangeLogErrorAction {
  payload: string;
  type: ChangeLogActionTypes.GET_ALL_CHANGE_LOG_FAIL;
}

// ============================================ GET_CHANGE_LOG_BY_ID

export interface GetChangeLogByIdAction {
  payload: Partial<IChangesRequest>;
  type: ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID;
}

export interface GetChangeLogByIdSuccessAction {
  payload: IChangesRequest;
  type: ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID_SUCCESS;
}

export interface GetChangeLogByIdErrorAction {
  payload: string;
  type: ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID_FAIL;
}
export type ChangeLogActions =
  | GetAllChangeLogAction
  | GetAllChangeLogSuccessAction
  | GetAllChangeLogErrorAction
  | GetChangeLogByIdAction
  | GetChangeLogByIdSuccessAction
  | GetChangeLogByIdErrorAction;
