/* eslint-disable no-useless-escape */

import { OPERATORS } from 'components/AdvancedSearch/AdvancedSearchForm/AdvancedSearchForm';
import { getOperatorDisplayLabelLegacy } from 'components/AdvancedSearch/AdvancedSearchForm/utils/getChipLabel';
import {
  MinMaxSearchValue,
  MultipleValuesSearchValue,
  OperationData,
  SearchFieldsOptions,
  SearchValue,
  SimpleSearchValue,
  TypToleranceSearchValue,
} from 'components/AdvancedSearch/Modals';
import { ISpecificationFilter, ISpecValues } from 'state_management/reducers/specifications/Modals';
import { ISubSystem } from 'state_management/reducers/subSystemCanvas/Modals';
import { serializeSpecification } from 'utils/specificationsSerializer';
import { AdvancedSearchFilterType } from 'views/BlockLibrary/BlockLibraryList/Modals';
import { deserializeRappidDesignCanvasBlock, serializeRappidDesignCanvasBlock } from './systemDesignCanvasSerializer';

export const convertSpecificationFilterToInstanceSpecification = (
  specFilter: ISpecificationFilter,
): Raw.InstanceSpecification => {
  let convertedObj: Partial<Raw.InstanceSpecification> = {};
  if (specFilter?.field?.inputType === 'single') {
    if (specFilter?.operator?.value?.toLowerCase() === 'between') {
      /* NOTE: From subsystem canvas point of view Min and Max always have the same unit */
      const values = specFilter.values as SearchValue;
      const userValues = specFilter?.userValues as SearchValue;
      convertedObj = {
        id: specFilter.field.id as string,
        unit: values?.unit || values?.maxUnit || '',
        user_unit: userValues?.unit || userValues?.maxUnit || '',
        value: {
          max: values?.max as number,
          min: values?.value as number,
        },
        user_value: {
          max: userValues?.max as number,
          min: userValues?.value as number,
        },
      };
    }

    const values = specFilter.values as SearchValue;
    const userValues = specFilter?.userValues as SearchValue;
    convertedObj = {
      id: specFilter?.field?.id as string,
      unit: values?.unit || values?.maxUnit || '',
      user_unit: userValues?.unit || userValues?.maxUnit || '',
      value: values?.value as number,
      user_value: userValues?.value as number,
    };
  } else if (['min_max', 'min_typ_max'].includes(specFilter?.field?.inputType)) {
    /* NOTE: This scenario only accepts Equal operator */
    const userValues = (specFilter.userValues || {}) as MinMaxSearchValue;
    const minUserValueData = ((userValues?.min || {}) as OperationData)?.data as SimpleSearchValue;
    const maxUserValueData = ((userValues?.max || {}) as OperationData)?.data as SimpleSearchValue;
    const typUserValueData = ((userValues?.typ || {}) as OperationData)?.data as SimpleSearchValue;

    const values = (specFilter.values || {}) as MinMaxSearchValue;
    const minValueData = ((values?.min || {}) as OperationData)?.data as SimpleSearchValue;
    const maxValueData = ((values?.max || {}) as OperationData)?.data as SimpleSearchValue;
    const typValueData = ((values?.typ || {}) as OperationData)?.data as SimpleSearchValue;

    convertedObj = {
      id: specFilter?.field?.id as string,
      unit: minValueData?.unit || maxValueData?.unit || typValueData?.unit,
      user_unit: minUserValueData?.unit || maxUserValueData?.unit || typUserValueData?.unit,
      value: {
        min: minValueData?.value,
        max: maxValueData?.value,
        typ: typValueData?.value,
      },
      user_value: {
        min: minUserValueData?.value,
        max: maxUserValueData?.value,
        typ: typUserValueData?.value,
      },
    };
  } else if (['tolerance', 'tolerance_percentage'].includes(specFilter?.field?.inputType)) {
    /* NOTE: This scenario only accepts Equal operator */
    const userValues = (specFilter.userValues || {}) as TypToleranceSearchValue;
    const typUserValueData = ((userValues?.typ || {}) as OperationData)?.data as SimpleSearchValue;
    const toleranceMinUserValueData = ((userValues?.toleranceMin || {}) as OperationData)?.data as SimpleSearchValue;
    const toleranceMaxUserValueData = ((userValues?.toleranceMax || {}) as OperationData)?.data as SimpleSearchValue;

    const values = (specFilter.values || {}) as TypToleranceSearchValue;
    const typValueData = ((values?.typ || {}) as OperationData)?.data as SimpleSearchValue;
    const toleranceMinValueData = ((values?.toleranceMin || {}) as OperationData)?.data as SimpleSearchValue;
    const toleranceMaxValueData = ((values?.toleranceMax || {}) as OperationData)?.data as SimpleSearchValue;

    convertedObj = {
      id: specFilter?.field?.id as string,
      unit: typValueData?.unit || toleranceMinValueData?.unit || toleranceMaxValueData?.unit,
      user_unit: typUserValueData?.unit || toleranceMinUserValueData?.unit || toleranceMaxUserValueData?.unit,
      value: {
        typ: typValueData?.value,
        min: toleranceMinValueData?.value,
        max: toleranceMaxValueData?.value,
      },
      user_value: {
        typ: typUserValueData?.value,
        min: toleranceMinUserValueData?.value,
        max: toleranceMaxUserValueData?.value,
      },
    };
  } else if (specFilter?.field?.inputType === 'list' && specFilter?.values) {
    const { values, userValues } = (specFilter as AdvancedSearchFilterType) || {};
    convertedObj = {
      id: specFilter?.field?.id as string,
      value: (values as MultipleValuesSearchValue)?.value,
      user_value: (userValues as MultipleValuesSearchValue)?.value,
      user_unit: (userValues as MultipleValuesSearchValue)?.unit,
      unit: (values as MultipleValuesSearchValue)?.unit,
    };
  }
  return convertedObj as Raw.InstanceSpecification;
};

export const deSerializeSubSystemCanvas = (payload: Partial<ISubSystem>): Partial<RawSubSystemCanvas.Payload> => ({
  id: payload.id,
  name: payload.name,
  description: payload.description,
  info: payload.info || '',
  icon: payload.icon || 'mcu',
  functions: payload.functions,
  taxonomy: payload.taxonomy,
  specifications: payload.specificationFilters?.map(convertSpecificationFilterToInstanceSpecification) || [],
  instance_specifications: payload.specificationFilters?.map(convertSpecificationFilterToInstanceSpecification) || [],
  canvas: {
    graph: payload.canvas?.graph.map(deserializeRappidDesignCanvasBlock) || [],
  },
});

export const generateSpecificationFilterFromSpecification = (
  specification: Raw.InstanceSpecification | Raw.ISpecification,
): ISpecificationFilter => {
  const inputType = (specification as Raw.ISpecification)?.specification_value_type;
  const field: SearchFieldsOptions = {
    id: specification?.id,
    label: undefined as never,
    value: `instance_specifications-match-{\"id\":\"${specification.id}\",\"value\": VALUE_QUERY_PLACEHOLDER}`,
    inputType,
  };
  let values = {};
  let userValues = {};
  const specValues = (specification as Raw.InstanceSpecification)?.value as ISpecValues;
  const equalOperator = OPERATORS['='];
  /* Remember In JS 0 is also false, so for numeric fields check if it they are not undefined */
  if (['tolerance', 'tolerance_percentage'].includes(inputType)) {
    values = {
      typ: specValues.typ
        ? {
            operator: equalOperator,
            data: {
              value: specValues.typ,
              unit: specification?.unit,
            },
          }
        : undefined,
      toleranceMin:
        specValues?.min !== undefined
          ? {
              operator: equalOperator,
              data: {
                value: specValues?.min,
                unit: specification?.unit,
              },
            }
          : undefined,
      toleranceMax:
        specValues?.max !== undefined
          ? {
              operator: equalOperator,
              data: {
                value: specValues?.max,
                unit: specification?.unit,
              },
            }
          : undefined,
    } as TypToleranceSearchValue;
    const _user_values = (specification as Raw.InstanceSpecification)?.user_value as ISpecValues;
    const _user_unit = (specification as Raw.InstanceSpecification)?.user_unit as string;
    userValues = {
      typ: _user_values?.typ
        ? {
            operator: equalOperator,
            data: { value: _user_values?.typ, unit: _user_unit } as SimpleSearchValue,
          }
        : undefined,
      toleranceMin: _user_values?.min
        ? {
            operator: equalOperator,
            data: { value: _user_values?.min, unit: _user_unit } as SimpleSearchValue,
          }
        : undefined,
      toleranceMax: _user_values?.max
        ? {
            operator: equalOperator,
            data: { value: _user_values?.max, unit: _user_unit } as SimpleSearchValue,
          }
        : undefined,
    } as TypToleranceSearchValue;
  } else if (['min_max', 'min_typ_max'].includes(inputType)) {
    const _values: any = {};

    ['min', 'max', 'typ'].forEach((part) => {
      const _specValues: any = specValues;
      _values[part] = _specValues[part]
        ? {
            operator: equalOperator,
            data: {
              value: _specValues[part],
              unit: specification?.unit,
            },
          }
        : undefined;
    });
    values = { ..._values };
    const _user_values = (specification as Raw.InstanceSpecification)?.user_value as ISpecValues;
    const _user_unit = (specification as Raw.InstanceSpecification)?.user_unit as string;
    userValues = {
      min: _user_values?.min
        ? {
            operator: equalOperator,
            data: { value: _user_values?.min, unit: _user_unit } as SimpleSearchValue,
          }
        : values['min' as keyof typeof values],
      max: _user_values?.max
        ? {
            operator: equalOperator,
            data: { value: _user_values?.max, unit: _user_unit } as SimpleSearchValue,
          }
        : values['max' as keyof typeof values],
      typ: _user_values?.typ
        ? {
            operator: equalOperator,
            data: { value: _user_values?.typ, unit: _user_unit } as SimpleSearchValue,
          }
        : values['typ' as keyof typeof values],
    } as MinMaxSearchValue;
  } else {
    values = {
      value: specification.value,
      unit: specification.unit,
    };
    const userValue = (specification as Raw.InstanceSpecification)?.user_value;
    const userUnit = (specification as Raw.InstanceSpecification)?.user_unit;
    /* Should always support old in which there is no userValue as well */
    userValues = {
      value: userValue !== undefined ? userValue : values['value' as keyof typeof values],
      unit: userUnit !== undefined ? userUnit : values['unit' as keyof typeof values],
    };
  }
  const convertedData = {
    id: specification?.id,
    label: undefined,
    field,
    operator: {
      // NOTE: Making = as default here, but on system design canvas this can be updated to `is` depending the datatype
      ...OPERATORS[inputType !== 'list' ? '=' : '-contains-all-'],
      chipDisplayLabel: getOperatorDisplayLabelLegacy(
        (specification as Raw.InstanceSpecification).user_value ? userValues : values,
        field,
        inputType !== 'list' ? '=' : 'includes',
      ),
    },
    values,
    userValues,
  };
  return convertedData;
};

export const serializeSubSystemCanvas = (payload: Partial<RawSubSystemCanvas.Payload>): ISubSystem => ({
  id: payload.id || '',
  name: payload.name || '',
  description: payload.description || '',
  info: payload.info || '',
  icon: payload.icon || 'mcu',
  createdAt: payload.created_at || '',
  modifiedAt: payload.modified_at || '',
  functions: payload.functions || [],
  taxonomy: payload.taxonomy,
  origin: 'subsystem',
  categories: [],
  specifications: (payload.specifications as Array<Raw.ISpecification> | undefined)?.map(serializeSpecification) || [],
  specificationFilters: (function (): AdvancedSearchFilterType[] {
    let result: AdvancedSearchFilterType[] = [];
    if (payload.instance_specifications?.length) {
      const specMap = payload.instance_specifications.reduce((p, c) => ({ ...p, [c.id]: c }), {});
      if (payload.specifications?.length) {
        payload.specifications.forEach((s) => {
          const instanceSpec = specMap[s?.id as keyof typeof specMap] as Raw.InstanceSpecification;
          if (instanceSpec)
            /* NOTE: specification_value_type must be dynamically added to the object */
            Object.defineProperty(instanceSpec, 'specification_value_type', {
              value: s.specification_value_type,
              configurable: true,
            });
        });
      }
      result = payload?.instance_specifications?.map((spec) =>
        generateSpecificationFilterFromSpecification(spec as Raw.InstanceSpecification),
      );
    } else {
      result = (payload?.specifications || []).map((spec) =>
        generateSpecificationFilterFromSpecification(spec as Raw.ISpecification),
      );
    }
    return result;
  })(),
  canvas: {
    graph: payload.canvas?.graph?.map((b) => serializeRappidDesignCanvasBlock(b)) || [],
  },
});
