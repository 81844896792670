import { PutEffect, ForkEffect, CallEffect, put, call, SelectEffect, select, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import {
  getChangesRequestByIdError,
  getChangesRequestByIdSuccess,
  ENDPOINT_REQUESTS,
} from 'state_management/actions/changesRequest/changesRequestActions';
import { Implementation } from 'state_management/reducers/implementations/Modals';
import { SpecificationsState } from 'state_management/reducers/specifications/Modals';
import { ENDPOINT_IMPLEMENTATIONS } from 'state_management/actions/implementations/implementationsActions';
import { ENDPOINT_DESIGN_BLOCKS } from 'state_management/actions/designBlocks/designBlocksActions';
import { ENDPOINT_INTERFACES } from 'state_management/actions/interfaces/interfacesActions';
import { ENDPOINT_SPECIFICATIONS } from 'state_management/actions/specifications/specificationsActions';
import { ENDPOINT_COMPONENTS } from 'state_management/actions/components/componentsActions';
import { ENDPOINT_PROJECTS } from 'state_management/actions/projects/projectsActions';
import {
  GetChangesRequestByIdAction,
  GetChangesRequestByIdErrorAction,
  GetChangesRequestByIdSuccessAction,
  RequestsActionTypes,
} from 'state_management/actions/changesRequest/ActionTypes';
import { serializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';
import { ENDPOINT_SUB_SYSTEM_CANVAS } from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';
import { ShareGroup, ShareType, ShareUser } from 'state_management/reducers/sharing/Modals';
import {
  ApprovalsType,
  ChangeRequestResourceNameType,
  IChangesRequest,
} from 'state_management/reducers/changesRequest/Modals';
import { Roles, User } from 'state_management/reducers/auth/Modals';
import { ChangeLogActionTypes, GetChangeLogByIdAction } from 'state_management/actions/changeLog/ActionTypes';
import { getChangeLogByIdError, getChangeLogByIdSuccess } from 'state_management/actions/changeLog/changeLogActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';

import { serializeInterface } from 'utils/interfaceSerializer';
import { serializeDesignBlock } from 'utils/designBlocksSerializer';
import { serializeImplementation } from 'utils/implementationSerializer';
import { serializeSpecification } from 'utils/specificationsSerializer';
import { serializeSharing } from 'utils/sharingSerializer';
import { serializeChangesRequests } from 'utils/changesRequestSerializer';
import { serializeProject } from 'utils/projectSerializer';
import { serializeComponent } from 'utils/componentsSerializer';
import { getErrorMessage } from 'utils/getErrorMessage';
import { IFileUpload } from 'models/IFileUpload';
import { serializeCategories } from 'utils/categoriesSerializer';
import { ENDPOINT_CATEGORIES, getAllCategoriesAction } from 'state_management/actions/categories/categoriesActions';
import { serializeVirtualComponent } from 'utils/virtualComponentsSerializer';
import { ENDPOINT_VIRTUAL_COMPONENTS } from 'state_management/actions/virtualComponents/virtualComponentsActions';
import { IVirtualComponent } from 'state_management/reducers/virtualComponents/Modals';
import { serializeSupplier } from 'utils/suppliersSerializer';
import { ENDPOINT_SUPPLIERS } from 'state_management/actions/suppliers/suppliersActions';
import { serializeManufacturer } from 'utils/manufacturersSerializer';
import { ENDPOINT_MANUFACTURERS } from 'state_management/actions/manufacturers/manufacturersActions';
import { serializeDesignConsideration } from 'utils/designConsiderationsSerializer';
import { ENDPOINT_DESIGN_CONSIDERATIONS } from 'state_management/actions/designConsiderations/designConsiderationsActions';
import { GetAllCategoriesAction } from 'state_management/actions/categories/ActionTypes';
import { BLOCKS_LIBRARY_ROUTES } from 'routes/getRoutes';
import { AppState } from 'state_management/AppState';

import { serializeExportJob } from 'utils/exportJobsSerializer';
import { serializeExportTemplate } from 'utils/exportTemplatesSerializer';
import { ENDPOINT_EXPORT_TEMPLATES } from 'state_management/actions/exportTemplates/exportTemplatesActions';
import { ENDPOINT_EXPORT_JOBS } from 'state_management/actions/exportJobs/exportJobsActions';
import { getImplementationFilesSaga } from '../implementations/getByIdSaga';
import { getComponentFilesSaga } from '../components/getByIdSaga';
import { getVirtualComponentFilesSaga } from '../virtualComponents/getByIdSaga';
import { translateIdsToEmail } from '../sharing/utils/emailsTranslation';

export const SERIALIZERS: Record<ChangeRequestResourceNameType, { serializer: any; endpoint: string }> = {
  [BLOCKS_LIBRARY_ROUTES.designBlocks.backendResourceName]: {
    serializer: serializeDesignBlock,
    endpoint: ENDPOINT_DESIGN_BLOCKS,
  },
  [BLOCKS_LIBRARY_ROUTES.implementations.backendResourceName]: {
    serializer: serializeImplementation,
    endpoint: ENDPOINT_IMPLEMENTATIONS,
  },
  [BLOCKS_LIBRARY_ROUTES.interfaces.backendResourceName]: {
    serializer: serializeInterface,
    endpoint: ENDPOINT_INTERFACES,
  },
  [BLOCKS_LIBRARY_ROUTES.specifications.backendResourceName]: {
    serializer: serializeSpecification,
    endpoint: ENDPOINT_SPECIFICATIONS,
  },
  [BLOCKS_LIBRARY_ROUTES.components.backendResourceName]: {
    serializer: serializeComponent,
    endpoint: ENDPOINT_COMPONENTS,
  },
  [BLOCKS_LIBRARY_ROUTES.referenceDesigns.backendResourceName]: {
    serializer: serializeProject,
    endpoint: ENDPOINT_PROJECTS,
  },
  [BLOCKS_LIBRARY_ROUTES.subSystems.backendResourceName]: {
    serializer: serializeSubSystemCanvas,
    endpoint: ENDPOINT_SUB_SYSTEM_CANVAS,
  },
  [BLOCKS_LIBRARY_ROUTES.categories.backendResourceName]: {
    serializer: serializeCategories,
    endpoint: ENDPOINT_CATEGORIES,
  },
  [BLOCKS_LIBRARY_ROUTES.virtualComponents.backendResourceName]: {
    serializer: serializeVirtualComponent,
    endpoint: ENDPOINT_VIRTUAL_COMPONENTS,
  },
  [BLOCKS_LIBRARY_ROUTES.suppliers.backendResourceName]: {
    serializer: serializeSupplier,
    endpoint: ENDPOINT_SUPPLIERS,
  },
  [BLOCKS_LIBRARY_ROUTES.manufacturers.backendResourceName]: {
    serializer: serializeManufacturer,
    endpoint: ENDPOINT_MANUFACTURERS,
  },
  [BLOCKS_LIBRARY_ROUTES.designConsiderations.backendResourceName]: {
    serializer: serializeDesignConsideration,
    endpoint: ENDPOINT_DESIGN_CONSIDERATIONS,
  },
  [BLOCKS_LIBRARY_ROUTES.exportJobs.backendResourceName]: {
    serializer: serializeExportJob,
    endpoint: ENDPOINT_EXPORT_JOBS,
  },
  [BLOCKS_LIBRARY_ROUTES.exportTemplates.backendResourceName]: {
    serializer: serializeExportTemplate,
    endpoint: ENDPOINT_EXPORT_TEMPLATES,
  },
};

const getShareType = (groups: Array<ShareGroup>, users: Array<ShareUser>): Array<ShareType> => {
  const type: Array<ShareType> = [];

  if (groups.filter((g: ShareGroup) => g.selected).length) {
    type.push('groups');
  }
  if (users.length) {
    type.push('users');
  }

  return type;
};

const actionsByReducer: Record<
  ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID | RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID,
  any
> = {
  [RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID]: {
    success: getChangesRequestByIdSuccess,
    fail: getChangesRequestByIdError,
    endpoint: ENDPOINT_REQUESTS,
  },
  [ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID]: {
    success: getChangeLogByIdSuccess,
    fail: getChangeLogByIdError,
    endpoint: ENDPOINT_REQUESTS,
  },
} as const;

const getUserApprovals = (changeRequest: IChangesRequest, userInfo: User): IChangesRequest => {
  let userCanApprove = false;
  let userPastAction: undefined | string;
  changeRequest.approvals?.forEach((approval: ApprovalsType) => {
    if (approval?.responsible === userInfo?._id) {
      userPastAction = approval.state;
    }
    const canApprove = userInfo?.roles?.find((role: Roles) => role === approval.target);
    if (changeRequest.state === 'pending' && approval.state === 'pending' && canApprove) userCanApprove = true;
  });
  return { ...changeRequest, userCanApprove, userPastAction };
};

function* getSharingData(
  changeRequest: Raw.IChangesRequest,
  userInfo: User,
): Generator<CallEffect<AxiosResponse> | SelectEffect | PutEffect<GetChangesRequestByIdSuccessAction>> {
  // Get old sharing data that contains other essential fields for the form
  const tempChangeRequest = changeRequest;
  const resOldSharing = (yield call(() =>
    axiosInstance.get(
      apiUri(
        `/dataservice/${SERIALIZERS[tempChangeRequest.resource_name].endpoint}/${tempChangeRequest.resource_id}/share`,
        2,
      ),
    ),
  )) as AxiosResponse;
  let oldSharingData = resOldSharing.data as Raw.IResourceSharing;

  const {
    authState: { featurePermissions },
  } = (yield select((state) => state)) as AppState;

  if (featurePermissions.SHARE_WITH_USER.read) {
    if (oldSharingData.users) {
      oldSharingData = {
        ...oldSharingData,
        users: (yield call(() => translateIdsToEmail(oldSharingData.users)) as CallEffect) as Raw.IResourceShareItems,
      };
    }
    if (tempChangeRequest.changes.users) {
      tempChangeRequest.changes.users = (yield call(() =>
        translateIdsToEmail(tempChangeRequest.changes.users),
      ) as CallEffect) as Raw.IResourceShareItems;
    }
    if (tempChangeRequest.old_data.users) {
      tempChangeRequest.old_data.users = (yield call(() =>
        translateIdsToEmail(tempChangeRequest.old_data.users),
      ) as CallEffect) as Raw.IResourceShareItems;
    }
  }

  // Serializer requested changes along with fixing the share_type (aka type)
  const serializedData = serializeSharing(
    { ...oldSharingData, groups: tempChangeRequest.changes.groups, users: tempChangeRequest.changes.users },
    true,
  );

  serializedData.type = getShareType(serializedData.groups, serializedData.users);

  // Set original data either but using `old_data` which is usually when the changes were already applied
  // Or with old data when changes are not yet applied
  // And fix share type
  const oldData = tempChangeRequest.old_data;
  const serializedOriginalData = serializeSharing({
    ...oldSharingData,
    groups: oldData?.groups || oldSharingData.groups,
    users: oldData?.users || oldSharingData.users,
  });

  const originalOldDataType = getShareType(serializedOriginalData.groups, serializedOriginalData.users);

  serializedOriginalData.type = Object.keys(oldData).length ? originalOldDataType : serializedOriginalData.type;

  // Serialize the change request and put success
  const serializedChangesRequest = serializeChangesRequests(tempChangeRequest);
  yield put(
    getChangesRequestByIdSuccess(
      { ...getUserApprovals(serializedChangesRequest, userInfo), changes: serializedData },
      serializedOriginalData,
    ),
  );
}

const isOldDataTruthy = (oldData?: any): boolean => !!oldData && Object.keys(oldData)?.length > 0;

export function* getRequestData(
  changeRequest: Raw.IChangesRequest,
  userInfo: User,
  action: GetChangesRequestByIdAction | GetChangeLogByIdAction,
): Generator<
  CallEffect<AxiosResponse> | SelectEffect | PutEffect<GetChangesRequestByIdSuccessAction | GetAllCategoriesAction>
> {
  const tempChangeRequest = changeRequest;
  const actions = actionsByReducer[action.type];
  if (changeRequest.target_method === 'update') {
    if (!isOldDataTruthy(tempChangeRequest.old_data)) {
      const resOldData = (yield call(() =>
        axiosInstance.get(
          apiUri(
            `/dataservice/${SERIALIZERS[changeRequest.resource_name as keyof typeof SERIALIZERS].endpoint}/${
              changeRequest.resource_id
            }`,
            2,
          ),
        ),
      )) as AxiosResponse;
      tempChangeRequest.old_data =
        Array.isArray(resOldData.data) && (resOldData.data as any).length ? resOldData.data[0] : resOldData.data;
    }
  }

  if (changeRequest.resource_name === 'implementations') {
    const specificationsState = (yield select((state) => state.specificationsState)) as SpecificationsState;

    const filesData = (yield call(() =>
      getImplementationFilesSaga(changeRequest.changes, changeRequest.id),
    )) as Array<Raw.IMinioResource>;

    const parsedImplementation: Implementation = serializeImplementation(
      tempChangeRequest.changes,
      specificationsState.specificationsList,
      filesData,
    );
    tempChangeRequest.changes = parsedImplementation;

    if (isOldDataTruthy(tempChangeRequest.old_data)) {
      const oldFilesData = (yield call(() =>
        getImplementationFilesSaga(changeRequest.old_data, changeRequest.id),
      )) as Array<Raw.IMinioResource>;

      tempChangeRequest.old_data = serializeImplementation(
        tempChangeRequest.old_data,
        specificationsState.specificationsList,
        oldFilesData,
      );
    }
  } else if (changeRequest.resource_name === 'components') {
    const parsedComponent: Raw.Components = tempChangeRequest.changes;

    const [resDocFiles, resCadFiles] = (yield call(() =>
      getComponentFilesSaga(parsedComponent, changeRequest.id),
    )) as Array<Array<Raw.IMinioResource>>;

    const [resOldDocFiles, resOldCadFiles] = (yield call(() =>
      getComponentFilesSaga(tempChangeRequest.old_data, changeRequest.id),
    )) as Array<Array<Raw.IMinioResource>>;

    const ResAllDocFiles = resDocFiles?.concat(resOldDocFiles).reduce<Array<Raw.IMinioResource>>((prev, curr) => {
      !prev.find((e) => e.id === curr.id) && prev.push(curr);
      return prev;
    }, []);
    const ResAllCadFiles = resCadFiles?.concat(resOldCadFiles).reduce<Array<Raw.IMinioResource>>((prev, curr) => {
      !prev.find((e) => e.id === curr.id) && prev.push(curr);
      return prev;
    }, []);

    tempChangeRequest.changes = {
      ...serializeComponent(tempChangeRequest.changes),
      componentDocFiles:
        ResAllDocFiles?.map(
          (f: Raw.IMinioResource) =>
            ({
              id: f.id,
              name: f.display_name,
              state: 'success',
              createdAt: f.created_at,
              usedAs: [f.type],
            } as IFileUpload),
        ) || [],
      componentCadFiles:
        ResAllCadFiles.map(
          (f: Raw.IMinioResource) =>
            ({
              id: f.id,
              name: f.display_name,
              state: 'success',
              createdAt: f.created_at,
              usedAs: [f.type],
            } as IFileUpload),
        ) || [],
    };

    tempChangeRequest.old_data =
      (isOldDataTruthy(tempChangeRequest.old_data) && serializeComponent(tempChangeRequest.old_data)) || undefined;
  } else if (changeRequest.resource_name === 'virtual_components') {
    const parsedComponent: IVirtualComponent = serializeVirtualComponent(tempChangeRequest.changes);
    const [resDocFiles, resCadFiles] = (yield call(() =>
      getVirtualComponentFilesSaga(tempChangeRequest.changes, changeRequest.id),
    )) as Array<Array<Raw.IMinioResource>>;

    const [resOldDocFiles, resOldCadFiles] = (yield call(() =>
      getVirtualComponentFilesSaga(tempChangeRequest.old_data, changeRequest.id),
    )) as Array<Array<Raw.IMinioResource>>;

    const ResAllDocFiles = resDocFiles?.concat(resOldDocFiles).reduce<Array<Raw.IMinioResource>>((prev, curr) => {
      !prev.find((e) => e.id === curr.id) && prev.push(curr);
      return prev;
    }, []);
    const ResAllCadFiles = resCadFiles?.concat(resOldCadFiles).reduce<Array<Raw.IMinioResource>>((prev, curr) => {
      !prev.find((e) => e.id === curr.id) && prev.push(curr);
      return prev;
    }, []);

    tempChangeRequest.changes = {
      ...parsedComponent,
      virtualComponentDocFiles:
        ResAllDocFiles?.map(
          (f: Raw.IMinioResource) =>
            ({
              id: f.id,
              name: f.display_name,
              state: 'success',
              createdAt: f.created_at,
              usedAs: [f.type],
            } as IFileUpload),
        ) || [],
      virtualComponentCadFiles:
        ResAllCadFiles?.map(
          (f: Raw.IMinioResource) =>
            ({
              id: f.id,
              name: f.display_name,
              state: 'success',
              createdAt: f.created_at,
              usedAs: [f.type],
            } as IFileUpload),
        ) || [],
    };

    tempChangeRequest.old_data =
      (isOldDataTruthy(tempChangeRequest.old_data) && serializeVirtualComponent(tempChangeRequest.old_data)) ||
      undefined;
  } else if (changeRequest.resource_name === 'taxonomy') {
    tempChangeRequest.changes = SERIALIZERS[changeRequest.resource_name as keyof typeof SERIALIZERS].serializer(
      tempChangeRequest.changes,
    );

    const { parent } = tempChangeRequest.changes;
    const { parent: oldDataParent } = tempChangeRequest.old_data;
    const parentIds = [...(parent ? [parent] : []), ...(oldDataParent ? [oldDataParent] : [])];
    const parentIdsQuery = `query=id="${parentIds?.join('"%OR%id="')}"`;

    yield put(getAllCategoriesAction({ advancedSearchQuery: parentIdsQuery, queryType: 'parent' }));

    tempChangeRequest.old_data =
      (isOldDataTruthy(tempChangeRequest.old_data) &&
        SERIALIZERS[changeRequest.resource_name as keyof typeof SERIALIZERS].serializer(tempChangeRequest.old_data)) ||
      undefined;
  } else {
    // Doing ts-ignore here because it is complaining that some serializers have more data
    // But for those we are already considering in the ifs above
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    tempChangeRequest.changes = SERIALIZERS[changeRequest.resource_name as keyof typeof SERIALIZERS].serializer(
      tempChangeRequest.changes,
    );

    tempChangeRequest.old_data =
      (isOldDataTruthy(tempChangeRequest.old_data) &&
        // Doing ts-ignore here because it is complaining that some serializers have more data
        // But for those we are already considering in the ifs above
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        SERIALIZERS[changeRequest.resource_name as keyof typeof SERIALIZERS].serializer(tempChangeRequest.old_data)) ||
      undefined;
  }

  const serializedChangesRequest = serializeChangesRequests(tempChangeRequest);
  yield put(actions.success(getUserApprovals(serializedChangesRequest, userInfo)));
}

export function* getByIdSaga(
  action: GetChangesRequestByIdAction,
): Generator<
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetChangesRequestByIdSuccessAction | GetChangesRequestByIdErrorAction>
  | CallEffect
> {
  const actions = actionsByReducer[action.type];
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${actions.endpoint}/${action.payload.id}`, 2)),
    )) as AxiosResponse;
    const changeRequest: Raw.IChangesRequest = res.data;

    const userInfo = (yield select((state) => state.authState.userInfo)) as User;

    // Sharing requests
    if (changeRequest.target_method === 'share') {
      yield call(getSharingData, changeRequest, userInfo);
    }

    // Other requests
    if (['create', 'update', 'delete'].includes(changeRequest.target_method)) {
      yield call(getRequestData, changeRequest, userInfo, action);
    }
  } catch (error) {
    yield put(actions.fail(getErrorMessage(error as AxiosError) || 'Fetching request failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest([RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID], getByIdSaga);
}
