import {
  InterfaceActionTypes,
  InterfaceActions,
  CreateInterfaceErrorAction,
  UpdateInterfaceErrorAction,
  DeleteInterfaceErrorAction,
  CloneInterfaceErrorAction,
} from 'state_management/actions/interfaces/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const interfacesCreateToasts = (action: InterfaceActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Electrical Port Type is being created, please wait...',
      success: 'Electrical Port Type has been successfully created.',
      error:
        getErrorMessage((action as CreateInterfaceErrorAction).error) ||
        'Creating Electrical Port Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const interfacesUpdateToasts = (action: InterfaceActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Electrical Port Type is being updated, please wait...',
      success: 'Electrical Port Type has been successfully updated.',
      error:
        getErrorMessage((action as UpdateInterfaceErrorAction).error) ||
        'Updating Electrical Port Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const interfacesCloneToasts = (action: InterfaceActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Electrical Port Type is being cloned, please wait...',
      success: 'Electrical Port Type has been successfully cloned.',
      error:
        getErrorMessage((action as unknown as CloneInterfaceErrorAction).error) ||
        'Cloning Electrical Port Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const interfacesDeleteToasts = (action: InterfaceActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Electrical Port Type is being deleted, please wait...',
      success: 'Electrical Port Type has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteInterfaceErrorAction).error) ||
        'Deleting Electrical Port Type failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsSaga(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        InterfaceActionTypes.CREATE_INTERFACE,
        InterfaceActionTypes.CREATE_INTERFACE_SUCCESS,
        InterfaceActionTypes.CREATE_INTERFACE_FAIL,
      ],
      interfacesCreateToasts,
    ),
    takeEvery(
      [
        InterfaceActionTypes.UPDATE_INTERFACE,
        InterfaceActionTypes.UPDATE_INTERFACE_SUCCESS,
        InterfaceActionTypes.UPDATE_INTERFACE_FAIL,
      ],
      interfacesUpdateToasts,
    ),
    takeEvery(
      [
        InterfaceActionTypes.CLONE_INTERFACE,
        InterfaceActionTypes.CLONE_INTERFACE_SUCCESS,
        InterfaceActionTypes.CLONE_INTERFACE_FAIL,
      ],
      interfacesCloneToasts,
    ),
    takeEvery(
      [
        InterfaceActionTypes.DELETE_INTERFACE,
        InterfaceActionTypes.DELETE_INTERFACE_SUCCESS,
        InterfaceActionTypes.DELETE_INTERFACE_FAIL,
      ],
      interfacesDeleteToasts,
    ),
  ]);
}
