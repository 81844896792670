import { CanvasType } from 'routes/getRoutes';
import { IssuesData, ProjectIssues } from 'state_management/reducers/projects/Modals';

const filterIssuesByCanvas = (
  issues: ProjectIssues | undefined,
  selectedCanvas: CanvasType | undefined,
): ProjectIssues => {
  let errors: IssuesData[] = [];
  let warnings: IssuesData[] = [];

  if (selectedCanvas === 'Design Canvas') {
    errors = issues?.errors?.filter((e) => e.canvas === 'design') || [];
    warnings = issues?.warnings?.filter((w) => w.canvas === 'design') || [];
  }

  if (selectedCanvas === 'Board-Shape Canvas') {
    errors = issues?.errors?.filter((e) => e.canvas === 'board') || [];
    warnings = issues?.warnings?.filter((w) => w.canvas === 'board') || [];
  }

  return { errors, warnings };
};

export default filterIssuesByCanvas;
