import {
  ExportTemplatesActionTypes,
  ExportTemplateActions,
  UpdateExportTemplateErrorAction,
  CreateExportTemplateErrorAction,
  DeleteExportTemplateErrorAction,
} from 'state_management/actions/exportTemplates/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const exportTemplatesUpdateToasts = (action: ExportTemplateActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Export Template is being updated, please wait...',
      success: 'Export Template has been successfully updated.',
      error:
        getErrorMessage((action as UpdateExportTemplateErrorAction).error) ||
        'Updating Export Template failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const exportTemplatesCloneToasts = (action: ExportTemplateActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Export Template is being cloned, please wait...',
      success: 'Export Template has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateExportTemplateErrorAction).error) ||
        'Cloning Expor tTemplate failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const exportTemplatesCreateToasts = (action: ExportTemplateActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Export Template is being created, please wait...',
      success: 'Export Template has been successfully created.',
      error:
        getErrorMessage((action as CreateExportTemplateErrorAction).error) ||
        'Creating Export Template failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const exportTemplatesDeleteToasts = (action: ExportTemplateActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Export Template is being deleted, please wait...',
      success: 'Export Template has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteExportTemplateErrorAction).error) ||
        'Deleting Export Template failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE,
        ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS,
        ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_FAIL,
      ],
      exportTemplatesCreateToasts,
    ),
    takeEvery(
      [
        ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE,
        ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS,
        ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_FAIL,
      ],
      exportTemplatesUpdateToasts,
    ),
    takeEvery(
      [
        ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE,
        ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_SUCCESS,
        ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_FAIL,
      ],
      exportTemplatesCloneToasts,
    ),
    takeEvery(
      [
        ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE,
        ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS,
        ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_FAIL,
      ],
      exportTemplatesDeleteToasts,
    ),
  ]);
}
