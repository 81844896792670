import { IPagination } from 'models/Pagination';
import { AppType } from 'state_management/reducers/auth/Modals';
import { Project } from 'state_management/reducers/projects/Modals';
import { AxiosErrorPayload, AxiosRequestPayload, AxiosSuccessPayload } from '../../middlewares/axios/Modals';

export enum RefDesignActionTypes {
  GET_ALL_REFERENCE_DESIGNS = 'GET_ALL_REFERENCE_DESIGNS',
  GET_ALL_REFERENCE_DESIGNS_SUCCESS = 'GET_ALL_REFERENCE_DESIGNS_SUCCESS',
  GET_ALL_REFERENCE_DESIGNS_FAIL = 'GET_ALL_REFERENCE_DESIGNS_FAIL',

  CREATE_REFERENCE_DESIGNS = 'CREATE_REFERENCE_DESIGNS',
  CREATE_REFERENCE_DESIGNS_SUCCESS = 'CREATE_REFERENCE_DESIGNS_SUCCESS',
  CREATE_REFERENCE_DESIGNS_FAIL = 'CREATE_REFERENCE_DESIGNS_FAIL',

  UPDATE_REFERENCE_DESIGNS = 'UPDATE_REFERENCE_DESIGNS',
  UPDATE_REFERENCE_DESIGNS_SUCCESS = 'UPDATE_REFERENCE_DESIGNS_SUCCESS',
  UPDATE_REFERENCE_DESIGNS_FAIL = 'UPDATE_REFERENCE_DESIGNS_FAIL',

  DELETE_REFERENCE_DESIGNS = 'DELETE_REFERENCE_DESIGNS',
  DELETE_REFERENCE_DESIGNS_SUCCESS = 'DELETE_REFERENCE_DESIGNS_SUCCESS',
  DELETE_REFERENCE_DESIGNS_FAIL = 'DELETE_REFERENCE_DESIGNS_FAIL',

  GET_REFERENCE_DESIGNS_BY_ID = 'GET_REFERENCE_DESIGNS_BY_ID',
  GET_REFERENCE_DESIGNS_BY_ID_SUCCESS = 'GET_REFERENCE_DESIGNS_BY_ID_SUCCESS',
  GET_REFERENCE_DESIGNS_BY_ID_FAIL = 'GET_REFERENCE_DESIGNS_BY_ID_FAIL',
}

// ============================================ GET_ALL_REFERENCE_DESIGNS
export interface RefDesignsGetAllAction {
  type: RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS;
  payload: Partial<IPagination>;
}

export interface RefDesignsGetAllSuccessAction {
  type: RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_SUCCESS;
  payload: { data: Array<Project>; pagination: IPagination };
}

export interface RefDesignsGetAllErrorAction {
  type: RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_FAIL;
  payload: string;
}

// ============================================ CREATE_REFERENCE_DESIGNS

export interface RefDesignCreateAction extends AxiosRequestPayload<{ referenceDesign: boolean }> {
  type: RefDesignActionTypes.CREATE_REFERENCE_DESIGNS;
}

export interface RefDesignCreateSuccessAction
  extends AxiosSuccessPayload<
    Raw.Project,
    RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_SUCCESS,
    RefDesignCreateAction
  > {
  type: RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_SUCCESS;
}

export interface RefDesignCreateErrorAction
  extends AxiosErrorPayload<RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_FAIL, RefDesignCreateAction> {
  type: RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_FAIL;
}

// ============================================ UPDATE_REFERENCE_DESIGNS

export interface RefDesignUpdateAction extends AxiosRequestPayload<Partial<Raw.Project & { userID: string }>> {
  type: RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS;
}

export interface RefDesignUpdateSuccessAction
  extends AxiosSuccessPayload<
    Raw.Project & { currentAppType: AppType },
    RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_SUCCESS,
    RefDesignUpdateAction
  > {
  type: RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_SUCCESS;
}

export interface RefDesignUpdateErrorAction
  extends AxiosErrorPayload<RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_FAIL, RefDesignUpdateAction> {
  type: RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_FAIL;
}

// ============================================ DELETE_REFERENCE_DESIGNS

export interface RefDesignDeleteAction extends AxiosRequestPayload {
  type: RefDesignActionTypes.DELETE_REFERENCE_DESIGNS;
}

export interface RefDesignDeleteSuccessAction
  extends AxiosSuccessPayload<string, RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_SUCCESS, RefDesignDeleteAction> {
  type: RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_SUCCESS;
}

export interface RefDesignDeleteErrorAction
  extends AxiosErrorPayload<RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_FAIL, RefDesignDeleteAction> {
  type: RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_FAIL;
}

export interface RefDesignsGetByIdAction extends AxiosRequestPayload<{ id: string }> {
  type: RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID;
}

export interface RefDesignsGetByIdSuccessAction
  extends AxiosSuccessPayload<Project, RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID, RefDesignsGetByIdAction> {
  type: RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID_SUCCESS;
}

export interface RefDesignsGetByIdErrorAction {
  type: RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID_FAIL;
  payload: string;
}

// ============================================ ALL

export type RefDesignsActions =
  | RefDesignsGetAllAction
  | RefDesignsGetAllSuccessAction
  | RefDesignsGetAllErrorAction
  | RefDesignCreateAction
  | RefDesignCreateSuccessAction
  | RefDesignCreateErrorAction
  | RefDesignUpdateAction
  | RefDesignUpdateSuccessAction
  | RefDesignUpdateErrorAction
  | RefDesignDeleteAction
  | RefDesignDeleteSuccessAction
  | RefDesignDeleteErrorAction
  | RefDesignsGetByIdAction
  | RefDesignsGetByIdSuccessAction
  | RefDesignsGetByIdErrorAction;
