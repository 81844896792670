import { call, CallEffect, ForkEffect, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';

import {
  DS_PLUGINS_ENDPOINT,
  getPluginsErrorAction,
  getPluginsSuccessAction,
} from 'state_management/actions/plugins/pluginsActions';
import {
  PluginsActionTypes,
  PluginsGetAllErrorAction,
  PluginsGetAllSuccessAction,
} from 'state_management/actions/plugins/ActionTypes';
import { getErrorMessage } from 'utils/getErrorMessage';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';

export function* getAllSaga(): Generator<
  SelectEffect | CallEffect<AxiosResponse> | PutEffect<PluginsGetAllSuccessAction | PluginsGetAllErrorAction>
> {
  try {
    const fields = 'label,id,icon,extras,type,location';

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`${DS_PLUGINS_ENDPOINT}?pg=0&pg_len=100&fields=${fields}`, 2)),
    )) as AxiosResponse;
    const { data } = res;

    yield put(getPluginsSuccessAction({ ...getPaginationFromResponse(res), search: '' }, data));
  } catch (error) {
    yield put(getPluginsErrorAction(getErrorMessage(error as AxiosError) || 'Fetching plugins failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(PluginsActionTypes.GET_ALL_PLUGINS, getAllSaga);
}
