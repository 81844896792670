/* eslint-disable no-case-declarations */
import {
  Implementation,
  ILayerSignalType,
  LayerSignalValue,
  ImplementationCategory,
  IImplementationPort,
  IImplementationSchematicInterface,
} from 'state_management/reducers/implementations/Modals';
import {
  IImplementationSpecification,
  IInstanceSpecification,
  IPcbSpecification,
  ISpecification,
  ISpecValues,
  SpecificationList,
} from 'state_management/reducers/specifications/Modals';
import { IFileState, IFileUpload } from 'models/IFileUpload';
import { deserializeInstanceSpecifications, serializeInstanceSpecifications } from './specificationsSerializer';
import { isMongoId } from './mongoId';

const setDefaultValue = <T>(condition?: boolean, defaultValue?: T): T | undefined =>
  condition ? undefined : defaultValue;

export const standardizeCadFilesUse = (usedAs: Array<string>): Array<string> =>
  usedAs.map((type) => {
    if (type === 'schematics') {
      return 'schematic';
    }

    if (type === 'BOM') {
      return 'bom';
    }

    return type;
  });

const serializeFiles = (payload: Raw.Implementation, filesData?: Array<Raw.IMinioResource>): Array<IFileUpload> => {
  const files: Record<string, IFileUpload> = {
    schematic: {
      id: payload.schematic?.independent_implementation || payload.schematic?.filename || '',
      name: payload.schematic?.filename || '',
      error: '',
      state: 'converted' as IFileState,
      createdAt: payload.created_at,
      usedAs: ['schematic'],
      isOldFile: true,
    },
    bom: {
      id: payload.bom?.independent_implementation || payload.bom?.filename || '',
      name: payload.bom?.filename || '',
      error: '',
      state: 'converted' as IFileState,
      createdAt: payload.created_at,
      usedAs: ['bom'],
      isOldFile: true,
    },
    layout: {
      id: payload.pcb?.independent_implementation || payload.pcb?.filename || '',
      name: payload.pcb?.filename || '',
      error: '',
      state: 'converted' as IFileState,
      createdAt: payload.created_at,
      usedAs: ['layout'],
      isOldFile: true,
    },
  };

  filesData?.forEach((f) => {
    // NOTE: sometimes we receive `schematic` as `schematics` so here we fix that
    const fileType = f.type?.includes('schematic') ? 'schematic' : (f.type as 'schematic' | 'bom' | 'layout');

    files[fileType] = {
      ...files[fileType],
      name: f.display_name,
      createdAt: f.created_at,
      isOldFile: false,
      cad: f.extra.cad,
      originalFileId: f.id,
      version: f.extra.file_version,
      columns: f.extra.columns,
    } as IFileUpload;
  });

  return Object.values(files).filter((f) => f.id);
};

// TODO: We shouldn't do this on frontend, rather mold at source for it's use, i.e Backend/Service
export const serializeImplementation = (
  payload: Raw.Implementation,
  specificationOptions: SpecificationList,
  filesData?: Array<Raw.IMinioResource>,
): Implementation => {
  const instanceSpecifications = payload.instance_specifications?.map(serializeInstanceSpecifications) || [];
  const pcbInstanceSpecifications = payload.pcb?.instance_specifications?.map(serializeInstanceSpecifications) || [];

  return {
    id: payload.id || '',
    bomId: payload.bom_id,
    name: payload.name,
    info: payload.full_info,
    description: payload.short_description,
    isValid: payload.is_valid,
    createdAt: payload.created_at,
    modifiedAt: payload.modified_at,
    iconId: payload.icon_id,
    dynamicBom: payload.bom?.dynamic || false,
    connectionPointsPrefix: payload.schematic?.config?.connection_points_prefix || '*',
    partRefColname: payload.bom?.config?.part_ref_colname || 'Part',
    valueRefColname: payload.bom?.config?.value_ref_colname || 'Value',
    cost: Number(payload.criteria?.cost) || 0,
    robustness: Number(payload.criteria?.robustness) || 0,
    cadFiles: serializeFiles(payload, filesData),
    functions: payload.functions || [],
    instanceSpecifications,
    interfaces:
      payload.ports?.map((p) => ({
        id: p.id,
        direction: p.direction,
        portType: p.port_type,
        // NOTE: not used on UI but we shouldn't loose this
        suitableMcus: p.suitable_mcus,
        suitableSupplies: p.suitable_supplies,
      })) || [],
    pcb: {
      edgeAlignment:
        ((Object.entries(payload.pcb?.edge_alignment || {}).find(([_, value]) => value) || [])[0] as
          | 'top'
          | 'bottom'
          | 'left'
          | 'right'
          | 'none') || 'none',
      filename: payload.pcb?.filename || '',
      independentImplementation: payload.pcb?.independent_implementation,
      originalFile: payload.pcb?.original_file,
      layerSignalType: Object.entries<Array<string>>(payload.pcb?.layer_signal_type || {}).reduce<ILayerSignalType>(
        (res, [key, values]) =>
          values.reduce<ILayerSignalType>(
            (_res, value: string) => ({ ..._res, [value]: key as LayerSignalValue }),
            res,
          ),
        {},
      ),
      instanceSpecifications: pcbInstanceSpecifications,
      softModule: payload.pcb?.soft || false,
    },
    schematic: {
      interfaces:
        payload.schematic?.ports?.map((p) => ({
          id: p.id,
          portType: p.port_type,
          direction: p.direction,
          instanceSpecifications: p.instance_specifications?.map(serializeInstanceSpecifications) || [],
          ports: Object.entries(p.signals || {}).reduce<Array<IImplementationPort>>(
            (res, [key, value]): Array<IImplementationPort> => [...res, { type: key, name: value }],
            [],
          ),
          mlNotice: p.automatic_port,
        })) || [],
      filename: payload.schematic?.filename || '',
      independentImplementation: payload.schematic?.independent_implementation,
      originalFile: payload.schematic?.original_file,
    },
    category: payload.category as ImplementationCategory,
    taxonomy: payload.taxonomy || undefined,
    bom: {
      filename: payload.bom?.filename || '',
      independentImplementation: payload.bom?.independent_implementation,
      originalFile: payload.bom?.original_file,
    },
    constraints: payload.constraints,
    designTargets: payload.design_targets,
    equations: payload.equations,
  };
};

const deserializeSchematic = (
  implementation: Partial<Implementation>,
  specMap: Record<string, ISpecification>,
  isChangesRequest?: boolean,
): Partial<Raw.Schematic> | undefined => {
  let _schematic: Partial<Raw.Schematic> | undefined = {};
  const ports = implementation?.schematic?.interfaces?.map((i) => ({
    id: i.id,
    automatic_port: i.mlNotice,
    port_type: i.portType || '',
    direction: i.direction || 'in',
    instance_specifications:
      (i.instanceSpecifications
        ?.map((is) => (isMongoId(is.id) ? deserializeInstanceSpecifications(is, specMap[is.id]) : undefined))
        .filter(Boolean) as Array<IInstanceSpecification>) || [],
    signals: i.ports?.filter((p) => p.type).reduce((res, p) => ({ ...res, [p.type || '']: p.name }), {}) || {},
  }));
  _schematic = !ports?.length && isChangesRequest ? _schematic : { ..._schematic, ports };

  const filename = implementation?.schematic?.filename;
  _schematic = !filename?.length && isChangesRequest ? _schematic : { ..._schematic, filename };

  const originalFile = implementation.schematic?.originalFile;
  _schematic = !originalFile?.length && isChangesRequest ? _schematic : { ..._schematic, original_file: originalFile };

  const independentImplementation = implementation?.schematic?.independentImplementation;
  _schematic =
    !independentImplementation?.length && isChangesRequest
      ? _schematic
      : { ..._schematic, independent_implementation: independentImplementation };

  const config =
    (implementation?.connectionPointsPrefix && {
      connection_points_prefix: implementation.connectionPointsPrefix,
    }) ||
    undefined;
  _schematic = !Object.keys(config || {}).length && isChangesRequest ? _schematic : { ..._schematic, config };

  return isChangesRequest && !Object.keys(_schematic).length ? undefined : _schematic;
};

const deserializeBom = (
  implementation: Partial<Implementation>,
  isChangesRequest?: boolean,
): Partial<Raw.Bom> | undefined => {
  let bom: Partial<Raw.Bom> | undefined = {};
  const filename = implementation?.bom?.filename;
  bom = !filename?.length && isChangesRequest ? bom : { ...bom, filename };

  const originalFile = implementation.bom?.originalFile;
  bom = !originalFile?.length && isChangesRequest ? bom : { ...bom, original_file: originalFile };

  const independentImplementation = implementation?.bom?.independentImplementation;
  bom =
    !independentImplementation?.length && isChangesRequest
      ? bom
      : { ...bom, independent_implementation: independentImplementation };

  const dynamic = implementation?.dynamicBom;
  bom = dynamic === true && !isChangesRequest ? { ...bom, dynamic } : bom;

  let config: Partial<Raw.Config3> | undefined = {};
  const partRefColname = !implementation?.partRefColname?.length
    ? setDefaultValue(isChangesRequest, 'Part')
    : implementation.partRefColname;
  const valueRefColname = !implementation?.valueRefColname?.length
    ? setDefaultValue(isChangesRequest, 'Value')
    : implementation.valueRefColname;

  config = partRefColname ? { ...config, part_ref_colname: partRefColname } : config;
  config = valueRefColname ? { ...config, value_ref_colname: valueRefColname } : config;

  bom = !Object.keys(config || {}).length && isChangesRequest ? bom : { ...bom, config };

  return isChangesRequest && !Object.keys(bom).length ? undefined : bom;
};

const deserializePcb = (
  implementation: Partial<Implementation>,
  specMap: Record<string, ISpecification>,
  isChangesRequest?: boolean,
): Partial<Raw.Pcb> | undefined => {
  let pcb: Partial<Raw.Pcb> | undefined = {};
  const filename = implementation?.pcb?.filename;
  pcb = !filename?.length && isChangesRequest ? pcb : { ...pcb, filename };

  const originalFile = implementation.pcb?.originalFile;
  pcb = !originalFile?.length && isChangesRequest ? pcb : { ...pcb, original_file: originalFile };

  const independentImplementation = implementation?.pcb?.independentImplementation;
  pcb =
    !independentImplementation?.length && isChangesRequest
      ? pcb
      : { ...pcb, independent_implementation: independentImplementation };

  const edgeAlignment = implementation.pcb?.edgeAlignment
    ? {
        top: implementation.pcb?.edgeAlignment === 'top',
        bottom: implementation.pcb?.edgeAlignment === 'bottom',
        left: implementation.pcb?.edgeAlignment === 'left',
        right: implementation.pcb?.edgeAlignment === 'right',
      }
    : setDefaultValue(isChangesRequest, {
        top: false,
        bottom: false,
        left: false,
        right: false,
      });
  pcb = !edgeAlignment && isChangesRequest ? pcb : { ...pcb, edge_alignment: edgeAlignment };

  const instanceSpecifications =
    (implementation.pcb?.instanceSpecifications
      .map((is) => (isMongoId(is.id) ? deserializeInstanceSpecifications(is, specMap[is.id]) : undefined))
      .filter(Boolean) as Array<IInstanceSpecification>) || [];
  pcb =
    !instanceSpecifications.length && isChangesRequest
      ? pcb
      : { ...pcb, instance_specifications: instanceSpecifications };

  const layerSignalType = Object.entries(implementation.pcb?.layerSignalType || {}).reduce(
    (a, [name, type]) => ({ ...a, [type]: [...(a[type] || []), name] }),
    {} as Raw.LayerSignalType,
  );
  pcb = !Object.keys(layerSignalType).length && isChangesRequest ? pcb : { ...pcb, layer_signal_type: layerSignalType };

  const config = isChangesRequest ? undefined : { pcb_origin_coordinates: [0, 0] };
  pcb = config ? { ...pcb, config } : pcb;

  const soft = implementation.pcb?.softModule;
  pcb = isChangesRequest && soft === undefined ? pcb : { ...pcb, soft: soft || false };

  return isChangesRequest && !Object.keys(pcb).length ? undefined : pcb;
};

const deserializeCriteria = (
  implementation: Partial<Implementation>,
  isChangesRequest?: boolean,
): Partial<Raw.Criteria> | undefined => {
  let criteria: { cost?: number | undefined; robustness: number | undefined } = {
    robustness: implementation.robustness,
  };
  const cost = Number(implementation.cost) === implementation.cost ? implementation.cost : undefined;

  criteria = !cost && isChangesRequest ? criteria : { ...criteria, cost };

  return !Object.keys(criteria).length && isChangesRequest
    ? undefined
    : setDefaultValue(Number(implementation.cost) !== implementation.cost, criteria);
};

const getCastedValue = (
  value: string | number | ISpecValues,
  dataType: 'string' | 'number' | 'integer' | 'select',
  valueType: string,
): string | number | ISpecValues => {
  switch (dataType) {
    case 'number':
    case 'integer':
      switch (valueType) {
        case 'list':
          return value;
        case 'min_max':
        case 'min_typ_max':
        case 'tolerance_percentage':
        case 'tolerance':
          const { min, max, typ, tolerance } = value as ISpecValues;
          const newVal: ISpecValues = {};
          const _values = { min, max, typ, tolerance };
          Object.keys(_values).forEach((key) => {
            if (_values[key as keyof typeof _values]) {
              newVal[key as keyof typeof _values] =
                dataType === 'integer'
                  ? parseInt(`${_values[key as keyof typeof _values]}`, 10)
                  : parseFloat(`${_values[key as keyof typeof _values]}`);
            }
          });
          return newVal;
        default:
          return dataType === 'integer' ? parseInt(`${value}`, 10) : parseFloat(`${value}`);
      }
    case 'select':
    case 'string':
    default:
      return value;
  }
};

// For some reason cloning is changing the datatype apparently py-ds-client with DynamicField issue
export const castSpecValueToRightType = (
  instanceSpecification: IInstanceSpecification,
  dataType: 'string' | 'number' | 'integer' | 'select',
  valueType: string,
): IInstanceSpecification => ({
  ...instanceSpecification,
  value: getCastedValue(instanceSpecification.value, dataType, valueType),
});

export const deserializeInterfaces = (
  interfaces: Array<IImplementationSchematicInterface> | undefined,
  nets: Array<string>,
): Array<Raw.IImplementationInterface> | undefined =>
  interfaces?.map((_interface) => ({
    name: _interface.id as string,
    type: _interface.portType,
    signals: _interface.ports.map((sig) => ({
      signal: sig.type,
      net_index: nets.findIndex((net) => net === sig.name),
    })),
  }));

export const deserializeImplementation = (
  imple: Partial<Implementation>,
  isChangesRequest?: boolean,
  specificationsImplList?: Array<IImplementationSpecification>,
  specificationsPcbList?: Array<IPcbSpecification>,
  withPortsV2?: boolean,
): Partial<Raw.Implementation> => {
  const specImplMap: Record<string, IImplementationSpecification> =
    specificationsImplList?.reduce((p, c: IImplementationSpecification) => ({ ...p, [c.id as string]: c }), {}) || {};
  const specPcbMap: Record<string, IPcbSpecification> =
    specificationsPcbList?.reduce((p, c: IPcbSpecification) => ({ ...p, [c.id as string]: c }), {}) || {};

  const implInstanceSpecifications = (imple.instanceSpecifications || []).map((spec) =>
    castSpecValueToRightType(
      spec,
      (specImplMap[spec.id]?.dataType as 'string' | 'number' | 'integer' | 'select') || ('string' as const),
      specImplMap[spec.id]?.specificationValueType || 'single',
    ),
  );

  const instanceSpecifications = implInstanceSpecifications
    .map((is) => (isMongoId(is.id) ? deserializeInstanceSpecifications(is, specImplMap[is.id]) : undefined))
    .filter(Boolean) as Array<Raw.InstanceSpecification>;

  const portsV2Payload = withPortsV2
    ? { nets: imple.nets, interfaces: deserializeInterfaces(imple.schematic?.interfaces, imple.nets || []) }
    : {};

  return {
    id: imple?.id,
    category: imple.category || setDefaultValue(isChangesRequest, ''),
    taxonomy: imple.taxonomy || null,
    full_info: imple.info || setDefaultValue(isChangesRequest, ''),
    is_valid: setDefaultValue(isChangesRequest, true),
    name: imple.name || setDefaultValue(isChangesRequest, ''),
    short_description: imple.description || setDefaultValue(isChangesRequest, ''),
    instance_specifications: setDefaultValue(
      !instanceSpecifications?.length && isChangesRequest,
      instanceSpecifications,
    ),
    functions: imple.functions,
    ports:
      imple.interfaces?.map((i) => ({
        id: i.id,
        direction: i.direction || 'in',
        port_type: i.portType,
        suitable_mcus: i.suitableMcus,
        suitable_supplies: i.suitableSupplies,
      })) || setDefaultValue(isChangesRequest, []),
    schematic: deserializeSchematic(imple, specImplMap, isChangesRequest),
    bom: deserializeBom(imple, isChangesRequest),
    bom_id: imple.bom?.originalFile,
    criteria: deserializeCriteria(imple, isChangesRequest),
    icon_id: imple?.iconId || setDefaultValue(isChangesRequest, ''),
    pcb: deserializePcb(imple, specPcbMap, isChangesRequest),
    constraints: imple.constraints || setDefaultValue(isChangesRequest, []),
    design_targets: imple.designTargets || setDefaultValue(isChangesRequest, []),
    equations: imple.equations || setDefaultValue(isChangesRequest, []),
    original_cad: imple.cadFiles?.find((f) => f.usedAs.includes('schematic'))?.cad,
    ...portsV2Payload,
  };
};
