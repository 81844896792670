import { CallApprovalType, IChangesRequest } from 'state_management/reducers/changesRequest/Modals';
import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import { deserializeChangesRequests } from 'utils/changesRequestSerializer';
import {
  RequestsActionTypes,
  GetAllChangesRequestsAction,
  GetAllChangesRequestsSuccessAction,
  GetAllChangesRequestsErrorAction,
  CallChangesRequestApprovalAction,
  CreateChangeRequestAction,
  GetChangesRequestByIdAction,
  GetChangesRequestByIdErrorAction,
  GetChangesRequestByIdSuccessAction,
  DeleteChangesRequestAction,
  RetryChangesRequestAction,
  CreateChangeRequestForSharingAction,
} from './ActionTypes';
import { ResourceSharing } from '../../reducers/sharing/Modals';

export const ENDPOINT_REQUESTS = 'changes-request';

export const getAllRequestsAction = (payload: Partial<IPagination> = {}): GetAllChangesRequestsAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
});

export const getAllRequestsSuccessAction = (
  pagination: IPagination,
  data: IChangesRequest[],
): GetAllChangesRequestsSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_SUCCESS,
});

export const getAllRequestsErrorAction = (payload: string): GetAllChangesRequestsErrorAction => ({
  payload,
  type: RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_FAIL,
});

export const callApprovalRequest = ({
  resourceId,
  approvalType,
}: {
  resourceId: string;
  approvalType: CallApprovalType;
}): CallChangesRequestApprovalAction => ({
  type: RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_REQUESTS}/${resourceId}/${approvalType}`, 2),
    },
  },
});

export const createChangeRequestAction = (
  data: Partial<IChangesRequest>,
  forceRefresh = true,
  withApiGateway = false,
): CreateChangeRequestAction => ({
  type: RequestsActionTypes.CREATE_CHANGES_REQUEST,
  forceRefresh,
  payload: {
    request: {
      data: deserializeChangesRequests(data),
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_REQUESTS}`, 2, withApiGateway),
    },
  },
});

export const getChangesRequestById = (payload: Partial<IChangesRequest>): GetChangesRequestByIdAction => ({
  type: RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getChangesRequestByIdSuccess = (
  payload: IChangesRequest,
  originalSharingData?: ResourceSharing,
): GetChangesRequestByIdSuccessAction => ({
  type: RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID_SUCCESS,
  payload,
  originalSharingData,
});

export const getChangesRequestByIdError = (payload: string): GetChangesRequestByIdErrorAction => ({
  type: RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID_FAIL,
  payload,
});

export const deleteChangesRequest = (changesRequest: Partial<IChangesRequest>): DeleteChangesRequestAction => ({
  type: RequestsActionTypes.DELETE_CHANGES_REQUEST,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_REQUESTS}/${changesRequest.id}`, 2),
    },
  },
});

export const retryChangesRequest = (changesRequest: Partial<IChangesRequest>): RetryChangesRequestAction => ({
  type: RequestsActionTypes.RETRY_CHANGES_REQUEST,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_REQUESTS}/${changesRequest.id}/retry`, 2),
    },
  },
});

export const createChangeRequestForSharingAction = (
  data: Partial<IChangesRequest>,
): CreateChangeRequestForSharingAction => ({
  type: RequestsActionTypes.CREATE_CHANGES_REQUEST_FOR_SHARING,
  payload: data,
});
