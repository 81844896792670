import { all, AllEffect } from 'redux-saga/effects';
import { getAllWatcher } from './getAllSaga';
import { refreshSaga, refreshChangeListSaga } from './refreshSaga';
import { getByIdWatcher } from './getByIdSaga';
import { requestsToastsSaga } from './requestsToastsSaga';
import { createChangeRequestForSharingSaga } from './createChangeRequestForSharing';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* changesRequestRootSaga(): Generator<AllEffect<any>> {
  yield all([
    getAllWatcher(),
    refreshSaga(),
    getByIdWatcher(),
    requestsToastsSaga(),
    refreshChangeListSaga(),
    createChangeRequestForSharingSaga(),
  ]);
}
