import { createContext, useMemo, useState } from 'react';
import { ShortcutCheatSheetContextType, ShortcutMap, SubscribedShortcut } from './Modals';

const shortcutsCheatSheetContextDefault = {
  subscribedShortcuts: [],
  add(_id: string, _title: string, _shortcutMaps: Array<ShortcutMap>): void {},
  remove(_id: string): void {},
};

export const ShortcutsCheatSheetContext = createContext<Partial<ShortcutCheatSheetContextType>>(
  shortcutsCheatSheetContextDefault,
);

const ShortcutCheatSheetProvider = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [subscribedShortcuts, setSubscribedShortcuts] = useState<Array<SubscribedShortcut>>([]);

  const add = (id: string, title: string, shortcutMaps: Array<ShortcutMap>): void => {
    setSubscribedShortcuts((_subscribedShortcuts) =>
      [..._subscribedShortcuts.filter((s) => s.id !== id), { id, title, keyMaps: shortcutMaps }].sort((s) =>
        s.title.toLowerCase() > title.toLowerCase() ? 1 : -1,
      ),
    );
  };

  const remove = (id: string): void => {
    setSubscribedShortcuts(subscribedShortcuts.filter((s) => s.id !== id));
  };

  const contextValue = useMemo(() => ({ subscribedShortcuts, add, remove }), [subscribedShortcuts, add, remove]);

  return <ShortcutsCheatSheetContext.Provider value={contextValue}>{children}</ShortcutsCheatSheetContext.Provider>;
};

export default ShortcutCheatSheetProvider;
