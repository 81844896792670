import { ICategories } from 'state_management/reducers/categories/Modals';
import { ITemplateSpecification } from 'state_management/reducers/specifications/Modals';
import { ITemplateDesignConsideration } from 'state_management/reducers/designConsiderations/Modals';

const setDefaultValue = <T>(
  condition?: boolean,
  defaultValue?: T,
  conditionValue: undefined | T = undefined,
): T | undefined => (condition ? conditionValue : defaultValue);

const deserializeSpecification = (categorySpecification: ITemplateSpecification): Raw.ITemplateSpecification => ({
  id: categorySpecification.id,
  is_required: categorySpecification.isRequired === 'required',
  default: categorySpecification.default,
});

const deserializeDesignConsideration = (
  categorySpecification: ITemplateDesignConsideration,
): Raw.ITemplateDesignConsideration => ({
  id: categorySpecification.id,
  is_required: categorySpecification.isRequired === 'required',
  default: categorySpecification.default,
});

export const deserializeCategories = (
  category: Partial<ICategories>,
  isChangesRequest?: boolean,
): Partial<Raw.Category> => ({
  id: category.id,
  label: category.label || setDefaultValue(isChangesRequest, ''),
  description: category.description || setDefaultValue(isChangesRequest, ''),
  full_info: category.fullInfo || setDefaultValue(isChangesRequest, ''),
  created_at: category.createdAt,
  modified_at: category.modifiedAt,
  specifications: category.specifications?.length
    ? category.specifications.map(deserializeSpecification)
    : setDefaultValue(isChangesRequest, []),
  // Both specifications and custom fields are specifications but with different spec_type
  custom_fields: category.customFields?.length
    ? category.customFields.map(deserializeSpecification)
    : setDefaultValue(isChangesRequest, []),
  design_considerations: category.designConsiderations?.length
    ? category.designConsiderations.map(deserializeDesignConsideration)
    : setDefaultValue(isChangesRequest, []),
  is_internal_standard: category.isInternalStandard,
  // This must be null to be passed in the json to the backend, otherwise it will be omitted
  parent: category.parent || setDefaultValue(isChangesRequest, undefined, category.parent),
});

const serializeSpecification = (rawCategorySpecification: Raw.ITemplateSpecification): ITemplateSpecification => ({
  id: rawCategorySpecification.id,
  isRequired: rawCategorySpecification.is_required ? 'required' : 'optional',
  default: rawCategorySpecification.default,
});

const serializeDesignConsideration = (
  rawCategorySpecification: Raw.ITemplateDesignConsideration,
): ITemplateDesignConsideration => ({
  id: rawCategorySpecification.id,
  isRequired: rawCategorySpecification.is_required ? 'required' : 'optional',
  default: rawCategorySpecification.default,
});

export const serializeCategories = (category: Raw.Category): ICategories => ({
  id: category.id,
  label: category.label,
  createdAt: category.created_at,
  modifiedAt: category.modified_at,
  description: category.description,
  fullInfo: category.full_info,
  specifications: category.specifications?.map(serializeSpecification),
  customFields: category.custom_fields?.map(serializeSpecification),
  designConsiderations: category.design_considerations?.map(serializeDesignConsideration),
  isInternalStandard: category.is_internal_standard,
  parent: category.parent,
});
