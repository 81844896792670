import { groupToShareGroup, serializeSharing } from 'utils/sharingSerializer';
import { SharingState } from './Modals';
import { SharingActions, SharingActionTypes } from '../../actions/sharing/ActionTypes';

export const initialStateSharing: SharingState = {
  resources: {},
  userGroups: [],
  resourceSharingsState: 'idle',
  resourceSharingsError: '',
  sharingUpdateState: 'idle',
  sharingUpdateError: '',
};

export const sharingReducer = (state: SharingState = initialStateSharing, action: SharingActions): SharingState => {
  switch (action.type) {
    case SharingActionTypes.GET_RESOURCE_SHARING:
      return {
        ...state,
        resourceSharingsState: 'loading',
      };
    case SharingActionTypes.GET_RESOURCE_SHARING_SUCCESS:
      return {
        ...state,
        resourceSharingsState: 'success',
        resources: {
          ...state.resources,
          [(action.payload.resourceId as string) || 'unknown-id']: serializeSharing(action.payload.data),
        },
        userGroups: action.payload.data.user_groups.map(groupToShareGroup(false, 'read')),
      };
    case SharingActionTypes.GET_RESOURCE_SHARING_FAIL:
      return {
        ...state,
        resourceSharingsState: 'error',
        resourceSharingsError: action.payload,
      };
    case SharingActionTypes.UPDATE_RESOURCE_SHARING:
      return {
        ...state,
        sharingUpdateState: 'loading',
      };
    case SharingActionTypes.UPDATE_RESOURCE_SHARING_SUCCESS:
      return {
        ...state,
        sharingUpdateState: 'success',
      };
    case SharingActionTypes.UPDATE_RESOURCE_SHARING_FAIL:
      return {
        ...state,
        sharingUpdateState: 'error',
        sharingUpdateError: action.payload,
      };
    default:
      return state;
  }
};
