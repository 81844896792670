import { IDesignBlock } from 'state_management/reducers/designBlocks/Modals';

const setDefaultValue = <T>(condition?: boolean, defaultValue?: T): T | undefined =>
  condition ? undefined : defaultValue;

export const deserializeDesignBlock = (
  designBlock: Partial<IDesignBlock>,
  isChangesRequest?: boolean,
): Partial<Raw.IDesignBlock> => ({
  id: designBlock.id,
  name: designBlock.name || setDefaultValue(isChangesRequest, ''),
  description: designBlock.description || setDefaultValue(isChangesRequest, ''),
  created_at: designBlock.createdAt,
  modified_at: designBlock.modifiedAt,
  full_info: designBlock.info || setDefaultValue(isChangesRequest, ''),
  category: designBlock.category || setDefaultValue(isChangesRequest, ''),
  functions: designBlock.functions || setDefaultValue(isChangesRequest, []),
  specifications: designBlock.specificationIds?.length
    ? designBlock.specificationIds
    : setDefaultValue(isChangesRequest, []),
  icon: designBlock.icon,
  color: designBlock.color,
  taxonomy: designBlock.taxonomy,
});

export const serializeDesignBlock = (designBlock: Raw.IDesignBlock): IDesignBlock => ({
  id: designBlock.id,
  name: designBlock.name,
  createdAt: designBlock.created_at,
  modifiedAt: designBlock.modified_at,
  description: designBlock.description,
  info: designBlock.full_info,
  category: designBlock.category,
  functions: designBlock.functions,
  specificationIds: designBlock.specifications,
  icon: designBlock.icon || 'chip_mcu',
  color: designBlock.color || 'transparent',
  taxonomy: designBlock.taxonomy,
});
