import {
  IInterfaceSpecification,
  IInterfaceSpecificationDirection,
  InterfaceType,
} from 'state_management/reducers/interfaces/Modals';

const deserializeSpecifications = (
  interfaceSpecification?: Array<IInterfaceSpecification>,
  isChangesRequest?: boolean,
): Partial<Raw.SpecificationKeys> | undefined => {
  let specs = {};
  const all = interfaceSpecification
    ?.filter((s: IInterfaceSpecification) => s.direction === 'all')
    .map((s: IInterfaceSpecification) => s.id || '')
    .filter(Boolean);
  specs = !all?.length && isChangesRequest ? specs : { ...specs, all };

  const _in = interfaceSpecification
    ?.filter((s: IInterfaceSpecification) => s.direction === 'in')
    .map((s: IInterfaceSpecification) => s.id || '')
    .filter(Boolean);
  specs = !_in?.length && isChangesRequest ? specs : { ...specs, in: _in };

  const out = interfaceSpecification
    ?.filter((s: IInterfaceSpecification) => s.direction === 'out')
    .map((s: IInterfaceSpecification) => s.id || '')
    .filter(Boolean);
  specs = !out?.length && isChangesRequest ? specs : { ...specs, out };

  const io = interfaceSpecification
    ?.filter((s: IInterfaceSpecification) => s.direction === 'io')
    .map((s: IInterfaceSpecification) => s.id || '')
    .filter(Boolean);
  specs = !io?.length && isChangesRequest ? specs : { ...specs, io };

  return Object.keys(specs).length ? specs : undefined;
};

export function deserializeInterface(interfaceModel: InterfaceType, isChangesRequest?: boolean): Raw.InterfaceType;
export function deserializeInterface(
  interfaceModel: Partial<InterfaceType>,
  isChangesRequest?: boolean,
): Partial<Raw.InterfaceType>;
export function deserializeInterface(
  interfaceModel: Partial<InterfaceType>,
  isChangesRequest?: boolean,
): Partial<Raw.InterfaceType> {
  return {
    id: interfaceModel.id,
    port_type: interfaceModel.name,
    short_description: interfaceModel.shortDescription,
    full_info: interfaceModel.fullInfo,
    multiuse: interfaceModel.multiuse,
    direction: interfaceModel.direction,
    signals: interfaceModel.ports?.map((p) => ({
      key: p.signalId,
      required: p.required === 'true',
      signal_class: p.type,
    })),
    specification_keys: deserializeSpecifications(interfaceModel.specifications, isChangesRequest),
    // DEPRECATED
    metadata: {},
    id_prefix: interfaceModel.name,
    signal_keys: interfaceModel.ports?.map((port) => port.signalId || '').filter(Boolean),
  };
}

export const serializeInterface = (interfacePayload: Raw.InterfaceType): InterfaceType => ({
  id: interfacePayload.id,
  name: interfacePayload.port_type,
  shortDescription: interfacePayload.short_description || '',
  fullInfo: interfacePayload.full_info || '',
  multiuse: interfacePayload.multiuse,
  direction: interfacePayload.direction,
  createdAt: interfacePayload.created_at,
  modifiedAt: interfacePayload.modified_at,
  ports:
    interfacePayload.signals?.map((signal) => ({
      signalId: signal.key,
      type: signal.signal_class,
      required: (signal.required && 'true') || 'false',
    })) ||
    interfacePayload.signal_keys?.map((signal) => ({
      // DEPRECATED
      signalId: signal,
      type: 'basic',
      required: 'true',
    })) ||
    [],
  // NOTE: These specs are Specification Definitions, that's why no `value` just template
  specifications: Object.keys(interfacePayload.specification_keys).flatMap(
    (key) =>
      interfacePayload.specification_keys[key as unknown as IInterfaceSpecificationDirection]?.map((value) => ({
        id: value,
        direction: key,
      })) as unknown as Array<IInterfaceSpecification>,
  ),
});
