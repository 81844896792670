import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';

import {
  getAllRefDesignsErrorAction,
  getAllRefDesignsSuccessAction,
} from 'state_management/actions/referenceDesigns/referenceDesignsActions';
import {
  RefDesignActionTypes,
  RefDesignsGetAllAction,
  RefDesignsGetAllErrorAction,
  RefDesignsGetAllSuccessAction,
} from 'state_management/actions/referenceDesigns/ActionTypes';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeProject } from 'utils/projectSerializer';
import { combineSearchQueries, getSearchQuery, TypeSearch } from 'utils/searchHelper';
import { escapeSearch } from 'utils/urlUtils';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { DS_PROJECTS_ENDPOINT } from 'state_management/actions/projects/projectsActions';

export function* getAllSaga(
  action: RefDesignsGetAllAction,
): Generator<
  SelectEffect | CallEffect<AxiosResponse> | PutEffect<RefDesignsGetAllSuccessAction | RefDesignsGetAllErrorAction>
> {
  try {
    const {
      referenceDesignsState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search || '',
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const refDesignsSearchSchema: TypeSearch = {
      title: 'value',
      description: 'value',
    };

    const sQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), refDesignsSearchSchema),
      true,
    );

    const searchQuery = combineSearchQueries(
      'query=is_reference_design=true',
      sQuery,
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`${DS_PROJECTS_ENDPOINT}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2)),
    )) as AxiosResponse;
    const data = res.data.map(serializeProject);

    yield put(getAllRefDesignsSuccessAction({ ...getPaginationFromResponse(res), search: search || '' }, data));
  } catch (error) {
    yield put(
      getAllRefDesignsErrorAction(getErrorMessage(error as AxiosError) || 'Fetching reference designs failed.'),
    );
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS, getAllSaga);
}
