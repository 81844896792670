import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import {
  CategoryGelAllByLinkTypes,
  ICategories,
  ICategoriesPagination,
} from 'state_management/reducers/categories/Modals';
import { deserializeCategories } from 'utils/categoriesSerializer';
import {
  CloneCategoryAction,
  CategoriesActionTypes,
  CreateCategoryAction,
  DeleteCategoryAction,
  GetAllCategoriesAction,
  GetAllCategoriesErrorAction,
  GetAllCategoriesSuccessAction,
  GetCategoryByIdAction,
  GetCategoryByIdErrorAction,
  GetCategoryByIdSuccessAction,
  GetCategoryDependenciesAction,
  UpdateCategoryAction,
  GetAllCategoriesByLinkAction,
  GetAllCategoriesByLinkSuccessAction,
  GetAllCategoriesByLinkErrorAction,
} from './ActionTypes';

export const ENDPOINT_CATEGORIES = 'taxonomy';

export const getAllCategoriesAction = (payload: ICategoriesPagination = {}): GetAllCategoriesAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: CategoriesActionTypes.GET_ALL_CATEGORIES,
});

export const getAllCategoriesSuccessAction = (
  pagination: ICategoriesPagination,
  data: Array<ICategories>,
): GetAllCategoriesSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_SUCCESS,
});

export const getAllCategoriesErrorAction = (payload: string): GetAllCategoriesErrorAction => ({
  payload,
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_FAIL,
});

export const getAllCategoriesByLinkAction = (
  payload: {
    pagination?: Partial<IPagination>;
    linkType: CategoryGelAllByLinkTypes;
    linkedResourceId?: string;
  } = { linkType: 'root' },
): GetAllCategoriesByLinkAction => ({
  payload: {
    ...payload,
    pagination: { ...payload?.pagination, queryType: payload?.pagination?.queryType || 'paginated' },
  },
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK,
});

export const getAllCategoriesByLinkSuccessAction = (
  pagination: ICategoriesPagination,
  categoriesItems: Record<string, ICategories>,
  categoriesAncestorsList: Record<string, Array<string>>,
  categoriesDescendantsList: Record<string, Array<string>>,
): GetAllCategoriesByLinkSuccessAction => ({
  payload: {
    pagination,
    categoriesItems,
    categoriesAncestorsList,
    categoriesDescendantsList,
  },
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK_SUCCESS,
});

export const getAllCategoriesByLinkErrorAction = (payload: string): GetAllCategoriesByLinkErrorAction => ({
  payload,
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK_FAIL,
});

export const createCategory = (category: ICategories): CreateCategoryAction => ({
  type: CategoriesActionTypes.CREATE_CATEGORY,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_CATEGORIES}`, 2),
      data: deserializeCategories(category) as Raw.Category,
    },
  },
});

export const updateCategory = (category: ICategories): UpdateCategoryAction => ({
  type: CategoriesActionTypes.UPDATE_CATEGORY,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/${category.id}`, 2),
      data: deserializeCategories(category) as Raw.Category,
    },
  },
});

export const cloneCategory = (category: ICategories): CloneCategoryAction => ({
  type: CategoriesActionTypes.CLONE_CATEGORY,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/`, 2),
      data: deserializeCategories(category) as Raw.Category,
    },
  },
});

export const deleteCategory = (category: ICategories): DeleteCategoryAction => ({
  type: CategoriesActionTypes.DELETE_CATEGORY,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/${category.id}`, 2),
    },
  },
});

export const getCategoryById = (payload: ICategories): GetCategoryByIdAction => ({
  type: CategoriesActionTypes.GET_CATEGORY_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getCategoryDependenciesAction = (): GetCategoryDependenciesAction => ({
  type: CategoriesActionTypes.GET_CATEGORY_DEPENDENCIES,
});

export const getCategoryByIdSuccess = (payload: {
  data: ICategories;
  parent: ICategories | undefined;
}): GetCategoryByIdSuccessAction => ({
  type: CategoriesActionTypes.GET_CATEGORY_BY_ID_SUCCESS,
  payload,
});

export const getCategoryByIdError = (payload: string): GetCategoryByIdErrorAction => ({
  type: CategoriesActionTypes.GET_CATEGORY_BY_ID_FAIL,
  payload,
});
