import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { RefDesignActionTypes, RefDesignsGetAllAction } from 'state_management/actions/referenceDesigns/ActionTypes';
import { getAllRefDesigns } from 'state_management/actions/referenceDesigns/referenceDesignsActions';

export function* refreshRefDesigns(): Generator<PutEffect<RefDesignsGetAllAction>> {
  yield put(getAllRefDesigns());
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_SUCCESS,
      RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_SUCCESS,
      RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_SUCCESS,
    ],
    refreshRefDesigns,
  );
}
