import { AxiosResponse } from 'axios';
import { call, put, CallEffect, PutEffect, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  BillOfMaterialActionTypes,
  GetBomTransitionState,
  GetBomTransitionStateFail,
  GetBomTransitionStateSuccess,
} from 'state_management/actions/billOfMaterial/ActionTypes';
import {
  getBomTransitionStateFail,
  getBomTransitionStateSuccess,
} from 'state_management/actions/billOfMaterial/billOfMaterialActions';

export function* getBomTransitionState(
  action: GetBomTransitionState,
): Generator<
  | CallEffect<AxiosResponse<Array<{ modified_at: string }>>>
  | PutEffect<GetBomTransitionStateSuccess | GetBomTransitionStateFail>
> {
  const { projectId } = action.payload;

  try {
    // try to get the last modification date of rapid canvas
    const canvasEndPoint = `dataservice/rappid-canvas?fields=modified_at&query=project_id="${projectId}"`;
    const canvasData = (yield call(() => axiosInstance.get(apiUri(canvasEndPoint, 2)))) as AxiosResponse<
      Array<{ modified_at: string }>
    >;

    if (!canvasData?.data?.length) {
      yield put(getBomTransitionStateSuccess('idle'));
      return;
    }

    const modificationRulesEndPoint = `dataservice/compiler/bom-modification-rules/?fields=rules&query=project_id="${projectId}"`;
    const modificationRulesData = (yield call(() =>
      axiosInstance.get(apiUri(modificationRulesEndPoint, 2)),
    )) as AxiosResponse<Array<{ rules: Array<{ processed_time?: string }> }>>;

    const modificationRule = modificationRulesData?.data[0];

    if (!modificationRule?.rules.filter((r) => r.processed_time).length) {
      yield put(getBomTransitionStateSuccess('idle'));
      return;
    }

    const canvasModificationDate = new Date(canvasData.data[0].modified_at);

    // try to get the latest processed rule.
    const lastRuleProcessedTime = Math.max(
      ...(modificationRule.rules
        .filter((r) => r.processed_time)
        .map((r) => new Date(r.processed_time as string).getTime()) || []),
    );

    if (canvasModificationDate.getTime() > lastRuleProcessedTime) yield put(getBomTransitionStateSuccess('outdated'));
    else yield put(getBomTransitionStateSuccess('updated'));
  } catch {
    yield put(getBomTransitionStateFail());
  }
}

export function* getBomTransitionStateWatcher(): any {
  yield takeLatest(BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET, getBomTransitionState);
}
