import {
  retry,
  PutEffect,
  ForkEffect,
  CallEffect,
  put,
  select,
  takeLatest,
  call,
  SelectEffect,
} from 'redux-saga/effects';

import {
  ConvertImplementationCadFilesActionSuccess,
  ConvertImplementationCadFilesActionFail,
  ImplementationActionTypes,
} from 'state_management/actions/implementations/ActionTypes';
import {
  convertImplementationCadFilesSuccessAction,
  convertImplementationCadFilesFailAction,
} from 'state_management/actions/implementations/implementationsActions';
import { axiosInstance } from 'services/dataService';
import { AppState } from 'state_management/AppState';
import { IFileState, IFileUpload } from 'models/IFileUpload';
import { apiUri } from 'services/main_app';
import { AxiosResponse } from 'axios';

export function* importCadFilesSaga(): Generator<
  | SelectEffect
  | CallEffect<unknown>
  | PutEffect<ConvertImplementationCadFilesActionSuccess>
  | PutEffect<ConvertImplementationCadFilesActionFail>
> {
  const {
    implementationsState: { implementationFiles },
    authState: {
      userInfo: { _id: user },
    },
  } = (yield select((_state: AppState) => _state)) as AppState;

  try {
    const sch = implementationFiles.find((file: IFileUpload) => file.usedAs[0] === 'schematic') as IFileUpload;
    const bom = implementationFiles.find((file: IFileUpload) => file.usedAs[0] === 'bom') as IFileUpload;
    const layout: IFileUpload | undefined = implementationFiles.find(
      (file: IFileUpload) => file.usedAs[0] === 'layout',
    );

    // if the layout and schematics type are not of the same cad tool and the layout is provided, then return fail
    if (layout && layout.cad !== sch.cad) {
      throw new Error('Layout and Schematic has different CAD');
    }

    // Get the original file id and fallback to the converted file ID
    // This is because converter expects the original file ID
    const fileData = {
      user,
      cad: sch.cad,
      sch_item: sch.originalFileId || sch.id,
      brd_item: layout?.originalFileId || layout?.id || null,
      csv_item: bom.originalFileId || bom.id,
      raw_crc: '',
    };

    yield call(() => axiosInstance.post(apiUri(`/process/import_sch_and_brd/${sch.id}`, 2), fileData));
    const dagSuccess = yield retry(5 * 60, 1000, async () => {
      const validationResponse = await axiosInstance.get(apiUri(`/process/import_sch_and_brd/${sch.id}`, 2));
      const validationState = (validationResponse.data as { state: 'running' | 'failed' }).state;

      if (validationState === 'running') {
        throw new Error('File validation still pending');
      }
      return validationState !== 'failed';
    });

    if (!dagSuccess) {
      throw new Error('Converter DAG Failed');
    }

    const independentSch = yield retry(2 * 60, 3000, async () =>
      axiosInstance.get(apiUri(`/dataservice/independent-schematics/${sch.id}`, 2), {
        withCredentials: true,
      }),
    );
    const independentBom = yield retry(2 * 60, 3000, async () =>
      axiosInstance.get(apiUri(`/dataservice/independent-bom/${bom.id}`, 2), {
        withCredentials: true,
      }),
    );

    let layoutFilenameMap = {};
    if (layout) {
      const independentLayout = yield retry(2 * 60, 3000, async () =>
        axiosInstance.get(apiUri(`/dataservice/independent-layout/${layout.id}`, 2), {
          withCredentials: true,
        }),
      );
      layoutFilenameMap = { [layout.id]: ((independentLayout as AxiosResponse).data as Raw.IMinioResource).id };
    }
    const mapFilename2Id: Record<string, string> = {
      [sch.id]: ((independentSch as AxiosResponse).data as Raw.IMinioResource).id,
      [bom.id]: ((independentBom as AxiosResponse).data as Raw.IMinioResource).id,
      ...layoutFilenameMap,
    };

    const convertedFiles = implementationFiles.map((f: IFileUpload) => ({
      ...f,
      id: mapFilename2Id[f.id], // converted file ID (independent)
      state: (mapFilename2Id[f.id] && 'converted') || ('error' as IFileState),
      originalFileId: f.originalFileId || f.id, // Original file ID (object-storage)
    }));

    yield put(convertImplementationCadFilesSuccessAction(convertedFiles));
  } catch (error) {
    yield put(
      convertImplementationCadFilesFailAction(implementationFiles.map((f) => ({ ...f, state: 'error' as IFileState }))),
    );
  }
}

export function* importCadFilesSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE, importCadFilesSaga);
}
