import styled from 'styled-components';
import { spinningAnimation } from 'theme';

export const Icon = styled.i<{
  size: string;
  active?: boolean;
}>`
  && {
    display: inline-block;
    ${({ size, color }): string => `
        color: ${color};
        font-size: ${size}px;
    `}

    ${({ className, active = true }): string => (className === 'celusico-loading' && spinningAnimation(active)) || ''}
  }
`;
