import {
  PutEffect,
  ForkEffect,
  CallEffect,
  put,
  takeEvery,
  call,
  SelectEffect,
  TakeEffect,
  select,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  SystemDesignCanvasActionTypes,
  GetDesignCanvasAction,
  GetDesignCanvasErrorAction,
  GetDesignCanvasSuccessAction,
} from 'state_management/actions/systemDesignCanvas/ActionTypes';

import {
  getDesignCanvasSuccess,
  getDesignCanvasError,
} from 'state_management/actions/systemDesignCanvas/systemDesignCanvasActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  serializeDesignCanvasBlock,
  serializeRappidDesignCanvasBlock,
} from 'utils/serializers/systemDesignCanvasSerializer';
import { GetAllSubSystemsAction } from 'state_management/actions/subSystemCanvas/ActionTypes';
import { GetAllImplementationsAction } from 'state_management/actions/implementations/ActionTypes';
import {
  ENDPOINT_DESIGN_BLOCKS,
  getAllDesignBlocksSuccessAction,
} from 'state_management/actions/designBlocks/designBlocksActions';
import { serializeDesignBlock } from 'utils/designBlocksSerializer';
import { AppState } from 'state_management/AppState';
import { getLocalCanvasByProjectId } from 'services/localProjectsService';
import { GetAllDesignBlocksSuccessAction } from 'state_management/actions/designBlocks/ActionTypes';
import { DesignBlocksState } from 'state_management/reducers/designBlocks/Modals';
import dataLayer from 'services/dataLayer';
import { combineSearchQueries } from 'utils/searchHelper';
import { GROUPS_FILTERING_LUT } from 'config';
import { AuthState } from 'state_management/reducers/auth/Modals';

export function* getSaga(
  action: GetDesignCanvasAction,
): Generator<
  | PutEffect<GetDesignCanvasAction>
  | SelectEffect
  | TakeEffect
  | CallEffect<AxiosResponse | undefined>
  | CallEffect<[AxiosResponse, AxiosResponse]>
  | PutEffect<
      | GetAllSubSystemsAction
      | GetAllImplementationsAction
      | GetDesignCanvasSuccessAction
      | GetDesignCanvasErrorAction
      | GetAllDesignBlocksSuccessAction
    >
> {
  try {
    const { id, version } = action.payload;

    const { userInfo, userSettings } = (yield select((_state: AppState) => _state.authState)) as AuthState;

    let designBlocksQuery = 'query=category!="Manual Blocks"';
    // Filter design blocks by global workspaces
    if (window.location.host in GROUPS_FILTERING_LUT) {
      designBlocksQuery = combineSearchQueries(
        '',
        designBlocksQuery,
        false,
        userInfo?._id,
        userSettings?.preferredWorkspaces,
      );
    }

    if (userInfo?.isAnonymousUser) {
      const canvasData = getLocalCanvasByProjectId(id);

      const libraryRes = (yield call(() =>
        axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}?${designBlocksQuery}`, 2)),
      )) as AxiosResponse<Array<Raw.IDesignBlock>>;

      yield put(
        getDesignCanvasSuccess({
          id,
          graph: canvasData,
          library: libraryRes.data.map(serializeDesignBlock),
        }),
      );

      const { pagination } = (yield select((_state: AppState) => _state.designBlocksState)) as DesignBlocksState;
      yield put(getAllDesignBlocksSuccessAction(pagination, libraryRes.data.map(serializeDesignBlock)));
    } else {
      // NOTE: For Version 2 Canvas manage rappid toJSON based payload and endpoint
      const isVersion2 = version === '2';

      const designEndpoint = isVersion2
        ? apiUri(`/dataservice/rappid-canvas/?query=project_id="${id}"&fields&pg_len=1`, 2)
        : apiUri(`/dataservice/compiler/design-canvas/${id}`, 2);

      const designRes = (yield call(() =>
        axiosInstance.get(designEndpoint).catch((err) => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(err);
          }
          // NOTE: Don't throw if no design as on new canvas it won't be
          return undefined;
        }),
      )) as AxiosResponse | undefined;

      const libraryRes = (yield call(() =>
        axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_DESIGN_BLOCKS}?${designBlocksQuery}`, 2)),
      )) as AxiosResponse<Array<Raw.IDesignBlock>>;

      const designData = (designRes?.data || {}) as Partial<RawDesignCanvas.Payload>;

      const canvasData = isVersion2 ? undefined : designData;

      const graphData = isVersion2
        ? (designData as Array<RawRappidDesignCanvas.RappidPayload | undefined>)[0]
        : undefined;

      yield put(
        getDesignCanvasSuccess({
          id: graphData?.id,
          graph: graphData?.graph?.map(serializeRappidDesignCanvasBlock),
          canvas: {
            ...(canvasData || {}),
            blocks: canvasData?.blocks?.map(serializeDesignCanvasBlock) || [],
          },
          library: libraryRes.data.map(serializeDesignBlock),
        }),
      );

      const { pagination } = (yield select((_state: AppState) => _state.designBlocksState)) as DesignBlocksState;
      dataLayer.saveDesignCanvasData(id, graphData?.graph || []);
      yield put(getAllDesignBlocksSuccessAction(pagination, libraryRes.data.map(serializeDesignBlock)));
    }
  } catch (error) {
    yield put(getDesignCanvasError(getErrorMessage(error) || 'Fetching Design Canvas failed.'));
  }
}

export function* getWatcher(): Generator<ForkEffect<never>> {
  // TODO: [WAPP-928] Deprecate Canvas v1
  // Then on takeLatest
  yield takeEvery(SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS, getSaga);
}
