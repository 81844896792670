import { AxiosResponse } from 'axios';
import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  ProjectsActionTypes,
  ProjectsGetByIdAction,
  ProjectsGetByIdErrorAction,
  ProjectsGetByIdSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  getProjectByIdErrorAction,
  getProjectByIdSuccessAction,
  DS_PROJECTS_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeProject } from 'utils/projectSerializer';
import { getLocalProjectById } from 'services/localProjectsService';
import { getProjectGeneralSettings } from 'state_management/actions/projectGeneralSettings/projectGeneralSettingsActions';
import { ProjectGeneralSettingsAction } from 'state_management/actions/projectGeneralSettings/ActionTypes';

export function* projectGetByIdSaga(
  action: ProjectsGetByIdAction,
): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<ProjectsGetByIdSuccessAction | ProjectsGetByIdErrorAction | ProjectGeneralSettingsAction>
> {
  try {
    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
      projectGeneralSettingsState: { requestState: projectSettingsRequestState },
    } = (yield select((state: AppState) => state)) as AppState;

    const id = action.payload;

    if (projectSettingsRequestState === 'idle') {
      yield put(getProjectGeneralSettings());
    }

    if (isAnonymousUser) {
      const _project = getLocalProjectById(id);

      yield put(getProjectByIdSuccessAction(_project));
    } else {
      const res = yield call(() => axiosInstance.get(apiUri(`${DS_PROJECTS_ENDPOINT}/${id}`, 2)));

      yield put(getProjectByIdSuccessAction(serializeProject((res as AxiosResponse<Raw.Project>).data)));
    }
  } catch (error) {
    yield put(getProjectByIdErrorAction(getErrorMessage(error) || 'Fetching Project failed'));
  }
}

export function* projectGetByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECTS_GET_BY_ID, projectGetByIdSaga);
}
