/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeEvery,
  call,
  all,
  AllEffect,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { IFileUpload } from 'models/IFileUpload';
import {
  ENDPOINT_COMPONENTS_FILES,
  uploadComponentCadFileFailAction,
  uploadComponentCadFileSuccessAction,
  uploadComponentDocFileFailAction,
  uploadComponentDocFileSuccessAction,
} from 'state_management/actions/components/componentsActions';
import {
  ComponentActionTypes,
  UploadComponentCadFileFailAction,
  UploadComponentCadFilesAction,
  UploadComponentCadFileSuccessAction,
  UploadComponentDocFileFailAction,
  UploadComponentDocFilesAction,
  UploadComponentDocFileSuccessAction,
} from 'state_management/actions/components/ActionTypes';
import {
  UploadVirtualComponentCadFileFailAction,
  UploadVirtualComponentCadFilesAction,
  UploadVirtualComponentCadFileSuccessAction,
  UploadVirtualComponentDocFileFailAction,
  UploadVirtualComponentDocFilesAction,
  UploadVirtualComponentDocFileSuccessAction,
  VirtualComponentsActionTypes,
} from 'state_management/actions/virtualComponents/ActionTypes';
import {
  uploadVirtualComponentCadFileFailAction,
  uploadVirtualComponentCadFileSuccessAction,
  uploadVirtualComponentDocFileFailAction,
  uploadVirtualComponentDocFileSuccessAction,
} from 'state_management/actions/virtualComponents/virtualComponentsActions';
import { AppState } from 'state_management/AppState';

const actionsByReducer = {
  [ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE]: {
    success: uploadComponentCadFileSuccessAction,
    fail: uploadComponentCadFileFailAction,
    filesEndpoint: ENDPOINT_COMPONENTS_FILES,
  },
  [ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE]: {
    success: uploadComponentDocFileSuccessAction,
    fail: uploadComponentDocFileFailAction,
    filesEndpoint: ENDPOINT_COMPONENTS_FILES,
  },
  [VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE]: {
    success: uploadVirtualComponentCadFileSuccessAction,
    fail: uploadVirtualComponentCadFileFailAction,
    filesEndpoint: ENDPOINT_COMPONENTS_FILES,
  },
  [VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE]: {
    success: uploadVirtualComponentDocFileSuccessAction,
    fail: uploadVirtualComponentDocFileFailAction,
    filesEndpoint: ENDPOINT_COMPONENTS_FILES,
  },
};

export function* uploadFileSaga(
  fileUpload: IFileUpload,
  reducerType: keyof typeof actionsByReducer,
): Generator<
  | TakeEffect
  | CallEffect<any>
  | SelectEffect
  | PutEffect<
      | UploadComponentCadFileSuccessAction
      | UploadComponentCadFileFailAction
      | UploadComponentDocFileSuccessAction
      | UploadComponentDocFileFailAction
      | UploadVirtualComponentCadFileSuccessAction
      | UploadVirtualComponentCadFileFailAction
      | UploadVirtualComponentDocFileSuccessAction
      | UploadVirtualComponentDocFileFailAction
    >
> {
  const {
    authState: {
      featurePermissions: { USE_NEW_PROXY_SERVICE },
    },
  } = (yield select((state: AppState) => state)) as AppState;

  const actions = actionsByReducer[reducerType];
  try {
    const usedAs: Array<string> = [];
    const cad = '';

    const postResponse = (yield call(() =>
      axiosInstance.post(
        apiUri(`/dataservice/${actions.filesEndpoint}`, 2, USE_NEW_PROXY_SERVICE.read === true),
        { display_name: fileUpload.name, content_type: fileUpload.file?.type, type: usedAs[0], extra: { cad } },
        {
          withCredentials: true,
        },
      ),
    )) as AxiosResponse;

    const { id, display_name: displayName, created_at: createdAt } = postResponse.data;

    const fileData = new FormData();
    fileData.append(fileUpload.name, fileUpload.file as File);

    const fileUploadResponse = (yield call(() =>
      axiosInstance.post(
        apiUri(
          `/dataservice/${actions.filesEndpoint}/${id}/upload?fileContentData=true`,
          2,
          USE_NEW_PROXY_SERVICE.read === true,
        ),
        fileData,
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ),
    )) as AxiosResponse;

    yield put(
      actions.success({
        id,
        name: displayName,
        state: fileUploadResponse.data.key ? 'success' : 'error',
        error: fileUploadResponse.data.key ? '' : 'Something went wrong',
        usedAs,
        cad,
        createdAt,
      } as IFileUpload),
    );
  } catch (error) {
    yield put(
      actions.fail({
        ...fileUpload,
        state: 'error',
        error: (error as AxiosError)?.response?.data?.errorMessage || 'Something went wrong',
      }),
    );
  }
}

export function* uploadFilesSaga(
  action:
    | UploadComponentCadFilesAction
    | UploadComponentDocFilesAction
    | UploadVirtualComponentCadFilesAction
    | UploadVirtualComponentDocFilesAction,
): Generator<AllEffect<any>> {
  const newFiles = action.payload.filter((f) => f.state === 'idle');

  yield all(newFiles.map((f) => uploadFileSaga(f, action.type)));
}

export function* uploadFilesSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(
    [
      ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE,
      ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE,
      VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE,
      VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE,
    ],
    uploadFilesSaga,
  );
}
