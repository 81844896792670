export const downloadFile = async (link: string, actionType: 'download' | 'redirect'): Promise<void> => {
  const linkElement = document.createElement('a');

  linkElement.style.display = 'none';
  linkElement.href = link;

  if (actionType === 'download') {
    linkElement.download = link;
  } else {
    linkElement.target = '_blank';
  }

  linkElement.click();
};
