import { ProjectsActions, ProjectsActionTypes } from 'state_management/actions/projects/ActionTypes';
import { DashboardDisplayModes, DashboardProjectTypes, DashboardSortingTypes, ProjectsState } from './Modals';
import { getProjectCompiledState } from './utils/projectCompileState';
import { replaceProject } from './utils/replaceProject';

export const initialProjectsState: ProjectsState = {
  currentProject: {
    numberOfLayers: undefined,
    workspaces: [],
    routing: false,
    id: '',
    userId: '',
    title: '',
    image: '',
    description: '',
    projectType: DashboardProjectTypes.PRIVATE,
    shareType: [DashboardProjectTypes.PRIVATE],
    isRefDesign: false,
    optimizations: [],
    downloadFiles: [],
    canvasStates: {
      designCanvas: 'enabled',
      bomCanvas: 'disabled',
      boardCanvas: 'disabled',
    },
    cadFilesState: {
      sch: 'disabled',
      brd: 'disabled',
      bom: 'disabled',
      pdf: 'disabled',
    },
    compilationStates: {
      design: 'disabled',
      board: 'disabled',
    },
    compilerState: 'EMPTY',
    cadTool: '',
    pcbTechnology: '',
    bomType: undefined,
    createdAt: '',
    updatedAt: '',
    issues: { errors: [], warnings: [] },
  },
  projectsGetRequest: 'idle',
  projectsGetRequestError: '',
  projectsCloneRequest: 'idle',
  projectsCloneRequestError: '',
  projectStateRequestState: 'idle',
  projectStateRequestError: '',
  projectCompileIssuesRequest: 'idle',
  projectCompileIssuesRequestError: '',
  boardCompileRequest: 'idle',
  boardCompileRequestError: '',
  designCompileRequest: 'success',
  designCompileRequestError: '',
  projectFileDownloadSchematics: 'idle',
  projectFileDownloadSchematicsError: '',
  projectFileDownloadLayout: 'idle',
  projectFileDownloadLayoutError: '',
  projectFileDownloadBom: 'idle',
  projectFileDownloadBomError: '',
  projectFileDownloadPdf: 'idle',
  projectFileDownloadPdfError: '',
  projectImageUpdateRequest: 'idle',
  projectImageUpdateRequestError: '',
  projectUpdateRequest: 'idle',
  projectUpdateRequestError: '',
  projectImportRequest: 'idle',
  projectImportError: '',
  projects: [],
  optimizationOptions: [],
  projectsGetAllRequest: 'idle',
  projectsGetAllRequestError: '',
  viewOptions: {
    displayMode: DashboardDisplayModes.GRID,
    sortType: DashboardSortingTypes.LAST_CREATED,
    sharingFilter: DashboardProjectTypes.ALL,
  },
  pagination: {
    page: 0,
    perPage: 20,
    search: '',
    total: 20,
  },
  waitingPaymentForFiles: [],
};

export const projectsReducer = (
  state: ProjectsState = initialProjectsState,
  action: ProjectsActions,
): ProjectsState => {
  switch (action.type) {
    case ProjectsActionTypes.PROJECTS_RESET:
      return {
        ...initialProjectsState,
      };
    case ProjectsActionTypes.PROJECTS_UPDATE_SORTING_TYPE:
      return {
        ...state,
        viewOptions: {
          ...state.viewOptions,
          sortType: action.payload,
        },
      };
    case ProjectsActionTypes.PROJECTS_UPDATE_DISPLAY_MODE:
      return {
        ...state,
        viewOptions: {
          ...state.viewOptions,
          displayMode: action.payload,
        },
      };
    case ProjectsActionTypes.PROJECTS_UPDATE_SHARING_FILTER:
      return {
        ...state,
        viewOptions: {
          ...state.viewOptions,
          sharingFilter: action.payload,
        },
      };
    case ProjectsActionTypes.PROJECT_STATE_GET:
      return {
        ...state,
        projectStateRequestState: 'loading',
        projectStateRequestError: '',
      };
    case ProjectsActionTypes.PROJECT_STATE_GET_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          canvasStates: {
            ...state.currentProject.canvasStates,
            ...action.payload.canvasStates,
          },
          cadFilesState: {
            ...state.currentProject.cadFilesState,
            ...action.payload.cadFilesState,
          },
          compilerState: action.payload.compilerState,
        },
        projectStateRequestState: 'success',
        projectStateRequestError: '',
        ...action.payload.projectCompiledState,
      };
    case ProjectsActionTypes.PROJECT_STATE_GET_FAIL:
      return {
        ...state,
        projectStateRequestState: 'error',
        projectStateRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_SET_CURRENT:
      return {
        ...state,
        boardCompileRequest: 'idle',
        boardCompileRequestError: '',
        designCompileRequest: 'idle',
        designCompileRequestError: '',
        currentProject: {
          ...initialProjectsState.currentProject,
          ...action.payload,
        },
      };
    case ProjectsActionTypes.PROJECTS_GET_BY_ID:
      return {
        ...state,
        projectsGetRequest: 'idle',
      };
    case ProjectsActionTypes.PROJECTS_GET_BY_ID_SUCCESS:
      return {
        ...state,
        projectsGetRequest: 'success',
        currentProject: {
          ...state.currentProject,
          ...action.payload,
        },
        projects: replaceProject(state.projects, action.payload),
        ...getProjectCompiledState(action.payload.compilationStates, undefined),
      };
    case ProjectsActionTypes.PROJECTS_GET_BY_ID_FAIL:
      return {
        ...state,
        projectsGetRequest: 'error',
        projectsGetRequestError: action.payload,
      };
    case ProjectsActionTypes.PROJECTS_CLONE:
      return {
        ...state,
        projectsCloneRequest: 'loading',
        projectsCloneRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_CLONE_SUCCESS:
      return {
        ...state,
        projectsCloneRequest: 'success',
        projectsCloneRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_CLONE_FAIL:
      return {
        ...state,
        projectsCloneRequest: 'error',
        projectsCloneRequestError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_SAVE_SUCCESS:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          ...action.payload,
          cadTool: action.payload.cadTool || state.currentProject.cadTool,
        },
        projects: replaceProject(state.projects, action.payload),
        ...getProjectCompiledState(action.payload.compilationStates, undefined),
      };
    case ProjectsActionTypes.PROJECTS_IMAGE_UPDATE:
      return {
        ...state,
        projectImageUpdateRequest: 'loading',
        projectImageUpdateRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_SUCCESS:
      return {
        ...state,
        projectImageUpdateRequest: 'success',
        projectImageUpdateRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_FAIL:
      return {
        ...state,
        projectImageUpdateRequest: 'error',
        projectImageUpdateRequestError: action.payload,
      };
    case ProjectsActionTypes.PROJECTS_UPDATE:
      return {
        ...state,
        projectUpdateRequest: 'loading',
        projectUpdateRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_UPDATE_SUCCESS:
      return {
        ...state,
        projectUpdateRequest: 'success',
        projectUpdateRequestError: '',
        currentProject: {
          ...state.currentProject,
          ...action.payload,
        },
      };
    case ProjectsActionTypes.PROJECTS_UPDATE_FAIL:
      return {
        ...state,
        projectUpdateRequest: 'error',
        projectUpdateRequestError: action.payload,
      };
    case ProjectsActionTypes.PROJECTS_GET_ALL:
      return {
        ...state,
        projectsGetAllRequest: 'loading',
        projectsGetAllRequestError: '',
      };
    case ProjectsActionTypes.PROJECTS_GET_ALL_SUCCESS:
      return {
        ...state,
        projects: action.payload.data,
        projectsGetAllRequest: 'success',
        projectsGetAllRequestError: '',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case ProjectsActionTypes.PROJECTS_GET_ALL_FAIL:
      return {
        ...state,
        projectsGetAllRequest: 'error',
        projectsGetAllRequestError: action.payload || 'Fetching projects failed. Please try again...',
      };
    case ProjectsActionTypes.PROJECT_CANVAS_COMPILE:
      return {
        ...state,
        boardCompileRequest: action.payload.canvas === 'board' ? 'loading' : 'idle',
        boardCompileRequestError: '',
        designCompileRequest: action.payload.canvas === 'design' ? 'loading' : 'idle',
        designCompileRequestError: '',
      };
    case ProjectsActionTypes.PROJECT_CANVAS_COMPILE_SUCCESS:
      return {
        ...state,
        // NOTE: If Board is success then Design has to be success else we couldn't reach Board
        // If Design is Success, as we have to do is not set Board to idle => so we can set it to success
        boardCompileRequest: 'success',
        boardCompileRequestError: '',
        designCompileRequest: 'success',
        designCompileRequestError: '',
      };
    case ProjectsActionTypes.PROJECT_CANVAS_COMPILE_FAIL:
      return {
        ...state,
        ...action.payload,
      };
    case ProjectsActionTypes.PROJECT_COMPILE_ISSUES:
      return {
        ...state,
        projectCompileIssuesRequest: 'loading',
        projectCompileIssuesRequestError: '',
      };
    case ProjectsActionTypes.PROJECT_COMPILE_ISSUES_SUCCESS:
      return {
        ...state,
        projectCompileIssuesRequest: 'success',
        projectCompileIssuesRequestError: '',
        currentProject: {
          ...state.currentProject,
          issues: action.payload,
        },
      };
    case ProjectsActionTypes.PROJECT_COMPILE_ISSUES_FAIL:
      return {
        ...state,
        projectCompileIssuesRequest: 'error',
        projectCompileIssuesRequestError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS:
      return {
        ...state,
        projectFileDownloadSchematics: 'loading',
        projectFileDownloadSchematicsError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_SUCCESS:
      return {
        ...state,
        projectFileDownloadSchematics: 'success',
        projectFileDownloadSchematicsError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL:
      return {
        ...state,
        projectFileDownloadSchematics: 'error',
        projectFileDownloadSchematicsError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_PAYMENT:
      return {
        ...state,
        projectFileDownloadSchematics: 'payment',
        waitingPaymentForFiles: ['schematics'],
        projectFileDownloadSchematicsError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT:
      return {
        ...state,
        projectFileDownloadLayout: 'loading',
        projectFileDownloadLayoutError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_SUCCESS:
      return {
        ...state,
        projectFileDownloadLayout: 'success',
        projectFileDownloadLayoutError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_PAYMENT:
      return {
        ...state,
        projectFileDownloadLayout: 'payment',
        projectFileDownloadLayoutError: '',
        waitingPaymentForFiles: ['layout'],
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_FAIL:
      return {
        ...state,
        projectFileDownloadLayout: 'error',
        projectFileDownloadLayoutError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_CLEAR_PAYMENT:
      return {
        ...state,
        waitingPaymentForFiles: [],
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM:
      return {
        ...state,
        projectFileDownloadBom: 'loading',
        projectFileDownloadBomError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_SUCCESS:
      return {
        ...state,
        projectFileDownloadBom: 'success',
        projectFileDownloadBomError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_FAIL:
      return {
        ...state,
        projectFileDownloadBom: 'error',
        projectFileDownloadBomError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF:
      return {
        ...state,
        projectFileDownloadPdf: 'loading',
        projectFileDownloadPdfError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_SUCCESS:
      return {
        ...state,
        projectFileDownloadPdf: 'success',
        projectFileDownloadPdfError: '',
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_FAIL:
      return {
        ...state,
        projectFileDownloadPdf: 'error',
        projectFileDownloadPdfError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_IMPORT:
      return {
        ...state,
        projectImportRequest: 'loading',
        projectImportError: '',
      };
    case ProjectsActionTypes.PROJECT_IMPORT_SUCCESS:
      return {
        ...state,
        projectImportRequest: 'success',
        projectImportError: '',
        currentProject: action.payload,
      };
    case ProjectsActionTypes.PROJECT_IMPORT_FAIL:
      return {
        ...state,
        projectImportRequest: 'error',
        projectImportError: action.payload,
      };
    case ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_CANCEL:
      return {
        ...state,
        projectFileDownloadSchematics: 'idle',
        projectFileDownloadSchematicsError: '',
        projectFileDownloadLayout: 'idle',
        projectFileDownloadLayoutError: '',
        projectFileDownloadBom: 'idle',
        projectFileDownloadBomError: '',
      };
    case ProjectsActionTypes.PROJECTS_CLEAR:
      return {
        ...state,
        projectUpdateRequest: action.payload.includes('projectUpdateRequest') ? 'idle' : state.projectUpdateRequest,
        projectUpdateRequestError: action.payload.includes('projectUpdateRequestError')
          ? ''
          : state.projectUpdateRequestError,
      };
    case ProjectsActionTypes.PDF_DOWNLOAD_ICON_LOADING_FAIL:
      return {
        ...state,
        projectFileDownloadPdf: 'error',
        projectFileDownloadPdfError: '',
      };
    case ProjectsActionTypes.BOM_DOWNLOAD_ICON_LOADING_FAIL:
      return {
        ...state,
        projectFileDownloadBom: 'error',
        projectFileDownloadBomError: '',
      };
    case ProjectsActionTypes.SCHEMATICS_DOWNLOAD_ICON_LOADING_FAIL:
      return {
        ...state,
        projectFileDownloadSchematics: 'error',
        projectFileDownloadSchematicsError: '',
      };
    case ProjectsActionTypes.LAYOUT_DOWNLOAD_ICON_LOADING_FAIL:
      return {
        ...state,
        projectFileDownloadLayout: 'error',
        projectFileDownloadLayoutError: '',
      };
    default:
      return state;
  }
};
