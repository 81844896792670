import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  SelectEffect,
  call,
  select,
} from 'redux-saga/effects';

import { AxiosResponse } from 'axios';

import {
  ChangeLogActionTypes,
  GetAllChangeLogAction,
  GetAllChangeLogSuccessAction,
  GetAllChangeLogErrorAction,
} from 'state_management/actions/changeLog/ActionTypes';

import { getErrorMessage } from 'utils/getErrorMessage';
import {
  getAllChangeLogSuccessAction,
  getAllChangeLogErrorAction,
} from 'state_management/actions/changeLog/changeLogActions';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { apiUri } from 'services/main_app';
import { axiosInstance } from 'services/dataService';
import { serializeChangesRequests } from 'utils/changesRequestSerializer';
import { ChangeLogState } from 'state_management/reducers/changeLog/Modals';
import { AppState } from 'state_management/AppState';
import { SERIALIZERS } from '../changesRequest/getByIdSaga';

export function* getAllSaga(
  action: GetAllChangeLogAction,
): Generator<
  | PutEffect<GetAllChangeLogAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllChangeLogSuccessAction | GetAllChangeLogErrorAction>
> {
  try {
    const { pagination } = (yield select((state: AppState) => state.changeLogState)) as ChangeLogState;

    const { page = pagination.page, perPage = pagination.perPage, clResourceId, clResourceType } = action.payload;

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(
          `/dataservice/${
            SERIALIZERS[clResourceType as keyof typeof SERIALIZERS].endpoint
          }/${clResourceId}/changelog?pg=${page}&pg_len=${perPage}`,
          2,
        ),
      ),
    )) as AxiosResponse;

    const _pagination = { ...getPaginationFromResponse(res), search: '' || '' };

    yield put(getAllChangeLogSuccessAction(_pagination, res.data.map(serializeChangesRequests)));
  } catch (error) {
    yield put(getAllChangeLogErrorAction(getErrorMessage(error) || 'Fetching change log failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ChangeLogActionTypes.GET_ALL_CHANGE_LOG, getAllSaga);
}
