import { retry, PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest } from 'redux-saga/effects';

import {
  GetListNetsAction,
  GetListNetsErrorAction,
  GetListNetsSuccessAction,
  MachineLearningActionTypes,
} from 'state_management/actions/machineLearning/ActionTypes';
import { AxiosResponse } from 'axios';
import { getListNetsSuccess, getListNetsFail } from 'state_management/actions/machineLearning/machineLearningActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  IImplementationSchematicInterface,
  ILayerSignalType,
  LayerSignalValue,
} from 'state_management/reducers/implementations/Modals';
import { getErrorMessage } from 'utils/getErrorMessage';

export function* predictedInterfacesPoolingSaga(
  action: GetListNetsAction,
): Generator<
  | PutEffect<GetListNetsAction>
  | TakeEffect
  | CallEffect<AxiosResponse<any>>
  | PutEffect<GetListNetsSuccessAction | GetListNetsErrorAction>
> {
  try {
    const response = yield retry(5, 2000, () =>
      axiosInstance.get(apiUri(`/machine-learning/schematic/${action.payload.schematicFileId}`, 2)),
    );

    const { withPrediction } = action.payload;

    // NOTE: API Ports are Interfaces and Signals are Ports
    const interfaces = withPrediction
      ? (((response as AxiosResponse).data as Raw.PredictedInterfacesResponse).ports.map((p) => ({
          id: p.id,
          portType: p.port_type === 'UNKNOWN' ? '' : p.port_type,
          direction: p.direction,
          mlNotice: true,
          ports: p.signals?.map((s) => ({ name: s.signal_name, type: s.signal_type })) || [],
        })) as Array<IImplementationSchematicInterface>)
      : undefined;

    const rawLayerSignalType = ((response as AxiosResponse).data as Raw.PredictedInterfacesResponse).layer_signals;

    const layerSignalType = withPrediction
      ? Object.entries(rawLayerSignalType)?.reduce(
          (res, [key, values]) =>
            values?.reduce(
              (_res: ILayerSignalType, value: LayerSignalValue) => ({ ..._res, [value]: key as LayerSignalValue }),
              res,
            ),
          {},
        )
      : undefined;

    const listNets = ((response as AxiosResponse).data as Raw.PredictedInterfacesResponse).list_nets;

    yield put(getListNetsSuccess({ interfaces, layerSignalType, listNets }));
  } catch (error) {
    yield put(getListNetsFail(getErrorMessage(error) || 'Fetching list nets failed...'));
  }
}

export function* predictedInterfacesSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(MachineLearningActionTypes.GET_LIST_NETS, predictedInterfacesPoolingSaga);
}
