import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getExportTemplateByIdSuccess,
  getExportTemplateByIdError,
  ENDPOINT_EXPORT_TEMPLATES,
} from 'state_management/actions/exportTemplates/exportTemplatesActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetExportTemplateByIdErrorAction,
  GetExportTemplateByIdSuccessAction,
  GetExportTemplateByIdAction,
  ExportTemplatesActionTypes,
} from 'state_management/actions/exportTemplates/ActionTypes';
import { IExportTemplate } from 'state_management/reducers/exportTemplates/Modals';
import { serializeExportTemplate } from 'utils/exportTemplatesSerializer';

export function* getByIdSaga(
  action: GetExportTemplateByIdAction,
): Generator<
  | PutEffect<GetExportTemplateByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetExportTemplateByIdSuccessAction | GetExportTemplateByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_EXPORT_TEMPLATES}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedExportTemplate: IExportTemplate = serializeExportTemplate(res.data);

    yield put(getExportTemplateByIdSuccess(parsedExportTemplate));
  } catch (error) {
    yield put(
      getExportTemplateByIdError(getErrorMessage(error) || 'Fetching export templates failed. Please try again...'),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID, getByIdSaga);
}
