import { IDownloadFileType } from 'state_management/reducers/projects/Modals';
import {
  ProjectDownloadFileBomErrorAction,
  ProjectDownloadFileLayoutErrorAction,
  ProjectDownloadFilePdfErrorAction,
  ProjectDownloadFileSchematicsErrorAction,
} from '../projects/ActionTypes';

export enum ErmisActionTypes {
  GET_ERMIS_EXCEPTIONS = 'GET_ERMIS_EXCEPTIONS',
}

export type GetErmisExceptionActionPayloadType = {
  defaultError:
    | ProjectDownloadFileSchematicsErrorAction
    | ProjectDownloadFileLayoutErrorAction
    | ProjectDownloadFileBomErrorAction
    | ProjectDownloadFilePdfErrorAction;
  projectId: string;
  fileType: IDownloadFileType;
  cadTool: string;
};

export interface GetErmisExceptionAction {
  type: ErmisActionTypes.GET_ERMIS_EXCEPTIONS;
  payload: GetErmisExceptionActionPayloadType;
}

export type ErmisActions = GetErmisExceptionAction;
