/* Be advised! This util has been created for a very specific scenario. 
   In order to avoid interfering with the Rappid history
   stack the styles are injected into the elements directly
   in an old js coding style.
   Therefore, you are not allowed to use this util, unless, 
   you have a super-clear idea about Rappid and canvas architecture.
   If you really insist to use it, assign your MR to the Supernova Front-End developer,
   and make sure you are not losing the canvas change history .
*/

import { DEFAULT_THEME } from 'theme';

const style = {
  link: {
    errorColor: DEFAULT_THEME.supernova.palette.error,
  },
  block: {
    errorColor: DEFAULT_THEME.supernova.palette.error,
  },
};

const findLink = (linkId: string): HTMLElement | undefined => {
  const linkElement = document.querySelector(`[model-id="${linkId}"]`);
  const lineSvgElement = linkElement?.childNodes[1];
  const htmlElement = lineSvgElement as HTMLElement;
  return htmlElement;
};

const findBlock = (blockId: string): HTMLElement | undefined => {
  const blockElement = document.querySelector(`[model-id="${blockId}"]`);
  const innerGElement = blockElement?.childNodes[0];
  return Array.from(innerGElement?.childNodes || []).find((node) => node.nodeName === 'rect') as HTMLElement;
};

export const isBlock = (itemId: string): boolean => {
  const item = findBlock(itemId);
  return item?.nodeName === 'rect';
};

export const isLink = (itemId: string): boolean => {
  const item = findLink(itemId);
  return item?.nodeName === 'path';
};

const findExternalPort = (externalPortId: string): HTMLElement | undefined => {
  const externalPortElement = document.querySelector(`[model-id="${externalPortId}"]`);
  const innerGElement = externalPortElement?.childNodes[0];
  const rect = innerGElement?.childNodes[1] as HTMLElement;
  return rect;
};

export const highlightCorruptedExternalPort = (externalPortId: string): void => {
  const externalPort = findExternalPort(externalPortId);
  sessionStorage.setItem(externalPortId, externalPort?.getAttribute('stroke') || '');
  externalPort?.setAttribute('stroke', style.block.errorColor);
};

const resetExternalPortStyle = (externalPortId: string): void => {
  const colorCode = sessionStorage.getItem(externalPortId);
  if (colorCode) {
    const externalPort = findExternalPort(externalPortId);
    externalPort?.setAttribute('stroke', colorCode);
  }
};

export const resetExternalPortsStyle = (externalPorts: Array<string>): void => {
  (externalPorts || []).forEach((ep) => {
    resetExternalPortStyle(ep);
  });
};

export const highlightCorruptedBlock = (blockId: string): void => {
  const block = findBlock(blockId);
  sessionStorage.setItem(blockId, block?.getAttribute('stroke') || '');
  if (block) {
    block.setAttribute('stroke', style.block.errorColor);
    block.style.strokeWidth = '12px';
    block.style.strokeOpacity = '0.4';
  }
};

export const highlightCorruptedLink = (linkId: string): void => {
  const link = findLink(linkId);
  sessionStorage.setItem(linkId, link?.getAttribute('stroke') || '');
  if (link) {
    link.setAttribute('stroke', style.link.errorColor);
    link.style.strokeWidth = '12px';
    link.style.strokeOpacity = '0.4';
  }
};

export const resetLinkStyle = (linkId: string): void => {
  const colorCode = sessionStorage.getItem(linkId);
  if (colorCode) {
    const link = findLink(linkId);
    if (link) {
      link.setAttribute('stroke', colorCode);
      link.style.strokeWidth = '4px';
      link.style.strokeOpacity = '1';
    }
  }
};

export const resetBlockStyle = (blockId: string): void => {
  const colorCode = sessionStorage.getItem(blockId);
  if (colorCode) {
    const block = findBlock(blockId);
    if (block) {
      block.setAttribute('stroke', colorCode);
      block.style.strokeWidth = '4px';
      block.style.strokeOpacity = '1';
    }
  }
};

export const resetBlocksStyle = (blocks: Array<string>): void => {
  (blocks || []).forEach((b) => {
    resetBlockStyle(b);
  });
};

export const resetLinksStyle = (links: Array<string>): void => {
  (links || []).forEach((l) => {
    resetLinkStyle(l);
  });
};
