import { connect } from 'react-redux';

import { AppState } from 'state_management/AppState';

import WelcomeDialog from './WelcomeDialog';

import { IProps } from './IProps';

export default connect(
  (state: AppState): Omit<IProps, 'dispatch'> => ({
    featurePermissions: state.authState.featurePermissions,
    welcomeAt: state.unboxingState.welcomeAt,
    getWelcomeAtState: state.unboxingState.getWelcomeAtState,
    updateWelcomeAtState: state.unboxingState.updateWelcomeAtState,
  }),
)(WelcomeDialog);
