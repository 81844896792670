import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  ManufacturersActionTypes,
  GetAllManufacturersAction,
  GetAllManufacturersErrorAction,
  GetAllManufacturersSuccessAction,
} from 'state_management/actions/manufacturers/ActionTypes';

import {
  getAllManufacturersSuccessAction,
  getAllManufacturersErrorAction,
  ENDPOINT_MANUFACTURERS,
} from 'state_management/actions/manufacturers/manufacturersActions';
import { AppState } from 'state_management/AppState';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeManufacturer } from 'utils/manufacturersSerializer';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';

export function* getAllSaga(
  action: GetAllManufacturersAction,
): Generator<
  | PutEffect<GetAllManufacturersAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllManufacturersSuccessAction | GetAllManufacturersErrorAction>
> {
  try {
    const {
      manufacturersState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const isPaginatedAction = action.payload.queryType === 'paginated';

    const manufacturersSearchSchema: TypeSearch = {
      name: 'value',
      short_description: 'value',
      full_info: 'value',
      website: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), manufacturersSearchSchema),
      false,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const query = isPaginatedAction ? `?pg=${page}&pg_len=${perPage}&${searchQuery}` : `?${searchQuery}`;

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_MANUFACTURERS}${query}`, 2)),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllManufacturersSuccessAction(_pagination, res.data.map(serializeManufacturer)));
  } catch (error) {
    yield put(getAllManufacturersErrorAction(getErrorMessage(error) || 'Fetching manufacturers failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ManufacturersActionTypes.GET_ALL_MANUFACTURERS, getAllSaga);
}
