import { PopoverStyles, DOMLocation } from '../Modals';

const horizontalStyles = (
  left: number,
  halfAnchorWidth: number,
  horizontalAdjustToolTip: number,
  halfContainerWidth: number,
  viewPortWidth: number,
  right: number,
  horizontalOffset: string,
): {
  horizontalLocation: 'left' | 'right';
  centerContainerFromLeft?: boolean;
  centerContainerFromRight?: boolean;
  containerHorizontalValue: string;
} => {
  if (horizontalOffset) {
    return {
      horizontalLocation: 'left',
      containerHorizontalValue: horizontalOffset,
    };
  }

  const tempLeft = left + halfAnchorWidth;
  // `horizontalAdjustToolTip` to align the TIP is only need if we are not Center
  const tempLeftCenter = tempLeft - halfContainerWidth;

  // We got enough room on Left to Adjust Half container on Left from current Position
  const centerContainerFromLeft = tempLeft > halfContainerWidth;

  const positionFromLeft = centerContainerFromLeft ? tempLeftCenter : tempLeft - horizontalAdjustToolTip;

  const tempRight = viewPortWidth - right;

  // `horizontalAdjustToolTip` to align the TIP is only need if we are not Center
  const tempRightCenter = tempRight - halfContainerWidth + halfAnchorWidth;

  // We got enough room on Right to Adjust Half container on Right from current Position
  const centerContainerFromRight = tempRight > halfContainerWidth;

  const positionFromRight = centerContainerFromRight ? tempRightCenter : tempRight + horizontalAdjustToolTip;

  const horizontalLocation = left > viewPortWidth / 2 ? 'right' : 'left';

  const containerHorizontalValue = horizontalLocation === 'left' ? `${positionFromLeft}px` : `${positionFromRight}px`;

  return { horizontalLocation, centerContainerFromLeft, centerContainerFromRight, containerHorizontalValue };
};

const verticalStyles = ({
  bottom,
  viewPortHeight,
  verticalAdjustToolTip,
  top,
  verticalOffset,
}: {
  bottom: number;
  viewPortHeight: number;
  verticalAdjustToolTip: number;
  top: number;
  verticalOffset: string;
}): { [key in 'top' | 'bottom']?: string } => {
  if (verticalOffset) {
    return { top: verticalOffset };
  }

  const positionWithTop = { top: `${bottom + verticalAdjustToolTip}px` };
  const positionWithBottom = { bottom: `${viewPortHeight - top + verticalAdjustToolTip}px` };

  const verticalLocation = bottom > viewPortHeight / 2 ? 'above' : 'bellow';

  const containerVerticalValue = verticalLocation === 'above' ? positionWithBottom : positionWithTop;

  return containerVerticalValue;
};

const getToolTipStyles = (
  horizontalLocation: keyof DOMRect,
  toolTipOffset: number,
  toolTipLocation?: DOMLocation,
  centerContainerFromLeft?: boolean,
  centerContainerFromRight?: boolean,
  noTip?: true,
): Partial<DOMRect> => {
  const isTipCenter = horizontalLocation === 'left' ? centerContainerFromLeft : centerContainerFromRight;
  const adjustForToolTipWidthCenter = noTip ? 0 : 10;
  const adjustForToolTipWidth = noTip ? 0 : 18;

  // NOTE: Add more direction/locations when needed.
  // toolTipLocation to css allowable properties (Like on Tooltip on LEFT we can only move up / bottom)
  const toolTipLocationForStyles =
    toolTipLocation && (toolTipLocation === 'left' || toolTipLocation === 'right') ? 'top' : horizontalLocation;

  return {
    [toolTipLocationForStyles]:
      (toolTipOffset && `${toolTipOffset}px`) ||
      (isTipCenter ? `calc(50% - ${adjustForToolTipWidthCenter}px)` : `${adjustForToolTipWidth}px`),
  };
};

export const getPopoverStyles = (
  { left, right, top, bottom, width }: DOMRect,
  viewPortWidth: number,
  viewPortHeight: number,
  containerWidth: number,
  toolTipLocation?: 'left' | 'right' | 'top' | 'bottom',
  horizontalOffset = '',
  verticalOffset = '',
  toolTipOffset = 0,
  noTip: true | undefined = undefined,
): PopoverStyles => {
  const horizontalAdjustToolTip = noTip ? 0 : 25;
  const verticalAdjustToolTip = noTip ? 0 : 12;
  const halfAnchorWidth = width / 2;
  const halfContainerWidth = containerWidth / 2;

  const { horizontalLocation, centerContainerFromLeft, centerContainerFromRight, containerHorizontalValue } =
    horizontalStyles(
      left,
      halfAnchorWidth,
      horizontalAdjustToolTip,
      halfContainerWidth,
      viewPortWidth,
      right,
      horizontalOffset,
    );

  const containerVerticalValue = verticalStyles({
    bottom,
    viewPortHeight,
    verticalAdjustToolTip,
    top,
    verticalOffset,
  });

  const toolTipStyles = getToolTipStyles(
    horizontalLocation,
    toolTipOffset,
    toolTipLocation,
    centerContainerFromLeft,
    centerContainerFromRight,
    noTip,
  );

  return {
    toolTipStyles,
    containerStyles: {
      ...containerVerticalValue,
      ...{ [horizontalLocation]: containerHorizontalValue },
    },
  };
};
