import styled, { ThemeProps } from 'styled-components';
import MUIButton from '@material-ui/core/Button';

import { AppTheme } from 'models/ThemeProps';
import { Colors } from './Modals';

const outlinedButtonStyle = (theme: AppTheme, colors?: Colors): string => `
  background-color: transparent;
  color: ${colors?.accent || theme.palette.accent};
  border-color: ${colors?.borderColor || colors?.accent || theme.palette.accent};
  &:hover {
    background-color: ${colors?.hoverBg || theme.palette.accentHover};
    color: ${colors?.hover || theme.palette.white};
  }
`;

export const CelusButton = styled(MUIButton)<{
  empty: boolean;
  isOnlyIcon: boolean;
  colors?: Colors;
  theme: AppTheme;
}>`
  && {
    line-height: unset;

    ${({ empty, disabled, variant, colors, theme, isOnlyIcon }): string => {
      if (!empty) {
        if (!disabled)
          return `
          ${
            variant === 'outlined'
              ? outlinedButtonStyle(theme, colors)
              : `background-color: ${theme.palette.accent};
          color: ${theme.palette.white};
          border: 1px solid ${theme.palette.accent};
          &:hover {
            background-color: ${theme.palette.accentHover};
          }`
          }
        `;
        return `background-color: ${theme.palette.disabled} !important;
        color: ${theme.palette.white} !important;
        border-color: transparent !important;`;
      }

      if (!disabled) {
        return `background-color: transparent;
                color: ${theme.palette.darkText};
                border-color: transparent;
                &:hover {
                  color: ${theme.palette.accent};
                  background-color: ${isOnlyIcon ? '' : 'transparent !important'};
                }`;
      }

      return `background-color: transparent;
              color: ${theme.palette.disabled};
              border-color: transparent;`;
    }}

    ${({ size, theme }): string =>
      !(size === 'small')
        ? `font-size: ${theme.fontSize.normal};
    padding: 10px ${theme.spacing.large}px;`
        : `font-size: ${theme.fontSize.small};
        padding: ${theme.spacing.small}px ${theme.spacing.medium}px;`}

  ${({ empty }): string => (!empty ? `text-transform : none` : '')}
  ${({ isOnlyIcon }): string =>
      isOnlyIcon
        ? `
    padding: 12px !important;
    min-width: 0 !important;
  `
        : ''}
  }
`;

export const CelusIconWrapper = styled.div<{ isOnlyIcon: boolean; tooltip?: boolean; theme: ThemeProps<AppTheme> }>`
  && {
    display: flex;
    padding-right: ${({ isOnlyIcon, theme }): number => (isOnlyIcon ? 0 : theme.spacing.small)}px;
    position: relative;
  }
`;

export const TooltipInfoContainer = styled.div`
  && {
    margin: 0;
    display: flex;
    align-item: center;
    justify-content: center;
    padding: 6px 12px;
    min-width: 50px;
  }
`;
