import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  select,
  SelectEffect,
  call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  CategoriesActionTypes,
  GetAllCategoriesAction,
  GetAllCategoriesSuccessAction,
  GetAllCategoriesErrorAction,
} from 'state_management/actions/categories/ActionTypes';

import { getErrorMessage } from 'utils/getErrorMessage';
import {
  getAllCategoriesSuccessAction,
  getAllCategoriesErrorAction,
  ENDPOINT_CATEGORIES,
} from 'state_management/actions/categories/categoriesActions';
import { AppState } from 'state_management/AppState';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { combineSearchQueries, getSearchQuery, SpecificationsSchema, TypeSearch } from 'utils/searchHelper';
import { escapeSearch } from 'utils/urlUtils';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { serializeCategories } from 'utils/categoriesSerializer';
import { ICategoriesPagination } from 'state_management/reducers/categories/Modals';

export function* getAllSaga(
  action: GetAllCategoriesAction,
): Generator<
  | PutEffect<GetAllCategoriesAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllCategoriesSuccessAction | GetAllCategoriesErrorAction>
> {
  try {
    const {
      categoriesState: { pagination },
      specificationsState: { specificationsImplementationList },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
      queryType = pagination.queryType,
    } = action.payload;

    const categoriesSearchSchema: TypeSearch = {
      label: 'value',
      description: 'value',
      full_info: 'value',
    };

    const specificationsSchema: SpecificationsSchema = [
      { specDbKey: 'specifications.', specList: specificationsImplementationList },
    ];

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search || ''), categoriesSearchSchema, specificationsSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_CATEGORIES}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2)),
    )) as AxiosResponse;

    const _pagination: ICategoriesPagination = {
      ...getPaginationFromResponse(res),
      search: (queryType !== 'parent' && search) || '',
      queryType,
    };

    yield put(getAllCategoriesSuccessAction(_pagination, res.data.map(serializeCategories)));
  } catch (error) {
    yield put(getAllCategoriesErrorAction(getErrorMessage(error) || 'Fetching categories failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(CategoriesActionTypes.GET_ALL_CATEGORIES, getAllSaga);
}
