import Button from 'components/Button';

import { IProps } from './IProps';

import { Container } from './styles';

const ZoomButtons = ({ onChange }: IProps): JSX.Element => {
  const handleChange = (value: -1 | 0 | 1) => (): void => {
    onChange(value);
  };

  return (
    <Container>
      <Button
        empty
        data-testid="zoom-out"
        iconSize="normal"
        tooltip="Zoom out"
        icon="zoom-out"
        onClick={handleChange(-1)}
      />
      <Button
        empty
        data-testid="fit-to-screen"
        iconSize="normal"
        tooltip="Fit to screen"
        icon="fit-to-screen"
        onClick={handleChange(0)}
      />
      <Button
        empty
        data-testid="zoom-in"
        iconSize="normal"
        tooltip="Zoom in"
        icon="zoom-in"
        onClick={handleChange(1)}
      />
    </Container>
  );
};

export default ZoomButtons;
