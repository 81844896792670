import { IFileUpload } from 'models/IFileUpload';
import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import { IComponents, IComponentWithChildren } from 'state_management/reducers/components/Modals';
import { IImplementationSpecification } from 'state_management/reducers/specifications/Modals';
import { deserializeComponent } from 'utils/componentsSerializer';
import {
  CleanUpComponentLinksAction,
  CleanUpComponentsAction,
  CloneComponentAction,
  ComponentActionTypes,
  CreateComponentAction,
  DeleteComponentAction,
  DeleteComponentCadFileAction,
  DeleteComponentDocFileAction,
  GetAllComponentsAction,
  GetAllComponentsErrorAction,
  GetAllComponentsSuccessAction,
  GetAllForLinkComponentsAction,
  GetAllForLinkComponentsErrorAction,
  GetAllForLinkComponentsSuccessAction,
  GetComponentByIdAction,
  GetComponentByIdErrorAction,
  GetComponentByIdSuccessAction,
  GetComponentDependenciesAction,
  UpdateComponentAction,
  UpdateComponentFileAction,
  UploadComponentCadFileFailAction,
  UploadComponentCadFilesAction,
  UploadComponentCadFileSuccessAction,
  UploadComponentDocFileFailAction,
  UploadComponentDocFilesAction,
  UploadComponentDocFileSuccessAction,
} from './ActionTypes';

export const ENDPOINT_COMPONENTS = 'components';
export const ENDPOINT_COMPONENTS_FILES = 'object-storage/components-files';

export const getAllComponentsAction = (payload: Partial<IPagination> = {}): GetAllComponentsAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: ComponentActionTypes.GET_ALL_COMPONENTS,
});

export const getAllComponentsSuccessAction = (
  pagination: IPagination,
  data: Array<IComponents>,
): GetAllComponentsSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: ComponentActionTypes.GET_ALL_COMPONENTS_SUCCESS,
});

export const getAllComponentsErrorAction = (payload: string): GetAllComponentsErrorAction => ({
  payload,
  type: ComponentActionTypes.GET_ALL_COMPONENTS_FAIL,
});

export const getAllForLinkComponentsAction = (
  payload: Partial<IPagination> & { withResetList: boolean } = { withResetList: false },
): GetAllForLinkComponentsAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS,
});

export const getAllForLinkComponentsSuccessAction = (
  data: Array<IComponentWithChildren>,
  withResetList: boolean,
): GetAllForLinkComponentsSuccessAction => ({
  payload: {
    data,
    withResetList,
  },
  type: ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS_SUCCESS,
});

export const getAllForLinkComponentsErrorAction = (payload: string): GetAllForLinkComponentsErrorAction => ({
  payload,
  type: ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS_FAIL,
});

export const createComponent = (component: IComponents): CreateComponentAction => ({
  type: ComponentActionTypes.CREATE_COMPONENT,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_COMPONENTS}`, 2),
      data: deserializeComponent(component),
    },
  },
});

export const updateComponent = (component: IComponents): UpdateComponentAction => ({
  type: ComponentActionTypes.UPDATE_COMPONENT,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_COMPONENTS}/${component.id}`, 2),
      data: deserializeComponent(component),
    },
  },
});

export const cloneComponent =
  (specificationsImplList?: Array<IImplementationSpecification>) =>
  (component: IComponents): CloneComponentAction => ({
    type: ComponentActionTypes.CLONE_COMPONENT,
    payload: {
      request: {
        method: 'post',
        withCredentials: true,
        url: apiUri(`/macro-resources/${ENDPOINT_COMPONENTS}/${component.id}/clone`, 2),
        data: deserializeComponent(component, false, specificationsImplList),
      },
    },
  });

export const deleteComponent = (component: IComponents): DeleteComponentAction => ({
  type: ComponentActionTypes.DELETE_COMPONENT,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_COMPONENTS}/${component.id}`, 2),
    },
  },
});

export const getComponentById = (payload: IComponents): GetComponentByIdAction => ({
  type: ComponentActionTypes.GET_COMPONENT_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getComponentDependenciesAction = (): GetComponentDependenciesAction => ({
  type: ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
});

export const getComponentByIdSuccess = (payload: {
  data: IComponents;
  docFiles: Array<IFileUpload>;
  cadFiles: Array<IFileUpload>;
}): GetComponentByIdSuccessAction => ({
  type: ComponentActionTypes.GET_COMPONENT_BY_ID_SUCCESS,
  payload,
});

export const getComponentByIdError = (payload: string): GetComponentByIdErrorAction => ({
  type: ComponentActionTypes.GET_COMPONENT_BY_ID_FAIL,
  payload,
});

const COMPONENTS_FILES_ACTION_TYPES = {
  cadFiles: {
    action: ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE,
    delete: ComponentActionTypes.DELETE_COMPONENT_CAD_FILE,
  },
  docFiles: {
    action: ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE,
    delete: ComponentActionTypes.DELETE_COMPONENT_DOC_FILE,
  },
};

export const uploadComponentFilesAction =
  (fileType: keyof typeof COMPONENTS_FILES_ACTION_TYPES) =>
  (files: Array<IFileUpload>): UploadComponentCadFilesAction | UploadComponentDocFilesAction => ({
    payload: files,
    type: COMPONENTS_FILES_ACTION_TYPES[fileType].action as
      | ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE
      | ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE,
  });

export const uploadComponentCadFileSuccessAction = (fileUpload: IFileUpload): UploadComponentCadFileSuccessAction => ({
  payload: fileUpload,
  type: ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_SUCCESS,
});

export const uploadComponentCadFileFailAction = (fileUpload: IFileUpload): UploadComponentCadFileFailAction => ({
  payload: fileUpload,
  type: ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_FAIL,
});

export const uploadComponentDocFileSuccessAction = (fileUpload: IFileUpload): UploadComponentDocFileSuccessAction => ({
  payload: fileUpload,
  type: ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE_SUCCESS,
});

export const uploadComponentDocFileFailAction = (fileUpload: IFileUpload): UploadComponentDocFileFailAction => ({
  payload: fileUpload,
  type: ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE_FAIL,
});

export const deleteComponentFileAction =
  (fileType: keyof typeof COMPONENTS_FILES_ACTION_TYPES) =>
  (file: IFileUpload): DeleteComponentCadFileAction | DeleteComponentDocFileAction => ({
    payload: file,
    type: COMPONENTS_FILES_ACTION_TYPES[fileType].delete as
      | ComponentActionTypes.DELETE_COMPONENT_CAD_FILE
      | ComponentActionTypes.DELETE_COMPONENT_DOC_FILE,
  });

export const cleanUpComponents = (): CleanUpComponentsAction => ({
  type: ComponentActionTypes.CLEAN_UP_COMPONENTS,
});

export const cleanUpComponentLinks = (): CleanUpComponentLinksAction => ({
  type: ComponentActionTypes.CLEAN_UP_COMPONENT_LINKS,
});

export const patchComponentFile = ({
  fileId,
  patchedData,
}: {
  fileId: string;
  patchedData: Partial<Raw.IMinioResource>;
}): UpdateComponentFileAction => ({
  type: ComponentActionTypes.UPDATE_COMPONENT_FILE,
  payload: {
    request: {
      method: 'patch',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_COMPONENTS_FILES}/${fileId}`, 2),
      data: patchedData,
    },
  },
});
