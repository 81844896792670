import Button from 'components/Button';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Plugin } from 'state_management/reducers/plugins/Modals';
import Dialog from 'components/Dialog';
import IFrame from 'components/iFrame';
import { setAcceptedConsentIdsAction } from 'state_management/actions/unboxing/unboxingActions';
import { IConsentModal } from './IProps';

import { Container, ControllerContainer } from './styles';

const ConsentModal = ({ plugins, dispatch }: IConsentModal): JSX.Element => {
  const [consentedIds, setConsentedIds] = useState<Array<string>>([]);

  useEffect(() => {
    const unparsedConsentIds = localStorage.getItem('consentIds');
    setConsentedIds(JSON.parse(unparsedConsentIds || '[]'));
  }, []);

  const consentPlugin: Plugin[] = useMemo(
    () =>
      (plugins || [])
        .filter((p) => p.type === 'iframe' && p.location === 'consent-modal')
        .filter((p) => !consentedIds.includes(p.id)),
    [plugins, consentedIds],
  );

  const { t } = useTranslation();

  const onConsent = (consentedId: string): void => {
    const unparsedConsentIds: string | null = localStorage.getItem('consentIds');
    let savedIds: Array<string> = [];
    if (unparsedConsentIds) {
      savedIds = JSON.parse(unparsedConsentIds);
      savedIds.push(consentedId);
    } else {
      savedIds = [consentedId];
    }
    localStorage.setItem('consentIds', JSON.stringify(savedIds));
    setConsentedIds(savedIds);
    dispatch(setAcceptedConsentIdsAction(savedIds));
  };

  const emptyFunction = (): void => {};

  return (
    <>
      {(consentPlugin || []).map((plugin) => (
        <Dialog open fullScreen disableCloseIcon onClose={emptyFunction}>
          <Container data-testid="consent-modal">
            <IFrame src={plugin.extras?.url} />
            <ControllerContainer>
              <Button onClick={(): void => onConsent(plugin.id)}>
                {t(`mainDashboard.consentModal.${plugin.extras?.buttonLabel}`, plugin.extras?.buttonLabel || 'I agree')}
              </Button>
            </ControllerContainer>
          </Container>
        </Dialog>
      ))}
    </>
  );
};
export default ConsentModal;
