import { call, CallEffect, ForkEffect, put, PutEffect, takeEvery, select, SelectEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';
import i18n from 'i18n/config';

import { getErrorMessage } from 'utils/getErrorMessage';
import { AppState } from 'state_management/AppState';
import { createChangeRequestAction } from 'state_management/actions/changesRequest/changesRequestActions';
import {
  CreateChangeRequestForSharingAction,
  RequestsActionTypes,
} from 'state_management/actions/changesRequest/ActionTypes';
import { translateEmailsToIds } from '../sharing/utils/emailsTranslation';

export function* createChangeRequestForSharing(
  action: CreateChangeRequestForSharingAction,
): Generator<SelectEffect | CallEffect<AxiosResponse> | PutEffect | CallEffect> {
  const { payload } = action;
  const t = i18n.t.bind(i18n);
  try {
    const {
      authState: { featurePermissions },
    } = (yield select((state) => state)) as AppState;

    if (featurePermissions.SHARE_WITH_USER.read && payload.changes.users) {
      payload.changes.users = (yield call(() =>
        translateEmailsToIds(payload.changes.users as Raw.IResourceShareItems),
      )) as Raw.IResourceShareItems;
    }

    yield put(createChangeRequestAction(payload));
  } catch (error) {
    yield put({
      type: RequestsActionTypes.CREATE_CHANGES_REQUEST_FAIL,
      payload:
        getErrorMessage(error as AxiosError) ||
        t('orbit:createSharingRequestError', "Updating sharing failed. Please check the email address you've entered."),
    });
  }
}

export function* createChangeRequestForSharingSaga(): Generator<ForkEffect<never>> {
  yield takeEvery(RequestsActionTypes.CREATE_CHANGES_REQUEST_FOR_SHARING, createChangeRequestForSharing);
}
