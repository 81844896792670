import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Segmented, Skeleton, Space } from 'antd';
import { WarningFilled } from '@ant-design/icons';
import {
  highlightCorruptedBlock,
  highlightCorruptedLink,
  isBlock,
  isLink,
  resetBlockStyle,
  resetLinkStyle,
} from 'utils/dom/canvasStyleUtil';
import { ElementType } from 'components/CanvasV2/Modals';
import { Body, Header, CloseButton, AlertItem, PanelWrapper, ToolsButton } from './styles';
import { IProps } from './IProps';
import DraggableDrawer from '../DraggableDrawer';
import { tab } from '../modals';

enum SplitType {
  DUPLICATE,
  TOOLS,
}

const IssuesPanel = ({
  projectIssues,
  issuesPanelToggle,
  setIssuesPanelToggle,
  currentTab,
  setCurrentTab,
  canvasRendererRef,
}: IProps): JSX.Element => {
  const [doesPanelSplit, setDoesPanelSplit] = useState(false);
  const [panelSplitType, setPanelSplitType] = useState<SplitType>();

  const { t } = useTranslation();
  const closeButtonAction = (): void => {
    if (!doesPanelSplit) {
      setIssuesPanelToggle((prev) => !prev);
    } else {
      setDoesPanelSplit((prev) => !prev);
    }
  };

  const handleToolsAction = (): void => {
    setDoesPanelSplit((prev) => !prev);
    setPanelSplitType(SplitType.TOOLS);
  };

  const resetIssues = (itemsArray: string[]): void => {
    itemsArray.forEach((itemId) => {
      if (isBlock(itemId)) resetBlockStyle(itemId);
      if (isLink(itemId)) resetLinkStyle(itemId);
    });
    document
      .querySelector('.joint-paper-background ~ svg')
      ?.removeEventListener('click', () => resetIssues(itemsArray));
  };

  const highlightIssues = (itemsArray: string[]): void => {
    resetIssues(itemsArray);
    itemsArray.forEach((itemId) => {
      if (isBlock(itemId)) {
        highlightCorruptedBlock(itemId);
        canvasRendererRef?.current?.centerItem(itemId, ElementType.ELEMENT);
      }
      if (isLink(itemId)) {
        highlightCorruptedLink(itemId);
        canvasRendererRef?.current?.centerItem(itemId, ElementType.LINK);
      }
    });
    document.querySelector('.joint-paper-background ~ svg')?.addEventListener('click', () => resetIssues(itemsArray));
  };

  const panel = (
    <PanelWrapper doesSplit={doesPanelSplit} data-testid="issues-panel">
      <Header>
        <Space direction="vertical">
          <Segmented
            className="segmented-tabs"
            size="small"
            options={[
              {
                label: t('supernova:issuesPanel.tabs.all', 'All'),
                value: 'all',
              },
              {
                label: t('supernova:issuesPanel.tabs.errors', 'Errors'),
                value: 'errors',
                disabled: (projectIssues?.errors || []).length === 0,
              },
              {
                label: t('supernova:issuesPanel.tabs.warnings', 'Warnings'),
                value: 'warnings',
                disabled: (projectIssues?.warnings || []).length === 0,
              },
            ]}
            value={currentTab}
            onChange={(value): void => setCurrentTab(value.toString() as tab)}
            onResize={undefined}
            onResizeCapture={undefined}
          />
        </Space>
        <div>
          {!doesPanelSplit && <ToolsButton onClick={handleToolsAction} />}
          <CloseButton onClick={closeButtonAction} />
        </div>
      </Header>
      <Body>
        <Space direction="vertical" size={2} className="alertItem-container">
          {(currentTab === 'all' || currentTab === 'errors') &&
            projectIssues &&
            projectIssues.errors &&
            projectIssues.errors.map((issue, index) => (
              <AlertItem
                key={`${index}-${issue.title}`}
                type="error"
                message={`${issue.title}: ${issue.message}`}
                banner
                onClick={(): void => highlightIssues(issue.element_uids)}
              />
            ))}
          {(currentTab === 'all' || currentTab === 'warnings') &&
            projectIssues &&
            projectIssues.warnings &&
            projectIssues.warnings.map((issue, index) => (
              <AlertItem
                key={`${index}-${issue.title}`}
                icon={<WarningFilled />}
                type="warning"
                message={`${issue.title}: ${issue.message}`}
                banner
                onClick={(): void => highlightIssues(issue.element_uids)}
              />
            ))}
          {!projectIssues ||
            ((projectIssues?.errors || []).length === 0 && (projectIssues?.warnings || []).length === 0 && (
              <AlertItem type="success" message={t('supernova:issuesPanel.noIssuesFound', 'No issues found.')} banner />
            ))}
        </Space>
      </Body>
    </PanelWrapper>
  );

  const tools = (
    <PanelWrapper doesSplit={doesPanelSplit}>
      <Header>
        <Space direction="vertical">
          <Segmented
            className="segmented-tabs"
            size="small"
            options={[
              {
                label: t('supernova:issuesPanel.tabs.performance', 'Performance'),
                value: 'performance',
              },
              {
                label: t('supernova:issuesPanel.tabs.tips', 'Tips'),
                value: 'tips',
              },
            ]}
            onResize={undefined}
            onResizeCapture={undefined}
          />
        </Space>
        <div>
          <CloseButton onClick={closeButtonAction} />
        </div>
      </Header>
      <Body>
        <Skeleton active paragraph={{ rows: 2 }} />
      </Body>
    </PanelWrapper>
  );

  return (
    <DraggableDrawer isOpen={issuesPanelToggle}>
      {panel}
      {doesPanelSplit && panelSplitType === SplitType.TOOLS && tools}
    </DraggableDrawer>
  );
};

export default IssuesPanel;
