export const upsertResource = <T extends { id?: string | number }>(
  resources: Array<T>,
  newResource: T,
  ignoredFields?: Array<keyof T>,
): Array<T> => {
  let resourceUpdated = false;

  const updatedResources = resources.map((r) => {
    if (r.id === newResource.id) {
      resourceUpdated = true;
      const tempResource = newResource;
      Object.keys(newResource).forEach((k) => {
        if (ignoredFields?.includes(k as keyof T)) {
          delete tempResource[k as keyof T];
        }
      });
      return { ...r, ...tempResource };
    }

    return r;
  });

  return resourceUpdated ? updatedResources : [...resources, newResource];
};

export const mergeResourcesExclusive = <T extends { id?: string | number }>(
  resources: Array<T>,
  newResource: Array<T>,
): Array<T> => {
  const mergedData: T[] = [];
  const set = new Set<string>([...resources.map((r) => r.id as string), ...newResource.map((nr) => nr.id as string)]);
  Array.from(set).forEach((id) => {
    mergedData.push((resources.find((r) => r.id === id) || newResource.find((nr) => nr.id === id)) as T);
  });
  return mergedData;
};

// NOTE: Not being used anywhere for now
export const deDupeResources = <T extends { id?: string | number }>(resources: Array<T>): Array<T> =>
  resources.reduce((list, curr) => (list.find((r) => r.id === curr.id) ? list : [...list, curr]), [] as Array<T>);
