import { NewNotification, NotificationActionTypes } from 'state_management/actions/notification/ActionTypes';
import { takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';

const notificationSaga = (action: NewNotification): void => {
  const notification = action.payload;

  // NOTE: `idle` is silent and do not shout silent notifications
  if (notification.type !== 'idle') {
    globalToast(
      undefined,
      notification.type,
      {
        [notification.type]: notification.message,
      },
      { autoClose: notification.autoClose },
    );
  }
};

export function* notificationSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery([NotificationActionTypes.NEW_NOTIFICATION], notificationSaga);
}
