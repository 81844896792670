import { FeatureActionTypes, FeaturesActions } from '../../actions/features/ActionTypes';
import { FeaturesState } from './Modals';

export const CURRENT_FEATURE_STATE_MIGRATION = '09-12-2021';

export const initialFeaturesState: FeaturesState = {
  subSystemCanvas: false,
  futureFormsMocksService: false,
  subSystemCanvasCreate: false,
  languageSelect: false,
  migration: CURRENT_FEATURE_STATE_MIGRATION,
  enableRetryChangeRequest: false,
};

export const featuresReducer = (
  state: FeaturesState = initialFeaturesState,
  action: FeaturesActions,
): FeaturesState => {
  switch (action.type) {
    case FeatureActionTypes.FEAT_TOGGLE_FUTURE_FORMS_MOCKS:
      return { ...state, futureFormsMocksService: !state.futureFormsMocksService };
    case FeatureActionTypes.FEAT_TOGGLE_SUB_SYSTEM_CANVAS:
      return { ...state, subSystemCanvas: !state.subSystemCanvas };
    case FeatureActionTypes.FEAT_TOGGLE_SUB_SYSTEM_CANVAS_CREATE:
      return { ...state, subSystemCanvasCreate: !state.subSystemCanvasCreate };
    case FeatureActionTypes.FEAT_TOGGLE_LANGUAGE_SELECTION:
      return { ...state, languageSelect: !state.languageSelect };
    case FeatureActionTypes.FEAT_TOGGLE_RETRY_CHANGE_REQUEST:
      return { ...state, enableRetryChangeRequest: !state.enableRetryChangeRequest };
    default:
      return state;
  }
};
