import { combineReducers } from 'redux';

import { AppState } from 'state_management/AppState';

import { authReducer as authState, initialAuthState } from './auth/authReducer';
import { designBlocksReducer as designBlocksState, initialStateDesignBlocks } from './designBlocks/designBlocksReducer';
import {
  initialStateMachineLearning,
  machineLearningReducer as machineLearningState,
} from './machineLearning/machineLearningReducer';
import { initialProjectsState, projectsReducer as projectsState } from './projects/projectsReducer';
import {
  implementationsReducer as implementationsState,
  initialStateImplementations,
} from './implementations/implementationsReducer';
import {
  initialStateSpecifications,
  specificationsReducer as specificationsState,
} from './specifications/specificationsReducer';
import { initialStateInterfaces, interfacesReducer as interfacesState } from './interfaces/interfacesReducer';
import {
  changesRequestReducer as changesRequestState,
  initialStateRequests,
} from './changesRequest/changesRequestReducer';
import { changeLogReducer as changeLogState, initialChangeLogState } from './changeLog/changeLogReducer';
import { componentsReducer as componentsState, initialStateComponents } from './components/componentsReducer';
import { categoriesReducer as categoriesState, initialStateCategories } from './categories/categoriesReducer';
import { boardCanvasReducer as boardCanvasState, initialStateBoardCanvas } from './boardCanvas/boardCanvasReducer';
import { initialStateNotification, notificationReducer as notificationState } from './notification/notificationReducer';
import { baseLayoutReducer as baseLayoutState, initialStateBaseLayout } from './baseLayout/baseLayoutReducer';
import {
  billOfMaterialReducer as billOfMaterialState,
  initialStatBillOfMaterialsView,
} from './billOfMaterial/billOfMaterialReducer';
import { initialStateSharing, sharingReducer as sharingState } from './sharing/sharingReducer';
import {
  initialSystemDesignCanvasState,
  systemDesignCanvasReducer as systemDesignCanvasState,
} from './systemDesignCanvas/systemDesignCanvasReducer';
import { featuresReducer as featuresState, initialFeaturesState } from './features/featuresReducer';
import {
  initialStateRefDesigns,
  referenceDesignsReducer as referenceDesignsState,
} from './referenceDesigns/referenceDesignsReducer';
import {
  initialSubSystemState,
  subSystemCanvasReducer as subSystemCanvasState,
} from './subSystemCanvas/subSystemCanvasReducer';
import { initialStatePlugins, pluginsReducer as pluginsState } from './plugins/pluginsReducer';
import {
  initialStateManufacturers,
  manufacturersReducer as manufacturersState,
} from './manufacturers/manufacturersReducer';
import {
  initialStateVirtualComponents,
  virtualComponentsReducer as virtualComponentsState,
} from './virtualComponents/virtualComponentsReducer';
import { initialStateSuppliers, suppliersReducer as suppliersState } from './suppliers/suppliersReducer';
import {
  designConsiderationsReducer as designConsiderationsState,
  initialStateDesignConsiderations,
} from './designConsiderations/designConsiderationsReducer';
import {
  localSnapShotInitState,
  localSnapshotsReducer as localSnapshotsState,
} from './localSnapshots/localSnapshotsReducer';
import { initialStateExportJobs, exportJobsReducer as exportJobsState } from './exportJobs/exportJobsReducer';
import {
  initialStateExportTemplates,
  exportTemplatesReducer as exportTemplatesState,
} from './exportTemplates/exportTemplatesReducer';
import {
  initialUnitConverterState,
  unitConverterReducer as unitConverterState,
} from './unitConverter/unitConverterReducer';
import { initialStateHybridForms, hybridFormsReducer as hybridFormsState } from './hybridForms/hybridFormsReducer';
import { unboxingReducer as unboxingState, initialUnboxingState } from './unboxing/unboxingReducer';
import {
  initialBomModificationState,
  bomModificationReplacementReducer as bomModificationState,
} from './bomModification/bomModificationReducer';
import {
  initialProjectGeneralSettingsState,
  projectGeneralSettingsReducer as projectGeneralSettingsState,
} from './projectGeneralSettings/projectGeneralSettingsReducer';
import {
  initialDesignSystemState,
  designSystemToolBarReducer as designSystemToolBarState,
} from './DesignSystemToolBar/designSystemToolBarReducer';

export default combineReducers<AppState>({
  pluginsState,
  featuresState,
  authState,
  designBlocksState,
  machineLearningState,
  projectsState,
  implementationsState,
  specificationsState,
  interfacesState,
  changesRequestState,
  changeLogState,
  componentsState,
  categoriesState,
  boardCanvasState,
  notificationState,
  baseLayoutState,
  billOfMaterialState,
  sharingState,
  systemDesignCanvasState,
  referenceDesignsState,
  subSystemCanvasState,
  manufacturersState,
  virtualComponentsState,
  suppliersState,
  designConsiderationsState,
  exportJobsState,
  exportTemplatesState,
  localSnapshotsState,
  unitConverterState,
  hybridFormsState,
  unboxingState,
  bomModificationState,
  designSystemToolBarState,
  projectGeneralSettingsState,
});

export const initialStoreState: AppState = {
  pluginsState: initialStatePlugins,
  featuresState: initialFeaturesState,
  authState: initialAuthState,
  designBlocksState: initialStateDesignBlocks,
  machineLearningState: initialStateMachineLearning,
  projectsState: initialProjectsState,
  implementationsState: initialStateImplementations,
  specificationsState: initialStateSpecifications,
  interfacesState: initialStateInterfaces,
  changesRequestState: initialStateRequests,
  changeLogState: initialChangeLogState,
  componentsState: initialStateComponents,
  categoriesState: initialStateCategories,
  boardCanvasState: initialStateBoardCanvas,
  notificationState: initialStateNotification,
  baseLayoutState: initialStateBaseLayout,
  billOfMaterialState: initialStatBillOfMaterialsView,
  sharingState: initialStateSharing,
  systemDesignCanvasState: initialSystemDesignCanvasState,
  referenceDesignsState: initialStateRefDesigns,
  subSystemCanvasState: initialSubSystemState,
  manufacturersState: initialStateManufacturers,
  virtualComponentsState: initialStateVirtualComponents,
  suppliersState: initialStateSuppliers,
  designConsiderationsState: initialStateDesignConsiderations,
  exportJobsState: initialStateExportJobs,
  exportTemplatesState: initialStateExportTemplates,
  localSnapshotsState: localSnapShotInitState,
  unitConverterState: initialUnitConverterState,
  hybridFormsState: initialStateHybridForms,
  unboxingState: initialUnboxingState,
  bomModificationState: initialBomModificationState,
  designSystemToolBarState: initialDesignSystemState,
  projectGeneralSettingsState: initialProjectGeneralSettingsState,
};
