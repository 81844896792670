import { AxiosResponse } from 'axios';
import { CallEffect, ForkEffect, PutEffect, takeLatest, put, call } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  GetImplementationsByIdsAction,
  ImplementationActionTypes,
} from 'state_management/actions/implementations/ActionTypes';
import {
  ENDPOINT_IMPLEMENTATIONS,
  getImplementationsByIdsFail,
  getImplementationsByIdsSuccess,
} from 'state_management/actions/implementations/implementationsActions';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeImplementation } from 'utils/implementationSerializer';

function* getByIds(action: GetImplementationsByIdsAction): Generator<CallEffect | PutEffect> {
  const implementationsIdsQuery = action?.payload?.ids?.reduce(
    (prev, curr) => `${prev ? `${prev}%25OR%25` : ''}id="${curr}"`,
    '',
  );

  if (!implementationsIdsQuery) yield put(getImplementationsByIdsSuccess([]));

  const fields = action.payload?.fields?.join(',') || undefined;
  if (!fields) yield put(getImplementationsByIdsSuccess([]));

  try {
    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS}?fields=${fields}&query=${implementationsIdsQuery}`, 2),
      ),
    )) as AxiosResponse;

    const implementations = ((res?.data as Array<Raw.Implementation>) || []).map((i) => serializeImplementation(i, {}));
    yield put(getImplementationsByIdsSuccess(implementations));
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    yield put(
      getImplementationsByIdsFail(getErrorMessage(error) || 'Fetching implementations failed. Please try again...'),
    );
  }
}

export function* getImplementationsByIdsWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS, getByIds);
}
