import { BaseLayoutActions, BaseLayoutActionTypes } from 'state_management/actions/baseLayout/ActionTypes';
import { BaseLayoutState } from './Modals';

export const initialStateBaseLayout: BaseLayoutState = {
  activeLink: '',
};

export const baseLayoutReducer = (
  state: BaseLayoutState = initialStateBaseLayout,
  action: BaseLayoutActions,
): BaseLayoutState => {
  switch (action.type) {
    case BaseLayoutActionTypes.CHANGE_ACTIVE_LINK:
      return {
        ...state,
        activeLink: action.payload,
      };
    default:
      return state;
  }
};
