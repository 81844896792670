import { ICanvasData } from 'models/ICanvas';
import { CanvasVersionType, IDesignCanvas, RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';
import {
  SystemDesignCanvasActionTypes,
  GetDesignCanvasAction,
  GetDesignCanvasSuccessAction,
  GetDesignCanvasErrorAction,
  DesignCanvasSuccessPayload,
  SaveDesignCanvasAction,
  SaveDesignCanvasErrorAction,
  SaveDesignCanvasSuccessAction,
  ResetDesignCanvasAction,
} from './ActionTypes';

export const ENDPOINT_CONCEPT_BLOCKS = 'concepts';

export const getDesignCanvas = (id: string, version?: string): GetDesignCanvasAction => ({
  payload: {
    id,
    version,
  },
  type: SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS,
});

export const getDesignCanvasSuccess = (payload: DesignCanvasSuccessPayload): GetDesignCanvasSuccessAction => ({
  payload,
  type: SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS_SUCCESS,
});

export const getDesignCanvasError = (payload: string): GetDesignCanvasErrorAction => ({
  payload,
  type: SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS_FAIL,
});

export const saveDesignCanvas = (
  data: Partial<ICanvasData<IDesignCanvas>> | Array<RappidGraph>,
  canvasVersion?: string,
  compileProject?: boolean,
): SaveDesignCanvasAction => ({
  payload: {
    data,
    canvasVersion: canvasVersion as CanvasVersionType,
    compileProject,
  },
  type: SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS,
});

export const saveDesignCanvasSuccess = (canvasId: string): SaveDesignCanvasSuccessAction => ({
  payload: canvasId,
  type: SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_SUCCESS,
});

export const saveDesignCanvasError = (payload: string): SaveDesignCanvasErrorAction => ({
  payload,
  type: SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_FAIL,
});

export const resetDesignCanvas = (): ResetDesignCanvasAction => ({
  type: SystemDesignCanvasActionTypes.RESET_DESIGN_CANVAS,
});
