import { upsertResource } from 'utils/resourceUtils';
import { ExportJobsState } from './Modals';
import { ExportJobActions, ExportJobsActionTypes } from '../../actions/exportJobs/ActionTypes';

export const initialStateExportJobs: ExportJobsState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  exportJobsList: [],
  exportJobsListState: 'idle',
  exportJobsListError: '',
  exportJobCreateState: 'idle',
  exportJobCreateError: '',
  exportJobUpdateState: 'idle',
  exportJobUpdateError: '',
  exportJobDeleteState: 'idle',
  exportJobDeleteError: '',
  exportJobByIdState: 'idle',
  exportJobByIdStateError: '',
  runExportJobState: 'idle',
  runExportJobStateError: '',
};

export const exportJobsReducer = (
  state: ExportJobsState = initialStateExportJobs,
  action: ExportJobActions,
): ExportJobsState => {
  switch (action.type) {
    case ExportJobsActionTypes.GET_ALL_EXPORT_JOBS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        exportJobsListState: 'loading',
      };
    case ExportJobsActionTypes.GET_ALL_EXPORT_JOBS_SUCCESS:
      return {
        ...state,
        exportJobsListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        exportJobsList: action.payload.data,
      };
    case ExportJobsActionTypes.GET_ALL_EXPORT_JOBS_FAIL:
      return {
        ...state,
        exportJobsListState: 'error',
        exportJobsListError: action.payload,
      };
    case ExportJobsActionTypes.CREATE_EXPORT_JOB:
      return {
        ...state,
        exportJobCreateState: 'loading',
      };
    case ExportJobsActionTypes.CREATE_EXPORT_JOB_SUCCESS:
      return {
        ...state,
        exportJobCreateState: 'success',
      };
    case ExportJobsActionTypes.CREATE_EXPORT_JOB_FAIL:
      return {
        ...state,
        exportJobCreateState: 'error',
        exportJobCreateError: action.payload,
      };
    case ExportJobsActionTypes.UPDATE_EXPORT_JOB:
      return {
        ...state,
        exportJobUpdateState: 'loading',
      };
    case ExportJobsActionTypes.UPDATE_EXPORT_JOB_SUCCESS:
      return {
        ...state,
        exportJobUpdateState: 'success',
      };
    case ExportJobsActionTypes.UPDATE_EXPORT_JOB_FAIL:
      return {
        ...state,
        exportJobUpdateState: 'error',
        exportJobUpdateError: action.error.message,
      };
    case ExportJobsActionTypes.DELETE_EXPORT_JOB:
      return {
        ...state,
        exportJobDeleteState: 'loading',
      };
    case ExportJobsActionTypes.DELETE_EXPORT_JOB_SUCCESS:
      return {
        ...state,
        exportJobDeleteState: 'success',
      };
    case ExportJobsActionTypes.DELETE_EXPORT_JOB_FAIL:
      return {
        ...state,
        exportJobDeleteState: 'error',
        exportJobDeleteError: action.error.message,
      };
    case ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID:
      return {
        ...state,
        exportJobByIdState: 'loading',
      };
    case ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID_SUCCESS:
      return {
        ...state,
        exportJobByIdState: 'success',
        exportJobsList: upsertResource(state.exportJobsList, action.payload),
      };
    case ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID_FAIL:
      return {
        ...state,
        exportJobByIdState: 'error',
        exportJobByIdStateError: action.payload,
      };
    case ExportJobsActionTypes.RUN_EXPORT_JOB:
      return {
        ...state,
        runExportJobState: 'loading',
      };
    case ExportJobsActionTypes.RUN_EXPORT_JOB_SUCCESS:
      return {
        ...state,
        runExportJobState: 'success',
      };
    case ExportJobsActionTypes.RUN_EXPORT_JOB_FAIL:
      return {
        ...state,
        runExportJobState: 'error',
        exportJobByIdStateError: action.payload,
      };
    default:
      return state;
  }
};
