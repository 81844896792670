import { AppType, UserSettings } from 'state_management/reducers/auth/Modals';
import {
  GetUserInfoAction,
  AuthActionTypes,
  LogoutAction,
  LoginAction,
  UpdateAppTypeAction,
  UnauthorizedUserAction,
  GetI18nTranslations,
  GetPermissionsAction,
  GetAuthIssuer,
  GetActiveThemeAction,
  LogoutSuccessAction,
  LogoutErrorAction,
  UpdateUserSettingsAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const getI18nTranslations = (): GetI18nTranslations => ({
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri('i18n', 2),
    },
  },
  type: AuthActionTypes.GET_I18N_TRANSLATIONS,
});

export const getUserInfo = (): GetUserInfoAction => ({
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri('auth-user/me', 2),
    },
  },
  type: AuthActionTypes.GET_USER_INFO,
});

export const getPermissions = (): GetPermissionsAction => ({
  type: AuthActionTypes.GET_PERMISSIONS,
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri('auth-user/permissions', 2),
    },
  },
});

export const unAuthorizedUserAction = (): UnauthorizedUserAction => ({
  type: AuthActionTypes.UNAUTHORIZED_USER_ACTION,
});

export const loginAction = (): LoginAction => ({
  type: AuthActionTypes.LOGIN,
});

export const logout = (next?: string): LogoutAction => ({
  type: AuthActionTypes.LOGOUT,
  payload: next,
});

export const logoutSuccessAction = (): LogoutSuccessAction => ({
  type: AuthActionTypes.LOGOUT_SUCCESS,
});

export const logoutErrorAction = (error: string): LogoutErrorAction => ({
  type: AuthActionTypes.LOGOUT_FAIL,
  payload: error,
});

export const updateAppType = (appType: AppType): UpdateAppTypeAction => ({
  payload: appType,
  type: AuthActionTypes.UPDATE_APP_TYPE,
});

export const getAuthIssuer = (): GetAuthIssuer => ({
  type: AuthActionTypes.GET_AUTH_ISSUER,
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri('auth-user/.well-known/openid-configuration', 2),
    },
  },
});

export const getActiveThemeAction = (): GetActiveThemeAction => ({
  type: AuthActionTypes.GET_ACTIVE_THEME,
  endpoint: apiUri('/themes/active', 2),
});

export const updateUserSettings = (userSettings: UserSettings): UpdateUserSettingsAction => ({
  payload: userSettings,
  type: AuthActionTypes.UPDATE_USER_SETTINGS,
});
