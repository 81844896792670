import { useContext, useEffect } from 'react';
import { Modifiers, ShortcutMap } from './Modals';
import { ShortcutsCheatSheetContext } from './ShortcutsCheatSheetContext';

const checkModifiersMatch = ({ altKey, shiftKey, ctrlKey, metaKey }: KeyboardEvent, modifiers: Modifiers): boolean =>
  Boolean(modifiers.ctrl) === (ctrlKey || metaKey) &&
  Boolean(modifiers.alt) === altKey &&
  Boolean(modifiers.shift) === shiftKey;

export const useShortcuts = (id: string, description: string, shortcutMaps: Array<ShortcutMap>): void => {
  const { add, remove } = useContext(ShortcutsCheatSheetContext);

  const rootHandler = (event: KeyboardEvent): void => {
    const { key } = event;

    shortcutMaps.forEach((s) => {
      const singleKeyMatch = checkModifiersMatch(event, s) && s.key === key;

      const multiKeyMatch = s.keys?.some((keyMap) => checkModifiersMatch(event, keyMap) && keyMap.key === key);

      if (singleKeyMatch || multiKeyMatch) {
        event.preventDefault();

        s.handler && s.handler();
      }
    });
  };

  useEffect(() => {
    add && add(id, description, shortcutMaps);

    return (): void => {
      remove && remove(id);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', rootHandler, false);

    return (): void => {
      window.removeEventListener('keydown', rootHandler, false);
    };
  }, [rootHandler]);
};
