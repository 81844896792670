import { BomModificationRequest, BomModificationResponse } from 'state_management/reducers/bomModification/Modals';
import {
  BomModificationActionTypes,
  GetBomModificationRulesAction,
  GetBomModificationRulesErrorAction,
  GetBomModificationRulesSuccessAction,
  ResetBomModificationDialogueStateAction,
  ResetBomModificationRulesAction,
  CreateBomModificationRulesAction,
  CreateBomModificationRulesFailAction,
  CreateBomModificationRulesSuccessAction,
  UpdateBomModificationRulesAction,
  UpdateBomModificationRulesErrorAction,
  UpdateBomModificationRulesSuccessAction,
} from './ActionTypes';

/* ------- GET OPERATION ------------------------- */
export const getBomModificationRules = (projectId: string): GetBomModificationRulesAction => ({
  payload: projectId,
  type: BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES,
});

export const getBomModificationsRulesSuccess = (
  payload: BomModificationResponse,
): GetBomModificationRulesSuccessAction => ({
  type: BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_SUCCESS,
  payload,
});

export const getBomModificationsRulesFail = (payload: string): GetBomModificationRulesErrorAction => ({
  payload,
  type: BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES_FAIL,
});

/* ------- SAVE OPERATION ------------------------- */
export const createBomModificationRules = (payload: BomModificationRequest): CreateBomModificationRulesAction => ({
  payload,
  type: BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE,
});

export const createBomModificationRulesSuccess = (
  payload: BomModificationResponse,
): CreateBomModificationRulesSuccessAction => ({
  payload,
  type: BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE_SUCCESS,
});

export const createBomModificationRulesFail = (payload: string): CreateBomModificationRulesFailAction => ({
  payload,
  type: BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE_FAIL,
});

/* ------- UPDATE OPERATION ------------------------- */

export const updateBomModificationRules = (
  payload: BomModificationRequest & { id: string },
): UpdateBomModificationRulesAction => ({
  type: BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE,
  payload,
});

export const updateBomModificationRulesSuccess = (
  payload: BomModificationResponse,
): UpdateBomModificationRulesSuccessAction => ({
  payload,
  type: BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE_SUCCESS,
});

export const updateBomModificationRulesFail = (payload: string): UpdateBomModificationRulesErrorAction => ({
  payload,
  type: BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE_FAIL,
});
/* ---------------------- RESET ----------------------- */
export const resetBomModificationRules = (): ResetBomModificationRulesAction => ({
  type: BomModificationActionTypes.RESET_PROJECT_BOM_MODIFICATION_RULES,
});

export const resetBomModificationDialogue = (): ResetBomModificationDialogueStateAction => ({
  type: BomModificationActionTypes.RESET_BOM_MODIFICATION_DIALOGUE_STATE,
});
