import { deserializeSpecification } from 'utils/specificationsSerializer';
import {
  ISpecification,
  SpecificationList,
  SpecificationContext,
} from 'state_management/reducers/specifications/Modals';
import { IPagination } from 'models/Pagination';
import {
  GetAllSpecificationsAction,
  CreateSpecificationAction,
  GetSpecificationByIdAction,
  UpdateSpecificationAction,
  DeleteSpecificationAction,
  SpecificationActionTypes,
  GetSpecificationByIdSuccessAction,
  GetSpecificationByIdErrorAction,
  GetAllSpecificationsSuccessAction,
  GetAllSpecificationsErrorAction,
  CloneSpecificationAction,
  GetSpecificationkDependenciesAction,
  ResetSpecificationsAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const ENDPOINT_SPECIFICATIONS = 'specifications';

export const getAllSpecificationsAction =
  (specTypes: Array<SpecificationContext>) =>
  (payload: Partial<IPagination> = {}): GetAllSpecificationsAction => ({
    payload: {
      specTypes,
      pagination: { ...payload, queryType: payload.queryType || 'paginated' },
    },
    type: SpecificationActionTypes.GET_ALL_SPECIFICATIONS,
  });

export const getAllSpecificationsSuccessAction = (
  pagination: IPagination,
  data: SpecificationList,
  specTypes: Array<SpecificationContext>,
): GetAllSpecificationsSuccessAction => ({
  payload: {
    data,
    pagination,
    specTypes,
  },
  type: SpecificationActionTypes.GET_ALL_SPECIFICATIONS_SUCCESS,
});

export const getAllSpecificationsErrorAction = (payload: string): GetAllSpecificationsErrorAction => ({
  payload,
  type: SpecificationActionTypes.GET_ALL_SPECIFICATIONS_FAIL,
});

export const getSpecificationkDependenciesAction = (): GetSpecificationkDependenciesAction => ({
  type: SpecificationActionTypes.GET_SPECIFICATION_DEPENDENCIES,
});

export const createSpecification = (specification: Partial<ISpecification>): CreateSpecificationAction => ({
  type: SpecificationActionTypes.CREATE_SPECIFICATION,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}`, 2),
      data: deserializeSpecification(specification),
    },
  },
});

export const updateSpecification = (specification: ISpecification): UpdateSpecificationAction => ({
  type: SpecificationActionTypes.UPDATE_SPECIFICATION,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}/${specification.id}`, 2),
      data: deserializeSpecification(specification),
    },
  },
});

export const cloneSpecification = (specification: Partial<ISpecification>): CloneSpecificationAction => ({
  type: SpecificationActionTypes.CLONE_SPECIFICATION,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}`, 2),
      data: deserializeSpecification(specification),
    },
  },
});

export const deleteSpecification = (specification: ISpecification): DeleteSpecificationAction => ({
  type: SpecificationActionTypes.DELETE_SPECIFICATION,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SPECIFICATIONS}/${specification.id}`, 2),
    },
  },
});

export const getSpecificationById = (payload: Partial<ISpecification>): GetSpecificationByIdAction => ({
  type: SpecificationActionTypes.GET_SPECIFICATION_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getSpecificationByIdSuccess = (payload: ISpecification): GetSpecificationByIdSuccessAction => ({
  type: SpecificationActionTypes.GET_SPECIFICATION_BY_ID_SUCCESS,
  payload,
});

export const getSpecificationByIdError = (payload: string): GetSpecificationByIdErrorAction => ({
  type: SpecificationActionTypes.GET_SPECIFICATION_BY_ID_FAIL,
  payload,
});

export const resetSpecifications = (): ResetSpecificationsAction => ({
  type: SpecificationActionTypes.RESET_SPECIFICATIONS,
});
