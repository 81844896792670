import {
  ManufacturersActionTypes,
  ManufacturerActions,
  UpdateManufacturerErrorAction,
  CreateManufacturerErrorAction,
  DeleteManufacturerErrorAction,
} from 'state_management/actions/manufacturers/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const manufacturersUpdateToasts = (action: ManufacturerActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Manufacturer is being updated, please wait...',
      success: 'Manufacturer has been successfully updated.',
      error:
        getErrorMessage((action as UpdateManufacturerErrorAction).error) ||
        'Updating Manufacturer failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const manufacturersCloneToasts = (action: ManufacturerActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Manufacturer is being cloned, please wait...',
      success: 'Manufacturer has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateManufacturerErrorAction).error) ||
        'Cloning Manufacturer failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const manufacturersCreateToasts = (action: ManufacturerActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Manufacturer is being created, please wait...',
      success: 'Manufacturer has been successfully created.',
      error:
        getErrorMessage((action as CreateManufacturerErrorAction).error) ||
        'Creating Manufacturer failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const manufacturersDeleteToasts = (action: ManufacturerActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Manufacturer is being deleted, please wait...',
      success: 'Manufacturer has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteManufacturerErrorAction).error) ||
        'Deleting Manufacturer failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        ManufacturersActionTypes.CREATE_MANUFACTURER,
        ManufacturersActionTypes.CREATE_MANUFACTURER_SUCCESS,
        ManufacturersActionTypes.CREATE_MANUFACTURER_FAIL,
      ],
      manufacturersCreateToasts,
    ),
    takeEvery(
      [
        ManufacturersActionTypes.UPDATE_MANUFACTURER,
        ManufacturersActionTypes.UPDATE_MANUFACTURER_SUCCESS,
        ManufacturersActionTypes.UPDATE_MANUFACTURER_FAIL,
      ],
      manufacturersUpdateToasts,
    ),
    takeEvery(
      [
        ManufacturersActionTypes.CLONE_MANUFACTURER,
        ManufacturersActionTypes.CLONE_MANUFACTURER_SUCCESS,
        ManufacturersActionTypes.CLONE_MANUFACTURER_FAIL,
      ],
      manufacturersCloneToasts,
    ),
    takeEvery(
      [
        ManufacturersActionTypes.DELETE_MANUFACTURER,
        ManufacturersActionTypes.DELETE_MANUFACTURER_SUCCESS,
        ManufacturersActionTypes.DELETE_MANUFACTURER_FAIL,
      ],
      manufacturersDeleteToasts,
    ),
  ]);
}
