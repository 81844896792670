import { PutEffect, ForkEffect, put, SelectEffect, takeLatest, select } from 'redux-saga/effects';

import {
  GetDesignBlockAlternativeAction,
  GetSubSystemCanvasAction,
  SubSystemCanvasActionTypes,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import { GetImplementationByIdAction } from 'state_management/actions/implementations/ActionTypes';
import { AppState } from 'state_management/AppState';
import { IDesignBlockMatchData } from 'models/ICanvas';
import { getSubSystemCanvasAction } from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';
import { getImplementationById } from 'state_management/actions/implementations/implementationsActions';

export function* getAlternativeSaga(
  action: GetDesignBlockAlternativeAction,
): Generator<SelectEffect | PutEffect<GetSubSystemCanvasAction | GetImplementationByIdAction>> {
  const selectedId = action.payload;
  const { alternatives } = (yield select(
    (state: AppState) => state.subSystemCanvasState.designBlockMatchData,
  )) as IDesignBlockMatchData;

  const selectedAlternative = alternatives.find((a) => a.id === selectedId);
  const getAction =
    selectedAlternative?.type === 'sub-system'
      ? getSubSystemCanvasAction(selectedId)
      : getImplementationById({ id: selectedId });

  yield put(getAction);
}

export function* getAlternativeSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(SubSystemCanvasActionTypes.GET_DESIGN_BLOCK_ALTERNATIVE, getAlternativeSaga);
}
