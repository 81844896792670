import { CallEffect, ForkEffect, put, PutEffect, takeEvery, SelectEffect, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import i18n from 'i18n/config';

import { getErrorMessage } from 'utils/getErrorMessage';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { deserializeExportJob } from 'utils/exportJobsSerializer';
import { CreateExportJobAction, ExportJobsActionTypes } from 'state_management/actions/exportJobs/ActionTypes';
import {
  createExportJobErrorAction,
  createExportJobSuccessAction,
  ENDPOINT_EXPORT_JOBS,
} from 'state_management/actions/exportJobs/exportJobsActions';
import { IExportJob } from 'state_management/reducers/exportJobs/Modals';

export function* createExportJob(
  action: CreateExportJobAction,
): Generator<SelectEffect | CallEffect<AxiosResponse> | PutEffect | CallEffect> {
  const t = i18n.t.bind(i18n);

  try {
    const deserializedExportJob = deserializeExportJob(action.payload as IExportJob);
    const res = (yield call(() =>
      axiosInstance.post(apiUri(`dataservice/${ENDPOINT_EXPORT_JOBS}`, 2), deserializedExportJob),
    )) as AxiosResponse;
    const { id } = res.data;

    (yield call(() =>
      axiosInstance.post(apiUri(`process/data_exporter/${id}`, 2), { export_job_id: id }),
    )) as AxiosResponse<IExportJob>;
    yield put(createExportJobSuccessAction());
  } catch (error) {
    yield put(
      createExportJobErrorAction(
        getErrorMessage(error) || t('orbit.exportJob.error', 'Creating export job failed. Please try again...'),
      ),
    );
  }
}

export function* createExportJobSaga(): Generator<ForkEffect<never>> {
  yield takeEvery(ExportJobsActionTypes.CREATE_EXPORT_JOB, createExportJob);
}
