import { AxiosResponse } from 'axios';
import i18n from 'i18n/config';
import { ForkEffect, CallEffect, call, takeLatest, PutEffect, SelectEffect, select, put } from 'redux-saga/effects';
import { MAIN_ROUTES } from 'routes/getRoutes';
import { axiosInstance } from 'services/dataService';
import { getLocalProjects } from 'services/localProjectsService';
import { apiUri, goToLogin, goToLogout } from 'services/main_app';

import {
  AuthActionTypes,
  GetUserInfoErrorAction,
  LogoutAction,
  LogoutErrorAction,
  LogoutSuccessAction,
} from 'state_management/actions/auth/ActionTypes';
import { logoutErrorAction, logoutSuccessAction } from 'state_management/actions/auth/authActions';
import { AuthState } from 'state_management/reducers/auth/Modals';
import { getErrorMessage } from 'utils/getErrorMessage';

export function* logoutSaga(
  action: LogoutAction | GetUserInfoErrorAction,
): Generator<CallEffect<AxiosResponse<void>> | PutEffect<LogoutSuccessAction | LogoutErrorAction> | SelectEffect> {
  const t = i18n.t.bind(i18n);

  try {
    let next: string | undefined;

    const {
      userInfo: { isAnonymousUser },
    } = (yield select((state) => state.authState)) as AuthState;

    if (isAnonymousUser) {
      const localProjects = getLocalProjects();
      next = localProjects.length ? `${MAIN_ROUTES.projectImport.route}?importType=localProject` : undefined;
    }

    if (action.type === AuthActionTypes.LOGOUT) {
      next = next || action.payload;
      (yield call(() => axiosInstance.post(apiUri('auth-user/logout', 2)))) as AxiosResponse<void>;

      yield put(logoutSuccessAction());
    }

    const { loggedOut } = (yield select((state) => state.authState)) as AuthState;

    if (loggedOut && !isAnonymousUser) {
      goToLogout(next);
    } else {
      goToLogin(next);
    }
  } catch (error) {
    yield put(logoutErrorAction(getErrorMessage(error) || t('logout.error', 'Error during logout')));
  }
}

export function* logoutSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest([AuthActionTypes.LOGOUT, AuthActionTypes.GET_USER_INFO_FAIL], logoutSaga);
}
