import { useEffect, useMemo } from 'react';

import { setAcceptedConsentIdsAction } from 'state_management/actions/unboxing/unboxingActions';
import { IProps } from './IProps';
import ConsentModal from './ConsentModal';

const Consents = ({
  children,
  plugins,
  pluginsState,
  isNoAuthRoute,
  acceptedConsentIds,
  dispatch,
}: IProps): JSX.Element => {
  useEffect(() => {
    const unparsedAcceptedConsentIds: string | null = localStorage.getItem('consentIds');
    if (unparsedAcceptedConsentIds) {
      dispatch(setAcceptedConsentIdsAction(JSON.parse(unparsedAcceptedConsentIds)));
    }
  }, []);

  const hasAcceptedAllConsents = useMemo(() => {
    const allConsentIds = (plugins || [])
      .filter((p) => p.type === 'iframe' && p.location === 'consent-modal')
      .map((p) => p.id);
    const _hasAcceptedAllConsents = allConsentIds.every((consentId) => acceptedConsentIds.includes(consentId));
    return _hasAcceptedAllConsents;
  }, [pluginsState, acceptedConsentIds]);

  return isNoAuthRoute || hasAcceptedAllConsents ? children : <ConsentModal plugins={plugins} dispatch={dispatch} />;
};
export default Consents;
