import {
  SuppliersActionTypes,
  SupplierActions,
  UpdateSupplierErrorAction,
  CreateSupplierErrorAction,
  DeleteSupplierErrorAction,
} from 'state_management/actions/suppliers/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const suppliersUpdateToasts = (action: SupplierActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Supplier is being updated, please wait...',
      success: 'Supplier has been successfully updated.',
      error:
        getErrorMessage((action as UpdateSupplierErrorAction).error) || 'Updating Supplier failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const suppliersCloneToasts = (action: SupplierActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Supplier is being cloned, please wait...',
      success: 'Supplier has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateSupplierErrorAction).error) || 'Cloning Supplier failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const suppliersCreateToasts = (action: SupplierActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Supplier is being created, please wait...',
      success: 'Supplier has been successfully created.',
      error:
        getErrorMessage((action as CreateSupplierErrorAction).error) || 'Creating Supplier failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const suppliersDeleteToasts = (action: SupplierActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Supplier is being deleted, please wait...',
      success: 'Supplier has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteSupplierErrorAction).error) || 'Deleting Supplier failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        SuppliersActionTypes.CREATE_SUPPLIER,
        SuppliersActionTypes.CREATE_SUPPLIER_SUCCESS,
        SuppliersActionTypes.CREATE_SUPPLIER_FAIL,
      ],
      suppliersCreateToasts,
    ),
    takeEvery(
      [
        SuppliersActionTypes.UPDATE_SUPPLIER,
        SuppliersActionTypes.UPDATE_SUPPLIER_SUCCESS,
        SuppliersActionTypes.UPDATE_SUPPLIER_FAIL,
      ],
      suppliersUpdateToasts,
    ),
    takeEvery(
      [
        SuppliersActionTypes.CLONE_SUPPLIER,
        SuppliersActionTypes.CLONE_SUPPLIER_SUCCESS,
        SuppliersActionTypes.CLONE_SUPPLIER_FAIL,
      ],
      suppliersCloneToasts,
    ),
    takeEvery(
      [
        SuppliersActionTypes.DELETE_SUPPLIER,
        SuppliersActionTypes.DELETE_SUPPLIER_SUCCESS,
        SuppliersActionTypes.DELETE_SUPPLIER_FAIL,
      ],
      suppliersDeleteToasts,
    ),
  ]);
}
