import { IPagination } from 'models/Pagination';
import { IDesignBlock } from 'state_management/reducers/designBlocks/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum DesignBlockActionTypes {
  GET_ALL_DESIGN_BLOCKS = 'GET_ALL_DESIGN_BLOCKS',
  GET_ALL_DESIGN_BLOCKS_SUCCESS = 'GET_ALL_DESIGN_BLOCKS_SUCCESS',
  GET_ALL_DESIGN_BLOCKS_FAIL = 'GET_ALL_DESIGN_BLOCKS_FAIL',

  /** Gets the dependencies for design blocks CREATE/UPDATE like Specs */
  GET_DESIGN_BLOCK_DEPENDENCIES = 'GET_DESIGN_BLOCK_DEPENDENCIES',

  GET_DESIGN_BLOCK_BY_ID = 'GET_DESIGN_BLOCK_BY_ID',
  GET_DESIGN_BLOCK_BY_ID_SUCCESS = 'GET_DESIGN_BLOCK_BY_ID_SUCCESS',
  GET_DESIGN_BLOCK_BY_ID_FAIL = 'GET_DESIGN_BLOCK_BY_ID_FAIL',

  CREATE_DESIGN_BLOCK = 'CREATE_DESIGN_BLOCK',
  CREATE_DESIGN_BLOCK_SUCCESS = 'CREATE_DESIGN_BLOCK_SUCCESS',
  CREATE_DESIGN_BLOCK_FAIL = 'CREATE_DESIGN_BLOCK_FAIL',

  UPDATE_DESIGN_BLOCK = 'UPDATE_DESIGN_BLOCK',
  UPDATE_DESIGN_BLOCK_SUCCESS = 'UPDATE_DESIGN_BLOCK_SUCCESS',
  UPDATE_DESIGN_BLOCK_FAIL = 'UPDATE_DESIGN_BLOCK_FAIL',

  CLONE_DESIGN_BLOCK = 'CLONE_DESIGN_BLOCK',
  CLONE_DESIGN_BLOCK_SUCCESS = 'CLONE_DESIGN_BLOCK_SUCCESS',
  CLONE_DESIGN_BLOCK_FAIL = 'CLONE_DESIGN_BLOCK_FAIL',

  DELETE_DESIGN_BLOCK = 'DELETE_DESIGN_BLOCK',
  DELETE_DESIGN_BLOCK_SUCCESS = 'DELETE_DESIGN_BLOCK_SUCCESS',
  DELETE_DESIGN_BLOCK_FAIL = 'DELETE_DESIGN_BLOCK_FAIL',
}

// ============================================ GET_ALL_DESIGN_BLOCK

export interface GetAllDesignBlocksAction {
  payload: Partial<IPagination>;
  type: DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS;
}

export interface GetAllDesignBlocksSuccessAction {
  payload: { pagination: IPagination; data: Array<IDesignBlock> };
  type: DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS_SUCCESS;
}

export interface GetAllDesignBlocksErrorAction {
  payload: string;
  type: DesignBlockActionTypes.GET_ALL_DESIGN_BLOCKS_FAIL;
}

// ============================================ GET_DESIGN_BLOCK_DEPENDENCIES

export interface GetDesignBlockDependenciesAction {
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_DEPENDENCIES;
}

// ============================================ GET_DESIGN_BLOCK_BY_ID

export interface GetDesignBlockByIdAction {
  payload: { id: string };
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID;
}

export interface GetDesignBlockByIdSuccessAction {
  payload: IDesignBlock;
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID_SUCCESS;
}

export interface GetDesignBlockByIdErrorAction {
  payload: string;
  type: DesignBlockActionTypes.GET_DESIGN_BLOCK_BY_ID_FAIL;
}

// ============================================ CREATE_DESIGN_BLOCK

export interface CreateDesignBlockAction extends AxiosRequestPayload<Raw.IDesignBlock> {
  type: DesignBlockActionTypes.CREATE_DESIGN_BLOCK;
}

export interface CreateDesignBlockSuccessAction
  extends AxiosSuccessPayload<
    IDesignBlock,
    DesignBlockActionTypes.CREATE_DESIGN_BLOCK_SUCCESS,
    CreateDesignBlockAction
  > {
  type: DesignBlockActionTypes.CREATE_DESIGN_BLOCK_SUCCESS;
}

export interface CreateDesignBlockErrorAction
  extends AxiosErrorPayload<DesignBlockActionTypes.CREATE_DESIGN_BLOCK_FAIL, CreateDesignBlockAction> {
  type: DesignBlockActionTypes.CREATE_DESIGN_BLOCK_FAIL;
}

// ============================================ UPDATE_DESIGN_BLOCK

export interface UpdateDesignBlockAction extends AxiosRequestPayload<Raw.IDesignBlock> {
  type: DesignBlockActionTypes.UPDATE_DESIGN_BLOCK;
}
export interface UpdateDesignBlockSuccessAction
  extends AxiosSuccessPayload<
    IDesignBlock,
    DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_SUCCESS,
    UpdateDesignBlockAction
  > {
  type: DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_SUCCESS;
}
export interface UpdateDesignBlockErrorAction
  extends AxiosErrorPayload<DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_FAIL, UpdateDesignBlockAction> {
  type: DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_FAIL;
}

// ============================================ CLONE_DESIGN_BLOCK

export interface CloneDesignBlockAction extends AxiosRequestPayload<Raw.IDesignBlock> {
  type: DesignBlockActionTypes.CLONE_DESIGN_BLOCK;
}

export interface CloneDesignBlockSuccessAction
  extends AxiosSuccessPayload<IDesignBlock, DesignBlockActionTypes.CLONE_DESIGN_BLOCK_SUCCESS, CloneDesignBlockAction> {
  type: DesignBlockActionTypes.CLONE_DESIGN_BLOCK_SUCCESS;
}

export interface CloneDesignBlockErrorAction
  extends AxiosErrorPayload<DesignBlockActionTypes.CLONE_DESIGN_BLOCK_FAIL, CloneDesignBlockAction> {
  type: DesignBlockActionTypes.CLONE_DESIGN_BLOCK_FAIL;
}

// ============================================ DELETE_DESIGN_BLOCK

export interface DeleteDesignBlockAction extends AxiosRequestPayload {
  type: DesignBlockActionTypes.DELETE_DESIGN_BLOCK;
}
export interface DeleteDesignBlockSuccessAction
  extends AxiosSuccessPayload<string, DesignBlockActionTypes.DELETE_DESIGN_BLOCK_SUCCESS, DeleteDesignBlockAction> {
  type: DesignBlockActionTypes.DELETE_DESIGN_BLOCK_SUCCESS;
}
export interface DeleteDesignBlockErrorAction
  extends AxiosErrorPayload<DesignBlockActionTypes.DELETE_DESIGN_BLOCK_FAIL, DeleteDesignBlockAction> {
  type: DesignBlockActionTypes.DELETE_DESIGN_BLOCK_FAIL;
}

// ============================================ ALL

export type DesignBlockActions =
  | GetAllDesignBlocksAction
  | GetAllDesignBlocksSuccessAction
  | GetAllDesignBlocksErrorAction
  | GetDesignBlockDependenciesAction
  | CreateDesignBlockAction
  | CreateDesignBlockSuccessAction
  | CreateDesignBlockErrorAction
  | UpdateDesignBlockAction
  | UpdateDesignBlockSuccessAction
  | UpdateDesignBlockErrorAction
  | CloneDesignBlockAction
  | CloneDesignBlockSuccessAction
  | CloneDesignBlockErrorAction
  | DeleteDesignBlockAction
  | DeleteDesignBlockSuccessAction
  | DeleteDesignBlockErrorAction
  | GetDesignBlockByIdAction
  | GetDesignBlockByIdSuccessAction
  | GetDesignBlockByIdErrorAction;
