import { all, AllEffect, ForkEffect, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { globalToast } from 'components/ToastComponent';
import {
  RefDesignActionTypes,
  RefDesignCreateErrorAction,
  RefDesignCreateSuccessAction,
  RefDesignsGetAllErrorAction,
  RefDesignUpdateErrorAction,
  RefDesignUpdateSuccessAction,
} from 'state_management/actions/referenceDesigns/ActionTypes';
import { getErrorMessage } from 'utils/getErrorMessage';
import i18n from '../../../i18n/config';

const refDesignCreateToasts = (action: RefDesignCreateSuccessAction | RefDesignCreateErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.refDesignCreateLoading', 'Reference Design is being created, please wait...'),
    success: t('supernova:toasts.refDesignCreateSuccess', 'Reference Design has been successfully created.'),
    error:
      getErrorMessage((action as RefDesignCreateErrorAction).error) ||
      t('supernova:toasts.refDesignCreateError', 'Creating Reference Design failed, please try again...'),
  });
};

const refDesignUpdateToasts = (action: RefDesignUpdateSuccessAction | RefDesignUpdateErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.refDesignUpdateLoading', 'Reference Design is being updated, please wait...'),
    success: t('supernova:toasts.refDesignUpdateSuccess', 'Reference Design has been successfully updated.'),
    error:
      getErrorMessage((action as RefDesignUpdateErrorAction).error) ||
      t('supernova:toasts.refDesignUpdateError', 'Updating Reference Design failed, please try again...'),
  });
};

const refDesignDeleteToasts = (action: RefDesignCreateSuccessAction | RefDesignCreateErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.refDesignDeleteLoading', 'Reference Design is being deleted, please wait...'),
    success: t('supernova:toasts.refDesignDeleteSuccess', 'Reference Design has been successfully deleted.'),
    error:
      getErrorMessage((action as RefDesignCreateErrorAction).error) ||
      t('supernova:toasts.refDesignDeleteError', 'Deleting Reference Design failed, please try again...'),
  });
};

const refDesignGetAllToasts = (action: RefDesignsGetAllErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    error:
      (action as RefDesignsGetAllErrorAction).payload ||
      t('supernova:toasts.refDesignGetAllError', 'Failed to fetch Reference Designs'),
  });
};

export function* requestsToastsSagaWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeLatest([RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_FAIL], refDesignGetAllToasts),
    takeEvery(
      [
        RefDesignActionTypes.CREATE_REFERENCE_DESIGNS,
        RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_SUCCESS,
        RefDesignActionTypes.CREATE_REFERENCE_DESIGNS_FAIL,
      ],
      refDesignCreateToasts,
    ),
    takeEvery(
      [
        RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS,
        RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_SUCCESS,
        RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_FAIL,
      ],
      refDesignUpdateToasts,
    ),
    takeEvery(
      [
        RefDesignActionTypes.DELETE_REFERENCE_DESIGNS,
        RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_SUCCESS,
        RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_FAIL,
      ],
      refDesignDeleteToasts,
    ),
  ]);
}
