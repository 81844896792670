import { IFileUpload } from 'models/IFileUpload';
import { IPagination } from 'models/Pagination';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';
import { Implementation } from '../../reducers/implementations/Modals';

export enum ImplementationActionTypes {
  REST_IMPLEMENTATIONS = 'REST_IMPLEMENTATIONS',

  GET_ALL_IMPLEMENTATIONS = 'GET_ALL_IMPLEMENTATIONS',
  GET_ALL_IMPLEMENTATIONS_SUCCESS = 'GET_ALL_IMPLEMENTATIONS_SUCCESS',
  GET_ALL_IMPLEMENTATIONS_FAIL = 'GET_ALL_IMPLEMENTATIONS_FAIL',

  GET_IMPLEMENTATION_BY_ID = 'GET_IMPLEMENTATION_BY_ID',
  GET_IMPLEMENTATION_BY_ID_SUCCESS = 'GET_IMPLEMENTATION_BY_ID_SUCCESS',
  GET_IMPLEMENTATION_BY_ID_FAIL = 'GET_IMPLEMENTATION_BY_ID_FAIL',

  GET_IMPLEMENTATIONS_BY_IDS = 'GET_IMPLEMENTATIONS_BY_IDS',
  GET_IMPLEMENTATIONS_BY_IDS_SUCCESS = 'GET_IMPLEMENTATIONS_BY_IDS_SUCCESS',
  GET_IMPLEMENTATIONS_BY_IDS_ERROR = 'GET_IMPLEMENTATIONS_BY_IDS_ERROR',

  /** Gets the dependencies for implementations CREATE/UPDATE like Specs, Interfaces */
  GET_IMPLEMENTATION_DEPENDENCIES = 'GET_IMPLEMENTATION_DEPENDENCIES',

  CREATE_IMPLEMENTATION = 'CREATE_IMPLEMENTATION',
  CREATE_IMPLEMENTATION_SUCCESS = 'CREATE_IMPLEMENTATION_SUCCESS',
  CREATE_IMPLEMENTATION_FAIL = 'CREATE_IMPLEMENTATION_FAIL',

  UPDATE_IMPLEMENTATION = 'UPDATE_IMPLEMENTATION',
  UPDATE_IMPLEMENTATION_SUCCESS = 'UPDATE_IMPLEMENTATION_SUCCESS',
  UPDATE_IMPLEMENTATION_FAIL = 'UPDATE_IMPLEMENTATION_FAIL',

  CLONE_IMPLEMENTATION = 'CLONE_IMPLEMENTATION',
  CLONE_IMPLEMENTATION_SUCCESS = 'CLONE_IMPLEMENTATION_SUCCESS',
  CLONE_IMPLEMENTATION_FAIL = 'CLONE_IMPLEMENTATION_FAIL',

  DELETE_IMPLEMENTATION = 'DELETE_IMPLEMENTATION',
  DELETE_IMPLEMENTATION_SUCCESS = 'DELETE_IMPLEMENTATION_SUCCESS',
  DELETE_IMPLEMENTATION_FAIL = 'DELETE_IMPLEMENTATION_FAIL',

  // NOTE: Refactor out to it's own state, if use grows, i.e CRUD + List
  GET_ALL_FUNCTIONS = 'GET_ALL_FUNCTIONS',
  GET_ALL_FUNCTIONS_FAIL = 'GET_ALL_FUNCTIONS_FAIL',
  GET_ALL_FUNCTIONS_SUCCESS = 'GET_ALL_FUNCTIONS_SUCCESS',

  UPLOAD_IMPLEMENTATION_FILE = 'UPLOAD_IMPLEMENTATION_FILE',
  UPLOAD_IMPLEMENTATION_FILE_SUCCESS = 'UPLOAD_IMPLEMENTATION_FILE_SUCCESS',
  UPLOAD_IMPLEMENTATION_FILE_FAIL = 'UPLOAD_IMPLEMENTATION_FILE_FAIL',

  VERIFY_IMPLEMENTATION_FILE = 'VERIFY_IMPLEMENTATION_FILE',
  VERIFY_IMPLEMENTATION_FILE_SUCCESS = 'VERIFY_IMPLEMENTATION_FILE_SUCCESS',
  VERIFY_IMPLEMENTATION_FILE_FAIL = 'VERIFY_IMPLEMENTATION_FILE_FAIL',

  DELETE_IMPLEMENTATION_FILE = 'DELETE_IMPLEMENTATION_FILE',

  UPDATE_IMPLEMENTATION_FILE = 'UPDATE_IMPLEMENTATION_FILE',

  IMPLEMENTATIONS_CLEAN_UP = 'IMPLEMENTATIONS_CLEAN_UP',

  CONVERT_IMPLEMENTATION_CAD_FILE = 'CONVERT_IMPLEMENTATION_CAD_FILE',
  CONVERT_IMPLEMENTATION_CAD_FILE_SUCCESS = 'CONVERT_IMPLEMENTATION_CAD_FILE_SUCCESS',
  CONVERT_IMPLEMENTATION_CAD_FILE_FAIL = 'CONVERT_IMPLEMENTATION_CAD_FILE_FAIL',
}

// ============================================ GET_ALL_IMPLEMENTATIONS

export interface GetAllImplementationsAction {
  payload: Partial<IPagination & { selectedWorkspaces?: string[] }>;
  type: ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS;
}

export interface GetAllImplementationsSuccessAction {
  payload: { pagination: IPagination; data: Array<Implementation> };
  type: ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS_SUCCESS;
}

export interface GetAllImplementationsErrorAction {
  payload: string;
  type: ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS_FAIL;
}

// ========================================== RESET_IMPLEMENTATIONS
export interface RestImplementationsAction {
  type: ImplementationActionTypes.REST_IMPLEMENTATIONS;
}

// ============================================ GET_IMPLEMENTATION_BY_ID

export interface GetImplementationByIdAction {
  payload: { id: string };
  type: ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID;
}
export interface GetImplementationByIdSuccessAction {
  payload: Implementation;
  type: ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID_SUCCESS;
}
export interface GetImplementationByIdErrorAction {
  payload: string;
  type: ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID_FAIL;
}

// ============================================ GET_IMPLEMENTATION_DEPENDENCIES

export interface GetImplementationDependenciesAction {
  type: ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES;
}

// ============================================ CREATE_IMPLEMENTATION

export interface CreateImplementationAction extends Partial<AxiosRequestPayload<Partial<Raw.Implementation>>> {
  type: ImplementationActionTypes.CREATE_IMPLEMENTATION;
}

export interface CreateImplementationSuccessAction
  extends AxiosSuccessPayload<
    Raw.Implementation,
    ImplementationActionTypes.CREATE_IMPLEMENTATION,
    CreateImplementationAction
  > {
  type: ImplementationActionTypes.CREATE_IMPLEMENTATION_SUCCESS;
}

export interface CreateImplementationErrorAction
  extends AxiosErrorPayload<ImplementationActionTypes.CREATE_IMPLEMENTATION_FAIL, CreateImplementationAction> {
  type: ImplementationActionTypes.CREATE_IMPLEMENTATION_FAIL;
}

// ============================================ UPDATE_IMPLEMENTATION

export interface UpdateImplementationAction extends AxiosRequestPayload<Raw.Implementation> {
  type: ImplementationActionTypes.UPDATE_IMPLEMENTATION;
}
export interface UpdateImplementationSuccessAction
  extends AxiosSuccessPayload<
    Raw.Implementation,
    ImplementationActionTypes.UPDATE_IMPLEMENTATION_SUCCESS,
    UpdateImplementationAction
  > {
  type: ImplementationActionTypes.UPDATE_IMPLEMENTATION_SUCCESS;
}
export interface UpdateImplementationErrorAction
  extends AxiosErrorPayload<ImplementationActionTypes.UPDATE_IMPLEMENTATION_FAIL, UpdateImplementationAction> {
  type: ImplementationActionTypes.UPDATE_IMPLEMENTATION_FAIL;
}

// ============================================ CLONE_IMPLEMENTATION

export interface CloneImplementationAction extends Partial<AxiosRequestPayload<Partial<Raw.Implementation>>> {
  type: ImplementationActionTypes.CLONE_IMPLEMENTATION;
}

export interface CloneImplementationSuccessAction
  extends AxiosSuccessPayload<
    Implementation,
    ImplementationActionTypes.CLONE_IMPLEMENTATION,
    CloneImplementationAction
  > {
  type: ImplementationActionTypes.CLONE_IMPLEMENTATION_SUCCESS;
}

export interface CloneImplementationErrorAction
  extends AxiosErrorPayload<ImplementationActionTypes.CLONE_IMPLEMENTATION_FAIL, CloneImplementationAction> {
  type: ImplementationActionTypes.CLONE_IMPLEMENTATION_FAIL;
}

// ============================================ DELETE_IMPLEMENTATION

export interface DeleteImplementationAction extends AxiosRequestPayload {
  type: ImplementationActionTypes.DELETE_IMPLEMENTATION;
}
export interface DeleteImplementationSuccessAction
  extends AxiosSuccessPayload<
    string,
    ImplementationActionTypes.DELETE_IMPLEMENTATION_SUCCESS,
    DeleteImplementationAction
  > {
  type: ImplementationActionTypes.DELETE_IMPLEMENTATION_SUCCESS;
}
export interface DeleteImplementationErrorAction
  extends AxiosErrorPayload<ImplementationActionTypes.DELETE_IMPLEMENTATION_FAIL, DeleteImplementationAction> {
  type: ImplementationActionTypes.DELETE_IMPLEMENTATION_FAIL;
}

// ============================================ GET_ALL_FUNCTIONS

export interface GetAllFunctions extends AxiosRequestPayload<string> {
  type: ImplementationActionTypes.GET_ALL_FUNCTIONS;
}

export interface GetAllFunctionsSuccess
  extends AxiosSuccessPayload<Array<string>, ImplementationActionTypes.GET_ALL_FUNCTIONS_SUCCESS, GetAllFunctions> {
  type: ImplementationActionTypes.GET_ALL_FUNCTIONS_SUCCESS;
}

export interface GetAllFunctionsFail
  extends AxiosErrorPayload<ImplementationActionTypes.GET_ALL_FUNCTIONS_FAIL, GetAllFunctions> {
  type: ImplementationActionTypes.GET_ALL_FUNCTIONS_FAIL;
}

// ============================================ UPLOAD_IMPLEMENTATION_FILE

export interface ConvertImplementationCadFilesAction {
  type: ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE;
}
export interface ConvertImplementationCadFilesActionSuccess {
  type: ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE_SUCCESS;
  payload: Array<IFileUpload>;
}
export interface ConvertImplementationCadFilesActionFail {
  type: ImplementationActionTypes.CONVERT_IMPLEMENTATION_CAD_FILE_FAIL;
  payload: Array<IFileUpload>;
}
export interface UploadImplementationFilesAction {
  type: ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE;
  payload: Array<IFileUpload>;
}

export interface UploadImplementationFileSuccessAction {
  type: ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE_SUCCESS;
  payload: IFileUpload;
}

export interface UploadImplementationFileFailAction {
  type: ImplementationActionTypes.UPLOAD_IMPLEMENTATION_FILE_FAIL;
  payload: IFileUpload;
}

// ============================================ DELETE_IMPLEMENTATION_FILE

export interface DeleteImplementationFileAction {
  payload: IFileUpload;
  type: ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE;
}

// TODO: Temporary Disable, until new endpoint for Express for Delete is setup
// export interface DeleteImplementationFileSuccessAction
//   extends AxiosSuccessPayload<
//     { tool?: string; file_type: string; file_id: string },
//     ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE_SUCCESS,
//     DeleteImplementationFileAction
//   > {
//   type: ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE_SUCCESS;
// }

// export interface DeleteImplementationFileFailAction
//   extends AxiosErrorPayload<
// ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE_FAIL, DeleteImplementationFileAction > {
//   type: ImplementationActionTypes.DELETE_IMPLEMENTATION_FILE_FAIL;
// }

// ============================================ UPDATE_IMPLEMENTATION_FILE

export interface UpdateImplementationFileAction extends AxiosRequestPayload<Partial<Raw.IMinioResource>> {
  type: ImplementationActionTypes.UPDATE_IMPLEMENTATION_FILE;
}

// ============================================ IMPLEMENTATIONS_CLEAR_ALL

export interface CleanUpImplementationAction {
  type: ImplementationActionTypes.IMPLEMENTATIONS_CLEAN_UP;
}

// ============================================ IMPLEMENTATION_BY_IDS

export interface GetImplementationsByIdsAction {
  payload: {
    ids: Array<string>;
    fields: Array<string>;
  };
  type: ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS;
}

export interface GetImplementationsByIdsSuccessAction {
  payload: Array<Implementation>;
  type: ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS_SUCCESS;
}

export interface GetImplementationsByIdsErrorAction {
  payload: string;
  type: ImplementationActionTypes.GET_IMPLEMENTATIONS_BY_IDS_ERROR;
}

// ============================================ ALL

export type ImplementationActions =
  | GetAllImplementationsAction
  | GetAllImplementationsSuccessAction
  | GetAllImplementationsErrorAction
  | GetImplementationByIdAction
  | GetImplementationByIdSuccessAction
  | GetImplementationByIdErrorAction
  | GetImplementationDependenciesAction
  | CreateImplementationAction
  | CreateImplementationSuccessAction
  | CreateImplementationErrorAction
  | UpdateImplementationAction
  | UpdateImplementationSuccessAction
  | UpdateImplementationErrorAction
  | CloneImplementationAction
  | CloneImplementationSuccessAction
  | CloneImplementationErrorAction
  | DeleteImplementationAction
  | DeleteImplementationSuccessAction
  | DeleteImplementationErrorAction
  | GetAllFunctions
  | GetAllFunctionsSuccess
  | GetAllFunctionsFail
  | UploadImplementationFilesAction
  | ConvertImplementationCadFilesAction
  | ConvertImplementationCadFilesActionSuccess
  | ConvertImplementationCadFilesActionFail
  | UploadImplementationFileSuccessAction
  | UploadImplementationFileFailAction
  | DeleteImplementationFileAction
  | CleanUpImplementationAction
  | RestImplementationsAction
  | GetImplementationsByIdsAction
  | GetImplementationsByIdsSuccessAction
  | GetImplementationsByIdsErrorAction;
