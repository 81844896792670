import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  SelectEffect,
  all,
  AllEffect,
  select,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  BillOfMaterialActionTypes,
  GetAllBillOfMaterialsAction,
  GetAllBillOfMaterialsErrorAction,
  GetAllBillOfMaterialsSuccessAction,
} from 'state_management/actions/billOfMaterial/ActionTypes';

import {
  getAllBillOfMaterialsSuccessAction,
  getAllBillOfMaterialsErrorAction,
} from 'state_management/actions/billOfMaterial/billOfMaterialActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeBillOfMaterials } from 'utils/billOfMaterialsSerializer';
import { IPagination } from 'models/Pagination';
import { IBillOfMaterial } from 'state_management/reducers/billOfMaterial/Modals';
import { ProjectsActionTypes } from 'state_management/actions/projects/ActionTypes';
import { AppState } from 'state_management/AppState';
import { getLocalProjectById } from 'services/localProjectsService';
import i18n from 'i18n/config';

// NOTE: Temporary workaround for not having pagination on BOM
let billOfMaterialList: Array<IBillOfMaterial> = [];
const ENDPOINT_PROJECT_ARTIFACTS = '/dataservice/object-storage/project-artifacts';

export function* getAllSaga(
  action: GetAllBillOfMaterialsAction,
): Generator<
  | PutEffect<GetAllBillOfMaterialsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllBillOfMaterialsSuccessAction | GetAllBillOfMaterialsErrorAction>
> {
  const t = i18n.t.bind(i18n);

  const { pagination: _pagination, projectId: _projectId } = action.payload;
  let projectId = _projectId;

  const {
    authState: {
      userInfo: { isAnonymousUser },
    },
  } = (yield select((state: AppState) => state)) as AppState;

  try {
    if (isAnonymousUser) {
      const _project = getLocalProjectById(projectId);
      const originalProjId = _project.originalProj;

      if (!originalProjId) {
        return;
      }
      projectId = originalProjId;
    }

    if (!billOfMaterialList.length) {
      const projArtifactRes = (yield call(() =>
        axiosInstance.get(
          apiUri(`${ENDPOINT_PROJECT_ARTIFACTS}?pg=0&pg_len=1&query=project="${projectId}";type="bom"`, 2),
        ),
      )) as AxiosResponse<Array<{ id: string }>>;

      const originalFileId = projArtifactRes.data[0].id;

      const res = (yield call(() =>
        axiosInstance.get(apiUri(`${ENDPOINT_PROJECT_ARTIFACTS}/${originalFileId}/download`, 2)),
      )) as AxiosResponse<Array<Raw.IBillOfMaterial>>;

      billOfMaterialList = res.data.map(serializeBillOfMaterials);
    }

    const pagination: IPagination = {
      page: _pagination.page || 0,
      perPage: _pagination.perPage || 50,
      total: billOfMaterialList.length,
      search: '',
    };

    yield put(
      getAllBillOfMaterialsSuccessAction(
        pagination,
        billOfMaterialList.slice(pagination.page * pagination.perPage, (pagination.page + 1) * pagination.perPage),
      ),
    );
  } catch (error) {
    yield put(
      getAllBillOfMaterialsErrorAction(
        getErrorMessage(error) || t('supernova:canvas.toasts.getBomError', 'Fetching BOM failed.'),
      ),
    );
  }
}

export const resetBillOfMaterial = (): void => {
  billOfMaterialList = [];
};

export function* getAllWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeLatest(BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS, getAllSaga),
    takeLatest(
      [BillOfMaterialActionTypes.RESET_BILL_OF_MATERIAL, ProjectsActionTypes.PROJECT_CANVAS_COMPILE_SUCCESS],
      resetBillOfMaterial,
    ),
  ]);
}
