import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';

import {
  ImplementationActionTypes,
  GetAllImplementationsAction,
  GetAllImplementationsErrorAction,
  GetAllImplementationsSuccessAction,
  GetAllFunctions,
} from 'state_management/actions/implementations/ActionTypes';
import {
  getAllImplementationsSuccess,
  getAllImplementationsError,
  ENDPOINT_IMPLEMENTATIONS,
  getAllFunctions,
} from 'state_management/actions/implementations/implementationsActions';
import { serializeImplementation } from 'utils/implementationSerializer';
import { ISpecification } from 'state_management/reducers/specifications/Modals';
import { Implementation } from 'state_management/reducers/implementations/Modals';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from 'utils/getErrorMessage';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { TypeSearch, getSearchQuery, combineSearchQueries, SpecificationsSchema } from 'utils/searchHelper';

export function* getAllSaga(
  action: GetAllImplementationsAction,
): Generator<
  | PutEffect<GetAllImplementationsAction>
  | TakeEffect
  | CallEffect<AxiosResponse<Array<Raw.Implementation>>>
  | SelectEffect
  | PutEffect<GetAllImplementationsSuccessAction | GetAllImplementationsErrorAction | GetAllFunctions>
> {
  try {
    const {
      implementationsState: { pagination },
      specificationsState: { specificationsList },
      authState: {
        userInfo,
        userSettings,
        featurePermissions: { USE_NEW_PROXY_SERVICE },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const implementationSearchSchema: TypeSearch = {
      name: 'value',
      short_description: 'value',
      full_info: 'value',
      functions: 'value',
      ports: {
        port_type: 'value',
      },
      schematic: {
        ports: {
          port_type: 'value',
        },
      },
    };
    const specificationsSchema: SpecificationsSchema = [
      { specDbKey: 'specifications.', specList: specificationsList.implementation as Array<ISpecification> },
      { specDbKey: 'specifications.pcb.', specList: specificationsList.pcb as Array<ISpecification> },
    ];

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), implementationSearchSchema, specificationsSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      action?.payload?.selectedWorkspaces || userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(
          `/dataservice/${ENDPOINT_IMPLEMENTATIONS}?pg=${page}&pg_len=${perPage}&${searchQuery}`,
          2,
          USE_NEW_PROXY_SERVICE.read === true,
        ),
      ),
    )) as AxiosResponse<Array<Raw.Implementation>>;

    const parsedImplementations: Array<Implementation> = res.data.map((i: Raw.Implementation) =>
      serializeImplementation(i, specificationsList),
    );
    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllImplementationsSuccess(_pagination, parsedImplementations));
    // NOTE: Implementations Edit and Create Form needs all Implementation Functions
    yield put(getAllFunctions());
  } catch (error) {
    yield put(getAllImplementationsError(getErrorMessage(error) || 'Fetching implementations failed'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ImplementationActionTypes.GET_ALL_IMPLEMENTATIONS, getAllSaga);
}
