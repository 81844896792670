import styled from 'styled-components';

// NOTE: Theme can be undefined when using outside of ThemeProvider on Root level
export const Container = styled.div<{ fullPage?: boolean }>`
  && {
    ${({ fullPage, theme }): string =>
      fullPage
        ? `
    overflow: hidden;
    position: fixed;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    background-color: ${theme?.palette?.loaderBg};
    `
        : ''}
  }
`;
