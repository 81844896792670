import { useTranslation } from 'react-i18next';
import Popover from 'components/Popover';
import { IProps } from './IProps';
import { Button, ErrorIcon, TooltipInfoContainer, WarningIcon, Wrapper } from './styles';

const ErrorButtons = ({ onClick, numberOfErrors, numberOfWarnings }: IProps): JSX.Element => {
  const { t } = useTranslation();
  const tooltip =
    numberOfErrors === 0 && numberOfWarnings === 0 && t('supernova:statusBar.errorButtons.tooltip', 'No issues found.');

  const buttons = (
    <>
      <Button
        onClick={(): void => onClick('errors')}
        hasIssues={numberOfErrors > 0}
        issueType="errors"
        icon={<ErrorIcon />}
      >
        {numberOfErrors}
      </Button>
      <Button
        onClick={(): void => onClick('warnings')}
        hasIssues={numberOfWarnings > 0}
        issueType="warnings"
        icon={<WarningIcon />}
      >
        {numberOfWarnings}
      </Button>
    </>
  );

  return (
    <Wrapper>
      {tooltip ? (
        <Popover noTip delay={0.5} hoverTrigger content={<TooltipInfoContainer>{tooltip}</TooltipInfoContainer>}>
          {buttons}
        </Popover>
      ) : (
        buttons
      )}
    </Wrapper>
  );
};

export default ErrorButtons;
