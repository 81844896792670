import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  DesignConsiderationsActionTypes,
  GetAllDesignConsiderationsAction,
} from 'state_management/actions/designConsiderations/ActionTypes';
import { getAllDesignConsiderationsAction } from 'state_management/actions/designConsiderations/designConsiderationsActions';
import { CategoriesActionTypes } from 'state_management/actions/categories/ActionTypes';
import { RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { ChangeLogActionTypes } from 'state_management/actions/changeLog/ActionTypes';

export function* refreshDesignConsiderations(): Generator<PutEffect<GetAllDesignConsiderationsAction>> {
  yield put(getAllDesignConsiderationsAction({ queryType: 'paginated' }));
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_SUCCESS,
      DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_SUCCESS,
      DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_SUCCESS,
      DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_SUCCESS,

      CategoriesActionTypes.GET_CATEGORY_DEPENDENCIES,
      RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
      ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
    ],
    refreshDesignConsiderations,
  );
}
