import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  ImplementationActionTypes,
  GetAllImplementationsAction,
} from 'state_management/actions/implementations/ActionTypes';
import { getAllImplementations } from 'state_management/actions/implementations/implementationsActions';

export function* refreshImplementations(): Generator<PutEffect<GetAllImplementationsAction>> {
  yield put(getAllImplementations());
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ImplementationActionTypes.CREATE_IMPLEMENTATION_SUCCESS,
      ImplementationActionTypes.UPDATE_IMPLEMENTATION_SUCCESS,
      ImplementationActionTypes.CLONE_IMPLEMENTATION_SUCCESS,
      ImplementationActionTypes.DELETE_IMPLEMENTATION_SUCCESS,
    ],
    refreshImplementations,
  );
}
