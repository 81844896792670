import { ICadFilesState, ICanvasStates, ICompilationStates, Project } from 'state_management/reducers/projects/Modals';

export const serializeProjectState = (
  rawState?: Raw.ProjectState,
): {
  canvasStates: ICanvasStates;
  cadFilesState: ICadFilesState;
  compilationStates: ICompilationStates;
} => ({
  canvasStates: {
    designCanvas: rawState?.tabs?.design_canvas || 'disabled',
    bomCanvas: rawState?.tabs?.bom_canvas || 'disabled',
    boardCanvas: rawState?.tabs?.board_canvas || 'disabled',
  },
  cadFilesState: {
    sch: rawState?.cad_files.files_sch || 'disabled',
    brd: rawState?.cad_files.files_brd || 'disabled',
    bom: rawState?.cad_files.files_bom || 'disabled',
    pdf: rawState?.cad_files.files_pdf || 'disabled',
  },
  compilationStates: {
    design: rawState?.compilation?.comp_design || 'disabled',
    board: rawState?.compilation?.comp_board || 'disabled',
  },
});

export const serializeProject = (rawProject: Raw.Project): Project => ({
  id: rawProject.projectID || rawProject.id || '',
  userId: rawProject.userID,
  title: rawProject.title,
  description: rawProject.description,
  image: rawProject.image || '',
  projectType: undefined,
  shareType: rawProject.share_type,
  canvasStates: serializeProjectState(rawProject.state).canvasStates,
  compilerState: rawProject.state?.state || 'EMPTY',
  cadTool: rawProject.cadTool || '',
  cadFilesState: serializeProjectState(rawProject.state).cadFilesState,
  pcbTechnology: rawProject.pcbTechnology || '',
  createdAt: rawProject.stats?.createdAt,
  updatedAt: rawProject.stats?.lastModified,
  optimizations: [],
  downloadFiles: [],
  compilationStates: serializeProjectState(rawProject.state).compilationStates,
  isRefDesign: rawProject.is_reference_design || false,
  originalProj: rawProject.original_project,
  bomType: rawProject.bom_type,
  routing: rawProject.routing,
  workspaces: rawProject.workspaces,
  numberOfLayers: rawProject.number_of_layers,
});

export const deserializeProject = (project: Partial<Project>): Partial<Raw.Project> => ({
  title: project.title,
  description: project.description,
  cadTool: project.cadTool,
  bom_type: project.bomType,
  routing: project.routing,
  workspaces: project.workspaces,
  number_of_layers: project.numberOfLayers,
  pcbTechnology: project.pcbTechnology,
});
