import { AxiosResponse } from 'axios';
import { ForkEffect, takeLatest, CallEffect, PutEffect, call, put } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  BomModificationActionTypes,
  GetBomModificationRulesAction,
} from 'state_management/actions/bomModification/ActionTypes';
import {
  getBomModificationsRulesFail,
  getBomModificationsRulesSuccess,
} from 'state_management/actions/bomModification/bomModificationActions';
import { BomModificationResponse } from 'state_management/reducers/bomModification/Modals';
import BomModificationSerializer, { RawBomModificationResponse } from 'utils/serializers/bomModificationSerializer';

function* getSaga(
  action: GetBomModificationRulesAction,
): Generator<CallEffect<AxiosResponse<BomModificationResponse>> | PutEffect> {
  const projectId = action?.payload;
  try {
    const result = (yield call(() =>
      axiosInstance.get(apiUri(`dataservice/compiler/bom-modification-rules/${projectId}`, 2)),
    )) as AxiosResponse<RawBomModificationResponse>;

    if (result?.data) {
      yield put(getBomModificationsRulesSuccess(BomModificationSerializer.deSerializeResponsePayload(result.data)));
    } else {
      yield put(getBomModificationsRulesFail('FAILED > BOM Modification GET request returns undefined data'));
    }
  } catch (error) {
    yield put(getBomModificationsRulesFail('FAILED > BOM Modification GET request encounters an unknown error'));
  }
}

const getSagaWatcherFunc = function* getSagaWatcher(): Generator<ForkEffect> {
  yield takeLatest(BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES, getSaga);
};

export default getSagaWatcherFunc;
