import { put, select, CallEffect, ForkEffect, PutEffect, takeLatest, SelectEffect } from 'redux-saga/effects';
import {
  ProjectGeneralSettingsActionTypes,
  GetProjectGeneralSettingsSuccessAction,
  GetProjectGeneralSettingsFailAction,
} from 'state_management/actions/projectGeneralSettings/ActionTypes';
import { NewNotification } from 'state_management/actions/notification/ActionTypes';
import {
  getProjectGeneralSettingsFail,
  getProjectGeneralSettingsSuccess,
} from 'state_management/actions/projectGeneralSettings/projectGeneralSettingsActions';
import { getDefaultBomType, getDefaultCadTool, listBomTypes, listCadTools } from 'services/projectGeneralSettings';
import { AppState } from 'state_management/AppState';
import { newNotification } from 'state_management/actions/notification/notificationActions';
import i18n from 'i18n/config';

export function* getProjectGeneralSettingsSaga(): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<GetProjectGeneralSettingsSuccessAction | GetProjectGeneralSettingsFailAction | NewNotification>
> {
  const t = i18n.t.bind(i18n);
  try {
    const {
      authState: { featurePermissions },
    } = (yield select((state: AppState) => state)) as AppState;

    const cadTools = listCadTools(featurePermissions);
    const bomTypes = listBomTypes(featurePermissions);

    yield put(
      getProjectGeneralSettingsSuccess({
        cadTools,
        defaultCadToolID: getDefaultCadTool(cadTools),
        bomTypes,
        defaultBomTypeID: getDefaultBomType(bomTypes),
      }),
    );
  } catch (error) {
    yield put(getProjectGeneralSettingsFail('Get general setting failed'));
    yield put(
      newNotification({
        type: 'error',
        autoClose: false,
        message: t('supernova:general.setting.get.failed', 'Application settings could not be loaded!'),
      }),
    );
  }
}

export default function* getProjectGeneralSettingsSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS, getProjectGeneralSettingsSaga);
}
