import { Plugin } from 'state_management/reducers/plugins/Modals';

export const parseURL = (baseURL: string, email?: string): string => {
  // NOTE: the url might have a placeholder, which means that the URL is dynamic
  // and the host, email or other variables would be inserted into it
  const url = baseURL.replace('{{host}}', window.location.host).replace('{{email}}', encodeURIComponent(email || ''));

  return url;
};

export const welcomeIframeUri = 'https://pages.celus.io/celus_login_overview';

export const createHomePlugin = (t: (s: string, d: string) => string, plugins?: Plugin[]): Plugin => ({
  id: 'home',
  label: t('account.home', 'Home'),
  icon: 'home',
  type: 'iframe',
  location: 'main-sidebar',
  extras: {
    url:
      (plugins || []).filter((p) => p.type === 'iframe' && p.location === 'main-home').pop()?.extras?.url ||
      welcomeIframeUri,
  },
});
