export const blockNameFormatting = (name: string, width: number, maxLines = 1): Array<string> => {
  // NOTE: Per 100px width / allow 13 Characters = 7.69
  const allowedNameLength = Math.floor(width / 7.69);
  const nameLength = name.length;

  const isOverflow = nameLength > allowedNameLength;

  if (isOverflow && maxLines > 1) {
    let prevLineNameLength = 0;
    return Array.from({ length: maxLines }, (_, index) => {
      const isLastLine = maxLines - 1 === index;
      let lastWordIndex = prevLineNameLength + allowedNameLength;

      let currentLineName = name.slice(prevLineNameLength, lastWordIndex);
      let currentLineNameLength = currentLineName.length;

      // NOTE: Split on Word when non-last line
      if (!isLastLine && currentLineName.includes(' ')) {
        lastWordIndex = currentLineName.lastIndexOf(' ');
        currentLineName = currentLineName.slice(0, lastWordIndex);

        // NOTE: +1 Adjust for dropped space
        currentLineNameLength = currentLineName.length + 1;
      }

      const remainingNameLength = name.slice(prevLineNameLength).length;
      const shouldBeTrimmed = isLastLine && remainingNameLength > currentLineNameLength;

      prevLineNameLength += currentLineNameLength;
      return shouldBeTrimmed ? `${currentLineName.slice(0, -3).trim()}...` : currentLineName;
    });
  }

  if (isOverflow && maxLines === 1) {
    return [
      name
        .slice(0, allowedNameLength - 3)
        .trim()
        .padEnd(Math.min(nameLength, allowedNameLength), '...'),
    ];
  }

  return [name];
};
