import { NewNotification, ResetNotificationsAction, NotificationActionTypes } from './ActionTypes';
import { Notification } from '../../reducers/notification/Modals';

export const newNotification = (notification: Notification): NewNotification => ({
  type: NotificationActionTypes.NEW_NOTIFICATION,
  payload: notification,
});

export const resetNotifications = (): ResetNotificationsAction => ({
  type: NotificationActionTypes.RESET_NOTIFICATIONS,
});
