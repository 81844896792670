import { SHEET_NAME_FROM_NONE } from 'components/ExportTemplatesForm/Modals';
import { IExportTemplate, ITemplateField } from 'state_management/reducers/exportTemplates/Modals';

export const deserializeExportTemplate = (exportTemplate: Partial<IExportTemplate>): Partial<Raw.IExportTemplate> => ({
  id: exportTemplate.id,
  created_at: exportTemplate.createdAt,
  modified_at: exportTemplate.modifiedAt,
  description: exportTemplate.description,
  resource: exportTemplate.resource,
  name: exportTemplate.name,
  fields: exportTemplate.fields?.map((field: ITemplateField) => ({
    value_from: field.valueFrom,
    name: field.name,
    name_from: field.nameFrom,
    type: field.type,
    type_from: field.typeFrom,
  })),
  sheet_name: exportTemplate.name,
  sheet_name_from: exportTemplate.sheetNameFrom === SHEET_NAME_FROM_NONE ? undefined : exportTemplate.sheetNameFrom,
});

export const serializeExportTemplate = (exportTemplate: Raw.IExportTemplate): IExportTemplate => ({
  id: exportTemplate.id,
  createdAt: exportTemplate.created_at,
  modifiedAt: exportTemplate.modified_at,
  sheetName: exportTemplate.sheet_name,
  sheetNameFrom: exportTemplate.sheet_name_from,
  description: exportTemplate.description,
  resource: exportTemplate.resource,
  name: exportTemplate.name,
  fields: exportTemplate.fields?.map((field: Raw.ITemplateField) => ({
    valueFrom: field.value_from,
    name: field.name,
    nameFrom: field.name_from,
    type: field.type,
    typeFrom: field.type_from,
  })),
});
