import { IDynamicInput, IDynamicInputType } from 'models/ICanvas';

export const serializeDynamicOption = (option: RawCanvasLibrary.Option): IDynamicInput => ({
  id: option.NameID,
  name: option.NameID,
  description: option.Description || '',
  createdAt: '',
  type: option.Type as IDynamicInputType,
  value: option.Value,
  options: option.Optional,
  dataType: option.DataType || 'string',
});

export const deserializeDynamicOption = (payload: IDynamicInput): RawCanvasLibrary.Option => ({
  NameID: payload.id,
  Type: payload.type,
  Value: payload.value,
  Optional: payload.options || [],
});
