import {
  ProjectsActionTypes,
  ProjectSaveSuccessAction,
  ProjectsUpdateErrorAction,
  ProjectsUpdateSuccessAction,
  ProjectDownloadFileBomErrorAction,
  ProjectDownloadFileLayoutErrorAction,
  ProjectDownloadFileSchematicsErrorAction,
  ProjectDownloadFilePdfErrorAction,
  ProjectsCloneSuccessAction,
  ProjectsCloneErrorAction,
  ProjectsAddErrorAction,
  ProjectsAddSuccessAction,
  ProjectsDeleteSuccessAction,
  ProjectsDeleteErrorAction,
  ProjectsGetAllErrorAction,
  ProjectCanvasCompileErrorAction,
} from 'state_management/actions/projects/ActionTypes';
import { all, AllEffect, takeLatest, ForkEffect, takeEvery } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { DesignSaveInfo, BoardShapeSaveInfo } from 'components/ProjectSaveInfo';
import {
  BoardShapeCanvasActionTypes,
  SaveBoardShapeCanvasSuccessAction,
} from 'state_management/actions/boardShapeCanvas/ActionTypes';
import i18n from 'i18n/config';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const projectCreateToasts = (action: ProjectsAddSuccessAction | ProjectsAddErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.projectCreateLoading', 'Creating project, please wait...'),
    success: t('supernova:toasts.projectCreateSuccess', 'Successfully created the project'),
    error:
      (action as ProjectsAddErrorAction).payload || t('supernova:toasts.projectCreateError', 'Creating project failed'),
  });
};

const projectUpdateToasts = (action: ProjectsUpdateSuccessAction | ProjectsUpdateErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.projectUpdateLoading', 'Updating project, please wait...'),
    success: t('supernova:toasts.projectUpdateSuccess', 'Successfully updated the project'),
    error:
      (action as ProjectsUpdateErrorAction).payload ||
      t('supernova:toasts.projectUpdateError', 'Updating project failed'),
  });
};

const projectDeleteToasts = (action: ProjectsDeleteSuccessAction | ProjectsDeleteErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.projectDeleteLoading', 'Deleting project, please wait...'),
    success: t('supernova:toasts.projectDeleteSuccess', 'Successfully deleted the project'),
    error:
      (action as ProjectsDeleteErrorAction).payload ||
      t('supernova:toasts.projectDeleteError', 'Deleting project failed'),
  });
};

const projectCloneToasts = (action: ProjectsCloneSuccessAction | ProjectsCloneErrorAction): void => {
  const t = i18n.t.bind(i18n);

  globalToast(undefined, action.type, {
    loading: t('supernova:toasts.projectCloneLoading', 'Cloning project, please wait...'),
    success: t('supernova:toasts.projectCloneSuccess', 'Successfully cloned the project'),
    error:
      (action as ProjectsCloneErrorAction).payload || t('supernova:toasts.projectCloneError', 'Cloning project failed'),
  });
};

const projectAfterSaveToasts = (action: ProjectSaveSuccessAction | SaveBoardShapeCanvasSuccessAction): void => {
  const ProjectSaveInfo =
    action.type === ProjectsActionTypes.PROJECT_SAVE_SUCCESS ? DesignSaveInfo : BoardShapeSaveInfo;

  TOAST_IDS.afterSaveToast = globalToast(
    TOAST_IDS.afterSaveToast,
    'idle',
    {
      idle: ProjectSaveInfo,
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.afterSaveToast },
  );
};

const projectFilesDownloadToasts = (
  action:
    | ProjectDownloadFileBomErrorAction
    | ProjectDownloadFileLayoutErrorAction
    | ProjectDownloadFileSchematicsErrorAction
    | ProjectDownloadFilePdfErrorAction,
): void => {
  const t = i18n.t.bind(i18n);

  globalToast(
    undefined,
    action.type,
    {
      error: action.payload || t('supernova:toasts.projectDownloadError', 'Downloading project files failed'),
    },
    { autoClose: false },
  );
};

const projectGetAllToasts = (action: ProjectsGetAllErrorAction): void => {
  globalToast(undefined, action.type, {
    error: action.payload || 'Failed to fetch Projects',
  });
};

const projectCompileToasts = (action: ProjectCanvasCompileErrorAction): void => {
  globalToast(
    undefined,
    action.type,
    {
      error:
        action.payload.designCompileRequestError ||
        action.payload.boardCompileRequestError ||
        'Failed to compile project',
    },
    { autoClose: false },
  );
};

export function* requestsToastsSagaWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        ProjectsActionTypes.PROJECTS_UPDATE,
        ProjectsActionTypes.PROJECTS_UPDATE_SUCCESS,
        ProjectsActionTypes.PROJECTS_UPDATE_FAIL,
      ],
      projectUpdateToasts,
    ),

    takeLatest(
      [ProjectsActionTypes.PROJECT_SAVE_SUCCESS, BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE_SUCCESS],
      projectAfterSaveToasts,
    ),
    takeLatest(
      [
        ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_FAIL,
        ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_FAIL,
        ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL,
        ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_FAIL,
      ],
      projectFilesDownloadToasts,
    ),
    takeEvery(
      [
        ProjectsActionTypes.PROJECTS_CLONE,
        ProjectsActionTypes.PROJECTS_CLONE_SUCCESS,
        ProjectsActionTypes.PROJECTS_CLONE_FAIL,
      ],
      projectCloneToasts,
    ),
    takeEvery(
      [
        ProjectsActionTypes.PROJECTS_ADD,
        ProjectsActionTypes.PROJECTS_ADD_SUCCESS,
        ProjectsActionTypes.PROJECTS_ADD_FAIL,
      ],
      projectCreateToasts,
    ),
    takeEvery(
      [
        ProjectsActionTypes.PROJECTS_DELETE,
        ProjectsActionTypes.PROJECTS_DELETE_SUCCESS,
        ProjectsActionTypes.PROJECTS_DELETE_FAIL,
      ],
      projectDeleteToasts,
    ),
    takeLatest([ProjectsActionTypes.PROJECTS_GET_ALL_FAIL], projectGetAllToasts),
    takeLatest([ProjectsActionTypes.PROJECT_CANVAS_COMPILE_FAIL], projectCompileToasts),
  ]);
}
