import styled from 'styled-components';
import { motion } from 'framer-motion';

import { DOMLocation } from './Modals';

// NOTE: `section` to avoid child selectors `& > div`
export const Container = styled.section`
  display: inline-block;
  position: relative;
`;

export const ContentContainer = styled(motion.div)<{
  toolTipDirection: DOMLocation;
  toolTipStyles: Partial<DOMRect>;
  noTip?: true;
}>`
  z-index: 10;
  position: relative;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }): string => theme.palette.white};
  border-radius: 4px;
  box-shadow: ${({ theme }): string => theme.shadow.prominent};
  ${({ toolTipDirection, toolTipStyles, noTip, theme }): string => `
    &:before {
      background: ${theme.palette.white};
      box-shadow: ${theme.shadow.prominent};
      content: "";
      display: ${noTip ? 'none' : 'block'};
      width: 18px;
      height: 18px;
      position: absolute;
      ${toolTipDirection}: -9px;
      ${JSON.stringify(toolTipStyles).replace(/"/g, '')};
      transform: rotate(45deg); 
    }
`}
`;

export const Content = styled.div`
  background-color: ${({ theme }): string => theme.palette.white};
  border-radius: 4px;
  position: relative;
`;
