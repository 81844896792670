import { IManufacturer } from 'state_management/reducers/manufacturers/Modals';
import { deserializeManufacturer } from 'utils/manufacturersSerializer';
import { IPagination } from 'models/Pagination';
import {
  GetAllManufacturersAction,
  CreateManufacturerAction,
  UpdateManufacturerAction,
  DeleteManufacturerAction,
  ManufacturersActionTypes,
  GetManufacturerByIdAction,
  GetManufacturerByIdSuccessAction,
  GetManufacturerByIdErrorAction,
  GetAllManufacturersErrorAction,
  GetAllManufacturersSuccessAction,
  CloneManufacturerAction,
  GetManufacturerDependenciesAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const ENDPOINT_MANUFACTURERS = 'manufacturers';

export const getAllManufacturersAction = (payload: Partial<IPagination> = {}): GetAllManufacturersAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: ManufacturersActionTypes.GET_ALL_MANUFACTURERS,
});

export const getAllManufacturersSuccessAction = (
  pagination: IPagination,
  data: Array<IManufacturer>,
): GetAllManufacturersSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: ManufacturersActionTypes.GET_ALL_MANUFACTURERS_SUCCESS,
});

export const getAllManufacturersErrorAction = (payload: string): GetAllManufacturersErrorAction => ({
  payload,
  type: ManufacturersActionTypes.GET_ALL_MANUFACTURERS_FAIL,
});

export const getManufacturerDependenciesAction = (): GetManufacturerDependenciesAction => ({
  type: ManufacturersActionTypes.GET_MANUFACTURER_DEPENDENCIES,
});

export const createManufacturer = (manufacturer: Partial<IManufacturer>): CreateManufacturerAction => ({
  type: ManufacturersActionTypes.CREATE_MANUFACTURER,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_MANUFACTURERS}`, 2),
      data: deserializeManufacturer(manufacturer) as unknown as Raw.IManufacturer,
    },
  },
});

export const updateManufacturer = (manufacturer: IManufacturer): UpdateManufacturerAction => ({
  type: ManufacturersActionTypes.UPDATE_MANUFACTURER,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_MANUFACTURERS}/${manufacturer.id}`, 2),
      data: deserializeManufacturer(manufacturer) as unknown as Raw.IManufacturer,
    },
  },
});

export const cloneManufacturer = (manufacturer: Partial<IManufacturer>): CloneManufacturerAction => ({
  type: ManufacturersActionTypes.CLONE_MANUFACTURER,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_MANUFACTURERS}`, 2),
      data: deserializeManufacturer(manufacturer) as unknown as Raw.IManufacturer,
    },
  },
});

export const deleteManufacturer = (manufacturer: Partial<IManufacturer>): DeleteManufacturerAction => ({
  type: ManufacturersActionTypes.DELETE_MANUFACTURER,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_MANUFACTURERS}/${manufacturer.id}`, 2),
    },
  },
});

export const getManufacturerById = (payload: Partial<IManufacturer>): GetManufacturerByIdAction => ({
  type: ManufacturersActionTypes.GET_MANUFACTURER_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getManufacturerByIdSuccess = (payload: IManufacturer): GetManufacturerByIdSuccessAction => ({
  type: ManufacturersActionTypes.GET_MANUFACTURER_BY_ID_SUCCESS,
  payload,
});

export const getManufacturerByIdError = (payload: string): GetManufacturerByIdErrorAction => ({
  type: ManufacturersActionTypes.GET_MANUFACTURER_BY_ID_FAIL,
  payload,
});
