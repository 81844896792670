import {
  VirtualComponentsActionTypes,
  VirtualComponentActions,
  UpdateVirtualComponentErrorAction,
  CreateVirtualComponentErrorAction,
  DeleteVirtualComponentErrorAction,
} from 'state_management/actions/virtualComponents/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const virtualComponentsUpdateToasts = (action: VirtualComponentActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Virtual Component is being updated, please wait...',
      success: 'Virtual Component has been successfully updated.',
      error:
        getErrorMessage((action as UpdateVirtualComponentErrorAction).error) ||
        'Updating Virtual Component failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const virtualComponentsCloneToasts = (action: VirtualComponentActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Virtual Component is being cloned, please wait...',
      success: 'Virtual Component has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateVirtualComponentErrorAction).error) ||
        'Cloning Virtual Component failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const virtualComponentsCreateToasts = (action: VirtualComponentActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Virtual Component is being created, please wait...',
      success: 'Virtual Component has been successfully created.',
      error:
        getErrorMessage((action as CreateVirtualComponentErrorAction).error) ||
        'Creating Virtual Component failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const virtualComponentsDeleteToasts = (action: VirtualComponentActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Virtual Component is being deleted, please wait...',
      success: 'Virtual Component has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteVirtualComponentErrorAction).error) ||
        'Deleting Virtual Component failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT,
        VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_SUCCESS,
        VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_FAIL,
      ],
      virtualComponentsCreateToasts,
    ),
    takeEvery(
      [
        VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT,
        VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_SUCCESS,
        VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_FAIL,
      ],
      virtualComponentsUpdateToasts,
    ),
    takeEvery(
      [
        VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT,
        VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_SUCCESS,
        VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_FAIL,
      ],
      virtualComponentsCloneToasts,
    ),
    takeEvery(
      [
        VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT,
        VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_SUCCESS,
        VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_FAIL,
      ],
      virtualComponentsDeleteToasts,
    ),
  ]);
}
