import {
  FeatToggleSubSystemCanvasAction,
  FeatToggleSubSystemCanvasCreateAction,
  FeatureActionTypes,
  FeatToggleLanguageSelection,
  FeatToggleFutureFormsMocks,
  FeatToggleRetryChangeRequest,
} from './ActionTypes';

export const featureToggleSubSystemCanvasAction = (): FeatToggleSubSystemCanvasAction => ({
  type: FeatureActionTypes.FEAT_TOGGLE_SUB_SYSTEM_CANVAS,
});

export const featureToggleSubSystemCanvasCreate = (): FeatToggleSubSystemCanvasCreateAction => ({
  type: FeatureActionTypes.FEAT_TOGGLE_SUB_SYSTEM_CANVAS_CREATE,
});

export const featToggleLanguageSelection = (): FeatToggleLanguageSelection => ({
  type: FeatureActionTypes.FEAT_TOGGLE_LANGUAGE_SELECTION,
});

export const featToggleFutureFormsMocks = (): FeatToggleFutureFormsMocks => ({
  type: FeatureActionTypes.FEAT_TOGGLE_FUTURE_FORMS_MOCKS,
});

export const featToggleRetryChangeRequest = (): FeatToggleRetryChangeRequest => ({
  type: FeatureActionTypes.FEAT_TOGGLE_RETRY_CHANGE_REQUEST,
});
