import { ComponentType } from 'react';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Loader from 'components/Loader';
import Chip from '@material-ui/core/Chip';
import { PopperProps } from '@material-ui/core';
import FormFieldClickBlocker from 'components/FormFieldClickBlocker';
import InfoIcon from '../Icons/InfoIcon';

import { IProps } from './IProps';

import { FormContainer, FormControlContainer } from './styles';

function Autocomplete<
  TOption,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>({
  id,
  label,
  value,
  required,
  multiple,
  options = [],
  error,
  autoFocus,
  allowUnknown,
  disableClearable,
  noMargin,
  loading,
  info,
  placeholder,
  noOptionsText,
  submitOnInputChange,
  disabled,
  limitTags,
  customClasses,
  disableCloseOnSelect,
  isClickedBlocked = false,
  popperComponent,
  renderOption,
  getOptionLabel,
  onInputChange,
  onChange,
}: IProps<TOption, Multiple, DisableClearable, FreeSolo>): JSX.Element {
  const onSelectChangeHandler = (
    _: React.ChangeEvent<Record<string, string>>,
    val: IProps<TOption, Multiple, DisableClearable, boolean>['value'],
  ): void => {
    onChange(val);
  };

  const handleInputChange = (
    _: React.ChangeEvent<Record<string, string>>,
    inputValue: string,
    reason: 'input' | 'reset' | 'clear',
  ): void => {
    onInputChange && onInputChange(inputValue, reason);

    submitOnInputChange && onChange(inputValue as IProps<TOption, Multiple, DisableClearable, boolean>['value']);
  };

  return (
    <FormContainer>
      <FormControlContainer noMargin={noMargin} hasInfo={!!info} disabled={disabled}>
        <MUIAutocomplete
          id={id ? `autocomplete-${id}` : undefined}
          PopperComponent={popperComponent as unknown as ComponentType<PopperProps>}
          classes={customClasses}
          options={options}
          multiple={multiple}
          disableClearable={disableClearable}
          disableCloseOnSelect={disableCloseOnSelect}
          fullWidth
          autoHighlight
          noOptionsText={noOptionsText}
          freeSolo={allowUnknown}
          autoSelect={allowUnknown} // NOTE: autoSelect is needed to use the freeSolo (doesn't break the other usage)
          // NOTE: Adding null because there is a bug on initial render
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value={(value as IProps<TOption, Multiple, DisableClearable, boolean | undefined>['value']) || null}
          loading={loading}
          limitTags={limitTags}
          onChange={onSelectChangeHandler}
          onInputChange={handleInputChange}
          getOptionLabel={getOptionLabel}
          disabled={disabled}
          renderTags={(tagValue, getTagProps): Array<JSX.Element> =>
            tagValue.map((option, index) => (
              <Chip label={getOptionLabel ? getOptionLabel(option) : option} {...getTagProps({ index })} />
            ))
          }
          renderOption={renderOption}
          renderInput={(params): JSX.Element => (
            <TextField
              {...params}
              id={id}
              fullWidth
              size="small"
              label={label}
              error={error}
              variant="outlined"
              required={required}
              autoFocus={autoFocus}
              placeholder={multiple && (value as Array<TOption>)?.length ? undefined : placeholder}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <Loader size={12} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {info && (
          <Tooltip arrow title={info} color="primary">
            <InfoIcon />
          </Tooltip>
        )}
        {isClickedBlocked ? <FormFieldClickBlocker /> : <></>}
      </FormControlContainer>
    </FormContainer>
  );
}

export default Autocomplete;
