import { upsertResource } from 'utils/resourceUtils';
import { ManufacturersState } from './Modals';
import { ManufacturerActions, ManufacturersActionTypes } from '../../actions/manufacturers/ActionTypes';

export const initialStateManufacturers: ManufacturersState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  manufacturersList: [],
  manufacturersListState: 'idle',
  manufacturersListError: '',
  manufacturerCreateState: 'idle',
  manufacturerCreateError: '',
  manufacturerUpdateState: 'idle',
  manufacturerUpdateError: '',
  manufacturerDeleteState: 'idle',
  manufacturerDeleteError: '',
  manufacturerByIdState: 'idle',
  manufacturerByIdStateError: '',
};

export const manufacturersReducer = (
  state: ManufacturersState = initialStateManufacturers,
  action: ManufacturerActions,
): ManufacturersState => {
  switch (action.type) {
    case ManufacturersActionTypes.GET_ALL_MANUFACTURERS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        manufacturersListState: 'loading',
      };
    case ManufacturersActionTypes.GET_ALL_MANUFACTURERS_SUCCESS:
      return {
        ...state,
        manufacturersListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        manufacturersList: action.payload.data,
      };
    case ManufacturersActionTypes.GET_ALL_MANUFACTURERS_FAIL:
      return {
        ...state,
        manufacturersListState: 'error',
        manufacturersListError: action.payload,
      };
    case ManufacturersActionTypes.CREATE_MANUFACTURER:
      return {
        ...state,
        manufacturerCreateState: 'loading',
      };
    case ManufacturersActionTypes.CREATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        manufacturerCreateState: 'success',
      };
    case ManufacturersActionTypes.CREATE_MANUFACTURER_FAIL:
      return {
        ...state,
        manufacturerCreateState: 'error',
        manufacturerCreateError: action.error.message,
      };
    case ManufacturersActionTypes.UPDATE_MANUFACTURER:
      return {
        ...state,
        manufacturerUpdateState: 'loading',
      };
    case ManufacturersActionTypes.UPDATE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        manufacturerUpdateState: 'success',
      };
    case ManufacturersActionTypes.UPDATE_MANUFACTURER_FAIL:
      return {
        ...state,
        manufacturerUpdateState: 'error',
        manufacturerUpdateError: action.error.message,
      };
    case ManufacturersActionTypes.DELETE_MANUFACTURER:
      return {
        ...state,
        manufacturerDeleteState: 'loading',
      };
    case ManufacturersActionTypes.DELETE_MANUFACTURER_SUCCESS:
      return {
        ...state,
        manufacturerDeleteState: 'success',
      };
    case ManufacturersActionTypes.DELETE_MANUFACTURER_FAIL:
      return {
        ...state,
        manufacturerDeleteState: 'error',
        manufacturerDeleteError: action.error.message,
      };
    case ManufacturersActionTypes.GET_MANUFACTURER_BY_ID:
      return {
        ...state,
        manufacturerByIdState: 'loading',
      };
    case ManufacturersActionTypes.GET_MANUFACTURER_BY_ID_SUCCESS:
      return {
        ...state,
        manufacturerByIdState: 'success',
        manufacturersList: upsertResource(state.manufacturersList, action.payload),
      };
    case ManufacturersActionTypes.GET_MANUFACTURER_BY_ID_FAIL:
      return {
        ...state,
        manufacturerByIdState: 'error',
        manufacturerByIdStateError: action.payload,
      };
    default:
      return state;
  }
};
