import { useEffect } from 'react';

import { IProps } from './IProps';

const PageTitle = ({ tabName = 'Celus', title, children }: IProps): JSX.Element => {
  const appType = tabName.toLowerCase();
  const appBaseTitle = appType[0].toUpperCase() + appType.slice(1);

  useEffect(() => {
    document.title = title ? `${appBaseTitle} - ${title}` : appBaseTitle;
  }, [title, tabName]);

  return children;
};

export default PageTitle;
