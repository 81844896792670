export enum FeatureActionTypes {
  // Supernova
  FEAT_TOGGLE_SUB_SYSTEM_CANVAS = 'FEAT_TOGGLE_SUB_SYSTEM_CANVAS',
  FEAT_TOGGLE_SUB_SYSTEM_CANVAS_CREATE = 'FEAT_TOGGLE_SUB_SYSTEM_CANVAS_CREATE',

  // Orbit
  FEAT_TOGGLE_CHANGE_LOG_SERVICE = 'FEAT_TOGGLE_CHANGE_LOG_SERVICE',
  FEAT_TOGGLE_FUTURE_FORMS_MOCKS = 'FEAT_TOGGLE_FUTURE_FORMS_MOCKS',
  FEAT_TOGGLE_RETRY_CHANGE_REQUEST = 'FEAT_TOGGLE_RETRY_CHANGE_REQUEST',

  // Main
  FEAT_TOGGLE_LANGUAGE_SELECTION = 'FEAT_TOGGLE_LANGUAGE_SELECTION',
}

// ============================================ FEAT_TOGGLE_FUTURE_FORMS_MOCKS

export interface FeatToggleFutureFormsMocks {
  type: FeatureActionTypes.FEAT_TOGGLE_FUTURE_FORMS_MOCKS;
}

// ============================================ FEAT_TOGGLE_SUB_SYSTEM_CANVAS

export interface FeatToggleSubSystemCanvasAction {
  type: FeatureActionTypes.FEAT_TOGGLE_SUB_SYSTEM_CANVAS;
}

// ============================================ FEAT_TOGGLE_SUB_SYSTEM_CANVAS_CREATE

export interface FeatToggleSubSystemCanvasCreateAction {
  type: FeatureActionTypes.FEAT_TOGGLE_SUB_SYSTEM_CANVAS_CREATE;
}

// ============================================ FEAT_TOGGLE_LANGUAGE_SELECTION

export interface FeatToggleLanguageSelection {
  type: FeatureActionTypes.FEAT_TOGGLE_LANGUAGE_SELECTION;
}

// ============================================ FEAT_TOGGLE_LANGUAGE_SELECTION

export interface FeatToggleRetryChangeRequest {
  type: FeatureActionTypes.FEAT_TOGGLE_RETRY_CHANGE_REQUEST;
}

// ============================================ ALL

export type FeaturesActions =
  | FeatToggleFutureFormsMocks
  | FeatToggleSubSystemCanvasAction
  | FeatToggleSubSystemCanvasCreateAction
  | FeatToggleLanguageSelection
  | FeatToggleRetryChangeRequest;
