import * as jsts from 'jsts/dist/jsts';

const vectorCoordinates2JTS = (polygon) => polygon.map((pt) => new jsts.geom.Coordinate(pt.x, pt.y));

const round = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

export const getInsetPoly = (poly, spacing) => {
  const geoInput = vectorCoordinates2JTS(poly);
  geoInput.push(geoInput[0]);
  const geometryFactory = new jsts.geom.GeometryFactory();
  const shell = geometryFactory.createPolygon(geoInput);
  const polygon = shell.buffer(-spacing);
  // const polygon = shell.buffer(-spacing, [], jsts.operation.buffer.BufferParameters.CAP_SQUARE);
  if (!polygon._shell) return null;
  return polygon._shell._points._coordinates.map((pt) => ({ x: round(pt.x), y: round(pt.y) }));
};

/**
 * Replaces an angle (in degrees) with a congruent alternative constrained between the range [0, 360[
 */
export const constrainAngle = (angle) => {
  let resAng = angle;
  while (resAng < 0) resAng += 360;
  return resAng % 360;
};
/**
 * Flip an angle in degrees (from clock-wise to counter-clockwise and vice-versa)
 */
export const flipAngle = (ccAngle) => (720 - constrainAngle(ccAngle)) % 360;

/**
 * Flip all angles of an array of objects that contain a `rotation` key
 */
export const flipAllAngles = (array) =>
  array.map((obj) => ('rotation' in obj ? { ...obj, rotation: flipAngle(obj.rotation) } : obj));

/**
 * Return true if the polygon provided is closed (first and last points are the same), and false otherwise
 */
const isClosed = (poly) => poly[0].x === poly[poly.length - 1].x && poly[0].y === poly[poly.length - 1].y;

/**
 * Returns an open polygon from the provided set of points.
 */
export const getOpenPoly = (poly) => (isClosed(poly) ? poly.slice(0, poly.length - 1) : poly);

/**
 * Returns a closed polygon from the provided set of points.
 */
export const getClosedPoly = (poly) => [...getOpenPoly(poly), poly[0]];
