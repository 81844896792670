const createInput = (inputName: string, value: string, _document: Document): HTMLInputElement => {
  const input: HTMLInputElement = _document.createElement('input');
  input.name = inputName;
  input.value = value;

  return input;
};

export const submitFormOnForeignOrigin = (
  formName: string,
  origin: string,
  values: Array<{ name: string; value: string }>,
): void => {
  const previousForm = document.getElementById(formName);
  // NOTE: Cleanup if needed
  previousForm && document.body.removeChild(previousForm);

  const form = document.createElement('form');

  form.name = formName;
  form.id = formName;
  form.action = origin;
  form.method = 'post';
  form.target = '_blank';
  form.hidden = true;
  // NOTE: this protects against a vulnerability when having target as '_blank'
  // Without it, the linked page gains partial access to the linking page via the window.opener object.
  form.setAttribute('rel', 'noopener noreferrer');

  values.forEach(({ name, value }) => {
    form.appendChild(createInput(name, value, document));
  });

  document.body.appendChild(form);

  try {
    form.submit();
  } catch (error) {
    throw new Error(
      'Failed to reach the BOM service. Ensure that pop-ups are not blocked for this website in your browser',
    );
  }
};
