import styled from 'styled-components';
import MUIFormControl from '@material-ui/core/FormControl';
import MUIHelpOutline from '@material-ui/icons/HelpOutline';
import MUIIconButton from '@material-ui/core/IconButton';

export const FormControl = styled(MUIFormControl)`
  && {
    margin: 0px !important;
    width: 100%;

    & > div {
      margin: ${({ theme }): number => theme.spacing.small}px 0;
      width: 100%;
    }

    & > div > div {
      background-color: ${({ theme, disabled }): string => (disabled ? theme.palette.canvasBg : theme.palette.white)};
    }

    & label {
      text-transform: capitalize;
    }

    & input {
      min-height: 19px;
    }
  }
`;

export const HelpOutline = styled(MUIHelpOutline)`
  && {
    opacity: 0;
    width: 0px !important;
    overflow: hidden;
  }
`;

export const IconButton = styled(MUIIconButton)`
  && {
    width: 2rem;
  }
`;

export const TooltipInfoContainer = styled.div`
  && {
    margin: 0;
    min-width: 200px;
    max-width: 200px;
    padding: 12px;
    white-space: pre-wrap;
  }
`;
