import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const isProduction = process.env.NODE_ENV === 'production';
i18n
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // lazy load translations
  .use(Backend)
  .init(
    {
      react: {
        useSuspense: false,
      },
      detection: {
        // , 'navigator' is out for now. Add it once we release it fully
        order: ['querystring', 'cookie', 'localStorage', 'htmlTag'],
      },
      ns: ['main', 'orbit', 'supernova'],
      // Set default namespace
      defaultNS: 'main',
      debug: !isProduction,
      fallbackLng: 'en',
      saveMissing: !isProduction,
      saveMissingTo: 'fallback',
      backend: {
        loadPath: '/api/v2/i18n/{{ns}}/{{lng}}',
      },
    },
    (error) => {
      if (error) {
        // Sending error to sentry in the future
        // eslint-disable-next-line no-console
        console.log('Error when initializing i18n', error);
      }
    },
  );

export default i18n;
