import {
  ProjectGeneralSettingsActionTypes,
  ProjectGeneralSettingsSuccessPayload,
  GetProjectGeneralSettingsAction,
  GetProjectGeneralSettingsFailAction,
  GetProjectGeneralSettingsSuccessAction,
} from './ActionTypes';

export const getProjectGeneralSettings = (): GetProjectGeneralSettingsAction => ({
  type: ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS,
});

export const getProjectGeneralSettingsSuccess = (
  payload: ProjectGeneralSettingsSuccessPayload,
): GetProjectGeneralSettingsSuccessAction => ({
  type: ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS_SUCCESS,
  payload,
});

export const getProjectGeneralSettingsFail = (payload: string): GetProjectGeneralSettingsFailAction => ({
  type: ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS_FAIL,
  payload,
});
