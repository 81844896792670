/* eslint-disable no-restricted-syntax */
import { AxiosError, AxiosResponse } from 'axios';
import {
  call,
  PutEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  TakeEffect,
  take,
  race,
  RaceEffect,
} from 'redux-saga/effects';

import { axiosInstance } from 'services/dataService';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  ProjectsActionTypes,
  ProjectCanvasCompileAction,
  ProjectImportAction,
  ProjectsAddSuccessAction,
  ProjectsAddErrorAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  addProject,
  projectImageUpdateAction,
  projectImportErrorAction,
  projectImportSuccessAction,
  setCurrentProject,
  AVAIL_INTEGRATION_PROJECT_IMPORT_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { serializeProject } from 'utils/projectSerializer';
import { Project } from 'state_management/reducers/projects/Modals';
import {
  deleteLocalProjects,
  getLocalCanvasByProjectId,
  getLocalScreenshotByProjectId,
} from 'services/localProjectsService';
import { saveDesignCanvas } from 'state_management/actions/systemDesignCanvas/systemDesignCanvasActions';
import {
  SaveDesignCanvasErrorAction,
  SaveDesignCanvasSuccessAction,
  SystemDesignCanvasActionTypes,
} from 'state_management/actions/systemDesignCanvas/ActionTypes';
import { apiUri } from 'services/main_app';

export function* projectImportSaga(
  action: ProjectImportAction,
): Generator<PutEffect<ProjectCanvasCompileAction> | CallEffect | PutEffect | TakeEffect | RaceEffect<TakeEffect>> {
  const { data, type } = action.payload;

  let project = {} as Project;

  try {
    if (type === 'localProject') {
      const projects = data as Array<Partial<Project>>;

      for (const p of projects) {
        const localProjectId = p.id as string;

        // NOTE: Create Project
        yield put(addProject({ ...p, id: undefined }));
        const addProjectResult = yield race({
          success: take(ProjectsActionTypes.PROJECTS_ADD_SUCCESS),
          error: take(ProjectsActionTypes.PROJECTS_ADD_FAIL),
        });

        const { success: projectAddSuccess, error: projectAddError } = addProjectResult as {
          success: ProjectsAddSuccessAction;
          error: ProjectsAddErrorAction;
        };

        if (projectAddError) {
          throw new Error(projectAddError.payload);
        }

        const { payload: rawProject } = projectAddSuccess;
        // NOTE: Set Created Project as Current Project (so `id` can be pulled from Store)
        project = serializeProject(rawProject as Raw.Project);
        yield put(setCurrentProject(project));

        // NOTE: Create System Design Canvas for Current Project
        const localCanvas = getLocalCanvasByProjectId(localProjectId);
        yield put(saveDesignCanvas(localCanvas, '2'));
        const saveCanvasResult = yield race({
          success: take(SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_SUCCESS),
          error: take(SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_SUCCESS),
        });
        const { error: saveCanvasError } = saveCanvasResult as {
          success: SaveDesignCanvasSuccessAction;
          error: SaveDesignCanvasErrorAction;
        };

        if (saveCanvasError) {
          throw new Error(saveCanvasError.payload);
        }

        // NOTE: Update/Upload Screenshot of current Project
        const imageData = getLocalScreenshotByProjectId(localProjectId);
        imageData && (yield put(projectImageUpdateAction(imageData)));
        imageData && (yield take(ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_SUCCESS));
      }
    } else {
      const res = yield call(axiosInstance.post, apiUri(AVAIL_INTEGRATION_PROJECT_IMPORT_ENDPOINT, 2), {
        projectId: data as string,
      });
      project = serializeProject((res as AxiosResponse).data);
    }

    yield put(projectImportSuccessAction(project));
  } catch (error) {
    yield put(
      projectImportErrorAction(
        (error as AxiosError).response?.data?.message?.message || getErrorMessage(error) || 'Project import failed',
      ),
    );
  } finally {
    // NOTE: Clean up if local projects
    if (type === 'localProject') {
      deleteLocalProjects();
    }
  }
}

export function* projectImportSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECT_IMPORT, projectImportSaga);
}
