import { ICoords, ICanvas, IModule } from 'state_management/reducers/boardCanvas/Modals';
import {
  GetBoardShapeCanvasAction,
  BoardShapeCanvasActionTypes,
  SaveBoardShapeCanvasAction,
  SetBoardShapeCanvasBlocksAction,
  BoardShapeCanvasSetShapeAction,
  SetBoardShapeCanvasLockAction,
  SaveBoardShapeCanvasSuccessAction,
  SaveBoardShapeCanvasErrorAction,
  GetBoardShapeCanvasSuccessAction,
  GetBoardShapeCanvasErrorAction,
  ClearBoardShapeCanvasAction,
} from './ActionTypes';

export const getBoardShapeCanvasAction = (projectId: string): GetBoardShapeCanvasAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD,
  payload: projectId,
});

export const getBoardShapeCanvasSuccessAction = (canvas: ICanvas): GetBoardShapeCanvasSuccessAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD_SUCCESS,
  payload: canvas,
});

export const getBoardShapeCanvasErrorAction = (payload: string): GetBoardShapeCanvasErrorAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD_FAIL,
  payload,
});

export const clearBoardShapeCanvasAction = (): ClearBoardShapeCanvasAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_CLEAR,
});

export const saveBoardShapeCanvasAction = (
  canvas: Partial<ICanvas>,
  compileProject?: boolean,
): SaveBoardShapeCanvasAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE,
  payload: {
    canvas,
    compileProject,
  },
});

export const saveBoardShapeCanvasSuccessAction = (payload: ICanvas): SaveBoardShapeCanvasSuccessAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE_SUCCESS,
  payload,
});

export const saveBoardShapeCanvasErrorAction = (payload: string): SaveBoardShapeCanvasErrorAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE_FAIL,
  payload,
});

export const setBoardShapeCanvasBlocks = (blocks: Array<IModule>): SetBoardShapeCanvasBlocksAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BLOCKS,
  payload: blocks,
});

export const setBoardShapeCanvasLock = (locked: boolean): SetBoardShapeCanvasLockAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE_LOCK,
  payload: locked,
});

export const setBoardShapeCanvasShape = (boardPolygon: Array<ICoords>): BoardShapeCanvasSetShapeAction => ({
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE,
  payload: boardPolygon,
});
