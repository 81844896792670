import {
  DashboardDisplayModes,
  DashboardProjectTypes,
  DashboardSortingTypes,
  ICadFilesState,
  ICanvasStates,
  ICanvasType,
  ICompilationStates,
  IPaymentFileType,
  Project,
  ProjectImportType,
  ProjectIssues,
  ProjectsState,
} from 'state_management/reducers/projects/Modals';
import { AppType } from 'state_management/reducers/auth/Modals';
import { CanvasVersionType } from 'state_management/reducers/systemDesignCanvas/Modals';
import { IPagination } from 'models/Pagination';

import {
  ProjectCanvasCompileAction,
  ProjectCanvasCompileErrorAction,
  ProjectCanvasCompileSuccessAction,
  ProjectCompileIssuesAction,
  ProjectCompileIssuesErrorAction,
  ProjectCompileIssuesSuccessAction,
  ProjectClearAction,
  ProjectDownloadFileBomAction,
  ProjectDownloadFileBomErrorAction,
  ProjectDownloadFileBomSuccessAction,
  ProjectDownloadFileCancelAction,
  ProjectDownloadFileLayoutAction,
  ProjectDownloadFileLayoutErrorAction,
  ProjectDownloadFileLayoutSuccessAction,
  ProjectDownloadFileLayoutPaymentAction,
  ProjectDownloadFilePdfAction,
  ProjectDownloadFilePdfErrorAction,
  ProjectDownloadFilePdfSuccessAction,
  ProjectDownloadFileSchematicsAction,
  ProjectDownloadFileSchematicsErrorAction,
  ProjectDownloadFileSchematicsPaymentAction,
  ProjectDownloadFileSchematicsSuccessAction,
  ProjectImportAction,
  ProjectImportErrorAction,
  ProjectImportSuccessAction,
  ProjectsActionTypes,
  ProjectsAddAction,
  ProjectsAddErrorAction,
  ProjectsAddSuccessAction,
  ProjectSaveAction,
  ProjectSaveErrorAction,
  ProjectSaveSuccessAction,
  ProjectsCloneAction,
  ProjectsCloneErrorAction,
  ProjectsCloneSuccessAction,
  ProjectsDeleteAction,
  ProjectsDeleteErrorAction,
  ProjectsDeleteSuccessAction,
  ProjectsGetAllAction,
  ProjectsGetAllErrorAction,
  ProjectsGetAllSuccessAction,
  ProjectsGetByIdAction,
  ProjectsGetByIdErrorAction,
  ProjectsGetByIdSuccessAction,
  ProjectsImageUpdateAction,
  ProjectsImageUpdateErrorAction,
  ProjectsImageUpdateSuccessAction,
  ProjectsResetStateAction,
  ProjectStateChangeAction,
  ProjectsSetCurrentAction,
  ProjectStateGetAction,
  ProjectStateGetCancelAction,
  ProjectStateGetErrorAction,
  ProjectStateGetSuccessAction,
  ProjectsUpdateAction,
  ProjectsUpdateDisplayModeAction,
  ProjectsUpdateErrorAction,
  ProjectsUpdateSharingFilterAction,
  ProjectsUpdateSortTypeAction,
  ProjectsUpdateSuccessAction,
  ProjectsClearPaymentAction,
  PdfDownloadIConLoadingFailAction,
  SchematicsDownloadIconLoadingFailAction,
  BomDownloadIconLoadingFailAction,
  LayoutDownloadIconLoadingFailACtion,
} from './ActionTypes';

export const ENDPOINT_PROJECTS = 'projects';
export const DS_PROJECTS_ENDPOINT = `dataservice/${ENDPOINT_PROJECTS}`;
export const MACRO_RES_PROJECTS_ENDPOINT = `macro-resources/${ENDPOINT_PROJECTS}`;
export const DS_PROJECT_STATE_ENDPOINT = `dataservice/project-state`;
export const AVAIL_INTEGRATION_PROJECT_IMPORT_ENDPOINT = `avail-service/projects/import`;

export const resetProjectState = (): ProjectsResetStateAction => ({
  type: ProjectsActionTypes.PROJECTS_RESET,
});

export const dashUpdateDisplayMode = (displayMode: DashboardDisplayModes): ProjectsUpdateDisplayModeAction => ({
  type: ProjectsActionTypes.PROJECTS_UPDATE_DISPLAY_MODE,
  payload: displayMode,
});

export const dashUpdateSortType = (sortType: DashboardSortingTypes): ProjectsUpdateSortTypeAction => ({
  type: ProjectsActionTypes.PROJECTS_UPDATE_SORTING_TYPE,
  payload: sortType,
});

export const dashUpdateSharingFilter = (sharingFilter: DashboardProjectTypes): ProjectsUpdateSharingFilterAction => ({
  type: ProjectsActionTypes.PROJECTS_UPDATE_SHARING_FILTER,
  payload: sharingFilter,
});

export const setCurrentProject = (project: Partial<Project>): ProjectsSetCurrentAction => ({
  type: ProjectsActionTypes.PROJECTS_SET_CURRENT,
  payload: project,
});

export const projectImageUpdateAction = (imageData: string): ProjectsImageUpdateAction => ({
  type: ProjectsActionTypes.PROJECTS_IMAGE_UPDATE,
  payload: { data: imageData },
});

export const projectImageUpdateSuccessAction = (): ProjectsImageUpdateSuccessAction => ({
  type: ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_SUCCESS,
});

export const projectImageUpdateErrorAction = (error: string): ProjectsImageUpdateErrorAction => ({
  type: ProjectsActionTypes.PROJECTS_IMAGE_UPDATE_FAIL,
  payload: error,
});

export const getProjectState = (initialState?: ICanvasType, canvasVersion?: '1' | '2'): ProjectStateGetAction => ({
  payload: { initialState, canvasVersion },
  type: ProjectsActionTypes.PROJECT_STATE_GET,
});

export const getProjectStateSuccess = (payload: {
  canvasStates: ICanvasStates;
  cadFilesState: ICadFilesState;
  compilerState: Raw.CompilerState;
  compilationStates: ICompilationStates;
  projectCompiledState: Pick<
    ProjectsState,
    'boardCompileRequest' | 'designCompileRequest' | 'boardCompileRequestError' | 'designCompileRequestError'
  >;
}): ProjectStateGetSuccessAction => ({
  payload,
  type: ProjectsActionTypes.PROJECT_STATE_GET_SUCCESS,
});

export const getProjectStateFail = (error: string): ProjectStateGetErrorAction => ({
  payload: error,
  type: ProjectsActionTypes.PROJECT_STATE_GET_FAIL,
});

export const getProjectStateCancel = (): ProjectStateGetCancelAction => ({
  type: ProjectsActionTypes.PROJECT_STATE_GET_CANCEL,
});

export const projectSaveAction = (payload: Project): ProjectSaveAction => ({
  type: ProjectsActionTypes.PROJECT_SAVE,
  payload,
});

export const projectSaveSuccessAction = (payload: Project & { currentAppType: AppType }): ProjectSaveSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_SAVE_SUCCESS,
  payload,
});

export const projectSaveErrorAction = (error: string): ProjectSaveErrorAction => ({
  type: ProjectsActionTypes.PROJECT_SAVE_FAIL,
  payload: error,
});

export const projectCanvasCompileAction = (
  canvas: ICanvasType,
  version?: CanvasVersionType,
): ProjectCanvasCompileAction => ({
  type: ProjectsActionTypes.PROJECT_CANVAS_COMPILE,
  payload: { canvas, version },
});

export const projectCanvasCompileSuccessAction = (): ProjectCanvasCompileSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_CANVAS_COMPILE_SUCCESS,
});

export const projectCanvasCompileErrorAction = (
  payload: Pick<
    ProjectsState,
    'boardCompileRequest' | 'designCompileRequest' | 'boardCompileRequestError' | 'designCompileRequestError'
  >,
): ProjectCanvasCompileErrorAction => ({
  type: ProjectsActionTypes.PROJECT_CANVAS_COMPILE_FAIL,
  payload,
});

export const projectCompileIssuesAction = (
  projectId: string,
  version?: CanvasVersionType,
): ProjectCompileIssuesAction => ({
  type: ProjectsActionTypes.PROJECT_COMPILE_ISSUES,
  payload: { projectId, version },
});

export const projectCompileIssuesSuccessAction = (issues: ProjectIssues): ProjectCompileIssuesSuccessAction => ({
  payload: issues,
  type: ProjectsActionTypes.PROJECT_COMPILE_ISSUES_SUCCESS,
});

export const projectCompileIssuesErrorAction = (error: string): ProjectCompileIssuesErrorAction => ({
  payload: error,
  type: ProjectsActionTypes.PROJECT_COMPILE_ISSUES_FAIL,
});

export const getAllProjects = (payload?: Omit<IPagination, 'total'>): ProjectsGetAllAction => ({
  type: ProjectsActionTypes.PROJECTS_GET_ALL,
  payload: { ...payload, queryType: payload?.queryType || 'paginated' },
});

export const getAllProjectsSuccessAction = (
  pagination: IPagination,
  data: Array<Project>,
): ProjectsGetAllSuccessAction => ({
  type: ProjectsActionTypes.PROJECTS_GET_ALL_SUCCESS,
  payload: {
    data,
    pagination,
  },
});

export const getAllProjectsErrorAction = (payload: string): ProjectsGetAllErrorAction => ({
  payload,
  type: ProjectsActionTypes.PROJECTS_GET_ALL_FAIL,
});

export const getProjectByIdAction = (id: string): ProjectsGetByIdAction => ({
  type: ProjectsActionTypes.PROJECTS_GET_BY_ID,
  payload: id,
});

export const getProjectByIdSuccessAction = (project: Project): ProjectsGetByIdSuccessAction => ({
  type: ProjectsActionTypes.PROJECTS_GET_BY_ID_SUCCESS,
  payload: project,
});

export const getProjectByIdErrorAction = (error: string): ProjectsGetByIdErrorAction => ({
  type: ProjectsActionTypes.PROJECTS_GET_BY_ID_FAIL,
  payload: error,
});

export const addProject = (project: Partial<Project>): ProjectsAddAction => ({
  type: ProjectsActionTypes.PROJECTS_ADD,
  payload: project,
});

export const addProjectSuccess = (project: Partial<Raw.Project>): ProjectsAddSuccessAction => ({
  payload: project,
  type: ProjectsActionTypes.PROJECTS_ADD_SUCCESS,
});

export const addProjectFail = (error: string): ProjectsAddErrorAction => ({
  payload: error,
  type: ProjectsActionTypes.PROJECTS_ADD_FAIL,
});

export const cloneProjectAction = (project: Partial<Project>): ProjectsCloneAction => ({
  type: ProjectsActionTypes.PROJECTS_CLONE,
  payload: project,
});

export const cloneProjectSuccessAction = (project: Project): ProjectsCloneSuccessAction => ({
  type: ProjectsActionTypes.PROJECTS_CLONE_SUCCESS,
  payload: project,
});

export const cloneProjectErrorAction = (error: string): ProjectsCloneErrorAction => ({
  type: ProjectsActionTypes.PROJECTS_CLONE_FAIL,
  payload: error,
});

export const updateProjectAction = (project: Partial<Project>): ProjectsUpdateAction => ({
  type: ProjectsActionTypes.PROJECTS_UPDATE,
  payload: project,
});

export const updateProjectSuccessAction = (project: Project): ProjectsUpdateSuccessAction => ({
  type: ProjectsActionTypes.PROJECTS_UPDATE_SUCCESS,
  payload: project,
});

export const updateProjectErrorAction = (error: string): ProjectsUpdateErrorAction => ({
  type: ProjectsActionTypes.PROJECTS_UPDATE_FAIL,
  payload: error,
});

export const deleteProjectAction = (project: Partial<Project>): ProjectsDeleteAction => ({
  type: ProjectsActionTypes.PROJECTS_DELETE,
  payload: project,
});

export const deleteProjectSuccessAction = (project: Partial<Project>): ProjectsDeleteSuccessAction => ({
  type: ProjectsActionTypes.PROJECTS_DELETE_SUCCESS,
  payload: project,
});

export const deleteProjectErrorAction = (error: string): ProjectsDeleteErrorAction => ({
  type: ProjectsActionTypes.PROJECTS_DELETE_FAIL,
  payload: error,
});

export const projectDownloadFileSchematicsAction = (unlock?: boolean): ProjectDownloadFileSchematicsAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS,
  unlock: !!unlock,
});

export const projectDownloadFileSchematicsSuccessAction = (): ProjectDownloadFileSchematicsSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_SUCCESS,
});

export const projectDownloadFileSchematicsErrorAction = (
  payload: string,
): ProjectDownloadFileSchematicsErrorAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_FAIL,
  payload,
});

export const projectDownloadFileSchematicsPaymentAction = (
  numComponents: number,
): ProjectDownloadFileSchematicsPaymentAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS_PAYMENT,
  numComponents,
});

export const projectClearPayment = (): ProjectsClearPaymentAction => ({
  type: ProjectsActionTypes.PROJECT_CLEAR_PAYMENT,
});

export const projectDownloadFileLayoutAction = (unlock?: boolean): ProjectDownloadFileLayoutAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT,
  unlock: !!unlock,
});

export const projectDownloadFileLayoutSuccessAction = (): ProjectDownloadFileLayoutSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_SUCCESS,
});

export const projectDownloadFileLayoutErrorAction = (payload: string): ProjectDownloadFileLayoutErrorAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_FAIL,
  payload,
});

export const projectDownloadFileLayoutPaymentAction = (
  numComponents: number,
  fileType: IPaymentFileType,
): ProjectDownloadFileLayoutPaymentAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT_PAYMENT,
  fileType,
  numComponents,
});

export const projectDownloadFileBomAction = (): ProjectDownloadFileBomAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM,
});

export const projectDownloadFileBomSuccessAction = (): ProjectDownloadFileBomSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_SUCCESS,
});

export const projectDownloadFileBomErrorAction = (payload: string): ProjectDownloadFileBomErrorAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM_FAIL,
  payload,
});

export const projectDownloadFilePdfAction = (): ProjectDownloadFilePdfAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF,
});

export const projectDownloadFilePdfSuccessAction = (): ProjectDownloadFilePdfSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_SUCCESS,
});

export const projectDownloadFilePdfErrorAction = (payload: string): ProjectDownloadFilePdfErrorAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF_FAIL,
  payload,
});

export const projectDownloadFileCancelAction = (): ProjectDownloadFileCancelAction => ({
  type: ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_CANCEL,
});

export const projectClearAction = (payload: Array<keyof ProjectsState>): ProjectClearAction => ({
  payload,
  type: ProjectsActionTypes.PROJECTS_CLEAR,
});

export const projectImportAction = (
  data: string | Array<Partial<Project>>,
  type: ProjectImportType,
): ProjectImportAction => ({
  type: ProjectsActionTypes.PROJECT_IMPORT,
  payload: {
    data,
    type,
  },
});

export const projectImportSuccessAction = (project: Project): ProjectImportSuccessAction => ({
  type: ProjectsActionTypes.PROJECT_IMPORT_SUCCESS,
  payload: project,
});

export const projectImportErrorAction = (error: string): ProjectImportErrorAction => ({
  type: ProjectsActionTypes.PROJECT_IMPORT_FAIL,
  payload: error,
});

export const projectStateChangeAction = (stateChange: {
  projectId: string;
  state: string;
  error?: {
    error_msg: string;
  };
}): ProjectStateChangeAction => ({
  type: ProjectsActionTypes.PROJECT_STATE_CHANGE,
  payload: stateChange,
});

export const pdfDownloadIConLoadingFailAction = (): PdfDownloadIConLoadingFailAction => ({
  type: ProjectsActionTypes.PDF_DOWNLOAD_ICON_LOADING_FAIL,
});

export const schematicsDownloadIconLoadingFailAction = (): SchematicsDownloadIconLoadingFailAction => ({
  type: ProjectsActionTypes.SCHEMATICS_DOWNLOAD_ICON_LOADING_FAIL,
});

export const bomDownloadIconLoadingFailAction = (): BomDownloadIconLoadingFailAction => ({
  type: ProjectsActionTypes.BOM_DOWNLOAD_ICON_LOADING_FAIL,
});

export const layoutDownloadIconLoadingFailACtion = (): LayoutDownloadIconLoadingFailACtion => ({
  type: ProjectsActionTypes.LAYOUT_DOWNLOAD_ICON_LOADING_FAIL,
});
