import { Notification } from '../../reducers/notification/Modals';

export enum NotificationActionTypes {
  NEW_NOTIFICATION = 'NEW_NOTIFICATION',
  LOGIN_REDIRECT_NOTIFICATION = 'LOGIN_REDIRECT_NOTIFICATION',
  RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS',
}

// ============================================ NEW_NOTIFICATION

export interface NewNotification {
  type: NotificationActionTypes.NEW_NOTIFICATION;
  payload: Notification;
}

// ============================================ RESET_NOTIFICATIONS

export interface ResetNotificationsAction {
  type: NotificationActionTypes.RESET_NOTIFICATIONS;
}

// ============================================ ALL

export type NotificationActions = NewNotification | ResetNotificationsAction;
