import { PluginsActionTypes, PluginActions } from 'state_management/actions/plugins/ActionTypes';
import { parseURL } from 'views/Plugins/utils';
import { PluginState } from './Modals';

export const initialStatePlugins: PluginState = {
  pagination: {
    page: 0,
    perPage: 100,
    search: '',
    total: 20,
  },
  plugins: [],
  pluginsState: 'idle',
};

export const pluginsReducer = (state: PluginState = initialStatePlugins, action: PluginActions): PluginState => {
  switch (action.type) {
    case PluginsActionTypes.GET_ALL_PLUGINS:
      return {
        ...state,
        pluginsState: 'loading',
      };
    case PluginsActionTypes.GET_ALL_PLUGINS_SUCCESS:
      return {
        ...state,
        plugins: (action.payload.data || []).map((p) => {
          const url = p.extras?.url ? parseURL(p.extras?.url) : undefined;

          return url
            ? {
                ...p,
                extras: {
                  ...p.extras,
                  url,
                },
              }
            : p;
        }),
        pluginsState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    default:
      return state;
  }
};
