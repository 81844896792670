import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Popover from 'components/Popover';
import CelusIcon from 'components/CelusIcon';
import { CanvasType } from 'routes/getRoutes';
import { withTheme } from 'styled-components';
import {
  getBomTransitionState,
  resetBomTransitionState,
} from 'state_management/actions/billOfMaterial/billOfMaterialActions';
import { IProps } from './IProps';
import {
  Container,
  TabContainer,
  Tab,
  CanvasProgressState,
  PopoverContent,
  DesignCanvasPopoverContent,
  Strong,
} from './styles';

const TabsSwitch = ({
  theme,
  selectedTab,
  canvasStates,
  boardCompileRequest,
  designCompileRequest,
  currentProject,
  featurePermissions,
  bomTransitionState,
  dispatch,
  onSwitch,
}: IProps): JSX.Element => {
  const [boardCanvasStateIcon, setBoardCanvasStateIcon] = useState<JSX.Element>(<></>);
  const [designCanvasStateIcon, setDesignCanvasStateIcon] = useState<JSX.Element>(<></>);
  const [designCanvasStatePopoverContent, setDesignCanvasStatePopoverContent] = useState<JSX.Element>(<></>);
  const [designWarning, setDesignWarning] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getBomTransitionState({ projectId: currentProject.id }));
    /* Clean Bom Transition State */
    return (): void => {
      resetBomTransitionState();
    };
  }, []);

  useEffect(() => {
    if (designCompileRequest === 'success') dispatch(getBomTransitionState({ projectId: currentProject.id }));
  }, [designCompileRequest]);

  const designCanvasPopoverContent = (
    <DesignCanvasPopoverContent>
      {t('supernova:tabs.designcanvas.popover.line1', `Some solution sources have changed `)}
      <Strong>
        {t('supernova:tabs.designcanvas.popover.line2', 'because of changes in the  workspace selection. ')}
      </Strong>
      {t('supernova:tabs.designcanvas.popover.line3', 'Please ')}
      <Strong>
        {t('supernova:tabs.designcanvas.popover.line4', 'resolve the project again in order to get a proper result.')}
      </Strong>
    </DesignCanvasPopoverContent>
  );

  const boardCanvasPopoverContent = (
    <DesignCanvasPopoverContent>
      {t('supernova:tabs.messages.boardNotUpToDate', 'Your canvas is outdated.')}
      <br />
      {t('supernova:tabs.messages.boardYouCanUpdate', 'You can update the canvas by')}
      <br />
      {t('supernova:tabs.messages.boardClickingOn', 'clicking on')}
      <CelusIcon size="tiny">generate-button</CelusIcon>
      {t('supernova:tabs.messages.buttonInside', 'the resolve button inside of')}
      <br />
      {['idle', 'loading'].includes(boardCompileRequest)
        ? t('supernova:tabs.systemDesignCanvas', 'Design Canvas')
        : t('supernova:tabs.boardShapeCanvas', 'Board-Shape Canvas')}
      .
    </DesignCanvasPopoverContent>
  );

  const bomCanvasPopoverContent = (
    <DesignCanvasPopoverContent>
      <Strong>{t('supernova:tabs.bom.popover.line1.1', 'Your canvas is outdated.')}</Strong>
      {t('supernova:tabs.bom.popover.line1.2', 'The BOM')}
      <br />
      {t('supernova:tabs.bom.popover.line2', 'Preview is outdated due to added')}
      <br />
      {t('supernova:tabs.bom.popover.line3', 'modification rules. Please update the')}
      <br />
      {t('supernova:tabs.bom.popover.line4', 'BOM by clicking on the Resolve button.')}
    </DesignCanvasPopoverContent>
  );

  const loadingIcon = (
    <CanvasProgressState>
      <CelusIcon size="tiny">loading</CelusIcon>
    </CanvasProgressState>
  );

  const warningIcon = (
    <CanvasProgressState>
      <CelusIcon size="small" color={theme.palette.warning}>
        warning
      </CelusIcon>
    </CanvasProgressState>
  );

  const setBoardCanvasIconState = (): void => {
    if (canvasStates.boardCanvas === 'warning') {
      setBoardCanvasStateIcon(warningIcon);
    } else if (boardCompileRequest === 'loading') {
      setBoardCanvasStateIcon(loadingIcon);
    } else {
      setBoardCanvasStateIcon(<></>);
    }
  };

  const switchHandler = (_selectedTab: CanvasType) => (): void => {
    onSwitch(_selectedTab);
  };

  const setDesignCanvasIconState = (): void => {
    if (designCompileRequest === 'loading') {
      setDesignCanvasStateIcon(loadingIcon);
      /* Let the design warning icon disappear whenever the resolve button is clicked */
      setDesignWarning(false);
    } else {
      setDesignCanvasStateIcon(<></>);
    }
  };

  useEffect(() => {
    if (designWarning) {
      setDesignCanvasStatePopoverContent(designCanvasPopoverContent);
      setDesignCanvasStateIcon(warningIcon);
    } else {
      setDesignCanvasStatePopoverContent(<></>);
      setDesignCanvasStateIcon(<></>);
    }
  }, [designWarning]);

  useEffect(() => {
    currentProject.compilerState === 'SETTINGS_CHANGE' && setDesignWarning(true);
    setDesignCanvasIconState();
    setBoardCanvasIconState();
  }, [canvasStates.boardCanvas, boardCompileRequest, designCompileRequest, currentProject.compilerState]);

  return (
    <Container>
      <TabContainer>
        <Tab
          data-testid="tab-project-settings"
          selected={selectedTab === 'Project Settings'}
          onClick={switchHandler('Project Settings')}
        >
          {t('supernova:tabs.projectSettings', 'Project Settings')}
        </Tab>
      </TabContainer>
      <TabContainer>
        <Tab
          data-testid="tab-design-canvas"
          selected={selectedTab === 'Design Canvas'}
          onClick={switchHandler('Design Canvas')}
        >
          {t('supernova:tabs.designCanvas', 'Design Canvas')}
        </Tab>
        <Popover
          noTip
          hoverTrigger
          delay={1}
          content={<PopoverContent>{designCanvasStatePopoverContent}</PopoverContent>}
        >
          {designCanvasStateIcon}
        </Popover>
      </TabContainer>
      <TabContainer>
        <Tab data-testid="tab-bom-canvas" selected={selectedTab === 'BOM Canvas'} onClick={switchHandler('BOM Canvas')}>
          {t('supernova:tabs.bomPreviewCanvas', 'BOM Preview')}
        </Tab>
        {((): JSX.Element => {
          let icon = null;
          if (designCompileRequest === 'loading') icon = loadingIcon;
          else if (bomTransitionState?.state === 'outdated') {
            icon = warningIcon;
          }
          return (
            <Popover noTip hoverTrigger delay={1} content={<PopoverContent>{bomCanvasPopoverContent}</PopoverContent>}>
              {icon}
            </Popover>
          );
        })()}
      </TabContainer>
      {featurePermissions?.SUPERNOVA_SHOW_BOARD_SHAPE_CANVAS_AND_DOWNLOAD_LAYOUT?.read && (
        <TabContainer>
          <Tab
            data-testid="tab-board-canvas"
            selected={selectedTab === 'Board-Shape Canvas'}
            onClick={switchHandler('Board-Shape Canvas')}
          >
            {t('supernova:tabs.boardShapeCanvas', 'Board-Shape Canvas')}
          </Tab>
          <Popover
            noTip
            hoverTrigger
            delay={1}
            controlledClose={
              canvasStates.boardCanvas !== 'warning' && ['idle', 'loading'].includes(boardCompileRequest)
            }
            content={
              canvasStates.boardCanvas === 'warning' && <PopoverContent>{boardCanvasPopoverContent}</PopoverContent>
            }
          >
            {boardCanvasStateIcon}
          </Popover>
        </TabContainer>
      )}
      <TabContainer>
        <Tab
          data-testid="tab-project-files"
          selected={selectedTab === 'Project Files'}
          onClick={switchHandler('Project Files')}
        >
          {t('supernova:tabs.outputFiles', 'Output Files')}
        </Tab>
      </TabContainer>
    </Container>
  );
};

export default withTheme(TabsSwitch);
