/* eslint-disable dot-notation */
import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getDesignConsiderationByIdSuccess,
  getDesignConsiderationByIdError,
  ENDPOINT_DESIGN_CONSIDERATIONS,
  getDesignConsiderationByIdErrorEvery,
  getDesignConsiderationByIdSuccessEvery,
} from 'state_management/actions/designConsiderations/designConsiderationsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetDesignConsiderationByIdErrorAction,
  GetDesignConsiderationByIdSuccessAction,
  GetDesignConsiderationByIdAction,
  DesignConsiderationsActionTypes,
  GetDesignConsiderationByIdEveryAction,
} from 'state_management/actions/designConsiderations/ActionTypes';
import { IDesignConsideration } from 'state_management/reducers/designConsiderations/Modals';
import { serializeDesignConsideration } from 'utils/designConsiderationsSerializer';
import { OPERATORS } from 'components/AdvancedSearch/AdvancedSearchForm/AdvancedSearchForm';
import { Filter, SearchFieldsOptions, SearchValue } from 'components/AdvancedSearch/Modals';
import { getAdvancedSearchQuery } from 'utils/searchHelper';

export function* getByIdSaga(
  action: GetDesignConsiderationByIdAction,
): Generator<
  | PutEffect<GetDesignConsiderationByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetDesignConsiderationByIdSuccessAction | GetDesignConsiderationByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedDesignConsideration: IDesignConsideration = serializeDesignConsideration(res.data);
    yield put(getDesignConsiderationByIdSuccess(parsedDesignConsideration));
  } catch (error) {
    yield put(
      getDesignConsiderationByIdError(
        getErrorMessage(error) || 'Fetching designConsiderations failed. Please try again...',
      ),
    );
  }
}

export function* getByIdSetSaga(action: GetDesignConsiderationByIdEveryAction): Generator<CallEffect | PutEffect> {
  const { ids } = action?.payload || [];
  const filters: Array<Filter>[] = [];
  ids.forEach((id) => {
    const filter: Filter[] = [
      {
        field: { type: 'string', value: 'id' } as SearchFieldsOptions,
        operator: OPERATORS['is'],
        values: { value: id } as SearchValue,
        userValues: { value: id } as SearchValue,
      },
    ];
    filters.push(filter);
  });
  let query = getAdvancedSearchQuery(filters);
  query += '&fields=id,name,hyperlink';
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}?${query}`, 2)),
    )) as AxiosResponse;
    const parsedDesignConsiderations = ((res?.data as Raw.IDesignConsideration[]) || []).map((dc) =>
      serializeDesignConsideration(dc),
    );
    yield put(getDesignConsiderationByIdSuccessEvery(parsedDesignConsiderations));
  } catch (error) {
    yield put(
      getDesignConsiderationByIdErrorEvery(
        getErrorMessage(error) || 'Fetching designConsiderations failed. Please try again...',
      ),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID, getByIdSaga);
}

export function* getByIdEveryWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_EVERY, getByIdSetSaga);
}
