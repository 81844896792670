import { useState, useEffect } from 'react';

import BrowserZoomGuard from 'components/BrowserZoomGuard';
import TabsSwitch from 'components/TabsSwitch';
import ZoomButtons from 'components/CanvasV2/ZoomButtons';
import PoweredByCelus from 'components/PoweredByCelus';
import Button from 'components/Button';
import { ProjectIssues } from 'state_management/reducers/projects/Modals';
import filterIssuesByCanvas from '../utils/filterIssuesByCanvas';

import ErrorButtons from './ErrorButtons';
import { IProps } from './IProps';

import {
  StatusBarContainer,
  CanvasTabs,
  NavigatorContainer,
  NavigatorMap,
  navigatorMapAnimation,
  ZoomContainer,
  ErrorButtonsContainer,
} from './styles';
import IssuesPanel from './IssuesPanel';
import { tab } from './modals';

const StatusBar = ({
  selectedCanvas,
  projectsGetRequest,
  isReadOnly,
  currentAppType,
  featurePermissions,
  boardCompileRequest,
  designCompileRequest,
  setSelectedCanvas,
  currentProject,
  canvasRendererRef,
  dispatch,
  bomTransitionState,
}: IProps): JSX.Element => {
  const [mapViewToggle, setMapViewToggle] = useState(false);
  const [issuesPanelToggle, setIssuesPanelToggle] = useState(false);
  const [currentTab, setCurrentTab] = useState<tab>('all');
  const [projectIssues, setProjectIssues] = useState<ProjectIssues>([]);

  useEffect(() => {
    const issues = filterIssuesByCanvas(currentProject?.issues, selectedCanvas);
    setProjectIssues(issues);
    if (issuesPanelToggle) {
      if (currentTab === 'errors' && issues.errors?.length === 0) {
        setCurrentTab('all');
      } else if (currentTab === 'warnings' && issues.warnings?.length === 0) {
        setCurrentTab('all');
      }
    }
  }, [selectedCanvas, currentProject?.issues]);

  const handleZoomChange = (_zoom: -1 | 0 | 1): void => {
    canvasRendererRef?.current?.zoom(_zoom);
  };

  const handleMapViewToggle = (): void => {
    setMapViewToggle(!mapViewToggle);
  };

  const errorButtonsHandler = (issuesTabName: tab): void => {
    setIssuesPanelToggle(true);
    if (issuesTabName === 'errors' && projectIssues.errors?.length === 0) {
      setCurrentTab('all');
    } else if (issuesTabName === 'warnings' && projectIssues.warnings?.length === 0) {
      setCurrentTab('all');
    } else {
      setCurrentTab(issuesTabName);
    }
  };

  return (
    <StatusBarContainer>
      <BrowserZoomGuard>
        {selectedCanvas !== 'Sub System Canvas' &&
          projectsGetRequest === 'success' &&
          featurePermissions &&
          currentAppType &&
          boardCompileRequest &&
          designCompileRequest &&
          selectedCanvas &&
          setSelectedCanvas &&
          dispatch &&
          bomTransitionState &&
          currentProject && (
            <CanvasTabs>
              <TabsSwitch
                dispatch={dispatch}
                bomTransitionState={bomTransitionState}
                readOnly={isReadOnly}
                featurePermissions={featurePermissions}
                currentAppType={currentAppType}
                boardCompileRequest={boardCompileRequest}
                designCompileRequest={designCompileRequest}
                selectedTab={selectedCanvas}
                onSwitch={setSelectedCanvas}
                canvasStates={currentProject.canvasStates}
                currentProject={currentProject}
              />
            </CanvasTabs>
          )}

        {(selectedCanvas === 'Design Canvas' || selectedCanvas === 'Board-Shape Canvas') && (
          <ErrorButtonsContainer data-testid="error-buttons">
            <ErrorButtons
              onClick={errorButtonsHandler}
              numberOfErrors={projectIssues.errors?.length || 0}
              numberOfWarnings={projectIssues.warnings?.length || 0}
            />
          </ErrorButtonsContainer>
        )}
        {['Design Canvas', 'Sub System Canvas'].includes(selectedCanvas) && (
          <>
            <NavigatorContainer>
              <NavigatorMap
                toggle={mapViewToggle}
                className="navigator-container"
                animate={mapViewToggle ? 'open' : 'closed'}
                variants={navigatorMapAnimation}
              />
              <Button
                dataTestId="map-btn"
                empty
                iconSize="normal"
                tooltip="Map view"
                icon="map"
                onClick={handleMapViewToggle}
              />
            </NavigatorContainer>

            <ZoomContainer>
              <ZoomButtons onChange={handleZoomChange} />
            </ZoomContainer>
          </>
        )}
        <PoweredByCelus hasStatusBar />
      </BrowserZoomGuard>
      {(selectedCanvas === 'Design Canvas' || selectedCanvas === 'Board-Shape Canvas') && (
        <IssuesPanel
          canvasRendererRef={canvasRendererRef}
          projectIssues={projectIssues}
          issuesPanelToggle={issuesPanelToggle}
          setIssuesPanelToggle={setIssuesPanelToggle}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      )}
    </StatusBarContainer>
  );
};

export default StatusBar;
