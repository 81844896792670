import { RefDesignActionTypes, RefDesignsActions } from 'state_management/actions/referenceDesigns/ActionTypes';
import { ReferenceDesignsState } from './Modals';

export const initialStateRefDesigns: ReferenceDesignsState = {
  pagination: {
    page: 0,
    perPage: 20,
    search: '',
    total: 20,
  },
  referenceDesigns: [],
  referenceDesignsListState: 'idle',
  referenceDesignsListError: '',
  referenceDesignsUpdateState: 'idle',
  referenceDesignsUpdateError: '',
  referenceDesignsDeleteState: 'idle',
  referenceDesignsDeleteError: '',
  referenceDesignsByIdState: 'idle',
  referenceDesignsByIdError: '',
};

export const referenceDesignsReducer = (
  state: ReferenceDesignsState = initialStateRefDesigns,
  action: RefDesignsActions,
): ReferenceDesignsState => {
  switch (action.type) {
    case RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS:
      return {
        ...state,
        referenceDesignsListState: 'loading',
        referenceDesignsListError: '',
      };
    case RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_SUCCESS:
      return {
        ...state,
        referenceDesigns: action.payload.data,
        referenceDesignsListState: 'success',
        referenceDesignsListError: '',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_FAIL:
      return {
        ...state,
        referenceDesignsListState: 'error',
        referenceDesignsListError: action.payload || 'Fetching reference designs failed',
      };
    case RefDesignActionTypes.DELETE_REFERENCE_DESIGNS:
      return {
        ...state,
        referenceDesignsDeleteState: 'loading',
        referenceDesignsDeleteError: '',
      };
    case RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_SUCCESS:
      return {
        ...state,
        referenceDesignsDeleteState: 'success',
      };
    case RefDesignActionTypes.DELETE_REFERENCE_DESIGNS_FAIL:
      return {
        ...state,
        referenceDesignsDeleteState: 'error',
        referenceDesignsDeleteError: action.error.message,
      };
    case RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS:
      return {
        ...state,
        referenceDesignsUpdateState: 'loading',
        referenceDesignsUpdateError: '',
      };
    case RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_SUCCESS:
      return {
        ...state,
        referenceDesignsUpdateState: 'success',
      };
    case RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS_FAIL:
      return {
        ...state,
        referenceDesignsUpdateState: 'error',
        referenceDesignsUpdateError: action.error.message,
      };
    case RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID:
      return {
        ...state,
        referenceDesignsByIdState: 'loading',
      };
    case RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID_SUCCESS:
      return {
        ...state,
        referenceDesignsByIdState: 'success',
        referenceDesigns: [action.payload.data],
      };

    case RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID_FAIL:
      return {
        ...state,
        referenceDesignsByIdState: 'error',
        referenceDesignsByIdError: action.payload,
        referenceDesigns: [],
      };

    default:
      return state;
  }
};
