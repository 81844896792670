import { IPagination } from 'models/Pagination';
import { IExportTemplate } from 'state_management/reducers/exportTemplates/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum ExportTemplatesActionTypes {
  GET_ALL_EXPORT_TEMPLATES = 'GET_ALL_EXPORT_TEMPLATES',
  GET_ALL_EXPORT_TEMPLATES_SUCCESS = 'GET_ALL_EXPORT_TEMPLATES_SUCCESS',
  GET_ALL_EXPORT_TEMPLATES_FAIL = 'GET_ALL_EXPORT_TEMPLATES_FAIL',

  GET_EXPORT_TEMPLATE_DEPENDENCIES = 'GET_EXPORT_TEMPLATE_DEPENDENCIES',

  GET_EXPORT_TEMPLATE_BY_ID = 'GET_EXPORT_TEMPLATE_BY_ID',
  GET_EXPORT_TEMPLATE_BY_ID_SUCCESS = 'GET_EXPORT_TEMPLATE_BY_ID_SUCCESS',
  GET_EXPORT_TEMPLATE_BY_ID_FAIL = 'GET_EXPORT_TEMPLATE_BY_ID_FAIL',

  CREATE_EXPORT_TEMPLATE = 'CREATE_EXPORT_TEMPLATE',
  CREATE_EXPORT_TEMPLATE_SUCCESS = 'CREATE_EXPORT_TEMPLATE_SUCCESS',
  CREATE_EXPORT_TEMPLATE_FAIL = 'CREATE_EXPORT_TEMPLATE_FAIL',

  UPDATE_EXPORT_TEMPLATE = 'UPDATE_EXPORT_TEMPLATE',
  UPDATE_EXPORT_TEMPLATE_SUCCESS = 'UPDATE_EXPORT_TEMPLATE_SUCCESS',
  UPDATE_EXPORT_TEMPLATE_FAIL = 'UPDATE_EXPORT_TEMPLATE_FAIL',

  CLONE_EXPORT_TEMPLATE = 'CLONE_EXPORT_TEMPLATE',
  CLONE_EXPORT_TEMPLATE_SUCCESS = 'CLONE_EXPORT_TEMPLATE_SUCCESS',
  CLONE_EXPORT_TEMPLATE_FAIL = 'CLONE_EXPORT_TEMPLATE_FAIL',

  DELETE_EXPORT_TEMPLATE = 'DELETE_EXPORT_TEMPLATE',
  DELETE_EXPORT_TEMPLATE_SUCCESS = 'DELETE_EXPORT_TEMPLATE_SUCCESS',
  DELETE_EXPORT_TEMPLATE_FAIL = 'DELETE_EXPORT_TEMPLATE_FAIL',
}

// ============================================ GET_ALL_EXPORT_TEMPLATES

export interface GetAllExportTemplatesAction {
  payload: Partial<IPagination>;
  type: ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES;
}

export interface GetAllExportTemplatesSuccessAction {
  payload: { pagination: IPagination; data: Array<IExportTemplate> };
  type: ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES_SUCCESS;
}

export interface GetAllExportTemplatesErrorAction {
  payload: string;
  type: ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES_FAIL;
}

// ============================================ GET_EXPORT_TEMPLATE_DEPENDENCIES

export interface GetExportTemplateDependenciesAction {
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_DEPENDENCIES;
}

// ============================================ GET_EXPORT_TEMPLATE_BY_ID

export interface GetExportTemplateByIdAction {
  payload: { id: string };
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID;
}

export interface GetExportTemplateByIdSuccessAction {
  payload: IExportTemplate;
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID_SUCCESS;
}

export interface GetExportTemplateByIdErrorAction {
  payload: string;
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID_FAIL;
}

// ============================================ CREATE_EXPORT_TEMPLATE

export interface CreateExportTemplateAction extends AxiosRequestPayload<Raw.IExportTemplate> {
  type: ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE;
}

export interface CreateExportTemplateSuccessAction
  extends AxiosSuccessPayload<
    IExportTemplate,
    ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS,
    CreateExportTemplateAction
  > {
  type: ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS;
}

export interface CreateExportTemplateErrorAction
  extends AxiosErrorPayload<ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_FAIL, CreateExportTemplateAction> {
  type: ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_FAIL;
}

// ============================================ UPDATE_EXPORT_TEMPLATE

export interface UpdateExportTemplateAction extends AxiosRequestPayload<Raw.IExportTemplate> {
  type: ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE;
}
export interface UpdateExportTemplateSuccessAction
  extends AxiosSuccessPayload<
    IExportTemplate,
    ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS,
    UpdateExportTemplateAction
  > {
  type: ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS;
}
export interface UpdateExportTemplateErrorAction
  extends AxiosErrorPayload<ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_FAIL, UpdateExportTemplateAction> {
  type: ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_FAIL;
}

// ============================================ CLONE_EXPORT_TEMPLATE

export interface CloneExportTemplateAction extends AxiosRequestPayload<Raw.IExportTemplate> {
  type: ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE;
}

export interface CloneExportTemplateSuccessAction
  extends AxiosSuccessPayload<
    IExportTemplate,
    ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_SUCCESS,
    CloneExportTemplateAction
  > {
  type: ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_SUCCESS;
}

export interface CloneExportTemplateErrorAction
  extends AxiosErrorPayload<ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_FAIL, CloneExportTemplateAction> {
  type: ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_FAIL;
}

// ============================================ DELETE_EXPORT_TEMPLATE

export interface DeleteExportTemplateAction extends AxiosRequestPayload {
  type: ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE;
}
export interface DeleteExportTemplateSuccessAction
  extends AxiosSuccessPayload<
    string,
    ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS,
    DeleteExportTemplateAction
  > {
  type: ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS;
}
export interface DeleteExportTemplateErrorAction
  extends AxiosErrorPayload<ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_FAIL, DeleteExportTemplateAction> {
  type: ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_FAIL;
}

// ============================================ ALL

export type ExportTemplateActions =
  | GetAllExportTemplatesAction
  | GetAllExportTemplatesSuccessAction
  | GetAllExportTemplatesErrorAction
  | GetExportTemplateDependenciesAction
  | CreateExportTemplateAction
  | CreateExportTemplateSuccessAction
  | CreateExportTemplateErrorAction
  | UpdateExportTemplateAction
  | UpdateExportTemplateSuccessAction
  | UpdateExportTemplateErrorAction
  | CloneExportTemplateAction
  | CloneExportTemplateSuccessAction
  | CloneExportTemplateErrorAction
  | DeleteExportTemplateAction
  | DeleteExportTemplateSuccessAction
  | DeleteExportTemplateErrorAction
  | GetExportTemplateByIdAction
  | GetExportTemplateByIdSuccessAction
  | GetExportTemplateByIdErrorAction;
