import { AxiosResponse } from 'axios';
import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  ProjectsActionTypes,
  ProjectsCloneAction,
  ProjectsCloneErrorAction,
  ProjectsCloneSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  cloneProjectErrorAction,
  cloneProjectSuccessAction,
  DS_PROJECTS_ENDPOINT,
  MACRO_RES_PROJECTS_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeProject } from 'utils/projectSerializer';
import { cloneLocalProject } from 'services/localProjectsService';
import { getProjectBackgroundUri } from 'config';
import { RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';
import { Project } from 'state_management/reducers/projects/Modals';
import { IEntity } from 'models/IEntity';
import { serializeRappidDesignCanvasBlock } from 'utils/serializers/systemDesignCanvasSerializer';
import DataLayer from 'services/dataLayer';
import { getProjectStateData } from './getProjectStateSaga';

export function* projectCloneSaga(
  action: ProjectsCloneAction,
): Generator<SelectEffect | CallEffect | PutEffect<ProjectsCloneSuccessAction | ProjectsCloneErrorAction>> {
  try {
    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    const project = action.payload;

    if (isAnonymousUser) {
      const res = (yield call(() =>
        axiosInstance.get(apiUri(`${DS_PROJECTS_ENDPOINT}/${project.id}`, 2)),
      )) as AxiosResponse<Raw.Project>;
      const newProject = res.data;

      let image: string | undefined;
      try {
        // NOTE: sometimes there are projects pointing to an image that doesn't exist, it would make it fail
        if (newProject.image) {
          const screenshotRes = (yield call(() =>
            axiosInstance.get(getProjectBackgroundUri(newProject.image as string)),
          )) as AxiosResponse<string>;
          image = screenshotRes.data;
        }
      } catch (err) {}

      let canvas: Array<RappidGraph<IEntity>> | undefined;
      try {
        const canvasRes = (yield call(() =>
          axiosInstance.get(apiUri(`/dataservice/rappid-canvas/${project.id}`, 2)),
        )) as AxiosResponse<RawRappidDesignCanvas.RappidPayload>;
        canvas = canvasRes.data.graph.map(serializeRappidDesignCanvasBlock);
      } catch (err) {}

      const { compilerError, ...serializedProjState } = (yield call(() =>
        getProjectStateData(project.id as string),
      )) as Pick<Project, 'canvasStates' | 'cadFilesState' | 'compilationStates' | 'compilerState'> & {
        compilerError?: string;
      };

      const _project = cloneLocalProject(serializeProject(newProject), serializedProjState, canvas, image);

      yield put(cloneProjectSuccessAction(_project));
    } else {
      const res = yield call(() =>
        axiosInstance.post(apiUri(`${MACRO_RES_PROJECTS_ENDPOINT}/${project.id}/clone`, 2), { referenceDesign: false }),
      );

      const clonedProject = serializeProject((res as AxiosResponse<Raw.Project>).data);
      DataLayer.saveCloneProjectData(clonedProject, (project as Project));
      yield put(cloneProjectSuccessAction(clonedProject));
    }
  } catch (error) {
    yield put(cloneProjectErrorAction(getErrorMessage(error) || 'Cloning Project failed'));
  }
}

export function* projectCloneWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECTS_CLONE, projectCloneSaga);
}
