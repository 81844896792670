import { CircularProgress } from '@material-ui/core';
import { withTheme } from 'styled-components';

import { IProps } from './IProps';

import { Container } from './styles';

// NOTE: Setting default `accent` color when using outside of ThemeProvider on Root level
const Loader = ({ size, fullPage, theme }: IProps): JSX.Element => (
  <Container fullPage={fullPage}>
    <CircularProgress
      size={size}
      data-testid="progress-loader"
      style={{ color: theme?.palette?.accent || '#004C97' }}
    />
  </Container>
);

export default withTheme(Loader);
