import { IFileUpload } from 'models/IFileUpload';
import { IPagination } from 'models/Pagination';
import { IVirtualComponent } from 'state_management/reducers/virtualComponents/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum VirtualComponentsActionTypes {
  GET_ALL_VIRTUAL_COMPONENTS = 'GET_ALL_VIRTUAL_COMPONENTS',
  GET_ALL_VIRTUAL_COMPONENTS_SUCCESS = 'GET_ALL_VIRTUAL_COMPONENTS_SUCCESS',
  GET_ALL_VIRTUAL_COMPONENTS_FAIL = 'GET_ALL_VIRTUAL_COMPONENTS_FAIL',

  GET_VIRTUAL_COMPONENT_DEPENDENCIES = 'GET_VIRTUAL_COMPONENT_DEPENDENCIES',

  GET_VIRTUAL_COMPONENT_BY_ID = 'GET_VIRTUAL_COMPONENT_BY_ID',
  GET_VIRTUAL_COMPONENT_BY_ID_SUCCESS = 'GET_VIRTUAL_COMPONENT_BY_ID_SUCCESS',
  GET_VIRTUAL_COMPONENT_BY_ID_FAIL = 'GET_VIRTUAL_COMPONENT_BY_ID_FAIL',

  CREATE_VIRTUAL_COMPONENT = 'CREATE_VIRTUAL_COMPONENT',
  CREATE_VIRTUAL_COMPONENT_SUCCESS = 'CREATE_VIRTUAL_COMPONENT_SUCCESS',
  CREATE_VIRTUAL_COMPONENT_FAIL = 'CREATE_VIRTUAL_COMPONENT_FAIL',

  UPDATE_VIRTUAL_COMPONENT = 'UPDATE_VIRTUAL_COMPONENT',
  UPDATE_VIRTUAL_COMPONENT_SUCCESS = 'UPDATE_VIRTUAL_COMPONENT_SUCCESS',
  UPDATE_VIRTUAL_COMPONENT_FAIL = 'UPDATE_VIRTUAL_COMPONENT_FAIL',

  CLONE_VIRTUAL_COMPONENT = 'CLONE_VIRTUAL_COMPONENT',
  CLONE_VIRTUAL_COMPONENT_SUCCESS = 'CLONE_VIRTUAL_COMPONENT_SUCCESS',
  CLONE_VIRTUAL_COMPONENT_FAIL = 'CLONE_VIRTUAL_COMPONENT_FAIL',

  DELETE_VIRTUAL_COMPONENT = 'DELETE_VIRTUAL_COMPONENT',
  DELETE_VIRTUAL_COMPONENT_SUCCESS = 'DELETE_VIRTUAL_COMPONENT_SUCCESS',
  DELETE_VIRTUAL_COMPONENT_FAIL = 'DELETE_VIRTUAL_COMPONENT_FAIL',

  UPLOAD_VIRTUAL_COMPONENT_CAD_FILE = 'UPLOAD_VIRTUAL_COMPONENT_CAD_FILE',
  UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS = 'UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS',
  UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_FAIL = 'UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_FAIL',

  VERIFY_VIRTUAL_COMPONENT_CAD_FILE = 'VERIFY_VIRTUAL_COMPONENT_CAD_FILE',
  VERIFY_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS = 'VERIFY_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS',
  VERIFY_VIRTUAL_COMPONENT_CAD_FILE_FAIL = 'VERIFY_VIRTUAL_COMPONENT_CAD_FILE_FAIL',

  DELETE_VIRTUAL_COMPONENT_CAD_FILE = 'DELETE_VIRTUAL_COMPONENT_CAD_FILE',

  UPLOAD_VIRTUAL_COMPONENT_DOC_FILE = 'UPLOAD_VIRTUAL_COMPONENT_DOC_FILE',
  UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS = 'UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS',
  UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_FAIL = 'UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_FAIL',

  VERIFY_VIRTUAL_COMPONENT_DOC_FILE = 'VERIFY_VIRTUAL_COMPONENT_DOC_FILE',
  VERIFY_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS = 'VERIFY_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS',
  VERIFY_VIRTUAL_COMPONENT_DOC_FILE_FAIL = 'VERIFY_VIRTUAL_COMPONENT_DOC_FILE_FAIL',

  DELETE_VIRTUAL_COMPONENT_DOC_FILE = 'DELETE_VIRTUAL_COMPONENT_DOC_FILE',

  UPDATE_VIRTUAL_COMPONENT_FILE = 'UPDATE_VIRTUAL_COMPONENT_FILE',
  DOWNLOAD_VIRTUAL_COMPONENT_FILE = 'DOWNLOAD_VIRTUAL_COMPONENT_FILE',
  CLEAN_UP_VIRTUAL_COMPONENTS = 'CLEAN_UP_VIRTUAL_COMPONENTS',
}

// ============================================ GET_ALL_VIRTUAL_COMPONENTS

export interface GetAllVirtualComponentsAction {
  payload: Partial<IPagination>;
  type: VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS;
}

export interface GetAllVirtualComponentsSuccessAction {
  payload: { pagination: IPagination; data: Array<IVirtualComponent> };
  type: VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS_SUCCESS;
}

export interface GetAllVirtualComponentsErrorAction {
  payload: string;
  type: VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS_FAIL;
}

// ============================================ GET_VIRTUAL_COMPONENT_DEPENDENCIES

export interface GetVirtualComponentDependenciesAction {
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES;
}

// ============================================ GET_VIRTUAL_COMPONENT_BY_ID

export interface GetVirtualComponentByIdAction {
  payload: { id: string };
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID;
}

export interface GetVirtualComponentByIdSuccessAction {
  payload: {
    data: IVirtualComponent;
    docFiles: Array<IFileUpload>;
    cadFiles: Array<IFileUpload>;
  };
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID_SUCCESS;
}

export interface GetVirtualComponentByIdErrorAction {
  payload: string;
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID_FAIL;
}

// ============================================ CREATE_VIRTUAL_COMPONENT

export interface CreateVirtualComponentAction extends AxiosRequestPayload<Raw.IVirtualComponent> {
  type: VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT;
}

export interface CreateVirtualComponentSuccessAction
  extends AxiosSuccessPayload<
    IVirtualComponent,
    VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_SUCCESS,
    CreateVirtualComponentAction
  > {
  type: VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_SUCCESS;
}

export interface CreateVirtualComponentErrorAction
  extends AxiosErrorPayload<VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_FAIL, CreateVirtualComponentAction> {
  type: VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_FAIL;
}

// ============================================ UPDATE_VIRTUAL_COMPONENT

export interface UpdateVirtualComponentAction extends AxiosRequestPayload<Raw.IVirtualComponent> {
  type: VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT;
}
export interface UpdateVirtualComponentSuccessAction
  extends AxiosSuccessPayload<
    IVirtualComponent,
    VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_SUCCESS,
    UpdateVirtualComponentAction
  > {
  type: VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_SUCCESS;
}
export interface UpdateVirtualComponentErrorAction
  extends AxiosErrorPayload<VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_FAIL, UpdateVirtualComponentAction> {
  type: VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_FAIL;
}

// ============================================ CLONE_VIRTUAL_COMPONENT

export interface CloneVirtualComponentAction extends AxiosRequestPayload<Raw.IVirtualComponent> {
  type: VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT;
}

export interface CloneVirtualComponentSuccessAction
  extends AxiosSuccessPayload<
    IVirtualComponent,
    VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_SUCCESS,
    CloneVirtualComponentAction
  > {
  type: VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_SUCCESS;
}

export interface CloneVirtualComponentErrorAction
  extends AxiosErrorPayload<VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_FAIL, CloneVirtualComponentAction> {
  type: VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT_FAIL;
}

// ============================================ DELETE_VIRTUAL_COMPONENT

export interface DeleteVirtualComponentAction extends AxiosRequestPayload {
  type: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT;
}
export interface DeleteVirtualComponentSuccessAction
  extends AxiosSuccessPayload<
    string,
    VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_SUCCESS,
    DeleteVirtualComponentAction
  > {
  type: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_SUCCESS;
}
export interface DeleteVirtualComponentErrorAction
  extends AxiosErrorPayload<VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_FAIL, DeleteVirtualComponentAction> {
  type: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_FAIL;
}

// ============================================ UPLOAD_VIRTUAL_COMPONENT_CAD_FILE

export interface UploadVirtualComponentCadFilesAction {
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE;
  payload: Array<IFileUpload>;
}

export interface UploadVirtualComponentCadFileSuccessAction {
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS;
  payload: IFileUpload;
}

export interface UploadVirtualComponentCadFileFailAction {
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_FAIL;
  payload: IFileUpload;
}

// ============================================ DELETE_VIRTUAL_COMPONENT_CAD_FILE

export interface DeleteVirtualComponentCadFileAction {
  payload: IFileUpload;
  type: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_CAD_FILE;
}

// ============================================ UPLOAD_VIRTUAL_COMPONENT_DOC_FILE

export interface UploadVirtualComponentDocFilesAction {
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE;
  payload: Array<IFileUpload>;
}

export interface UploadVirtualComponentDocFileSuccessAction {
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS;
  payload: IFileUpload;
}

export interface UploadVirtualComponentDocFileFailAction {
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_FAIL;
  payload: IFileUpload;
}

// ============================================ DELETE_VIRTUAL_COMPONENT_DOC_FILE

export interface DeleteVirtualComponentDocFileAction {
  payload: IFileUpload;
  type: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_DOC_FILE;
}

// ============================================ UPDATE_VIRTUAL_COMPONENT_FILE

export interface UpdateVirtualComponentFileAction extends AxiosRequestPayload<Partial<Raw.IMinioResource>> {
  type: VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_FILE;
}

// ============================================ DOWNLOAD_VIRTUAL_COMPONENT_FILE

export interface DownloadVirtualComponentFileAction extends AxiosRequestPayload {
  type: VirtualComponentsActionTypes.DOWNLOAD_VIRTUAL_COMPONENT_FILE;
}

// ============================================ CLEAN_UP_VIRTUAL_COMPONENTS

export interface CleanUpVirtualComponentsAction {
  type: VirtualComponentsActionTypes.CLEAN_UP_VIRTUAL_COMPONENTS;
}

// ============================================ ALL

export type VirtualComponentActions =
  | GetAllVirtualComponentsAction
  | GetAllVirtualComponentsSuccessAction
  | GetAllVirtualComponentsErrorAction
  | GetVirtualComponentDependenciesAction
  | CreateVirtualComponentAction
  | CreateVirtualComponentSuccessAction
  | CreateVirtualComponentErrorAction
  | UpdateVirtualComponentAction
  | UpdateVirtualComponentSuccessAction
  | UpdateVirtualComponentErrorAction
  | CloneVirtualComponentAction
  | CloneVirtualComponentSuccessAction
  | CloneVirtualComponentErrorAction
  | DeleteVirtualComponentAction
  | DeleteVirtualComponentSuccessAction
  | DeleteVirtualComponentErrorAction
  | GetVirtualComponentByIdAction
  | GetVirtualComponentByIdSuccessAction
  | GetVirtualComponentByIdErrorAction
  | UploadVirtualComponentCadFilesAction
  | UploadVirtualComponentCadFileSuccessAction
  | UploadVirtualComponentCadFileFailAction
  | DeleteVirtualComponentCadFileAction
  | UploadVirtualComponentDocFilesAction
  | UploadVirtualComponentDocFileSuccessAction
  | UploadVirtualComponentDocFileFailAction
  | UpdateVirtualComponentFileAction
  | DeleteVirtualComponentDocFileAction
  | CleanUpVirtualComponentsAction;
