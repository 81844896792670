import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';

import Button from 'components/Button';

import { IProps } from './IProps';

import { DialogTitle, useStyles } from './styles';

const Dialog = (props: IProps): JSX.Element => {
  const {
    open,
    heading,
    fullScreen,
    disableEscapeKeyDown,
    disableActions,
    disableCloseIcon,
    onClose,
    children,
    dialogHeadingEl,
  } = props;
  const classes = useStyles(props);

  return (
    <MuiDialog
      fullScreen={fullScreen}
      disableEscapeKeyDown={disableEscapeKeyDown}
      open={open}
      onClose={onClose}
      scroll="paper"
      classes={{ paperScrollPaper: classes.paperScrollPaper }}
    >
      {!disableActions && (
        <MuiDialogActions className={classes.actionsContainer}>
          {dialogHeadingEl || <DialogTitle>{heading}</DialogTitle>}
          <div>
            {!disableCloseIcon && (
              <Button onClick={onClose} icon="cancel" size="medium" empty className={classes.closeButton} />
            )}
          </div>
        </MuiDialogActions>
      )}
      <MuiDialogContent className={classes.root}>
        <div className={classes.content}>{children}</div>
      </MuiDialogContent>
    </MuiDialog>
  );
};

export default Dialog;
