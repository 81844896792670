import { IExportTemplate } from 'state_management/reducers/exportTemplates/Modals';
import { deserializeExportTemplate } from 'utils/exportTemplatesSerializer';
import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import {
  GetAllExportTemplatesAction,
  CreateExportTemplateAction,
  UpdateExportTemplateAction,
  DeleteExportTemplateAction,
  ExportTemplatesActionTypes,
  GetExportTemplateByIdAction,
  GetExportTemplateByIdSuccessAction,
  GetExportTemplateByIdErrorAction,
  GetAllExportTemplatesErrorAction,
  GetAllExportTemplatesSuccessAction,
  CloneExportTemplateAction,
  GetExportTemplateDependenciesAction,
} from './ActionTypes';

export const ENDPOINT_EXPORT_TEMPLATES = 'export-templates';

export const getAllExportTemplatesAction = (payload: Partial<IPagination> = {}): GetAllExportTemplatesAction => ({
  payload,
  type: ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES,
});

export const getAllExportTemplatesSuccessAction = (
  pagination: IPagination,
  data: Array<IExportTemplate>,
): GetAllExportTemplatesSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES_SUCCESS,
});

export const getAllExportTemplatesErrorAction = (payload: string): GetAllExportTemplatesErrorAction => ({
  payload,
  type: ExportTemplatesActionTypes.GET_ALL_EXPORT_TEMPLATES_FAIL,
});

export const getExportTemplateDependenciesAction = (): GetExportTemplateDependenciesAction => ({
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_DEPENDENCIES,
});

export const createExportTemplate = (exportTemplate: Partial<IExportTemplate>): CreateExportTemplateAction => ({
  type: ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_TEMPLATES}`, 2),
      data: deserializeExportTemplate(exportTemplate) as unknown as Raw.IExportTemplate,
    },
  },
});

export const updateExportTemplate = (exportTemplate: IExportTemplate): UpdateExportTemplateAction => ({
  type: ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_TEMPLATES}/${exportTemplate.id}`, 2),
      data: deserializeExportTemplate(exportTemplate) as unknown as Raw.IExportTemplate,
    },
  },
});

export const cloneExportTemplate = (exportTemplate: Partial<IExportTemplate>): CloneExportTemplateAction => ({
  type: ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_TEMPLATES}`, 2),
      data: deserializeExportTemplate(exportTemplate) as unknown as Raw.IExportTemplate,
    },
  },
});

export const deleteExportTemplate = (exportTemplate: Partial<IExportTemplate>): DeleteExportTemplateAction => ({
  type: ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_TEMPLATES}/${exportTemplate.id}`, 2),
    },
  },
});

export const getExportTemplateById = (payload: Partial<IExportTemplate>): GetExportTemplateByIdAction => ({
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getExportTemplateByIdSuccess = (payload: IExportTemplate): GetExportTemplateByIdSuccessAction => ({
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID_SUCCESS,
  payload,
});

export const getExportTemplateByIdError = (payload: string): GetExportTemplateByIdErrorAction => ({
  type: ExportTemplatesActionTypes.GET_EXPORT_TEMPLATE_BY_ID_FAIL,
  payload,
});
