import { assetUri } from 'services/utils';
import styled from 'styled-components';
// NOTE: Display grid trick to stop layout shift on Tab Switch

export const DesignCanvasPopoverContent = styled.div`
  width: 250px;
  padding: 3px;
  font-family: ${({ theme }): string => theme.fonts.normal};
  font-size: ${({ theme }): string => theme.fontSize.tiny};
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25%;
`;
export const Strong = styled.span`
  font-weight: 500;
`;

export const Container = styled.div`
  && {
    display: flex;
    height: 42px;
    background-color: ${({ theme }): string => theme.palette.white};
    box-shadow: ${({ theme }): string => theme.shadow.normal};
    border-radius: 4px;
    overflow: hidden;
  }
`;

export const TabContainer = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: '';
      height: 11px;
      width: 6px;
      background-image: url('${(): string => assetUri('/img/tab_switch_arrow.svg')}');
      background-repeat: no-repeat;
      background-position: center;
    }

    &:last-child::after {
      display: none;
    }
  }
`;

export const Tab = styled.button<{ selected?: boolean; disabled?: boolean }>`
  && {
    background: none;
    border: none;
    padding: 0 14px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    background-color: ${({ theme }): string => theme.palette.white};
    width: auto;
    font-size: ${({ theme }): string => theme.fontSize.small};
    font-weight: ${({ selected }): string => (selected ? 'bold' : '400')};
    margin: 0 14px;
    padding: 14px 0 10px 0;
    color: ${({ theme, selected }): string => (selected ? theme.palette.accent : theme.palette.text)};
    border-bottom: 2px solid ${({ selected, theme }): string => (selected ? theme.palette.accent : 'transparent')};

    pointer-events: ${({ disabled }): string => (disabled ? 'none' : '')};

    &:hover:enabled {
      color: ${({ theme }): string => theme.palette.accent};
      border-bottom: 2px solid ${({ theme }): string => theme.palette.accent};
    }

    &:disabled {
      color: ${({ theme }): string => theme.palette.disabled};
    }
`;

export const CanvasProgressState = styled.button<{ selected?: boolean; disabled?: boolean }>`
  && {
    height: 42px;
    background: none;
    border: none;
    padding: 0 14px;
    font: inherit;   
    outline: inherit;
    background-color: ${({ theme }): string => theme.palette.white};
    width: auto;
    color: ${({ theme }): string => theme.palette.text};
    font-size: ${({ theme }): string => theme.fontSize.small};
    font-weight: ${({ selected }): string => (selected ? 'bold' : '400')};
    margin: 0;
    margin-left: -14px;
    border-bottom: 2px solid ${({ selected, theme }): string => (selected ? theme.palette.accent : 'transparent')};

    pointer-events: ${({ disabled }): string => (disabled ? 'none' : '')};

    &:hover:enabled {
      color: ${({ theme }): string => theme.palette.accent};
    }

    &:disabled {
      color: ${({ theme }): string => theme.palette.disabled};
    }
`;

export const PopoverContent = styled.p`
  && {
    margin: 0;
    padding: 8px;
`;
