import { IComponentExternalSource } from 'state_management/reducers/components/Modals';
import { IInstanceSpecification, IImplementationSpecification } from 'state_management/reducers/specifications/Modals';
import { IVirtualComponent } from 'state_management/reducers/virtualComponents/Modals';
import { deserializeInstanceSpecifications, serializeInstanceSpecifications } from './specificationsSerializer';

const setDefaultValue = <T>(condition?: boolean, defaultValue?: T): T | undefined =>
  condition ? undefined : defaultValue;

export const deserializeSpecification = (
  instanceSpecifications: Array<IInstanceSpecification>,
  specificationsImplList?: Array<IImplementationSpecification>,
): Array<Raw.InstanceSpecification> => {
  const specImplMap: Record<string, IImplementationSpecification> =
    specificationsImplList?.reduce((p, c: IImplementationSpecification) => ({ ...p, [c.id as string]: c }), {}) || {};

  return (
    instanceSpecifications &&
    instanceSpecifications.map((spec) => deserializeInstanceSpecifications(spec, specImplMap[spec.id]))
  );
};

const deserializeExternalSources = (
  externalSources?: Array<IComponentExternalSource>,
  isChangesRequest?: boolean,
): Array<Raw.ComponentExternalSource> | undefined =>
  externalSources?.length
    ? externalSources.map((es) => ({ provider_id: es.providerId, provider_name: es.providerName }))
    : setDefaultValue(isChangesRequest, []);

export const deserializeVirtualComponent = (
  component: Partial<IVirtualComponent>,
  isChangesRequest?: boolean,
  specificationsImplList?: Array<IImplementationSpecification>,
): Partial<Raw.IVirtualComponent> => ({
  id: component.id,
  external_sources: deserializeExternalSources(component.externalSources, isChangesRequest),
  internal_part_number: component.internalPartNumber || setDefaultValue(isChangesRequest, ''),
  description: component.description || setDefaultValue(isChangesRequest, ''),
  full_info: component.fullInfo || setDefaultValue(isChangesRequest, ''),
  created_at: component.createdAt,
  modified_at: component.modifiedAt,
  footprint_name: component.footprintName || setDefaultValue(isChangesRequest, ''),
  symbol_name: component.symbolName || setDefaultValue(isChangesRequest, ''),
  lifecycle_status: component.lifecycleStatus || setDefaultValue(isChangesRequest, ''),
  taxonomy: component.taxonomy || setDefaultValue<any>(isChangesRequest, null),
  instance_specifications: component.instanceSpecifications?.length
    ? deserializeSpecification(component.instanceSpecifications, specificationsImplList)
    : setDefaultValue(isChangesRequest, []),
  instance_custom_fields: [],
  ports: component.ports?.length ? component.ports : setDefaultValue(isChangesRequest, []),
  cad_files: component.cadFiles?.length ? component.cadFiles : setDefaultValue(isChangesRequest, []),
  datasheet_files: component.datasheetFiles?.length ? component.datasheetFiles : setDefaultValue(isChangesRequest, []),
  datasheet_url: component.datasheetUrl || setDefaultValue(isChangesRequest, ''),
  price: component.price || setDefaultValue(isChangesRequest, 0),
  links: component?.links?.map((link) => ({ reference_id: link.referenceId, link_type: link.linkType })),
});

export const serializeVirtualComponent = (component: Raw.IVirtualComponent): IVirtualComponent => ({
  id: component.id,
  internalPartNumber: component.internal_part_number,
  providerId: component.external_sources?.length ? component.external_sources[0].provider_id : '',
  externalSources:
    component.external_sources?.map((es) => ({
      providerId: es.provider_id,
      providerName: es.provider_name,
    })) || [],
  description: component.description,
  fullInfo: component.full_info,
  createdAt: component.created_at,
  modifiedAt: component.modified_at,
  footprintName: component.footprint_name,
  symbolName: component.symbol_name,
  lifecycleStatus: component.lifecycle_status,
  taxonomy: component.taxonomy,
  instanceSpecifications:
    (component.instance_specifications && component.instance_specifications.map(serializeInstanceSpecifications)) || [],
  instanceCustomFields: [],
  ports: component.ports,
  cadFiles: component.cad_files || [],
  datasheetFiles: component.datasheet_files || [],
  datasheetUrl: component.datasheet_url,
  price: component.price,
  links: component?.links?.map((link) => ({ referenceId: link.reference_id, linkType: link.link_type })),
});
