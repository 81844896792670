import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  select,
  SelectEffect,
  call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  RequestsActionTypes,
  GetAllChangesRequestsAction,
  GetAllChangesRequestsSuccessAction,
  GetAllChangesRequestsErrorAction,
} from 'state_management/actions/changesRequest/ActionTypes';

import { getErrorMessage } from 'utils/getErrorMessage';
import {
  getAllRequestsSuccessAction,
  getAllRequestsErrorAction,
  ENDPOINT_REQUESTS,
} from 'state_management/actions/changesRequest/changesRequestActions';
import { AppState } from 'state_management/AppState';
import { ApprovalsType } from 'state_management/reducers/changesRequest/Modals';
import { combineSearchQueries, getSearchQuery, TypeSearch } from 'utils/searchHelper';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { escapeSearch } from 'utils/urlUtils';
import { apiUri } from 'services/main_app';
import { axiosInstance } from 'services/dataService';
import { serializeChangesRequests } from 'utils/changesRequestSerializer';
import { Roles } from 'state_management/reducers/auth/Modals';

export function* getAllSaga(
  action: GetAllChangesRequestsAction,
): Generator<
  | PutEffect<GetAllChangesRequestsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllChangesRequestsSuccessAction | GetAllChangesRequestsErrorAction>
> {
  try {
    const {
      changesRequestState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const changesRequestSearchSchema: TypeSearch = {
      document_name: 'value',
      resource_name: 'value',
      resource_id: 'value',
      state: 'value',
      target_method: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), changesRequestSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_REQUESTS}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2)),
    )) as AxiosResponse;

    const _pagination = { ...getPaginationFromResponse(res), search: search || '' };

    // figuring out icons on the list
    const newData = (res.data as Array<Raw.IChangesRequest>)?.map((data) => {
      if (data.state !== 'pending') return { ...data, requestIcon: data.state };

      let canApproveForReal = false;
      let waitForYourResponse = false;
      data.approvals?.forEach((approval: ApprovalsType) => {
        const canApprove = userInfo?.roles?.find((role: Roles) => role === approval.target);
        canApproveForReal = canApproveForReal || !!canApprove;
        if (canApprove && approval.responsible === '') {
          waitForYourResponse = true;
        }
      });
      const requestIcon =
        (waitForYourResponse && 'waitingYourResponse') ||
        (canApproveForReal && !waitForYourResponse && 'waitingForOthers') ||
        data.state;
      return { ...data, requestIcon };
    });

    yield put(getAllRequestsSuccessAction(_pagination, newData.map(serializeChangesRequests)));
  } catch (error) {
    yield put(getAllRequestsErrorAction(getErrorMessage(error) || 'Fetching requests failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(RequestsActionTypes.GET_ALL_CHANGES_REQUESTS, getAllSaga);
}
