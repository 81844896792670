import { upsertResource } from 'utils/resourceUtils';
import { ChangeLogState } from './Modals';
import { ChangeLogActions, ChangeLogActionTypes } from '../../actions/changeLog/ActionTypes';

export const initialChangeLogState: ChangeLogState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  changeLogList: [],
  changeLogListState: 'idle',
  changeLogListError: '',
  changeLogByIdState: 'idle',
  changeLogByIdError: '',
};

export const changeLogReducer = (
  state: ChangeLogState = initialChangeLogState,
  action: ChangeLogActions,
): ChangeLogState => {
  switch (action.type) {
    case ChangeLogActionTypes.GET_ALL_CHANGE_LOG:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        changeLogListState: 'loading',
      };
    case ChangeLogActionTypes.GET_ALL_CHANGE_LOG_SUCCESS:
      return {
        ...state,
        changeLogListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        changeLogList: action.payload.data,
      };
    case ChangeLogActionTypes.GET_ALL_CHANGE_LOG_FAIL:
      return {
        ...state,
        changeLogListState: 'error',
        changeLogListError: action.payload,
      };
    case ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID:
      return {
        ...state,
        changeLogByIdState: 'loading',
      };
    case ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID_SUCCESS:
      return {
        ...state,
        changeLogByIdState: 'success',
        changeLogList: upsertResource(state.changeLogList, action.payload),
      };
    case ChangeLogActionTypes.GET_CHANGE_LOG_BY_ID_FAIL:
      return {
        ...state,
        changeLogByIdState: 'error',
        changeLogByIdError: action.payload,
      };

    default:
      return state;
  }
};
