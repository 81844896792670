import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import { Project } from 'state_management/reducers/projects/Modals';
import { deserializeProject } from 'utils/projectSerializer';
import { DS_PROJECTS_ENDPOINT, MACRO_RES_PROJECTS_ENDPOINT } from '../projects/projectsActions';
import {
  RefDesignActionTypes,
  RefDesignCreateAction,
  RefDesignDeleteAction,
  RefDesignsGetAllAction,
  RefDesignsGetAllErrorAction,
  RefDesignsGetAllSuccessAction,
  RefDesignsGetByIdAction,
  RefDesignUpdateAction,
} from './ActionTypes';

export const getAllRefDesigns = (payload: Partial<IPagination> = {}): RefDesignsGetAllAction => ({
  type: RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS,
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
});

export const getAllRefDesignsSuccessAction = (
  pagination: IPagination,
  data: Array<Project>,
): RefDesignsGetAllSuccessAction => ({
  type: RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_SUCCESS,
  payload: {
    data,
    pagination,
  },
});

export const getAllRefDesignsErrorAction = (payload: string): RefDesignsGetAllErrorAction => ({
  payload,
  type: RefDesignActionTypes.GET_ALL_REFERENCE_DESIGNS_FAIL,
});

export const createRefDesign = (id: string): RefDesignCreateAction => ({
  type: RefDesignActionTypes.CREATE_REFERENCE_DESIGNS,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      data: {
        referenceDesign: true,
      },
      url: apiUri(`${MACRO_RES_PROJECTS_ENDPOINT}/${id}/clone`, 2),
    },
  },
});

export const updateRefDesign = (refDesign: Partial<Project>): RefDesignUpdateAction => ({
  type: RefDesignActionTypes.UPDATE_REFERENCE_DESIGNS,
  payload: {
    request: {
      method: 'patch',
      withCredentials: true,
      url: apiUri(`${DS_PROJECTS_ENDPOINT}/${refDesign.id}`, 2),
      data: { ...deserializeProject(refDesign), userID: refDesign.userId },
    },
  },
});

export const deleteRefDesign = (refDesign: Partial<Project>): RefDesignDeleteAction => ({
  type: RefDesignActionTypes.DELETE_REFERENCE_DESIGNS,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`${MACRO_RES_PROJECTS_ENDPOINT}/${refDesign.id}`, 2),
    },
  },
});

export const getReferenceDesignsById = (payload: Partial<Project>): RefDesignsGetByIdAction => ({
  type: RefDesignActionTypes.GET_REFERENCE_DESIGNS_BY_ID,
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri(`${DS_PROJECTS_ENDPOINT}/${payload.id}`, 2),
    },
  },
});
