import { IBlockV2 } from 'models/ICanvas';
import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import { ISubSystem } from 'state_management/reducers/subSystemCanvas/Modals';
import { deSerializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';
import {
  SubSystemCanvasActionTypes,
  GetSubSystemCanvasAction,
  GetSubSystemCanvasSuccessAction,
  GetSubSystemCanvasErrorAction,
  SaveSelectedAlternativeAction,
  SaveSelectedAlternativeErrorAction,
  SaveSelectedAlternativeSuccessAction,
  ResetSubSystemAction,
  SetSelectedBlockAction,
  SaveSubSystemCanvasAction,
  SaveSubSystemCanvasErrorAction,
  SaveSubSystemCanvasSuccessAction,
  GetAllSubSystemsAction,
  GetAllSubSystemsErrorAction,
  GetAllSubSystemsSuccessAction,
  GetDesignBlockAlternativeAction,
  UpdateSubSystemCanvasAction,
  UpdateSubSystemCanvasErrorAction,
  UpdateSubSystemCanvasSuccessAction,
  ClearSelectedBlockAction,
  CloneSubSystemCanvasAction,
  DeleteSubSystemCanvasAction,
} from './ActionTypes';

export const ENDPOINT_SUB_SYSTEM_CANVAS = 'subsystems';

export const setSelectedBlockAction = (data: IBlockV2): SetSelectedBlockAction => ({
  payload: data,
  type: SubSystemCanvasActionTypes.SET_SELECTED_BLOCK,
});

export const clearSelectedBlockAction = (): ClearSelectedBlockAction => ({
  type: SubSystemCanvasActionTypes.CLEAR_SELECTED_BLOCK,
});

export const getAllSubSystemsAction = (
  payload: Partial<IPagination & { selectedWorkspaces?: string[] }> = {},
): GetAllSubSystemsAction => ({
  payload,
  type: SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS,
});

export const getAllSubSystemsSuccessAction = (
  pagination: IPagination,
  data: Array<Partial<ISubSystem>>,
): GetAllSubSystemsSuccessAction => ({
  payload: {
    pagination,
    data,
  },
  type: SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_SUCCESS,
});

export const getAllSubSystemsErrorAction = (payload: string): GetAllSubSystemsErrorAction => ({
  payload,
  type: SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_FAIL,
});

export const getSubSystemCanvasAction = (id: string): GetSubSystemCanvasAction => ({
  payload: id,
  type: SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS,
});

export const getSubSystemCanvasSuccessAction = (payload: ISubSystem): GetSubSystemCanvasSuccessAction => ({
  payload,
  type: SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_SUCCESS,
});

export const getSubSystemCanvasErrorAction = (payload: string): GetSubSystemCanvasErrorAction => ({
  payload,
  type: SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_FAIL,
});

export const saveSubSystemCanvasAction = (canvas: Partial<ISubSystem>): SaveSubSystemCanvasAction => ({
  payload: canvas,
  type: SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS,
});

export const saveSubSystemCanvasSuccessAction = (payload: Partial<ISubSystem>): SaveSubSystemCanvasSuccessAction => ({
  payload,
  type: SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_SUCCESS,
});

export const saveSubSystemCanvasErrorAction = (payload: string): SaveSubSystemCanvasErrorAction => ({
  payload,
  type: SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_FAIL,
});

export const updateSubSystemCanvasAction = (canvas: Partial<ISubSystem>): UpdateSubSystemCanvasAction => ({
  payload: canvas,
  type: SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS,
});

export const updateSubSystemCanvasSuccessAction = (payload: ISubSystem): UpdateSubSystemCanvasSuccessAction => ({
  payload,
  type: SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS_SUCCESS,
});

export const updateSubSystemCanvasErrorAction = (payload: string): UpdateSubSystemCanvasErrorAction => ({
  payload,
  type: SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS_FAIL,
});

export const saveSelectedAlternativeAction = (
  alternativeId: string,
  subSystemBlockId?: string,
): SaveSelectedAlternativeAction => ({
  payload: {
    alternativeId,
    subSystemBlockId,
  },
  type: SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE,
});

export const saveSelectedAlternativeSuccessAction = (): SaveSelectedAlternativeSuccessAction => ({
  type: SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE_SUCCESS,
});

export const saveSelectedAlternativeErrorAction = (payload: string): SaveSelectedAlternativeErrorAction => ({
  payload,
  type: SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE_FAIL,
});

export const getDesignBlockAlternativeAction = (payload: string): GetDesignBlockAlternativeAction => ({
  payload,
  type: SubSystemCanvasActionTypes.GET_DESIGN_BLOCK_ALTERNATIVE,
});

export const resetSubSystemAction = (): ResetSubSystemAction => ({
  type: SubSystemCanvasActionTypes.RESET_SUB_SYSTEM_CANVAS,
});

export const cloneSubSystemCanvas = (canvas: Partial<ISubSystem>): CloneSubSystemCanvasAction => ({
  type: SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SUB_SYSTEM_CANVAS}`, 2),
      data: deSerializeSubSystemCanvas(canvas),
    },
  },
});

export const deleteSubSystemCanvas = (canvas: Partial<ISubSystem>): DeleteSubSystemCanvasAction => ({
  type: SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SUB_SYSTEM_CANVAS}/${canvas.id}`, 2),
    },
  },
});
