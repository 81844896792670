import { ActionType } from 'views/Project/DesignToolBar/util';

export enum DesignSystemToolBarActionTypes {
  SET_SELECTED_ACTION = 'SET_SELECTED_ACTION',
  RESET_SELECTED_ACTION = 'RESET_SELECTED_ACTION',
}

export interface SetSelectedActionAction {
  payload: {
    selectedAction: ActionType;
    isApplicableToBlock: boolean;
    isApplicableToLink: boolean;
  };
  type: DesignSystemToolBarActionTypes.SET_SELECTED_ACTION;
}

export interface ResetSelectedAction {
  type: DesignSystemToolBarActionTypes.RESET_SELECTED_ACTION;
}

export type DesignSystemToolBarActions = SetSelectedActionAction | ResetSelectedAction;
