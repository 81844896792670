/* eslint-disable max-classes-per-file */
/* eslint-disable no-lonely-if */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BetweenSearchValue,
  MinMaxSearchValue,
  MultipleValuesSearchValue,
  OperationData,
  SearchValue,
  SimpleSearchValue,
  TypToleranceSearchValue,
} from 'components/AdvancedSearch/Modals';
import { AdvancedSearchFilterType } from 'views/BlockLibrary/BlockLibraryList/Modals';

type UserValuesTypes = MultipleValuesSearchValue | MinMaxSearchValue | TypToleranceSearchValue | SearchValue;

/**
 * An abstract class including methods which help you
 * to find out if the specification ranges button should be enabled or not
 */
export abstract class SpecAddButtonValidator {
  constructor(userValues: UserValuesTypes, formValues?: Partial<AdvancedSearchFilterType>) {
    this.userValues = userValues;
    this.formValues = formValues;
  }

  protected userValues: UserValuesTypes;

  protected formValues?: Partial<AdvancedSearchFilterType>;

  protected abstract hasIncompleteOperationData(obj: UserValuesTypes): boolean;

  /**
   * This function determines whether the Specification Button should be disabled
   */

  public abstract isSpecificationDataValid(): boolean;
}

/**
 * This class is a validator for validating Typical-Tolerance specification range.
 * The public method is used for the Add Button validation
 */
export class TypTolAddButtonValidator extends SpecAddButtonValidator {
  constructor(userValues: UserValuesTypes, formValues?: Partial<AdvancedSearchFilterType>) {
    super(userValues, formValues);
  }

  protected hasIncompleteOperationData(obj: TypToleranceSearchValue): boolean {
    let foundIncompleteData = false;
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const operationData = ((obj as any)[key] as OperationData) || {};
      const objectKeyCounts = Object.keys(operationData)?.length;

      /* If it has no keys, it means it has not been set at all yet.
                   It is ok to consider such an object as a valid one  */
      if (objectKeyCounts === 0) break;
      /* There should be at least 2 keys (operator and data). Otherwise, the data is not valid yet */
      if (objectKeyCounts < 2) {
        foundIncompleteData = true;
        break;
        /* It has 2 keys, so check if the actual data is valid and acceptable */
      } else {
        if (!operationData?.operator) {
          foundIncompleteData = true;
          break;
        } else if (operationData?.operator?.value === 'between') {
          const { minData, maxData } = operationData?.data as BetweenSearchValue;
          const minDataValue = minData?.value;
          const minDataUnit = minData?.unit;
          const maxDataValue = maxData?.value;
          const maxDataUnit = maxData?.unit;
          if (minDataValue === undefined || !minDataUnit || maxDataValue === undefined || !maxDataUnit) {
            foundIncompleteData = true;
            break;
          }
        } else {
          const { unit, value } = (operationData?.data as SimpleSearchValue) || {};
          if (value === undefined || !unit) {
            foundIncompleteData = true;
            break;
          }
        }
      }
    }
    return foundIncompleteData;
  }

  public isSpecificationDataValid(): boolean {
    const dataObject = this.userValues as TypToleranceSearchValue;
    /* Typical part is mandatory */
    /* Tolerance without any typical doesn't make sense */
    const hasValidTypPart = ((): boolean => {
      const { operator, data } = (dataObject?.typ as OperationData) || {};
      const { unit, value } = (data as SimpleSearchValue) || {};
      const { minData, maxData } = (data as BetweenSearchValue) || {};
      const minDataValue = minData?.value;
      const minDataUnit = minData?.unit;
      const maxDataValue = maxData?.value;
      const maxDataUnit = maxData?.unit;
      /* a valid data item must have operator, value and unit,
                  or the same things in a range value including min and max parts */
      return !!(
        (operator && unit && value !== undefined) ||
        (minDataValue !== undefined && maxDataValue !== undefined && minDataUnit && maxDataUnit)
      );
    })();
    const hasIncompleteParts = this.hasIncompleteOperationData(dataObject);
    return hasValidTypPart && !hasIncompleteParts;
  }
}

export class TypTolOnlyEqualAddButtonValidator extends SpecAddButtonValidator {
  protected hasIncompleteOperationData(obj: TypToleranceSearchValue): boolean {
    throw new Error('not implemented exception');
  }

  public isSpecificationDataValid(): boolean {
    const { typ, toleranceMax, toleranceMin } = (this.userValues as TypToleranceSearchValue) || {};
    const typSimpleData = (typ?.data as SimpleSearchValue) || {};
    const tolMaxSimpleData = (toleranceMax?.data as SimpleSearchValue) || {};
    const tolMinSimpleData = (toleranceMin?.data as SimpleSearchValue) || {};
    const isValid = !!(
      typSimpleData.unit &&
      typSimpleData.value !== undefined &&
      tolMaxSimpleData.unit &&
      tolMaxSimpleData.value !== undefined &&
      tolMinSimpleData.unit &&
      tolMinSimpleData.value !== undefined
    );
    return isValid;
  }
}

/**
 * This class is a validator for validating Minimum-Maximum-Typical specification range.
 * The public method is used for the Add Button validation
 */

export class MinMaxTypAddButtonValidator extends SpecAddButtonValidator {
  constructor(userValues: UserValuesTypes, formValues?: Partial<AdvancedSearchFilterType>) {
    super(userValues, formValues);
  }

  protected hasIncompleteOperationData(obj: MinMaxSearchValue): boolean {
    let isIncomplete = false;
    const keys = (Object.keys(obj) as Array<keyof MinMaxSearchValue>) || [];
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      const { data, operator } = (obj[key] as OperationData) || {};
      const betweenData = data as BetweenSearchValue;
      const simpleData = data as SimpleSearchValue;
      if (operator?.value) {
        if (operator?.value === 'between') {
          isIncomplete =
            !betweenData?.maxData?.unit ||
            betweenData?.maxData?.value === undefined ||
            !betweenData?.minData?.unit ||
            betweenData?.minData?.value === undefined;
          if (isIncomplete) break;
        } else {
          isIncomplete = !simpleData?.unit || simpleData?.value === undefined;
          if (isIncomplete) break;
        }
      } else {
        isIncomplete = !!(
          simpleData?.unit ||
          simpleData?.value !== undefined ||
          betweenData?.minData?.unit ||
          betweenData?.minData?.value !== undefined ||
          betweenData?.maxData?.value !== undefined ||
          betweenData?.maxData?.unit
        );
        if (isIncomplete) break;
      }
    }
    return isIncomplete;
  }

  public isSpecificationDataValid(): boolean {
    const dataObject = this.userValues as MinMaxSearchValue;
    const keys = Object.keys(dataObject);
    /* Checks whether is there ANY valid single value item */
    let hasAnySingleValue = false;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i] as keyof MinMaxSearchValue;
      const { data, operator } = (dataObject[key] || {}) as OperationData;
      hasAnySingleValue = !!(
        (data as SimpleSearchValue)?.value !== undefined &&
        (data as SimpleSearchValue)?.unit &&
        operator?.value
      );
      if (hasAnySingleValue) break;
    }
    /* Checks whether is there ANY valid compound value item */
    let hasAnyCompoundValue = false;
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i] as keyof MinMaxSearchValue;
      const { data, operator } = (dataObject[key] || {}) as OperationData;
      const { minData, maxData } = (data as BetweenSearchValue) || {};

      if (minData && maxData) {
        hasAnyCompoundValue = !!(
          minData?.unit !== undefined &&
          minData?.value !== undefined &&
          maxData?.unit !== undefined &&
          maxData?.value !== undefined &&
          operator?.value
        );
      }
      if (hasAnyCompoundValue) break;
    }
    const hasIncompleteParts = this.hasIncompleteOperationData(dataObject);
    return (hasAnySingleValue || hasAnyCompoundValue) && !hasIncompleteParts;
  }
}

/**
 * This class is a validator for validating Minimum-Maximum-Typical specification range
 * inside the subsystem canvas
 * The public method is used for the Add Button validation
 */

export class MinMaxTypOnlyEqualAddButtonValidator extends SpecAddButtonValidator {
  constructor(userValues: UserValuesTypes, formValues?: Partial<AdvancedSearchFilterType>) {
    super(userValues, formValues);
  }

  protected hasIncompleteOperationData(obj: MinMaxSearchValue): boolean {
    return false;
  }

  public isSpecificationDataValid(): boolean {
    let isValid = true;
    if (!this.formValues?.field?.inputType) {
      isValid = false;
    } else {
      const { min, max, typ } = (this.userValues as MinMaxSearchValue) || {};
      const minSimpleData = (min?.data as SimpleSearchValue) || {};
      const maxSimpleData = (max?.data as SimpleSearchValue) || {};

      if (this.formValues?.field?.inputType === 'min_max') {
        isValid = !!(
          minSimpleData?.unit &&
          minSimpleData?.value !== undefined &&
          maxSimpleData?.unit &&
          maxSimpleData?.value !== undefined
        );
      }

      if (this.formValues?.field?.inputType === 'min_typ_max') {
        const typSimpleData = (typ?.data as SimpleSearchValue) || {};
        isValid = !!(
          minSimpleData?.unit &&
          minSimpleData?.value !== undefined &&
          maxSimpleData?.unit &&
          maxSimpleData?.value !== undefined &&
          typSimpleData?.unit &&
          typSimpleData?.value !== undefined
        );
      }
    }
    return isValid;
  }
}

/**
 * This class is a validator for validating Multiple Values specification range.
 * The public method is used for the Add Button validation
 */
export class MultipleValuesAddButtonValidator extends SpecAddButtonValidator {
  constructor(userValues: UserValuesTypes, formValues?: Partial<AdvancedSearchFilterType>) {
    super(userValues, formValues);
  }

  hasIncompleteOperationData(obj: MultipleValuesSearchValue): boolean {
    throw new Error('not implemented exception');
  }

  public isSpecificationDataValid(): boolean {
    const userValues = this.userValues as MultipleValuesSearchValue;
    return !!(this.userValues && this.formValues?.operator && userValues?.value?.length && userValues?.unit);
  }
}

/**
 * This class is a validator for validating Single Values specification range.
 * The public method is used for the Add Button validation
 */

export class SingleValueAddButtonValidator extends SpecAddButtonValidator {
  constructor(userValues: UserValuesTypes, formValues?: Partial<AdvancedSearchFilterType>, fieldName?: string) {
    super(userValues, formValues);
    this.fieldName = fieldName;
  }

  private fieldName?: string;

  hasIncompleteOperationData(obj: MultipleValuesSearchValue): boolean {
    throw new Error('not implemented exception');
  }

  public isSpecificationDataValid(): boolean {
    let isValid = true;
    if (!this.fieldName || !this.formValues?.operator?.value) {
      isValid = false;
    } else if (
      !(this.userValues as SearchValue)?.value?.toString() &&
      !['-exists', '-not-exists'].includes(this.formValues?.operator?.value as string)
    ) {
      isValid = false;
    } else if (
      Array.isArray((this.userValues as SearchValue)?.value) &&
      !((this.userValues as SearchValue)?.value as Array<string>)?.length
    ) {
      isValid = false;
    }
    return isValid;
  }
}
