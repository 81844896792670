import { all, AllEffect } from 'redux-saga/effects';
import { getAllWatcher } from './getAllSaga';
import { getWatcher } from './getSaga';
import { requestsToastsWatcher } from './requestsToastsSaga';
import { saveWatcher } from './saveSaga';
import { updateSelectedAlternativeWatcher } from './updateSelectedAlternativeSaga';
import { getAlternativeSagaWatcher } from './getAlternativeSaga';
import { updateSagaWatcher } from './updateSaga';
import { refreshSaga } from './refreshSaga';

export function* subSystemCanvasRootSaga(): Generator<AllEffect<any>> {
  yield all([
    getWatcher(),
    getAllWatcher(),
    saveWatcher(),
    updateSagaWatcher(),
    updateSelectedAlternativeWatcher(),
    requestsToastsWatcher(),
    getAlternativeSagaWatcher(),
    refreshSaga(),
  ]);
}
