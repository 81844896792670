import PropTypes from 'prop-types';
import { Icon } from './styles';

const propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.string.isRequired,

  withLoadingAnimation: PropTypes.bool,
};

const defaultProps = {
  color: 'inherit',
  size: 'normal',
  withLoadingAnimation: true,
};

const sizes = {
  nano: 8,
  tiny: 12,
  small: 16,
  normal: 18,
  medium: 24,
  large: 32,
};
const CelusIcon = ({ color, size, children, withLoadingAnimation }) => {
  const sizePx = typeof size === 'number' ? size : sizes[size] || sizes.normal;
  return <Icon className={`celusico-${children}`} size={sizePx} color={color} active={withLoadingAnimation} />;
};

CelusIcon.propTypes = propTypes;
CelusIcon.defaultProps = defaultProps;

export default CelusIcon;
