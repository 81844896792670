import { ISupplier } from 'state_management/reducers/suppliers/Modals';
import { deserializeSupplier } from 'utils/suppliersSerializer';
import { IPagination } from 'models/Pagination';
import {
  GetAllSuppliersAction,
  CreateSupplierAction,
  UpdateSupplierAction,
  DeleteSupplierAction,
  SuppliersActionTypes,
  GetSupplierByIdAction,
  GetSupplierByIdSuccessAction,
  GetSupplierByIdErrorAction,
  GetAllSuppliersErrorAction,
  GetAllSuppliersSuccessAction,
  CloneSupplierAction,
  GetSupplierDependenciesAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const ENDPOINT_SUPPLIERS = 'suppliers';

export const getAllSuppliersAction = (payload: Partial<IPagination> = {}): GetAllSuppliersAction => ({
  payload: { ...payload, queryType: payload.queryType || 'paginated' },
  type: SuppliersActionTypes.GET_ALL_SUPPLIERS,
});

export const getAllSuppliersSuccessAction = (
  pagination: IPagination,
  data: Array<ISupplier>,
): GetAllSuppliersSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: SuppliersActionTypes.GET_ALL_SUPPLIERS_SUCCESS,
});

export const getAllSuppliersErrorAction = (payload: string): GetAllSuppliersErrorAction => ({
  payload,
  type: SuppliersActionTypes.GET_ALL_SUPPLIERS_FAIL,
});

export const getSupplierDependenciesAction = (): GetSupplierDependenciesAction => ({
  type: SuppliersActionTypes.GET_SUPPLIER_DEPENDENCIES,
});

export const createSupplier = (supplier: Partial<ISupplier>): CreateSupplierAction => ({
  type: SuppliersActionTypes.CREATE_SUPPLIER,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SUPPLIERS}`, 2),
      data: deserializeSupplier(supplier) as unknown as Raw.ISupplier,
    },
  },
});

export const updateSupplier = (supplier: ISupplier): UpdateSupplierAction => ({
  type: SuppliersActionTypes.UPDATE_SUPPLIER,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SUPPLIERS}/${supplier.id}`, 2),
      data: deserializeSupplier(supplier) as unknown as Raw.ISupplier,
    },
  },
});

export const cloneSupplier = (supplier: Partial<ISupplier>): CloneSupplierAction => ({
  type: SuppliersActionTypes.CLONE_SUPPLIER,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SUPPLIERS}`, 2),
      data: deserializeSupplier(supplier) as unknown as Raw.ISupplier,
    },
  },
});

export const deleteSupplier = (supplier: Partial<ISupplier>): DeleteSupplierAction => ({
  type: SuppliersActionTypes.DELETE_SUPPLIER,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_SUPPLIERS}/${supplier.id}`, 2),
    },
  },
});

export const getSupplierById = (payload: Partial<ISupplier>): GetSupplierByIdAction => ({
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getSupplierByIdSuccess = (payload: ISupplier): GetSupplierByIdSuccessAction => ({
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID_SUCCESS,
  payload,
});

export const getSupplierByIdError = (payload: string): GetSupplierByIdErrorAction => ({
  type: SuppliersActionTypes.GET_SUPPLIER_BY_ID_FAIL,
  payload,
});
