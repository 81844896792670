import { all, AllEffect, ForkEffect, takeLatest, call, CallEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { SupportActionTypes } from 'state_management/actions/support/ActionTypes';
import { axiosInstance } from 'services/dataService';
import { AxiosResponse } from 'axios';
import { BROWSER_SUPPORT_CHECK, ENDPOINT_SUPPORT } from 'state_management/actions/support/supportActions';
import { apiHostV2 } from 'config';

const TOAST_IDS: { [key: string]: string | undefined } = {};

export function* requestsBrowserSupportSaga(): Generator<CallEffect<AxiosResponse>> {
  try {
    const {
      data: { isSupported },
    } = (yield call(() => axiosInstance.get(`${apiHostV2}/${ENDPOINT_SUPPORT}/browser`))) as AxiosResponse;

    if (!isSupported) {
      TOAST_IDS.unsupportedBrowser = globalToast(
        TOAST_IDS.unsupportedBrowser,
        'warning',
        {
          warning:
            'Current browser version is not supported. To avoid potential issues, please upgrade your version, or use another browser.',
        },
        { autoClose: false },
        { onClose: () => delete TOAST_IDS.unsupportedBrowser },
      );
    }

    localStorage.setItem(BROWSER_SUPPORT_CHECK, 'checked');
  } catch (error) {
    if (process.env.NODE_ENV === 'test') {
      throw error;
    }
    // NOTE: Do nothing, as we don't wanna throw false flags or bother user in case everything was ok
  }
}

export function* requestsBrowserSupportSagaWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([takeLatest(SupportActionTypes.GET_BROWSER_SUPPORT, requestsBrowserSupportSaga)]);
}
