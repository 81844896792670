import { apiUri } from 'services/main_app';

import { IExportJob, IExportJobWithChildren } from 'state_management/reducers/exportJobs/Modals';
import { deserializeExportJob } from 'utils/exportJobsSerializer';
import { IPagination } from 'models/Pagination';
import {
  GetAllExportJobsAction,
  CreateExportJobAction,
  UpdateExportJobAction,
  DeleteExportJobAction,
  ExportJobsActionTypes,
  GetExportJobByIdAction,
  GetExportJobByIdSuccessAction,
  GetExportJobByIdErrorAction,
  GetAllExportJobsErrorAction,
  GetAllExportJobsSuccessAction,
  CloneExportJobAction,
  GetExportJobDependenciesAction,
  CreateExportJobSuccessAction,
  CreateExportJobErrorAction,
  RunExportJobAction,
} from './ActionTypes';

export const ENDPOINT_EXPORT_JOBS = 'export-jobs';
export const ENDPOINT_EXPORT_JOBS_FILES = 'object-storage/report-files';

export const getAllExportJobsAction = (payload: Partial<IPagination> = {}): GetAllExportJobsAction => ({
  payload,
  type: ExportJobsActionTypes.GET_ALL_EXPORT_JOBS,
});

export const getAllExportJobsSuccessAction = (
  pagination: IPagination,
  data: Array<IExportJob>,
): GetAllExportJobsSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: ExportJobsActionTypes.GET_ALL_EXPORT_JOBS_SUCCESS,
});

export const getAllExportJobsErrorAction = (payload: string): GetAllExportJobsErrorAction => ({
  payload,
  type: ExportJobsActionTypes.GET_ALL_EXPORT_JOBS_FAIL,
});

export const getExportJobDependenciesAction = (): GetExportJobDependenciesAction => ({
  type: ExportJobsActionTypes.GET_EXPORT_JOB_DEPENDENCIES,
});

export const createExportJobAction = (payload: Partial<IExportJob>): CreateExportJobAction => ({
  type: ExportJobsActionTypes.CREATE_EXPORT_JOB,
  payload,
});
export const createExportJobSuccessAction = (): CreateExportJobSuccessAction => ({
  type: ExportJobsActionTypes.CREATE_EXPORT_JOB_SUCCESS,
});
export const createExportJobErrorAction = (payload: string): CreateExportJobErrorAction => ({
  type: ExportJobsActionTypes.CREATE_EXPORT_JOB_FAIL,
  payload,
});

export const updateExportJob = (exportJob: IExportJob): UpdateExportJobAction => ({
  type: ExportJobsActionTypes.UPDATE_EXPORT_JOB,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_JOBS}/${exportJob.id}`, 2),
      data: deserializeExportJob(exportJob) as unknown as Raw.IExportJob,
    },
  },
});

export const cloneExportJob = (exportJob: IExportJob): CloneExportJobAction => ({
  type: ExportJobsActionTypes.CLONE_EXPORT_JOB,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_JOBS}`, 2),
      data: deserializeExportJob(exportJob) as unknown as Raw.IExportJob,
    },
  },
});

export const deleteExportJob = (exportJob: Partial<IExportJob>): DeleteExportJobAction => ({
  type: ExportJobsActionTypes.DELETE_EXPORT_JOB,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_EXPORT_JOBS}/${exportJob.id}`, 2),
    },
  },
});

export const getExportJobById = (payload: { id: string; fetchChildren: boolean }): GetExportJobByIdAction => ({
  type: ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID,
  payload: {
    id: payload.id || '',
    fetchChildren: payload.fetchChildren || false,
  },
});

export const getExportJobByIdSuccess = (
  payload: IExportJob | IExportJobWithChildren,
): GetExportJobByIdSuccessAction => ({
  type: ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID_SUCCESS,
  payload,
});

export const getExportJobByIdError = (payload: string): GetExportJobByIdErrorAction => ({
  type: ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID_FAIL,
  payload,
});

export const runExportJob = (payload: Partial<IExportJob>): RunExportJobAction => ({
  type: ExportJobsActionTypes.RUN_EXPORT_JOB,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`process/data_exporter/${payload.id}`, 2),
      data: { export_job_id: payload.id as string },
    },
  },
});
