import CelusIcon from 'components/CelusIcon';

import { toast, UpdateOptions, ToastOptions, TypeOptions } from 'react-toastify';
import ToastIcon from './ToastIcon';
import { IProps } from './IProps';

import { Container, Message, CloseIcon } from './styles';
import { MessageType, ToastMessages } from './Modals';

const ToastComponent = ({ message, type, closeToast }: IProps): JSX.Element => {
  const RenderMessage = message;

  let responsiveFontSize = '14px';

  if (typeof message === 'string') {
    // NOTE: poorman's Responsive formula
    // Default fontSize: 15pt, 3 Magic number, 714 = 238(Message width on Toast) * 3(rows we ideally want)
    const responsiveRatio = 714 / message.length;
    const unitAdjustment = 3;
    responsiveFontSize = `${Math.min(15, responsiveRatio < 10 ? responsiveRatio + unitAdjustment : responsiveRatio)}pt`;
  }

  return (
    <Container data-testid={`toast-${type}`} simpleMessage={typeof message === 'string'}>
      {typeof message === 'string' && <Message style={{ fontSize: responsiveFontSize }}>{message}</Message>}
      {typeof message !== 'string' && <RenderMessage />}
      <CloseIcon onClick={closeToast}>
        <CelusIcon size="tiny">cancel</CelusIcon>
      </CloseIcon>
    </Container>
  );
};

const defaultOptions: ToastOptions = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const globalToast = (
  toastId: string | undefined,
  type: string,
  messages: Partial<ToastMessages>,
  options?: ToastOptions,
  updateOptions?: UpdateOptions,
): string | undefined => {
  const autoClose = options?.autoClose;
  // NOTE: Need to do this to handle redux `Action.type` and also `RequestState`
  const lowerType = type.toLowerCase();
  const messageType: MessageType =
    (lowerType === 'idle' && 'idle') ||
    (lowerType.includes('success') && 'success') ||
    (lowerType.includes('fail') && 'error') ||
    (lowerType.includes('error') && 'error') ||
    (lowerType.includes('warning') && 'warning') ||
    'loading';

  const message = messages[messageType] || '';

  const toastType = (['idle'].includes(messageType) ? 'default' : messageType) as TypeOptions;
  if (!toastId) {
    return toast(<ToastComponent message={message} type={messageType} />, {
      ...defaultOptions,
      ...(options || {
        autoClose: autoClose || 5000,
      }),
      type: toastType,
      icon: <ToastIcon type={messageType} />,
    }) as string;
  }

  toast.update(toastId, {
    ...defaultOptions,
    ...options,
    render: <ToastComponent message={message} type={messageType} />,
    type: toastType,
    ...(updateOptions || {}),
    icon: <ToastIcon type={messageType} />,
  });

  return undefined;
};

export default ToastComponent;
