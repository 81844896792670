import { PutEffect, CallEffect, put, takeLatest, SelectEffect, call, select, ForkEffect } from 'redux-saga/effects';

import i18n from 'i18n/config';
import { AxiosResponse } from 'axios';

import { getErrorMessage } from 'utils/getErrorMessage';
import { apiUri } from 'services/main_app';
import { axiosInstance } from 'services/dataService';
import { AppState } from 'state_management/AppState';
import { getLocalProjectById } from 'services/localProjectsService';
import {
  BoardShapeCanvasActionTypes,
  GetBoardShapeCanvasAction,
  GetBoardShapeCanvasErrorAction,
  GetBoardShapeCanvasSuccessAction,
} from 'state_management/actions/boardShapeCanvas/ActionTypes';
import {
  getBoardShapeCanvasErrorAction,
  getBoardShapeCanvasSuccessAction,
} from 'state_management/actions/boardShapeCanvas/boardShapeCanvasActions';
import { ICanvas } from 'state_management/reducers/boardCanvas/Modals';

export function* getByIdSaga(
  action: GetBoardShapeCanvasAction,
): Generator<
  | SelectEffect
  | CallEffect<AxiosResponse<{ canvas: ICanvas }>>
  | PutEffect<GetBoardShapeCanvasSuccessAction | GetBoardShapeCanvasErrorAction>
> {
  const t = i18n.t.bind(i18n);

  try {
    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    let projectId = action.payload;
    if (isAnonymousUser) {
      const _project = getLocalProjectById(projectId);

      const originalProjId = _project.originalProj;

      if (!originalProjId) {
        return;
      }
      projectId = originalProjId;
    }

    const res = (yield call(() => axiosInstance.get(apiUri(`canvas/board/${projectId}`, 2)))) as AxiosResponse<{
      canvas: ICanvas;
    }>;

    yield put(getBoardShapeCanvasSuccessAction(res.data.canvas));
  } catch (error) {
    yield put(
      getBoardShapeCanvasErrorAction(
        getErrorMessage(error) || t('supernova:canvas.toast.getBoardError', 'Fetching board canvas failed.'),
      ),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD, getByIdSaga);
}
