import { NO_UNIT_OPTION } from 'components/SpecificationsForm/constants';
import { UnitConverterActions, UnitConverterActionTypes } from 'state_management/actions/unitConverter/ActionTypes';
import { UnitConverterState } from './Modals';

export const initialUnitConverterState: UnitConverterState = {
  physicalQuantitiesMap: {},
  physicalQuantitiesMapRequest: 'idle',
  physicalQuantitiesMapRequestError: '',
};

export const unitConverterReducer = (
  state: UnitConverterState = initialUnitConverterState,
  action: UnitConverterActions,
): UnitConverterState => {
  switch (action.type) {
    case UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES:
      return {
        ...state,
        physicalQuantitiesMapRequest: 'loading',
      };
    case UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES_SUCCESS:
      return {
        ...state,
        physicalQuantitiesMapRequest: 'success',
        physicalQuantitiesMap: {
          ...action.payload.data,
          Other: [{ label: NO_UNIT_OPTION, value: NO_UNIT_OPTION, inconvertible: true }],
        },
      };
    case UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES_FAIL:
      return {
        ...state,
        physicalQuantitiesMapRequest: 'error',
        physicalQuantitiesMapRequestError: action.error.message,
      };
    default:
      return state;
  }
};
