import {
  SubSystemCanvasActionTypes,
  SubSystemCanvasActions,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import { upsertResource } from 'utils/resourceUtils';
import { updateMatches } from 'utils/subSystems/subSystemUtils';
import { SubSystemState } from './Modals';

export const initialSubSystemState: SubSystemState = {
  designBlockMatchData: {
    selectedId: '',
    alternatives: [],
  },
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  selectedSubSystem: { origin: 'subsystem' },
  selectedSystemDesignBlock: undefined,
  subSystems: [],
  subSystemsListRequest: 'idle',
  subSystemsListRequestError: '',
  subSystemCanvasRequest: 'idle',
  subSystemCanvasError: '',
  saveSelectedSubSystemRequest: 'idle',
  saveSelectedSubSystemError: '',
  saveSubSystemState: 'idle',
  saveSubSystemError: '',
};

export const subSystemCanvasReducer = (
  state: SubSystemState = initialSubSystemState,
  action: SubSystemCanvasActions,
): SubSystemState => {
  switch (action.type) {
    case SubSystemCanvasActionTypes.SET_SELECTED_BLOCK:
      return {
        ...state,
        designBlockMatchData: {
          ...state.designBlockMatchData,
          ...action.payload.matches,
        },
        selectedSystemDesignBlock: action.payload,
      };
    case SubSystemCanvasActionTypes.CLEAR_SELECTED_BLOCK:
      return {
        ...state,
        selectedSubSystem: { id: undefined, origin: 'subsystem' },
      };
    case SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        subSystemsListRequest: 'loading',
        subSystemsListRequestError: '',
      };
    case SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_SUCCESS:
      return {
        ...state,
        subSystems: action.payload.data,
        pagination: action.payload.pagination,
        subSystemsListRequest: 'success',
        subSystemsListRequestError: '',
      };
    case SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_FAIL:
      return {
        ...state,
        subSystemsListRequest: 'error',
        subSystemsListRequestError: action.payload,
      };
    case SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS:
      return {
        ...state,
        subSystemCanvasRequest: 'loading',
        subSystemCanvasError: '',
      };
    case SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_SUCCESS:
      return {
        ...state,
        subSystems: upsertResource(state.subSystems, action.payload),
        selectedSubSystem: action.payload,
        subSystemCanvasRequest: 'success',
        subSystemCanvasError: '',
      };
    case SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_FAIL:
      return {
        ...state,
        subSystemCanvasRequest: 'error',
        subSystemCanvasError: action.payload,
      };
    case SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE:
      return {
        ...state,
        designBlockMatchData: updateMatches(
          state.designBlockMatchData,
          action.payload.alternativeId,
          action.payload.subSystemBlockId,
        ),
        saveSelectedSubSystemRequest: 'loading',
        saveSelectedSubSystemError: '',
      };
    case SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE_SUCCESS:
      return {
        ...state,
        saveSelectedSubSystemRequest: 'success',
        saveSelectedSubSystemError: '',
      };
    case SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE_FAIL:
      return {
        ...state,
        saveSelectedSubSystemRequest: 'error',
        saveSelectedSubSystemError: action.payload,
      };
    case SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS:
      return {
        ...state,
        saveSubSystemState: 'loading',
        saveSubSystemError: '',
      };
    case SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_SUCCESS:
      return {
        ...state,
        selectedSubSystem: action.payload,
        saveSubSystemState: 'success',
        saveSubSystemError: '',
      };
    case SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_FAIL:
      return {
        ...state,
        saveSubSystemState: 'error',
        saveSubSystemError: action.payload,
      };
    case SubSystemCanvasActionTypes.RESET_SUB_SYSTEM_CANVAS:
      return initialSubSystemState;
    default:
      return state;
  }
};
