import { IDesignConsideration } from 'state_management/reducers/designConsiderations/Modals';
import { deserializeDesignConsideration } from 'utils/designConsiderationsSerializer';
import { IPagination } from 'models/Pagination';
import { apiUri } from 'services/main_app';
import {
  GetAllDesignConsiderationsAction,
  CreateDesignConsiderationAction,
  UpdateDesignConsiderationAction,
  DeleteDesignConsiderationAction,
  DesignConsiderationsActionTypes,
  GetDesignConsiderationByIdAction,
  GetDesignConsiderationByIdSuccessAction,
  GetDesignConsiderationByIdErrorAction,
  GetDesignConsiderationByIdEveryAction,
  GetDesignConsiderationByIdSuccessEveryAction,
  GetDesignConsiderationByIdErrorEveryAction,
  GetAllDesignConsiderationsErrorAction,
  GetAllDesignConsiderationsSuccessAction,
  CloneDesignConsiderationAction,
  GetDesignConsiderationDependenciesAction,
  CleanDesignConsiderationListAction,
} from './ActionTypes';

export const ENDPOINT_DESIGN_CONSIDERATIONS = 'design-considerations';

export const getAllDesignConsiderationsAction = (
  payload: Partial<IPagination> = {},
): GetAllDesignConsiderationsAction => ({
  payload,
  type: DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS,
});

export const getAllDesignConsiderationsSuccessAction = (
  pagination: IPagination,
  data: Array<IDesignConsideration>,
): GetAllDesignConsiderationsSuccessAction => ({
  payload: {
    data,

    pagination,
  },
  type: DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS_SUCCESS,
});

export const getAllDesignConsiderationsErrorAction = (payload: string): GetAllDesignConsiderationsErrorAction => ({
  payload,
  type: DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS_FAIL,
});

export const getDesignConsiderationDependenciesAction = (): GetDesignConsiderationDependenciesAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_DEPENDENCIES,
});

export const createDesignConsideration = (
  designConsideration: Partial<IDesignConsideration>,
): CreateDesignConsiderationAction => ({
  type: DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}`, 2),
      data: deserializeDesignConsideration(designConsideration) as unknown as Raw.IDesignConsideration,
    },
  },
});

export const updateDesignConsideration = (
  designConsideration: IDesignConsideration,
): UpdateDesignConsiderationAction => ({
  type: DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}/${designConsideration.id}`, 2),
      data: deserializeDesignConsideration(designConsideration) as unknown as Raw.IDesignConsideration,
    },
  },
});

export const cloneDesignConsideration = (
  designConsideration: Partial<IDesignConsideration>,
): CloneDesignConsiderationAction => ({
  type: DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}`, 2),
      data: deserializeDesignConsideration(designConsideration) as unknown as Raw.IDesignConsideration,
    },
  },
});

export const deleteDesignConsideration = (
  designConsideration: Partial<IDesignConsideration>,
): DeleteDesignConsiderationAction => ({
  type: DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}/${designConsideration.id}`, 2),
    },
  },
});

export const getDesignConsiderationById = (
  payload: Partial<IDesignConsideration>,
): GetDesignConsiderationByIdAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getDesignConsiderationByIdSuccess = (
  payload: IDesignConsideration,
): GetDesignConsiderationByIdSuccessAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS,
  payload,
});

export const getDesignConsiderationByIdError = (payload: string): GetDesignConsiderationByIdErrorAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_FAIL,
  payload,
});

export const getDesignConsiderationByIdEvery = (payload: string[]): GetDesignConsiderationByIdEveryAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_EVERY,
  payload: {
    ids: payload,
  },
});

export const getDesignConsiderationByIdSuccessEvery = (
  payload: IDesignConsideration[],
): GetDesignConsiderationByIdSuccessEveryAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS_EVERY,
  payload,
});

export const getDesignConsiderationByIdErrorEvery = (payload: string): GetDesignConsiderationByIdErrorEveryAction => ({
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_FAIL_EVERY,
  payload,
});

export const cleanDesignConsiderationListAction = (): CleanDesignConsiderationListAction => ({
  type: DesignConsiderationsActionTypes.CLEAN_DESIGN_CONSIDERATION_LIST,
});
