import {
  DesignBlockActionTypes,
  DesignBlockActions,
  UpdateDesignBlockErrorAction,
  CreateDesignBlockErrorAction,
  DeleteDesignBlockErrorAction,
  CloneDesignBlockErrorAction,
} from 'state_management/actions/designBlocks/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const designBlocksUpdateToasts = (action: DesignBlockActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Design Block is being updated, please wait...',
      success: 'Design Block has been successfully updated.',
      error:
        getErrorMessage((action as UpdateDesignBlockErrorAction).error) ||
        'Updating Design Block failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const designBlocksCloneToasts = (action: DesignBlockActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Design Block is being cloned, please wait...',
      success: 'Design Block has been successfully cloned.',
      error:
        getErrorMessage((action as CloneDesignBlockErrorAction).error) ||
        'Cloning Design Block failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const designBlocksCreateToasts = (action: DesignBlockActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Design Block is being created, please wait...',
      success: 'Design Block has been successfully created.',
      error:
        getErrorMessage((action as CreateDesignBlockErrorAction).error) ||
        'Creating Design Block failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const designBlocksDeleteToasts = (action: DesignBlockActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Design Block is being deleted, please wait...',
      success: 'Design Block has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteDesignBlockErrorAction).error) ||
        'Deleting Design Block failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsSaga(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        DesignBlockActionTypes.CREATE_DESIGN_BLOCK,
        DesignBlockActionTypes.CREATE_DESIGN_BLOCK_SUCCESS,
        DesignBlockActionTypes.CREATE_DESIGN_BLOCK_FAIL,
      ],
      designBlocksCreateToasts,
    ),
    takeEvery(
      [
        DesignBlockActionTypes.UPDATE_DESIGN_BLOCK,
        DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_SUCCESS,
        DesignBlockActionTypes.UPDATE_DESIGN_BLOCK_FAIL,
      ],
      designBlocksUpdateToasts,
    ),
    takeEvery(
      [
        DesignBlockActionTypes.CLONE_DESIGN_BLOCK,
        DesignBlockActionTypes.CLONE_DESIGN_BLOCK_SUCCESS,
        DesignBlockActionTypes.CLONE_DESIGN_BLOCK_FAIL,
      ],
      designBlocksCloneToasts,
    ),
    takeEvery(
      [
        DesignBlockActionTypes.DELETE_DESIGN_BLOCK,
        DesignBlockActionTypes.DELETE_DESIGN_BLOCK_SUCCESS,
        DesignBlockActionTypes.DELETE_DESIGN_BLOCK_FAIL,
      ],
      designBlocksDeleteToasts,
    ),
  ]);
}
