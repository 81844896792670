/* eslint-disable no-case-declarations */
import retus from 'retus';
import { NO_UNIT_OPTION, OTHER_MEASURE } from 'components/SpecificationsForm/constants';
import {
  IInstanceSpecification,
  ISpecification,
  ISpecificationFilter,
  ISpecValues,
} from 'state_management/reducers/specifications/Modals';
import { apiUri } from 'services/main_app';

export const specToSpecFilter = (specification: ISpecification): ISpecificationFilter =>
  ({
    id: specification.id,
    field: {
      id: specification.id,
      label: specification.name,
    },
    label: specification.name,
    operator: {
      chipDisplayLabel: ' = ',
    },
    values: { value: specification.value },
  } as ISpecificationFilter);

const setDefaultValue = <T>(condition?: boolean, defaultValue?: T): T | undefined =>
  condition ? undefined : defaultValue;

export const replaceSpecificationNoUnit = (unit: string | undefined, isChangesRequest?: boolean): string | undefined =>
  unit === NO_UNIT_OPTION ? '' : unit || setDefaultValue(isChangesRequest, '');

export const generateKeyFromName = (name: string): string => name.replace(/ /g, '_').toLowerCase();

export function deserializeSpecification(specification: ISpecification): Raw.ISpecification;
export function deserializeSpecification(specification: Partial<ISpecification>): Partial<Raw.ISpecification>;
export function deserializeSpecification(
  specification: Partial<ISpecification>,
  isChangesRequest?: boolean,
): Partial<Raw.ISpecification> {
  return {
    id: specification.id,
    key: specification.id,
    name: specification.name,
    short_description: specification.shortDescription,
    full_info: specification.fullInfo,
    data_type: specification.dataType,
    physical_quantity: specification.physicalQuantity,
    unit: replaceSpecificationNoUnit(specification.unit, isChangesRequest),
    spec_type: specification.specType,
    value: specification.value,
    specification_value_type: specification.specificationValueType,
    options: specification.options,
  };
}

export const serializeSpecification = (specification: Raw.ISpecification): ISpecification => ({
  id: specification.id,
  key: specification.key,
  name: specification.name,
  shortDescription: specification.short_description,
  fullInfo: specification.full_info,
  dataType: specification.data_type,
  physicalQuantity: specification.physical_quantity || OTHER_MEASURE,
  unit: specification.unit || NO_UNIT_OPTION,
  specificationValueType: specification.specification_value_type,
  value: specification.value,
  specType: specification.spec_type,
  createdAt: specification.created_at,
  modifiedAt: specification.modified_at,
  options: specification.options,
});

export const convertUnit = (value: number, unit?: string, mainUnit?: string, withUnitConversion = false): number => {
  if (!unit || !mainUnit || !withUnitConversion) {
    return value;
  }
  try {
    // Note: we are using a sync request here, it is a temporary dirty solution
    // This will be approached differently in new orbit
    const res = retus.post<{ value: number }>(apiUri('unit-converter/converter/convert-to-target-unit', 2), {
      json: {
        unit,
        value,
        target_unit: mainUnit,
      },
    });
    return res.body.value;
  } catch (error) {
    // We keep this for debugging reasons
    // eslint-disable-next-line no-console
    console.warn(error);
    return value;
  }
};

const getSpecificationValue = (
  instanceSpecification: IInstanceSpecification,
  specification?: ISpecification,
  withConversion?: boolean,
): string | number | Array<string> | Array<number> | ISpecValues => {
  const { value, unit } = instanceSpecification;
  const dataType = specification?.dataType || 'string';
  const valueType = specification?.specificationValueType || 'single';
  switch (valueType) {
    case 'single':
      if (specification && typeof value === 'number') {
        return withConversion ? convertUnit(value, unit, specification.unit) : value;
      }
      return value as string;
    case 'list':
      const values = (value as string).split(';') as Array<string>;
      if (dataType === 'number') {
        return withConversion && specification
          ? values.map((val) => convertUnit(parseFloat(val), unit, specification.unit))
          : values.map(parseFloat);
      }
      if (dataType === 'integer') {
        return withConversion && specification
          ? values.map((val) => convertUnit(parseInt(val, 10), unit, specification.unit))
          : values.map((val) => parseInt(val, 10));
      }
      return values;
    case 'min_max':
    case 'min_typ_max':
    case 'tolerance_percentage':
    case 'tolerance':
      const { min, max, typ } = value as ISpecValues;
      const newVal: ISpecValues = {};
      const _values = { min, max, typ };
      Object.keys(_values).forEach((key) => {
        if (_values[key as keyof typeof _values]) {
          newVal[key as keyof typeof _values] =
            withConversion && specification
              ? convertUnit(_values[key as keyof typeof _values] as number, unit, specification.unit)
              : _values[key as keyof typeof _values];
        }
      });
      return newVal;
    default:
      return value as string | number;
  }
};

export const deserializeInstanceSpecifications = (
  instanceSpecification: IInstanceSpecification,
  specification?: ISpecification,
): Raw.InstanceSpecification => ({
  id: instanceSpecification.id,
  value: getSpecificationValue(instanceSpecification, specification, true),
  unit: replaceSpecificationNoUnit(specification ? specification.unit : instanceSpecification.unit) as string,
  user_value: getSpecificationValue(instanceSpecification, specification),
  user_unit: replaceSpecificationNoUnit(instanceSpecification.unit),
});

const getStringValueIfList = (
  value: string | number | Array<string> | Array<number> | ISpecValues,
): string | number | ISpecValues => (Array.isArray(value) ? value.join(';') : value);

export const serializeInstanceSpecifications = (
  instanceSpecification: Raw.InstanceSpecification,
): IInstanceSpecification => ({
  id: instanceSpecification.id,
  value: getStringValueIfList(instanceSpecification.user_value || instanceSpecification.value),
  unit: instanceSpecification.user_unit || instanceSpecification.unit || NO_UNIT_OPTION,
  normalizedValue: getStringValueIfList(instanceSpecification.value),
  normalizedUnit: instanceSpecification.unit || NO_UNIT_OPTION,
});
