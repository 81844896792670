import { ICanvas, IModule, ICoords } from 'state_management/reducers/boardCanvas/Modals';

export enum BoardShapeCanvasActionTypes {
  BOARD_SHAPE_CANVAS_GET_BOARD = 'BOARD_SHAPE_CANVAS_GET_BOARD',
  BOARD_SHAPE_CANVAS_GET_BOARD_SUCCESS = 'BOARD_SHAPE_CANVAS_GET_BOARD_SUCCESS',
  BOARD_SHAPE_CANVAS_GET_BOARD_FAIL = 'BOARD_SHAPE_CANVAS_GET_BOARD_FAIL',

  BOARD_SHAPE_CANVAS_SAVE = 'BOARD_SHAPE_CANVAS_SAVE',
  BOARD_SHAPE_CANVAS_SAVE_SUCCESS = 'BOARD_SHAPE_CANVAS_SAVE_SUCCESS',
  BOARD_SHAPE_CANVAS_SAVE_FAIL = 'BOARD_SHAPE_CANVAS_SAVE_FAIL',

  BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE = 'BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE',

  BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE_LOCK = 'BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE_LOCK',

  BOARD_SHAPE_CANVAS_SET_BLOCKS = 'BOARD_SHAPE_CANVAS_SET_BLOCKS',

  BOARD_SHAPE_CANVAS_CLEAR = 'BOARD_SHAPE_CANVAS_CLEAR',
}
// ============================================BOARD_SHAPE_CANVAS_CLEAR
export interface ClearBoardShapeCanvasAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_CLEAR;
}

// ============================================ BOARD_SHAPE_CANVAS_GET_BOARD

export interface GetBoardShapeCanvasAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD;
  payload: string;
}

export interface GetBoardShapeCanvasSuccessAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD_SUCCESS;
  payload: ICanvas;
}

export interface GetBoardShapeCanvasErrorAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD_FAIL;
  payload: string;
}

// ============================================ BOARD_SHAPE_CANVAS_SAVE

export interface SaveBoardShapeCanvasAction {
  payload: {
    canvas: Partial<ICanvas>;
    compileProject?: boolean;
  };
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE;
}

export interface SaveBoardShapeCanvasSuccessAction {
  payload: ICanvas;
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE_SUCCESS;
}

export interface SaveBoardShapeCanvasErrorAction {
  payload: string;
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SAVE_FAIL;
}

// ============================================ BOARD_SHAPE_CANVAS_SET_BLOCKS

export interface SetBoardShapeCanvasBlocksAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BLOCKS;
  payload: Array<IModule>;
}

// ============================================ BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE_LOCK

export interface SetBoardShapeCanvasLockAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE_LOCK;
  payload: boolean;
}

// ============================================ BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE

export interface BoardShapeCanvasSetShapeAction {
  type: BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE;
  payload: Array<ICoords>;
}

// ============================================ ALL

export type BoardShapeCanvasActions =
  | GetBoardShapeCanvasAction
  | GetBoardShapeCanvasSuccessAction
  | GetBoardShapeCanvasErrorAction
  | SaveBoardShapeCanvasAction
  | SaveBoardShapeCanvasSuccessAction
  | SaveBoardShapeCanvasErrorAction
  | SetBoardShapeCanvasBlocksAction
  | SetBoardShapeCanvasLockAction
  | BoardShapeCanvasSetShapeAction
  | ClearBoardShapeCanvasAction;
