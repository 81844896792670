import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getSupplierByIdSuccess,
  getSupplierByIdError,
  ENDPOINT_SUPPLIERS,
} from 'state_management/actions/suppliers/suppliersActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetSupplierByIdErrorAction,
  GetSupplierByIdSuccessAction,
  GetSupplierByIdAction,
  SuppliersActionTypes,
} from 'state_management/actions/suppliers/ActionTypes';
import { ISupplier } from 'state_management/reducers/suppliers/Modals';
import { serializeSupplier } from 'utils/suppliersSerializer';

export function* getByIdSaga(
  action: GetSupplierByIdAction,
): Generator<
  | PutEffect<GetSupplierByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetSupplierByIdSuccessAction | GetSupplierByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_SUPPLIERS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedSupplier: ISupplier = serializeSupplier(res.data);

    yield put(getSupplierByIdSuccess(parsedSupplier));
  } catch (error) {
    yield put(getSupplierByIdError(getErrorMessage(error) || 'Fetching suppliers failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(SuppliersActionTypes.GET_SUPPLIER_BY_ID, getByIdSaga);
}
