import { IPagination } from 'models/Pagination';
import { IBillOfMaterial, IRappidGraphWithChildren } from 'state_management/reducers/billOfMaterial/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum BillOfMaterialActionTypes {
  GET_ALL_BILL_OF_MATERIALS = 'GET_ALL_BILL_OF_MATERIALS',
  GET_ALL_BILL_OF_MATERIALS_SUCCESS = 'GET_ALL_BILL_OF_MATERIALS_SUCCESS',
  GET_ALL_BILL_OF_MATERIALS_FAIL = 'GET_ALL_BILL_OF_MATERIALS_FAIL',

  CREATE_BILL_OF_MATERIAL = 'CREATE_BILL_OF_MATERIAL',
  CREATE_BILL_OF_MATERIAL_SUCCESS = 'CREATE_BILL_OF_MATERIAL_SUCCESS',
  CREATE_BILL_OF_MATERIAL_FAIL = 'CREATE_BILL_OF_MATERIAL_FAIL',

  UPDATE_BILL_OF_MATERIAL = 'UPDATE_BILL_OF_MATERIAL',
  UPDATE_BILL_OF_MATERIAL_SUCCESS = 'UPDATE_BILL_OF_MATERIAL_SUCCESS',
  UPDATE_BILL_OF_MATERIAL_FAIL = 'UPDATE_BILL_OF_MATERIAL_FAIL',

  DELETE_BILL_OF_MATERIAL = 'DELETE_BILL_OF_MATERIAL',
  DELETE_BILL_OF_MATERIAL_SUCCESS = 'DELETE_BILL_OF_MATERIAL_SUCCESS',
  DELETE_BILL_OF_MATERIAL_FAIL = 'DELETE_BILL_OF_MATERIAL_FAIL',

  RESET_BILL_OF_MATERIAL = 'RESET_BILL_OF_MATERIAL',

  UPDATE_HIERARCHICAL_BOM = 'UPDATE_HIERARCHICAL_BOM',

  INIT_HIERARCHICAL_BOM = 'INIT_HIERARCHICAL_BOM',
  INIT_HIERARCHICAL_BOM_SUCCESS = 'INIT_HIERARCHICAL_BOM_SUCCESS',
  INIT_HIERARCHICAL_BOM_FAIL = 'INIT_HIERARCHICAL_BOM_FAIL',

  TRANSITION_STATE_BOM_GET = 'TRANSITION_STATE_BOM_GET',
  TRANSITION_STATE_BOM_GET_SUCCESS = 'TRANSITION_STATE_BOM_GET_SUCCESS',
  TRANSITION_STATE_BOM_GET_FAIL = 'TRANSITION_STATE_BOM_GET_FAIL',
  TRANSITION_STATE_BOM_RESET = 'TRANSITION_STATE_BOM_RESET',
}

// ============================================ GET_ALL_BILL_OF_MATERIAL

export interface GetAllBillOfMaterialsAction {
  payload: {
    projectId: string;
    pagination: Partial<IPagination>;
  };
  type: BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS;
}

export interface GetAllBillOfMaterialsSuccessAction {
  payload: { pagination: IPagination; data: Array<IBillOfMaterial> };
  type: BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS_SUCCESS;
}

export interface GetAllBillOfMaterialsErrorAction {
  payload: string;
  type: BillOfMaterialActionTypes.GET_ALL_BILL_OF_MATERIALS_FAIL;
}

// ============================================ CREATE_BILL_OF_MATERIAL

export interface CreateBillOfMaterialAction extends AxiosRequestPayload<Partial<Raw.IBillOfMaterial>> {
  type: BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL;
}

export interface CreateBillOfMaterialSuccessAction
  extends AxiosSuccessPayload<
    Partial<Raw.IBillOfMaterial>,
    BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL_SUCCESS,
    CreateBillOfMaterialAction
  > {
  type: BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL_SUCCESS;
}

export interface CreateBillOfMaterialErrorAction
  extends AxiosErrorPayload<BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL_FAIL, CreateBillOfMaterialAction> {
  type: BillOfMaterialActionTypes.CREATE_BILL_OF_MATERIAL_FAIL;
}

// ============================================ UPDATE_BILL_OF_MATERIAL

export interface UpdateBillOfMaterialAction extends AxiosRequestPayload<Partial<Raw.IBillOfMaterial>> {
  type: BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL;
}
export interface UpdateBillOfMaterialSuccessAction
  extends AxiosSuccessPayload<
    Partial<Raw.IBillOfMaterial>,
    BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL_SUCCESS,
    UpdateBillOfMaterialAction
  > {
  type: BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL_SUCCESS;
}
export interface UpdateBillOfMaterialErrorAction
  extends AxiosErrorPayload<BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL_FAIL, UpdateBillOfMaterialAction> {
  type: BillOfMaterialActionTypes.UPDATE_BILL_OF_MATERIAL_FAIL;
}

// ============================================ DELETE_BILL_OF_MATERIAL

export interface DeleteBillOfMaterialAction extends AxiosRequestPayload {
  type: BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL;
}
export interface DeleteBillOfMaterialSuccessAction
  extends AxiosSuccessPayload<
    string,
    BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL_SUCCESS,
    DeleteBillOfMaterialAction
  > {
  type: BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL_SUCCESS;
}
export interface DeleteBillOfMaterialErrorAction
  extends AxiosErrorPayload<BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL_FAIL, DeleteBillOfMaterialAction> {
  type: BillOfMaterialActionTypes.DELETE_BILL_OF_MATERIAL_FAIL;
}

// ============================================ RESET_BILL_OF_MATERIAL

export interface ResetBillOfMaterialAction {
  type: BillOfMaterialActionTypes.RESET_BILL_OF_MATERIAL;
}

// ============================================ HIERARCHICAL BOM

export interface UpdateHierarchicalBomAction {
  payload: Array<IRappidGraphWithChildren>;
  type: BillOfMaterialActionTypes.UPDATE_HIERARCHICAL_BOM;
}

export interface InitHierarchicalBomAction {
  type: BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM;
}

export interface InitHierarchicalBomSuccessAction {
  type: BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_SUCCESS;
}

export interface InitHierarchicalBomErrorAction {
  payload: string;
  type: BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_FAIL;
}

// =========================================== TRANSITION_STATE_GET
export interface GetBomTransitionState {
  payload: { projectId: string };
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET;
}

export interface GetBomTransitionStateSuccess {
  payload: 'idle' | 'outdated' | 'updated';
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET_SUCCESS;
}

export interface GetBomTransitionStateFail {
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_GET_FAIL;
}

// =========================================== TRANSITION_STATE_RESET
export interface ResetBomTransitionState {
  type: BillOfMaterialActionTypes.TRANSITION_STATE_BOM_RESET;
}

// ============================================ ALL

export type BillOfMaterialActions =
  | GetAllBillOfMaterialsAction
  | GetAllBillOfMaterialsSuccessAction
  | GetAllBillOfMaterialsErrorAction
  | CreateBillOfMaterialAction
  | CreateBillOfMaterialSuccessAction
  | CreateBillOfMaterialErrorAction
  | UpdateBillOfMaterialAction
  | UpdateBillOfMaterialSuccessAction
  | UpdateBillOfMaterialErrorAction
  | DeleteBillOfMaterialAction
  | DeleteBillOfMaterialSuccessAction
  | DeleteBillOfMaterialErrorAction
  | ResetBillOfMaterialAction
  | UpdateHierarchicalBomAction
  | InitHierarchicalBomAction
  | InitHierarchicalBomSuccessAction
  | InitHierarchicalBomErrorAction
  | GetBomTransitionState
  | GetBomTransitionStateSuccess
  | GetBomTransitionStateFail
  | ResetBomTransitionState;
