/* eslint-disable no-await-in-loop */
import { AxiosResponse } from 'axios';
import {
  call,
  CallEffect,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  TakeEffect,
  takeEvery,
} from 'redux-saga/effects';

import {
  BillOfMaterialActionTypes,
  InitHierarchicalBomAction,
  InitHierarchicalBomErrorAction,
  InitHierarchicalBomSuccessAction,
  UpdateHierarchicalBomAction,
} from 'state_management/actions/billOfMaterial/ActionTypes';

import i18n from 'i18n/config';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  initHierarchicalBomErrorAction,
  initHierarchicalBomSuccessAction,
  updateHierarchicalBomAction,
} from 'state_management/actions/billOfMaterial/billOfMaterialActions';
import { AppState } from 'state_management/AppState';
import { IBom, IBomInfo, ICuboBomList, ISubsystemList } from 'state_management/reducers/billOfMaterial/Modals';
import {
  addCuboBomToHierarchicalBom,
  addCuboSlotsNameToHierarchicalBom,
  formatHierarchicalBom,
  getCuboIdsFromHierarchicalBom,
  getSubsystemIdsFromHierarchicalBom,
} from 'utils/hierarchicalBom/hierarchicalBomUtils';
import { serializeBillOfMaterials } from 'utils/billOfMaterialsSerializer';
import { serializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';

const queryRequestBuilder =
  (path: string, query: string, fields: string[] = []) =>
  (page = 0): Promise<AxiosResponse> =>
    axiosInstance.get(
      apiUri(`dataservice/${path}?pg=${page}${fields.length ? `&fields=${fields.join(',')}` : ''}&query=${query}`, 2),
    );

const paginateToEnd = async <T>(
  paginatedApiCall: (page?: number) => Promise<AxiosResponse<Array<T>>>,
): Promise<Array<T>> => {
  let allData: Array<T> = [];
  const { headers, data } = (await paginatedApiCall()) as AxiosResponse<Array<T>>;
  if (!data) {
    return allData;
  }
  // headers['x-pagination-last'] is expected from the backend
  const pagination_last = Number(headers['x-pagination-last']);
  const apiCalls = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i <= pagination_last; i++) {
    apiCalls.push(paginatedApiCall(i));
  }
  const apiCallsResponse = await Promise.all(apiCalls);
  apiCallsResponse.forEach((response) => {
    allData = [...allData, ...response.data];
  });
  return allData;
};

export function* initHierarchicalBomSaga(): Generator<
  | PutEffect
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | CallEffect<any>
  | PutEffect<
      | InitHierarchicalBomSuccessAction
      | InitHierarchicalBomErrorAction
      | InitHierarchicalBomAction
      | UpdateHierarchicalBomAction
    >
> {
  const t = i18n.t.bind(i18n);

  try {
    const {
      systemDesignCanvasState: { graph },
    } = (yield select((state: AppState) => state)) as AppState;
    let hierarchicalBom = formatHierarchicalBom(graph);
    const cuboIdList = getCuboIdsFromHierarchicalBom(hierarchicalBom);
    const subsystemIdList = getSubsystemIdsFromHierarchicalBom(hierarchicalBom);

    let cuboIdToBomMap: ICuboBomList = {};
    if (cuboIdList.length) {
      // Get all cubo info
      const cubosIdQuery = cuboIdList.reduce((prev, curr) => `${prev ? `${prev}%OR%` : ''}id="${curr}"`, '');
      const cuboBomInfoResponse = (yield call(() =>
        paginateToEnd(queryRequestBuilder('pure-implementations', cubosIdQuery, ['bom', 'id'])),
      )) as Array<IBomInfo>;

      if (cuboBomInfoResponse?.length) {
        const independentImplementationIdList: string[] = cuboBomInfoResponse?.map(
          (el) => el?.bom?.independent_implementation,
        );
        const independentImplementationsIdQuery = independentImplementationIdList.reduce(
          (prev, curr) => `${prev ? `${prev}%OR%` : ''}id="${curr}"`,
          '',
        );
        // Get all bom
        const implementationBomListRespnse = (yield call(() =>
          paginateToEnd(queryRequestBuilder('independent-bom', independentImplementationsIdQuery, ['Data', 'id'])),
        )) as Array<IBom>;

        const independentBomIdToCuboIdMap: Record<string, string> = cuboBomInfoResponse?.reduce(
          (prev, curr) => ({ ...prev, [curr.bom.independent_implementation]: curr.id }),
          {},
        );

        // Map cubo ids to their bom
        cuboIdToBomMap = implementationBomListRespnse?.reduce(
          (prev, curr) => ({
            ...prev,
            [independentBomIdToCuboIdMap[curr.id]]: curr?.Data.map(serializeBillOfMaterials),
          }),
          {},
        );
      }
    }

    let subsystemIdToInfoMap: ISubsystemList = {};
    if (subsystemIdList.length) {
      const subsystemsIdQuery = subsystemIdList.reduce((prev, curr) => `${prev ? `${prev}%OR%` : ''}id="${curr}"`, '');
      // Get all suzbsystem info
      const subsystemListRespnse = (yield call(() =>
        paginateToEnd(queryRequestBuilder('subsystems', subsystemsIdQuery, ['canvas', 'id'])),
      )) as Array<RawSubSystemCanvas.Payload>;
      subsystemIdToInfoMap = subsystemListRespnse?.reduce(
        (prev, curr) => ({ ...prev, [curr.id]: serializeSubSystemCanvas(curr) }),
        {},
      );
    }

    // Include all bom and cubo slot names
    if (Object.keys(cuboIdToBomMap).length) {
      hierarchicalBom = addCuboBomToHierarchicalBom(hierarchicalBom, cuboIdToBomMap);
    }
    if (Object.keys(subsystemIdToInfoMap).length) {
      hierarchicalBom = addCuboSlotsNameToHierarchicalBom(hierarchicalBom, subsystemIdToInfoMap);
    }
    yield put(updateHierarchicalBomAction(hierarchicalBom));
    yield put(initHierarchicalBomSuccessAction());
  } catch (error) {
    yield put(
      initHierarchicalBomErrorAction(
        t(
          'supernova:canvas.toasts.initHierarchicalBomError',
          'Fetching BOM failed. Please resolve Design Canvas to get the BOM',
        ),
      ),
    );
  }
}

export function* initHierarchicalBomWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM, initHierarchicalBomSaga);
}
