import { BomModificationRequest, BomModificationResponse } from 'state_management/reducers/bomModification/Modals';

export enum BomModificationActionTypes {
  GET_PROJECT_BOM_MODIFICATION_RULES = 'GET_PROJECT_BOM_MODIFICATION_RULES',
  GET_PROJECT_BOM_MODIFICATION_SUCCESS = 'GET_PROJECT_BOM_MODIFICATION_RULES_SUCCESS',
  GET_PROJECT_BOM_MODIFICATION_RULES_FAIL = 'GET_PROJECT_BOM_MODIFICATION_RULES_FAIL',

  RESET_PROJECT_BOM_MODIFICATION_RULES = 'RESET_PROJECT_BOM_MODIFICATION_RULES',
  RESET_PROJECT_BOM_MODIFICATION_RULES_FAIL = 'RESET_PROJECT_BOM_MODIFICATION_RULES_FAIL',
  RESET_PROJECT_BOM_MODIFICATION_RULES_SUCCESS = 'RESET_PROJECT_BOM_MODIFICATION_RULES_SUCCESS',

  CREATE_BOM_MODIFICATION_RULE = 'CREATE_BOM_MODIFICATION_RULE',
  CREATE_BOM_MODIFICATION_RULE_SUCCESS = 'CREATE_BOM_MODIFICATION_RULE_SUCCESS',
  CREATE_BOM_MODIFICATION_RULE_FAIL = 'CREATE_BOM_MODIFICATION_RULE_FAIL',

  UPDATE_BOM_MODIFICATION_RULE = 'UPDATE_BOM_MODIFICATION_RULE',
  UPDATE_BOM_MODIFICATION_RULE_SUCCESS = 'UPDATE_BOM_MODIFICATION_RULE_SUCCESS',
  UPDATE_BOM_MODIFICATION_RULE_FAIL = 'UPDATE_BOM_MODIFICATION_RULE_FAIL',

  RESET_BOM_MODIFICATION_DIALOGUE_STATE = 'RESET_BOM_MODIFICATION_DIALOGUE_STATE ',
}

export interface GetBomModificationRulesAction {
  type: BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES;
  payload: string;
}

export interface GetBomModificationRulesSuccessAction {
  type: BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_SUCCESS;
  payload: BomModificationResponse;
}

export interface GetBomModificationRulesErrorAction {
  type: BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES_FAIL;
  payload: string;
}

export interface CreateBomModificationRulesAction {
  type: BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE;
  payload: BomModificationRequest;
}

export interface CreateBomModificationRulesSuccessAction {
  type: BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE_SUCCESS;
  payload: BomModificationResponse;
}

export interface CreateBomModificationRulesFailAction {
  type: BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE_FAIL;
  payload: string;
}

export interface UpdateBomModificationRulesAction {
  type: BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE;
  payload: BomModificationRequest & { id: string };
}

export interface UpdateBomModificationRulesSuccessAction {
  type: BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE_SUCCESS;
  payload: BomModificationResponse;
}

export interface UpdateBomModificationRulesErrorAction {
  type: BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE_FAIL;
  payload: string;
}

export interface ResetBomModificationRulesAction {
  type: BomModificationActionTypes.RESET_PROJECT_BOM_MODIFICATION_RULES;
}

export interface ResetBomModificationDialogueStateAction {
  type: BomModificationActionTypes.RESET_BOM_MODIFICATION_DIALOGUE_STATE;
}

export type BomModificationActions =
  | GetBomModificationRulesAction
  | GetBomModificationRulesSuccessAction
  | GetBomModificationRulesErrorAction
  | ResetBomModificationRulesAction
  | CreateBomModificationRulesAction
  | CreateBomModificationRulesSuccessAction
  | CreateBomModificationRulesFailAction
  | UpdateBomModificationRulesAction
  | UpdateBomModificationRulesSuccessAction
  | UpdateBomModificationRulesErrorAction
  | ResetBomModificationRulesAction
  | ResetBomModificationDialogueStateAction;
