import { IPagination } from 'models/Pagination';
import { IDesignConsideration } from 'state_management/reducers/designConsiderations/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum DesignConsiderationsActionTypes {
  GET_ALL_DESIGN_CONSIDERATIONS = 'GET_ALL_DESIGN_CONSIDERATIONS',
  GET_ALL_DESIGN_CONSIDERATIONS_SUCCESS = 'GET_ALL_DESIGN_CONSIDERATIONS_SUCCESS',
  GET_ALL_DESIGN_CONSIDERATIONS_FAIL = 'GET_ALL_DESIGN_CONSIDERATIONS_FAIL',

  GET_DESIGN_CONSIDERATION_DEPENDENCIES = 'GET_DESIGN_CONSIDERATION_DEPENDENCIES',

  GET_DESIGN_CONSIDERATION_BY_ID = 'GET_DESIGN_CONSIDERATION_BY_ID',
  GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS = 'GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS',
  GET_DESIGN_CONSIDERATION_BY_ID_FAIL = 'GET_DESIGN_CONSIDERATION_BY_ID_FAIL',

  GET_DESIGN_CONSIDERATION_BY_ID_EVERY = 'GET_DESIGN_CONSIDERATION_BY_ID_EVERY',
  GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS_EVERY = 'GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS_EVERY',
  GET_DESIGN_CONSIDERATION_BY_ID_FAIL_EVERY = 'GET_DESIGN_CONSIDERATION_BY_ID_FAIL_EVERY',

  CREATE_DESIGN_CONSIDERATION = 'CREATE_DESIGN_CONSIDERATION',
  CREATE_DESIGN_CONSIDERATION_SUCCESS = 'CREATE_DESIGN_CONSIDERATION_SUCCESS',
  CREATE_DESIGN_CONSIDERATION_FAIL = 'CREATE_DESIGN_CONSIDERATION_FAIL',

  UPDATE_DESIGN_CONSIDERATION = 'UPDATE_DESIGN_CONSIDERATION',
  UPDATE_DESIGN_CONSIDERATION_SUCCESS = 'UPDATE_DESIGN_CONSIDERATION_SUCCESS',
  UPDATE_DESIGN_CONSIDERATION_FAIL = 'UPDATE_DESIGN_CONSIDERATION_FAIL',

  CLONE_DESIGN_CONSIDERATION = 'CLONE_DESIGN_CONSIDERATION',
  CLONE_DESIGN_CONSIDERATION_SUCCESS = 'CLONE_DESIGN_CONSIDERATION_SUCCESS',
  CLONE_DESIGN_CONSIDERATION_FAIL = 'CLONE_DESIGN_CONSIDERATION_FAIL',

  DELETE_DESIGN_CONSIDERATION = 'DELETE_DESIGN_CONSIDERATION',
  DELETE_DESIGN_CONSIDERATION_SUCCESS = 'DELETE_DESIGN_CONSIDERATION_SUCCESS',
  DELETE_DESIGN_CONSIDERATION_FAIL = 'DELETE_DESIGN_CONSIDERATION_FAIL',

  CLEAN_DESIGN_CONSIDERATION_LIST = 'CLEAN_DESIGN_CONSIDERATION_LIST',
}

// ============================================ GET_ALL_DESIGN_CONSIDERATIONS

export interface GetAllDesignConsiderationsAction {
  payload: Partial<IPagination>;
  type: DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS;
}

export interface GetAllDesignConsiderationsSuccessAction {
  payload: { pagination: IPagination; data: Array<IDesignConsideration> };
  type: DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS_SUCCESS;
}

export interface GetAllDesignConsiderationsErrorAction {
  payload: string;
  type: DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS_FAIL;
}

// ============================================ GET_DESIGN_CONSIDERATION_DEPENDENCIES

export interface GetDesignConsiderationDependenciesAction {
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_DEPENDENCIES;
}

// ============================================ GET_DESIGN_CONSIDERATION_BY_ID

export interface GetDesignConsiderationByIdAction {
  payload: { id: string };
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID;
}

export interface GetDesignConsiderationByIdSuccessAction {
  payload: IDesignConsideration;
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS;
}

export interface GetDesignConsiderationByIdErrorAction {
  payload: string;
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_FAIL;
}

// ============================================ GET_DESIGN_CONSIDERATION_BY_ID_EVERY
export interface GetDesignConsiderationByIdEveryAction {
  payload: { ids: string[] };
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_EVERY;
}

export interface GetDesignConsiderationByIdSuccessEveryAction {
  payload: IDesignConsideration[];
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_SUCCESS_EVERY;
}

export interface GetDesignConsiderationByIdErrorEveryAction {
  payload: string;
  type: DesignConsiderationsActionTypes.GET_DESIGN_CONSIDERATION_BY_ID_FAIL_EVERY;
}

// ============================================ CREATE_DESIGN_CONSIDERATION

export interface CreateDesignConsiderationAction extends AxiosRequestPayload<Raw.IDesignConsideration> {
  type: DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION;
}

export interface CreateDesignConsiderationSuccessAction
  extends AxiosSuccessPayload<
    IDesignConsideration,
    DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_SUCCESS,
    CreateDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_SUCCESS;
}

export interface CreateDesignConsiderationErrorAction
  extends AxiosErrorPayload<
    DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_FAIL,
    CreateDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.CREATE_DESIGN_CONSIDERATION_FAIL;
}

// ============================================ UPDATE_DESIGN_CONSIDERATION

export interface UpdateDesignConsiderationAction extends AxiosRequestPayload<Raw.IDesignConsideration> {
  type: DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION;
}
export interface UpdateDesignConsiderationSuccessAction
  extends AxiosSuccessPayload<
    IDesignConsideration,
    DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_SUCCESS,
    UpdateDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_SUCCESS;
}
export interface UpdateDesignConsiderationErrorAction
  extends AxiosErrorPayload<
    DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_FAIL,
    UpdateDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.UPDATE_DESIGN_CONSIDERATION_FAIL;
}

// ============================================ CLONE_DESIGN_CONSIDERATION

export interface CloneDesignConsiderationAction extends AxiosRequestPayload<Raw.IDesignConsideration> {
  type: DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION;
}

export interface CloneDesignConsiderationSuccessAction
  extends AxiosSuccessPayload<
    IDesignConsideration,
    DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_SUCCESS,
    CloneDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_SUCCESS;
}

export interface CloneDesignConsiderationErrorAction
  extends AxiosErrorPayload<
    DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_FAIL,
    CloneDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.CLONE_DESIGN_CONSIDERATION_FAIL;
}

// ============================================ DELETE_DESIGN_CONSIDERATION

export interface DeleteDesignConsiderationAction extends AxiosRequestPayload {
  type: DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION;
}
export interface DeleteDesignConsiderationSuccessAction
  extends AxiosSuccessPayload<
    string,
    DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_SUCCESS,
    DeleteDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_SUCCESS;
}
export interface DeleteDesignConsiderationErrorAction
  extends AxiosErrorPayload<
    DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_FAIL,
    DeleteDesignConsiderationAction
  > {
  type: DesignConsiderationsActionTypes.DELETE_DESIGN_CONSIDERATION_FAIL;
}

// ============================================CLEAN DESIGN CONSIDERATION
export interface CleanDesignConsiderationListAction {
  type: DesignConsiderationsActionTypes.CLEAN_DESIGN_CONSIDERATION_LIST;
}

// ============================================ ALL

export type DesignConsiderationActions =
  | GetAllDesignConsiderationsAction
  | GetAllDesignConsiderationsSuccessAction
  | GetAllDesignConsiderationsErrorAction
  | GetDesignConsiderationDependenciesAction
  | CreateDesignConsiderationAction
  | CreateDesignConsiderationSuccessAction
  | CreateDesignConsiderationErrorAction
  | UpdateDesignConsiderationAction
  | UpdateDesignConsiderationSuccessAction
  | UpdateDesignConsiderationErrorAction
  | CloneDesignConsiderationAction
  | CloneDesignConsiderationSuccessAction
  | CloneDesignConsiderationErrorAction
  | DeleteDesignConsiderationAction
  | DeleteDesignConsiderationSuccessAction
  | DeleteDesignConsiderationErrorAction
  | GetDesignConsiderationByIdAction
  | GetDesignConsiderationByIdSuccessAction
  | GetDesignConsiderationByIdErrorAction
  | GetDesignConsiderationByIdEveryAction
  | GetDesignConsiderationByIdSuccessEveryAction
  | GetDesignConsiderationByIdErrorEveryAction
  | CleanDesignConsiderationListAction;
