import {
  CategoriesActionTypes,
  CategoryActions,
  UpdateCategoryErrorAction,
  CreateCategoryErrorAction,
  DeleteCategoryErrorAction,
} from 'state_management/actions/categories/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const categoriesUpdateToasts = (action: CategoryActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Category is being updated, please wait...',
      success: 'Category has been successfully updated.',
      error:
        getErrorMessage((action as UpdateCategoryErrorAction).error) || 'Updating Category failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const categoriesCloneToasts = (action: CategoryActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Category is being cloned, please wait...',
      success: 'Category has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateCategoryErrorAction).error) || 'Cloning Category failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const categoriesCreateToasts = (action: CategoryActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Category is being created, please wait...',
      success: 'Category has been successfully created.',
      error:
        getErrorMessage((action as CreateCategoryErrorAction).error) || 'Creating Category failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const categoriesDeleteToasts = (action: CategoryActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Category is being deleted, please wait...',
      success: 'Category has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteCategoryErrorAction).error) || 'Deleting Category failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        CategoriesActionTypes.CREATE_CATEGORY,
        CategoriesActionTypes.CREATE_CATEGORY_SUCCESS,
        CategoriesActionTypes.CREATE_CATEGORY_FAIL,
      ],
      categoriesCreateToasts,
    ),
    takeEvery(
      [
        CategoriesActionTypes.UPDATE_CATEGORY,
        CategoriesActionTypes.UPDATE_CATEGORY_SUCCESS,
        CategoriesActionTypes.UPDATE_CATEGORY_FAIL,
      ],
      categoriesUpdateToasts,
    ),
    takeEvery(
      [
        CategoriesActionTypes.CLONE_CATEGORY,
        CategoriesActionTypes.CLONE_CATEGORY_SUCCESS,
        CategoriesActionTypes.CLONE_CATEGORY_FAIL,
      ],
      categoriesCloneToasts,
    ),
    takeEvery(
      [
        CategoriesActionTypes.DELETE_CATEGORY,
        CategoriesActionTypes.DELETE_CATEGORY_SUCCESS,
        CategoriesActionTypes.DELETE_CATEGORY_FAIL,
      ],
      categoriesDeleteToasts,
    ),
  ]);
}
