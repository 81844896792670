import styled from 'styled-components';
import { Button as AntButton } from 'antd';
import { WarningFilled, CloseCircleFilled } from '@ant-design/icons';

export const WarningIcon = styled(WarningFilled)``;

export const ErrorIcon = styled(CloseCircleFilled)``;

export const Button = styled(AntButton)<{
  hasIssues: boolean;
  issueType: string;
}>`
  color: ${({ hasIssues, issueType, theme }): string => {
    if (hasIssues) {
      return issueType === 'errors' ? theme.palette.error : theme.palette.warning;
    }
    return theme.palette.disabled;
  }};
  min-width: 55px;
  width: 55px;
  height: 42px;
  padding: 4px 12px;
  border: none;

  &&:hover {
    background-color: ${({ theme }): string => theme.palette.lightgrey};
    border-color: transparent;
    color: ${({ theme }): string => theme.palette.accent};
  }

  &&::after {
    display: none;
  }

  && > span {
    margin-right: 5px;
  }
`;

export const Wrapper = styled.div`
  && ${Button} {
    :first-of-type {
      border-radius: 4px 0 0 4px;
    }
    :last-of-type {
      border-radius: 0 4px 4px 0;
    }
  }
`;

export const TooltipInfoContainer = styled.div`
  && {
    margin: 0;
    display: flex;
    align-item: center;
    justify-content: center;
    padding: 6px 12px;
    min-width: 50px;
  }
`;
