import { useNavigate } from 'react-router';
import { MAIN_ROUTES } from 'routes/getRoutes';
import { IProps } from './IProps';

import { IFrameContainer } from './styles';

const IFrame = ({ src, style, onNotFound }: IProps): JSX.Element => {
  const navigate = useNavigate();

  if (!src) {
    if (onNotFound) {
      onNotFound();
    } else {
      navigate(MAIN_ROUTES.notMatch.alterPath);
    }
  }

  return <IFrameContainer loading="lazy" style={style} src={src} title="CELUS Engineering Platform" />;
};

export default IFrame;
