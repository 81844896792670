import { IBillOfMaterial } from 'state_management/reducers/billOfMaterial/Modals';

export function deserializeBillOfMaterials(billOfMaterial: Partial<IBillOfMaterial>): Partial<Raw.IBillOfMaterial> {
  return {
    uid: billOfMaterial.id,
    Part: billOfMaterial.partNumber,
    MF: billOfMaterial.manufacturer,
    Description: billOfMaterial.description,
    'Mouser No:': billOfMaterial.source,
    'Mfr. No': billOfMaterial.providerId,
    Value: billOfMaterial.value,
    Package: billOfMaterial.package,
    Device: billOfMaterial.device,
    'Socket Number': billOfMaterial.socketNumber,
  };
}

export function serializeBillOfMaterials(payload: Raw.IBillOfMaterial): IBillOfMaterial {
  return {
    id: payload.uid,
    partNumber: payload.Part,
    manufacturer: payload.MF,
    description: payload.Description,
    source: payload['Mouser No:'],
    providerId: payload['Mfr. No'],
    socketNumber: payload['Socket Number'],
    value: payload.Value,
    package: payload.Package,
    device: payload.Device,
  };
}
