import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  GetAllSubSystemsAction,
  SubSystemCanvasActionTypes,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import { getAllSubSystemsAction } from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';

export function* refreshSubSystemCanvas(): Generator<PutEffect<GetAllSubSystemsAction>> {
  yield put(getAllSubSystemsAction());
}

export function* refreshSaga(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_SUCCESS,
      SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_SUCCESS,
    ],
    refreshSubSystemCanvas,
  );
}
