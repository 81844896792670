import {
  RequestsActionTypes,
  RequestActions,
  CreateChangeRequestErrorAction,
  CallChangesRequestApprovalErrorAction,
  CallChangesRequestApprovalAction,
  CallChangesRequestApprovalSuccessAction,
  DeleteChangesRequestErrorAction,
  RetryChangesRequestErrorAction,
} from 'state_management/actions/changesRequest/ActionTypes';
import { takeEvery, ForkEffect, all, AllEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';

const changesRequestCreateToasts = (action: RequestActions): void => {
  globalToast(undefined, action.type, {
    loading: 'Your changes are being requested, please wait...',
    success: 'Your changes has been successfully requested. You can manage your requests on Manage Requests panel.',
    error: (action as CreateChangeRequestErrorAction).payload || 'Your request failed, please try again...',
  });
};

const changesRequestDeleteToasts = (action: RequestActions): void => {
  globalToast(undefined, action.type, {
    loading: 'Request is being deleted, please wait...',
    success: 'Request has been successfully deleted.',
    error: (action as DeleteChangesRequestErrorAction).payload || 'Deleting request failed, please try again...',
  });
};
const changesRequestRetryToasts = (action: RequestActions): void => {
  globalToast(undefined, action.type, {
    loading: 'Your request to retry is waiting for approval. Please wait.',
    success: 'Your request to retry is executed successfully.',
    error: (action as RetryChangesRequestErrorAction).payload || 'Your request to retry has failed. Please try again.',
  });
};

const changesRequestApproveToasts = (action: RequestActions): void => {
  let requestUrl;
  switch (action.type) {
    case RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL:
      requestUrl = (action as CallChangesRequestApprovalAction).payload.request.url;
      break;
    case RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_SUCCESS:
      requestUrl = (action as CallChangesRequestApprovalSuccessAction).meta.previousAction.payload.request.url;
      break;
    case RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_FAIL:
      requestUrl = (action as CallChangesRequestApprovalErrorAction).meta.previousAction.payload.request.url;
      break;
    default:
      requestUrl = '';
  }
  const approvalType = requestUrl.endsWith('approve') ? 'approved' : 'rejected';
  const approvalFailType = requestUrl.endsWith('approve') ? 'Approval' : 'Rejection';
  globalToast(undefined, action.type, {
    loading: `The request is being ${approvalType}, please wait...`,
    success: `The request has been ${approvalType}. This could take some time, try refreshing the page to see the updates.`,
    error:
      (action as CallChangesRequestApprovalErrorAction).payload || `${approvalFailType} failed, please try again...`,
  });
};

export function* requestsToastsSaga(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        RequestsActionTypes.CREATE_CHANGES_REQUEST,
        RequestsActionTypes.CREATE_CHANGES_REQUEST_SUCCESS,
        RequestsActionTypes.CREATE_CHANGES_REQUEST_FAIL,
        RequestsActionTypes.CREATE_CHANGES_REQUEST_FOR_SHARING,
      ],
      changesRequestCreateToasts,
    ),
    takeEvery(
      [
        RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL,
        RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_SUCCESS,
        RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_FAIL,
      ],
      changesRequestApproveToasts,
    ),
    takeEvery(
      [
        RequestsActionTypes.DELETE_CHANGES_REQUEST,
        RequestsActionTypes.DELETE_CHANGES_REQUEST_SUCCESS,
        RequestsActionTypes.DELETE_CHANGES_REQUEST_FAIL,
      ],
      changesRequestDeleteToasts,
    ),
    takeEvery(
      [
        RequestsActionTypes.RETRY_CHANGES_REQUEST,
        RequestsActionTypes.RETRY_CHANGES_REQUEST_SUCCESS,
        RequestsActionTypes.RETRY_CHANGES_REQUEST_FAIL,
      ],
      changesRequestRetryToasts,
    ),
  ]);
}
