import {
  SharingActionTypes,
  SharingActions,
  UpdateResourceSharingErrorAction,
} from 'state_management/actions/sharing/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const sharingUpdateToasts = (action: SharingActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Saving, please wait...',
      success: 'Successfully Updated Sharing',
      error:
        getErrorMessage((action as UpdateResourceSharingErrorAction).error) ||
        'Updating Sharing failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

export function* requestsToastsSaga(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        SharingActionTypes.UPDATE_RESOURCE_SHARING,
        SharingActionTypes.UPDATE_RESOURCE_SHARING_SUCCESS,
        SharingActionTypes.UPDATE_RESOURCE_SHARING_FAIL,
      ],
      sharingUpdateToasts,
    ),
  ]);
}
