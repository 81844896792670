import { useCallback, useEffect, useRef, useState } from 'react';
import { Drawer, Dragger, PanelSplitWrapper } from './styles';
import { IProps } from './IProps';

const DraggableDrawer = ({ isOpen, children }: IProps): JSX.Element => {
  const defaultPanelHeight = 0;
  const minPanelHeight = 146;
  const panelRef = useRef<HTMLDivElement>(null);
  const [panelHeight, setPanelHeight] = useState(defaultPanelHeight);

  useEffect(() => {
    if (isOpen) {
      setPanelHeight(minPanelHeight);
    } else {
      setPanelHeight(defaultPanelHeight);
    }
  }, [isOpen]);

  const handleMouseMove = useCallback((e) => {
    document.body.style.cursor = 'n-resize';
    const heightOffSet = document.body.clientHeight - 160 - e.clientY;
    const newHeight = heightOffSet + minPanelHeight;
    if (heightOffSet > 0 && newHeight < document.body.clientHeight * 0.75) {
      setPanelHeight(newHeight);
    }
  }, []);

  const handleMouseUp = (): void => {
    if (panelRef.current) panelRef.current.style.transition = 'all 300ms ease-in-out';
    document.body.style.cursor = 'initial';
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseDown = (): void => {
    if (panelRef.current) panelRef.current.style.transition = 'none';
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  return (
    <Drawer data-testid="drawer" ref={panelRef} style={{ height: panelHeight }} className={isOpen ? 'open' : 'close'}>
      <Dragger onMouseDown={(): void => handleMouseDown()} />
      <PanelSplitWrapper>{children}</PanelSplitWrapper>
    </Drawer>
  );
};

export default DraggableDrawer;
