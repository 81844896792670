import { stripSlashes } from 'services/utils';
import { apiHost, apiHostV2, loginUri } from 'config';

export const goToLogin = (targetNext?: string): void => {
  const next = encodeURIComponent(targetNext || `${window.location.pathname}${window.location.search}`);
  const location = `${loginUri}?next=${next}`;
  window.location.href = location;
};

export const apiUri = (path: string, version: 1 | 2 = 1, useNewProxy = false): string => {
  const expressApiHost = version === 2 ? apiHostV2 : apiHost;
  const origin = stripSlashes(document.location.origin);

  return useNewProxy
    ? `${origin}/api-gateway/${stripSlashes(path)}`
    : `${origin}${expressApiHost}/${stripSlashes(path)}`;
};

export const goToLogout = (next?: string): void => {
  const encodedNext = encodeURIComponent(next || `${window.location.pathname}${window.location.search}`);
  window.location.href = `/logout${encodedNext ? `?next=${encodedNext}` : ''}`;
};
