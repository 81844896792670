import { lazy, Suspense, useRef } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import PageTitle from 'components/PageTitle';
import ApiSuspense from 'components/ApiSuspense';
import Loader from 'components/Loader';
import { goToLogin } from 'services/main_app';
import StatusBar from 'views/Project/StatusBar';
import { IProps } from './IProps';
import { MAIN_ROUTES } from './getRoutes';

const Project = lazy(() => import('views/Project'));
const Dashboard = lazy(() => import('views/Dashboard'));
const BlockLibrary = lazy(() => import('views/BlockLibrary'));
const MainDashboard = lazy(() => import('views/MainDashboard'));
const ProjectImport = lazy(() => import('views/ProjectImport'));
const SubSystemCanvas = lazy(() => import('views/Project/SubSystemCanvas'));
const UrlNotMatch = lazy(() => import('views/UrlNotMatch'));
const SuccessfulLogout = lazy(() => import('views/SuccessfulLogout'));
const UnSuccessfulLogin = lazy(() => import('views/UnsuccessfulLogin'));
const FormView = lazy(() => import('views/BlockLibrary/FormView/FormView'));
const CreateFormView = lazy(() => import('views/BlockLibrary/FormView/CreateFormView'));
const ChangeRequestFormView = lazy(() => import('views/BlockLibrary/FormView/ChangeRequestFormView'));

const BannedCountries = lazy(() => import('views/BannedCountries'));

const Navigation = ({ userInfo, featurePermissions }: IProps): JSX.Element => {
  const canvasRendererRef = useRef();
  const theme = useTheme();

  return (
    <ApiSuspense success={!!userInfo}>
      <Suspense fallback={<Loader fullPage />}>
        <Routes>
          {featurePermissions.ORBIT_SHOW_ORBIT.read && (
            // NOTE: this is part of Orbit editing view
            <Route
              path={MAIN_ROUTES.subSystemCanvas.route}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.subSystemCanvas.label}>
                  <>
                    <SubSystemCanvas canvasRendererRef={canvasRendererRef} />
                    <StatusBar canvasRendererRef={canvasRendererRef} selectedCanvas="Sub System Canvas" />
                  </>
                </PageTitle>
              }
            />
          )}
          {featurePermissions.SUPERNOVA_SHOW_SUPERNOVA.read && (
            <Route
              path={MAIN_ROUTES.projectImport.route}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.projectImport.label}>
                  <ProjectImport />
                </PageTitle>
              }
            />
          )}
          {featurePermissions.SUPERNOVA_SHOW_SUPERNOVA.read && (
            <Route
              path={`${MAIN_ROUTES.projects.route}/:id`}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.projects.label}>
                  <Project />
                </PageTitle>
              }
            />
          )}
          {featurePermissions.ORBIT_SHOW_ORBIT.read && (
            <Route
              path={`${MAIN_ROUTES.blocksLibrary.route}/requests/:resourceId/`}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.projects.label}>
                  <ChangeRequestFormView />
                </PageTitle>
              }
            />
          )}
          {featurePermissions.ORBIT_SHOW_ORBIT.read && (
            <Route
              path={`${MAIN_ROUTES.blocksLibrary.route}/:resourceName/:resourceId`}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.projects.label}>
                  <FormView />
                </PageTitle>
              }
            />
          )}
          {featurePermissions.ORBIT_SHOW_ORBIT.read && (
            <Route
              path={`${MAIN_ROUTES.blocksLibrary.route}/:resourceName/create`}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.projects.label}>
                  <CreateFormView />
                </PageTitle>
              }
            />
          )}
          {featurePermissions.SUPERNOVA_SHOW_SUPERNOVA.read && (
            <Route
              path={MAIN_ROUTES.projects.route}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.projects.label}>
                  <Dashboard />
                </PageTitle>
              }
            />
          )}
          {featurePermissions.ORBIT_SHOW_ORBIT.read && !featurePermissions.USE_NEW_ORBIT_UI.read && (
            <Route
              path={`${MAIN_ROUTES.blocksLibrary.route}/*`}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.blocksLibrary.label}>
                  <BlockLibrary />
                </PageTitle>
              }
            />
          )}
          <Route
            path={MAIN_ROUTES.logout.route}
            element={
              <PageTitle tabName={theme?.name} title={MAIN_ROUTES.logout.label}>
                <SuccessfulLogout onSignIn={goToLogin} />
              </PageTitle>
            }
          />
          <Route
            path={MAIN_ROUTES.loginFailed.route}
            element={
              <PageTitle tabName={theme?.name} title={MAIN_ROUTES.loginFailed.label}>
                <UnSuccessfulLogin onSignIn={goToLogin} />
              </PageTitle>
            }
          />
          <Route
            path={MAIN_ROUTES.bannedContries.route}
            element={
              <PageTitle tabName={theme?.name} title={MAIN_ROUTES.bannedContries.label}>
                <BannedCountries />
              </PageTitle>
            }
          />
          {/* if not matched with previous main routes */}
          <Route
            path={MAIN_ROUTES.notMatch.route}
            element={
              <PageTitle tabName={theme?.name} title={MAIN_ROUTES.notMatch.label}>
                <UrlNotMatch />
              </PageTitle>
            }
          />
          {!featurePermissions.HIDE_MAIN_DASHBOARD.read ? (
            <Route
              path={MAIN_ROUTES.mainDashboard.route}
              element={
                <PageTitle tabName={theme?.name} title={MAIN_ROUTES.mainDashboard.label}>
                  <MainDashboard />
                </PageTitle>
              }
            />
          ) : (
            <Route path={MAIN_ROUTES.mainDashboard.route} element={<Navigate to={MAIN_ROUTES.projects.route} />} />
          )}
        </Routes>
      </Suspense>
    </ApiSuspense>
  );
};

export default Navigation;
