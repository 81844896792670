import { upsertResource } from 'utils/resourceUtils';
import { InterfacesState } from './Modals';
import { InterfaceActions, InterfaceActionTypes } from '../../actions/interfaces/ActionTypes';

export const initialStateInterfaces: InterfacesState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  interfacesList: [],
  interfacesListState: 'idle',
  interfacesListError: '',
  interfaceByIdState: 'idle',
  interfaceByIdError: '',
  interfaceCreateState: 'idle',
  interfaceCreateError: '',
  interfaceUpdateState: 'idle',
  interfaceUpdateError: '',
  interfaceDeleteState: 'idle',
  interfaceDeleteError: '',
};

export const interfacesReducer = (
  state: InterfacesState = initialStateInterfaces,
  action: InterfaceActions,
): InterfacesState => {
  switch (action.type) {
    case InterfaceActionTypes.GET_ALL_INTERFACES:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        interfacesListState: 'loading',
      };
    case InterfaceActionTypes.GET_ALL_INTERFACES_SUCCESS:
      return {
        ...state,
        interfacesListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        interfacesList: action.payload.data,
      };
    case InterfaceActionTypes.GET_ALL_INTERFACES_FAIL:
      return {
        ...state,
        interfacesListState: 'error',
        interfacesListError: action.payload,
      };
    case InterfaceActionTypes.GET_INTERFACE_BY_ID:
      return {
        ...state,
        interfaceByIdState: 'loading',
      };
    case InterfaceActionTypes.GET_INTERFACE_BY_ID_SUCCESS:
      return {
        ...state,
        interfaceByIdState: 'success',
        interfacesList: upsertResource(state.interfacesList, action.payload),
      };
    case InterfaceActionTypes.GET_INTERFACE_BY_ID_FAIL:
      return {
        ...state,
        interfaceByIdState: 'error',
        interfaceByIdError: action.payload,
      };
    case InterfaceActionTypes.CREATE_INTERFACE:
      return {
        ...state,
        interfaceCreateState: 'loading',
      };
    case InterfaceActionTypes.CREATE_INTERFACE_SUCCESS:
      return {
        ...state,
        interfaceCreateState: 'success',
      };
    case InterfaceActionTypes.CREATE_INTERFACE_FAIL:
      return {
        ...state,
        interfaceCreateState: 'error',
        interfaceCreateError: action.error.message,
      };
    case InterfaceActionTypes.UPDATE_INTERFACE:
      return {
        ...state,
        interfaceUpdateState: 'loading',
      };
    case InterfaceActionTypes.UPDATE_INTERFACE_SUCCESS:
      return {
        ...state,
        interfaceUpdateState: 'success',
      };
    case InterfaceActionTypes.UPDATE_INTERFACE_FAIL:
      return {
        ...state,
        interfaceUpdateState: 'error',
        interfaceUpdateError: action.error.message,
      };
    case InterfaceActionTypes.DELETE_INTERFACE:
      return {
        ...state,
        interfaceDeleteState: 'loading',
      };
    case InterfaceActionTypes.DELETE_INTERFACE_SUCCESS:
      return {
        ...state,
        interfaceDeleteState: 'success',
      };
    case InterfaceActionTypes.DELETE_INTERFACE_FAIL:
      return {
        ...state,
        interfaceDeleteState: 'error',
        interfaceDeleteError: action.error.message,
      };
    default:
      return state;
  }
};
