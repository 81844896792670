import { PutEffect, ForkEffect, CallEffect, put, takeEvery, call } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { axiosInstance } from 'services/dataService';
import { getErrorMessage } from 'utils/getErrorMessage';

const getFetchHandler = (type: string): typeof axiosInstance.get | undefined =>
  type.includes('GET') ? axiosInstance.get : undefined;

const getSendHandler = (
  type: string,
): typeof axiosInstance.post | typeof axiosInstance.patch | typeof axiosInstance.delete => {
  const operation: 'post' | 'patch' | 'delete' =
    (type.includes('UPDATE') && 'patch') || (type.includes('DELETE') && 'delete') || 'post';

  return axiosInstance[operation];
};

export function* crudRequestHandleSaga(action: {
  endpoint?: string;
  type: string;
  payload?: any;
}): Generator<PutEffect | CallEffect<AxiosResponse> | PutEffect> {
  const { endpoint, type, payload } = action;
  if (endpoint) {
    try {
      const getHandler = getFetchHandler(type);

      const response = (yield call(
        () => (getHandler && getHandler(endpoint)) || getSendHandler(type)(endpoint, payload),
      )) as AxiosResponse;

      yield put({
        type: `${type}_SUCCESS`,
        payload: response.data,
      });
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('==================================');
        console.log({ error });
        console.log('==================================');
      }

      yield put({
        type: `${type}_FAIL`,
        payload: getErrorMessage(error) || 'Something went wrong',
      });
    }
  }
}

export function* crudRequestMiddlewareWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery('*', crudRequestHandleSaga);
}
