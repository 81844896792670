import { IExportJob, IExportJobAirflow, IExportJobWithChildren } from 'state_management/reducers/exportJobs/Modals';

export const deserializeExportJobAirflow = (exportJob: IExportJobAirflow): Raw.IExportJobAirflow => ({
  report: {
    output_location: exportJob.report.outputLocation,
    output_format: exportJob.report.outputFormat,
    sheet: exportJob.report.sheet,
  },
});

export const deserializeExportJob = (exportJob: IExportJob): Raw.IExportJob => ({
  id: exportJob.id,
  created_at: exportJob.createdAt,
  modified_at: exportJob.modifiedAt,
  name: exportJob.name,
  state: exportJob.state || 'pending',
  file_id: exportJob.fileId,
  files: exportJob.files,
  file_format: exportJob.fileFormat,
  output_location: exportJob.outputLocation || 'data-service',
  description: exportJob.description,
  query: exportJob.query,
  advanced_search_query: exportJob.advancedSearchQuery,
  templates: exportJob.templates?.map(({ id }: { id: string }) => id),
  can_use_dynamic_templates: exportJob.customizedSheets,
});

export const serializeExportJob = (exportJob: Raw.IExportJob): IExportJob => ({
  id: exportJob.id,
  createdAt: exportJob.created_at,
  modifiedAt: exportJob.modified_at,
  name: exportJob.name,
  state: exportJob.state,
  fileId: exportJob.file_id,
  files: exportJob.files,
  fileFormat: exportJob.file_format,
  outputLocation: exportJob.output_location,
  description: exportJob.description,
  query: exportJob.query,
  advancedSearchQuery: exportJob.advanced_search_query,
  templates: exportJob.templates?.map((id: string) => ({ id })),
  customizedSheets: exportJob.can_use_dynamic_templates,
});

export const serializeExportJobWithChildren = (exportJob: Raw.IExportJobWithChildren): IExportJobWithChildren => ({
  id: exportJob.id,
  createdAt: exportJob.created_at,
  modifiedAt: exportJob.modified_at,
  name: exportJob.name,
  state: exportJob.state,
  fileId: exportJob.file_id,
  files: exportJob.files.map((file) => ({
    id: file.id,
    createdAt: file.created_at,
    selected: true,
  })),
  fileFormat: exportJob.file_format,
  outputLocation: exportJob.output_location,
  description: exportJob.description,
  query: exportJob.query,
  advancedSearchQuery: exportJob.advanced_search_query,
  templates: exportJob.templates?.map((id: string) => ({ id })),
  customizedSheets: exportJob.can_use_dynamic_templates,
});
