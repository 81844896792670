import {
  BoardShapeCanvasActions,
  BoardShapeCanvasActionTypes,
} from 'state_management/actions/boardShapeCanvas/ActionTypes';
import { flipAllAngles } from '../../../services/geo';
import { BoardCanvasState } from './Modals';

export const initialStateBoardCanvas: BoardCanvasState = {
  boardShapeCanvas: {
    uiOptions: { grid: { display: '', type: '', value: 0 }, jumpover: false },
    // TODO: Should remove blocks, as `blocks` = `modules` and API talks in `modules` language
    blocks: [],
    links: [],
    settings: [],
    readOnly: true,
    unitFactor: 1,
    margin: 0,
    boardShapeFixed: false,
    boardPolygon: [],
    marginPolygon: [],
    modules: [],
  },
};

export const boardCanvasReducer = (
  state: BoardCanvasState = initialStateBoardCanvas,
  action: BoardShapeCanvasActions,
): BoardCanvasState => {
  switch (action.type) {
    case BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_CLEAR:
      return initialStateBoardCanvas;
    case BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_GET_BOARD_SUCCESS:
      return {
        ...state,
        boardShapeCanvas: {
          ...action.payload,
          modules: flipAllAngles(action.payload.modules),
        },
      };
    case BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE:
      return {
        ...state,
        boardShapeCanvas: { ...state.boardShapeCanvas, boardPolygon: action.payload },
      };
    case BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BOARD_SHAPE_LOCK:
      return {
        ...state,
        boardShapeCanvas: { ...state.boardShapeCanvas, boardShapeFixed: action.payload },
      };
    case BoardShapeCanvasActionTypes.BOARD_SHAPE_CANVAS_SET_BLOCKS:
      return {
        ...state,
        boardShapeCanvas: { ...state.boardShapeCanvas, modules: action.payload },
      };
    default:
      return state;
  }
};
