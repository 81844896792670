import {
  GetWelcomeAtAction,
  UnboxingActionTypes,
  UpdateWelcomeAtAction,
  SetAcceptedConsentIdsActionType,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';

export const getWelcomeAtAction = (): GetWelcomeAtAction => ({
  type: UnboxingActionTypes.GET_WELCOME_AT,
  payload: {
    request: {
      method: 'get',
      withCredentials: true,
      url: apiUri('/unboxing/api/welcome', 2),
    },
  },
});

export const updateWelcomeAtAction = (): UpdateWelcomeAtAction => ({
  type: UnboxingActionTypes.UPDATE_WELCOME_AT,
  payload: {
    request: {
      method: 'patch',
      withCredentials: true,
      url: apiUri('/unboxing/api/welcome', 2),
    },
  },
});

export const setAcceptedConsentIdsAction = (payload: Array<string>): SetAcceptedConsentIdsActionType => ({
  type: UnboxingActionTypes.SET_ACCEPTED_CONSENT_IDS,
  payload,
});
