import {
  ApprovalsType,
  ChangeRequestResourceNameType,
  IChangesRequest,
  PreApprovalsType,
} from 'state_management/reducers/changesRequest/Modals';

export function deserializeChangesRequests(changesRequest: Partial<IChangesRequest>): Partial<Raw.IChangesRequest> {
  return {
    id: changesRequest.id,
    pre_approvals: changesRequest.preApprovals,
    approvals: changesRequest.approvals,
    resource_name: changesRequest.resourceName,
    target_method: changesRequest.targetMethod,
    changes: changesRequest.changes,
    state: changesRequest.state,
    resource_id: changesRequest.resourceId,
    created_at: changesRequest.createdAt,
    modified_at: changesRequest.modifiedAt,
    owner_email: changesRequest.ownerEmail,
    document_name: changesRequest.documentName,
    extra: changesRequest.extra,
  };
}
export function serializeChangesRequests(payload: Raw.IChangesRequest): IChangesRequest {
  return {
    id: payload.id,
    preApprovals: payload.pre_approvals as Array<PreApprovalsType>,
    approvals: payload.approvals as Array<ApprovalsType>,
    resourceName: payload.resource_name as ChangeRequestResourceNameType,
    targetMethod: payload.target_method,
    changes: payload.changes,
    state: payload.state || 'pending',
    resourceId: payload.resource_id,
    createdAt: payload.created_at,
    modifiedAt: payload.modified_at,
    ownerEmail: payload.owner_email,
    documentName: payload.document_name,
    requestIcon: payload.requestIcon,
    updateOldData: payload.old_data,
    extra: payload.extra,
  };
}
