import styled from 'styled-components';

export const Container = styled.div`
  && {
    width: 80vw;
    max-width: 1000px;
    height: 80vh;
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px 24px 24px;
    box-sizing: border-box;
  }
`;

export const SectionContainer = styled.div`
  && {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 45%;
    margin: 16px;
  }
`;

export const SectionTitle = styled.p`
  && {
    font-size: ${({ theme }): string => theme.fontSize.tiny};
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const ShortcutDetails = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid ${({ theme }): string => theme.palette.border};

    font-size: ${({ theme }): string => theme.fontSize.small};

    & > p:first-child {
      font-weight: 500;
    }

    & > p {
      margin: 8px;
    }

    & kbd {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      margin: 5px 0;
      border: 1px solid ${({ theme }): string => theme.palette.border};
      font-family: Arial, Helvetica, sans-serif;
      background-color: ${({ theme }): string => theme.palette.canvasBg};
      color: ${({ theme }): string => theme.palette.text};
      box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px ${({ theme }): string => theme.palette.white} inset;
      border-radius: 3px;
    }
  }
`;
