import { upsertResource } from 'utils/resourceUtils';
import { VirtualComponentsState } from './Modals';
import { VirtualComponentActions, VirtualComponentsActionTypes } from '../../actions/virtualComponents/ActionTypes';

export const initialStateVirtualComponents: VirtualComponentsState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  virtualComponentsList: [],
  virtualComponentsListState: 'idle',
  virtualComponentsListError: '',
  virtualComponentCreateState: 'idle',
  virtualComponentCreateError: '',
  virtualComponentUpdateState: 'idle',
  virtualComponentUpdateError: '',
  virtualComponentDeleteState: 'idle',
  virtualComponentDeleteError: '',
  virtualComponentByIdState: 'idle',
  virtualComponentByIdStateError: '',
  virtualComponentCadFiles: [],
  virtualComponentCadFilesError: '',
  virtualComponentCadFileDeleteState: 'idle',
  virtualComponentDocFiles: [],
  virtualComponentDocFilesError: '',
};

export const virtualComponentsReducer = (
  state: VirtualComponentsState = initialStateVirtualComponents,
  action: VirtualComponentActions,
): VirtualComponentsState => {
  switch (action.type) {
    case VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        virtualComponentsListState: 'loading',
      };
    case VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS_SUCCESS:
      return {
        ...state,
        virtualComponentsListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        virtualComponentsList: action.payload.data,
      };
    case VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS_FAIL:
      return {
        ...state,
        virtualComponentsListState: 'error',
        virtualComponentsListError: action.payload,
      };
    case VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT:
      return {
        ...state,
        virtualComponentCreateState: 'loading',
      };
    case VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_SUCCESS:
      return {
        ...state,
        virtualComponentCreateState: 'success',
      };
    case VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT_FAIL:
      return {
        ...state,
        virtualComponentCreateState: 'error',
        virtualComponentCreateError: action.error.message,
      };
    case VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT:
      return {
        ...state,
        virtualComponentUpdateState: 'loading',
      };
    case VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_SUCCESS:
      return {
        ...state,
        virtualComponentUpdateState: 'success',
      };
    case VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_FAIL:
      return {
        ...state,
        virtualComponentUpdateState: 'error',
        virtualComponentUpdateError: action.error.message,
      };
    case VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT:
      return {
        ...state,
        virtualComponentDeleteState: 'loading',
      };
    case VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_SUCCESS:
      return {
        ...state,
        virtualComponentDeleteState: 'success',
      };
    case VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_FAIL:
      return {
        ...state,
        virtualComponentDeleteState: 'error',
        virtualComponentDeleteError: action.error.message,
      };
    case VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID:
      return {
        ...state,
        virtualComponentByIdState: 'loading',
      };
    case VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID_SUCCESS:
      return {
        ...state,
        virtualComponentByIdState: 'success',
        virtualComponentsList: upsertResource(state.virtualComponentsList, action.payload.data),
        virtualComponentDocFiles: action.payload.docFiles,
        virtualComponentCadFiles: action.payload.cadFiles,
      };
    case VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID_FAIL:
      return {
        ...state,
        virtualComponentByIdState: 'error',
        virtualComponentByIdStateError: action.payload,
      };
    case VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE:
    case VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE: {
      const listKey =
        action.type === VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE
          ? 'virtualComponentCadFiles'
          : 'virtualComponentDocFiles';
      return {
        ...state,
        [listKey]: action.payload.map((f) => (f.state === 'idle' ? { ...f, state: 'loading' } : f)),
      };
    }
    case VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS:
    case VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS: {
      const listKey =
        action.type === VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS
          ? 'virtualComponentCadFiles'
          : 'virtualComponentDocFiles';
      const file = action.payload;
      return {
        ...state,
        [listKey]: state[listKey]
          // NOTE: Filter out old files with same usage as `file` and not pending validation
          .filter(
            (f) =>
              f.name === file.name ||
              !(f.usedAs?.some((u) => file.usedAs?.includes(u)) && (f.state === 'success' || f.state === 'error')),
          )
          .map((f) =>
            file.name === f.name
              ? {
                  ...f,
                  ...file,
                }
              : f,
          ),
      };
    }
    case VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_FAIL:
    case VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_FAIL: {
      const listKey =
        action.type === VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_FAIL
          ? 'virtualComponentCadFiles'
          : 'virtualComponentDocFiles';
      const file = action.payload;

      return {
        ...state,
        [listKey]: state[listKey].map((f) =>
          f.name === file.name
            ? {
                ...f,
                ...file,
              }
            : f,
        ),
        [`${listKey}Error`]: file.error,
      };
    }
    case VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_DOC_FILE:
    case VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_CAD_FILE: {
      const listKey =
        action.type === VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_CAD_FILE
          ? 'virtualComponentCadFiles'
          : 'virtualComponentDocFiles';
      return {
        ...state,
        [listKey]: state[listKey].filter((f) => f.id !== action.payload.id),
      };
    }
    case VirtualComponentsActionTypes.CLEAN_UP_VIRTUAL_COMPONENTS: {
      return { ...state, virtualComponentDocFiles: [], virtualComponentCadFiles: [] };
    }
    default:
      return state;
  }
};
