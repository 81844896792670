import {
  ExportJobsActionTypes,
  ExportJobActions,
  UpdateExportJobErrorAction,
  CreateExportJobErrorAction,
  DeleteExportJobErrorAction,
} from 'state_management/actions/exportJobs/ActionTypes';
import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';
import { globalToast } from 'components/ToastComponent';
import { getErrorMessage } from 'utils/getErrorMessage';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const exportJobsUpdateToasts = (action: ExportJobActions): void => {
  TOAST_IDS.update = globalToast(
    TOAST_IDS.update,
    action.type,
    {
      loading: 'Export Job is being updated, please wait...',
      success: 'Export Job has been successfully updated.',
      error:
        getErrorMessage((action as UpdateExportJobErrorAction).error) ||
        'Updating Export Job failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.update },
  );
};

const exportJobsCloneToasts = (action: ExportJobActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Export Job is being cloned, please wait...',
      success: 'Export Job has been successfully cloned.',
      error:
        getErrorMessage((action as UpdateExportJobErrorAction).error) ||
        'Cloning Export Job failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const exportJobsCreateToasts = (action: ExportJobActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Export Job is being created, please wait...',
      success: 'Export Job has been successfully created.',
      error: getErrorMessage(action as CreateExportJobErrorAction) || 'Creating Export Job failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const exportJobsDeleteToasts = (action: ExportJobActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Export Job is being deleted, please wait...',
      success: 'Export Job has been successfully deleted.',
      error:
        getErrorMessage((action as DeleteExportJobErrorAction).error) ||
        'Deleting Export Job failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        ExportJobsActionTypes.CREATE_EXPORT_JOB,
        ExportJobsActionTypes.CREATE_EXPORT_JOB_SUCCESS,
        ExportJobsActionTypes.CREATE_EXPORT_JOB_FAIL,
      ],
      exportJobsCreateToasts,
    ),
    takeEvery(
      [
        ExportJobsActionTypes.UPDATE_EXPORT_JOB,
        ExportJobsActionTypes.UPDATE_EXPORT_JOB_SUCCESS,
        ExportJobsActionTypes.UPDATE_EXPORT_JOB_FAIL,
      ],
      exportJobsUpdateToasts,
    ),
    takeEvery(
      [
        ExportJobsActionTypes.CLONE_EXPORT_JOB,
        ExportJobsActionTypes.CLONE_EXPORT_JOB_SUCCESS,
        ExportJobsActionTypes.CLONE_EXPORT_JOB_FAIL,
      ],
      exportJobsCloneToasts,
    ),
    takeEvery(
      [
        ExportJobsActionTypes.DELETE_EXPORT_JOB,
        ExportJobsActionTypes.DELETE_EXPORT_JOB_SUCCESS,
        ExportJobsActionTypes.DELETE_EXPORT_JOB_FAIL,
      ],
      exportJobsDeleteToasts,
    ),
  ]);
}
