import { forwardRef } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Popover from 'components/Popover';
import FormFieldClickBlocker from 'components/FormFieldClickBlocker';

import { FilledInputProps, InputProps, OutlinedInputProps } from '@material-ui/core';
import { IProps } from './IProps';

import { FormControl, TooltipInfoContainer, HelpOutline, IconButton } from './styles';

const TextInput = (
  {
    id,
    name,
    label,
    value,
    info,
    error,
    errorMessage,
    required,
    size = 'medium',
    placeholder,
    variant = 'outlined',
    multiline,
    rows,
    rowsMax,
    type,
    disabled,
    onChange,
    onKeyDown,
    autoFocus,
    onBlur,
    isClickBlocked = false,
    ...rest
  }: IProps,
  ref: React.Ref<HTMLInputElement>,
): JSX.Element => {
  const tooltipAdornment = info ? (
    <InputAdornment key="tooltip-adornment" position="end">
      <Popover delay={0.5} hoverTrigger content={<TooltipInfoContainer>{info}</TooltipInfoContainer>}>
        <IconButton size="small">
          <HelpOutline />
        </IconButton>
      </Popover>
    </InputAdornment>
  ) : null;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    onKeyDown && onKeyDown(e);
    // NOTE: Only 'Escape shortcut should work in Input all other would get disabled
    if (e.key !== 'Escape') {
      e.stopPropagation();
    }
  };
  const getInputProps = ():
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | undefined => {
    let baseOption = { endAdornment: [tooltipAdornment] };

    if (type === 'positive_number') baseOption = { ...{ inputProps: { min: 0, step: 'any' } }, ...baseOption };
    return baseOption;
  };
  return (
    <FormControl disabled={disabled}>
      <div>
        <TextField
          id={id}
          label={label}
          name={name}
          inputRef={ref}
          value={value}
          autoFocus={autoFocus}
          error={error && !!errorMessage}
          onChange={onChange}
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
          required={required}
          size={size}
          placeholder={placeholder}
          variant={variant}
          multiline={multiline || Boolean(rowsMax)}
          fullWidth
          rows={rows}
          rowsMax={rowsMax}
          type={(type === 'positive_number' ? 'number' : type) || 'text'}
          disabled={disabled}
          InputProps={getInputProps()}
          helperText={error && errorMessage}
          data-testid={id}
          {...rest}
        />
        {isClickBlocked ? <FormFieldClickBlocker /> : <></>}
      </div>
    </FormControl>
  );
};

export default forwardRef(TextInput);
