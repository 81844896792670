import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  DesignConsiderationsActionTypes,
  GetAllDesignConsiderationsAction,
  GetAllDesignConsiderationsErrorAction,
  GetAllDesignConsiderationsSuccessAction,
} from 'state_management/actions/designConsiderations/ActionTypes';

import {
  getAllDesignConsiderationsSuccessAction,
  getAllDesignConsiderationsErrorAction,
  ENDPOINT_DESIGN_CONSIDERATIONS,
} from 'state_management/actions/designConsiderations/designConsiderationsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeDesignConsideration } from 'utils/designConsiderationsSerializer';

import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';

export function* getAllSaga(
  action: GetAllDesignConsiderationsAction,
): Generator<
  | PutEffect<GetAllDesignConsiderationsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllDesignConsiderationsSuccessAction | GetAllDesignConsiderationsErrorAction>
> {
  try {
    const {
      designConsiderationsState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const designConsiderationsSearchSchema: TypeSearch = {
      name: 'value',
      short_description: 'value',
      hyperlink: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), designConsiderationsSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_DESIGN_CONSIDERATIONS}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2),
      ),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllDesignConsiderationsSuccessAction(_pagination, res.data.map(serializeDesignConsideration)));
  } catch (error) {
    yield put(getAllDesignConsiderationsErrorAction(getErrorMessage(error) || 'Fetching designConsiderations failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(DesignConsiderationsActionTypes.GET_ALL_DESIGN_CONSIDERATIONS, getAllSaga);
}
