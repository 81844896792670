import { useEffect, useRef, useState } from 'react';

import { preventClickEventPropagation } from 'utils/preventEventPropagation';

import { getPopoverStyles } from './utils/getPopoverStyles';
import { getToolTipLocation } from './utils/getToolTipLocation';

import { IProps } from './IProps';

import { ContentContainer, Container, Content } from './styles';
import FormFieldClickBlocker from '../FormFieldClickBlocker';

const Popover = ({
  controlledClose,
  anchorLessOpen,
  anchorX,
  anchorY,
  delay,
  horizontalOffset,
  hoverTrigger,
  verticalOffset,
  toolTipOffset,
  toolTipLocation,
  onToggle,
  content,
  noTip,
  closeOnBackDrop,
  children,
  isClickedBlocked,
}: IProps): JSX.Element => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [containerWidth, setContainerWidth] = useState(0);
  const [anchorElement, setAnchorElement] = useState(anchorRef.current);
  const [open, setOpen] = useState(anchorLessOpen);

  const openHandler = (event: React.MouseEvent<HTMLDivElement>): void => {
    preventClickEventPropagation(event);

    setOpen(!open);
    onToggle && onToggle(!open);
  };

  const closeHandler = (): void => {
    setOpen(false);
    onToggle && onToggle(false);
  };

  useEffect(() => {
    setAnchorElement(anchorRef.current);
  }, [anchorRef.current]);

  useEffect(() => {
    setOpen(anchorLessOpen);
  }, [anchorLessOpen]);

  useEffect(() => {
    if (hoverTrigger) {
      return (): void => {};
    }

    const globalCloseHandler = (event: MouseEvent): void => {
      event.stopPropagation();

      closeHandler();
    };

    closeOnBackDrop && document.addEventListener('click', globalCloseHandler);
    document.addEventListener('scroll', closeHandler);

    return (): void => {
      closeOnBackDrop && document.removeEventListener('click', globalCloseHandler);
      document.removeEventListener('scroll', closeHandler);
    };
  }, []);

  useEffect(() => {
    const width = containerRef.current?.offsetWidth;

    width && setContainerWidth(width);
  }, [containerRef.current, content]);

  const viewPortHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth);

  const anchorRect: DOMRect =
    !children || !anchorElement
      ? ({
          x: anchorX || 0,
          y: anchorY || 0,
          left: anchorX || 0,
          right: anchorX || 0,
          top: anchorY || 0,
          bottom: anchorY || 0,
          width: 0,
          height: 0,
        } as DOMRect)
      : anchorElement.getBoundingClientRect();

  const { containerStyles, toolTipStyles } = getPopoverStyles(
    anchorRect,
    viewPortWidth,
    viewPortHeight,
    containerWidth,
    toolTipLocation,
    horizontalOffset,
    verticalOffset,
    toolTipOffset,
    noTip,
  );

  const toolTipFinalLocation = getToolTipLocation(containerStyles, toolTipLocation);

  const container = {
    hidden: {
      height: 0,
      scaleY: 0,
      transition: {
        duration: 0,
      },
    },
    show: {
      height: 'auto',
      scaleY: 1,
      transition: {
        delay: delay || 0,
        duration: 0, // TODO: Improve animations
      },
    },
  };

  const handlers = hoverTrigger ? { onMouseEnter: openHandler, onMouseLeave: closeHandler } : { onClick: openHandler };

  return (
    <>
      {/* `.popover-anchor-container` as global selector to style anchor under Popover */}
      <Container {...handlers} ref={anchorRef} className="popover-anchor-container">
        {children}
        {isClickedBlocked ? <FormFieldClickBlocker /> : <></>}
      </Container>
      <ContentContainer
        style={containerStyles}
        onClick={preventClickEventPropagation}
        ref={containerRef}
        toolTipDirection={toolTipFinalLocation}
        toolTipStyles={toolTipStyles}
        noTip={noTip}
        variants={container}
        initial="hidden"
        animate={open && !controlledClose ? 'show' : 'hidden'}
      >
        <Content>{content}</Content>
        {isClickedBlocked ? <FormFieldClickBlocker /> : <></>}
      </ContentContainer>
    </>
  );
};

export default Popover;
