import { css, createGlobalStyle, FlattenInterpolation, ThemeProps } from 'styled-components';
import { assetUri } from 'services/utils';
import { Theme as MuiTheme } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { AppTheme, UserTheme } from 'models/ThemeProps';
import { ThemeConfig } from 'antd/es/config-provider/context';

// NOTE: Default Theme in case API request fails for Active Theme, shouldn't be used directly
export const DEFAULT_THEME: UserTheme = {
  id: '61e6b62b8fd4c164868ed5db',
  name: 'Default Theme',
  host: '',
  show_powered_by_celus: false,
  mainDashboard: {
    name: 'Celus',
    logos: {
      square: assetUri('/img/celus-logo-square.svg'),
      wide: assetUri('/img/celus-logo-typo.svg'),
    },
    palette: {
      accent: '#004C97',
      accentHover: ' #005BB5',
      accentLightBg: '#cce6ff',
      text: '#172335',
      textLink: '#004C97',
      dark: '#000000',
      pageBg: 'white',
      canvasBg: '#f2f2f2',
      accentBg: '#616260',
      hoverGray: '#0000000a',
      block: '#161A25',
      whiteText: '#FFFFFF',
      lightText: '#E3E3E5',
      darkText: '#413E46',
      accentDark: '#002850',
      dashboardBg: '#FBFAFA',
      activeOption: '#172335',
      disabled: '#B2B2B2',
      border: '#D7D7D7',
      imageBg: '#EFEFEF',
      white: '#FFFFFF',
      settingsActive: 'white',
      settingsNotActive: 'lightgray',
      backdropBg: '#B1B1B180',
      loaderBg: '#E0E0E0',
      success: '#009954',
      active: '#d9fde6',
      error: '#c62500',
      lightError: '#fcc',
      warning: '#efb800',
      warningBackground: '#fff0cd',
      inactive: '#B1B1B1',
      separator: '#D9D9D9',
      lightSeparator: '#0000001f',
      info: '#5D5D5D',
      thumb: '#707070',
      linkHover: '#C5C5C5',
      schematics: '#932020',
      layout: '#22761B',
      bom: '#2866A3',
      pdf: '#5A0000',
      change: 'rgb(224, 145, 66,0.12)',
      clearYellow: '#E2A300',
      lightgrey: '#F5F5F5',
      textLight: '#5A626D',
      gray: '#E4E4E4',
    },
    sizes: {
      typoLogo: '40px',
      logo: '36px',
    },
    border: {
      thumbnail: '1px solid #707070;',
      lighter: '1px solid #D7D7D7;',
    },
    spacing: {
      extraSmall: 4,
      mediumSmall: 6,
      small: 8,
      normal: 16,
      screenContent: 12,
      medium: 24,
      large: 32,
      form: 12,
    },
    shadow: {
      tiny: '0px 1px 2px #0000001E',
      small: '0px 2px 4px #0000001E',
      normal: '0px 3px 6px #0000001E',
      topTiny: '0px -1px 2px #0000001E',
      prominent: '0px 1px 12px #00000029',
    },
    fonts: {
      normal: "'Roboto', sans-serif",
      semiBold: "'Roboto', semi-bold",
    },
    fontSize: {
      nether: '8px',
      quantum: '10px',
      nano: '12px',
      tiny: '13px',
      small: '14px',
      impact: '15px',
      normal: '16px',
      medium: '18px',
      mLarge: '20px',
      large: '24px',
      title: '32px',
    },
  },
  supernova: {
    name: 'Supernova',
    logos: {
      square: assetUri('/img/supernova-logo-square.svg'),
      wide: assetUri('/img/supernova-logo-typo.svg'),
    },
    palette: {
      accent: '#0089b1',
      accentHover: ' #33a0c1',
      accentLightBg: '#99d0e0',
      text: '#172335',
      textLink: '#004C97',
      dark: '#000000',
      pageBg: 'white',
      canvasBg: '#f2f2f2',
      accentBg: '#616260',
      hoverGray: '#0000000a',
      block: '#161A25',
      whiteText: '#FFFFFF',
      lightText: '#E3E3E5',
      darkText: '#413E46',
      accentDark: '#002850',
      dashboardBg: '#FBFAFA',
      activeOption: '#172335',
      disabled: '#B2B2B2',
      border: '#D7D7D7',
      imageBg: '#EFEFEF',
      white: '#FFFFFF',
      settingsActive: 'white',
      settingsNotActive: 'lightgray',
      backdropBg: '#B1B1B180',
      loaderBg: '#E0E0E0',
      success: '#009954',
      active: '#d9fde6',
      error: '#c62500',
      lightError: '#fcc',
      warning: '#efb800',
      warningBackground: '#fff0cd',
      inactive: '#B1B1B1',
      separator: '#D9D9D9',
      lightSeparator: '#0000001f',
      info: '#5D5D5D',
      thumb: '#707070',
      linkHover: '#C5C5C5',
      schematics: '#932020',
      layout: '#22761B',
      bom: '#2866A3',
      pdf: '#5A0000',
      change: 'rgb(224, 145, 66,0.12)',
      clearYellow: '#E2A300',
      lightgrey: '#F5F5F5',
      textLight: '#5A626D',
      gray: '#E4E4E4',
    },
    sizes: {
      typoLogo: '40px',
      logo: '36px',
    },
    border: {
      thumbnail: '1px solid #707070;',
      lighter: '1px solid #D7D7D7;',
    },
    spacing: {
      extraSmall: 4,
      mediumSmall: 6,
      small: 8,
      normal: 16,
      screenContent: 12,
      medium: 24,
      large: 32,
      form: 12,
    },
    shadow: {
      tiny: '0px 1px 2px #0000001E',
      small: '0px 2px 4px #0000001E',
      normal: '0px 3px 6px #0000001E',
      topTiny: '0px -1px 2px #0000001E',
      prominent: '0px 1px 12px #00000029',
    },
    fonts: {
      normal: "'Roboto', sans-serif",
      semiBold: "'Roboto', semi-bold",
    },
    fontSize: {
      nether: '8px',
      quantum: '10px',
      nano: '12px',
      tiny: '13px',
      small: '14px',
      impact: '15px',
      normal: '16px',
      medium: '18px',
      mLarge: '20px',
      large: '24px',
      title: '32px',
    },
  },
  orbit: {
    name: 'Orbit',
    logos: {
      square: assetUri('/img/orbit-logo-square.svg'),
      wide: assetUri('/img/orbit-logo-typo.svg'),
    },
    palette: {
      accent: '#08A39F',
      accentHover: '#06c3be',
      accentLightBg: '#9CDAD9',
      text: '#172335',
      textLink: '#004C97',
      dark: '#000000',
      pageBg: 'white',
      canvasBg: '#f2f2f2',
      accentBg: '#616260',
      hoverGray: '#0000000a',
      block: '#161A25',
      whiteText: '#FFFFFF',
      lightText: '#E3E3E5',
      darkText: '#413E46',
      accentDark: '#002850',
      dashboardBg: '#FBFAFA',
      activeOption: '#172335',
      disabled: '#B2B2B2',
      border: '#D7D7D7',
      imageBg: '#EFEFEF',
      white: '#FFFFFF',
      settingsActive: 'white',
      settingsNotActive: 'lightgray',
      backdropBg: '#B1B1B180',
      loaderBg: '#E0E0E0',
      success: '#009954',
      active: '#d9fde6',
      error: '#c62500',
      lightError: '#fcc',
      warning: '#efb800',
      warningBackground: '#fff0cd',
      inactive: '#B1B1B1',
      separator: '#D9D9D9',
      lightSeparator: '#0000001f',
      info: '#5D5D5D',
      thumb: '#707070',
      linkHover: '#C5C5C5',
      schematics: '#932020',
      layout: '#22761B',
      bom: '#2866A3',
      pdf: '#5A0000',
      change: 'rgb(224, 145, 66,0.12)',
      clearYellow: '#E2A300',
      lightgrey: '#F5F5F5',
      textLight: '#5A626D',
      gray: '#E4E4E4',
    },
    sizes: {
      typoLogo: '40px',
      logo: '36px',
    },
    border: {
      thumbnail: '1px solid #707070;',
      lighter: '1px solid #D7D7D7;',
    },
    spacing: {
      extraSmall: 4,
      mediumSmall: 6,
      small: 8,
      normal: 16,
      screenContent: 12,
      medium: 24,
      large: 32,
      form: 12,
    },
    shadow: {
      tiny: '0px 1px 2px #0000001E',
      small: '0px 2px 4px #0000001E',
      normal: '0px 3px 6px #0000001E',
      topTiny: '0px -1px 2px #0000001E',
      prominent: '0px 1px 12px #00000029',
    },
    fonts: {
      normal: "'Roboto', sans-serif",
      semiBold: "'Roboto', semi-bold",
    },
    fontSize: {
      nether: '8px',
      quantum: '10px',
      nano: '12px',
      tiny: '13px',
      small: '14px',
      impact: '15px',
      normal: '16px',
      medium: '18px',
      mLarge: '20px',
      large: '24px',
      title: '32px',
    },
  },
};

export const generateThemeFileUri = (id?: string): string =>
  `/api/v2/dataservice/object-storage/apptheme-files/${id}/download?fileContentData=true`;

const mergeDefaultAndActiveTheme = (defaultTheme: AppTheme, activeTheme: AppTheme): AppTheme => ({
  ...defaultTheme,
  ...activeTheme,
  logos: {
    ...defaultTheme.logos,
    ...(activeTheme?.logos.square && { square: activeTheme?.logos.square }),
    ...(activeTheme?.logos.wide && { wide: activeTheme?.logos.wide }),
    ...(activeTheme?.logos.favicon && { favicon: activeTheme?.logos.favicon }),
  },
  palette: {
    ...defaultTheme.palette,
    ...activeTheme?.palette,
  },
  sizes: {
    ...defaultTheme.sizes,
    ...activeTheme?.sizes,
  },
  border: {
    ...defaultTheme.border,
    ...activeTheme?.border,
  },
  spacing: {
    ...defaultTheme.spacing,
    ...activeTheme?.spacing,
  },
  shadow: {
    ...defaultTheme.shadow,
    ...activeTheme?.shadow,
  },
  fonts: {
    ...defaultTheme.fonts,
    ...activeTheme?.fonts,
  },
  fontSize: {
    ...defaultTheme.fontSize,
    ...activeTheme?.fontSize,
  },
});

export const deserializeLogos = (logos: Raw.ILogos): Raw.Logos => ({
  ...(logos?.square_file_id && {
    square: generateThemeFileUri(logos.square_file_id),
  }),
  ...(logos?.wide_file_id && {
    wide: generateThemeFileUri(logos.wide_file_id),
  }),
  ...(logos?.favicon_file_id && {
    favicon: generateThemeFileUri(logos.favicon_file_id),
  }),
});

const deserializeTheme = (theme: Raw.ITheme): UserTheme => ({
  id: theme.id,
  name: theme.name,
  host: theme.host,
  show_powered_by_celus: theme.show_powered_by_celus,
  mainDashboard: {
    ...theme.main_dashboard,
    logos: deserializeLogos(theme?.main_dashboard?.logos),
  },
  orbit: {
    ...theme.orbit,
    logos: deserializeLogos(theme?.orbit?.logos),
  },
  supernova: {
    ...theme.supernova,
    logos: deserializeLogos(theme?.supernova?.logos),
  },
});

export const mergeThemes = (defaultTheme: UserTheme, currentTheme: Raw.ITheme): UserTheme => {
  const activeTheme = deserializeTheme(currentTheme);
  return {
    ...defaultTheme,
    ...activeTheme,
    mainDashboard: mergeDefaultAndActiveTheme(defaultTheme.mainDashboard, activeTheme?.mainDashboard),
    orbit: mergeDefaultAndActiveTheme(defaultTheme.orbit, activeTheme?.orbit),
    supernova: mergeDefaultAndActiveTheme(defaultTheme.supernova, activeTheme?.supernova),
  };
};

export const getAppMuiTheme = (activeTheme: AppTheme, createDefault = false): MuiTheme =>
  createDefault
    ? createTheme()
    : createTheme({
        typography: {
          // NOTE: Update this when upgrade MuiTheme
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          useNextVariants: true,
          fontFamily: "'Roboto', 'sans-serif'",
          subtitle1: {
            fontSize: activeTheme.fontSize.small,
          },
        },
        palette: {
          primary: { 500: activeTheme.palette.accent, main: activeTheme.palette.accent },
          error: { main: activeTheme.palette.error },
          common: { white: activeTheme.palette.white, black: activeTheme.palette.dark },
          divider: activeTheme.palette.border,
          action: {
            disabled: activeTheme.palette.disabled,
          },
          text: { disabled: activeTheme.palette.disabled },
          contrastThreshold: 3, // 2 might be better
          tonalOffset: 0.2,
        },
      });

export const onShortMobile = (...args: Parameters<typeof css>): FlattenInterpolation<ThemeProps<any>> => css`
  @media (max-aspect-ratio: 100/90) {
    ${css(...args)}
  }
`;

export const mobileWidth = 1024;

export const onMobile = (...args: Parameters<typeof css>): FlattenInterpolation<ThemeProps<any>> => css`
  @media (max-width: ${mobileWidth - 0.000000001}px) {
    ${css(...args)}
  }
`;

export const onDesktop = (...args: Parameters<typeof css>): FlattenInterpolation<ThemeProps<any>> => css`
  @media (min-width: ${mobileWidth}px) {
    ${css(...args)}
  }
`;

export const customScrollBar = (color: string, backgroundColor = '#F5F5F5'): string => `
  ::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: ${backgroundColor};
  }
  ::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: lightgray;
    visibility: hidden;
  }
  &:hover::-webkit-scrollbar-thumb {
    visibility: visible;
  }
  ::-webkit-scrollbar{
    width: 10px;
    height: 10px;
    background-color: ${backgroundColor};
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: ${color} ${backgroundColor};
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${customScrollBar('lightgray')}

  body {
    font-family: 'Roboto', sans-serif;
    padding: 0px;
    margin: 0px;
    background-color: ${({ theme }): string => theme.palette.pageBg};
    overscroll-behavior: auto none;
  }

  i {
    text-rendering: optimizeLegibility;
  }
`;

export const flex = ({
  direction,
  align = '',
  justify = '',
}: {
  direction?: 'row' | 'column';
  align?: string;
  justify?: string;
}): FlattenInterpolation<ThemeProps<any>> => css`
  display: flex !important;
  box-sizing: border-box !important;
  ${direction && (direction === 'row' ? 'flex-direction: row !important;' : 'flex-direction: column !important;')}
  ${align && `align-items: ${align} !important;`}
  ${justify && `justify-content: ${justify} !important;`}
`;

export const expand = css`
  bottom: 0px;
  left: 0px;
  right: 0px;
  top: 0px;
`;

export const px = (v: number | string): string => `${v}px`;

// TODO: should update Settings Menu
export const canvasSettingsMenuStyles = {
  maxHeight: `calc(100vh - 226px)`,
};

export const dialogStyles = {
  headerHeight: `min(6.3vh, 64px)`,
};

export const spinningAnimation = (active: boolean): string => `
  animation: ${active ? 'rotation 2s linear infinite' : ''};
  transform-box: fill-box;
  transform-origin: 50% 55% 0;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }`;

export const getAntDesignTheme = (theme: AppTheme): ThemeConfig => ({
  token: {
    colorPrimary: theme?.palette?.accent,
    colorSuccess: theme?.palette?.success,
    colorError: theme?.palette?.error,
    colorWarning: theme?.palette?.warning,
    fontFamily: theme?.fonts.normal,
    colorLink: theme?.palette?.accent,
    colorLinkHover: theme?.palette?.accentHover,
    colorLinkActive: theme?.palette?.accent,
    borderRadius: 4,
  },
});
