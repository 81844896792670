import { ICanvasData } from 'models/ICanvas';
import { IDesignBlock } from 'state_management/reducers/designBlocks/Modals';
import { CanvasVersionType, IDesignCanvas, RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';

export enum SystemDesignCanvasActionTypes {
  GET_DESIGN_CANVAS = 'GET_DESIGN_CANVAS',
  GET_DESIGN_CANVAS_SUCCESS = 'GET_DESIGN_CANVAS_SUCCESS',
  GET_DESIGN_CANVAS_FAIL = 'GET_DESIGN_CANVAS_FAIL',

  SAVE_DESIGN_CANVAS = 'SAVE_DESIGN_CANVAS',
  SAVE_DESIGN_CANVAS_SUCCESS = 'SAVE_DESIGN_CANVAS_SUCCESS',
  SAVE_DESIGN_CANVAS_FAIL = 'SAVE_DESIGN_CANVAS_FAIL',

  RESET_DESIGN_CANVAS = 'RESET_DESIGN_CANVAS',
}

// ============================================ GET_DESIGN_CANVAS

export interface GetDesignCanvasAction {
  payload: {
    version?: string;
    id: string;
  };
  type: SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS;
}

export interface DesignCanvasSuccessPayload {
  id?: string;
  graph?: Array<RappidGraph>;
  canvas?: Partial<IDesignCanvas>;
  library: Array<IDesignBlock>;
}

export interface GetDesignCanvasSuccessAction {
  payload: DesignCanvasSuccessPayload;
  type: SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS_SUCCESS;
}

export interface GetDesignCanvasErrorAction {
  payload: string;
  type: SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS_FAIL;
}

// ============================================ SAVE_DESIGN_CANVAS

export interface SaveDesignCanvasAction {
  payload: {
    data: Partial<ICanvasData<IDesignCanvas>> | Array<RappidGraph>;
    canvasVersion?: CanvasVersionType;
    compileProject?: boolean;
  };
  type: SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS;
}

export interface SaveDesignCanvasSuccessAction {
  payload: string;
  type: SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_SUCCESS;
}

export interface SaveDesignCanvasErrorAction {
  payload: string;
  type: SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_FAIL;
}

// ============================================ RESET_DESIGN_CANVAS

export interface ResetDesignCanvasAction {
  type: SystemDesignCanvasActionTypes.RESET_DESIGN_CANVAS;
}
// ============================================ ALL

export type DesignCanvasActions =
  | GetDesignCanvasAction
  | GetDesignCanvasSuccessAction
  | GetDesignCanvasErrorAction
  | SaveDesignCanvasAction
  | SaveDesignCanvasSuccessAction
  | SaveDesignCanvasErrorAction
  | ResetDesignCanvasAction;
