import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';

import {
  SubSystemCanvasActionTypes,
  SubSystemCanvasActions,
  SaveSubSystemCanvasErrorAction,
} from 'state_management/actions/subSystemCanvas/ActionTypes';

import { globalToast } from 'components/ToastComponent';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const subSystemCreateToasts = (action: SubSystemCanvasActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Creating Sub System, please wait...',
      success: 'The Subsystem has successfully been saved to Orbit.',
      error: (action as SaveSubSystemCanvasErrorAction).payload || 'Creating Sub System failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const subSystemUpdateToasts = (action: SubSystemCanvasActions): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: 'Updating Sub System, please wait...',
      success: 'The Subsystem has successfully been updated.',
      error: (action as SaveSubSystemCanvasErrorAction).payload || 'Updating Sub System failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

const subSystemCloneToasts = (action: SubSystemCanvasActions): void => {
  TOAST_IDS.clone = globalToast(
    TOAST_IDS.clone,
    action.type,
    {
      loading: 'Cloning Sub System, please wait...',
      success: 'The Subsystem has successfully been cloned.',
      error: (action as SaveSubSystemCanvasErrorAction).payload || 'Cloning Sub System failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.clone },
  );
};

const subSystemDeleteToasts = (action: SubSystemCanvasActions): void => {
  TOAST_IDS.delete = globalToast(
    TOAST_IDS.delete,
    action.type,
    {
      loading: 'Deleting Sub System, please wait...',
      success: 'The Subsystem has successfully been deleted.',
      error: (action as SaveSubSystemCanvasErrorAction).payload || 'Deleting Sub System failed, please try again...',
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.delete },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeEvery(
      [
        SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS,
        SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_SUCCESS,
        SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_FAIL,
      ],
      subSystemCreateToasts,
    ),
    takeEvery(
      [
        SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS,
        SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS_SUCCESS,
        SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS_FAIL,
      ],
      subSystemUpdateToasts,
    ),
    takeEvery(
      [
        SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS,
        SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_SUCCESS,
        SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_FAIL,
      ],
      subSystemCloneToasts,
    ),
    takeEvery(
      [
        SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS,
        SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_SUCCESS,
        SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_FAIL,
      ],
      subSystemDeleteToasts,
    ),
  ]);
}
