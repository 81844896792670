import {
  BomModificationActions,
  BomModificationActionTypes,
  GetBomModificationRulesSuccessAction,
  CreateBomModificationRulesSuccessAction,
  UpdateBomModificationRulesSuccessAction,
} from 'state_management/actions/bomModification/ActionTypes';
import { BomModificationReplacementState, CuboComponentsReplacement } from './Modals';

export const initialBomModificationState: BomModificationReplacementState = {
  modificationDialogueState: 'idle',
  bomModificationSaveRequestState: 'idle',
  bomModificationUpdateRequestState: 'idle',
  bomModificationGetRequestState: 'idle',
  rules: [],
};

export const bomModificationReplacementReducer = (
  state: BomModificationReplacementState = initialBomModificationState,
  action: BomModificationActions,
): BomModificationReplacementState => {
  switch (action.type) {
    case BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES:
      return {
        ...state,
        bomModificationGetRequestState: 'loading',
        rules: [],
      };

    case BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_SUCCESS:
      return {
        ...state,
        ruleId: (action as GetBomModificationRulesSuccessAction).payload?.id,
        rules: (action as GetBomModificationRulesSuccessAction).payload?.items?.map(
          (p) =>
            ({
              designBlockId: p.designBlockId,
              selectedCuboId: p.selectedCuboId,
              originalComponentId: p.originalComponentId,
              replacedComponentId: p.replacedComponentId,
              replacementTarget: p.replacementTarget,
              replacedComponentDevice: p.replacedComponentDevice,
              originalComponentDevice: p.originalComponentDevice,
              applied: p.applied,
            } as CuboComponentsReplacement),
        ),
        bomModificationGetRequestState: 'success',
      };

    case BomModificationActionTypes.GET_PROJECT_BOM_MODIFICATION_RULES_FAIL:
      return {
        ...state,
        rules: [],
        bomModificationGetRequestState: 'error',
      };

    case BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE:
      return {
        ...state,
        bomModificationSaveRequestState: 'loading',
      };

    case BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE_SUCCESS:
      return {
        ...state,
        rules: (action as CreateBomModificationRulesSuccessAction).payload?.items?.map(
          (p) =>
            ({
              designBlockId: p.designBlockId,
              selectedCuboId: p.selectedCuboId,
              originalComponentId: p.originalComponentId,
              replacedComponentId: p.replacedComponentId,
              replacementTarget: p.replacementTarget,
            } as CuboComponentsReplacement),
        ),
        bomModificationSaveRequestState: 'success',
        modificationDialogueState: 'deactivated',
      };

    case BomModificationActionTypes.CREATE_BOM_MODIFICATION_RULE_FAIL:
      return {
        ...state,
        rules: [],
        bomModificationSaveRequestState: 'error',
      };

    case BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE:
      return {
        ...state,
        bomModificationUpdateRequestState: 'loading',
      };

    case BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE_FAIL:
      return {
        ...state,
        rules: [],
        bomModificationUpdateRequestState: 'error',
      };

    case BomModificationActionTypes.UPDATE_BOM_MODIFICATION_RULE_SUCCESS:
      return {
        ...state,
        rules: (action as UpdateBomModificationRulesSuccessAction).payload?.items?.map(
          (p) =>
            ({
              designBlockId: p.designBlockId,
              selectedCuboId: p.selectedCuboId,
              originalComponentId: p.originalComponentId,
              replacedComponentId: p.replacedComponentId,
              replacementTarget: p.replacementTarget,
            } as CuboComponentsReplacement),
        ),
        bomModificationUpdateRequestState: 'success',
        modificationDialogueState: 'deactivated',
      };

    case BomModificationActionTypes.RESET_BOM_MODIFICATION_DIALOGUE_STATE:
      return {
        ...state,
        modificationDialogueState: 'idle',
      };

    case BomModificationActionTypes.RESET_PROJECT_BOM_MODIFICATION_RULES:
      return {
        ...initialBomModificationState,
      };

    default:
      return state;
  }
};
