import { DashboardProjectTypes } from 'state_management/reducers/projects/Modals';

export const getProjectType = (
  currentUserId: string,
  projectOwner: string,
  projectShareType: Array<Raw.IShareType> = [],
): DashboardProjectTypes => {
  if (projectOwner === currentUserId) {
    if (projectShareType.includes('private')) {
      return DashboardProjectTypes.PRIVATE;
    }
    return DashboardProjectTypes.SHARED_BY_ME;
  }
  if (projectShareType.includes('private')) {
    return DashboardProjectTypes.ALL;
  }
  return DashboardProjectTypes.SHARED_WITH_ME;
};
