import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum UnitConverterActionTypes {
  GET_ALL_PHYSICAL_QUANTITIES = 'GET_ALL_PHYSICAL_QUANTITIES',
  GET_ALL_PHYSICAL_QUANTITIES_SUCCESS = 'GET_ALL_PHYSICAL_QUANTITIES_SUCCESS',
  GET_ALL_PHYSICAL_QUANTITIES_FAIL = 'GET_ALL_PHYSICAL_QUANTITIES_FAIL',
}

// ============================================ GET_ALL_PHYSICAL_QUANTITIES

export interface GetAllPhysicalQuantitiesAction extends AxiosRequestPayload<void> {
  type: UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES;
}

export interface GetAllPhysicalQuantitiesSuccessAction
  extends AxiosSuccessPayload<
    Raw.IPhysicalQuantity,
    UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES_SUCCESS,
    GetAllPhysicalQuantitiesAction
  > {
  type: UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES_SUCCESS;
}

export interface GetAllPhysicalQuantitiesErrorAction
  extends AxiosErrorPayload<UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES_FAIL, GetAllPhysicalQuantitiesAction> {
  type: UnitConverterActionTypes.GET_ALL_PHYSICAL_QUANTITIES_FAIL;
}

// ============================================ ALL

export type UnitConverterActions =
  | GetAllPhysicalQuantitiesAction
  | GetAllPhysicalQuantitiesSuccessAction
  | GetAllPhysicalQuantitiesErrorAction;
