import { IPagination } from 'models/Pagination';
import { IManufacturer } from 'state_management/reducers/manufacturers/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum ManufacturersActionTypes {
  GET_ALL_MANUFACTURERS = 'GET_ALL_MANUFACTURERS',
  GET_ALL_MANUFACTURERS_SUCCESS = 'GET_ALL_MANUFACTURERS_SUCCESS',
  GET_ALL_MANUFACTURERS_FAIL = 'GET_ALL_MANUFACTURERS_FAIL',

  GET_MANUFACTURER_DEPENDENCIES = 'GET_MANUFACTURER_DEPENDENCIES',

  GET_MANUFACTURER_BY_ID = 'GET_MANUFACTURER_BY_ID',
  GET_MANUFACTURER_BY_ID_SUCCESS = 'GET_MANUFACTURER_BY_ID_SUCCESS',
  GET_MANUFACTURER_BY_ID_FAIL = 'GET_MANUFACTURER_BY_ID_FAIL',

  CREATE_MANUFACTURER = 'CREATE_MANUFACTURER',
  CREATE_MANUFACTURER_SUCCESS = 'CREATE_MANUFACTURER_SUCCESS',
  CREATE_MANUFACTURER_FAIL = 'CREATE_MANUFACTURER_FAIL',

  UPDATE_MANUFACTURER = 'UPDATE_MANUFACTURER',
  UPDATE_MANUFACTURER_SUCCESS = 'UPDATE_MANUFACTURER_SUCCESS',
  UPDATE_MANUFACTURER_FAIL = 'UPDATE_MANUFACTURER_FAIL',

  CLONE_MANUFACTURER = 'CLONE_MANUFACTURER',
  CLONE_MANUFACTURER_SUCCESS = 'CLONE_MANUFACTURER_SUCCESS',
  CLONE_MANUFACTURER_FAIL = 'CLONE_MANUFACTURER_FAIL',

  DELETE_MANUFACTURER = 'DELETE_MANUFACTURER',
  DELETE_MANUFACTURER_SUCCESS = 'DELETE_MANUFACTURER_SUCCESS',
  DELETE_MANUFACTURER_FAIL = 'DELETE_MANUFACTURER_FAIL',
}

// ============================================ GET_ALL_MANUFACTURERS

export interface GetAllManufacturersAction {
  payload: Partial<IPagination>;
  type: ManufacturersActionTypes.GET_ALL_MANUFACTURERS;
}

export interface GetAllManufacturersSuccessAction {
  payload: { pagination: IPagination; data: Array<IManufacturer> };
  type: ManufacturersActionTypes.GET_ALL_MANUFACTURERS_SUCCESS;
}

export interface GetAllManufacturersErrorAction {
  payload: string;
  type: ManufacturersActionTypes.GET_ALL_MANUFACTURERS_FAIL;
}

// ============================================ GET_MANUFACTURER_DEPENDENCIES

export interface GetManufacturerDependenciesAction {
  type: ManufacturersActionTypes.GET_MANUFACTURER_DEPENDENCIES;
}

// ============================================ GET_MANUFACTURER_BY_ID

export interface GetManufacturerByIdAction {
  payload: { id: string };
  type: ManufacturersActionTypes.GET_MANUFACTURER_BY_ID;
}

export interface GetManufacturerByIdSuccessAction {
  payload: IManufacturer;
  type: ManufacturersActionTypes.GET_MANUFACTURER_BY_ID_SUCCESS;
}

export interface GetManufacturerByIdErrorAction {
  payload: string;
  type: ManufacturersActionTypes.GET_MANUFACTURER_BY_ID_FAIL;
}

// ============================================ CREATE_MANUFACTURER

export interface CreateManufacturerAction extends AxiosRequestPayload<Raw.IManufacturer> {
  type: ManufacturersActionTypes.CREATE_MANUFACTURER;
}

export interface CreateManufacturerSuccessAction
  extends AxiosSuccessPayload<
    IManufacturer,
    ManufacturersActionTypes.CREATE_MANUFACTURER_SUCCESS,
    CreateManufacturerAction
  > {
  type: ManufacturersActionTypes.CREATE_MANUFACTURER_SUCCESS;
}

export interface CreateManufacturerErrorAction
  extends AxiosErrorPayload<ManufacturersActionTypes.CREATE_MANUFACTURER_FAIL, CreateManufacturerAction> {
  type: ManufacturersActionTypes.CREATE_MANUFACTURER_FAIL;
}

// ============================================ UPDATE_MANUFACTURER

export interface UpdateManufacturerAction extends AxiosRequestPayload<Raw.IManufacturer> {
  type: ManufacturersActionTypes.UPDATE_MANUFACTURER;
}
export interface UpdateManufacturerSuccessAction
  extends AxiosSuccessPayload<
    IManufacturer,
    ManufacturersActionTypes.UPDATE_MANUFACTURER_SUCCESS,
    UpdateManufacturerAction
  > {
  type: ManufacturersActionTypes.UPDATE_MANUFACTURER_SUCCESS;
}
export interface UpdateManufacturerErrorAction
  extends AxiosErrorPayload<ManufacturersActionTypes.UPDATE_MANUFACTURER_FAIL, UpdateManufacturerAction> {
  type: ManufacturersActionTypes.UPDATE_MANUFACTURER_FAIL;
}

// ============================================ CLONE_MANUFACTURER

export interface CloneManufacturerAction extends AxiosRequestPayload<Raw.IManufacturer> {
  type: ManufacturersActionTypes.CLONE_MANUFACTURER;
}

export interface CloneManufacturerSuccessAction
  extends AxiosSuccessPayload<
    IManufacturer,
    ManufacturersActionTypes.CLONE_MANUFACTURER_SUCCESS,
    CloneManufacturerAction
  > {
  type: ManufacturersActionTypes.CLONE_MANUFACTURER_SUCCESS;
}

export interface CloneManufacturerErrorAction
  extends AxiosErrorPayload<ManufacturersActionTypes.CLONE_MANUFACTURER_FAIL, CloneManufacturerAction> {
  type: ManufacturersActionTypes.CLONE_MANUFACTURER_FAIL;
}

// ============================================ DELETE_MANUFACTURER

export interface DeleteManufacturerAction extends AxiosRequestPayload {
  type: ManufacturersActionTypes.DELETE_MANUFACTURER;
}
export interface DeleteManufacturerSuccessAction
  extends AxiosSuccessPayload<string, ManufacturersActionTypes.DELETE_MANUFACTURER_SUCCESS, DeleteManufacturerAction> {
  type: ManufacturersActionTypes.DELETE_MANUFACTURER_SUCCESS;
}
export interface DeleteManufacturerErrorAction
  extends AxiosErrorPayload<ManufacturersActionTypes.DELETE_MANUFACTURER_FAIL, DeleteManufacturerAction> {
  type: ManufacturersActionTypes.DELETE_MANUFACTURER_FAIL;
}

// ============================================ ALL

export type ManufacturerActions =
  | GetAllManufacturersAction
  | GetAllManufacturersSuccessAction
  | GetAllManufacturersErrorAction
  | GetManufacturerDependenciesAction
  | CreateManufacturerAction
  | CreateManufacturerSuccessAction
  | CreateManufacturerErrorAction
  | UpdateManufacturerAction
  | UpdateManufacturerSuccessAction
  | UpdateManufacturerErrorAction
  | CloneManufacturerAction
  | CloneManufacturerSuccessAction
  | CloneManufacturerErrorAction
  | DeleteManufacturerAction
  | DeleteManufacturerSuccessAction
  | DeleteManufacturerErrorAction
  | GetManufacturerByIdAction
  | GetManufacturerByIdSuccessAction
  | GetManufacturerByIdErrorAction;
