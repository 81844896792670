import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';

import {
  ImplementationActionTypes,
  GetImplementationByIdAction,
  GetImplementationByIdErrorAction,
  GetImplementationByIdSuccessAction,
} from 'state_management/actions/implementations/ActionTypes';
import { AxiosResponse } from 'axios';
import {
  getImplementationByIdSuccess,
  getImplementationByIdError,
  ENDPOINT_IMPLEMENTATIONS,
} from 'state_management/actions/implementations/implementationsActions';
import { serializeImplementation } from 'utils/implementationSerializer';
import { SpecificationsState } from 'state_management/reducers/specifications/Modals';
import { Implementation } from 'state_management/reducers/implementations/Modals';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { ENDPOINT_REQUESTS } from 'state_management/actions/changesRequest/changesRequestActions';

export function* getImplementationFilesSaga(
  implementation: Raw.Implementation,
  changeRequestId?: string,
): Generator<CallEffect<AxiosResponse>> {
  const filesData = [];
  // Keeping this try/catch to support old files and not break that for now
  try {
    const { bom, schematic, pcb } = implementation;
    const filesObject: { bom: Raw.Bom; schematic: Raw.Schematic; pcb: Raw.Pcb } = { bom, schematic, pcb } as {
      bom: Raw.Bom;
      schematic: Raw.Schematic;
      pcb: Raw.Pcb;
    };

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < Object.keys(filesObject).length; i++) {
      const key = Object.keys(filesObject)[i];
      const fileId =
        filesObject[key as keyof typeof filesObject]?.original_file ||
        filesObject[key as keyof typeof filesObject]?.filename;
      if (!fileId) {
        // eslint-disable-next-line no-continue
        continue;
      }

      let rawResFile: AxiosResponse;

      /* 
        First we try to get the files from the implementation since most of the cases it will be successful there
        If we didn't succeed, it is usually the old data of change request or the newly requested data
        and we need to get it from the change request
        Doing try catch because if we don't find the file, axios will throw an error
      */
      try {
        rawResFile = (yield call(() =>
          axiosInstance.get(
            apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS}/${implementation.id}/${key}/${fileId}`, 2),
          ),
        )) as AxiosResponse;
        if (!rawResFile || !rawResFile.data) {
          throw new Error();
        }
      } catch {
        rawResFile = (yield call(() =>
          axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_REQUESTS}/${changeRequestId}/${key}/${fileId}`, 2)),
        )) as AxiosResponse;
      }

      filesData.push(rawResFile?.data);
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('[DEPRECATED] Please re-upload your cubo files to use the new version of file upload');
  }

  return filesData;
}

export function* getByIdSaga(
  action: GetImplementationByIdAction,
): Generator<
  | PutEffect<GetImplementationByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetImplementationByIdSuccessAction | GetImplementationByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_IMPLEMENTATIONS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;
    const specificationsState = (yield select((state) => state.specificationsState)) as SpecificationsState;

    const filesData = (yield call(() => getImplementationFilesSaga(res.data))) as Array<Raw.IMinioResource>;

    const parsedImplementation: Implementation = serializeImplementation(
      res.data,
      specificationsState.specificationsList,
      filesData,
    );

    yield put(getImplementationByIdSuccess(parsedImplementation));
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.log(error);
    }

    yield put(
      getImplementationByIdError(getErrorMessage(error) || 'Fetching implementation failed. Please try again...'),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ImplementationActionTypes.GET_IMPLEMENTATION_BY_ID, getByIdSaga);
}
