import styled from 'styled-components';

export const PoweredByCelusLogo = styled.img<{
  hasStatusBar?: boolean;
}>`
  position: absolute;
  ${({ hasStatusBar, theme }): string => (!hasStatusBar ? `bottom: ${theme.spacing.screenContent}px;` : 'top: 0;')}
  right: ${({ theme }): number => theme.spacing.screenContent}px;
  box-shadow: ${({ theme }): string => theme.shadow.normal};
  background-color: white;
  box-sizing: border-box;
  border-radius: 4px;
  height: 42px;
  padding: 6px 12px;
`;
