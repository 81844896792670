import { IBlockV2 } from 'models/ICanvas';
import { IPagination } from 'models/Pagination';
import { AxiosErrorPayload, AxiosRequestPayload, AxiosSuccessPayload } from 'state_management/middlewares/axios/Modals';
import { ISubSystem } from 'state_management/reducers/subSystemCanvas/Modals';

export enum SubSystemCanvasActionTypes {
  SET_SELECTED_BLOCK = 'SET_SELECTED_BLOCK',
  CLEAR_SELECTED_BLOCK = 'CLEAR_SELECTED_BLOCK',

  GET_ALL_SUB_SYSTEMS = 'GET_ALL_SUB_SYSTEMS',
  GET_ALL_SUB_SYSTEMS_SUCCESS = 'GET_ALL_SUB_SYSTEMS_SUCCESS',
  GET_ALL_SUB_SYSTEMS_FAIL = 'GET_ALL_SUB_SYSTEMS_FAIL',

  GET_SUB_SYSTEM_CANVAS = 'GET_SUB_SYSTEM_CANVAS',
  GET_SUB_SYSTEM_CANVAS_SUCCESS = 'GET_SUB_SYSTEM_CANVAS_SUCCESS',
  GET_SUB_SYSTEM_CANVAS_FAIL = 'GET_SUB_SYSTEM_CANVAS_FAIL',

  SAVE_SUB_SYSTEM_CANVAS = 'SAVE_SUB_SYSTEM_CANVAS',
  SAVE_SUB_SYSTEM_CANVAS_SUCCESS = 'SAVE_SUB_SYSTEM_CANVAS_SUCCESS',
  SAVE_SUB_SYSTEM_CANVAS_FAIL = 'SAVE_SUB_SYSTEM_CANVAS_FAIL',

  UPDATE_SUB_SYSTEM_CANVAS = 'UPDATE_SUB_SYSTEM_CANVAS',
  UPDATE_SUB_SYSTEM_CANVAS_SUCCESS = 'UPDATE_SUB_SYSTEM_CANVAS_SUCCESS',
  UPDATE_SUB_SYSTEM_CANVAS_FAIL = 'UPDATE_SUB_SYSTEM_CANVAS_FAIL',

  SAVE_SELECTED_ALTERNATIVE = 'SAVE_SELECTED_ALTERNATIVE',
  SAVE_SELECTED_ALTERNATIVE_SUCCESS = 'SAVE_SELECTED_ALTERNATIVE_SUCCESS',
  SAVE_SELECTED_ALTERNATIVE_FAIL = 'SAVE_SELECTED_ALTERNATIVE_FAIL',

  CLONE_SUB_SYSTEM_CANVAS = 'CLONE_SUB_SYSTEM_CANVAS',
  CLONE_SUB_SYSTEM_CANVAS_SUCCESS = 'CLONE_SUB_SYSTEM_CANVAS_SUCCESS',
  CLONE_SUB_SYSTEM_CANVAS_FAIL = 'CLONE_SUB_SYSTEM_CANVAS_FAIL',

  DELETE_SUB_SYSTEM_CANVAS = 'DELETE_SUB_SYSTEM_CANVAS',
  DELETE_SUB_SYSTEM_CANVAS_SUCCESS = 'DELETE_SUB_SYSTEM_CANVAS_SUCCESS',
  DELETE_SUB_SYSTEM_CANVAS_FAIL = 'DELETE_SUB_SYSTEM_CANVAS_FAIL',

  GET_DESIGN_BLOCK_ALTERNATIVE = 'GET_DESIGN_BLOCK_ALTERNATIVE',

  RESET_SUB_SYSTEM_CANVAS = 'RESET_SUB_SYSTEM_CANVAS',
}

// ============================================ SET_SELECTED_BLOCK

export interface SetSelectedBlockAction {
  payload: IBlockV2;
  type: SubSystemCanvasActionTypes.SET_SELECTED_BLOCK;
}

export interface ClearSelectedBlockAction {
  type: SubSystemCanvasActionTypes.CLEAR_SELECTED_BLOCK;
}

// ============================================ GET_ALL_SUB_SYSTEMS

export interface GetAllSubSystemsAction {
  payload: Partial<IPagination & { selectedWorkspaces?: string[] }>;
  type: SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS;
}

export interface GetAllSubSystemsSuccessAction {
  payload: {
    pagination: IPagination;
    data: Array<Partial<ISubSystem>>;
  };
  type: SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_SUCCESS;
}

export interface GetAllSubSystemsErrorAction {
  payload: string;
  type: SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_FAIL;
}

// ============================================ GET_SUB_SYSTEM_CANVAS

export interface GetSubSystemCanvasAction {
  payload: string;
  type: SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS;
}

export interface GetSubSystemCanvasSuccessAction {
  payload: ISubSystem;
  type: SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_SUCCESS;
}

export interface GetSubSystemCanvasErrorAction {
  payload: string;
  type: SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_FAIL;
}

// ============================================ SAVE_SUB_SYSTEM_CANVAS

export interface SaveSubSystemCanvasAction {
  payload: Partial<ISubSystem>;
  type: SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS;
}

export interface SaveSubSystemCanvasSuccessAction {
  payload: Partial<ISubSystem>;
  type: SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_SUCCESS;
}

export interface SaveSubSystemCanvasErrorAction {
  payload: string;
  type: SubSystemCanvasActionTypes.SAVE_SUB_SYSTEM_CANVAS_FAIL;
}

// ============================================ UPDATE_SUB_SYSTEM_CANVAS

export interface UpdateSubSystemCanvasAction {
  payload: Partial<ISubSystem>;
  type: SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS;
}

export interface UpdateSubSystemCanvasSuccessAction {
  payload: ISubSystem;
  type: SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS_SUCCESS;
}

export interface UpdateSubSystemCanvasErrorAction {
  payload: string;
  type: SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS_FAIL;
}

// ============================================ SAVE_SELECTED_ALTERNATIVE

export interface SaveSelectedAlternativeAction {
  payload: {
    alternativeId: string;
    subSystemBlockId?: string;
  };
  type: SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE;
}

export interface SaveSelectedAlternativeSuccessAction {
  type: SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE_SUCCESS;
}

export interface SaveSelectedAlternativeErrorAction {
  payload: string;
  type: SubSystemCanvasActionTypes.SAVE_SELECTED_ALTERNATIVE_FAIL;
}

// ============================================ CLONE_SUB_SYSTEM_CANVAS

export interface CloneSubSystemCanvasAction extends AxiosRequestPayload<Partial<RawSubSystemCanvas.Payload>> {
  type: SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS;
}

export interface CloneSubSystemCanvasSuccessAction
  extends AxiosSuccessPayload<
    RawSubSystemCanvas.Payload,
    SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_SUCCESS,
    CloneSubSystemCanvasAction
  > {
  type: SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_SUCCESS;
}

export interface CloneSubSystemCanvasErrorAction
  extends AxiosErrorPayload<SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_FAIL, CloneSubSystemCanvasAction> {
  type: SubSystemCanvasActionTypes.CLONE_SUB_SYSTEM_CANVAS_FAIL;
}

// ============================================ DELETE_SUB_SYSTEM_CANVAS

export interface DeleteSubSystemCanvasAction extends AxiosRequestPayload {
  type: SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS;
}
export interface DeleteSubSystemCanvasSuccessAction
  extends AxiosSuccessPayload<
    string,
    SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_SUCCESS,
    DeleteSubSystemCanvasAction
  > {
  type: SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_SUCCESS;
}
export interface DeleteSubSystemCanvasErrorAction
  extends AxiosErrorPayload<SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_FAIL, DeleteSubSystemCanvasAction> {
  type: SubSystemCanvasActionTypes.DELETE_SUB_SYSTEM_CANVAS_FAIL;
}

// ============================================ GET_DESIGN_BLOCK_ALTERNATIVE

export interface GetDesignBlockAlternativeAction {
  payload: string;
  type: SubSystemCanvasActionTypes.GET_DESIGN_BLOCK_ALTERNATIVE;
}

// ============================================ RESET_SUB_SYSTEM_CANVAS

export interface ResetSubSystemAction {
  type: SubSystemCanvasActionTypes.RESET_SUB_SYSTEM_CANVAS;
}
// ============================================ ALL

export type SubSystemCanvasActions =
  | SetSelectedBlockAction
  | ClearSelectedBlockAction
  | GetAllSubSystemsAction
  | GetAllSubSystemsSuccessAction
  | GetAllSubSystemsErrorAction
  | GetSubSystemCanvasAction
  | GetSubSystemCanvasSuccessAction
  | GetSubSystemCanvasErrorAction
  | SaveSubSystemCanvasAction
  | SaveSubSystemCanvasSuccessAction
  | SaveSubSystemCanvasErrorAction
  | SaveSelectedAlternativeAction
  | SaveSelectedAlternativeSuccessAction
  | SaveSelectedAlternativeErrorAction
  | GetDesignBlockAlternativeAction
  | UpdateSubSystemCanvasAction
  | UpdateSubSystemCanvasSuccessAction
  | UpdateSubSystemCanvasErrorAction
  | CloneSubSystemCanvasAction
  | CloneSubSystemCanvasSuccessAction
  | CloneSubSystemCanvasErrorAction
  | DeleteSubSystemCanvasAction
  | DeleteSubSystemCanvasSuccessAction
  | DeleteSubSystemCanvasErrorAction
  | ResetSubSystemAction;
