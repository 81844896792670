import { AxiosError } from 'axios';

const messageCodeToText = (message: string | undefined): string => {
  try {
    return (
      message
        ?.replace(/_/g, ' ')
        .trim()
        .replace(/^\w/, (c) => c.toUpperCase()) || ''
    );
  } catch (error) {
    return '';
  }
};

export const getErrorMessage = (error: unknown): string => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('==================================');
    console.log(error);
    console.log('==================================');
  }

  const payload = (error as AxiosError | undefined)?.response?.data || error;

  return messageCodeToText(payload?.message || payload?.errorMessage || payload || '');
};
