import { OptionModel } from 'components/Select/Modals';
import { ITemplateField } from 'state_management/reducers/exportTemplates/Modals';

export type ITemplateFieldWithLabel = ITemplateField & { label: string };

export interface IRouteInfo {
  exportSchema: Record<string, ITemplateFieldWithLabel>;
  sheetNameFrom: Array<OptionModel>;
}

export const SHEET_NAME_FROM_NONE = 'None';
