import {
  SystemDesignCanvasActionTypes,
  DesignCanvasActions,
} from 'state_management/actions/systemDesignCanvas/ActionTypes';
import { SystemDesignCanvasState } from './Modals';

export const initialSystemDesignCanvasState: SystemDesignCanvasState = {
  id: '',
  graph: [],
  canvas: {
    uiOptions: {
      grid: {
        display: '',
        type: 'norm_w',
        value: 0,
      },
      jumpover: true,
      router: 'manhattan',
    },
    blocks: [],
    links: [],
    settings: [],
  },
  library: [],
  designCanvasRequest: 'idle',
  designCanvasError: '',
  saveDesignCanvasRequest: 'idle',
  saveDesignCanvasError: '',
};

export const systemDesignCanvasReducer = (
  state: SystemDesignCanvasState = initialSystemDesignCanvasState,
  action: DesignCanvasActions,
): SystemDesignCanvasState => {
  switch (action.type) {
    case SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS:
      return {
        ...state,
        designCanvasRequest: 'loading',
        designCanvasError: '',
      };
    case SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS_SUCCESS:
      return {
        ...state,
        id: action.payload.id || '',
        graph: action.payload.graph || state.graph,
        canvas: { ...state.canvas, ...(action.payload.canvas || {}), readOnly: false },
        library: action.payload.library || state.library,
        designCanvasRequest: 'success',
        designCanvasError: '',
      };
    case SystemDesignCanvasActionTypes.GET_DESIGN_CANVAS_FAIL:
      return {
        ...state,
        designCanvasRequest: 'error',
        designCanvasError: action.payload,
      };
    case SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS:
      return {
        ...state,
        saveDesignCanvasRequest: 'loading',
        saveDesignCanvasError: '',
      };
    case SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_SUCCESS:
      return {
        ...state,
        id: action.payload,
        saveDesignCanvasRequest: 'success',
        saveDesignCanvasError: '',
      };
    case SystemDesignCanvasActionTypes.SAVE_DESIGN_CANVAS_FAIL:
      return {
        ...state,
        saveDesignCanvasRequest: 'error',
        saveDesignCanvasError: action.payload,
      };
    case SystemDesignCanvasActionTypes.RESET_DESIGN_CANVAS:
      return initialSystemDesignCanvasState;
    default:
      return state;
  }
};
