import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import { ExportJobsActionTypes, GetAllExportJobsAction } from 'state_management/actions/exportJobs/ActionTypes';
import { getAllExportJobsAction } from 'state_management/actions/exportJobs/exportJobsActions';

export function* refreshExportJobs(): Generator<PutEffect<GetAllExportJobsAction>> {
  yield put(getAllExportJobsAction({ queryType: 'paginated' }));
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ExportJobsActionTypes.CREATE_EXPORT_JOB_SUCCESS,
      ExportJobsActionTypes.UPDATE_EXPORT_JOB_SUCCESS,
      ExportJobsActionTypes.CLONE_EXPORT_JOB_SUCCESS,
      ExportJobsActionTypes.DELETE_EXPORT_JOB_SUCCESS,
      ExportJobsActionTypes.RUN_EXPORT_JOB_SUCCESS,
    ],
    refreshExportJobs,
  );
}
