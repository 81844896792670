import { assetUri } from 'services/utils';
import { PoweredByCelusLogo } from './styles';
import { IProps } from './IProps';

const PoweredByCelus = ({ hasStatusBar = false, poweredByCelus = false }: IProps): JSX.Element => (
  <>
    {poweredByCelus ? (
      <PoweredByCelusLogo
        hasStatusBar={hasStatusBar}
        data-testid="powered-by-celus-icon"
        src={assetUri('/img/powered-celus-right-aligned.svg')}
      />
    ) : null}
  </>
);

export default PoweredByCelus;
