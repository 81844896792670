import { PutEffect, ForkEffect, CallEffect, put, takeEvery, call, select, SelectEffect } from 'redux-saga/effects';
import { AxiosError, AxiosResponse } from 'axios';

import {
  SubSystemCanvasActionTypes,
  UpdateSubSystemCanvasAction,
  UpdateSubSystemCanvasErrorAction,
  UpdateSubSystemCanvasSuccessAction,
} from 'state_management/actions/subSystemCanvas/ActionTypes';
import {
  ENDPOINT_SUB_SYSTEM_CANVAS,
  updateSubSystemCanvasErrorAction,
  updateSubSystemCanvasSuccessAction,
} from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';

import { axiosInstance } from 'services/dataService';
import { getErrorMessage } from 'utils/getErrorMessage';
import { deSerializeSubSystemCanvas, serializeSubSystemCanvas } from 'utils/serializers/subSystemCanvasSerializer';
import { apiUri } from 'services/main_app';
import {
  errorTypeToMessage,
  isSubSystemCanvasSaveDataValid,
  sanitizeErrorMessage,
  subsystemSaveErrors,
} from 'utils/validators/subSystemValidator';
import { AppState } from 'state_management/AppState';
import i18n from '../../../i18n/config';

export function* updateSaga(
  action: UpdateSubSystemCanvasAction,
): Generator<
  | CallEffect<AxiosResponse<Partial<RawSubSystemCanvas.Payload>>>
  | PutEffect<UpdateSubSystemCanvasSuccessAction | UpdateSubSystemCanvasErrorAction>
  | SelectEffect
> {
  const subSystem = action.payload;
  const store = (yield select((state) => state)) as AppState;

  const { isValid, errorType, corruptedObject } = isSubSystemCanvasSaveDataValid(subSystem, store) || {};
  const t = i18n.t.bind(i18n);
  try {
    if (!isValid) {
      const errorMessageObject = errorTypeToMessage[errorType || ''];
      if (errorMessageObject?.key) {
        const corruptedObjectName =
          typeof corruptedObject === 'string' ? corruptedObject : corruptedObject?.objectName || '';
        const errorMessage = t(errorMessageObject.key, errorMessageObject.errorMessage);
        const sanitizedErrorMessage = sanitizeErrorMessage(
          errorMessage,
          errorType as subsystemSaveErrors,
          corruptedObjectName,
        );
        yield put(updateSubSystemCanvasErrorAction(sanitizedErrorMessage));
        return;
      }
    }
    const res = (yield call(() =>
      axiosInstance.patch(apiUri(`/dataservice/${ENDPOINT_SUB_SYSTEM_CANVAS}/${subSystem.id}`, 2), {
        ...deSerializeSubSystemCanvas(subSystem),
        created_at: undefined,
      }),
    )) as AxiosResponse<RawSubSystemCanvas.Payload>;

    yield put(updateSubSystemCanvasSuccessAction(serializeSubSystemCanvas(res?.data)));
  } catch (error) {
    const subsystemUnknownError = t(
      'supernova:subSystemCanvas.update.validation.subSystem.unknownError',
      'Updating Sub System Canvas failed.',
    );
    yield put(updateSubSystemCanvasErrorAction(getErrorMessage(error as AxiosError) || subsystemUnknownError));
  }
}

export function* updateSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(SubSystemCanvasActionTypes.UPDATE_SUB_SYSTEM_CANVAS, updateSaga);
}
