import { all, AllEffect } from 'redux-saga/effects';
import { getAllWatcher } from './getAllSaga';
import { getAllByLinkWatcher } from './getAllByLinkSaga';
import { refreshWatcher } from './refreshSaga';
import { getByIdWatcher } from './getByIdSaga';
import { requestsToastsWatcher } from './requestsToastsSaga';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* categoriesRootSaga(): Generator<AllEffect<any>> {
  yield all([getAllWatcher(), getAllByLinkWatcher(), refreshWatcher(), getByIdWatcher(), requestsToastsWatcher()]);
}
