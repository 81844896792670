import styled from 'styled-components';
import MUIInfoIcon from '@material-ui/icons/InfoOutlined';

const InfoIcon = styled(MUIInfoIcon)`
  && {
    color: #1d1d1b;
  }
  &:hover {
    color: #004c97;
  }
`;

export default InfoIcon;
