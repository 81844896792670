/* eslint-disable no-use-before-define */
/* eslint-disable eqeqeq */
/* eslint-disable no-else-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-lonely-if */

import React, { useEffect, useState, useMemo, Suspense, lazy } from 'react';
import Button from 'components/Button';
import AutoComplete from 'components/Autocomplete';
import Loader from 'components/Loader';
import { OptionModel } from 'components/Select/Modals';
import { SpecificationValueType } from 'state_management/reducers/specifications/Modals';
import { convertUnit } from 'utils/specificationsSerializer';
import { AdvancedSearchFilterType } from 'views/BlockLibrary/BlockLibraryList/Modals';
import { Container, DownContainer, ResetContainer, InputsContainer, FieldContainer } from './styles';
import { IProps } from './IProps';
import {
  UnitValue,
  SearchFieldsOptions,
  SearchValue,
  Operator,
  MultipleValuesSearchValue,
  MinMaxSearchValue,
  TypToleranceSearchValue,
  SimpleSearchValue,
  BetweenSearchValue,
  OperationData,
} from '../Modals';
import {
  getOperatorDisplayLabel,
  getOperatorDisplayLabelMinMax,
  getOperatorDisplayLabelTypTol,
} from './utils/getChipLabel';
import {
  MinMaxTypAddButtonValidator,
  MinMaxTypOnlyEqualAddButtonValidator,
  MultipleValuesAddButtonValidator,
  SingleValueAddButtonValidator,
  SpecAddButtonValidator,
  TypTolAddButtonValidator,
  TypTolOnlyEqualAddButtonValidator,
} from './utils/SpecAddButtonValidator';

const SingleValueSearch = lazy(() => import('../SingleValueSearch/SingleValueSearch'));
const MultipleValuesSearch = lazy(() => import('../MultipleValuesSearch/MultipleValuesSearch'));
const MinMaxValueSearch = lazy(() => import('../MinMaxValueSearch/MinMaxValueSearch'));
const TypToleranceValuesSearch = lazy(() => import('../TypToleranceValuesSearch/TypToleranceValuesSearch'));

export const OPERATORS: { [key: string]: Operator } = {
  '-i-contains-': {
    label: 'Contains',
    value: '-i-contains-',
    types: ['string'],
    chipDisplayLabel: 'contains',
    valueType: 'string',
  },
  '-not-i-contains-': {
    label: "Doesn't Contain",
    value: '-not-i-contains-',
    types: ['string'],
    chipDisplayLabel: "doesn't contain",
    valueType: 'string',
  },
  is: {
    label: 'Is',
    value: 'is',
    types: ['string', 'select', 'dynamic_breadcrumb', 'reference'],
    chipDisplayLabel: '=',
    valueType: 'string',
  },
  isNot: {
    label: 'Is Not',
    value: 'isNot',
    types: ['string', 'select', 'dynamic_breadcrumb', 'reference'],
    chipDisplayLabel: '!=',
    valueType: 'string',
  },
  '-contains-all-': {
    label: 'Contains all',
    value: '-contains-all-',
    types: ['multi_select'],
    chipDisplayLabel: 'includes',
    valueType: 'list',
  },
  '-not-contains-all-': {
    label: 'Not Contains all',
    value: '-not-contains-all-',
    types: ['multi_select'],
    chipDisplayLabel: "doesn't include",
    valueType: 'list',
  },
  '-in-': {
    label: 'Is One Of',
    value: '-in-',
    types: ['multi_select'],
    chipDisplayLabel: 'includes one of',
    valueType: 'list',
  },
  '-nin-': {
    label: 'Is Not One Of',
    value: '-nin-',
    types: ['multi_select'],
    chipDisplayLabel: "doesn't include one of",
    valueType: 'list',
  },
  '=': { label: 'Equals', value: '=', types: ['number'], chipDisplayLabel: '=', valueType: 'number' },
  '!=': { label: 'Not equal to', value: '!=', types: ['number'], chipDisplayLabel: '!=', valueType: 'number' },
  '>': { label: 'Greater than', value: '>', types: ['number'], chipDisplayLabel: '>', valueType: 'number' },
  '<': { label: 'Less than', value: '<', types: ['number'], chipDisplayLabel: '<', valueType: 'number' },
  '>=': {
    label: 'Greater than or equal to',
    value: '>=',
    types: ['number'],
    chipDisplayLabel: '>=',
    valueType: 'number',
  },
  '<=': {
    label: 'Less than or equal to',
    value: '<=',
    types: ['number'],
    chipDisplayLabel: '<=',
    valueType: 'number',
  },
  between: {
    label: 'Between',
    value: 'between',
    types: ['number'],
    chipDisplayLabel: '=',
    valueType: 'number',
  },
  '-exists': {
    label: 'Exists',
    value: '-exists',
    types: ['boolean'],
    chipDisplayLabel: 'exists',
    valueType: 'boolean',
  },
  '-not-exists': {
    label: "Doesn't Exists",
    value: '-not-exists',
    types: ['boolean'],
    chipDisplayLabel: "doesn't exist",
    valueType: 'boolean',
  },
};

const AdvancedSearchForm = ({
  featurePermissions,
  fields,
  initialValues: _initialValues,
  submitText,
  fieldTypeLabel,
  deleteElement,
  onlyEqual,
  withUnitConversion,
  onSave,
}: IProps): JSX.Element => {
  /* USE STATES */
  const [formValues, setFormValues] = useState<Partial<AdvancedSearchFilterType>>(
    { ..._initialValues, userValues: _initialValues?.userValues || _initialValues?.values } || {},
  );
  const [fieldName, setFieldName] = useState(_initialValues?.parentField?.value || _initialValues?.field?.value || '');
  const [nestedFieldName, setNestedFieldName] = useState(
    _initialValues?.parentField ? _initialValues?.field?.value || '' : '',
  );
  const [inputType, setInputType] = useState<SpecificationValueType | undefined>(undefined);

  /* USE EFFECTS */
  useEffect(() => {
    _initialValues &&
      setFormValues({ ..._initialValues, userValues: _initialValues?.userValues || _initialValues?.values });
    setFieldName(_initialValues?.parentField?.value || _initialValues?.field?.value || '');
    setNestedFieldName(_initialValues?.parentField ? _initialValues?.field?.value || '' : '');
    setInputType(_initialValues?.field?.inputType);
  }, [_initialValues]);

  /* USE MEMO */
  const fieldsMap = useMemo(() => {
    const map: Record<string, SearchFieldsOptions> = {};
    fields?.forEach((opt: SearchFieldsOptions) => {
      if (opt.type === 'nested') {
        opt.options?.forEach((nestedOpt: string | number | SearchFieldsOptions) => {
          map[(nestedOpt as SearchFieldsOptions).value] = nestedOpt as SearchFieldsOptions;
        });
      }
      map[opt.value] = opt;
    });
    return map;
  }, [fields]);

  const reset = (): void => {
    setFieldName('');
    setNestedFieldName('');
    setFormValues({ id: formValues.id });
  };

  const handleSetFieldName = (value: string): void => {
    setFieldName(value);
    setNestedFieldName('');
    setFormValues({
      id: formValues.id,
      field: { ...fieldsMap[nestedFieldName || value], inputType: fieldsMap[value]?.inputType },
      parentField: nestedFieldName ? fieldsMap[value] : undefined,
      operator: onlyEqual ? OPERATORS[fieldsMap[value]?.type === 'number' ? '=' : 'is'] : formValues.operator,
    });
    setInputType(fieldsMap[value]?.inputType);
  };

  const handleSetNestedFieldName = (value: string): void => {
    const _nestedFieldName = nestedFieldName;
    setNestedFieldName(value);
    setFormValues({
      id: formValues.id,
      field: { ...fieldsMap[_nestedFieldName || fieldName], inputType: fieldsMap[value]?.inputType },
      parentField: _nestedFieldName ? fieldsMap[fieldName] : undefined,
    });
    setInputType(fieldsMap[value]?.inputType);
  };

  const handleSetOperator = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setFormValues({ ...formValues, operator: { ...formValues.operator, value: event.target.value } as Operator });
  };

  const handleSetValue =
    (
      valueType:
        | 'string'
        | 'number'
        | 'autocomplete'
        | 'chipSelect'
        | 'checkbox'
        | 'min'
        | 'max'
        | 'minUnit'
        | 'maxUnit'
        | 'numberUnit'
        | 'select'
        | 'dynamicBreadCrumb',
    ) =>
    (
      event:
        | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
        | string
        | number
        | boolean
        | Array<string>,
    ): void => {
      /* Never use strict comparison for the next block of code. Using == is completely intentional */
      const specOptionValue = fieldsMap[nestedFieldName || fieldName]?.options?.find(
        (opt) =>
          ((opt as OptionModel)?.value || opt)?.toString() ==
          (event as React.ChangeEvent<HTMLSelectElement>)?.target?.value,
      );

      let _value: SearchValue | undefined;

      switch (valueType) {
        case 'string':
          _value = { value: (event as React.ChangeEvent<HTMLInputElement>)?.target?.value };
          break;
        case 'number':
          _value = {
            ...(formValues.userValues as UnitValue),
            value: (event as React.ChangeEvent<HTMLInputElement>)?.target?.valueAsNumber,
          };
          break;
        case 'numberUnit':
          _value = {
            ...(formValues.userValues as UnitValue),
            unit: (event as React.ChangeEvent<HTMLInputElement>)?.target?.value,
          };
          break;
        case 'autocomplete':
          _value = { value: event as string };
          break;
        case 'chipSelect':
          _value = { value: event as Array<string> };
          break;
        case 'checkbox':
          if (Array.isArray((formValues.userValues as SearchValue)?.value)) {
            if ((((formValues.userValues as SearchValue)?.value as string[]) || []).includes(event as string)) {
              _value = {
                value: ((formValues.userValues as SearchValue)?.value as Array<string>)?.filter(
                  (v) => v !== (event as string),
                ),
              };
            } else {
              _value = {
                value: [...((formValues.userValues as SearchValue)?.value as Array<string>), event as string],
              };
            }
          } else {
            _value = { value: [event as string] };
          }
          break;
        case 'min':
        case 'max':
          // eslint-disable-next-line no-case-declarations
          const _event = event as React.ChangeEvent<HTMLInputElement>;
          _value = {
            ...(formValues.userValues as SearchValue),
            [valueType.startsWith('max') ? 'max' : 'value']: _event?.target?.valueAsNumber || _event?.target?.value,
          };
          break;
        case 'minUnit':
        case 'maxUnit':
          _value = {
            ...(formValues.userValues as SearchValue),
            // NOTE: Because of some async issue keeping them the same for now
            unit: (event as React.ChangeEvent<HTMLInputElement>)?.target?.value,
            maxUnit: (event as React.ChangeEvent<HTMLInputElement>)?.target?.value,
          };
          break;
        case 'select':
          _value = { value: ((specOptionValue as OptionModel)?.value || specOptionValue) as string | number };
          break;
        case 'dynamicBreadCrumb':
          fieldsMap[nestedFieldName || fieldName].extra?.handleDynamicBreadCrumbSelect({
            linkType: 'child',
            linkedResourceId: event,
          });
          _value = { value: event as string };
          break;

        default:
          _value = undefined;
      }
      setFormValues({ ...formValues, userValues: _value });
    };

  const handleMultipleValuesSearchSetValue = (multipleValues: MultipleValuesSearchValue): void => {
    setFormValues({
      ...formValues,
      userValues: { ...formValues?.userValues, value: multipleValues.value } as MultipleValuesSearchValue,
    });
  };

  const handleMultipleValuesSearchSetUnit = (unit: string): void => {
    setFormValues({
      ...formValues,
      userValues: {
        ...(formValues.userValues as MultipleValuesSearchValue),
        unit,
      },
    });
  };

  const handleMinMaxValuesSearchSetValue = (
    data: SimpleSearchValue | BetweenSearchValue,
    part: keyof MinMaxSearchValue,
  ): void => {
    const minMaxSearchValue = formValues.userValues as MinMaxSearchValue;
    let updatedData: Partial<AdvancedSearchFilterType>;
    if (!onlyEqual) {
      updatedData = {
        ...formValues,
        userValues: {
          min: part === 'min' ? { ...minMaxSearchValue?.min, data } : minMaxSearchValue?.min,
          max: part === 'max' ? { ...minMaxSearchValue?.max, data } : minMaxSearchValue?.max,
          typ: part === 'typ' ? { ...minMaxSearchValue?.typ, data } : minMaxSearchValue?.typ,
        },
      };
    } else {
      const unifiedUnit = (data as SimpleSearchValue)?.unit;
      updatedData = {
        ...formValues,
        userValues: {
          min:
            part === 'min'
              ? { ...minMaxSearchValue?.min, data: { ...data, unit: unifiedUnit } as SimpleSearchValue }
              : ({
                  ...minMaxSearchValue?.min,
                  data: {
                    value: ((minMaxSearchValue?.min as OperationData)?.data as SimpleSearchValue)?.value,
                    unit: unifiedUnit,
                  },
                } as OperationData),
          max:
            part === 'max'
              ? { ...minMaxSearchValue?.max, data: { ...data, unit: unifiedUnit } as SimpleSearchValue }
              : ({
                  ...minMaxSearchValue?.max,
                  data: {
                    value: ((minMaxSearchValue?.max as OperationData)?.data as SimpleSearchValue)?.value,
                    unit: unifiedUnit,
                  },
                } as OperationData),
          typ:
            part === 'typ'
              ? { ...minMaxSearchValue?.typ, data: { ...data, unit: unifiedUnit } as SimpleSearchValue }
              : ({
                  ...minMaxSearchValue?.typ,
                  data: {
                    value: ((minMaxSearchValue?.typ as OperationData)?.data as SimpleSearchValue)?.value,
                    unit: unifiedUnit,
                  },
                } as OperationData),
        },
      };
    }
    setFormValues(updatedData);
  };

  const handleTypTolValuesSearchSetValue = (
    data: SimpleSearchValue | BetweenSearchValue,
    part: keyof TypToleranceSearchValue,
  ): void => {
    const typTol = formValues.userValues as TypToleranceSearchValue;
    let updatedData: Partial<AdvancedSearchFilterType>;
    if (!onlyEqual) {
      updatedData = {
        ...formValues,
        userValues: {
          typ: part === 'typ' ? { ...typTol?.typ, data } : typTol?.typ,
          toleranceMin: part === 'toleranceMin' ? { ...typTol?.toleranceMin, data } : typTol?.toleranceMin,
          toleranceMax: part === 'toleranceMax' ? { ...typTol?.toleranceMax, data } : typTol?.toleranceMax,
        },
      };
    } else {
      if (inputType === 'tolerance_percentage') {
        const unifiedUnit = (data as SimpleSearchValue)?.unit;
        if (part === 'typ') {
          updatedData = {
            ...formValues,
            userValues: {
              typ: { ...typTol?.typ, data },
              toleranceMin: typTol?.toleranceMin,
              toleranceMax: typTol?.toleranceMax,
            },
          };
        } else {
          updatedData = {
            ...formValues,
            userValues: {
              typ: typTol?.typ,
              toleranceMin: ((): OperationData | undefined => {
                if (part === 'toleranceMin') {
                  let value = (data as SimpleSearchValue)?.value;
                  value = onlyEqual && value === undefined ? 0 : value;
                  return { ...typTol?.toleranceMin, data: { value, unit: unifiedUnit } };
                } else {
                  if (typTol?.toleranceMin) {
                    let value = (typTol?.toleranceMin?.data as SimpleSearchValue)?.value;
                    value = onlyEqual && value === undefined ? 0 : value;
                    return {
                      ...typTol?.toleranceMin,
                      data: { value, unit: unifiedUnit },
                    };
                  }
                  return undefined;
                }
              })(),
              toleranceMax: ((): OperationData | undefined => {
                if (part === 'toleranceMax') {
                  let value = (data as SimpleSearchValue)?.value;
                  value = onlyEqual && value === undefined ? 0 : value;
                  return { ...typTol?.toleranceMax, data: { value, unit: unifiedUnit } };
                } else {
                  if (typTol?.toleranceMax) {
                    let value = (typTol?.toleranceMax?.data as SimpleSearchValue)?.value;
                    value = onlyEqual && value === undefined ? 0 : value;
                    return {
                      ...typTol?.toleranceMax,
                      data: { value, unit: unifiedUnit },
                    };
                  }
                  return undefined;
                }
              })(),
            },
          };
        }
      } else {
        const unifiedUnit = (data as SimpleSearchValue)?.unit;
        updatedData = {
          ...formValues,
          userValues: {
            typ:
              part === 'typ'
                ? { ...typTol?.typ, data: { value: (data as SimpleSearchValue)?.value, unit: unifiedUnit } }
                : typTol?.typ
                ? {
                    ...typTol?.typ,
                    data: { value: (typTol?.typ?.data as SimpleSearchValue)?.value, unit: unifiedUnit },
                  }
                : undefined,
            toleranceMin: ((): OperationData | undefined => {
              if (part === 'toleranceMin') {
                let value = (data as SimpleSearchValue)?.value;
                value = onlyEqual && value === undefined ? 0 : value;
                return { ...typTol?.toleranceMin, data: { value, unit: unifiedUnit } };
              } else {
                if (typTol?.toleranceMin) {
                  let value = (typTol?.toleranceMin?.data as SimpleSearchValue)?.value;
                  value = onlyEqual && value === undefined ? 0 : value;
                  return {
                    ...typTol?.toleranceMin,
                    data: { value, unit: unifiedUnit },
                  };
                }
                return undefined;
              }
            })(),
            toleranceMax: ((): OperationData | undefined => {
              if (part === 'toleranceMax') {
                let value = (data as SimpleSearchValue)?.value;
                value = onlyEqual && value === undefined ? 0 : value;
                return { ...typTol?.toleranceMax, data: { value, unit: unifiedUnit } };
              } else {
                if (typTol?.toleranceMax) {
                  let value = (typTol?.toleranceMax?.data as SimpleSearchValue)?.value;
                  value = onlyEqual && value === undefined ? 0 : value;
                  return {
                    ...typTol?.toleranceMax,
                    data: { value, unit: unifiedUnit },
                  };
                }
                return undefined;
              }
            })(),
          },
        };
      }
    }
    setFormValues(updatedData);
  };

  const handleAllEqualSetOperatorMinMaxTyp = (): void => {
    const userValues = {} as MinMaxSearchValue;
    ['min' as keyof MinMaxSearchValue, 'max' as keyof MinMaxSearchValue, 'typ' as keyof MinMaxSearchValue].forEach(
      (valueType) => {
        userValues[valueType] = {
          ...(formValues.userValues as MinMaxSearchValue)?.[valueType],
          operator: {
            ...OPERATORS['='],
            chipDisplayLabel: getOperatorDisplayLabelMinMax(
              formValues.userValues as MinMaxSearchValue,
              OPERATORS['=']?.chipDisplayLabel,
              valueType,
            ),
          },
        };
      },
    );
    setFormValues({
      ...formValues,
      userValues,
    });
  };

  const handleMinMaxSetOperator = (operator: string, part: keyof MinMaxSearchValue): void => {
    if (!formValues?.userValues) return;
    const { min, max, typ } = formValues.userValues as MinMaxSearchValue;
    setFormValues({
      ...formValues,
      userValues: {
        ...formValues.userValues,
        min:
          part === 'min'
            ? {
                ...min,
                operator: {
                  ...OPERATORS[operator],
                  chipDisplayLabel: getOperatorDisplayLabelMinMax(
                    formValues.userValues as MinMaxSearchValue,
                    OPERATORS[operator]?.chipDisplayLabel,
                    part,
                  ),
                },
              }
            : min,

        max:
          part === 'max'
            ? {
                ...max,
                operator: {
                  ...OPERATORS[operator],
                  chipDisplayLabel: getOperatorDisplayLabelMinMax(
                    formValues.userValues as MinMaxSearchValue,
                    OPERATORS[operator]?.chipDisplayLabel,
                    part,
                  ),
                },
              }
            : max,

        typ:
          part === 'typ'
            ? {
                ...typ,
                operator: {
                  ...OPERATORS[operator],
                  chipDisplayLabel: getOperatorDisplayLabelMinMax(
                    formValues.userValues as MinMaxSearchValue,
                    OPERATORS[operator]?.chipDisplayLabel,
                    part,
                  ),
                },
              }
            : typ,
      } as MinMaxSearchValue,
    });
  };

  const handleAllEqualSetOperatorTypTol = (): void => {
    const userValues = { ...formValues?.userValues } as TypToleranceSearchValue;
    const keys = ['typ', 'toleranceMin', 'toleranceMax'] as Array<keyof TypToleranceSearchValue>;
    keys.forEach((key) => {
      userValues[key] = {
        ...(formValues.userValues as TypToleranceSearchValue)?.[key],
        operator: {
          ...OPERATORS['='],
          chipDisplayLabel: getOperatorDisplayLabelTypTol(
            formValues.userValues as TypToleranceSearchValue,
            OPERATORS['=']?.chipDisplayLabel,
            key,
          ),
        },
      };
    });
    setFormValues({
      ...formValues,
      userValues,
    });
  };

  const handleTypTolSetOperator = (operator: string, part: keyof TypToleranceSearchValue): void => {
    const { typ, toleranceMin, toleranceMax } = (formValues.userValues as TypToleranceSearchValue) || {};
    const _formValues = {
      ...formValues,
      userValues: {
        ...formValues.userValues,
        typ:
          part === 'typ'
            ? {
                ...typ,
                operator: {
                  ...OPERATORS[operator],
                  chipDisplayLabel: getOperatorDisplayLabelTypTol(
                    formValues.userValues as TypToleranceSearchValue,
                    OPERATORS[operator]?.chipDisplayLabel,
                    part,
                  ),
                },
              }
            : typ,
        toleranceMin:
          part === 'toleranceMin'
            ? {
                ...toleranceMin,
                operator: {
                  ...OPERATORS[operator],
                  chipDisplayLabel: getOperatorDisplayLabelTypTol(
                    formValues.userValues as TypToleranceSearchValue,
                    OPERATORS[operator]?.chipDisplayLabel,
                    part,
                  ),
                },
              }
            : toleranceMin,
        toleranceMax:
          part === 'toleranceMax'
            ? {
                ...toleranceMax,
                operator: {
                  ...OPERATORS[operator],
                  chipDisplayLabel: getOperatorDisplayLabelTypTol(
                    formValues.userValues as TypToleranceSearchValue,
                    OPERATORS[operator]?.chipDisplayLabel,
                    part,
                  ),
                },
              }
            : toleranceMax,
      } as TypToleranceSearchValue,
    };
    setFormValues(_formValues);
  };

  const handleValueUnitChange =
    (componentType: 'min' | 'max' | 'number' | 'select') =>
    (valueType: 'value' | 'unit') =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
      const newVal = (valueType === 'unit' ? `${componentType}Unit` : componentType) as
        | 'minUnit'
        | 'maxUnit'
        | 'numberUnit'
        | 'min'
        | 'max'
        | 'number';

      handleSetValue(newVal)(event);
    };
  /* SAVE HANDLERS */
  const saveHandler = (): void => {
    switch (inputType) {
      case 'single':
        singleValueSaveHandler();
        break;
      case 'list':
        multipleValuesSaveHandler();
        break;
      case 'min_max':
        minMaxTypValuesSaveHandler();
        break;
      case 'min_typ_max':
        minMaxTypValuesSaveHandler();
        break;
      case 'tolerance':
        typToleranceValuesSaveHandler();
        break;
      case 'tolerance_percentage':
        typToleranceValuesSaveHandler();
        break;
      default:
        singleValueSaveHandler();
        break;
    }
  };

  const typToleranceValuesSaveHandler = (): void => {
    if (!formValues?.userValues) return;
    const { typ, toleranceMin, toleranceMax } = formValues.userValues as TypToleranceSearchValue;
    const convertedValues: Partial<TypToleranceSearchValue> = {};
    const convertToMainUnit = (value: string | number, unit?: string, mainUnit?: string): string | number =>
      typeof value === 'number' && unit
        ? convertUnit(value, unit, mainUnit || unit, withUnitConversion) || value
        : value;

    ((Object.keys(formValues?.userValues) as Array<keyof TypToleranceSearchValue>) || []).forEach((key) => {
      let simpleData: Partial<SimpleSearchValue> | undefined = {};
      const betweenData: Partial<BetweenSearchValue> = {};
      const operationData = ((formValues?.userValues as TypToleranceSearchValue) || {})[key] as OperationData;
      if (operationData?.operator?.value === 'between') {
        const minData = (
          ((formValues?.userValues as TypToleranceSearchValue)[key] as OperationData)?.data as BetweenSearchValue
        )?.minData;
        const maxData = (
          ((formValues?.userValues as TypToleranceSearchValue)[key] as OperationData)?.data as BetweenSearchValue
        )?.maxData;
        betweenData.minData = {
          value: convertToMainUnit(
            minData?.value as number,
            minData?.unit,
            fieldsMap[nestedFieldName || fieldName].mainUnit,
          ) as number,
          unit: fieldsMap[nestedFieldName || fieldName].mainUnit,
        };
        betweenData.maxData = {
          value: convertToMainUnit(
            maxData?.value as number,
            maxData?.unit,
            fieldsMap[nestedFieldName || fieldName].mainUnit,
          ) as number,
          unit: fieldsMap[nestedFieldName || fieldName].mainUnit,
        };
      } else {
        const convertedToMainUnit = convertToMainUnit(
          (((formValues?.userValues as TypToleranceSearchValue)[key] as OperationData)?.data as SimpleSearchValue)
            ?.value as number,
          (((formValues?.userValues as TypToleranceSearchValue)[key] as OperationData)?.data as SimpleSearchValue)
            ?.unit,
          fieldsMap[nestedFieldName || fieldName].mainUnit,
        ) as number;

        simpleData =
          convertedToMainUnit !== undefined
            ? {
                value: convertedToMainUnit,
                unit: fieldsMap[nestedFieldName || fieldName].mainUnit,
              }
            : undefined;
      }
      convertedValues[key] = {
        operator: (((formValues?.userValues as TypToleranceSearchValue) || {})[key] as OperationData)?.operator,
        data:
          (((formValues?.userValues as TypToleranceSearchValue) || {})[key] as OperationData)?.operator?.value ===
          'between'
            ? betweenData
            : simpleData,
      } as OperationData;
    });

    const saveObject = {
      ...formValues,
      field: { ...formValues?.field, ...fieldsMap[nestedFieldName || fieldName], getOptionLabel: undefined },
      parentField: nestedFieldName ? fieldsMap[fieldName] : undefined,
      values: convertedValues as TypToleranceSearchValue,
      userValues: {
        ...formValues.userValues,
        typ: typ
          ? {
              ...typ,
              operator: typ?.operator?.value
                ? {
                    ...OPERATORS[typ?.operator?.value || ''],
                    chipDisplayLabel: getOperatorDisplayLabelTypTol(
                      formValues?.userValues as TypToleranceSearchValue,
                      OPERATORS[typ?.operator?.value || '']?.chipDisplayLabel,
                      'typ',
                    ),
                  }
                : undefined,
            }
          : undefined,
        toleranceMin: toleranceMin
          ? {
              ...toleranceMin,
              operator: toleranceMin?.operator?.value
                ? {
                    ...OPERATORS[toleranceMin?.operator?.value || ''],
                    chipDisplayLabel: getOperatorDisplayLabelTypTol(
                      formValues?.userValues as TypToleranceSearchValue,
                      OPERATORS[toleranceMin?.operator?.value || '']?.chipDisplayLabel,
                      'toleranceMin',
                    ),
                  }
                : undefined,
            }
          : undefined,
        toleranceMax: toleranceMax
          ? {
              ...toleranceMax,
              operator: toleranceMax?.operator?.value
                ? {
                    ...OPERATORS[toleranceMax?.operator?.value || ''],
                    chipDisplayLabel: getOperatorDisplayLabelTypTol(
                      formValues?.userValues as TypToleranceSearchValue,
                      OPERATORS[toleranceMax?.operator?.value || '']?.chipDisplayLabel,
                      'toleranceMax',
                    ),
                  }
                : undefined,
            }
          : undefined,
      } as TypToleranceSearchValue,
    } as AdvancedSearchFilterType;

    onSave(saveObject);
    reset();
  };

  const minMaxTypValuesSaveHandler = (): void => {
    if (!formValues.userValues) return;
    const { min, max, typ } = formValues.userValues as MinMaxSearchValue;

    const convertedValues: Partial<MinMaxSearchValue> = {};
    const convertToMainUnit = (value: string | number, unit?: string, mainUnit?: string): string | number =>
      typeof value === 'number' && unit
        ? convertUnit(value, unit, mainUnit || unit, withUnitConversion) || value
        : value;

    (Object.keys(formValues.userValues) as Array<keyof MinMaxSearchValue>).forEach((key) => {
      let simpleData: Partial<SimpleSearchValue> | undefined = {};
      const betweenData: Partial<BetweenSearchValue> = {};
      const operationData = ((formValues?.userValues as MinMaxSearchValue) || {})[key] as OperationData;
      if (operationData?.operator?.value === 'between') {
        const minData = (
          ((formValues?.userValues as MinMaxSearchValue)[key] as OperationData)?.data as BetweenSearchValue
        )?.minData;
        const maxData = (
          ((formValues?.userValues as MinMaxSearchValue)[key] as OperationData)?.data as BetweenSearchValue
        )?.maxData;
        betweenData.minData = {
          value: convertToMainUnit(
            minData?.value as number,
            minData?.unit,
            fieldsMap[nestedFieldName || fieldName].mainUnit,
          ) as number,
          unit: fieldsMap[nestedFieldName || fieldName].mainUnit,
        };
        betweenData.maxData = {
          value: convertToMainUnit(
            maxData?.value as number,
            maxData?.unit,
            fieldsMap[nestedFieldName || fieldName].mainUnit,
          ) as number,
          unit: fieldsMap[nestedFieldName || fieldName].mainUnit,
        };
      } else {
        const convertedToMainUnit = convertToMainUnit(
          (((formValues?.userValues as MinMaxSearchValue)[key] as OperationData)?.data as SimpleSearchValue)
            ?.value as number,
          (((formValues?.userValues as MinMaxSearchValue)[key] as OperationData)?.data as SimpleSearchValue)?.unit,
          fieldsMap[nestedFieldName || fieldName].mainUnit,
        ) as number;
        simpleData =
          convertedToMainUnit !== undefined
            ? {
                value: convertedToMainUnit,
                unit: fieldsMap[nestedFieldName || fieldName].mainUnit,
              }
            : undefined;
      }
      convertedValues[key] = {
        operator: (((formValues?.userValues as MinMaxSearchValue) || {})[key] as OperationData)?.operator,
        data:
          (((formValues?.userValues as MinMaxSearchValue) || {})[key] as OperationData)?.operator?.value === 'between'
            ? betweenData
            : simpleData,
      } as OperationData;
    });

    const saveObject = {
      ...formValues,
      field: { ...formValues?.field, ...fieldsMap[nestedFieldName || fieldName], getOptionLabel: undefined },
      parentField: nestedFieldName ? fieldsMap[fieldName] : undefined,
      values: convertedValues as MinMaxSearchValue,
      userValues: {
        ...formValues.userValues,
        min: min
          ? {
              ...min,
              operator: min?.operator?.value
                ? {
                    ...OPERATORS[min?.operator?.value || ''],
                    chipDisplayLabel: getOperatorDisplayLabelMinMax(
                      formValues?.userValues as MinMaxSearchValue,
                      OPERATORS[min?.operator?.value || '']?.chipDisplayLabel,
                      'min',
                    ),
                  }
                : undefined,
            }
          : undefined,
        max: max
          ? {
              ...max,
              operator: max?.operator?.value
                ? {
                    ...OPERATORS[max?.operator?.value || ''],
                    chipDisplayLabel: getOperatorDisplayLabelMinMax(
                      formValues?.userValues as MinMaxSearchValue,
                      OPERATORS[max?.operator?.value || '']?.chipDisplayLabel,
                      'max',
                    ),
                  }
                : undefined,
            }
          : undefined,
        typ: typ
          ? {
              ...typ,
              operator: typ?.operator?.value
                ? {
                    ...OPERATORS[typ?.operator?.value || ''],
                    chipDisplayLabel: getOperatorDisplayLabelMinMax(
                      formValues?.userValues as MinMaxSearchValue,
                      OPERATORS[typ?.operator?.value || '']?.chipDisplayLabel,
                      'typ',
                    ),
                  }
                : undefined,
            }
          : undefined,
      } as MinMaxSearchValue,
    } as AdvancedSearchFilterType;
    onSave(saveObject);
    reset();
  };

  const multipleValuesSaveHandler = (): void => {
    const saveObject = {
      ...formValues,
      field: { ...formValues?.field, ...fieldsMap[nestedFieldName || fieldName], getOptionLabel: undefined },
      parentField: nestedFieldName ? fieldsMap[fieldName] : undefined,
      userValues: formValues.userValues,
      operator: {
        label: formValues.operator?.label,
        value: formValues.operator?.value,
        chipDisplayLabel: getOperatorDisplayLabel(
          formValues.userValues as MultipleValuesSearchValue,
          fieldsMap[nestedFieldName || fieldName],
          formValues.operator?.chipDisplayLabel as string,
        ),
      },
    } as AdvancedSearchFilterType;
    saveObject.values = ((): MultipleValuesSearchValue => {
      const convertedValues: Array<number | string> = [];
      const convertToMainUnit = (value: string | number, unit?: string, mainUnit?: string): string | number =>
        typeof value === 'number' && unit
          ? convertUnit(value, unit, mainUnit || unit, withUnitConversion) || value
          : value;

      const { value, unit } = (formValues?.userValues as MultipleValuesSearchValue) || {};
      const specType = fieldsMap[nestedFieldName || fieldName]?.type;

      if (specType === 'number') {
        ((value as Array<number>) || [])?.forEach((n: number) => {
          const convertedValue = convertToMainUnit(
            n,
            unit,
            fieldsMap[nestedFieldName || fieldName]?.mainUnit,
          ) as number;
          convertedValues.push(convertedValue);
        });
      } else {
        convertedValues.push(...(value as string[]));
      }
      return {
        value: specType === 'number' ? convertedValues.map((i) => Number(i)) : convertedValues.map((i) => i.toString()),
        unit: fieldsMap[nestedFieldName || fieldName]?.mainUnit,
      };
    })();

    onSave(saveObject);
    reset();
  };

  const singleValueSaveHandler = (): void => {
    const userValues = formValues.userValues ? (formValues.userValues as SearchValue) : undefined;
    let convertedValues = formValues.userValues ? (formValues.userValues as SearchValue) : undefined;
    if (userValues?.unit || userValues?.maxUnit) {
      const convertToMainUnit = (value: string | number, unit?: string, mainUnit?: string): string | number =>
        typeof value === 'number' && unit
          ? convertUnit(value, unit, mainUnit || unit, withUnitConversion) || value
          : value;

      /* WARNING: This is a quick but temporary fix for data migration 24 JAN 2023 */
      /* Old data has a problem whereby the operator and specification filter are empty */
      /* The fieldName format is odd for old data => specification.xxxxxxxxxxxx.value */

      let _mainUnit: string | undefined;

      if (fieldsMap[nestedFieldName || fieldName]) {
        _mainUnit = fieldsMap[nestedFieldName || fieldName].mainUnit;
      } else {
        if (fieldName?.split('.')?.length === 3 || nestedFieldName?.split('.')?.length === 3) {
          const id = fieldName?.split('.')[1];
          const key = Object.keys(fieldsMap)?.find((fm) => fm.includes(id));

          const nestedId = nestedFieldName?.split('.')[1];
          const nestedKey = Object.keys(fieldsMap)?.find((nfm) => nfm.includes(nestedId));
          if (key) _mainUnit = fieldsMap[nestedKey || key].mainUnit;
        }
      }

      convertedValues = {
        value: convertToMainUnit(userValues.value as string | number, userValues.unit, _mainUnit),
        unit: _mainUnit,
        max:
          (userValues.max && convertToMainUnit(userValues.max as string | number, userValues.maxUnit, _mainUnit)) ||
          undefined,
        maxUnit: (userValues.max && _mainUnit) || undefined,
      };
    }

    /* WARNING: This is a quick but temporary fix for data migration 24 JAN 2023 */
    /* Old data has a problem whereby the operator and specification filter are empty */
    /* The fieldName format is odd for old data => specification.xxxxxxxxxxxx.value */

    onSave({
      ...formValues,
      values: convertedValues,
      field: {
        ...((): SearchFieldsOptions => {
          if (fieldsMap[nestedFieldName || fieldName]) return fieldsMap[nestedFieldName || fieldName];
          else {
            if (nestedFieldName?.split('.')?.length === 3) {
              const nestedFieldId = nestedFieldName?.split('.')[1];
              const nestedFieldKey = Object.keys(fieldsMap).find((fm) => fm.includes(nestedFieldId));
              return fieldsMap[nestedFieldKey || ''];
            }
            const fieldNameId = fieldName?.split('.')[1];
            const fieldKey = Object.keys(fieldsMap).find((fm) => fm.includes(fieldNameId));
            return fieldsMap[fieldKey || ''];
          }
        })(),
        getOptionLabel: undefined,
      },
      parentField: nestedFieldName
        ? ((): SearchFieldsOptions | undefined => {
            if (fieldsMap[fieldName]) return fieldsMap[fieldName];

            if (fieldName?.split('.')?.length === 3) {
              const fieldNameId = fieldName?.split('.')[1];
              const fieldKey = Object.keys(fieldsMap).find((fm) => fm.includes(fieldNameId));
              return fieldsMap[fieldKey || ''];
            }
            return undefined;
          })()
        : undefined,
      operator: {
        ...OPERATORS[formValues.operator?.value as string],
        chipDisplayLabel: getOperatorDisplayLabel(
          userValues as SearchValue,
          ((): SearchFieldsOptions => {
            if (fieldsMap[nestedFieldName || fieldName]) return fieldsMap[nestedFieldName || fieldName];
            else {
              if (nestedFieldName?.split('.')?.length === 3) {
                const nestedFieldId = nestedFieldName?.split('.')[1];
                const nestedFieldKey = Object.keys(fieldsMap).find((fm) => fm.includes(nestedFieldId));
                return fieldsMap[nestedFieldKey || ''];
              }
              const fieldNameId = fieldName?.split('.')[1];
              const fieldKey = Object.keys(fieldsMap).find((fm) => fm.includes(fieldNameId));
              return fieldsMap[fieldKey || ''];
            }
          })(),
          OPERATORS[formValues.operator?.value as string].chipDisplayLabel,
        ),
      },
    });
    reset();
  };

  const isSaveButtonDisabled = (): boolean => {
    let disabled = true;
    let validator: SpecAddButtonValidator;
    if (inputType === 'list') {
      validator = new MultipleValuesAddButtonValidator(formValues.userValues as MultipleValuesSearchValue, formValues);
      disabled = !validator.isSpecificationDataValid();
    } else if (['min_max', 'min_typ_max'].includes(inputType) && formValues?.userValues) {
      validator = !onlyEqual
        ? new MinMaxTypAddButtonValidator(formValues.userValues, formValues)
        : new MinMaxTypOnlyEqualAddButtonValidator(formValues.userValues, formValues);
      disabled = !validator.isSpecificationDataValid();
    } else if (['tolerance', 'tolerance_percentage'].includes(inputType) && formValues.userValues) {
      validator = !onlyEqual
        ? new TypTolAddButtonValidator(formValues.userValues, formValues)
        : new TypTolOnlyEqualAddButtonValidator(formValues.userValues, formValues);
      disabled = !validator.isSpecificationDataValid();
    } else {
      validator = new SingleValueAddButtonValidator(formValues.userValues as SearchValue, formValues, fieldName);
      disabled = !validator.isSpecificationDataValid();
    }
    return disabled;
  };

  return (
    <Container>
      <InputsContainer>
        <FieldContainer>
          {
            /* WARNING: This is a quick but temporary fix for data migration 24 JAN 2023 */
            /* Old data has a problem whereby the operator and specification filter are empty */
            /* The fieldName format is odd for old data => specification.xxxxxxxxxxxx.value */
            ((): JSX.Element => {
              let _fieldName = '';
              if (fieldsMap[fieldName]) {
                _fieldName = fieldName;
              } else {
                if (fieldName?.split('.')?.length === 3) {
                  const id = fieldName?.split('.')[1];
                  const key = Object.keys(fieldsMap).find((fm) => fm.includes(id));
                  _fieldName = key || '';
                }
              }

              return (
                <AutoComplete
                  id="field-type"
                  label={fieldTypeLabel || 'Select a field type'}
                  options={fields?.map((spec) => spec.value) as Array<string>}
                  value={_fieldName}
                  getOptionLabel={(specValue: string): string =>
                    (fields.find((f) => f.value === specValue)?.label as string) || ''
                  }
                  onChange={handleSetFieldName}
                  noMargin
                />
              );
            })()
          }
        </FieldContainer>
        {
          /* WARNING: This is a quick but temporary fix for data migration 24 JAN 2023 */
          /* Old data has a problem whereby the operator and specification filter are empty */
          /* The fieldName format is odd for old data => specification.xxxxxxxxxxxx.value */

          ((): JSX.Element => {
            if (fieldName) {
              if (fieldsMap[fieldName]?.type === 'nested') {
                return (
                  <FieldContainer>
                    <AutoComplete
                      label={`Type of ${fieldsMap[fieldName]?.label}`}
                      options={
                        (fieldsMap[fieldName]?.options as Array<OptionModel>)?.map(
                          (spec) => spec.value,
                        ) as Array<string>
                      }
                      value={nestedFieldName}
                      getOptionLabel={(specValue: string): string => fieldsMap[specValue]?.label as string}
                      onChange={handleSetNestedFieldName}
                      noMargin
                    />
                  </FieldContainer>
                );
              } else {
                if (fieldName?.split('.')?.length === 3) {
                  const idFieldName = fieldName?.split('.')[1];
                  const keyFieldName = Object.keys(fieldsMap).find((fm) => fm.includes(idFieldName));

                  const idNestedFieldName = nestedFieldName?.split('.')[1];
                  const keyNestedFieldName = Object.keys(fieldsMap).find((fm) => fm.includes(idNestedFieldName));

                  if (fieldsMap[keyFieldName || ''].type === 'nested') {
                    return (
                      <FieldContainer>
                        <AutoComplete
                          label={`Type of ${fieldsMap[keyFieldName || '']?.label}`}
                          options={
                            (fieldsMap[keyFieldName || '']?.options as Array<OptionModel>)?.map(
                              (spec) => spec.value,
                            ) as Array<string>
                          }
                          value={keyNestedFieldName}
                          getOptionLabel={(specValue: string): string => fieldsMap[specValue]?.label as string}
                          onChange={handleSetNestedFieldName}
                          noMargin
                        />
                      </FieldContainer>
                    );
                  }
                }
              }
            }
            return <></>;
          })()
        }
        <>
          {inputType ? (
            <>
              {inputType === 'single' && (
                <Suspense fallback={<Loader />}>
                  <SingleValueSearch
                    operators={OPERATORS}
                    fieldName={fieldName}
                    fieldsMap={fieldsMap}
                    nestedFieldName={nestedFieldName}
                    formValues={formValues}
                    onlyEqual={onlyEqual}
                    handleSetOperator={handleSetOperator}
                    handleSetValue={handleSetValue}
                    handleValueUnitChange={handleValueUnitChange}
                  />
                </Suspense>
              )}

              {featurePermissions?.SHOW_SPEC_VALUE_TYPES?.read && (
                <>
                  {(fieldName || nestedFieldName) && inputType === 'list' && (
                    <Suspense fallback={<Loader />}>
                      <MultipleValuesSearch
                        operators={OPERATORS}
                        fieldName={fieldName}
                        fieldsMap={fieldsMap}
                        nestedFieldName={nestedFieldName}
                        formValues={formValues}
                        onlyEqual={onlyEqual}
                        handleSetOperator={handleSetOperator}
                        handleSetValue={handleMultipleValuesSearchSetValue}
                        handleSetUnit={handleMultipleValuesSearchSetUnit}
                      />
                    </Suspense>
                  )}
                  {(fieldName || nestedFieldName) && ['min_max', 'min_typ_max'].includes(inputType) && (
                    <Suspense fallback={<Loader />}>
                      <MinMaxValueSearch
                        hasTyp={inputType === 'min_typ_max'}
                        operators={OPERATORS}
                        fieldName={fieldName}
                        nestedFieldName={nestedFieldName}
                        formValues={formValues}
                        onlyEqual={onlyEqual}
                        handleSetValue={handleMinMaxValuesSearchSetValue}
                        fieldsMap={fieldsMap}
                        handleSetOperator={handleMinMaxSetOperator}
                        handleAllEqualSetOperator={handleAllEqualSetOperatorMinMaxTyp}
                      />
                    </Suspense>
                  )}
                  {(fieldName || nestedFieldName) && ['tolerance', 'tolerance_percentage'].includes(inputType) && (
                    <Suspense fallback={<Loader />}>
                      <TypToleranceValuesSearch
                        percentageBase={inputType === 'tolerance_percentage'}
                        operators={OPERATORS}
                        fieldName={fieldName}
                        nestedFieldName={nestedFieldName}
                        formValues={formValues}
                        onlyEqual={onlyEqual}
                        handleSetValue={handleTypTolValuesSearchSetValue}
                        fieldsMap={fieldsMap}
                        handleSetOperator={handleTypTolSetOperator}
                        handleAllEqualSetOperator={handleAllEqualSetOperatorTypTol}
                      />
                    </Suspense>
                  )}
                </>
              )}
            </>
          ) : (
            <Suspense fallback={<Loader />}>
              <SingleValueSearch
                operators={OPERATORS}
                fieldName={fieldName}
                fieldsMap={fieldsMap}
                nestedFieldName={nestedFieldName}
                formValues={formValues}
                onlyEqual={onlyEqual}
                handleSetOperator={handleSetOperator}
                handleSetValue={handleSetValue}
                handleValueUnitChange={handleValueUnitChange}
              />
            </Suspense>
          )}
        </>
      </InputsContainer>
      <DownContainer>
        <div>{deleteElement}</div>
        <div>
          <ResetContainer disabled={!fieldName}>
            <Button empty onClick={reset} disabled={!fieldName}>
              Reset
            </Button>
          </ResetContainer>
          <Button
            dataTestId="spec-ranges-add-button"
            onClick={saveHandler}
            disabled={formValues && isSaveButtonDisabled()}
            size="small"
          >
            {submitText || 'Save'}
          </Button>
        </div>
      </DownContainer>
    </Container>
  );
};

export default AdvancedSearchForm;
