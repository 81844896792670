import { ENDPOINT_DESIGN_BLOCKS } from 'state_management/actions/designBlocks/designBlocksActions';
import { ENDPOINT_PLUGINS } from 'state_management/actions/plugins/pluginsActions';
import { ENDPOINT_IMPLEMENTATIONS } from 'state_management/actions/implementations/implementationsActions';
import { ENDPOINT_INTERFACES } from 'state_management/actions/interfaces/interfacesActions';
import { ENDPOINT_SPECIFICATIONS } from 'state_management/actions/specifications/specificationsActions';
import { ENDPOINT_COMPONENTS } from 'state_management/actions/components/componentsActions';
import { ENDPOINT_CATEGORIES } from 'state_management/actions/categories/categoriesActions';
import { ENDPOINT_REQUESTS } from 'state_management/actions/changesRequest/changesRequestActions';
import { ENDPOINT_SUB_SYSTEM_CANVAS } from 'state_management/actions/subSystemCanvas/subSystemCanvasActions';
import { ENDPOINT_VIRTUAL_COMPONENTS } from 'state_management/actions/virtualComponents/virtualComponentsActions';
import { ENDPOINT_SUPPLIERS } from 'state_management/actions/suppliers/suppliersActions';
import { ENDPOINT_MANUFACTURERS } from 'state_management/actions/manufacturers/manufacturersActions';
import { ENDPOINT_DESIGN_CONSIDERATIONS } from 'state_management/actions/designConsiderations/designConsiderationsActions';
import { ENDPOINT_PROJECTS } from 'state_management/actions/projects/projectsActions';
import { ENDPOINT_EXPORT_JOBS } from 'state_management/actions/exportJobs/exportJobsActions';
import { ENDPOINT_EXPORT_TEMPLATES } from 'state_management/actions/exportTemplates/exportTemplatesActions';
import { IRouteInfo } from './Modals';

export const SUPPORT_URLS = {
  implementationMoreInfo: { route: 'https://support.celus.io/hc/en-us/articles/4405243714193-Creating-a-new-Cubo' },
  mlInfo: {
    route:
      'https://support.celus.io/hc/en-us/articles/4405394857105-Guidelines-on-preparing-native-CAD-files-for-upload-on-CELUS-Orbit',
  },
  contact: { route: 'https://www.celus.io/en/contact' },
  youtube: { route: 'https://www.youtube.com/channel/UCa04DRhlTgYziPct56GwfYQ' },
  videos: { route: ' https://www.celus.io/video-library' },
  status: { route: 'https://status.celus.io/' },
  documentation: {
    route: 'https://support.celus.io/hc/en-us/signin?return_to=https%3A%2F%2Fsupport.celus.io%2Fhc%2Fen-us',
  },
  community: {
    route:
      'https://support.celus.io/hc/en-us/signin?return_to=https%3A%2F%2Fsupport.celus.io%2Fhc%2Fen-us%2Fcommunity%2Ftopics',
  },
} as const;

export const OPEN_SPACE_TERMS_AND_CONDITIONS_URL = 'https://www.celus.io/openspace-terms';
export const OPEN_SPACE_PROFESSIONAL_UPGRADE_URL = 'https://pages.celus.io/en/openspace-pro';

export const OPEN_SPACE_WELCOME_GUIDE_ORIGIN = 'https://pages.celus.io';
export const OPEN_SPACE_WELCOME_GUIDE_PATHNAME = '/platform-guide-popup';

export const AVNET_SUPPORT_ENDPOINT = '/help';
export const AVNET_BOM_ENDPOINT = '/bom-integration';

export const MAIN_ROUTES = {
  mainDashboard: { route: '/', path: '/', label: 'Main Dashboard' },
  projects: { route: '/projects', path: 'projects', label: 'Project' },
  blocksLibrary: { route: '/blocks-library', path: 'blocks-library', label: 'Block Library' },
  projectImport: { route: '/project-import', path: 'project-import', label: 'Importing Project' },
  subSystemCanvas: { route: '/sub-system-canvas', path: 'sub-system-canvas', label: 'Subsystem Canvas' },
  notMatch: { route: '/*', path: '/*', label: 'Page Not Found', alterPath: '/pagenotfound' },
  logout: { route: '/logout', path: 'logout', label: 'Logged Out' },
  loginFailed: { route: '/login-error', path: 'login-error', label: 'Login failed' },
  bannedContries: { route: '/country-not-supported', path: 'country-not-supported', label: 'Country Not Supported' },
  consents: { route: '/consents', path: 'consents', label: 'Terms and Conditions' },
} as const;

export const noAuthRoutes: string[] = [
  MAIN_ROUTES.logout.path,
  MAIN_ROUTES.loginFailed.path,
  MAIN_ROUTES.bannedContries.path,
];

export const BLOCKS_LIBRARY_ROUTES: Record<string, IRouteInfo> = {
  plugins: {
    path: 'plugins',
    route: `${MAIN_ROUTES.blocksLibrary.route}/plugins`,
    label: 'Plugins',
    i18nKey: 'orbit:blockLibrary.plugins',
    backendRouteName: ENDPOINT_PLUGINS,
  },
  designBlocks: {
    path: 'design',
    route: `${MAIN_ROUTES.blocksLibrary.route}/design`,
    label: 'Design Blocks',
    i18nKey: 'orbit:blockLibrary.designBlocks',
    backendResourceName: 'design_blocks',
    backendRouteName: ENDPOINT_DESIGN_BLOCKS,
  },
  implementations: {
    path: 'cubos',
    route: `${MAIN_ROUTES.blocksLibrary.route}/cubos`,
    label: 'Cubos',
    i18nKey: 'orbit:blockLibrary.implementations',
    backendResourceName: 'implementations',
    backendRouteName: ENDPOINT_IMPLEMENTATIONS,
  },
  interfaces: {
    path: 'interfaces',
    route: `${MAIN_ROUTES.blocksLibrary.route}/interfaces`,
    label: 'Electrical Port Types',
    i18nKey: 'orbit:blockLibrary.electricalPortTypes',
    backendResourceName: 'interfaces',
    backendRouteName: ENDPOINT_INTERFACES,
  },
  specifications: {
    path: 'specifications',
    route: `${MAIN_ROUTES.blocksLibrary.route}/specifications`,
    label: 'Specification Types',
    i18nKey: 'orbit:blockLibrary.specificationTypes',
    backendResourceName: 'specifications',
    backendRouteName: ENDPOINT_SPECIFICATIONS,
  },
  components: {
    path: 'components',
    route: `${MAIN_ROUTES.blocksLibrary.route}/components`,
    label: 'Components',
    i18nKey: 'orbit:blockLibrary.components',
    backendResourceName: 'components',
    backendRouteName: ENDPOINT_COMPONENTS,
  },
  categories: {
    // NOTE: It is also called taxonomy on the backend
    path: 'categories',
    route: `${MAIN_ROUTES.blocksLibrary.route}/categories`,
    label: 'Categories',
    i18nKey: 'orbit:blockLibrary.categories',
    backendResourceName: 'taxonomy',
    backendRouteName: ENDPOINT_CATEGORIES,
  },
  requests: {
    path: 'manage-requests',
    route: `${MAIN_ROUTES.blocksLibrary.route}/manage-requests`,
    label: 'Manage Requests',
    i18nKey: 'orbit:blockLibrary.yourRequests',
    backendResourceName: 'FILL_THIS_IN_ROUTES',
    backendRouteName: ENDPOINT_REQUESTS,
  },
  changeLog: {
    path: 'change-log',
    route: `${MAIN_ROUTES.blocksLibrary.route}/change-log`,
    label: 'Change History',
    i18nKey: 'orbit:blockLibrary.changeHistory',
    backendResourceName: 'FILL_THIS_IN_ROUTES',
    backendRouteName: ENDPOINT_REQUESTS,
  },
  referenceDesigns: {
    // NOTE: In reality these are projects
    path: 'reference-designs',
    route: `${MAIN_ROUTES.blocksLibrary.route}/reference-designs`,
    label: 'Reference Designs',
    i18nKey: 'orbit:blockLibrary.referenceDesigns',
    backendResourceName: 'projects',
    backendRouteName: ENDPOINT_PROJECTS,
  },
  subSystems: {
    path: 'subsystems',
    route: `${MAIN_ROUTES.blocksLibrary.route}/subsystems`,
    label: 'Subsystems',
    i18nKey: 'orbit:blockLibrary.subsystems',
    backendResourceName: 'subsystems',
    backendRouteName: ENDPOINT_SUB_SYSTEM_CANVAS,
  },
  designRules: {
    path: 'designRules',
    route: `${MAIN_ROUTES.blocksLibrary.route}/designRules`,
    label: 'Design Rules',
    i18nKey: 'orbit:blockLibrary.designRules',
    backendResourceName: 'FILL_THIS_IN_ROUTES',
    backendRouteName: '',
  },
  documentation: {
    path: 'documentation',
    route: `${MAIN_ROUTES.blocksLibrary.route}/documentation`,
    label: 'Documentation',
    i18nKey: 'orbit:blockLibrary.documentation',
    backendResourceName: 'FILL_THIS_IN_ROUTES',
    backendRouteName: '',
  },
  virtualComponents: {
    path: 'virtual-components',
    route: `${MAIN_ROUTES.blocksLibrary.route}/virtual-components`,
    label: 'Virtual Components',
    i18nKey: 'orbit:blockLibrary.virtualComponents',
    backendResourceName: 'virtual_components',
    backendRouteName: ENDPOINT_VIRTUAL_COMPONENTS,
  },
  suppliers: {
    path: 'suppliers',
    route: `${MAIN_ROUTES.blocksLibrary.route}/suppliers`,
    label: 'Suppliers',
    i18nKey: 'orbit:blockLibrary.suppliers',
    backendResourceName: 'suppliers',
    backendRouteName: ENDPOINT_SUPPLIERS,
  },
  manufacturers: {
    path: 'manufacturers',
    route: `${MAIN_ROUTES.blocksLibrary.route}/manufacturers`,
    label: 'Manufacturers',
    i18nKey: 'orbit:blockLibrary.manufacturers',
    backendResourceName: 'manufacturers',
    backendRouteName: ENDPOINT_MANUFACTURERS,
  },
  designConsiderations: {
    path: 'design-considerations',
    route: `${MAIN_ROUTES.blocksLibrary.route}/design-considerations`,
    label: 'Design Considerations',
    i18nKey: 'orbit:blockLibrary.designConsiderations',
    backendResourceName: 'design_considerations',
    backendRouteName: ENDPOINT_DESIGN_CONSIDERATIONS,
  },
  exportJobs: {
    path: 'export-jobs',
    route: `${MAIN_ROUTES.blocksLibrary.route}/export-jobs`,
    label: 'Exports',
    i18nKey: 'orbit:blockLibrary.exportJobs',
    backendResourceName: 'export_jobs',
    backendRouteName: ENDPOINT_EXPORT_JOBS,
  },
  exportTemplates: {
    path: 'export-templates',
    route: `${MAIN_ROUTES.blocksLibrary.route}/export-templates`,
    label: 'Export Templates',
    i18nKey: 'orbit:blockLibrary.exportTemplates',
    backendResourceName: 'export_templates',
    backendRouteName: ENDPOINT_EXPORT_TEMPLATES,
  },
  notMatch: {
    path: '*',
    route: `${MAIN_ROUTES.blocksLibrary.route}/*`,
    label: 'Page Not Found',
    i18nKey: 'urlNotMatch.pageNotFound',
    backendRouteName: '',
  },
} as const;

export type CanvasType =
  | 'Project Settings'
  | 'Design Canvas'
  | 'Board-Shape Canvas'
  | 'BOM Canvas'
  | 'Project Files'
  | 'Sub System Canvas';

export const CANVAS_ROUTES: Record<CanvasType, string> = {
  'Project Settings': 'project-settings-canvas',
  'Design Canvas': 'system-design-canvas',
  'Board-Shape Canvas': 'board-shape-canvas',
  'BOM Canvas': 'bom-canvas',
  'Project Files': 'project-files-canvas',
  'Sub System Canvas': 'sub-system-canvas',
};

export const getCanvasFromRoute = (route: string): CanvasType => {
  const entry = Object.entries(CANVAS_ROUTES).find(([_, value]) => value === route) || [
    'Design Canvas',
    'system-design-canvas',
  ];

  return entry[0] as CanvasType;
};

export const appRoutes = {
  getCanvasRoute: (projectId: string, canvas: CanvasType | undefined): string => {
    const _canvas = canvas || 'Design Canvas';

    return `${MAIN_ROUTES.projects.route}/${projectId}?canvas=${CANVAS_ROUTES[_canvas]}`;
  },
};
