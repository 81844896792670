import {
  GetResourceSharingAction,
  GetResourceSharingErrorAction,
  GetResourceSharingSuccessAction,
  SharingActionTypes,
  UpdateResourceSharingAction,
  UpdateResourceSharingActionPayload,
  UpdateResourceSharingErrorAction,
  UpdateResourceSharingSuccessAction,
} from './ActionTypes';

export const getResourceSharingAction = (
  payload: Omit<UpdateResourceSharingActionPayload, 'resourceDomains'>,
): GetResourceSharingAction => ({
  type: SharingActionTypes.GET_RESOURCE_SHARING,
  payload,
});

export const getResourceSharingSuccessAction = (
  resourceId: string,
  data: Raw.IResourceSharing,
): GetResourceSharingSuccessAction => ({
  type: SharingActionTypes.GET_RESOURCE_SHARING_SUCCESS,
  payload: {
    resourceId,
    data,
  },
});

export const getResourceSharingErrorAction = (payload: string): GetResourceSharingErrorAction => ({
  type: SharingActionTypes.GET_RESOURCE_SHARING_FAIL,
  payload,
});

export const updateResourceSharingAction = (
  payload: UpdateResourceSharingActionPayload,
): UpdateResourceSharingAction => ({
  type: SharingActionTypes.UPDATE_RESOURCE_SHARING,
  payload,
});

export const updateResourceSharingSuccessAction = (
  payload: Partial<Raw.IResourceShareItems>,
): UpdateResourceSharingSuccessAction => ({
  type: SharingActionTypes.UPDATE_RESOURCE_SHARING_SUCCESS,
  payload,
});

export const updateResourceSharingErrorAction = (payload: string): UpdateResourceSharingErrorAction => ({
  type: SharingActionTypes.UPDATE_RESOURCE_SHARING_FAIL,
  payload,
});
