export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const mlConfig = {
  mlTrustedThreshold: 0.7,
  mlWarningThreshold: 0.15,
};

// NOTE: The leading slash is required for the URL be absolute, not relative to the current URL
export const apiHost = '/api/v1';
export const apiHostV2 = '/api/v2';
export const loginUri = `${apiHostV2}/auth-user/login`;

export const FONTS_SRC = process.env.REACT_APP_FONTS_SRC;

export const getProjectBackgroundUri = (imageId: string): string => {
  const noCache = Date.now();

  return `${apiHostV2}/dataservice/object-storage/project-outputs/${imageId}/download?fileContentData=true&noCache=${noCache}`;
};

// NOTE: To prevent JS floating number issue, we need to fix our floating numbers
export const MAX_FLOATING_NUMBERS = parseInt(process.env.MAX_FLOATING_NUMBERS || '15', 10);

const LUT_AVNET_GROUPS = {
  EBV: ['AVNET/EBV/Public', 'AVNET/EBV/Archive', 'AVNET/EBV/Draft', 'AVNET/EBV/Released'],
  SILICA: ['AVNET/Silica/Public', 'AVNET/Silica/Archive', 'AVNET/Silica/Draft', 'AVNET/Silica/Released'],
  ABACUS: ['AVNET/Abacus/Public', 'AVNET/Abacus/Archive', 'AVNET/Abacus/Draft', 'AVNET/Abacus/Released'],
  AMERICAS: ['AVNET/Americas/Public', 'AVNET/Americas/Archive', 'AVNET/Americas/Draft', 'AVNET/Americas/Released'],
};
export const GROUPS_FILTERING_LUT: Record<string, Array<string>> = (process.env.GROUPS_FILTERING_LUT &&
  JSON.parse(process.env.GROUPS_FILTERING_LUT)) || {
  'ebv.celus.io': LUT_AVNET_GROUPS.EBV,
  'ebv-uat.celus.co': LUT_AVNET_GROUPS.EBV,
  'ebv-nightly.celus.co': LUT_AVNET_GROUPS.EBV,
  'silica.celus.io': LUT_AVNET_GROUPS.SILICA,
  'silica-uat.celus.co': LUT_AVNET_GROUPS.SILICA,
  'silica-nightly.celus.co': LUT_AVNET_GROUPS.SILICA,
  'abacus.celus.io': LUT_AVNET_GROUPS.ABACUS,
  'abacus-uat.celus.co': LUT_AVNET_GROUPS.ABACUS,
  'abacus-nightly.celus.co': LUT_AVNET_GROUPS.ABACUS,
  'avnet-americas.celus.io': LUT_AVNET_GROUPS.AMERICAS,
  'avnet-americas-uat.celus.co': LUT_AVNET_GROUPS.AMERICAS,
  'avnet-americas-nightly.celus.co': LUT_AVNET_GROUPS.AMERICAS,
};

export const FSP_HOSTS = Object.keys(GROUPS_FILTERING_LUT).filter((lut: string) =>
  ['ebv', 'silica', 'abacus'].some((value: string) => lut.includes(value)),
);
