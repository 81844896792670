import { conversionTable } from 'utils/convertionTable';
import { DetailedValue } from 'utils/detailedValue';
import { units, maxUnitLength } from './units';

const maxLength: number = maxUnitLength();

const NEXT = 'looking_for_next';
const SEARCH_NUMBER = 'looking_for_number';
const SEARCH_SECOND_NUMBER = 'looking_for_second_number';
const SEARCH_UNIT_BEGIN = 'looking_for_unit_begin';
const SEARCH_UNIT_END = 'looking_for_unit_end';
const SEARCH_STRING = 'looking_for_string';
const SEARCH_POSTFIX_NOTATION = 'looking_for_postfix_notation';

/**
 * @param prefix {string} target prefix to test
 * @returns { boolean }
 */
const isValidPrefix = (prefix: string): boolean => prefix in conversionTable;

/**
 * Check if a string is composed by a optional prefix and a unit.
 * @param rawString {string} string to be validated
 * @returns { unit: string, prefix: unit, valid: boolean }
 */
type AsUnitType = { unit: string; prefix: string; valid: boolean };
const asUnit = (rawString: string): AsUnitType => {
  let unit = '';
  let prefix = '';
  let validUnit = false;
  let pos: number;

  for (pos = maxLength; pos >= 0; pos -= 1) {
    unit = rawString.slice(rawString.length - pos, rawString.length);

    if (unit in units) {
      validUnit = true;
      break;
    }
  }

  if (!validUnit) {
    return { unit: '', prefix: '', valid: false };
  }
  if (pos === maxLength) {
    return { unit, prefix, valid: true };
  }
  if (!units[unit].acceptPrefix) {
    return { unit: '', prefix: '', valid: false };
  }

  prefix = rawString.slice(0, rawString.length - pos);

  if (isValidPrefix(prefix)) {
    return { unit, prefix, valid: true };
  }

  return { unit, prefix: '', valid: false };
};

/**
 * Try to convert a raw string to a number
 * @param rawString string to be validated
 * @returns { value: number, decimalPlaces: number, valid: boolean }
 */
type AsNumberType = { value: number; decimalPlaces: number; valid: boolean };
const asNumber = (rawString: string): AsNumberType => {
  const value = Number(rawString);
  let decimalPlaces = 0;

  if (Number.isNaN(value)) {
    return { value, decimalPlaces, valid: false };
  }

  decimalPlaces = rawString.indexOf('.');
  if (decimalPlaces === -1) {
    decimalPlaces = 0;
  } else {
    // -1 because length has 1 more digit than expected
    decimalPlaces = rawString.length - decimalPlaces - 1;
  }

  return { value, decimalPlaces, valid: true };
};

/**
 * Create a detailed value if the passed arguments are valid
 * @param numberPart the number to be verified
 * @param unitPart the string that can represent prefix+unit
 * @param emptySpaces quantity of empty spaces between the number and the unit
 * @param postfix boolean telling to use the postfix notation or not
 * @returns { DetailedValue }
 */
const toDetailedValue = (
  numberPart: string,
  unitPart: string,
  emptySpaces: number,
  postfix: boolean,
): DetailedValue | undefined => {
  const numberPartInfo: AsNumberType = asNumber(numberPart);
  const unitPartInfo: AsUnitType = asUnit(unitPart);

  if (numberPartInfo.valid && unitPartInfo.valid) {
    const number: number = numberPartInfo.value;
    const { unit } = unitPartInfo;
    const { prefix } = unitPartInfo;
    const { decimalPlaces } = numberPartInfo;
    return new DetailedValue(number, unit, prefix, emptySpaces, decimalPlaces, postfix);
  }
  return undefined;
};

/**
 * Verify is a input char is a digit
 * @param char
 */
const isDigit = (char: string): boolean => !Number.isNaN(+char);

/**
 * Parse a input string into a list of strings and detailed value
 * @param inputValue string to be parsed
 * @returns { (DetailedValue | string)[] }
 */
export const parseString = (inputValue: string): Array<DetailedValue | string> => {
  const parsed: (DetailedValue | string)[] = [];
  let state: string = NEXT;

  let rawBlock = '';
  let rawNumber = '';
  let rawString = '';
  let emptySpaces = 0;
  let postfixNotation = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const char of inputValue) {
    if (state === NEXT) {
      rawBlock = '';
      rawNumber = '';
      rawString = '';
      emptySpaces = 0;
      postfixNotation = false;
      if (char === ' ') {
        // eslint-disable-next-line no-continue
        continue;
      } else if (isDigit(char) || ['.', '-'].includes(char)) {
        rawBlock = char;
        rawNumber = char;
        emptySpaces = 0;
        state = SEARCH_NUMBER;
      } else {
        rawString = char;
        state = SEARCH_STRING;
      }
    } else if (state === SEARCH_STRING) {
      if (char === ' ') {
        parsed.push(rawString);
        state = NEXT;
      } else {
        rawString += char;
      }
    } else if (state === SEARCH_NUMBER) {
      rawBlock += char;
      if (char === ' ') {
        rawString = '';
        emptySpaces += 1;
        state = SEARCH_UNIT_BEGIN;
      } else if (isDigit(char) || char === '.') {
        rawNumber += char;
      } else {
        rawString = char;
        state = SEARCH_POSTFIX_NOTATION;
      }
    } else if (state === SEARCH_UNIT_BEGIN) {
      if (char === ' ') {
        rawBlock += char;
        emptySpaces += 1;
      } else if (isDigit(char)) {
        if (rawBlock.split(' ').filter((i) => i)) {
          parsed.push(...rawBlock.trim().replace(/  +/g, ' ').split(' '));
        }
        rawBlock = char;
        rawNumber = char;
        emptySpaces = 0;
        state = SEARCH_NUMBER;
      } else {
        rawBlock += char;
        rawString += char;
        state = SEARCH_UNIT_END;
      }
    } else if (state === SEARCH_UNIT_END) {
      if (char === ' ') {
        const detailedValue: DetailedValue | undefined = toDetailedValue(
          rawNumber,
          rawString,
          emptySpaces,
          postfixNotation,
        );
        if (detailedValue) {
          parsed.push(detailedValue);
        } else {
          if (rawNumber) {
            parsed.push(rawNumber);
          }
          if (rawString) {
            parsed.push(rawString);
          }
        }
        state = NEXT;
      } else {
        rawBlock += char;
        rawString += char;
      }
    } else if (state === SEARCH_POSTFIX_NOTATION) {
      if (char === ' ') {
        rawBlock += char;
        const detailedValue: DetailedValue | undefined = toDetailedValue(
          rawNumber,
          rawString,
          emptySpaces,
          postfixNotation,
        );
        if (detailedValue) {
          parsed.push(detailedValue);
          state = NEXT;
        } else if (isValidPrefix(rawString)) {
          emptySpaces = 1;
          state = SEARCH_UNIT_BEGIN;
        } else {
          parsed.push(...rawBlock.trim().replace(/  +/g, ' ').split(' '));
          state = NEXT;
        }
      } else if (isDigit(char) || char === '.') {
        rawBlock += char;
        if (isValidPrefix(rawString)) {
          postfixNotation = true;
          rawNumber = `${rawNumber}.${char}`;
          state = SEARCH_SECOND_NUMBER;
        } else {
          rawString = rawBlock;
          state = SEARCH_STRING;
        }
      } else {
        rawBlock += char;
        rawString += char;
      }
    } else if (state === SEARCH_SECOND_NUMBER) {
      if (char === ' ') {
        rawBlock += char;
        emptySpaces = 1;
        state = SEARCH_UNIT_BEGIN;
      } else if (isDigit(char) || char === '.') {
        rawBlock += char;
        rawNumber += char;
      } else {
        rawBlock += char;
        rawString += char;
        state = SEARCH_UNIT_END;
      }
    }
  }

  if (state === SEARCH_STRING && rawString) {
    parsed.push(rawString);
  } else if (state === SEARCH_NUMBER && rawNumber) {
    parsed.push(rawNumber);
  } else if (state === SEARCH_UNIT_BEGIN) {
    parsed.push(...rawBlock.trim().replace(/  +/g, ' ').split(' '));
  } else if (state === SEARCH_UNIT_END || state === SEARCH_POSTFIX_NOTATION) {
    const detailedValue: DetailedValue | undefined = toDetailedValue(
      rawNumber,
      rawString,
      emptySpaces,
      postfixNotation,
    );
    if (detailedValue) {
      parsed.push(detailedValue);
    } else if (rawBlock) {
      parsed.push(...rawBlock.trim().replace(/  +/g, ' ').split(' '));
    }
  } else if (state === SEARCH_SECOND_NUMBER) {
    parsed.push(...rawBlock.trim().replace(/  +/g, ' ').split(' '));
  }

  return parsed;
};
