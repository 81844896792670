import { applyMiddleware, createStore, compose, Middleware, CombinedState } from 'redux';
import { createLogicMiddleware } from 'redux-logic';
import createSagaMiddleware from 'redux-saga';
import axios, { AxiosError } from 'axios';
import thunk from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import immutable from 'redux-immutable-state-invariant';
import Reactotron, { trackGlobalErrors } from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';
import sagaPlugin from 'reactotron-redux-saga';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { axiosInstance } from 'services/dataService';
import { isDev } from '../config';
import logic from './logic';
import reducer from './reducers';
import { AppState, AppStore } from './AppState';
import rootSaga from './sagas';
import { unAuthorizedUserAction } from './actions/auth/authActions';

const axiosClient = axios.create({
  responseType: 'json',
});

let reactotronEnhancer = (): number => 0;
let sagaMiddleware = createSagaMiddleware();

if (isDev && !['production', 'test'].includes(process.env.NODE_ENV)) {
  reactotronEnhancer = Reactotron.configure({ name: 'Celus App' })
    .use(reactotronRedux())
    .use(sagaPlugin({}))
    .use(trackGlobalErrors({}))
    .connect()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .createEnhancer();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sagaMiddleware = createSagaMiddleware({ sagaMonitor: Reactotron.createSagaMonitor() });
}

const handleUnAuthorizedError = (store: AppStore, error: AxiosError): Promise<never> => {
  if (error.response?.status === 401) store.dispatch(unAuthorizedUserAction());
  // else if (error.response.status === 403) store.dispatch({'type': 'DENY_ACCESS'});

  return Promise.reject(error); // Reject promise on Err
};

const axiosMiddlewareConfig = {
  interceptors: {
    response: [
      {
        error: handleUnAuthorizedError,
      },
    ],
  },
};

export const middlewares: Array<Middleware> = [
  axiosMiddleware(axiosClient, axiosMiddlewareConfig),
  // TODO: Fix this
  createLogicMiddleware(logic as any),
  thunk,
  sagaMiddleware,
];

if (isDev) {
  /* There is an issue with react-immutable-state-invariant
  https://github.com/BBB/dropzone-redux-form-example/issues/5
  */
  middlewares.push(
    immutable({
      ignore: [
        'implementationsState.implementationFiles',
        'componentsState.componentCadFiles',
        'componentsState.componentDocFiles',
        'virtualComponentsState.virtualComponentCadFiles',
        'virtualComponentsState.virtualComponentDocFiles',
        'themesState.mainLogo',
        'themesState.supernovaLogos',
        'themesState.orbitLogos',
      ],
    }),
  );
}

const configureStore = (): AppStore => {
  const initialState = {};
  let store: AppStore;
  const persistConfig: PersistConfig<CombinedState<AppState>> = {
    key: 'root',
    storage,
    transforms: [],
    whitelist: ['featuresState'], // Only this will be persisted
    debug: isDev,
  };

  const persistedReducer = persistReducer(persistConfig, reducer);

  if (isDev) {
    const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    store = createStore(
      persistedReducer,
      initialState,
      composeEnhancers(applyMiddleware(...middlewares), reactotronEnhancer),
    );
  } else {
    store = createStore(persistedReducer, initialState, applyMiddleware(...middlewares));
  }

  return store;
};

export const store = configureStore();

// NOTE: Register Axios Error handler for Global 401 for given instance
axiosInstance.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => handleUnAuthorizedError(store, error),
);

export const persistor = persistStore(store);

// Must initiate sagas after creating the store
sagaMiddleware.run(rootSaga);
