/* eslint-disable no-case-declarations */
import { upsertResource } from 'utils/resourceUtils';
import { OTHER_MEASURE } from 'components/SpecificationsForm/constants';
import { IImplementationSpecification, IPcbSpecification, SpecificationList, SpecificationsState } from './Modals';
import { SpecificationActions, SpecificationActionTypes } from '../../actions/specifications/ActionTypes';

export const initialStateSpecifications: SpecificationsState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  specificationsList: { pcb: [], implementation: [], custom_field: [] },
  specificationsImplementationList: [],
  specificationsPcbList: [],
  specificationsCustomFieldList: [],
  specificationsListState: 'idle',
  specificationsListError: '',
  specificationCreateState: 'idle',
  specificationCreateError: '',
  specificationById: {
    dataType: 'number',
    id: '',
    key: '',
    name: '',
    physicalQuantity: OTHER_MEASURE,
    unit: '',
    specType: 'implementation',
    shortDescription: '',
    fullInfo: '',
    specificationValueType: 'single',
  },
  specificationByIdState: 'idle',
  specificationByIdError: '',
  specificationUpdateState: 'idle',
  specificationUpdateError: '',
  specificationDeleteState: 'idle',
  specificationDeleteError: '',
};

export const specificationsReducer = (
  state: SpecificationsState = initialStateSpecifications,
  action: SpecificationActions,
): SpecificationsState => {
  switch (action.type) {
    case SpecificationActionTypes.RESET_SPECIFICATIONS:
      return initialStateSpecifications;
    case SpecificationActionTypes.GET_ALL_SPECIFICATIONS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        specificationsListState: 'loading',
      };
    case SpecificationActionTypes.GET_ALL_SPECIFICATIONS_SUCCESS:
      return {
        ...state,
        specificationsListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        specificationsList: action.payload.data,
        specificationsImplementationList: action.payload.specTypes.includes('implementation')
          ? action.payload.data.implementation || []
          : state.specificationsImplementationList,
        specificationsPcbList: action.payload.specTypes.includes('pcb')
          ? action.payload.data.pcb || []
          : state.specificationsPcbList,
        specificationsCustomFieldList: action.payload.specTypes.includes('custom_field')
          ? action.payload.data.custom_field || []
          : state.specificationsCustomFieldList,
      };
    case SpecificationActionTypes.GET_ALL_SPECIFICATIONS_FAIL:
      return {
        ...state,
        specificationsListState: 'error',
        specificationsListError: action.payload,
      };
    case SpecificationActionTypes.GET_SPECIFICATION_BY_ID:
      return {
        ...state,
        specificationByIdState: 'loading',
      };
    case SpecificationActionTypes.GET_SPECIFICATION_BY_ID_SUCCESS:
      const specificationsImplementationList =
        action.payload.specType === 'implementation'
          ? upsertResource<IImplementationSpecification>(
              state.specificationsImplementationList,
              action.payload as IImplementationSpecification,
            )
          : state.specificationsImplementationList;
      const specificationsPcbList =
        action.payload.specType === 'pcb'
          ? upsertResource<IPcbSpecification>(state.specificationsPcbList, action.payload as IPcbSpecification)
          : state.specificationsPcbList;
      return {
        ...state,
        specificationByIdState: 'success',
        specificationsList: Object.entries(state.specificationsList).reduce(
          (a, [key, value]) => ({
            ...a,
            [key]: key !== action.payload.specType ? value : upsertResource(value, action.payload),
          }),
          {} as SpecificationList,
        ),
        specificationsImplementationList,
        specificationsPcbList,
      };
    case SpecificationActionTypes.GET_SPECIFICATION_BY_ID_FAIL:
      return {
        ...state,
        specificationByIdState: 'error',
        specificationByIdError: action.payload,
      };
    case SpecificationActionTypes.CREATE_SPECIFICATION:
      return {
        ...state,
        specificationCreateState: 'loading',
      };
    case SpecificationActionTypes.CREATE_SPECIFICATION_SUCCESS:
      return {
        ...state,
        specificationCreateState: 'success',
      };
    case SpecificationActionTypes.CREATE_SPECIFICATION_FAIL:
      return {
        ...state,
        specificationCreateState: 'error',
        specificationCreateError: action.error.message,
      };
    case SpecificationActionTypes.UPDATE_SPECIFICATION:
      return {
        ...state,
        specificationUpdateState: 'loading',
      };
    case SpecificationActionTypes.UPDATE_SPECIFICATION_SUCCESS:
      return {
        ...state,
        specificationUpdateState: 'success',
      };
    case SpecificationActionTypes.UPDATE_SPECIFICATION_FAIL:
      return {
        ...state,
        specificationUpdateState: 'error',
        specificationUpdateError: action.error.message,
      };
    case SpecificationActionTypes.DELETE_SPECIFICATION:
      return {
        ...state,
        specificationDeleteState: 'loading',
      };
    case SpecificationActionTypes.DELETE_SPECIFICATION_SUCCESS:
      return {
        ...state,
        specificationDeleteState: 'success',
      };
    case SpecificationActionTypes.DELETE_SPECIFICATION_FAIL:
      return {
        ...state,
        specificationDeleteState: 'error',
        specificationDeleteError: action.error.message,
      };
    default:
      return state;
  }
};
