import { ForkEffect, takeLatest, call, CallEffect } from 'redux-saga/effects';
import { ProjectsActionTypes, ProjectStateChangeAction } from 'state_management/actions/projects/ActionTypes';
import { updateProjectState } from './projectCanvasCompileSaga';

export function* projectStateChangeSaga(action: ProjectStateChangeAction): Generator<CallEffect> {
  const { projectId, state } = action.payload;
  yield call(() => updateProjectState(projectId, state));
}

export function* projectStateChangeSagaWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECT_STATE_CHANGE, projectStateChangeSaga);
}
