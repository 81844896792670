import { useContext, useState } from 'react';

import Dialog from 'components/Dialog';

import { useShortcuts } from './shortcutsHook';
import { ShortcutsCheatSheetContext } from './ShortcutsCheatSheetContext';

import { createShortcutKeyString } from './utils/createShortcutKeyString';

import { Container, SectionContainer, SectionTitle, ShortcutDetails } from './styles';

const ShortcutsCheatSheet = (): JSX.Element => {
  const [isCheatSheetOpen, setIsCheatSheetOpen] = useState(false);
  const { subscribedShortcuts } = useContext(ShortcutsCheatSheetContext);

  const toggleCheatSheet = (): void => {
    setIsCheatSheetOpen(!isCheatSheetOpen);
  };

  useShortcuts('shortcuts-cheat-sheet-id', 'Global', [
    {
      key: '?',
      shift: true,
      description: 'Open Keyboard shortcuts cheatsheet',
      handler: toggleCheatSheet,
    },
  ]);

  return (
    <Dialog heading="Keyboard shortcuts" open={isCheatSheetOpen} onClose={toggleCheatSheet}>
      <Container>
        {subscribedShortcuts?.map((s) => (
          <SectionContainer key={s.id}>
            <SectionTitle>{s.title}</SectionTitle>
            <div>
              {s.keyMaps?.map((k) => (
                <ShortcutDetails key={k.key || k.keys?.toString()}>
                  <p>{k.description}</p>
                  <kbd>{createShortcutKeyString(k)}</kbd>
                </ShortcutDetails>
              ))}
            </div>
          </SectionContainer>
        ))}
      </Container>
    </Dialog>
  );
};

export default ShortcutsCheatSheet;
