import {
  call,
  select,
  SelectEffect,
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  race,
  take,
  RaceEffect,
  retry,
  all,
  AllEffect,
} from 'redux-saga/effects';
import i18n from 'i18n/config';

import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { ProjectsActionTypes } from 'state_management/actions/projects/ActionTypes';
import {
  DashboardProjectTypes,
  FileType,
  IDownloadFileType,
  IPaymentFileType,
} from 'state_management/reducers/projects/Modals';
import { AxiosError, AxiosResponse } from 'axios';
import {
  projectDownloadFileBomErrorAction,
  projectDownloadFileBomSuccessAction,
  projectDownloadFileLayoutErrorAction,
  projectDownloadFileLayoutSuccessAction,
  projectDownloadFileSchematicsErrorAction,
  projectDownloadFileSchematicsSuccessAction,
  projectDownloadFilePdfSuccessAction,
  projectDownloadFilePdfErrorAction,
  projectDownloadFileSchematicsPaymentAction,
  projectDownloadFileLayoutPaymentAction,
  MACRO_RES_PROJECTS_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { downloadFile } from 'utils/files/downloadFile';
import { AppState } from 'state_management/AppState';
import { getLocalProjectById } from 'services/localProjectsService';
import { getProjectType } from 'utils/project';
import { AVNET_BOM_ENDPOINT } from 'routes/getRoutes';
import { getErmisGetExceptionAction } from 'state_management/actions/ermis/ermisActions';
import dataLayer from 'services/dataLayer';
import { submitFormOnForeignOrigin } from './utils/submitFormOnForeignOrigin';

const EXIT_CODE = 'EXIT_CODE';
const NOT_TRIGGERED_CODE = 'NOT_TRIGGERED_CODE';
const CONVERTER_DAG = 'converter_routing';
const PDF_DAG = 'pdf_generator';
const PROJECT_OUTPUTS_ENDPOINT = 'dataservice/object-storage/project-outputs';

type FileToolType = Raw.CadToolID | 'json' | 'csv' | undefined;
class PaymentError extends Error {}

const makeQuery = (
  endpoint: string,
  projectId: string,
  cadTool: FileToolType,
  fileType: IDownloadFileType | 'electra',
  bomType?: Raw.BomType,
): string => {
  let query = `project="${projectId}";type="${fileType}"`;
  if (endpoint === PROJECT_OUTPUTS_ENDPOINT && fileType !== 'pdf') {
    if (fileType === 'bom') {
      query = `${query};extra.cad="${cadTool}";extra.bom_type="${bomType}"`;
    } else {
      query = `${query};extra.cad="${cadTool}"`;
    }
  }
  return query;
};

const determineProcessId = (fileType: IDownloadFileType): string => {
  /*
    Determines which DAG to run based on type of the file
  */
  if (fileType === 'pdf') {
    return PDF_DAG;
  }
  return CONVERTER_DAG;
};

const generateRunId = (projectId: string, fileType: IDownloadFileType): string => `${projectId}_${fileType}`;

const getObjectStorageObjectId = async (
  endpoint: string,
  projectId: string,
  fileType: IDownloadFileType | 'electra',
  cadTool: FileToolType,
  bomType?: Raw.BomType,
): Promise<string | typeof EXIT_CODE> => {
  /*
    Returns ObjectID for the object storage resource if it exists
  */
  const query = makeQuery(endpoint, projectId, cadTool, fileType, bomType);
  const response = (await axiosInstance.get(apiUri(`${endpoint}?pg=0&pg_len=1&query=${query}`, 2))) as AxiosResponse<
    Array<{ id: string }>
  >;

  if (response.data.length === 0) {
    return EXIT_CODE;
  }
  return response.data[0].id;
};

const getProjectFilesLocked = async (
  endpoint: string,
  projectId: string,
  fileType: IPaymentFileType,
  cadTool: string,
): Promise<boolean> => {
  let query = '';
  if (fileType === 'schematics' || fileType === 'both') query = makeQuery(endpoint, projectId, cadTool, 'schematics');
  else query = makeQuery(endpoint, projectId, cadTool, 'layout');

  const response = (await axiosInstance.get(apiUri(`${endpoint}?pg=0&pg_len=1&query=${query}`, 2))) as AxiosResponse<
    Array<{ id: string; extra?: { [key: string]: any } }>
  >;
  return response.data.length > 0 && response.data[0].extra?.unlocked;
};

const generateDownloadLink = (objectId: string): string =>
  `${PROJECT_OUTPUTS_ENDPOINT}/${objectId}/download?fileContentData=true`;

const generatePatchLink = (objectId: string): string => `${PROJECT_OUTPUTS_ENDPOINT}/${objectId}`;

function* projectFilesDownloadPoolingSaga(
  id: string,
  fileType: IDownloadFileType,
): Generator<CallEffect<AxiosResponse<Raw.IProjectFileDownloadStatus>> | typeof EXIT_CODE> {
  try {
    const processId = determineProcessId(fileType);
    const runId = generateRunId(id, fileType);
    const response = (yield call(() =>
      axiosInstance.get(apiUri(`/process/${processId}/${runId}`, 2)),
    )) as AxiosResponse<Raw.IProjectFileDownloadStatus>;

    if (['running', 'queued'].includes(response.data.state)) {
      throw new Error('Not finished yet');
    }

    if (response.data.state === 'failed') {
      return EXIT_CODE;
    }
    return response;
  } catch (error) {
    if ((error as AxiosError)?.response?.status === 500) {
      return EXIT_CODE;
    }
    if ((error as AxiosError)?.response?.status === 404) {
      return NOT_TRIGGERED_CODE;
    }

    throw new Error('Not finished yet');
  }
}

function* triggerDAG(
  id: string,
  userId: string,
  fileType: IDownloadFileType,
  cadTool: FileToolType,
  pdfMode: string,
  dagConf: Record<string, string>,
  routing: boolean,
  numberOfLayers: number,
  bomType?: Raw.BomType,
  objectId?: string,
): Generator<
  | RaceEffect<
      | TakeEffect
      | CallEffect<
          Generator<typeof EXIT_CODE | CallEffect<AxiosResponse<Raw.IProjectFileDownloadStatus>> | CallEffect<string>>
        >
    >
  | CallEffect
  | RaceEffect<
      | TakeEffect
      | CallEffect<AxiosResponse<{ project?: string; mode: string; config: string; user: string; item: string }>>
    >,
  string
> {
  const processId = determineProcessId(fileType);
  const runId = generateRunId(id, fileType);
  const pdfRouting = routing && pdfMode.includes('layout');
  // NOTE not using userId from the project because if it is a shared project, it would be the owner
  let config = {
    user: userId,
    item: id,
    domain: window.location.host,
    number_of_layers: numberOfLayers,
  };
  config =
    fileType === 'pdf'
      ? {
          ...config,
          project: id,
          pdf_mode: pdfMode,
          routing: pdfRouting,
        }
      : {
          ...dagConf,
          ...config,
          mode: fileType,
          cad: cadTool,
          routing,
          operation: 'export',
        };

  let triggerDag = false;
  // NOTE: We must check if the DAG is not currently running otherwise it will end-up in a 409 error
  try {
    const response = (yield call(() => projectFilesDownloadPoolingSaga(id, fileType))) as
      | string
      | Raw.IProjectFileDownloadStatus;
    triggerDag = response === NOT_TRIGGERED_CODE || response === EXIT_CODE || response.data.state === 'success';
  } catch {}

  if (triggerDag) {
    yield race({
      task: call(() => axiosInstance.post(apiUri(`/process/${processId}/${runId}`, 2), config)),
      cancel: take(ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_CANCEL),
    });
  }

  const { response } = (yield race({
    // NOTE: Retry until 1Hour = 1800 retires * every 2000 ms
    response: retry(1800, 2000, projectFilesDownloadPoolingSaga, id, fileType),
    cancel: take(ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_CANCEL),
  })) as { response: CallEffect<AxiosResponse<Raw.IProjectFileDownloadStatus>> | typeof EXIT_CODE };

  if (response === EXIT_CODE) {
    throw new Error(`Download ${fileType} project file failed`);
  }

  if (!objectId) {
    return (yield call(() =>
      getObjectStorageObjectId(PROJECT_OUTPUTS_ENDPOINT, id, fileType, cadTool, bomType),
    )) as string;
  }

  return objectId;
}

function shouldTriggerDag(
  id: string,
  fileType: IDownloadFileType,
  bomFileType?: string,
): Promise<AxiosResponse<Raw.ICheckTrigger>> {
  const requires_generation_url = `${MACRO_RES_PROJECTS_ENDPOINT}/${id}/requires-generation/${fileType}`;
  const final_requires_generation_url = bomFileType
    ? `${requires_generation_url}?bom_file_type=${bomFileType}`
    : requires_generation_url;
  return axiosInstance.get(apiUri(final_requires_generation_url, 2));
}

export const generateAvnetBOM = (
  projectName: string,
  parts: Array<Record<string, string>>,
): Array<{ name: string; value: string }> => {
  const partDetails = parts
    .map((item) => ({ partNo: item['Part Number'], quantity: item.Quantity }))
    .reduce(
      // NOTE: join partNo that come from different manufacturers
      // as manufacturer is not specified when sending to the API
      // and all items should be unique
      (prev, current): Record<string, { partNo: string; quantity: string }> => ({
        ...prev,
        [current.partNo]: {
          ...current,
          quantity: (parseInt(current.quantity, 10) + parseInt(prev[current.partNo]?.quantity || '0', 10)).toString(),
        },
      }),
      {} as Record<string, { partNo: string; quantity: string }>,
    );

  const values = [
    {
      name: 'partMap',
      value: JSON.stringify({
        partNoType: 'Manufacturer Part Number',
        partDetails: Object.values(partDetails),
        bomName: projectName,
      }),
    },
  ];

  return values;
};

const exportAvnetBOM = async (projectName: string, downloadLink: string): Promise<void> => {
  const resBom = await axiosInstance.get(AVNET_BOM_ENDPOINT);
  const { url: bomUrl } = resBom.data;

  const res = await axiosInstance.get(downloadLink);
  const parts = res.data as Array<Record<string, string>>;

  const values = generateAvnetBOM(projectName, parts);
  dataLayer.collectExport('avnet bom', bomUrl);
  submitFormOnForeignOrigin('createbom', bomUrl, values);
};

const unlockCadFile = (objectId: string): void => {
  const link = apiUri(generatePatchLink(objectId), 2);
  // TODO: Make a saga for this
  fetch(link).then((response: Response) =>
    response.json().then((data) => {
      fetch(link, {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ extra: { ...data.extra, unlocked: true } }),
      });
    }),
  );
};

export function* projectFilesDownloadSaga(
  fileType: IDownloadFileType,
  unlock = false,
): Generator<SelectEffect | PutEffect | CallEffect<string | boolean | void | unknown>> {
  const {
    projectsState: {
      currentProject: {
        id,
        title,
        cadFilesState,
        cadTool,
        bomType,
        routing,
        userId: projectOwner,
        shareType,
        numberOfLayers,
      },
    },
    authState: {
      userInfo: { _id: userId, isAnonymousUser },
      featurePermissions: permissions,
    },
    projectGeneralSettingsState: { defaultNumberPcbLayers },
  } = (yield select((state) => state)) as AppState;
  const t = i18n.t.bind(i18n);
  let projectId = id;

  if (isAnonymousUser) {
    if (fileType !== 'bom') {
      throw Error(t('supernova:canvas.toasts.fileExportNotAllowed', 'Not allowed to export this file'));
    }

    const _project = getLocalProjectById(projectId);
    const originalProjId = _project.originalProj;

    if (!originalProjId) {
      return;
    }
    projectId = originalProjId;
  }

  const useAvnetBomAPI = permissions.BOM_API.read === true;
  const considerFileLock = permissions.BUY_CAD_FILES?.read === true;

  if (fileType !== 'bom' && !cadTool) {
    throw new Error(
      t(
        'supernova:toasts.projectExportCadToolError',
        'Failed to get cad tool! Please make sure you selected a cad tool and saved it!',
      ),
    );
  }

  if (fileType === 'bom' && useAvnetBomAPI && bomType !== 'consolidated') {
    throw new Error(
      t('supernova:toasts.projectExportConsolidatedBomError', 'Update the BOM Type to "Consolidated" in the settings'),
    );
  }

  const fileTypeMapping: Omit<Record<FileType, IDownloadFileType>, 'pdf'> = {
    sch: 'schematics',
    brd: 'layout',
    bom: 'bom',
  };

  const pdfMode = Object.keys(cadFilesState)
    .filter((key) => cadFilesState[key as FileType] === 'enabled' && fileTypeMapping[key as Exclude<FileType, 'pdf'>])
    .map((key) => fileTypeMapping[key as Exclude<FileType, 'pdf'>])
    .join(',');

  /*
    1. Check if the requested file already exists
      1.1. If yes, and considerFileLock is set to true, check if CAD file is unlocked
        1.1.1. If it is continue to 1.2
        1.1.2. Otherwise break and invoke payment
      1.2. If yes, and it is NOT BOM
        1.2.1 Serve the requested file
      1.3. If yes, and it is BOM, compare its timestamp to the timestamp of related project artifact
        1.2.1. If artifact is newer, trigger converter for project output again
        1.2.2. checks if there is an electra file associated with the project
        1.1.3. if there is an electra file, make routing false
        1.1.4. If artifacts is older, return its id
      1.4. If not
        1.3.1. check if dag is not currently running (the case of refresh or another way to trigger the conversion)
        1.3.2. if not trigger conversion for project output
    2. Download converted file
  */
  let fileTool: FileToolType = cadTool;
  let downloadHandler = (link: string): Promise<void> => downloadFile(link, 'download');
  if (fileType === 'bom') {
    if (useAvnetBomAPI) {
      fileTool = 'json';
      downloadHandler = (link: string): Promise<void> => exportAvnetBOM(title, link);
    } else {
      fileTool = 'csv';
    }
  }
  const projectType = getProjectType(userId, projectOwner as string, shareType);
  const isReadOnly = projectType === DashboardProjectTypes.SHARED_WITH_ME;

  let isUnlocked = (yield call(() =>
    getProjectFilesLocked(PROJECT_OUTPUTS_ENDPOINT, projectId, fileType as IPaymentFileType, fileTool),
  )) as boolean;

  // Only schematic and layout are lockable
  isUnlocked = isUnlocked || !considerFileLock || !['schematics', 'layout'].includes(fileType);

  const dagConf = fileType !== 'bom' ? {} : { bom_type: bomType };
  let objectId: string | undefined;
  if (!isAnonymousUser) {
    if (!isUnlocked && !unlock) {
      throw new PaymentError();
    }

    const bomFileType = fileType === 'bom' ? fileTool : undefined;
    const response = (yield call(() =>
      shouldTriggerDag(projectId, fileType, bomFileType),
    )) as AxiosResponse<Raw.ICheckTrigger>;
    if (response.data.conversion_required) {
      if (isReadOnly) {
        throw new Error(t('supernova:toasts.downloadNotAllowed', "You're not allowed to download this file."));
      }
      objectId = (yield call(() =>
        triggerDAG(
          projectId,
          userId,
          fileType,
          fileTool,
          pdfMode,
          dagConf,
          permissions?.SUPERNOVA_SHOW_PCB_AUTOROUTE_OPTION?.read ? routing : false,
          numberOfLayers || defaultNumberPcbLayers,
          bomType,
          response.data.file_id,
        ),
      )) as string;
    } else {
      objectId = response.data.file_id;
    }
  } else {
    objectId = (yield call(() =>
      getObjectStorageObjectId(PROJECT_OUTPUTS_ENDPOINT, projectId, fileType, fileTool, bomType),
    )) as string;
    if (!objectId || objectId === EXIT_CODE) {
      throw new Error(t('supernova:toasts.bomNotAvailable', 'This file is not available'));
    }
  }

  const downloadLink = generateDownloadLink(objectId) as string;

  if (!isUnlocked && unlock) {
    unlockCadFile(objectId);
  }

  const link = apiUri(downloadLink, 2);
  yield call(() => downloadHandler(link));
}

const actionsByFileType = {
  schematics: {
    success: projectDownloadFileSchematicsSuccessAction,
    error: projectDownloadFileSchematicsErrorAction,
    payment: projectDownloadFileSchematicsPaymentAction,
  },
  layout: {
    success: projectDownloadFileLayoutSuccessAction,
    error: projectDownloadFileLayoutErrorAction,
    payment: projectDownloadFileLayoutPaymentAction,
  },
  bom: {
    success: projectDownloadFileBomSuccessAction,
    error: projectDownloadFileBomErrorAction,
    payment: projectDownloadFileBomSuccessAction,
  },
  pdf: {
    success: projectDownloadFilePdfSuccessAction,
    error: projectDownloadFilePdfErrorAction,
    payment: projectDownloadFilePdfSuccessAction,
  },
};

export const projectFileDownloadSaga = (type: IDownloadFileType) =>
  function* _inner(action: { type: string; unlock?: boolean }): Generator<any, any, any> {
    const fileTypeActions = actionsByFileType[type];
    try {
      yield projectFilesDownloadSaga(type, !!action.unlock);
      dataLayer.collectDownload(type);
      yield put(fileTypeActions.success());
    } catch (error) {
      if (error instanceof PaymentError) {
        // TODO: This is hard-coded 100 components. Need to actually get it from the BOM later
        yield put(fileTypeActions.payment(100, type as IPaymentFileType));
      } else {
        const errorMessage =
          (error as Error)?.message ||
          getErrorMessage(error as AxiosError) ||
          `Downloading ${type} project file failed`;

        dataLayer.collectException('projectFileDownloadSaga.ts.projectFileDownloadSaga', errorMessage, 'error');
        const defaultError = fileTypeActions.error(errorMessage);
        /* try to fetch the exact cause of error from Ermis, */
        const {
          projectsState: {
            currentProject: { id, cadTool },
          },
        } = (yield select((state) => state)) as AppState;
        yield put(getErmisGetExceptionAction({ defaultError, fileType: type, projectId: id, cadTool }));
      }
    }
  };

export function* projectFilesDownloadSagaWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeLatest(ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_SCHEMATICS, projectFileDownloadSaga('schematics')),
    takeLatest(ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_LAYOUT, projectFileDownloadSaga('layout')),
    takeLatest(ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_BOM, projectFileDownloadSaga('bom')),
    takeLatest(ProjectsActionTypes.PROJECT_DOWNLOAD_FILE_PDF, projectFileDownloadSaga('pdf')),
  ]);
}
