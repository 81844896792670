import { IVirtualComponent } from 'state_management/reducers/virtualComponents/Modals';
import { deserializeVirtualComponent } from 'utils/virtualComponentsSerializer';
import { IPagination } from 'models/Pagination';
import { IFileUpload } from 'models/IFileUpload';
import { IImplementationSpecification } from 'state_management/reducers/specifications/Modals';
import {
  GetAllVirtualComponentsAction,
  CreateVirtualComponentAction,
  UpdateVirtualComponentAction,
  DeleteVirtualComponentAction,
  VirtualComponentsActionTypes,
  GetVirtualComponentByIdAction,
  GetVirtualComponentByIdSuccessAction,
  GetVirtualComponentByIdErrorAction,
  GetAllVirtualComponentsErrorAction,
  GetAllVirtualComponentsSuccessAction,
  CloneVirtualComponentAction,
  GetVirtualComponentDependenciesAction,
  CleanUpVirtualComponentsAction,
  DeleteVirtualComponentCadFileAction,
  DeleteVirtualComponentDocFileAction,
  UpdateVirtualComponentFileAction,
  UploadVirtualComponentCadFileFailAction,
  UploadVirtualComponentCadFilesAction,
  UploadVirtualComponentCadFileSuccessAction,
  UploadVirtualComponentDocFileFailAction,
  UploadVirtualComponentDocFilesAction,
  UploadVirtualComponentDocFileSuccessAction,
} from './ActionTypes';
import { apiUri } from '../../../services/main_app';
import { ENDPOINT_COMPONENTS_FILES } from '../components/componentsActions';

export const ENDPOINT_VIRTUAL_COMPONENTS = 'virtual-components';

export const getAllVirtualComponentsAction = (payload: Partial<IPagination> = {}): GetAllVirtualComponentsAction => ({
  payload,
  type: VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS,
});

export const getAllVirtualComponentsSuccessAction = (
  pagination: IPagination,
  data: Array<IVirtualComponent>,
): GetAllVirtualComponentsSuccessAction => ({
  payload: {
    data,
    pagination,
  },
  type: VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS_SUCCESS,
});

export const getAllVirtualComponentsErrorAction = (payload: string): GetAllVirtualComponentsErrorAction => ({
  payload,
  type: VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS_FAIL,
});

export const getVirtualComponentDependenciesAction = (): GetVirtualComponentDependenciesAction => ({
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
});

export const createVirtualComponent = (virtualComponent: Partial<IVirtualComponent>): CreateVirtualComponentAction => ({
  type: VirtualComponentsActionTypes.CREATE_VIRTUAL_COMPONENT,
  payload: {
    request: {
      method: 'post',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_VIRTUAL_COMPONENTS}`, 2),
      data: deserializeVirtualComponent(virtualComponent) as unknown as Raw.IVirtualComponent,
    },
  },
});

export const updateVirtualComponent = (virtualComponent: IVirtualComponent): UpdateVirtualComponentAction => ({
  type: VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT,
  payload: {
    request: {
      method: 'put',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_VIRTUAL_COMPONENTS}/${virtualComponent.id}`, 2),
      data: deserializeVirtualComponent(virtualComponent) as unknown as Raw.IVirtualComponent,
    },
  },
});

export const cloneVirtualComponent =
  (specificationsImplList?: Array<IImplementationSpecification>) =>
  (virtualComponent: Partial<IVirtualComponent>): CloneVirtualComponentAction => ({
    type: VirtualComponentsActionTypes.CLONE_VIRTUAL_COMPONENT,
    payload: {
      request: {
        method: 'post',
        withCredentials: true,
        url: apiUri(`/macro-resources/${ENDPOINT_VIRTUAL_COMPONENTS}/${virtualComponent.id}/clone`, 2),
        data: deserializeVirtualComponent(
          virtualComponent,
          false,
          specificationsImplList,
        ) as unknown as Raw.IVirtualComponent,
      },
    },
  });

export const deleteVirtualComponent = (virtualComponent: Partial<IVirtualComponent>): DeleteVirtualComponentAction => ({
  type: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT,
  payload: {
    request: {
      method: 'delete',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_VIRTUAL_COMPONENTS}/${virtualComponent.id}`, 2),
    },
  },
});

export const getVirtualComponentById = (payload: Partial<IVirtualComponent>): GetVirtualComponentByIdAction => ({
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID,
  payload: {
    id: payload.id || '',
  },
});

export const getVirtualComponentByIdSuccess = (payload: {
  data: IVirtualComponent;
  docFiles: Array<IFileUpload>;
  cadFiles: Array<IFileUpload>;
}): GetVirtualComponentByIdSuccessAction => ({
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID_SUCCESS,
  payload,
});

export const getVirtualComponentByIdError = (payload: string): GetVirtualComponentByIdErrorAction => ({
  type: VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID_FAIL,
  payload,
});

const COMPONENTS_FILES_ACTION_TYPES = {
  cadFiles: {
    action: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE,
    delete: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_CAD_FILE,
  },
  docFiles: {
    action: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE,
    delete: VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_DOC_FILE,
  },
};

export const uploadVirtualComponentFilesAction =
  (fileType: keyof typeof COMPONENTS_FILES_ACTION_TYPES) =>
  (files: Array<IFileUpload>): UploadVirtualComponentCadFilesAction | UploadVirtualComponentDocFilesAction => ({
    payload: files,
    type: COMPONENTS_FILES_ACTION_TYPES[fileType].action as
      | VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE
      | VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE,
  });

export const uploadVirtualComponentCadFileSuccessAction = (
  fileUpload: IFileUpload,
): UploadVirtualComponentCadFileSuccessAction => ({
  payload: fileUpload,
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_SUCCESS,
});

export const uploadVirtualComponentCadFileFailAction = (
  fileUpload: IFileUpload,
): UploadVirtualComponentCadFileFailAction => ({
  payload: fileUpload,
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_CAD_FILE_FAIL,
});

export const uploadVirtualComponentDocFileSuccessAction = (
  fileUpload: IFileUpload,
): UploadVirtualComponentDocFileSuccessAction => ({
  payload: fileUpload,
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_SUCCESS,
});

export const uploadVirtualComponentDocFileFailAction = (
  fileUpload: IFileUpload,
): UploadVirtualComponentDocFileFailAction => ({
  payload: fileUpload,
  type: VirtualComponentsActionTypes.UPLOAD_VIRTUAL_COMPONENT_DOC_FILE_FAIL,
});

export const deleteVirtualComponentFileAction =
  (fileType: keyof typeof COMPONENTS_FILES_ACTION_TYPES) =>
  (file: IFileUpload): DeleteVirtualComponentCadFileAction | DeleteVirtualComponentDocFileAction => ({
    payload: file,
    type: COMPONENTS_FILES_ACTION_TYPES[fileType].delete as
      | VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_CAD_FILE
      | VirtualComponentsActionTypes.DELETE_VIRTUAL_COMPONENT_DOC_FILE,
  });

export const cleanUpVirtualComponents = (): CleanUpVirtualComponentsAction => ({
  type: VirtualComponentsActionTypes.CLEAN_UP_VIRTUAL_COMPONENTS,
});

export const patchVirtualComponentFile = ({
  fileId,
  patchedData,
}: {
  fileId: string;
  patchedData: Partial<Raw.IMinioResource>;
}): UpdateVirtualComponentFileAction => ({
  type: VirtualComponentsActionTypes.UPDATE_VIRTUAL_COMPONENT_FILE,
  payload: {
    request: {
      method: 'patch',
      withCredentials: true,
      url: apiUri(`/dataservice/${ENDPOINT_COMPONENTS_FILES}/${fileId}`, 2),
      data: patchedData,
    },
  },
});
