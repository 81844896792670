import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  VirtualComponentsActionTypes,
  GetAllVirtualComponentsAction,
  GetAllVirtualComponentsErrorAction,
  GetAllVirtualComponentsSuccessAction,
} from 'state_management/actions/virtualComponents/ActionTypes';

import {
  getAllVirtualComponentsSuccessAction,
  getAllVirtualComponentsErrorAction,
  ENDPOINT_VIRTUAL_COMPONENTS,
} from 'state_management/actions/virtualComponents/virtualComponentsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeVirtualComponent } from 'utils/virtualComponentsSerializer';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';

export function* getAllSaga(
  action: GetAllVirtualComponentsAction,
): Generator<
  | PutEffect<GetAllVirtualComponentsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllVirtualComponentsSuccessAction | GetAllVirtualComponentsErrorAction>
> {
  try {
    const {
      virtualComponentsState: { pagination },
      authState: { userInfo, userSettings },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const virtualComponentsSearchSchema: TypeSearch = {
      internal_part_number: 'value',
      description: 'value',
      full_info: 'value',
      datasheet_url: 'value',
      lifecycle_status: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), virtualComponentsSearchSchema),
      !advancedSearchQuery && !search,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_VIRTUAL_COMPONENTS}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2),
      ),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllVirtualComponentsSuccessAction(_pagination, res.data.map(serializeVirtualComponent)));
  } catch (error) {
    yield put(getAllVirtualComponentsErrorAction(getErrorMessage(error) || 'Fetching virtualComponents failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(VirtualComponentsActionTypes.GET_ALL_VIRTUAL_COMPONENTS, getAllSaga);
}
