import { UnboxingActions, UnboxingActionTypes } from 'state_management/actions/unboxing/ActionTypes';
import { UnboxingState } from './Modals';

export const initialUnboxingState: UnboxingState = {
  welcomeAt: undefined,
  getWelcomeAtError: '',
  getWelcomeAtState: 'idle',
  updateWelcomeAtError: '',
  updateWelcomeAtState: 'idle',
  acceptedConsentIds: [],
};

export const unboxingReducer = (
  state: UnboxingState = initialUnboxingState,
  action: UnboxingActions,
): UnboxingState => {
  switch (action.type) {
    case UnboxingActionTypes.GET_WELCOME_AT: {
      return {
        ...state,
        getWelcomeAtState: 'loading',
      };
    }
    case UnboxingActionTypes.GET_WELCOME_AT_SUCCESS: {
      return {
        ...state,
        welcomeAt: action.payload?.data?.welcomed_at,
        getWelcomeAtState: 'success',
      };
    }
    case UnboxingActionTypes.GET_WELCOME_AT_FAIL: {
      return {
        ...state,
        getWelcomeAtState: 'error',
        getWelcomeAtError: action.error.message,
      };
    }

    case UnboxingActionTypes.UPDATE_WELCOME_AT: {
      return {
        ...state,
        updateWelcomeAtState: 'loading',
      };
    }
    case UnboxingActionTypes.UPDATE_WELCOME_AT_SUCCESS: {
      return {
        ...state,
        welcomeAt: action.payload?.data?.welcomed_at,
        updateWelcomeAtState: 'success',
      };
    }
    case UnboxingActionTypes.UPDATE_WELCOME_AT_FAIL: {
      return {
        ...state,
        updateWelcomeAtState: 'error',
        updateWelcomeAtError: action.error.message,
      };
    }
    case UnboxingActionTypes.SET_ACCEPTED_CONSENT_IDS: {
      return {
        ...state,
        acceptedConsentIds: action.payload,
      };
    }
    default:
      return state;
  }
};
