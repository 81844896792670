import {
  ProjectGeneralSettingsAction,
  ProjectGeneralSettingsActionTypes,
} from 'state_management/actions/projectGeneralSettings/ActionTypes';
import { ProjectGeneralSettingsState } from './Modals';

export const initialProjectGeneralSettingsState: ProjectGeneralSettingsState = {
  requestState: 'idle',
  requestError: '',
  cadTools: [],
  defaultCadToolID: undefined,
  bomTypes: [],
  defaultBomTypeID: undefined,
  numberPcbLayersOptions: [2, 4, 6],
  defaultNumberPcbLayers: 6,
};

export const projectGeneralSettingsReducer = (
  state: ProjectGeneralSettingsState = initialProjectGeneralSettingsState,
  action: ProjectGeneralSettingsAction,
): ProjectGeneralSettingsState => {
  switch (action.type) {
    case ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS:
      return {
        ...initialProjectGeneralSettingsState,
        requestState: 'loading',
      };
    case ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        cadTools: action.payload.cadTools,
        defaultCadToolID: action.payload.defaultCadToolID,
        bomTypes: action.payload.bomTypes,
        defaultBomTypeID: action.payload.defaultBomTypeID,
        requestState: 'success',
        requestError: '',
      };
    case ProjectGeneralSettingsActionTypes.GET_PROJECT_GENERAL_SETTINGS_FAIL:
      return {
        ...initialProjectGeneralSettingsState,
        requestError: action.payload,
        requestState: 'error',
      };
    default:
      return state;
  }
};
