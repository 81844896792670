import i18n from 'i18n/config';
import { DependencyList, EffectCallback, useEffect } from 'react';

type Effect = EffectCallback | ((lang: string) => ReturnType<EffectCallback>);

// NOTE: Detect any possible language change
export const useLocaleEffect = (effect: Effect, deps: DependencyList = []): void => {
  const { language, languages = [] } = i18n;

  useEffect(() => effect(languages.includes(language) ? language : 'en'), [...deps, language, languages]);
};
