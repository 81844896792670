import {
  call,
  select,
  SelectEffect,
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  retry,
  all,
  AllEffect,
  take,
  race,
  RaceEffect,
} from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  ProjectsActionTypes,
  ProjectSaveSuccessAction,
  ProjectsGetByIdSuccessAction,
  ProjectStateGetAction,
  ProjectStateGetSuccessAction,
  ProjectsUpdateSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  getProjectState,
  getProjectStateSuccess,
  getProjectStateFail,
  projectCanvasCompileSuccessAction,
  projectCanvasCompileErrorAction,
  DS_PROJECT_STATE_ENDPOINT,
} from 'state_management/actions/projects/projectsActions';
import { Project, CompilationState } from 'state_management/reducers/projects/Modals';
import { globalToast } from 'components/ToastComponent';
import { serializeProjectState } from 'utils/projectSerializer';
import { AppState } from 'state_management/AppState';
import { getProjectCompiledState } from 'state_management/reducers/projects/utils/projectCompileState';
import { getDesignCanvas } from 'state_management/actions/systemDesignCanvas/systemDesignCanvasActions';
import i18n from 'i18n/config';
import dataLayer from 'services/dataLayer';
import { updateProjectState } from './projectCanvasCompileSaga';
import {
  errorStatesOfRunningStates,
  getDagInfoByCanvas,
  getDagStatus,
  handleDAGRunning,
  handleGetDAGStatusError,
} from './utils/airflow';

async function docExists(endpoint: string): Promise<boolean> {
  try {
    (await axiosInstance.head(apiUri(endpoint, 2))) as AxiosResponse;
    return true;
  } catch (error) {
    return false;
  }
}

export async function getProjectStateData(
  projectId: string,
): Promise<
  Pick<Project, 'canvasStates' | 'cadFilesState' | 'compilationStates' | 'compilerState'> & { compilerError?: string }
> {
  const res = (await axiosInstance.get(
    apiUri(`${DS_PROJECT_STATE_ENDPOINT}/${projectId}`, 2),
  )) as AxiosResponse<Raw.ProjectState>;

  const _newCompilerState = res.data.state;
  const _newCompilerError = res.data.error?.error_msg;

  const serializedProjectState = serializeProjectState(res.data);

  return {
    compilerState: _newCompilerState,
    compilerError: _newCompilerError,
    canvasStates: serializedProjectState.canvasStates,
    cadFilesState: serializedProjectState.cadFilesState,
    compilationStates: serializedProjectState.compilationStates,
  };
}

function* projectStatePoolingSaga(
  projectId: string,
): Generator<PutEffect<ProjectStateGetSuccessAction> | TakeEffect | CallEffect | PutEffect | SelectEffect> {
  const t = i18n.t.bind(i18n);

  const projStateData = (yield call(() => getProjectStateData(projectId))) as Pick<
    Project,
    'canvasStates' | 'cadFilesState' | 'compilationStates' | 'compilerState'
  > & { compilerError?: string };

  let _newCanvasStates = projStateData.canvasStates;
  const _newCompilerState = projStateData.compilerState;
  const _newCompilerError = projStateData.compilerError;
  const _newCadFilesState = projStateData.cadFilesState;
  const _newCompilationStates = projStateData.compilationStates;

  const {
    projectsState: {
      currentProject: { compilerState },
    },
  } = (yield select((_state: AppState) => _state)) as AppState;

  // NOTE: if the boardCanvas is disabled, but the floorplanning document exists, then it is a recompilation
  if (_newCanvasStates.boardCanvas === 'disabled') {
    const floorplanningExists = (yield call(() =>
      docExists(`/dataservice/compiler/floorplanning/${projectId}`),
    )) as boolean;
    if (floorplanningExists) {
      _newCanvasStates = { ..._newCanvasStates, boardCanvas: 'warning' };
    }
  }

  // Check whether we should update the tabs or not
  if (_newCompilerState && compilerState !== _newCompilerState) {
    yield put(
      getProjectStateSuccess({
        canvasStates: _newCanvasStates,
        compilerState: _newCompilerState,
        cadFilesState: _newCadFilesState,
        compilationStates: _newCompilationStates,
        projectCompiledState: getProjectCompiledState(_newCompilationStates),
      }),
    );
  }

  const newProjectState = {
    compilerState: _newCompilerState,
    compilerError: _newCompilerError,
    canvasStates: _newCanvasStates,
    cadFilesState: _newCadFilesState,
    compilationStates: _newCompilationStates,
  };

  if ([_newCompilationStates.board, _newCompilationStates.design].includes('error' as CompilationState)) {
    return newProjectState;
  }

  let canvas: 'design' | 'board' | undefined;
  if (['running', 'success'].includes(_newCompilationStates.design)) {
    canvas = 'design';
  } else if (['running', 'success'].includes(_newCompilationStates.board)) {
    canvas = 'board';
  }

  if (!canvas) {
    // NOTE: DAG neither failed nor is running
    return newProjectState;
  }

  const { dagName: processId } = getDagInfoByCanvas(canvas);
  const runId = projectId;

  const dagStatusRes = (yield call(() =>
    getDagStatus(processId, runId, handleDAGRunning('Not finished yet'), handleGetDAGStatusError('Not finished yet')),
  )) as Raw.IDAGStatus;

  if (dagStatusRes.state === 'failed') {
    // NOTE: Handle in-between states failures
    if (Object.keys(errorStatesOfRunningStates).includes(_newCompilerState)) {
      yield call(() =>
        updateProjectState(projectId, errorStatesOfRunningStates[_newCompilerState], {
          error_msg: t('supernova:canvas.toasts.compilationError', 'Project compilation failed'),
        }),
      );
      // Needed to fetch the state with the error and trigger the FAIL action
      throw new Error('State not finished');
    }
  } else if ([_newCompilationStates.board, _newCompilationStates.design].includes('running' as CompilationState)) {
    // Throw to keep retrying for state as `retry` would go again on throw
    throw new Error('State not finished');
  }

  return newProjectState;
}

export function* getProjectStateSaga(
  action: ProjectStateGetAction,
): void | Generator<
  RaceEffect<TakeEffect | CallEffect> | TakeEffect | CallEffect | PutEffect | SelectEffect | boolean
> {
  const t = i18n.t.bind(i18n);
  try {
    const { initialState, canvasVersion } = action.payload;

    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
      projectsState: {
        currentProject: { id },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    // NOTE: If AnonUser (i.e Local Project) don't make state GET request
    if (isAnonymousUser) {
      return;
    }

    const {
      response: { compilerState, compilerError, canvasStates, cadFilesState, compilationStates },
    } = (yield race({
      // NOTE: Retry until 1Hour = 1800 retires * every 2000 ms
      response: retry(1800, 2000, projectStatePoolingSaga, id),
      cancel: take(ProjectsActionTypes.PROJECT_STATE_GET_CANCEL),
    })) as {
      response: Pick<Project, 'cadFilesState' | 'canvasStates' | 'compilerState' | 'compilationStates'> & {
        compilerError?: string;
      };
    };

    yield put(
      getProjectStateSuccess({
        canvasStates,
        compilerState,
        cadFilesState,
        compilationStates,
        projectCompiledState: getProjectCompiledState(compilationStates),
      }),
    );

    // NOTE: Only run when not first load, i.e no initialState was provided
    if (initialState) {
      // NOTE: Get Canvas after compilation on Canvas v2 which would include compilation results for individual elements
      if (canvasVersion === '2') {
        yield put(getDesignCanvas(id, canvasVersion));
      }
      // NOTE: Error handling for compilation
      if (compilerError) {
        dataLayer.collectException(
          'getProjectStateSaga.ts.getProjectStageSaga',
          `compilation failed - ${compilerError}`,
          'warning',
        );
        yield put(
          projectCanvasCompileErrorAction(
            getProjectCompiledState(
              compilationStates,
              compilerError || t('supernova:canvas.toasts.projectGenerateError', 'Project Generation failed'),
            ),
          ),
        );
      } else {
        dataLayer.collectCompilation(initialState, 'success');
        // NOTE: Compilation success
        yield put(projectCanvasCompileSuccessAction());

        globalToast(undefined, 'success', {
          success: t('supernova:canvas.toasts.projectResolveSuccess', 'Project has been successfully resolved.'),
        });
      }
    }
  } catch (error) {
    yield put(
      getProjectStateFail(
        getErrorMessage(error) || t('supernova:canvas.toasts.projectStateGetError', 'Fetching Project State failed'),
      ),
    );
  }
}

export function* reloadProjectStateSaga(
  action: ProjectSaveSuccessAction | ProjectsGetByIdSuccessAction | ProjectsUpdateSuccessAction,
): Generator<PutEffect<ProjectStateGetAction>> {
  if (action.type === ProjectsActionTypes.PROJECTS_GET_BY_ID_SUCCESS) {
    const { designCompileRequest, boardCompileRequest } = getProjectCompiledState(action.payload.compilationStates);

    if (['idle', 'loading'].includes(designCompileRequest) || ['idle', 'loading'].includes(boardCompileRequest)) {
      yield put(getProjectState());
    }
  } else {
    yield put(getProjectState());
  }
}

export function* projectStateSagaWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeLatest(
      [
        ProjectsActionTypes.PROJECT_SAVE_SUCCESS,
        ProjectsActionTypes.PROJECTS_UPDATE_SUCCESS,
        ProjectsActionTypes.PROJECTS_GET_BY_ID_SUCCESS,
      ],
      reloadProjectStateSaga,
    ),
    takeLatest(ProjectsActionTypes.PROJECT_STATE_GET, getProjectStateSaga),
  ]);
}
