import styled from 'styled-components';

export const Container = styled.div`
  && {
    width: 100%;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
`;

export const InputsContainer = styled.div`
  && {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
`;

export const HorizontalContainer = styled.div<{ disabled?: boolean }>`
  && {
    ${({ disabled }): string =>
      disabled
        ? `display: flex;
    flex-direction: row;
    width: 100%;
    & div:first-child + div {
      margin-left: 20px !important;
      margin-top: -2px !important;
    }
    `
        : ''}
  }
`;

export const BetweenContainer = styled.div<{ disabled?: boolean }>`
  && {
    ${({ disabled }): string =>
      disabled
        ? `display: flex;
    flex-direction: row;
    width: 100%;
    & div:first-child + div {
      margin-left: 20px !important;
    }
    `
        : ''}
  }
`;

export const AutoCompleteContainer = styled.div`
  && {
    width: 100%;
    margin-top: 12px;
    & div {
      grid-gap: 0px !important;
    }
  }
`;

export const FieldContainer = styled.div`
  && {
    width: 100%;
    margin-top: 9px;
    margin-bottom: 10px;
    & div {
      grid-gap: 0px !important;
    }
  }
`;

export const DownContainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
    }
  }
`;

export const ResetContainer = styled.div<{ disabled: boolean }>`
  && {
    & button:first-child {
      text-transform: none;
      text-decoration: underline;
      color: ${({ theme, disabled }): string => (disabled ? theme.palette.disabled : theme.palette.accent)};
      &:hover {
        background-color: ${({ theme }): string => theme.palette.accentHover};
      }
    }
  }
`;

export const ValueContainer = styled.div`
  && {
    margin-top: 3px;
  }
`;
