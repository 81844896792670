import { all, AllEffect, takeEvery, ForkEffect } from 'redux-saga/effects';

import { globalToast } from 'components/ToastComponent';
import {
  BillOfMaterialActionTypes,
  InitHierarchicalBomErrorAction,
} from 'state_management/actions/billOfMaterial/ActionTypes';

const TOAST_IDS: { [key: string]: string | undefined } = {};

const initHierarchicalBomToasts = (action: InitHierarchicalBomErrorAction): void => {
  TOAST_IDS.create = globalToast(
    TOAST_IDS.create,
    action.type,
    {
      loading: '',
      success: '',
      error: (action as InitHierarchicalBomErrorAction).payload,
    },
    { autoClose: false },
    { onClose: () => delete TOAST_IDS.create },
  );
};

export function* requestsToastsWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([takeEvery([BillOfMaterialActionTypes.INIT_HIERARCHICAL_BOM_FAIL], initHierarchicalBomToasts)]);
}
