import styled from 'styled-components';

export const Container = styled.div`
  height: 42px;
  box-shadow: ${({ theme }): string => theme.shadow.normal};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  background-color: white;

  & > button {
    width: 42px;
  }
`;
export const IconButton = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
