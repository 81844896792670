import i18next from 'i18next';

// NOTE: Can't load truly dynamically as Moment has issue with Webpack/CRA
// https://github.com/moment/moment/issues/4505
export const loadLocals = async (lang: string): Promise<void> => {
  try {
    switch (lang) {
      case 'de':
        await import('moment/locale/de');
        break;
      case 'nl':
        await import('moment/locale/nl');
        break;
      case 'pt':
        await import('moment/locale/pt');
        break;
      case 'ru':
        await import('moment/locale/ru');
        break;
      case 'es':
        await import('moment/locale/es');
        break;
      case 'bar':
        await import('moment/locale/de');
        await import('./locale/bar');
        break;
      default:
        break;
    }
  } catch (error) {
    console.log('==================================');
    console.log(`Error while trying to load local for Lang: ${lang}`, error);
    console.log('==================================');
  }
};

export const setLanguageNamespace = (ns: string): void => {
  loadLocals(ns).finally(() => {
    i18next.changeLanguage(ns);
  });
};
