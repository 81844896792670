export enum BaseLayoutActionTypes {
  CHANGE_ACTIVE_LINK = 'CHANGE_ACTIVE_LINK',
}

// ============================================ SIDE_MENU

export interface ChangeActiveLinkAction {
  type: BaseLayoutActionTypes.CHANGE_ACTIVE_LINK;
  payload: string;
}

export type BaseLayoutActions = ChangeActiveLinkAction;
