/* eslint-disable no-lonely-if */
import {
  MinMaxSearchValue,
  MultipleValuesSearchValue,
  SearchFieldsOptions,
  SearchValue,
  TypToleranceSearchValue,
  SimpleSearchValue,
  BetweenSearchValue,
  OperationData,
} from 'components/AdvancedSearch/Modals';
import { OptionModel } from 'components/Select/Modals';
import { NO_UNIT_OPTION } from 'components/SpecificationsForm/constants';

export const getOperatorDisplayLabel = (
  values: SearchValue | MultipleValuesSearchValue,
  field: SearchFieldsOptions,
  chipDisplayLabel: string,
): string => {
  if (!values || !field?.inputType) return chipDisplayLabel;

  let displayLabel = '';
  if (field.inputType === 'single') {
    let value;
    if (Array.isArray((values as SearchValue)?.value)) {
      const optionsMap =
        (field.options as Array<OptionModel<string>>)?.reduce(
          (prev: Record<string, string>, curr: OptionModel<string>) => ({
            ...prev,
            [curr.value as string]: curr.label as string,
          }),
          {},
        ) || {};

      value = `[${(((values as SearchValue)?.value as string[]) || [])
        .map((v) => (field.getOptionLabel && field.getOptionLabel(v)) || optionsMap[v] || v)
        ?.join(', ')}]`;
    } else {
      value =
        (field.getOptionLabel && field.getOptionLabel((values as SearchValue)?.value as any)) ||
        (values as SearchValue)?.value;
    }

    const _unit = (values as SearchValue)?.unit || (values as SearchValue)?.maxUnit || '';
    const unit = _unit && ` ${_unit === NO_UNIT_OPTION ? '' : _unit}`;

    if ('max' in values) {
      return `${chipDisplayLabel} ${(values as SearchValue)?.value}${unit} to ${values.max}${unit}`;
    }
    displayLabel = `${chipDisplayLabel} ${value}${unit}`;
  } else if (field.inputType === 'list') {
    const multipleItems = values as MultipleValuesSearchValue;
    if (multipleItems?.value?.length)
      if (Array.isArray(multipleItems?.value))
        displayLabel = `${field?.label} ${chipDisplayLabel} ${multipleItems?.value?.join(', ')}`;
      else
        displayLabel = `${field?.label} ${chipDisplayLabel} ${((multipleItems?.value as string) || '').replace(
          ';',
          ', ',
        )}`;
  }
  return displayLabel;
};

export const getOperatorDisplayLabelMinMax = (
  values: MinMaxSearchValue,
  chipDisplayLabel: string,
  part: keyof MinMaxSearchValue,
): string => {
  let displayLabel = '';
  let operationData: OperationData | undefined;
  if (part === 'min' && values?.min) {
    operationData = values?.min;
  } else if (part === 'max' && values?.max) {
    operationData = values?.max;
  } else if (values?.typ) {
    operationData = values?.typ;
  }
  if (operationData?.data) {
    if (operationData?.operator?.value?.toLowerCase() === 'between') {
      const { minData, maxData } = operationData.data as BetweenSearchValue;
      displayLabel = `${chipDisplayLabel} ${minData?.value} ${
        minData?.unit === NO_UNIT_OPTION ? '' : minData?.unit
      } to ${maxData?.value} ${maxData?.unit === NO_UNIT_OPTION ? '' : maxData?.unit}`;
    } else {
      const { value, unit } = operationData.data as SimpleSearchValue;
      displayLabel = `${chipDisplayLabel} ${value} ${unit === NO_UNIT_OPTION ? '' : unit}`;
    }
  }
  return displayLabel;
};

export const getOperatorDisplayLabelTypTol = (
  values: TypToleranceSearchValue,
  chipDisplayLabel: string,
  part: keyof TypToleranceSearchValue,
): string => {
  let displayLabel = '';
  let operationData: OperationData | undefined;

  if (values && values[part]) operationData = values[part];

  if (operationData?.data) {
    if (operationData?.operator?.value?.toLowerCase() === 'between') {
      const { minData, maxData } = operationData.data as BetweenSearchValue;
      displayLabel = `${chipDisplayLabel} ${minData?.value} ${
        minData?.unit === NO_UNIT_OPTION ? '' : minData?.unit
      } to ${maxData?.value} ${maxData?.unit === NO_UNIT_OPTION ? '' : maxData?.unit}`;
    } else {
      if (operationData?.data) {
        const { value, unit } = operationData.data as SimpleSearchValue;
        displayLabel = `${chipDisplayLabel} ${value} ${unit === NO_UNIT_OPTION ? '' : unit}`;
      }
    }
  }
  return displayLabel;
};

/* NOTE: WE HAVE NO OTHER CHOICE FOR KEEPING THE LEGACY CODE */
/* THE CONSEQUENCE MAYBE SEVERE! */
export const getOperatorDisplayLabelLegacy = (
  values: SearchValue,
  field: SearchFieldsOptions,
  chipDisplayLabel: string,
): string => {
  let displayLabel = '';
  let value;
  if (Array.isArray((values as SearchValue)?.value)) {
    const optionsMap =
      (field.options as Array<OptionModel<string>>)?.reduce(
        (prev: Record<string, string>, curr: OptionModel<string>) => ({
          ...prev,
          [curr.value as string]: curr.label as string,
        }),
        {},
      ) || {};

    value = `[${(((values as SearchValue)?.value as string[]) || [])
      .map((v) => (field.getOptionLabel && field.getOptionLabel(v)) || optionsMap[v] || v)
      ?.join(', ')}]`;
  } else {
    value =
      (field.getOptionLabel && field.getOptionLabel((values as SearchValue)?.value as any)) ||
      (values as SearchValue)?.value;
  }

  const _unit = (values as SearchValue)?.unit || (values as SearchValue)?.maxUnit || '';
  const unit = _unit && ` ${_unit === NO_UNIT_OPTION ? '' : _unit}`;

  if ('max' in values) {
    return `${chipDisplayLabel} ${(values as SearchValue)?.value}${unit} to ${values.max}${unit}`;
  }
  displayLabel = `${chipDisplayLabel} ${value}${unit}`;
  return displayLabel;
};
