import { IPagination } from 'models/Pagination';
import {
  CategoryGelAllByLinkTypes,
  ICategories,
  ICategoriesPagination,
} from 'state_management/reducers/categories/Modals';
import { AxiosRequestPayload, AxiosSuccessPayload, AxiosErrorPayload } from '../../middlewares/axios/Modals';

export enum CategoriesActionTypes {
  GET_ALL_CATEGORIES = 'GET_ALL_CATEGORIES',
  GET_ALL_CATEGORIES_SUCCESS = 'GET_ALL_CATEGORIES_SUCCESS',
  GET_ALL_CATEGORIES_FAIL = 'GET_ALL_CATEGORIES_FAIL',

  GET_ALL_CATEGORIES_BY_LINK = 'GET_ALL_CATEGORIES_BY_LINK',
  GET_ALL_CATEGORIES_BY_LINK_SUCCESS = 'GET_ALL_CATEGORIES_BY_LINK_SUCCESS',
  GET_ALL_CATEGORIES_BY_LINK_FAIL = 'GET_ALL_CATEGORIES_BY_LINK_FAIL',

  GET_CATEGORY_BY_ID = 'GET_CATEGORY_BY_ID',
  GET_CATEGORY_BY_ID_SUCCESS = 'GET_CATEGORY_BY_ID_SUCCESS',
  GET_CATEGORY_BY_ID_FAIL = 'GET_CATEGORY_BY_ID_FAIL',

  GET_CATEGORY_DEPENDENCIES = 'GET_CATEGORY_DEPENDENCIES',

  CREATE_CATEGORY = 'CREATE_CATEGORY',
  CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL',

  UPDATE_CATEGORY = 'UPDATE_CATEGORY',
  UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS',
  UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL',

  CLONE_CATEGORY = 'CLONE_CATEGORY',
  CLONE_CATEGORY_SUCCESS = 'CLONE_CATEGORY_SUCCESS',
  CLONE_CATEGORY_FAIL = 'CLONE_CATEGORY_FAIL',

  DELETE_CATEGORY = 'DELETE_CATEGORY',
  DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL',
}

// ============================================ GET_ALL_CATEGORIES

export interface GetAllCategoriesAction {
  payload: ICategoriesPagination;
  type: CategoriesActionTypes.GET_ALL_CATEGORIES;
}

export interface GetAllCategoriesSuccessAction {
  payload: { pagination: ICategoriesPagination; data: Array<ICategories> };
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_SUCCESS;
}

export interface GetAllCategoriesErrorAction {
  payload: string;
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_FAIL;
}

// ============================================ GET_ALL_CATEGORIES_BY_LINK

export interface GetAllCategoriesByLinkAction {
  payload: { pagination?: Partial<IPagination>; linkType: CategoryGelAllByLinkTypes; linkedResourceId?: string };
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK;
}

export interface GetAllCategoriesByLinkSuccessAction {
  payload: {
    pagination: ICategoriesPagination;
    categoriesItems: Record<string, ICategories>;
    categoriesAncestorsList: Record<string, Array<string>>;
    categoriesDescendantsList: Record<string, Array<string>>;
  };
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK_SUCCESS;
}

export interface GetAllCategoriesByLinkErrorAction {
  payload: string;
  type: CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK_FAIL;
}

// ============================================ GET_CATEGORY_BY_ID

export interface GetCategoryByIdAction {
  payload: { id: string };
  type: CategoriesActionTypes.GET_CATEGORY_BY_ID;
}

export interface GetCategoryByIdSuccessAction {
  payload: {
    data: ICategories;
    parent: ICategories | undefined;
  };
  type: CategoriesActionTypes.GET_CATEGORY_BY_ID_SUCCESS;
}

export interface GetCategoryByIdErrorAction {
  payload: string;
  type: CategoriesActionTypes.GET_CATEGORY_BY_ID_FAIL;
}

// ============================================ GET_CATEGORY_DEPENDENCIES

export interface GetCategoryDependenciesAction {
  type: CategoriesActionTypes.GET_CATEGORY_DEPENDENCIES;
}

// ============================================ CREATE_CATEGORY

export interface CreateCategoryAction extends AxiosRequestPayload<Raw.Category> {
  type: CategoriesActionTypes.CREATE_CATEGORY;
}

export interface CreateCategorySuccessAction
  extends AxiosSuccessPayload<Raw.Category, CategoriesActionTypes.CREATE_CATEGORY_SUCCESS, CreateCategoryAction> {
  type: CategoriesActionTypes.CREATE_CATEGORY_SUCCESS;
}

export interface CreateCategoryErrorAction
  extends AxiosErrorPayload<CategoriesActionTypes.CREATE_CATEGORY_FAIL, CreateCategoryAction> {
  type: CategoriesActionTypes.CREATE_CATEGORY_FAIL;
}

// ============================================ UPDATE_CATEGORY

export interface UpdateCategoryAction extends AxiosRequestPayload<Raw.Category> {
  type: CategoriesActionTypes.UPDATE_CATEGORY;
}
export interface UpdateCategorySuccessAction
  extends AxiosSuccessPayload<Raw.Category, CategoriesActionTypes.UPDATE_CATEGORY_SUCCESS, UpdateCategoryAction> {
  type: CategoriesActionTypes.UPDATE_CATEGORY_SUCCESS;
}
export interface UpdateCategoryErrorAction
  extends AxiosErrorPayload<CategoriesActionTypes.UPDATE_CATEGORY_FAIL, UpdateCategoryAction> {
  type: CategoriesActionTypes.UPDATE_CATEGORY_FAIL;
}

// ============================================ CLONE_CATEGORY

export interface CloneCategoryAction extends AxiosRequestPayload<Raw.Category> {
  type: CategoriesActionTypes.CLONE_CATEGORY;
}

export interface CloneCategorySuccessAction
  extends AxiosSuccessPayload<Raw.Category, CategoriesActionTypes.CLONE_CATEGORY_SUCCESS, CloneCategoryAction> {
  type: CategoriesActionTypes.CLONE_CATEGORY_SUCCESS;
}

export interface CloneCategoryErrorAction
  extends AxiosErrorPayload<CategoriesActionTypes.CLONE_CATEGORY_FAIL, CloneCategoryAction> {
  type: CategoriesActionTypes.CLONE_CATEGORY_FAIL;
}

// ============================================ DELETE_CATEGORY

export interface DeleteCategoryAction extends AxiosRequestPayload {
  type: CategoriesActionTypes.DELETE_CATEGORY;
}
export interface DeleteCategorySuccessAction
  extends AxiosSuccessPayload<string, CategoriesActionTypes.DELETE_CATEGORY_SUCCESS, DeleteCategoryAction> {
  type: CategoriesActionTypes.DELETE_CATEGORY_SUCCESS;
}
export interface DeleteCategoryErrorAction
  extends AxiosErrorPayload<CategoriesActionTypes.DELETE_CATEGORY_FAIL, DeleteCategoryAction> {
  type: CategoriesActionTypes.DELETE_CATEGORY_FAIL;
}

// ============================================ ALL

export type CategoryActions =
  | GetAllCategoriesAction
  | GetAllCategoriesSuccessAction
  | GetAllCategoriesErrorAction
  | GetAllCategoriesByLinkAction
  | GetAllCategoriesByLinkSuccessAction
  | GetAllCategoriesByLinkErrorAction
  | GetCategoryDependenciesAction
  | CreateCategoryAction
  | CreateCategorySuccessAction
  | CreateCategoryErrorAction
  | UpdateCategoryAction
  | UpdateCategorySuccessAction
  | UpdateCategoryErrorAction
  | CloneCategoryAction
  | CloneCategorySuccessAction
  | CloneCategoryErrorAction
  | DeleteCategoryAction
  | DeleteCategorySuccessAction
  | DeleteCategoryErrorAction
  | GetCategoryByIdAction
  | GetCategoryByIdSuccessAction
  | GetCategoryByIdErrorAction;
