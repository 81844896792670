import { IBlockV2, ILink } from 'models/ICanvas';

export enum LocalSnapshotsActionTypes {
  LOAD_BLOCK_SETTINGS_SNAPSHOT = 'LOAD_BLOCK_SETTINGS_SNAPSHOT',
  LOAD_LINK_SETTINGS_SNAPSHOT = 'LOAD_LINK_SETTINGS_SNAPSHOT',
  CLEAR_SETTINGS_SNAPSHOT = 'CLEAR_SETTINGS_SNAPSHOT',
}

export interface LoadBlockSettingsSnapshotActionType {
  payload: IBlockV2;
  type: LocalSnapshotsActionTypes.LOAD_BLOCK_SETTINGS_SNAPSHOT;
}

export interface LoadLinkSettingsSnapshotActionType {
  payload: ILink;
  type: LocalSnapshotsActionTypes.LOAD_LINK_SETTINGS_SNAPSHOT;
}

export interface ClearSettingsSnapShotActionType {
  type: LocalSnapshotsActionTypes.CLEAR_SETTINGS_SNAPSHOT;
}

export type LocalSnapshotsActionsTypes =
  | LoadLinkSettingsSnapshotActionType
  | LoadBlockSettingsSnapshotActionType
  | ClearSettingsSnapShotActionType;
