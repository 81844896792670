import { Project } from 'state_management/reducers/projects/Modals';
import { User } from '../state_management/reducers/auth/Modals';

/* DataLayer */

const checkDataLayer = (): void => {
  if (typeof window !== 'undefined' && typeof window.dataLayer !== 'object') {
    window.dataLayer = [];
  }
};

const pushEventToDataLayer = (data: Record<string, any>): void => {
  checkDataLayer();
  window.dataLayer.push(data);
};

/* General */

const collectEvent = (eventName: string, extra?: Record<string, any>): void => {
  pushEventToDataLayer({
    event: eventName,
    ...(extra || {}),
  });
};

// If App re-renders a lot this can be wasteful, so adding `window.dataLayer.length >= 4 ||`
// as an extra clause would help keep it down
const alreadyAddedBasicData = (): boolean => {
  checkDataLayer();
  return window.dataLayer.filter((i: Record<string, any>) => i.appType && i.appType.length > 0).length > 0;
};

const initializeDLAttributes = (userInfo: User): void => {
  if (alreadyAddedBasicData()) return;
  pushEventToDataLayer({
    appType: userInfo.app_type,
    isUserAdmin: userInfo.admin,
    userRoles: userInfo.roles,
    userGroups: userInfo.groups,
    targetHosts: userInfo.target_hosts,
    userId: userInfo._id,
    dataLayerVersion: 2.0,
  });
};

/* Supernova */

const collectSupernovaEvent = (resource: string | undefined, action: string, extra?: Record<string, any>): void => {
  collectEvent(`supernova.${resource}.${action}`, extra);
};

const collectCreateProjectEvent = (newProject: Raw.Project): void => collectEvent('create_project', { newProject });

const saveCloneProjectData = (newProject: Project | undefined, originProject: Project | undefined): void => {
  localStorage.setItem('create_project', JSON.stringify({ newProject, originProject }));
};

const collectCloneProjectEvent = (newProject: Project | undefined, originProject: Project | undefined): void => {
  collectEvent('create_project', { newProject, originProject });
};

const collectLoadProject = (project: Project): void => {
  collectEvent('load_project', { project });
};

const collectSaveProjectSettings = (project: Project): void => {
  collectEvent('save_project_settings', { project });
};

const saveDesignCanvasData = (projectId: string, graph: RawRappidDesignCanvas.CanvasItem[]): void => {
  sessionStorage.setItem(`project.${projectId}`, JSON.stringify(graph));
};

const collectSaveDesignCanvas = (projectId: string, newGraph: RawRappidDesignCanvas.CanvasItem[]): void => {
  const oldGraph = JSON.parse(sessionStorage.getItem(`project.${projectId}`) || '[]');

  sessionStorage.setItem(`project.${projectId}`, JSON.stringify(newGraph)); // saves current design state

  if (!oldGraph) {
    return;
  }

  const removedItems = new Map();
  oldGraph.forEach((block: RawRappidDesignCanvas.CanvasItem) => {
    removedItems.set(block.id, block);
  });

  const addedItems = newGraph.filter((block: RawRappidDesignCanvas.CanvasItem) => {
    if (removedItems.get(block.id)) {
      removedItems.delete(block.id);
      return false;
    }
    return true;
  });

  const total_block_quantity = newGraph.filter((block: RawRappidDesignCanvas.CanvasItem) =>
    block.type.includes('BLOCK'),
  ).length;

  collectEvent('save_design_canvas', {
    addedItems,
    removedItems: Array.from(removedItems.values()),
    total_block_quantity,
  });
};

const collectCompilation = (compilationService: string, finalStatus: 'success' | 'error'): void => {
  collectEvent('compile', { compilation_service: compilationService, status: finalStatus });
};

const collectException = (
  entityName: string,
  exceptionDescription: string,
  severity: 'info' | 'warning' | 'error' | 'fatal',
): void => {
  collectEvent('exception', {
    entity_name: entityName,
    exception_description: exceptionDescription,
    exception_severity: severity,
  });
};

const collectExport = (file_type: string, target_domain: string): void => {
  collectEvent('export', { file_type, target_domain });
};

const collectDownload = (file_type: IDownloadFileType): void => {
  collectEvent('download', { file_type });
};

const supernovaDeleteProject = (name: string): void => collectSupernovaEvent('project', 'delete', { name });

export default {
  initializeDLAttributes,

  collectEvent,
  collectCreateProjectEvent,
  saveCloneProjectData,
  collectCloneProjectEvent,
  collectLoadProject,
  collectSaveProjectSettings,
  saveDesignCanvasData,
  collectSaveDesignCanvas,
  collectCompilation,
  collectException,
  collectExport,
  collectDownload,

  supernovaDeleteProject,
};
