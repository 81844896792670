import { all, ForkEffect, put, PutEffect, takeLatest, AllEffect } from 'redux-saga/effects';

import {
  CategoriesActionTypes,
  GetAllCategoriesAction,
  GetAllCategoriesByLinkAction,
} from 'state_management/actions/categories/ActionTypes';
import {
  getAllCategoriesAction,
  getAllCategoriesByLinkAction,
} from 'state_management/actions/categories/categoriesActions';
import { RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { ComponentActionTypes } from 'state_management/actions/components/ActionTypes';
import { DesignBlockActionTypes } from 'state_management/actions/designBlocks/ActionTypes';
import { ImplementationActionTypes } from 'state_management/actions/implementations/ActionTypes';
import { ProjectsActionTypes } from 'state_management/actions/projects/ActionTypes';
import { SubSystemCanvasActionTypes } from 'state_management/actions/subSystemCanvas/ActionTypes';
import { VirtualComponentsActionTypes } from 'state_management/actions/virtualComponents/ActionTypes';

export function* refreshCategories(): Generator<PutEffect<GetAllCategoriesAction>> {
  yield put(getAllCategoriesAction({ queryType: 'paginated' }));
}

export function* refreshCategoriesRoot(): Generator<PutEffect<GetAllCategoriesByLinkAction>> {
  yield put(getAllCategoriesByLinkAction({ linkType: 'root' }));
}

export function* refreshWatcher(): Generator<AllEffect<ForkEffect<never>>> {
  yield all([
    takeLatest(
      [
        CategoriesActionTypes.CREATE_CATEGORY_SUCCESS,
        CategoriesActionTypes.UPDATE_CATEGORY_SUCCESS,
        CategoriesActionTypes.CLONE_CATEGORY_SUCCESS,
        CategoriesActionTypes.DELETE_CATEGORY_SUCCESS,
      ],
      refreshCategories,
    ),
    takeLatest(
      [
        RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_SUCCESS,
        ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
        VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
        ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
        DesignBlockActionTypes.GET_DESIGN_BLOCK_DEPENDENCIES,
        ProjectsActionTypes.PROJECTS_GET_BY_ID_SUCCESS,
        SubSystemCanvasActionTypes.GET_ALL_SUB_SYSTEMS_SUCCESS,
        SubSystemCanvasActionTypes.GET_SUB_SYSTEM_CANVAS_SUCCESS,
      ],
      refreshCategoriesRoot,
    ),
  ]);
}
