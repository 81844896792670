import { AxiosResponse } from 'axios';
import { takeLatest, ForkEffect, put, PutEffect, call, CallEffect, select, SelectEffect } from 'redux-saga/effects';
import {
  ProjectsActionTypes,
  ProjectsGetByIdAction,
  ProjectsImageUpdateAction,
  ProjectsImageUpdateErrorAction,
  ProjectsImageUpdateSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  projectImageUpdateSuccessAction,
  projectImageUpdateErrorAction,
  getProjectByIdAction,
} from 'state_management/actions/projects/projectsActions';
import { AppState } from 'state_management/AppState';
import { axiosInstance } from 'services/dataService';
import { apiHostV2 } from 'config';
import { getErrorMessage } from 'utils/getErrorMessage';
import { createLocalScreenshot } from 'services/localProjectsService';

export function* projectImageUpdateSaga(
  action: ProjectsImageUpdateAction,
): Generator<
  | PutEffect<ProjectsImageUpdateSuccessAction | ProjectsImageUpdateErrorAction | ProjectsGetByIdAction>
  | CallEffect<AxiosResponse<{ imageId: string; data: string }>>
  | SelectEffect
> {
  try {
    const { data } = action.payload;

    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
      projectsState: {
        currentProject: { id, image },
      },
    } = (yield select((_state: AppState) => _state)) as AppState;

    if (isAnonymousUser) {
      createLocalScreenshot(id, data);
    } else {
      const res = (yield call(() =>
        axiosInstance.post(`${apiHostV2}/projects/uploadScreenshot`, {
          projectId: id,
          imageId: image,
          data,
        }),
      )) as AxiosResponse<{ imageId: string; data: string }>;

      // NOTE: If Project Image was created, fetch the project again
      if (res.status === 201) {
        yield put(getProjectByIdAction(id));
      }
    }

    yield put(projectImageUpdateSuccessAction());
  } catch (error) {
    yield put(projectImageUpdateErrorAction(getErrorMessage(error)));
  }
}

export function* projectImageUpdateWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ProjectsActionTypes.PROJECTS_IMAGE_UPDATE, projectImageUpdateSaga);
}
