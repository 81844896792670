import { AxiosResponse } from 'axios';
import { call, CallEffect, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import {
  ProjectsActionTypes,
  ProjectsAddAction,
  ProjectsAddErrorAction,
  ProjectsAddSuccessAction,
} from 'state_management/actions/projects/ActionTypes';
import {
  addProjectFail,
  addProjectSuccess,
  ENDPOINT_PROJECTS,
} from 'state_management/actions/projects/projectsActions';
import { AppState } from 'state_management/AppState';
import { getErrorMessage } from 'utils/getErrorMessage';
import { createLocalProject } from 'services/localProjectsService';
import DataLayer from 'services/dataLayer';
import { Project } from 'state_management/reducers/projects/Modals';
import { ProjectGeneralSettingsAction } from 'state_management/actions/projectGeneralSettings/ActionTypes';
import { deserializeProject } from 'utils/projectSerializer';
import { ProjectGeneralSettingsState } from 'state_management/reducers/projectGeneralSettings/Modals';
import { getProjectGeneralSettingsSaga } from '../projectGeneralSettings/getSaga';

export function* projectAddSaga(
  action: ProjectsAddAction,
): Generator<
  | SelectEffect
  | CallEffect
  | PutEffect<ProjectsAddSuccessAction | ProjectsAddErrorAction | ProjectGeneralSettingsAction>
> {
  try {
    const {
      authState: {
        userInfo: { isAnonymousUser },
      },
      projectGeneralSettingsState,
    } = (yield select((state: AppState) => state)) as AppState;

    const { requestState, defaultCadToolID, defaultBomTypeID, defaultNumberPcbLayers } = projectGeneralSettingsState;
    const defaultSettings = {
      defaultCadToolID,
      defaultBomTypeID,
      defaultNumberPcbLayers,
    };
    if (requestState === 'idle') {
      yield call(getProjectGeneralSettingsSaga);
      const storeValues = (yield select(
        (state: AppState) => state.projectGeneralSettingsState,
      )) as ProjectGeneralSettingsState;

      defaultSettings.defaultCadToolID = storeValues.defaultCadToolID;
      defaultSettings.defaultBomTypeID = storeValues.defaultBomTypeID;
    }

    const project = action.payload;
    const commonValues = {
      cadTool: project.cadTool || defaultSettings.defaultCadToolID || '',
      bomType: project.bomType || defaultSettings.defaultBomTypeID,
      numberOfLayers: project.numberOfLayers || defaultSettings.defaultNumberPcbLayers,
    };

    if (isAnonymousUser) {
      const projectData: Partial<Project> = {
        ...project,
        ...commonValues,
      };
      createLocalProject(projectData);
      yield put(addProjectSuccess(deserializeProject(projectData)));
    } else {
      const projectData: Partial<Raw.Project> = deserializeProject({
        title: project.title as string,
        description: project.description as string,
        ...commonValues,
      });
      const res = yield call(() => axiosInstance.post(apiUri(`${ENDPOINT_PROJECTS}/`, 2), projectData));
      const newProject = (res as AxiosResponse<Raw.Project>).data;

      DataLayer.collectCreateProjectEvent(projectData);
      yield put(addProjectSuccess(newProject));
    }
  } catch (error) {
    yield put(addProjectFail(getErrorMessage(error) || 'Creating Project failed'));
  }
}

export function* projectAddWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery([ProjectsActionTypes.PROJECTS_ADD], projectAddSaga);
}
