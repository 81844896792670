import { useEffect } from 'react';
import Dialog from 'components/Dialog';
import IFrame from 'components/iFrame';
import Loader from 'components/Loader';

import { OPEN_SPACE_WELCOME_GUIDE_ORIGIN, OPEN_SPACE_WELCOME_GUIDE_PATHNAME } from 'routes/getRoutes';
import { updateWelcomeAtAction } from 'state_management/actions/unboxing/unboxingActions';

import { IProps } from './IProps';

const WelcomeDialog = ({
  welcomeAt,
  getWelcomeAtState,
  updateWelcomeAtState,
  featurePermissions,
  dispatch,
}: IProps): JSX.Element => {
  useEffect(() => {
    const onEvent = (event: MessageEvent): void => {
      if (event.origin !== OPEN_SPACE_WELCOME_GUIDE_ORIGIN) return;

      if (event?.data === 'close') {
        dispatch(updateWelcomeAtAction());
      }
    };
    window.addEventListener('message', onEvent);
    return (): void => window.removeEventListener('message', onEvent);
  }, []);

  return (
    <>
      {featurePermissions?.SHOW_WELCOME_DIALOG?.read && getWelcomeAtState === 'success' && !welcomeAt && (
        <div data-testid="welcome-dialog">
          {updateWelcomeAtState === 'loading' && <Loader fullPage />}
          <Dialog open={updateWelcomeAtState !== 'loading'} fullScreen disableActions onClose={(): void => {}}>
            <IFrame src={`${OPEN_SPACE_WELCOME_GUIDE_ORIGIN}${OPEN_SPACE_WELCOME_GUIDE_PATHNAME}`} />
          </Dialog>
        </div>
      )}
    </>
  );
};
export default WelcomeDialog;
