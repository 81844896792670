import { ForkEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { SpecificationActionTypes } from 'state_management/actions/specifications/ActionTypes';

import { GetAllPhysicalQuantitiesAction } from 'state_management/actions/unitConverter/ActionTypes';
import { getAllPhysicalQuantities } from 'state_management/actions/unitConverter/unitConverter';
import { AppState } from 'state_management/AppState';

export function* refreshPhysicalQuantities(): Generator<SelectEffect | PutEffect<GetAllPhysicalQuantitiesAction>> {
  const {
    unitConverterState: { physicalQuantitiesMap, physicalQuantitiesMapRequest },
  } = (yield select((state: AppState) => state)) as AppState;
  if (!Object.keys(physicalQuantitiesMap).length && physicalQuantitiesMapRequest !== 'loading')
    yield put(getAllPhysicalQuantities());
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [SpecificationActionTypes.GET_ALL_SPECIFICATIONS, SpecificationActionTypes.GET_SPECIFICATION_DEPENDENCIES],
    refreshPhysicalQuantities,
  );
}
