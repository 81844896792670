import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StatusBarContainer = styled.div`
  && {
    width: 100%;
    min-height: 42px;
    position: absolute;
    bottom: ${({ theme }): number => theme.spacing.screenContent}px;
    background-color: transparent;
  }
`;

export const CanvasTabs = styled.div`
  && {
    width: fit-content;
    position: relative;
    left: ${({ theme }): number => theme.spacing.screenContent}px;
  }
`;

const CONTACT_US_BTN_OFFSET = 142;

export const ErrorButtonsContainer = styled.div`
  position: absolute;
  top: 0;
  right: ${160 + CONTACT_US_BTN_OFFSET + 44 + 24}px;
  width: 110px;
  height: 42px;
  border-radius: 4px;
  box-shadow: ${({ theme }): string => theme.shadow.normal};
`;

export const NavigatorContainer = styled.div`
  && {
    position: absolute;
    right: ${160 + CONTACT_US_BTN_OFFSET}px;
    top: 0;

    & > section {
      border-radius: 4px;
      position: absolute;
      width: 42px;
      height: 42px;
      right: 0;
      top: 0;
      background-color: ${({ theme }): string => theme.palette.white};
      box-shadow: ${({ theme }): string => theme.shadow.normal};
    }
  }
`;

export const NavigatorMap = styled(motion.div)<{ toggle: boolean }>`
  && {
    position: absolute;
    bottom: 0;
    scale: 1;
    right: 45px;
    width: 0px;
    height: 0px;
    border: none;
    box-shadow: ${({ theme }): string => theme.shadow.normal};

    & div {
      border: none;
    }
  }
`;

export const navigatorMapAnimation = {
  open: {
    width: 240,
    height: 115,
    display: 'flex',
    transition: {
      type: 'tween',
    },
  },
  closed: {
    width: 0,
    height: 0,
    display: 'none',
    transition: {
      type: 'tween',
    },
  },
};

export const ZoomContainer = styled.div`
  && {
    position: absolute;
    top: 0;
    right: ${({ theme }): number => theme.spacing.screenContent + CONTACT_US_BTN_OFFSET}px;
  }
`;
