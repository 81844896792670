import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  select,
  SelectEffect,
  call,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  ComponentActionTypes,
  GetAllComponentsAction,
  GetAllComponentsSuccessAction,
  GetAllComponentsErrorAction,
} from 'state_management/actions/components/ActionTypes';

import { getErrorMessage } from 'utils/getErrorMessage';
import {
  getAllComponentsSuccessAction,
  getAllComponentsErrorAction,
  ENDPOINT_COMPONENTS,
} from 'state_management/actions/components/componentsActions';
import { AppState } from 'state_management/AppState';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { combineSearchQueries, getSearchQuery, SpecificationsSchema, TypeSearch } from 'utils/searchHelper';
import { escapeSearch } from 'utils/urlUtils';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { serializeComponent } from 'utils/componentsSerializer';

export function* getAllSaga(
  action: GetAllComponentsAction,
): Generator<
  | PutEffect<GetAllComponentsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllComponentsSuccessAction | GetAllComponentsErrorAction>
> {
  try {
    const {
      componentsState: { pagination },
      specificationsState: { specificationsImplementationList },
      authState: {
        userInfo,
        userSettings,
        featurePermissions: { USE_NEW_PROXY_SERVICE },
      },
    } = (yield select((state: AppState) => state)) as AppState;

    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const componentsSearchSchema: TypeSearch = {
      part_number: 'value',
      internal_part_number: 'value',
      description: 'value',
      full_info: 'value',
      datasheet_url: 'value',
      lifecycle_status: 'value',
    };

    const specificationsSchema: SpecificationsSchema = [
      { specDbKey: 'specifications.', specList: specificationsImplementationList },
    ];

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), componentsSearchSchema, specificationsSchema),
      false,
      userInfo._id,
      userSettings?.preferredWorkspaces,
    );

    const res = (yield call(() =>
      axiosInstance.get(
        apiUri(
          `/dataservice/${ENDPOINT_COMPONENTS}?pg=${page}&pg_len=${perPage}&${searchQuery}`,
          2,
          USE_NEW_PROXY_SERVICE.read === true,
        ),
      ),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllComponentsSuccessAction(_pagination, res.data.map(serializeComponent)));
  } catch (error) {
    yield put(getAllComponentsErrorAction(getErrorMessage(error) || 'Fetching components failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ComponentActionTypes.GET_ALL_COMPONENTS, getAllSaga);
}
