import { DashboardProjectTypes, DashboardSortingTypes } from 'state_management/reducers/projects/Modals';

export const createQueryStr = (
  userId: string,
  sharingFilter: DashboardProjectTypes,
  sortType: DashboardSortingTypes,
): { query: string; orderBy: string } => {
  let sortingKey: string;
  switch (sortType) {
    case DashboardSortingTypes.LAST_MODIFIED:
      sortingKey = `-modified_at`;
      break;
    case DashboardSortingTypes.ALPHABETICALLY:
      sortingKey = `title`;
      break;
    case DashboardSortingTypes.LAST_CREATED:
    default:
      sortingKey = `-created_at`;
      break;
  }

  let query: string;
  switch (sharingFilter) {
    case DashboardProjectTypes.PRIVATE:
      query = `_owner="${userId}";share_type-contains-all-["private"]`;
      break;
    case DashboardProjectTypes.SHARED_BY_ME:
      query = `_owner="${userId}";share_type-nin-["private"]`;
      break;
    case DashboardProjectTypes.SHARED_WITH_ME:
      query = `_owner!="${userId}";share_type-nin-["private"]`;
      break;
    default:
      query = '';
      break;
  }

  // NOTE: the baseQuery uses != because old projects don't have the is_reference_design field
  const baseQuery = 'is_reference_design!=true';
  query = query ? `${baseQuery};${query}` : baseQuery;

  return { query: `query=${query}`, orderBy: `order_by=${sortingKey}` };
};
