import { BlockNamespaceType, BLOCK_NAMESPACES, IBlockV2, IImplementationMatch } from 'models/ICanvas';
import { IEntity } from 'models/IEntity';
import { RequestState } from 'models/RequestState';
import { RappidGraph } from 'state_management/reducers/systemDesignCanvas/Modals';

export interface IBlockBase extends Partial<IEntity> {
  icon?: string;
  socketNum?: number;
  size?: {
    width: number;
    height: number;
  };
  state: RequestState;
  color?: string;
  namespace: BlockNamespaceType;
}

export const BLANK_BLOCK: IBlockV2 = {
  id: BLOCK_NAMESPACES.BASIC,
  description: '',
  createdAt: '',
  functions: [],
  icon: 'chip_mcu',
  name: 'Block Name',
  specificationFilters: [],
  specifications: [],
  size: {
    height: 100,
    width: 100,
  },
  errors: [],
  origin: 'blank',
  state: 'idle',
  existingBlockId: '',
  category: 'Other',
  info: '',
  position: {
    x: 0,
    y: 0,
  },
  errorHeader: '',
  categories: [],
  namespace: BLOCK_NAMESPACES.BASIC,
};

export const BLANK_TEXT_ONLY_BLOCK: IBlockV2 = {
  ...BLANK_BLOCK,
  id: BLOCK_NAMESPACES.TEXT_ONLY,
  name: 'Text Block',
  size: {
    height: 100,
    width: 200,
  },
  categories: ['Subsystem'],
  namespace: BLOCK_NAMESPACES.TEXT_ONLY,
};
export const getTextOnlyBlock = (block: Partial<IBlockV2>): IBlockV2 => ({
  ...BLANK_TEXT_ONLY_BLOCK,
  ...block,
});

export const BLANK_TRANSPARENT_BLOCK: IBlockV2 = {
  ...BLANK_BLOCK,
  id: BLOCK_NAMESPACES.TRANSPARENT,
  origin: 'port',
  size: {
    height: 100,
    width: 200,
  },
  name: 'Transparent Block',
  categories: ['Subsystem'],
  namespace: BLOCK_NAMESPACES.TRANSPARENT,
};
export const getTransparentBlock = (block: Partial<IBlockV2>): IBlockV2 => ({
  ...BLANK_TRANSPARENT_BLOCK,
  ...block,
});

export const getSchematicCanvasElement = (
  schematicContent: string,
  block: Partial<IImplementationMatch>,
): RappidGraph<IBlockV2> => ({
  id: BLOCK_NAMESPACES.SCHEMATIC,
  router: {},
  connector: {},
  type: BLOCK_NAMESPACES.SCHEMATIC,
  source: {},
  target: {},
  vertices: [],
  attrs: {
    '.img-container': {
      'xlink:href': `data:image/svg+xml;charset=utf-8,${schematicContent}`,
    },
  },
  z: 1,
  position: {
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  },
  angle: 0,
  ports: {},
  data: {
    ...block,
    origin: 'library',
    state: 'idle',
    id: BLOCK_NAMESPACES.SCHEMATIC,
    namespace: BLOCK_NAMESPACES.SCHEMATIC,
  } as IBlockV2,
  labels: [],
});

export const getBlankCanvasElement = (block: Partial<IImplementationMatch>): RappidGraph<IBlockV2> => ({
  id: '407eb538-688e-4088-b572-65ba94cfe946',
  router: {},
  connector: {},
  type: BLOCK_NAMESPACES.BASIC,
  source: {},
  target: {},
  vertices: [],
  attrs: {
    '.block-name': {
      text: block.name && block.name.length > 13 ? `${block.name?.slice(0, 12)}...` : block.name,
    },
    '.block-icon': {
      class: `block-icon celusico-${block.iconId || 'chip_mcu'}`,
    },
    '.canvas-block': {
      title: block.name,
    },
    '.block-status-icon': {
      class: 'block-status-icon celusico-search-idle',
    },
  },
  z: 1,
  size: {
    width: 100,
    height: 100,
  },
  position: {
    x: window.innerWidth / 2 - 50,
    y: window.innerHeight / 2 - 50,
  },
  angle: 0,
  ports: {},
  data: {
    ...BLANK_BLOCK,
    ...block,
    origin: 'library',
    state: 'idle',
    id: '407eb538-688e-4088-b572-65ba94cfe946',
    namespace: BLOCK_NAMESPACES.BASIC,
  } as IBlockV2,
  labels: [],
});

export enum ICanvasBlockAction {
  CANVAS_BLOCK_DROP_ACTION = 'CANVAS_BLOCK_DROP_ACTION',
}

export type ContextMenuActionsType = 'onSettings' | 'onCut' | 'onDelete' | 'onCopy' | 'onDuplicate';

export interface Snapshot {
  prev: any;
  next: any;
}
export interface StackPointer {
  steps: number;
  snapshot?: Snapshot;
  incomingData?: 'block' | 'link';
}
export interface StackItem {
  action: string;
  data: {
    previous: { data: any };
    next: { data: any };
  };
}

export enum ElementType {
  ELEMENT,
  LINK,
}
