import { all, AllEffect } from 'redux-saga/effects';
import { requestsToastsWatcher } from './requestsToastsSaga';
import { refreshWatcher } from './refreshSaga';
import { getAllWatcher } from './getAllSaga';
import { getByIdWatcher } from './getByIdSaga';
import { getAllForLinkWatcher } from './getAllForLinkSaga';

export function* componentsRootSaga(): Generator<AllEffect<any>> {
  yield all([requestsToastsWatcher(), refreshWatcher(), getAllWatcher(), getByIdWatcher(), getAllForLinkWatcher()]);
}
