import { PutEffect, ForkEffect, CallEffect, put, select, SelectEffect, call, takeEvery } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  CategoriesActionTypes,
  GetAllCategoriesByLinkAction,
  GetAllCategoriesByLinkSuccessAction,
  GetAllCategoriesByLinkErrorAction,
} from 'state_management/actions/categories/ActionTypes';

import { getErrorMessage } from 'utils/getErrorMessage';
import {
  ENDPOINT_CATEGORIES,
  getAllCategoriesByLinkErrorAction,
  getAllCategoriesByLinkSuccessAction,
} from 'state_management/actions/categories/categoriesActions';
import { AppState } from 'state_management/AppState';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { CategoriesState, ICategories } from 'state_management/reducers/categories/Modals';
import { serializeCategories } from 'utils/categoriesSerializer';

const getChildren = async (
  linkedResourceId: string,
  categoriesAncestorsList: Record<string, Array<string>>,
  categoriesDescendantsList: Record<string, Array<string>>,
  categoriesItems: Record<string, ICategories>,
  resCategories?: Array<Raw.Category>,
): Promise<void> => {
  /* 
      For children the API will return all direct descendants (when using `max_depth=0` as below)
      We have to map each of them to the parent in both ways ancestors & descendants
    */
  let _resCategories = resCategories;
  if (!_resCategories) {
    const res = (await axiosInstance.get(
      apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/${linkedResourceId}/descendants?max_depth=0`, 2),
    )) as AxiosResponse;
    _resCategories = res.data;
  }
  // eslint-disable-next-line no-param-reassign
  categoriesDescendantsList[linkedResourceId] = categoriesDescendantsList[linkedResourceId] || [];
  _resCategories?.forEach((cat: Raw.Category) => {
    // eslint-disable-next-line no-param-reassign
    categoriesItems[cat.id as string] = serializeCategories(cat);
    if (!categoriesDescendantsList[linkedResourceId].includes(cat.id as string)) {
      categoriesDescendantsList[linkedResourceId].push(cat.id as string);
    }
    // eslint-disable-next-line no-param-reassign
    categoriesAncestorsList[cat.id as string] = [
      ...((linkedResourceId in categoriesAncestorsList && categoriesAncestorsList[linkedResourceId]) || []),
      linkedResourceId,
    ];
  });
};

const getLists = (
  input: Record<string, Array<string>>,
): { listAncestors: Record<string, Array<string>>; listDescendants: Record<string, Array<string>> } => {
  const newList = Object.keys(input).map((k) => [...input[k], k]);
  const listAncestors: Record<string, Array<string>> = {};
  const listDescendants: Record<string, Array<string>> = {};
  newList.forEach((elm) => {
    elm.forEach((k, idx) => {
      // List of all ancestors starting from the root
      listAncestors[k] = elm.slice(0, idx);
      // List of direct descendants
      listDescendants[k] = [elm[idx + 1]].filter(Boolean);
    });
  });
  return { listAncestors, listDescendants };
};

export function* getAllByLinkSaga(
  action: GetAllCategoriesByLinkAction,
): Generator<
  | PutEffect<GetAllCategoriesByLinkAction>
  | SelectEffect
  | CallEffect<AxiosResponse | void>
  | PutEffect<GetAllCategoriesByLinkSuccessAction | GetAllCategoriesByLinkErrorAction>
> {
  try {
    const { linkType, linkedResourceId } = action.payload;

    const { pagination, categoriesAncestorsList, categoriesDescendantsList, categoriesItems } = (yield select(
      (state: AppState) => state.categoriesState,
    )) as CategoriesState;

    let clonedCategoriesAncestorsList = { ...categoriesAncestorsList };
    let clonedCategoriesDescendantsList = { ...categoriesDescendantsList };
    const clonedCategoriesItems = { ...categoriesItems };

    const { search = pagination.search } = action.payload.pagination || {};

    let url = '';

    switch (linkType) {
      case 'child':
        url += `/${linkedResourceId}/descendants?max_depth=0`;
        break;
      case 'parent':
        url += `/${linkedResourceId}/ancestors`;
        break;
      case 'root':
      default:
        url += '?query=parent-not-exists';
    }

    if (
      linkedResourceId &&
      categoriesDescendantsList &&
      linkedResourceId in categoriesDescendantsList &&
      categoriesDescendantsList[linkedResourceId].length
    ) {
      // The case where we already loaded the descendants
      yield put(
        getAllCategoriesByLinkSuccessAction(
          pagination,
          clonedCategoriesItems,
          clonedCategoriesAncestorsList,
          clonedCategoriesDescendantsList,
        ),
      );
    } else {
      const res = (yield call(() =>
        axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_CATEGORIES}${url}`, 2)),
      )) as AxiosResponse;

      const resCategories = res.data;
      if (linkType === 'child' && linkedResourceId) {
        yield call(() =>
          getChildren(
            linkedResourceId,
            clonedCategoriesAncestorsList,
            clonedCategoriesDescendantsList,
            clonedCategoriesItems,
            resCategories,
          ),
        );
      } else if (linkType === 'parent' && linkedResourceId) {
        /* 
          For parents the API will return a list off all Ancestors ordered from the root till the parent
          We have to create two lists:
            - one of ancestors for each ancestor (ordered from the root till the parent)
            - one for direct descendants
        */
        clonedCategoriesAncestorsList[linkedResourceId] = [];
        const { listAncestors, listDescendants } = getLists({
          [linkedResourceId]: resCategories.map((c: Raw.Category) => c.id),
        });

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < resCategories.length; i++) {
          clonedCategoriesItems[resCategories[i].id as string] = serializeCategories(resCategories[i]);
          // Load the children of each parent
          // eslint-disable-next-line no-loop-func
          yield call(() =>
            getChildren(
              resCategories[i].id as string,
              clonedCategoriesAncestorsList,
              clonedCategoriesDescendantsList,
              clonedCategoriesItems,
            ),
          );
        }

        clonedCategoriesAncestorsList = { ...clonedCategoriesAncestorsList, ...listAncestors };
        clonedCategoriesDescendantsList = { ...listDescendants, ...clonedCategoriesDescendantsList };

        const resParent = (yield call(() =>
          axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_CATEGORIES}/${linkedResourceId}`, 2)),
        )) as AxiosResponse;
        clonedCategoriesItems[resParent.data.id as string] = serializeCategories(resParent.data);
      } else if (linkType === 'root') {
        resCategories.forEach((cat: Raw.Category) => {
          clonedCategoriesItems[cat.id as string] = serializeCategories(cat);
          clonedCategoriesAncestorsList[cat.id as string] = [];
        });
      }
      const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };
      yield put(
        getAllCategoriesByLinkSuccessAction(
          _pagination,
          clonedCategoriesItems,
          clonedCategoriesAncestorsList,
          clonedCategoriesDescendantsList,
        ),
      );
    }
  } catch (error) {
    yield put(getAllCategoriesByLinkErrorAction(getErrorMessage(error) || 'Fetching categories failed.'));
  }
}

export function* getAllByLinkWatcher(): Generator<ForkEffect<never>> {
  yield takeEvery(CategoriesActionTypes.GET_ALL_CATEGORIES_BY_LINK, getAllByLinkSaga);
}
