import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';

import {
  ExportTemplatesActionTypes,
  GetAllExportTemplatesAction,
} from 'state_management/actions/exportTemplates/ActionTypes';
import { getAllExportTemplatesAction } from 'state_management/actions/exportTemplates/exportTemplatesActions';

export function* refreshExportTemplates(): Generator<PutEffect<GetAllExportTemplatesAction>> {
  yield put(getAllExportTemplatesAction({ queryType: 'paginated' }));
}

export function* refreshWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      ExportTemplatesActionTypes.CREATE_EXPORT_TEMPLATE_SUCCESS,
      ExportTemplatesActionTypes.UPDATE_EXPORT_TEMPLATE_SUCCESS,
      ExportTemplatesActionTypes.CLONE_EXPORT_TEMPLATE_SUCCESS,
      ExportTemplatesActionTypes.DELETE_EXPORT_TEMPLATE_SUCCESS,
    ],
    refreshExportTemplates,
  );
}
