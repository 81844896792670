import { all, AllEffect } from 'redux-saga/effects';
import { getAllWatcher } from './getAllSaga';
import { getByIdWatcher, getByIdEveryWatcher } from './getByIdSaga';
import { refreshWatcher } from './refreshSaga';
import { requestsToastsWatcher } from './requestsToastsSaga';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* designConsiderationsRootSaga(): Generator<AllEffect<any>> {
  yield all([getAllWatcher(), getByIdWatcher(), refreshWatcher(), requestsToastsWatcher(), getByIdEveryWatcher()]);
}
