import { ISupplier } from 'state_management/reducers/suppliers/Modals';

export const deserializeSupplier = (supplier: Partial<ISupplier>): Partial<Raw.ISupplier> => ({
  id: supplier.id,
  created_at: supplier.createdAt,
  modified_at: supplier.modifiedAt,
  name: supplier.name,
  short_description: supplier.shortDescription,
  full_info: supplier.fullInfo,
});

export const serializeSupplier = (supplier: Raw.ISupplier): ISupplier => ({
  id: supplier.id,
  createdAt: supplier.created_at,
  modifiedAt: supplier.modified_at,
  name: supplier.name,
  shortDescription: supplier.short_description,
  fullInfo: supplier.full_info,
});
