import {
  DesignSystemToolBarActionTypes,
  DesignSystemToolBarActions,
} from 'state_management/actions/DesignSystemToolBar/ActionTypes';
import { DesignSystemToolBarState } from './Modals';

export const initialDesignSystemState: DesignSystemToolBarState = {
  selectedAction: undefined,
  isApplicableToBlock: undefined,
  isApplicableToLink: undefined,
};

export const designSystemToolBarReducer = (
  state: DesignSystemToolBarState = initialDesignSystemState,
  action: DesignSystemToolBarActions,
): DesignSystemToolBarState => {
  switch (action.type) {
    case DesignSystemToolBarActionTypes.SET_SELECTED_ACTION:
      return {
        ...action.payload,
      };
    case DesignSystemToolBarActionTypes.RESET_SELECTED_ACTION:
      return initialDesignSystemState;

    default:
      return state;
  }
};
