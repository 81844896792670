import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

import { dialogStyles } from 'theme';

import { IProps } from './IProps';

export const DialogTitle = styled.h3`
  && {
    font-weight: bold;
    font-size: ${({ theme }): string => theme.fontSize.medium};
    padding-left: ${({ theme }): number => theme.spacing.medium}px;
  }
`;

export const useStyles = makeStyles(({ palette }) => ({
  paperScrollPaper: {
    maxWidth: ({ fullScreen }: IProps): string | number => (fullScreen ? '100%' : 3000),
    maxHeight: ({ fullScreen }: IProps): string | number => (fullScreen ? '100%' : 1200),
    overflow: 'hidden',
  },
  root: {
    display: 'flex',
    flex: '1 1 auto',
    padding: '0 !important',
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flex: '1 1 auto',
    width: '100%',

    '& > div': {
      maxWidth: ({ fullScreen }: IProps): string | number => (fullScreen ? '100%' : 3000),
      maxHeight: ({ fullScreen }: IProps): string | number => (fullScreen ? '100%' : 1200),
    },
  },
  actionsContainer: {
    position: 'relative',
    height: ({ heading }: IProps): string => (heading ? 'auto' : dialogStyles.headerHeight),
    justifyContent: 'space-between',
    padding: 0,
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 56,
    padding: 0,
    '& i:hover': {
      color: palette.primary.main,
    },
  },
}));
