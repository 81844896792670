import { upsertResource } from 'utils/resourceUtils';
import { SuppliersState } from './Modals';
import { SupplierActions, SuppliersActionTypes } from '../../actions/suppliers/ActionTypes';

export const initialStateSuppliers: SuppliersState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  suppliersList: [],
  suppliersListState: 'idle',
  suppliersListError: '',
  supplierCreateState: 'idle',
  supplierCreateError: '',
  supplierUpdateState: 'idle',
  supplierUpdateError: '',
  supplierDeleteState: 'idle',
  supplierDeleteError: '',
  supplierByIdState: 'idle',
  supplierByIdStateError: '',
};

export const suppliersReducer = (
  state: SuppliersState = initialStateSuppliers,
  action: SupplierActions,
): SuppliersState => {
  switch (action.type) {
    case SuppliersActionTypes.GET_ALL_SUPPLIERS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        suppliersListState: 'loading',
      };
    case SuppliersActionTypes.GET_ALL_SUPPLIERS_SUCCESS:
      return {
        ...state,
        suppliersListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        suppliersList: action.payload.data,
      };
    case SuppliersActionTypes.GET_ALL_SUPPLIERS_FAIL:
      return {
        ...state,
        suppliersListState: 'error',
        suppliersListError: action.payload,
      };
    case SuppliersActionTypes.CREATE_SUPPLIER:
      return {
        ...state,
        supplierCreateState: 'loading',
      };
    case SuppliersActionTypes.CREATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierCreateState: 'success',
      };
    case SuppliersActionTypes.CREATE_SUPPLIER_FAIL:
      return {
        ...state,
        supplierCreateState: 'error',
        supplierCreateError: action.error.message,
      };
    case SuppliersActionTypes.UPDATE_SUPPLIER:
      return {
        ...state,
        supplierUpdateState: 'loading',
      };
    case SuppliersActionTypes.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierUpdateState: 'success',
      };
    case SuppliersActionTypes.UPDATE_SUPPLIER_FAIL:
      return {
        ...state,
        supplierUpdateState: 'error',
        supplierUpdateError: action.error.message,
      };
    case SuppliersActionTypes.DELETE_SUPPLIER:
      return {
        ...state,
        supplierDeleteState: 'loading',
      };
    case SuppliersActionTypes.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierDeleteState: 'success',
      };
    case SuppliersActionTypes.DELETE_SUPPLIER_FAIL:
      return {
        ...state,
        supplierDeleteState: 'error',
        supplierDeleteError: action.error.message,
      };
    case SuppliersActionTypes.GET_SUPPLIER_BY_ID:
      return {
        ...state,
        supplierByIdState: 'loading',
      };
    case SuppliersActionTypes.GET_SUPPLIER_BY_ID_SUCCESS:
      return {
        ...state,
        supplierByIdState: 'success',
        suppliersList: upsertResource(state.suppliersList, action.payload),
      };
    case SuppliersActionTypes.GET_SUPPLIER_BY_ID_FAIL:
      return {
        ...state,
        supplierByIdState: 'error',
        supplierByIdStateError: action.payload,
      };
    default:
      return state;
  }
};
