import { upsertResource } from 'utils/resourceUtils';
import { RequestActions, RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { ChangesRequestState } from './Modals';

export const initialStateRequests: ChangesRequestState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  changesRequestList: [],
  changesRequestListState: 'idle',
  changesRequestListError: '',
  changesRequestCreateError: '',
  changesRequestCreateState: 'idle',
  changesRequestByIdState: 'idle',
  changesRequestByIdError: '',
  changesRequestDeleteState: 'idle',
  changesRequestDeleteError: '',
  changesRequestRetryState: 'idle',
  changesRequestRetryError: '',
  callChangesRequestApprovalState: 'idle',
  callChangesRequestApprovalError: '',
  originalSharingData: undefined,
};

export const changesRequestReducer = (
  state: ChangesRequestState = initialStateRequests,
  action: RequestActions,
): ChangesRequestState => {
  switch (action.type) {
    case RequestsActionTypes.GET_ALL_CHANGES_REQUESTS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        changesRequestListState: 'loading',
      };
    case RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_SUCCESS:
      return {
        ...state,
        changesRequestListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
        changesRequestList: action.payload.data,
      };
    case RequestsActionTypes.GET_ALL_CHANGES_REQUESTS_FAIL:
      return {
        ...state,
        changesRequestListState: 'error',
        changesRequestListError: action.payload,
      };
    case RequestsActionTypes.CREATE_CHANGES_REQUEST:
      return {
        ...state,
        changesRequestCreateState: 'loading',
      };
    case RequestsActionTypes.CREATE_CHANGES_REQUEST_SUCCESS:
      return {
        ...state,
        changesRequestCreateState: 'success',
      };
    case RequestsActionTypes.CREATE_CHANGES_REQUEST_FAIL:
      return {
        ...state,
        changesRequestCreateState: 'error',
        changesRequestCreateError: action.payload,
      };
    case RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID:
      return {
        ...state,
        changesRequestByIdState: 'loading',
      };
    case RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        changesRequestByIdState: 'success',
        changesRequestList: upsertResource(state.changesRequestList, action.payload, ['requestIcon', 'preApprovals']),
        originalSharingData: action.originalSharingData,
      };
    case RequestsActionTypes.GET_CHANGES_REQUEST_BY_ID_FAIL:
      return {
        ...state,
        changesRequestByIdState: 'error',
        changesRequestByIdError: action.payload,
      };
    case RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_SUCCESS:
      return {
        ...state,
        callChangesRequestApprovalState: 'success',
      };
    case RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL_FAIL:
      return {
        ...state,
        callChangesRequestApprovalState: 'error',
      };
    case RequestsActionTypes.CALL_CHANGES_REQUEST_APPROVAL:
      return {
        ...state,
        callChangesRequestApprovalState: 'loading',
      };
    case RequestsActionTypes.DELETE_CHANGES_REQUEST:
      return {
        ...state,
        changesRequestDeleteState: 'loading',
      };
    case RequestsActionTypes.DELETE_CHANGES_REQUEST_SUCCESS:
      return {
        ...state,
        changesRequestDeleteState: 'success',
      };
    case RequestsActionTypes.DELETE_CHANGES_REQUEST_FAIL:
      return {
        ...state,
        changesRequestDeleteState: 'error',
        changesRequestDeleteError: action.payload,
      };
    case RequestsActionTypes.RETRY_CHANGES_REQUEST:
      return {
        ...state,
        changesRequestRetryState: 'loading',
      };
    case RequestsActionTypes.RETRY_CHANGES_REQUEST_SUCCESS:
      return {
        ...state,
        changesRequestRetryState: 'success',
      };
    case RequestsActionTypes.RETRY_CHANGES_REQUEST_FAIL:
      return {
        ...state,
        changesRequestRetryState: 'error',
        changesRequestRetryError: action.payload,
      };
    default:
      return state;
  }
};
