import { ICategories } from 'state_management/reducers/categories/Modals';
import { ISpecification } from 'state_management/reducers/specifications/Modals';

export const getSpecificationsOfCategory = (
  specificationsList: Array<ISpecification>,
  categoriesItems: Record<string, ICategories>,
  categoriesAncestorsList: Record<string, Array<string>>,
  category?: string,
  specificationField: 'specifications' | 'customFields' = 'specifications',
  usedSpecifications: Array<string> = [],
): Array<ISpecification> => {
  if (!category) return specificationsList;
  const allSpecs =
    (categoriesItems[category] && categoriesItems[category][specificationField]?.map((spec) => spec.id)) || [];

  const outputSpecs = specificationsList.filter(
    (spec) => allSpecs?.includes(spec.id) || usedSpecifications?.includes(spec.id),
  );

  // As specified by product we fallback to show all specifications if we don't have filtered ones on that category
  // https://celus.atlassian.net/browse/ORBIT-318
  return (outputSpecs.length && outputSpecs) || specificationsList;
};

/**
 * This function should be used when looping through all units in a filter way to decide which unit to show
 * @param specification The Specification that we will filter its convertable units
 * @param physicalQuantitiesMap The physical quantity map
 * @param unit The unit that we are checking if we filter it out or show it
 * @returns boolean of whether the unit should be shown or not
 */
export const getListOfUnits = (
  specification: ISpecification,
  physicalQuantitiesMap: Raw.IPhysicalQuantity,
  unit: string,
): boolean => {
  const unitsOfPhyQty = physicalQuantitiesMap[specification.physicalQuantity] || [];
  const convertibleUnits = unitsOfPhyQty
    .filter((unitItem): boolean => !unitItem.inconvertible)
    .map((unitItem) => unitItem.value);
  const inConvertibleUnits = unitsOfPhyQty
    .filter((unitItem): boolean => !!unitItem.inconvertible)
    .map((unitItem) => unitItem.value);

  return (
    // Check if the unit is inconvertible, so we only show that unit
    (inConvertibleUnits.includes(specification.unit) && unit === specification.unit) ||
    // Check if the unit is part of the convertible units of the physical quantity
    (convertibleUnits.includes(specification.unit) && convertibleUnits.includes(unit)) ||
    specification.unit === unit
  );
};
