import { globalToast } from 'components/ToastComponent';
import { ForkEffect, CallEffect, put, PutEffect, takeLeading, delay } from 'redux-saga/effects';

import { AuthActionTypes, LoginAction } from 'state_management/actions/auth/ActionTypes';
import { loginAction } from 'state_management/actions/auth/authActions';

export function* unauthorizedUserSaga(): Generator<CallEffect<true | unknown> | PutEffect<LoginAction>> {
  globalToast(
    undefined,
    'error',
    {
      error: 'Unauthorized user! Login in progress...',
    },
    { autoClose: false },
  );

  yield delay(5000);

  yield put(loginAction());
}

export function* unauthorizedUserSagaWatcher(): Generator<ForkEffect<never>> {
  // NOTE: Leading, means take first and then wait to take others until first/leading is finished
  yield takeLeading(AuthActionTypes.UNAUTHORIZED_USER_ACTION, unauthorizedUserSaga);
}
