import { ActionCreatorType } from 'utils/dispatchHandler';
import {
  featureToggleSubSystemCanvasAction,
  featureToggleSubSystemCanvasCreate,
  featToggleLanguageSelection,
  featToggleFutureFormsMocks,
  featToggleRetryChangeRequest,
} from './featuresActions';

export const FEATURES: Record<string, { label: string; action?: ActionCreatorType }> = {
  // Supernova
  SUB_SYSTEM_CANVAS: { label: 'Create Sub System Canvas', action: featureToggleSubSystemCanvasCreate },
  SUB_SYSTEM_CANVAS_CREATE: { label: 'Enable Sub System Canvas', action: featureToggleSubSystemCanvasAction },

  // Orbit
  FUTURE_FORMS_MOCKS: { label: 'Future forms mocks', action: featToggleFutureFormsMocks },
  RETRY_CHANGE_REQUEST: { label: 'Retry change request', action: featToggleRetryChangeRequest },

  // MAIN
  LANGUAGES: { label: 'Language selection', action: featToggleLanguageSelection },
};
