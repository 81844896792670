import { deDupeResources, upsertResource } from 'utils/resourceUtils';
import { ComponentActions, ComponentActionTypes } from '../../actions/components/ActionTypes';
import { ComponentsState } from './Modals';

export const initialStateComponents: ComponentsState = {
  pagination: {
    page: 0,
    perPage: 10,
    search: '',
    total: 10,
  },
  componentsList: [],
  componentsLinkList: [],
  componentsListState: 'idle',
  componentsListError: '',
  componentCreateState: 'idle',
  componentCreateError: '',
  componentUpdateState: 'idle',
  componentUpdateError: '',
  componentDeleteState: 'idle',
  componentDeleteError: '',
  componentByIdState: 'idle',
  componentByIdStateError: '',
  componentCadFiles: [],
  componentCadFilesError: '',
  componentCadFileDeleteState: 'idle',
  componentDocFiles: [],
  componentDocFilesError: '',
};

export const componentsReducer = (
  state: ComponentsState = initialStateComponents,
  action: ComponentActions,
): ComponentsState => {
  switch (action.type) {
    case ComponentActionTypes.GET_ALL_COMPONENTS:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        componentsListState: 'loading',
      };
    case ComponentActionTypes.GET_ALL_COMPONENTS_SUCCESS:
      return {
        ...state,
        componentsList: action.payload.data,
        componentsListState: 'success',
        pagination: {
          ...state.pagination,
          ...action.payload.pagination,
        },
      };
    case ComponentActionTypes.GET_ALL_COMPONENTS_FAIL:
      return {
        ...state,
        componentsListState: 'error',
        componentsListError: action.payload,
      };
    case ComponentActionTypes.GET_ALL_FOR_LINK_COMPONENTS_SUCCESS:
      return {
        ...state,
        componentsLinkList: action.payload.withResetList
          ? action.payload.data
          : deDupeResources([...state.componentsLinkList, ...action.payload.data]),
      };
    case ComponentActionTypes.CREATE_COMPONENT:
      return {
        ...state,
        componentCreateState: 'loading',
      };
    case ComponentActionTypes.CREATE_COMPONENT_SUCCESS:
      return {
        ...state,
        componentCreateState: 'success',
      };
    case ComponentActionTypes.CREATE_COMPONENT_FAIL:
      return {
        ...state,
        componentCreateState: 'error',
        componentCreateError: action.error.message,
      };
    case ComponentActionTypes.UPDATE_COMPONENT:
      return {
        ...state,
        componentUpdateState: 'loading',
      };
    case ComponentActionTypes.UPDATE_COMPONENT_SUCCESS:
      return {
        ...state,
        componentUpdateState: 'success',
      };
    case ComponentActionTypes.UPDATE_COMPONENT_FAIL:
      return {
        ...state,
        componentUpdateState: 'error',
        componentUpdateError: action.error.message,
      };
    case ComponentActionTypes.DELETE_COMPONENT:
      return {
        ...state,
        componentDeleteState: 'loading',
      };
    case ComponentActionTypes.DELETE_COMPONENT_SUCCESS:
      return {
        ...state,
        componentDeleteState: 'success',
      };
    case ComponentActionTypes.DELETE_COMPONENT_FAIL:
      return {
        ...state,
        componentDeleteState: 'error',
        componentDeleteError: action.error.message,
      };
    case ComponentActionTypes.GET_COMPONENT_BY_ID:
      return {
        ...state,
        componentByIdState: 'loading',
      };
    case ComponentActionTypes.GET_COMPONENT_BY_ID_SUCCESS:
      return {
        ...state,
        componentsList: upsertResource(state.componentsList, action.payload.data),
        componentDocFiles: action.payload.docFiles,
        componentCadFiles: action.payload.cadFiles,
        componentByIdState: 'success',
      };
    case ComponentActionTypes.GET_COMPONENT_BY_ID_FAIL:
      return {
        ...state,
        componentByIdState: 'error',
        componentByIdStateError: action.payload,
      };
    case ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE:
    case ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE: {
      const listKey =
        action.type === ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE ? 'componentCadFiles' : 'componentDocFiles';
      return {
        ...state,
        [listKey]: action.payload.map((f) => (f.state === 'idle' ? { ...f, state: 'loading' } : f)),
      };
    }
    case ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE_SUCCESS:
    case ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_SUCCESS: {
      const listKey =
        action.type === ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_SUCCESS
          ? 'componentCadFiles'
          : 'componentDocFiles';
      const file = action.payload;
      return {
        ...state,
        [listKey]: state[listKey]
          // NOTE: Filter out old files with same usage as `file` and not pending validation
          .filter(
            (f) =>
              f.name === file.name ||
              !(f.usedAs?.some((u) => file.usedAs?.includes(u)) && (f.state === 'success' || f.state === 'error')),
          )
          .map((f) =>
            file.name === f.name
              ? {
                  ...f,
                  ...file,
                }
              : f,
          ),
      };
    }
    case ComponentActionTypes.UPLOAD_COMPONENT_DOC_FILE_FAIL:
    case ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_FAIL: {
      const listKey =
        action.type === ComponentActionTypes.UPLOAD_COMPONENT_CAD_FILE_FAIL ? 'componentCadFiles' : 'componentDocFiles';
      const file = action.payload;

      return {
        ...state,
        [listKey]: state[listKey].map((f) =>
          f.name === file.name
            ? {
                ...f,
                ...file,
              }
            : f,
        ),
        [`${listKey}Error`]: file.error,
      };
    }
    case ComponentActionTypes.DELETE_COMPONENT_DOC_FILE:
    case ComponentActionTypes.DELETE_COMPONENT_CAD_FILE: {
      const listKey =
        action.type === ComponentActionTypes.DELETE_COMPONENT_CAD_FILE ? 'componentCadFiles' : 'componentDocFiles';
      return {
        ...state,
        [listKey]: state[listKey].filter((f) => f.id !== action.payload.id),
      };
    }
    case ComponentActionTypes.CLEAN_UP_COMPONENTS: {
      return { ...state, componentDocFiles: [], componentCadFiles: [] };
    }
    case ComponentActionTypes.CLEAN_UP_COMPONENT_LINKS: {
      return { ...state, componentsLinkList: [] };
    }
    default:
      return state;
  }
};
