import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getVirtualComponentByIdSuccess,
  getVirtualComponentByIdError,
  ENDPOINT_VIRTUAL_COMPONENTS,
} from 'state_management/actions/virtualComponents/virtualComponentsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetVirtualComponentByIdErrorAction,
  GetVirtualComponentByIdSuccessAction,
  GetVirtualComponentByIdAction,
  VirtualComponentsActionTypes,
} from 'state_management/actions/virtualComponents/ActionTypes';
import { IVirtualComponent } from 'state_management/reducers/virtualComponents/Modals';
import { serializeVirtualComponent } from 'utils/virtualComponentsSerializer';
import { IFileUpload } from 'models/IFileUpload';
import { ENDPOINT_REQUESTS } from 'state_management/actions/changesRequest/changesRequestActions';

function* getFileFromDifferentSources(
  fileType: 'datasheet_files' | 'cad_files',
  fileId: string,
  virtualComponentId: string,
  changeRequestId?: string,
): Generator<CallEffect<AxiosResponse>> {
  let rawResFile: AxiosResponse;
  /* 
    First we try to get the files from the implementation since most of the cases it will be successful there
    If we didn't succeed, it is usually the old data of change request or the newly requested data
    and we need to get it from the change request
    Doing try catch because if we don't find the file, axios will throw an error
  */
  try {
    rawResFile = (yield call(() =>
      axiosInstance.get(
        apiUri(`/dataservice/${ENDPOINT_VIRTUAL_COMPONENTS}/${virtualComponentId}/${fileType}/${fileId}`, 2),
      ),
    )) as AxiosResponse;
    if (!rawResFile || !rawResFile.data) {
      throw new Error();
    }
  } catch {
    rawResFile = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_REQUESTS}/${changeRequestId}/${fileType}/${fileId}`, 2)),
    )) as AxiosResponse;
  }

  return rawResFile;
}

export function* getVirtualComponentFilesSaga(
  virtualComponent: Raw.IVirtualComponent,
  changeRequestId?: string,
): Generator<CallEffect<AxiosResponse>> {
  const { cad_files, datasheet_files } = virtualComponent;

  const docFileIds = datasheet_files?.flatMap((f) => f.filename) || [];
  const cadFileIds = cad_files?.flatMap((f) => f.filename) || [];

  const resDocFiles = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < docFileIds.length; i++) {
    const fileId = docFileIds[i];
    if (!fileId) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const rawResFile = (yield call(() =>
      getFileFromDifferentSources('datasheet_files', fileId, virtualComponent.id, changeRequestId),
    )) as AxiosResponse;

    resDocFiles.push(rawResFile?.data);
  }

  const resCadFiles = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < cadFileIds.length; i++) {
    const fileId = cadFileIds[i];
    if (!fileId) {
      // eslint-disable-next-line no-continue
      continue;
    }
    const rawResFile = (yield call(() =>
      getFileFromDifferentSources('cad_files', fileId, virtualComponent.id, changeRequestId),
    )) as AxiosResponse;

    resCadFiles.push(rawResFile?.data);
  }

  return [resDocFiles, resCadFiles];
}

export function* getByIdSaga(
  action: GetVirtualComponentByIdAction,
): Generator<
  | PutEffect<GetVirtualComponentByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetVirtualComponentByIdSuccessAction | GetVirtualComponentByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_VIRTUAL_COMPONENTS}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const rawVirtualComponent: Raw.IVirtualComponent = res.data;

    const [resDocFiles, resCadFiles] = (yield call(() => getVirtualComponentFilesSaga(rawVirtualComponent))) as Array<
      Array<Raw.IMinioResource>
    >;

    const parsedVirtualComponent: IVirtualComponent = serializeVirtualComponent(res.data);

    yield put(
      getVirtualComponentByIdSuccess({
        data: parsedVirtualComponent,
        docFiles:
          resDocFiles?.map(
            (f: Raw.IMinioResource) =>
              ({
                id: f.id,
                name: f.display_name,
                state: 'success',
                createdAt: f.created_at,
                usedAs: [f.type],
              } as IFileUpload),
          ) || [],
        cadFiles:
          resCadFiles?.map(
            (f: Raw.IMinioResource) =>
              ({
                id: f.id,
                name: f.display_name,
                state: 'success',
                createdAt: f.created_at,
                usedAs: [f.type],
              } as IFileUpload),
          ) || [],
      }),
    );
  } catch (error) {
    yield put(
      getVirtualComponentByIdError(getErrorMessage(error) || 'Fetching virtualComponents failed. Please try again...'),
    );
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_BY_ID, getByIdSaga);
}
