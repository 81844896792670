import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, call, SelectEffect, takeEvery } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getInterfaceByIdSuccess,
  getInterfaceByIdError,
  ENDPOINT_INTERFACES,
} from 'state_management/actions/interfaces/interfacesActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeInterface } from 'utils/interfaceSerializer';
import {
  GetInterfaceByIdAction,
  GetInterfaceByIdErrorAction,
  GetInterfaceByIdSuccessAction,
  InterfaceActionTypes,
} from '../../actions/interfaces/ActionTypes';
import { InterfaceType } from '../../reducers/interfaces/Modals';

export function* getByIdSaga(
  action: GetInterfaceByIdAction,
): Generator<
  | PutEffect<GetInterfaceByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetInterfaceByIdSuccessAction | GetInterfaceByIdErrorAction>
> {
  try {
    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_INTERFACES}/${action.payload.id}`, 2)),
    )) as AxiosResponse;

    const parsedInterfaceType: InterfaceType = serializeInterface(res.data);

    yield put(getInterfaceByIdSuccess(parsedInterfaceType));
  } catch (error) {
    yield put(getInterfaceByIdError(getErrorMessage(error) || 'Fetching interface failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  // NOTE: TakeEvery as in some places like LinkSettings for call for multiple Interfaces for multiple ids
  yield takeEvery(InterfaceActionTypes.GET_INTERFACE_BY_ID, getByIdSaga);
}
