import { all, AllEffect } from 'redux-saga/effects';

import { authRootSaga } from './auth';
import { designBlocksRootSaga } from './designBlocks';
import { changesRequestRootSaga } from './changesRequest';
import { interfaceRootSaga } from './interfaces';
import { implementationsRootSaga } from './implementations';
import { specificationsRootSaga } from './specifications';
import { componentsRootSaga } from './components';
import { categoriesRootSaga } from './categories';
import { notificationsRootSaga } from './notifications';
import { projectsRootSaga } from './projects';
import { sharingRootSaga } from './sharing';
import { billOfMaterialsRootSaga } from './billOfMaterials';
import { designCanvasRootSaga } from './designCanvas';
import { boardShapeCanvasRootSaga } from './boardShapeCanvas';
import { supportRootSaga } from './support';
import { filesRootSaga } from './files';
import { refDesignsRootSaga } from './referenceDesigns';
import { subSystemCanvasRootSaga } from './subSystemCanvas';
import { pluginsRootSaga } from './plugins';
import { changeLogRootSaga } from './changeLog';
import { crudRequestMiddlewareWatcher } from './utils/crudUtilities/crudUtilityMiddleware';
import { manufacturersRootSaga } from './manufacturers';
import { virtualComponentsRootSaga } from './virtualComponents';
import { suppliersRootSaga } from './suppliers';
import { designConsiderationsRootSaga } from './designConsiderations';
import { exportJobsRootSaga } from './exportJobs';
import { exportTemplatesRootSaga } from './exportTemplates';
import { unitConverterRootSaga } from './unitConverter';
import { bomModificationRootSaga } from './bomModification';
import { ermisRootSaga } from './ermiSaga';
import { projectGeneralSettingsRootSaga } from './projectGeneralSettings';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function* rootSaga(): Generator<AllEffect<any>> {
  yield all([
    authRootSaga(),
    designBlocksRootSaga(),
    interfaceRootSaga(),
    changesRequestRootSaga(),
    changeLogRootSaga(),
    implementationsRootSaga(),
    specificationsRootSaga(),
    componentsRootSaga(),
    categoriesRootSaga(),
    notificationsRootSaga(),
    projectsRootSaga(),
    sharingRootSaga(),
    billOfMaterialsRootSaga(),
    designCanvasRootSaga(),
    boardShapeCanvasRootSaga(),
    bomModificationRootSaga(),
    supportRootSaga(),
    filesRootSaga(),
    refDesignsRootSaga(),
    subSystemCanvasRootSaga(),
    pluginsRootSaga(),
    crudRequestMiddlewareWatcher(),
    manufacturersRootSaga(),
    virtualComponentsRootSaga(),
    suppliersRootSaga(),
    designConsiderationsRootSaga(),
    exportJobsRootSaga(),
    exportTemplatesRootSaga(),
    unitConverterRootSaga(),
    ermisRootSaga(),
    projectGeneralSettingsRootSaga(),
  ]);
}
