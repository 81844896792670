const XML_ESCAPE_CHARS: Record<string, string> = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  "'": '&#39;',
  '"': '&#34;',
};

const XML_UNESCAPE_CHARS: Record<string, string> = {
  '&amp;': '&',
  '&lt;': '<',
  '&gt;': '>',
  '&#39;': "'",
  '&#34;': '"',
};

export const escapeXmlText = (text?: string): string | undefined =>
  text ? text.replace(/[<>&'"]/g, (char) => XML_ESCAPE_CHARS[char]) : text;

export const unescapeXmlText = (text?: string): string | undefined => {
  if (!text) {
    return text;
  }
  let unescapeXml = text;
  Object.keys(XML_UNESCAPE_CHARS).forEach((key) => {
    unescapeXml = unescapeXml.replace(new RegExp(key, 'g'), (char) => XML_UNESCAPE_CHARS[char]);
  });
  return unescapeXml;
};
