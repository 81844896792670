/* eslint-disable no-lonely-if */
/* eslint-disable no-loop-func */
import {
  Filter,
  MinMaxSearchValue,
  MultipleValuesSearchValue,
  SearchFieldsOptions,
  SearchValue,
  TypToleranceSearchValue,
  OperationData,
  BetweenSearchValue,
  SimpleSearchValue,
} from 'components/AdvancedSearch/Modals';
import { FormSchema, FormSchemaChild } from 'components/FormBuilder/Modals';
import { NO_UNIT_OPTION } from 'components/SpecificationsForm/constants';
import { InterfaceType } from 'state_management/reducers/interfaces/Modals';
import { ISpecification } from 'state_management/reducers/specifications/Modals';
import { DetailedValue } from './detailedValue';
import { parseString } from './smartUnitConverter';
import { convertUnit } from './specificationsSerializer';
import { getListOfUnits } from './specificationUtils';
import { doubleUriEncodeSpecailChars } from './urlUtils';

// This is global OR
// e.g. `<ROUTE>?query=<QUERY_1>;<QUERY_2>%OR%<QUERY_3>...` this will return ((QUERY_1 AND QUERY_2) OR QUERY_3)
const orSymbol = '%25OR%25';
// This is inner OR
// e.g. `<ROUTE>?query=<QUERY_1>;<QUERY_2>%or%<QUERY_3>...` this will return (QUERY_1 AND (QUERY_2 OR QUERY_3)
const innerOrSymbol = '%25or%25';
export const VALUE_QUERY_PLACEHOLDER = 'VALUE_QUERY_PLACEHOLDER';

export interface TypeSearch {
  [key: string]: 'value' | TypeSearch;
}
export type SpecificationsSchema = Array<{ specDbKey: string; specList: Array<ISpecification> }>;

export const getSearchQueryFields = (search: string): string => {
  let newSearch = '';
  let isCombined = false;
  search
    .trim()
    .replace(/\s\s+/g, ' ')
    .split('')
    .forEach((char) => {
      let newChar = char;
      if (char === '"') {
        isCombined = !isCombined;
        newChar = '';
      }

      if (char === ' ') {
        newChar = isCombined ? char : '","';
      }
      newSearch += newChar;
    });
  return `["${newSearch}"]`;
};

export const getFieldKeys = (searchSchema: TypeSearch, keyPrefix = ''): string => {
  let fieldKeys = '';
  Object.keys(searchSchema).forEach((key) => {
    if (searchSchema[key] === 'value') {
      fieldKeys += fieldKeys && !fieldKeys.endsWith(',') ? ',' : '';
      fieldKeys += `"${keyPrefix}${key}"`;
    } else {
      fieldKeys += fieldKeys && fieldKeys.endsWith(',') ? '' : ',';
      fieldKeys += `${getFieldKeys(searchSchema[key] as TypeSearch, `${keyPrefix}${key}.`)}`;
    }
  });
  return fieldKeys;
};

export const getUnitAndValue = (str: string, unitsList: Array<string>): { value: string; unit: string } | undefined => {
  const match = str.match(new RegExp(`([0-9]+(.[0-9]+)?) *(${unitsList.join('|')})`));
  if (match) {
    return { value: match[1], unit: match[3] };
  }
  return undefined;
};

export const getSpecsList = (unit: string, specificationsList: Array<ISpecification>): Array<string> =>
  specificationsList.filter((spec) => spec.unit === unit).map((spec) => spec.id || '');

export const getSearchQuery = (
  search: string,
  searchSchema: TypeSearch,
  specificationsSchema?: SpecificationsSchema,
): string => {
  if (search.match(/^ *$/)) {
    return '';
  }
  const parsedQuery = parseString(search);
  const detailedValues: Array<DetailedValue> = parsedQuery.filter(
    (v) => v instanceof DetailedValue,
  ) as Array<DetailedValue>;
  const stringValues: Array<string> = parsedQuery.filter((v) => typeof v === 'string') as Array<string>;

  let query = '';
  const fieldKeys = getFieldKeys(searchSchema);
  stringValues
    .join(' ')
    .replace(/;+/g, '')
    .split('/')
    .forEach((u) => {
      query += query ? orSymbol : 'query=';
      query += `[${fieldKeys}]-match-all-${getSearchQueryFields(u)}`;
    });

  specificationsSchema &&
    detailedValues.forEach((dV) => {
      query += query ? ';' : 'query=';
      specificationsSchema.forEach((specSchema) => {
        const unitSpecifications = getSpecsList(dV.unit, specSchema.specList);
        if (unitSpecifications.length) {
          const unitSpecificationsList = unitSpecifications.map((spec) => `${specSchema.specDbKey}${spec}.value`);
          query += query ? ';' : 'query=';
          query += `${JSON.stringify(unitSpecificationsList)}-match-all-[${dV.value}]`;
        }
      });
    });

  query = query.replace(/,+/g, ',').replace(/;+/g, ';');
  return query;
};

// The `is` and `is not` are not real mongo operators, but the changes depending on the field type
// This function will transform that to the real mongo operator
const getIsOperator = (operator: 'is' | 'isNot', fieldType: string, dbFieldType: string): string =>
  ({
    select_field_is: '-exists',
    select_field_isNot: '-not-exists',
  }[`${fieldType}_${dbFieldType}_${operator}` as 'select_field_is' | 'select_field_isNot'] ||
  {
    is: '=',
    isNot: '!=',
  }[operator]);

const MATCH_ELEMENT_QUERY_MAPPERS = {
  '>': '$gt',
  '>=': '$gte',
  '<': '$lt',
  '<=': '$lte',
  '=': '$eq',
  '!=': '$ne',
  '-i-contains-': '$regex',
  '-not-i-contains-': '$regex', // The `not`will be on top of the value object
  is: '$eq',
  isNot: '$ne',
  '-nin-': '$nin',
  '-in-': '$in',
  '-contains-all-': '$all',
};

export const createTypToleranceSearchQuery = (
  currentQuery: string,
  filter: Filter,
  withInstanceSpecification = false,
  withUnitConversion = false,
): string => {
  let searchQuery = '';
  if (!filter?.userValues || !filter?.field) return searchQuery;

  searchQuery = currentQuery;
  const dataObject = filter.userValues as TypToleranceSearchValue;
  const keys = Object.keys(dataObject);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i] as keyof TypToleranceSearchValue;
    if (dataObject[key]?.operator && dataObject[key]?.data) {
      if (filter.field.dbFieldType !== 'embeddedList') {
        if (dataObject[key]?.operator?.value?.toLowerCase() === 'between') {
          const { data } = (dataObject[key] || {}) as OperationData;
          const { minData, maxData } = (data || {}) as BetweenSearchValue;
          if (key === 'typ') {
            if (minData?.unit && minData?.value !== undefined)
              searchQuery += `${filter?.field?.value}.${key}>=${convertUnit(
                minData?.value as number,
                minData?.unit,
                filter?.field?.mainUnit,
                withUnitConversion,
              )};`;
            if (maxData?.unit && maxData?.value !== undefined)
              searchQuery += `${filter?.field?.value}.${key}<=${convertUnit(
                maxData?.value as number,
                maxData?.unit,
                filter?.field?.mainUnit,
                withUnitConversion,
              )};`;
          } else {
            if (key === 'toleranceMin') {
              if (minData?.unit === '%' || maxData?.unit === '%') {
                if (minData?.value !== undefined && minData?.unit)
                  searchQuery += `${filter?.field?.value}.min>=${minData?.value as number};`;
                if (maxData?.value !== undefined && maxData?.unit)
                  searchQuery += `${filter?.field?.value}.min<=${maxData?.value as number};`;
              } else {
                if (minData?.value !== undefined && minData?.unit)
                  searchQuery += `${filter?.field?.value}.min>=${convertUnit(
                    minData?.value as number,
                    minData?.unit,
                    filter?.field?.mainUnit,
                    withUnitConversion,
                  )};`;
                if (maxData?.value !== undefined && maxData?.unit)
                  searchQuery += `${filter?.field?.value}.min<=${convertUnit(
                    maxData?.value as number,
                    maxData?.unit,
                    filter?.field?.mainUnit,
                    withUnitConversion,
                  )};`;
              }
            } else {
              if (minData?.unit === '%' || maxData?.unit === '%') {
                if (minData?.value !== undefined && minData?.unit)
                  searchQuery += `${filter?.field?.value}.max>=${minData?.value as number};`;
                if (maxData?.value !== undefined && maxData?.unit)
                  searchQuery += `${filter?.field?.value}.max<=${maxData?.value as number};`;
              } else {
                if (minData?.value !== undefined && minData?.unit)
                  searchQuery += `${filter?.field?.value}.max>=${convertUnit(
                    minData?.value as number,
                    minData?.unit,
                    filter?.field?.mainUnit,
                    withUnitConversion,
                  )};`;
                if (maxData?.value !== undefined && maxData?.unit)
                  searchQuery += `${filter?.field?.value}.max<=${convertUnit(
                    maxData?.value as number,
                    maxData?.unit,
                    filter?.field?.mainUnit,
                    withUnitConversion,
                  )};`;
              }
            }
          }
        } else {
          const { operator, data } = (dataObject[key] || {}) as OperationData;
          const { value, unit } = (data || {}) as SimpleSearchValue;
          if (key === 'typ') {
            if (value !== undefined && unit)
              searchQuery += `${filter?.field?.value}.${key}${operator?.value}${convertUnit(
                value as number,
                unit,
                filter?.field?.mainUnit,
                withUnitConversion,
              )};`;
          } else {
            const convertedValue =
              unit === '%' ? value : convertUnit(value as number, unit, filter?.field?.mainUnit, withUnitConversion);
            if (convertedValue !== undefined && value !== undefined && unit) {
              if (key === 'toleranceMin')
                searchQuery += `${filter?.field?.value}.min${operator?.value}${convertedValue};`;
              else searchQuery += `${filter?.field?.value}.max${operator?.value}${convertedValue};`;
            }
          }
        }
      } else {
        const specKeyWord = withInstanceSpecification ? 'instance_specifications' : 'specifications';
        if (dataObject[key]?.operator?.value?.toLowerCase() === 'between') {
          const { data } = dataObject[key] as OperationData;
          const { minData, maxData } = (data || {}) as BetweenSearchValue;
          if (key === 'typ') {
            if (minData?.value !== undefined && minData?.unit) {
              searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__${key}":${VALUE_QUERY_PLACEHOLDER}};`;
              searchQuery = searchQuery.replace(
                VALUE_QUERY_PLACEHOLDER,
                `{"$gte":${convertUnit(
                  minData?.value as number,
                  minData?.unit,
                  filter?.field?.mainUnit,
                  withUnitConversion,
                )}}`,
              );
            }

            if (maxData?.value !== undefined && maxData?.unit) {
              searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__${key}":${VALUE_QUERY_PLACEHOLDER}};`;
              searchQuery = searchQuery.replace(
                VALUE_QUERY_PLACEHOLDER,
                `{"$lte":${convertUnit(
                  maxData?.value as number,
                  maxData?.unit,
                  filter?.field?.mainUnit,
                  withUnitConversion,
                )}}`,
              );
            }
          } else {
            if (key === 'toleranceMin') {
              if (minData?.unit === '%' || maxData?.unit === '%') {
                if (minData?.value !== undefined && minData?.unit) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__min":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(VALUE_QUERY_PLACEHOLDER, `{"$gte":${minData?.value}}`);
                }
                if (maxData?.value !== undefined && maxData?.unit) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__min":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(VALUE_QUERY_PLACEHOLDER, `{"$lte":${maxData?.value}}`);
                }
              } else {
                if (minData?.unit && minData?.value !== undefined) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__min":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(
                    VALUE_QUERY_PLACEHOLDER,
                    `{"$gte":${convertUnit(
                      minData?.value as number,
                      minData?.unit,
                      filter?.field?.mainUnit,
                      withUnitConversion,
                    )}}`,
                  );
                }
                if (maxData?.unit && maxData?.value !== undefined) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__min":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(
                    VALUE_QUERY_PLACEHOLDER,
                    `{"$lte":${convertUnit(
                      maxData?.value as number,
                      maxData?.unit,
                      filter?.field?.mainUnit,
                      withUnitConversion,
                    )}}`,
                  );
                }
              }
            } else {
              if (minData?.unit === '%' || maxData?.unit === '%') {
                if (minData?.value !== undefined && minData?.unit) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__max":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(VALUE_QUERY_PLACEHOLDER, `{"$gte":${minData?.value}}`);
                }
                if (maxData?.value !== undefined && maxData?.unit) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__max":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(VALUE_QUERY_PLACEHOLDER, `{"$lte":${maxData?.value}}`);
                }
              } else {
                if (minData?.unit && minData?.value !== undefined) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__max":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(
                    VALUE_QUERY_PLACEHOLDER,
                    `{"$gte":${convertUnit(
                      minData?.value as number,
                      minData?.unit,
                      filter?.field?.mainUnit,
                      withUnitConversion,
                    )}}`,
                  );
                }
                if (maxData?.unit && maxData?.value !== undefined) {
                  searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__max":${VALUE_QUERY_PLACEHOLDER}};`;
                  searchQuery = searchQuery.replace(
                    VALUE_QUERY_PLACEHOLDER,
                    `{"$lte":${convertUnit(
                      maxData?.value as number,
                      maxData?.unit,
                      filter?.field?.mainUnit,
                      withUnitConversion,
                    )}}`,
                  );
                }
              }
            }
          }
        } else {
          const { operator, data } = ((dataObject as any)[key] || {}) as OperationData;
          const { value, unit } = (data || {}) as SimpleSearchValue;
          if (key === 'typ') {
            if (value !== undefined && unit) {
              searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__${key}":${VALUE_QUERY_PLACEHOLDER}};`;
              searchQuery = searchQuery.replace(
                VALUE_QUERY_PLACEHOLDER,
                `{"${
                  MATCH_ELEMENT_QUERY_MAPPERS[operator?.value as keyof typeof MATCH_ELEMENT_QUERY_MAPPERS]
                }":${convertUnit(value as number, unit, filter?.field?.mainUnit, withUnitConversion)}}`,
              );
            }
          } else {
            const convertedValue =
              unit === '%' ? value : convertUnit(value as number, unit, filter?.field?.mainUnit, withUnitConversion);
            if (convertedValue !== undefined && value !== undefined && unit && operator?.value) {
              if (key === 'toleranceMin') {
                searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__min":${VALUE_QUERY_PLACEHOLDER}};`;
                searchQuery = searchQuery.replace(
                  VALUE_QUERY_PLACEHOLDER,
                  `{"${
                    MATCH_ELEMENT_QUERY_MAPPERS[operator?.value as keyof typeof MATCH_ELEMENT_QUERY_MAPPERS]
                  }":${convertedValue}}`,
                );
              } else {
                searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__max":${VALUE_QUERY_PLACEHOLDER}};`;
                searchQuery = searchQuery.replace(
                  VALUE_QUERY_PLACEHOLDER,
                  `{"${
                    MATCH_ELEMENT_QUERY_MAPPERS[operator?.value as keyof typeof MATCH_ELEMENT_QUERY_MAPPERS]
                  }":${convertedValue}}`,
                );
              }
            }
          }
        }
      }
    }
  }
  /* Remove the last semicolon, if there is any */

  const finalQuery = searchQuery.endsWith(';') ? searchQuery.substring(0, searchQuery.length - 1) : searchQuery;
  return finalQuery;
};

export const createMinMaxTypSearchQuery = (
  currentQuery: string,
  filter: Filter,
  withInstanceSpecification = false,
  withUnitConversion = false,
): string => {
  let searchQuery = '';
  if (!filter?.userValues || !filter?.field) return searchQuery;

  searchQuery = currentQuery;
  const dataObject = filter.userValues as MinMaxSearchValue;

  const keys = Object.keys(dataObject);
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i] as keyof MinMaxSearchValue;

    if (dataObject[key]?.operator && dataObject[key]?.data) {
      if (filter.field.dbFieldType !== 'embeddedList') {
        if (dataObject[key]?.operator?.value.toLowerCase() === 'between') {
          const { data } = (dataObject[key] || {}) as OperationData;
          const { minData, maxData } = (data || {}) as BetweenSearchValue;
          if (minData?.value !== undefined && minData?.unit)
            searchQuery += `${filter?.field?.value}.${key}>=${convertUnit(
              minData?.value as number,
              minData?.unit,
              filter?.field?.mainUnit,
              withUnitConversion,
            )};`;
          if (maxData?.unit && maxData?.value !== undefined)
            searchQuery += `${filter?.field?.value}.${key}<=${convertUnit(
              maxData?.value as number,
              maxData?.unit,
              filter?.field?.mainUnit,
              withUnitConversion,
            )};`;
        } else {
          const { operator, data } = (dataObject[key] || {}) as OperationData;
          const { value, unit } = (data || {}) as SimpleSearchValue;
          if (value !== undefined && unit)
            searchQuery += `${filter?.field?.value}.${key}${operator?.value}${convertUnit(
              value as number,
              unit,
              filter?.field?.mainUnit,
              withUnitConversion,
            )};`;
        }
      } else {
        const specKeyWord = withInstanceSpecification ? 'instance_specifications' : 'specifications';
        if (dataObject[key]?.operator?.value.toLowerCase() === 'between') {
          const { data } = dataObject[key] as OperationData;
          const { minData, maxData } = (data || {}) as BetweenSearchValue;

          if (minData?.unit && minData?.value !== undefined) {
            searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__${key}":${VALUE_QUERY_PLACEHOLDER}};`;
            searchQuery = searchQuery.replace(
              VALUE_QUERY_PLACEHOLDER,
              `{"$gte":${convertUnit(
                minData?.value as number,
                minData?.unit,
                filter?.field?.mainUnit,
                withUnitConversion,
              )}}`,
            );
          }
          if (maxData?.unit && maxData?.value !== undefined) {
            searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__${key}":${VALUE_QUERY_PLACEHOLDER}};`;
            searchQuery = searchQuery.replace(
              VALUE_QUERY_PLACEHOLDER,
              `{"$lte":${convertUnit(
                maxData?.value as number,
                maxData?.unit,
                filter?.field?.mainUnit,
                withUnitConversion,
              )}}`,
            );
          }
        } else {
          const { operator, data } = (dataObject[key] || {}) as OperationData;
          const { value, unit } = (data || {}) as SimpleSearchValue;
          if (value !== undefined && unit) {
            searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value__${key}":${VALUE_QUERY_PLACEHOLDER}};`;
            searchQuery = searchQuery.replace(
              VALUE_QUERY_PLACEHOLDER,
              `{"${
                MATCH_ELEMENT_QUERY_MAPPERS[operator?.value as keyof typeof MATCH_ELEMENT_QUERY_MAPPERS]
              }":${convertUnit(value as number, unit, filter?.field?.mainUnit, withUnitConversion)}}`,
            );
          }
        }
      }
    }
  }
  /* Remove the last semicolon, if there is any */
  return searchQuery.endsWith(';') ? searchQuery.substring(0, searchQuery.length - 1) : searchQuery;
};

export const createMultipleValueSearchQuery = (
  currentQuery: string,
  filter: Filter,
  withInstanceSpecification = false,
  withUnitConversion = false,
): string => {
  const specKeyWord = withInstanceSpecification ? 'instance_specifications' : 'specifications';
  let searchQuery = '';
  if (!filter?.userValues || !filter?.field) return searchQuery;
  searchQuery = currentQuery;

  let arrayData = '';
  const { value, unit } = (filter.userValues as MultipleValuesSearchValue) || {};
  if (filter.field.type === 'string') {
    arrayData = JSON.stringify((value as Array<string>) || []);
  } else if (['number', 'integer'].includes(filter?.field?.type?.toLocaleLowerCase())) {
    if (unit !== NO_UNIT_OPTION)
      arrayData = JSON.stringify(
        ((value as Array<number>) || []).map((n) => convertUnit(n, unit, filter?.field?.mainUnit, withUnitConversion)),
      );
    else arrayData = JSON.stringify((value as Array<number>) || []);
  }

  if (filter?.field?.dbFieldType !== 'embeddedList') {
    searchQuery += `${filter?.field?.value}${filter?.operator?.value}${arrayData}`;
  } else {
    if (filter?.operator?.value !== '-not-contains-all-') {
      searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value":${VALUE_QUERY_PLACEHOLDER}}`;
      searchQuery = searchQuery.replace(
        VALUE_QUERY_PLACEHOLDER,
        `{"${
          MATCH_ELEMENT_QUERY_MAPPERS[filter?.operator?.value as keyof typeof MATCH_ELEMENT_QUERY_MAPPERS]
        }":${arrayData}}`,
      );
    } else {
      searchQuery += `${specKeyWord}-match-{"id":"${filter?.field?.id}","value":{"$not":{"$all":${arrayData}}}}`;
    }
  }
  return searchQuery;
};

export const getAdvancedSearchQuery = (
  _filters: Array<Array<Filter>>,
  withInstanceSpecification = false,
  withUnitConversion = false,
): string => {
  const filters = _filters.filter(Boolean);
  let searchQuery = filters?.length && filters.map((f) => f.length).some((v) => v) ? 'query=' : '';

  filters.forEach((query) => {
    searchQuery += query.length && !searchQuery.endsWith('query=') ? orSymbol : '';
    query.forEach((filter) => {
      if ([undefined, 'single'].includes(filter?.field?.inputType) || !('inputType' in (filter?.field || {}))) {
        if (filter.disabled || !filter.operator) {
          return;
        }
        searchQuery +=
          searchQuery.endsWith(';') || searchQuery.endsWith(orSymbol) || searchQuery.endsWith('query=') ? '' : ';';

        // hard-coded fields -------------------------
        if (filter.field.dbFieldType === 'embeddedList' || filter.parentField?.dbFieldType === 'embeddedList') {
          // EmbeddedDocumentLists are queries with match element
          // https://www.mongodb.com/docs/manual/reference/operator/query/elemMatch/
          const operator =
            filter.operator.value === 'between'
              ? '$gte'
              : MATCH_ELEMENT_QUERY_MAPPERS[filter.operator.value as keyof typeof MATCH_ELEMENT_QUERY_MAPPERS];

          const doubleQuotes = filter.field.type === 'number' ? '' : '"';
          let valueQuery =
            operator === '$eq'
              ? `${doubleQuotes}${(filter.values as SearchValue)?.value}${doubleQuotes}`
              : `{"${operator}": ${doubleQuotes}${(filter.values as SearchValue)?.value}${doubleQuotes}}`;
          if (filter.operator.value?.startsWith('-not-')) {
            valueQuery = `{"$not": ${valueQuery}}`;
          }
          searchQuery += filter.field.value.replace(VALUE_QUERY_PLACEHOLDER, valueQuery);
          if (filter.operator.value === 'between') {
            const maxValueQuery = `{"$lte": ${(filter.values as SearchValue)?.max}}`;
            searchQuery += `;${filter.field.value.replace(VALUE_QUERY_PLACEHOLDER, maxValueQuery)}`;
          }
        } else if (filter.operator.value === 'between') {
          // The between two numbers operator requires two queries
          searchQuery += `${filter.field.value}>=${(filter.values as SearchValue)?.value};`;
          searchQuery += `${filter.field.value}<=${(filter.values as SearchValue)?.max}`;
        } else if (filter.field.dbFieldType === 'field') {
          // Whenever the value is an actual field and not a value to search for
          searchQuery += `${filter.field.value}.${(filter.values as SearchValue)?.value}${getIsOperator(
            filter.operator.value as 'is' | 'isNot',
            filter.field.type as string,
            filter.field.dbFieldType,
          )}`;
          // Generic fields -------------------------
        } else if (filter.field.type === 'boolean') {
          const exists = filter.operator.value === '-exists';
          let operatorAndValue: string;
          switch (filter.field.dbFieldType) {
            case 'string':
              operatorAndValue = exists ? '!=""' : '=""';
              break;
            case 'list':
              operatorAndValue = exists ? `-exists` : `-not-exists`;
              break;
            case 'boolean':
              operatorAndValue = exists ? '=true' : '=false';
              break;
            default:
              operatorAndValue = filter.operator.value;
          }
          if (Array.isArray((filter.values as SearchValue)?.value)) {
            // When we have a list of values coming from a boolean filed (ex. checkbox)
            // We have to pass each value as a query filter and combine those with AND (`;`)
            ((filter.values as SearchValue)?.value as Array<string>).forEach((v) => {
              searchQuery +=
                searchQuery.endsWith(';') || searchQuery.endsWith(orSymbol) || searchQuery.endsWith('query=')
                  ? ''
                  : ';';

              const listQuery = exists ? '!=[]' : '=[]';
              const listQueryAndValue = exists ? `${v}${listQuery}` : '';
              searchQuery += `${v}${operatorAndValue}${exists ? ';' : ''}${listQueryAndValue}`;
            });
          } else {
            searchQuery += `${filter.field.value}${operatorAndValue}`;
          }
        } else if (filter.field.type === 'select') {
          if (filter.field.dbFieldType === 'boolean') {
            searchQuery += `${(filter.values as SearchValue)?.value}=${filter.operator.value === 'is'}`;
          } else {
            const operator = ['is', 'isNot'].includes(filter.operator.value)
              ? getIsOperator(
                  filter.operator.value as 'is' | 'isNot',
                  filter.field.type as string,
                  filter.field.dbFieldType || '',
                )
              : filter.operator.value;
            const _valueQuery =
              typeof (filter.values as SearchValue)?.value === 'string'
                ? `"${(filter.values as SearchValue)?.value}"`
                : (filter.values as SearchValue)?.value;
            searchQuery += `${filter.field.value}${operator}${_valueQuery}`;
          }
        } else if (filter.operator.valueType === 'string') {
          if (filter.field.dbFieldType === 'list') {
            const operator = filter.operator.value === '-i-contains-' ? '-in-' : '-nin-';
            searchQuery += `${filter.field.value}${operator}${JSON.stringify([(filter.values as SearchValue)?.value])}`;
          } else {
            const operator = ['is', 'isNot'].includes(filter.operator.value)
              ? getIsOperator(
                  filter.operator.value as 'is' | 'isNot',
                  filter.field.type as string,
                  filter.field.dbFieldType || '',
                )
              : filter.operator.value;
            searchQuery += `${filter.field.value}${operator}"${(filter.values as SearchValue)?.value}"`;
          }
        } else if (filter.operator.valueType === 'list') {
          searchQuery += `${filter.field.value}${filter.operator.value}${JSON.stringify(
            (filter.values as SearchValue)?.value as Array<string>,
          )}`;
        } else {
          searchQuery += `${filter.field.value}${filter.operator.value}${(filter.values as SearchValue)?.value}`;
        }
      } else if (['min_typ_max', 'min_max'].includes(filter?.field?.inputType) && !filter?.disabled) {
        searchQuery +=
          searchQuery.endsWith(';') || searchQuery.endsWith(orSymbol) || searchQuery.endsWith('query=') ? '' : ';';
        searchQuery = createMinMaxTypSearchQuery(searchQuery, filter, withInstanceSpecification, withUnitConversion);
      } else if (['tolerance', 'tolerance_percentage'].includes(filter?.field?.inputType) && !filter?.disabled) {
        searchQuery +=
          searchQuery.endsWith(';') || searchQuery.endsWith(orSymbol) || searchQuery.endsWith('query=') ? '' : ';';
        searchQuery = createTypToleranceSearchQuery(searchQuery, filter, withInstanceSpecification, withUnitConversion);
      } else if (filter?.field?.inputType === 'list' && !filter?.disabled) {
        searchQuery +=
          searchQuery.endsWith(';') || searchQuery.endsWith(orSymbol) || searchQuery.endsWith('query=') ? '' : ';';
        searchQuery = createMultipleValueSearchQuery(
          searchQuery,
          filter,
          withInstanceSpecification,
          withUnitConversion,
        );
      }
    });
  });
  //  This might be refactored if the express-app is discarded
  //  The double uri encoding with some special characters was implemented so
  //  the backend can get these characters without converting them to something else.
  return searchQuery === 'query=' ? '' : doubleUriEncodeSpecailChars(searchQuery);
};

export const serializeSpecifications = (
  specificationList: Array<ISpecification>,
  specificationPrefix = 'specifications',
): Array<SearchFieldsOptions> =>
  specificationList
    .map((spec) => ({
      id: spec.id,
      label: spec.name,
      value: `${specificationPrefix}.${spec.id}.value`,
      type: spec.dataType === 'integer' ? 'number' : (spec.dataType as 'string' | 'number' | 'list' | 'boolean'),
      inputType: spec.specificationValueType,
      units: spec.unit && spec.unit !== NO_UNIT_OPTION ? [spec.unit] : [],
      mainUnit: spec.unit,
      options: spec.options?.map((opt) => opt.value),
    }))
    .sort((specA, specB) => specA.label.localeCompare(specB.label));

export const serializeInstanceSpecifications = (
  specificationList: Array<ISpecification>,
  specificationPrefix = 'instance_specifications',
  physicalQuantitiesMap: Raw.IPhysicalQuantity = {},
): Array<SearchFieldsOptions> =>
  specificationList
    .map((spec) => ({
      id: spec.id,
      label: spec.name,
      inputType: spec.specificationValueType,
      value: `${specificationPrefix}-match-{"id":"${spec.id}","value": ${VALUE_QUERY_PLACEHOLDER}}`,
      type: spec.dataType === 'integer' ? 'number' : (spec.dataType as 'string' | 'number' | 'list' | 'boolean'),
      dbFieldType: 'embeddedList' as const,
      units:
        spec.unit && spec.unit !== NO_UNIT_OPTION
          ? physicalQuantitiesMap[spec.physicalQuantity]
              ?.filter((unit) => getListOfUnits(spec, physicalQuantitiesMap, unit.value))
              ?.map((i) => i.value) || [spec.unit]
          : [],
      mainUnit: spec.unit,
      options: spec.options?.map((opt) => opt.value),
    }))
    .sort((specA, specB) => specA.label.localeCompare(specB.label));

export const serializeInterfaces = (
  interfacesList: Array<InterfaceType>,
): { portTypes: Array<string>; portSignals: Array<string> } => {
  const portTypes: Array<string> = [];
  const portSignals = new Set();
  interfacesList.forEach((_interface) => {
    portTypes.push(_interface.name);
    _interface.ports.forEach((port) => {
      portSignals.add(port.signalId);
    });
  });
  return { portTypes, portSignals: [...(portSignals as unknown as Array<string>)] };
};

const combineFilters = (filters1: Array<string>, filters2: Array<string>): string => {
  let query = 'query=';
  filters1.forEach((q1) => {
    filters2.forEach((q2) => {
      query += query.endsWith(';') || query.endsWith(orSymbol) || query.endsWith('query=') ? '' : orSymbol;
      query += `${q1};${q2}`;
    });
  });
  return query;
};

export const enhanceSearchQuery = (
  query: string,
  withCount?: boolean,
  userId?: string,
  preferredWorkspaces?: Array<string>,
): string => {
  const countQuery = withCount ? '' : '&count=False';
  const userIdQuery = userId ? `${innerOrSymbol}_users_read-in-["${userId}"]${innerOrSymbol}_owner="${userId}"` : '';

  const preferredWorkspacesFilter = preferredWorkspaces
    ? `_groups_read-in-["${preferredWorkspaces.join(
        '","',
      )}"]${innerOrSymbol}share_type-contains-all-["private"]${userIdQuery}`
    : '';

  if (!query) {
    return `${
      preferredWorkspacesFilter ? `query=${preferredWorkspacesFilter}${countQuery}` : countQuery.replace('&', '')
    }`;
  }
  let queries = query;
  if (preferredWorkspacesFilter) {
    queries = combineFilters(query.replace('query=', '').split(orSymbol), [preferredWorkspacesFilter]);
  }

  return `${queries}${countQuery}`;
};

export const combineSearchQueries = (
  query1: string,
  query2: string,
  withCount?: boolean,
  userId?: string,
  preferredWorkspaces?: Array<string>,
): string => {
  if (!query1 || !query2) {
    return enhanceSearchQuery(query1 || query2, withCount, userId, preferredWorkspaces);
  }

  const query = combineFilters(
    query1.replace('query=', '').split(orSymbol),
    query2.replace('query=', '').split(orSymbol),
  );
  return enhanceSearchQuery(query, withCount, userId, preferredWorkspaces);
};

// WAPP-872: Improve this to consider specialComponents + injection of options from reducer
export const getAdvancedSearchSchemaFromFormBuilderSchema = (obj: Array<FormSchema>): Array<SearchFieldsOptions> => {
  const outputObj: Array<SearchFieldsOptions> = [];
  obj.forEach((o) => {
    o.options?.forEach((opt: Array<FormSchemaChild>) => {
      opt.forEach((_opt: FormSchemaChild) => {
        if (['text', 'specialComponent'].includes(_opt.type)) {
          // Ignoring text as it is just a text field not an  input
          // Ignoring special components for now since they have complex structure
          return;
        }
        outputObj.push({
          label: _opt.name,
          value: _opt.id,
          type: ['breadcrumb', 'icon'].includes(_opt.type) ? 'string' : _opt.type,
          dbFieldType: _opt.dataType,
          options: _opt.type !== 'breadcrumb' ? (_opt.options as Array<string | SearchFieldsOptions>) || [] : undefined,
        });
      });
    });
  });
  return outputObj;
};
