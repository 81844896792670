import {
  PutEffect,
  TakeEffect,
  ForkEffect,
  CallEffect,
  put,
  takeLatest,
  call,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import {
  ExportJobsActionTypes,
  GetAllExportJobsAction,
  GetAllExportJobsErrorAction,
  GetAllExportJobsSuccessAction,
} from 'state_management/actions/exportJobs/ActionTypes';

import {
  getAllExportJobsSuccessAction,
  getAllExportJobsErrorAction,
  ENDPOINT_EXPORT_JOBS,
} from 'state_management/actions/exportJobs/exportJobsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import { serializeExportJob } from 'utils/exportJobsSerializer';
import { IPagination } from 'models/Pagination';
import { getPaginationFromResponse } from 'utils/getPaginationFromResponse';
import { AppState } from 'state_management/AppState';
import { escapeSearch } from 'utils/urlUtils';
import { getSearchQuery, combineSearchQueries, TypeSearch } from 'utils/searchHelper';

export function* getAllSaga(
  action: GetAllExportJobsAction,
): Generator<
  | PutEffect<GetAllExportJobsAction>
  | TakeEffect
  | SelectEffect
  | CallEffect<AxiosResponse>
  | PutEffect<GetAllExportJobsSuccessAction | GetAllExportJobsErrorAction>
> {
  try {
    const pagination = (yield select((state: AppState) => state.exportJobsState.pagination)) as IPagination;
    const {
      page = pagination.page,
      perPage = pagination.perPage,
      search = pagination.search,
      advancedSearchQuery = pagination.advancedSearchQuery,
    } = action.payload;

    const exportJobsSearchSchema: TypeSearch = {
      name: 'value',
      state: 'value',
    };

    const searchQuery = combineSearchQueries(
      advancedSearchQuery || '',
      getSearchQuery(escapeSearch(search), exportJobsSearchSchema),
    );

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_EXPORT_JOBS}?pg=${page}&pg_len=${perPage}&${searchQuery}`, 2)),
    )) as AxiosResponse;

    const _pagination: IPagination = { ...getPaginationFromResponse(res), search: search || '' };

    yield put(getAllExportJobsSuccessAction(_pagination, res.data.map(serializeExportJob)));
  } catch (error) {
    yield put(getAllExportJobsErrorAction(getErrorMessage(error) || 'Fetching exportJobs failed.'));
  }
}

export function* getAllWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ExportJobsActionTypes.GET_ALL_EXPORT_JOBS, getAllSaga);
}
