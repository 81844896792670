import { IPagination } from 'models/Pagination';
import { AxiosRequestPayload } from '../../middlewares/axios/Modals';
import { Plugin } from '../../reducers/plugins/Modals';

export enum PluginsActionTypes {
  GET_ALL_PLUGINS = 'GET_ALL_PLUGINS',
  GET_ALL_PLUGINS_SUCCESS = 'GET_ALL_PLUGINS_SUCCESS',
  GET_ALL_PLUGINS_FAIL = 'GET_ALL_PLUGINS_FAIL',
  GET_PLUGIN_BY_ID = 'GET_PLUGIN_BY_ID',
}

// ============================================ GET_ALL_MAIN_PLUGINS
export interface PluginsGetAllAction {
  type: PluginsActionTypes.GET_ALL_PLUGINS;
}

export interface PluginsGetAllSuccessAction {
  type: PluginsActionTypes.GET_ALL_PLUGINS_SUCCESS;
  payload: { data: Array<Plugin>; pagination: IPagination };
}

export interface PluginsGetAllErrorAction {
  type: PluginsActionTypes.GET_ALL_PLUGINS_FAIL;
  payload: string;
}

export interface PluginById extends AxiosRequestPayload<Plugin> {
  type: PluginsActionTypes.GET_PLUGIN_BY_ID;
}

export type PluginActions = PluginsGetAllAction | PluginsGetAllSuccessAction | PluginsGetAllErrorAction;
