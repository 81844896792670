import { PutEffect, TakeEffect, ForkEffect, CallEffect, put, takeLatest, call, SelectEffect } from 'redux-saga/effects';

import { AxiosResponse } from 'axios';
import {
  getExportJobByIdSuccess,
  getExportJobByIdError,
  ENDPOINT_EXPORT_JOBS,
} from 'state_management/actions/exportJobs/exportJobsActions';
import { axiosInstance } from 'services/dataService';
import { apiUri } from 'services/main_app';
import { getErrorMessage } from 'utils/getErrorMessage';
import {
  GetExportJobByIdErrorAction,
  GetExportJobByIdSuccessAction,
  GetExportJobByIdAction,
  ExportJobsActionTypes,
} from 'state_management/actions/exportJobs/ActionTypes';
import { IExportJob, IExportJobWithChildren } from 'state_management/reducers/exportJobs/Modals';
import { serializeExportJob, serializeExportJobWithChildren } from 'utils/exportJobsSerializer';

export function* getByIdSaga(
  action: GetExportJobByIdAction,
): Generator<
  | PutEffect<GetExportJobByIdAction>
  | TakeEffect
  | CallEffect<AxiosResponse>
  | SelectEffect
  | PutEffect<GetExportJobByIdSuccessAction | GetExportJobByIdErrorAction>
> {
  try {
    const fetchChildrenQuery = action.payload.fetchChildren ? '?fetch_children=true' : '';

    const res = (yield call(() =>
      axiosInstance.get(apiUri(`/dataservice/${ENDPOINT_EXPORT_JOBS}/${action.payload.id}${fetchChildrenQuery}`, 2)),
    )) as AxiosResponse;

    const parsedExportJob: IExportJobWithChildren | IExportJob = fetchChildrenQuery
      ? serializeExportJobWithChildren(res.data)
      : serializeExportJob(res.data);

    yield put(getExportJobByIdSuccess(parsedExportJob));
  } catch (error) {
    yield put(getExportJobByIdError(getErrorMessage(error) || 'Fetching exportJobs failed. Please try again...'));
  }
}

export function* getByIdWatcher(): Generator<ForkEffect<never>> {
  yield takeLatest(ExportJobsActionTypes.GET_EXPORT_JOB_BY_ID, getByIdSaga);
}
