import { IDesignBlock } from 'state_management/reducers/designBlocks/Modals';
import { Implementation } from 'state_management/reducers/implementations/Modals';
import { ISpecification, ISpecificationFilter } from 'state_management/reducers/specifications/Modals';
import { InterfaceDirection, InterfaceType } from 'state_management/reducers/interfaces/Modals';
import { RequestState } from './RequestState';
import { IEntity } from './IEntity';

export type IDynamicInputType = 'text' | 'string' | 'number' | 'select' | 'switch' | 'autocomplete' | 'toggle';

export interface IDynamicInput {
  id: string;
  name: string;
  description?: string;
  createdAt?: string;
  value: string | number | boolean;
  type: IDynamicInputType;
  dataType?: 'number' | 'string';
  options?: Array<string | number | boolean>;
}

export type IPortTypes = 'number' | 'boolean' | 'string' | 'any' | 'hardware' | 'other';

interface Source {
  block: string;
  port: string;
}

export interface IPosition {
  x: number;
  y: number;
}

export interface IBlockLink {
  type: string;
  source: Source;
  target: Source;
  vertices: Array<IPosition>;
}

/**
 * `blank`: New Blank Block
 *
 * `new`: New Blank Block > New Design Block created
 *
 * `implementation`: New Blank Block > Existing Cubo Selected
 *
 * `subSystem`: New Blank Block > Existing SubSystem Selected
 *
 * `library`: Block Created from Design Library (design block)
 *
 * `port`: Block Created for Subsystem as External Port
 */
export type BlockOriginType = 'blank' | 'new' | 'implementation' | 'subsystem' | 'library' | 'port';

export interface ICanvasEntity extends IEntity {
  description: string;
  info: string;
  origin: BlockOriginType;
  icon: string;
  functions: Array<string>;
  categories: Array<string>;
}

export interface ILibraryBlock extends ICanvasEntity {
  info: string;
  createdAt?: string;
  iconSvg?: string;
  size: {
    width: number;
    height: number;
  };
  functions: Array<string>;
  options: Array<IDynamicInput>;
}

export interface IBlock {
  id: string;
  name: string;
  type: string;
  description?: string;
  size?: {
    width: number;
    height: number;
  };
  position: IPosition;
  options: Array<IDynamicInput>;
  functions: string[];
}

interface IElementType {
  /**
   * `idle`: New Block Created
   *
   * `loading`: Query is Running
   *
   * `success`: Query success and Block successfully compiled
   *
   * `error`: Query error or Block compilation failed
   */
  state: RequestState;
  errorHeader: string;
  errors: Array<{ id: string; message: string }>;
}

export interface IImplementationMatch extends Partial<Omit<Implementation, 'id' | 'name'>>, IEntity {
  type: 'implementation';
}

export interface ISubSystemBlockImplementationMatch {
  id: string;
  type?: undefined;
  matches: {
    selectedId: string;
    alternatives: Array<IImplementationMatch>;
  };
}

export interface ISubSystemMatch extends IEntity {
  type: 'sub-system';
  blocks: Array<ISubSystemBlockImplementationMatch>;
}

export interface IDesignBlockMatchData {
  selectedId: string;
  alternatives: Array<ISubSystemMatch | IImplementationMatch>;
}

export type IDesignBlockMatchLibrary = (ISubSystemMatch | IImplementationMatch) & {
  category: string;
  icon: string;
  selected?: boolean;
};

export const BLOCK_NAMESPACES = {
  BASIC: 'BASIC_BLOCK_NAMESPACE',
  TEXT_ONLY: 'TEXT_ONLY_BLOCK_NAMESPACE',
  TRANSPARENT: 'TRANSPARENT_BLOCK_NAMESPACE',
  SCHEMATIC: 'SCHEMATIC_BLOCK_NAMESPACE',
} as const;

export const PORT_NAMESPACES = {
  BASIC: 'LINK_PORT_NAMESPACE',
} as const;

export type BlockNamespaceType = ValueOf<typeof BLOCK_NAMESPACES>;

/** NOTE: Should replace IBlock when old canvas is deprecated, also rename to ISystemDesignBlock */
export interface IBlockV2
  extends IElementType,
    ICanvasEntity,
    Partial<Omit<IDesignBlock, 'id' | 'name' | 'icon' | 'description'>>,
    Pick<Implementation, 'taxonomy'> {
  createdAt: string;
  socketNum?: number;
  info: string;
  size: {
    width: number;
    height: number;
  };
  position: IPosition;
  existingBlockId?: string;
  functions: Array<string>;
  specificationFilters: Array<ISpecificationFilter>;
  specifications: Array<ISpecification>;
  category: string;
  selected?: boolean;
  matches?: IDesignBlockMatchData;
  namespace: BlockNamespaceType;
}

export interface ILinkPort {
  id: string;
  name: string;
  direction?: InterfaceDirection;
}

export interface ILink extends IElementType {
  id: string;
  type: 'visual' | 'broken' | 'normal';
  showLabel?: boolean;
  name: string;
  interfaces: Array<Partial<InterfaceType>>;
  sourcePort?: Partial<ILinkPort>;
  targetPort?: Partial<ILinkPort>;
}

interface Grid {
  type: string;
  value: number;
  display: string;
}

interface UiOptions {
  grid: Grid;
  jumpover: boolean;
  router: string;
}

export interface IGenericCanvas {
  blocks: Array<IBlock>;
  links: Array<IBlockLink>;
  uiOptions: UiOptions;
  settings: Array<IDynamicInput>;
  readOnly?: boolean;
  metadata?: { fileVersion: number };
}

export interface ICanvasData<T = IGenericCanvas> {
  metadata?: {
    uiGenerationTimestamp: number;
    fileVersion: string;
  };
  design: T;
}

export interface BlockAlternative {
  userID: string;
  projectID: string;
  alternativeID: string;
  blockID: string;
}

export interface Position {
  x: number;
  y: number;
}
