import { ForkEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import {
  GetImplementationByIdAction,
  ImplementationActionTypes,
} from 'state_management/actions/implementations/ActionTypes';
import { ComponentActionTypes } from 'state_management/actions/components/ActionTypes';

import {
  InterfaceActionTypes,
  GetAllInterfacesAction,
  DeleteInterfaceSuccessAction,
} from 'state_management/actions/interfaces/ActionTypes';
import { getAllInterfacesAction } from 'state_management/actions/interfaces/interfacesActions';
import { RequestsActionTypes } from 'state_management/actions/changesRequest/ActionTypes';
import { ChangeLogActionTypes } from 'state_management/actions/changeLog/ActionTypes';
import { VirtualComponentsActionTypes } from 'state_management/actions/virtualComponents/ActionTypes';

export function* refreshInterfaces(
  action: GetImplementationByIdAction | DeleteInterfaceSuccessAction,
): Generator<PutEffect<GetAllInterfacesAction>> {
  const isFetchFullListAction = [
    ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
    ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
    RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
    ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
    VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
  ].includes(action.type as ImplementationActionTypes | ComponentActionTypes);

  yield put(getAllInterfacesAction({ queryType: isFetchFullListAction ? 'full' : 'paginated' }));
}

export function* refreshSaga(): Generator<ForkEffect<never>> {
  yield takeLatest(
    [
      InterfaceActionTypes.CREATE_INTERFACE_SUCCESS,
      InterfaceActionTypes.UPDATE_INTERFACE_SUCCESS,
      InterfaceActionTypes.CLONE_INTERFACE_SUCCESS,
      InterfaceActionTypes.DELETE_INTERFACE_SUCCESS,

      // NOTE: Need all interfaces for Implementation and Concept Edit and Create Forms
      ImplementationActionTypes.GET_IMPLEMENTATION_DEPENDENCIES,
      ComponentActionTypes.GET_COMPONENT_DEPENDENCIES,
      RequestsActionTypes.GET_ALL_CHANGES_REQUESTS,
      ChangeLogActionTypes.GET_ALL_CHANGE_LOG,
      VirtualComponentsActionTypes.GET_VIRTUAL_COMPONENT_DEPENDENCIES,
    ],
    refreshInterfaces,
  );
}
