import {
  ResourceDomains,
  ResourceGroups,
  ResourceSharing,
  ShareGroup,
  ShareScope,
  ShareType,
  ShareUser,
} from 'state_management/reducers/sharing/Modals';

export const resourceGroupsToList = (
  resourceGroups: ResourceGroups | undefined,
  userGroups: Array<ShareGroup> | undefined,
): Array<ShareGroup> => {
  const resourceGroupList = Object.values(
    resourceGroups || {
      read: [],
      write: [],
      share: [],
    },
  ).reduce<Array<ShareGroup>>((list, groups) => [...list, ...groups], []);

  const resourceGroupIds = resourceGroupList.map((g) => g.id);
  const uniqueUserGroups = userGroups?.filter((g) => !resourceGroupIds.includes(g.id)) || [];

  return [...uniqueUserGroups, ...resourceGroupList];
};

export const groupToShareGroup =
  <T extends boolean>(selected: T, scope: ShareScope) =>
  (group: string): ShareGroup<T> => ({
    id: group,
    name: group,
    selected,
    scope,
  });
export const serializeShareUser =
  (scope: ShareScope) =>
  (user: string): ShareUser => ({
    id: user,
    label: user,
    scope,
  });

export const serializeSharing = (payload: Raw.IResourceSharing, isChangesRequest?: boolean): ResourceSharing => ({
  owner: payload.owner,
  isOwner: payload.is_owner,
  groups: resourceGroupsToList(
    {
      read: payload.groups?.read?.map(groupToShareGroup(true, 'read')) || [],
      write: payload.groups?.write?.map(groupToShareGroup(true, 'write')) || [],
      share: payload.groups?.share?.map(groupToShareGroup(true, 'share')) || [],
    },
    payload.user_groups?.map(groupToShareGroup(false, 'read')),
  ),
  users: Object.values({
    read: payload.users?.read?.map(serializeShareUser('read')) || [],
    write: payload.users?.write?.map(serializeShareUser('write')) || [],
    share: payload.users?.share?.map(serializeShareUser('share')) || [],
  }).reduce((list, users) => [...list, ...users], []),
  type: isChangesRequest ? undefined : (payload.share_type as Array<ShareType>),
  userAccess: payload.user_access,
});

export const deserializeSharingResourceGroups = (
  resourceDomains: ResourceDomains,
): Partial<{ groups: Raw.IResourceShareItems; users: Raw.IResourceShareItems }> => ({
  groups: {
    read: resourceDomains.resourceGroups.read.map((g) => g.id),
    write: resourceDomains.resourceGroups.write.map((g) => g.id),
    share: resourceDomains.resourceGroups.share.map((g) => g.id),
  },
  users: {
    read: resourceDomains.resourceUsers.read.map((u) => u.label),
    write: resourceDomains.resourceUsers.write.map((u) => u.label),
    share: resourceDomains.resourceUsers.share.map((u) => u.label),
  },
});
